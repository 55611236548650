import React, { useEffect, useState, useCallback } from "react";
import ChevronLeftCircle from "../../../assets/images/icons/ChevronLeftCircle.png";
import { Row, Col, Form, Button } from "react-bootstrap";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import DropZoneEditor from "./subComponents/dropZoneEditor";
import { Alert } from "reactstrap";
import RichTextEditor from "../../../components/common/richTextEditor";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import EditImage from "../../../assets/images/icons/EditImage.svg";
import {
  getAuditReportDetail,
  getExecutiveSummaries,
} from "../../../store/Report/Audit/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as helper from "../Report/helpers/helper";
import DiscardChangesPopup from "../../../components/common/discardChangesPopup";

const EditImageText = (props) => {
  const {
    viewExecutive,
    executiveId,
    aReportId,
    setAReportId,
    uploadHeaderImage,
    setUploadHeaderImage,
    fieldsDisabled,
    setFieldsDisabled,
    reportStatus,
    reportSelect
  } = props;

  let returnArray = [];
  const assignExisitingFileDetails = (data) => {
    let tempObj = {};

    tempObj = {
      id: data.id,
      file: data.imageTokenPath,
    };
    returnArray.push(tempObj);

    return returnArray;
  };

  let returnOldArray = [];
  const assignExisitingFileOldData = (data) => {
    let tempObj = {};

    tempObj = {
      id: data.id,
      file: data.imageTokenPath,
    };
    returnOldArray.push(tempObj);

    return returnOldArray;
  };

  const [values, setValues] = useState({
    header: "",
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editTest, setEditTest] = useState(EditorState.createEmpty());
  const [initialId, setInitialId] = useState("");
  const [executiveName, setExecutiveName] = useState("");
  const [status, setStatus] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [headerOldValue, setHeaderOldValue] = useState("");
  const [textOldValue, setTextOldValue] = useState(editorState);
  const [imageOldValue, setImageOldValue] = useState([]);

  let id = aReportId;

  useEffect(() => {
    if (id !== helper.ZEROID) {
      props.getAuditReportDetail(id);
    }
    props.getExecutiveSummaries();
  }, []);

  useEffect(() => {
    if (props.executiveSummary) {
      let executiveSummary = props.executiveSummary.data?.filter((obj) => {
        return obj.id === executiveId;
      });
      if (executiveSummary && executiveSummary.length > 0) {
        setInitialId(executiveSummary[0].id);
        setExecutiveName(executiveSummary[0].name);
        setStatus(executiveSummary[0].status);
        setEditContent(executiveSummary[0].isEditableContent);
      }
    }
  }, [props.executiveSummary]);

  let beforeSaveDraft = uploadHeaderImage?.filter((obj) => {
    return obj.executiveSummarySectionId === executiveId;
  });

  useEffect(() => {
    if (beforeSaveDraft && beforeSaveDraft.length > 0) {
      setValues({
        header: beforeSaveDraft[0].header
      });
      setEditorState(beforeSaveDraft[0].editorState);
      setUploadedPhotoObj(beforeSaveDraft[0].uploadedPhotoObj);
      setHeaderOldValue(beforeSaveDraft[0].header);
      setTextOldValue(beforeSaveDraft[0].editorState);
      setImageOldValue(beforeSaveDraft[0].uploadedPhotoObj);
      if (id !== helper.ZEROID) {
        if (props.auditReport) {
          let executiveSections =
            props.auditReport.data &&
            props.auditReport.data.executiveSummary.executiveSummarySections?.filter(
              (obj) => {
                return obj.id === executiveId;
              }
            );

          if (executiveSections && executiveSections.length > 0) {
            setEditableId(
              props.auditReport
                ? executiveSections[0].editableContent.id
                : executiveId
            );
          }
        }
        if (
          props.auditReport.data &&
          props.auditReport.data.reportDetail.status === 1
        ) {
          setFieldsDisabled(true);
        } else {
          setFieldsDisabled(false);
        }
      }
    } else {
      if (id !== helper.ZEROID) {
        if (props.auditReport) {
          let executiveSections =
            props.auditReport.data &&
            props.auditReport.data.executiveSummary.executiveSummarySections?.filter(
              (obj) => {
                return obj.id === executiveId;
              }
            );

          if (executiveSections && executiveSections.length > 0) {
            setHeaderOldValue(
              props.auditReport
                ? executiveSections[0].editableContent.header
                : ""
            );
            setTextOldValue(
              props.auditReport
                ? EditorState.createWithContent(
                    convertFromRaw(
                      JSON.parse(executiveSections[0].editableContent.text)
                    )
                  )
                : editorState
            );
            setEditorState(
                props.auditReport
                  ? EditorState.createWithContent(
                      convertFromRaw(
                        JSON.parse(executiveSections[0].editableContent.text)
                      )
                    )
                  : editorState
            );
            setImageOldValue(
              props.auditReport
                ? assignExisitingFileOldData(
                    executiveSections[0].editableContent
                  )
                : []
            );

            setValues({
              header: props.auditReport
                ? executiveSections[0].editableContent.header
                : ""
            });
            setUploadedPhotoObj(
              props.auditReport
                ? assignExisitingFileDetails(
                    executiveSections[0].editableContent
                  )
                : []
            );
            setEditableId(
              props.auditReport
                ? executiveSections[0].editableContent.id
                : executiveId
            );
          } else {
            setValues({
              header: "",
            });
            setEditorState(editTest);
            setTextOldValue(editTest);
            setUploadedPhotoObj([]);
          }
        }
      }
    }
  }, [props.auditReport]);

  const [editableId, setEditableId] = useState(executiveId);
  const [headerRequired, setHeaderRequired] = useState(false);
  const [textRequired, setTextRequired] = useState(false);
  const [imageRequired, setImageRequired] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitEnable, setIsSubmitEnable] = useState(false);

  const [showSaveExecutiveSuccess, setShowSaveExecutiveSuccess] =
    useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCloseConfirmation = () => setShowConfirmation(false);

  const handleConfirmationEdit = () => {
    if (
      headerOldValue === values.header &&
      JSON.stringify(convertToRaw(textOldValue.getCurrentContent())) ===
      JSON.stringify(convertToRaw(editorState.getCurrentContent())) &&
      JSON.stringify(imageOldValue) === JSON.stringify(uploadedPhotoObj)
    ) {
      setShowConfirmation(false);
      viewExecutive();
    } else {
      setShowConfirmation(true);
    }
  };

  const handleConfirmationYes = () => {
    setValues({
      header: headerOldValue,
    });
    setEditorState(textOldValue);
    setUploadedPhotoObj(imageOldValue);
    viewExecutive();
  };

  const [fileSizeError, setFileSizeError] = useState(false);
  const [currentSelectedFile, setCurrentSelectedFile] = useState(0);
  const [fileRequired, setFileRequired] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [currentUploadedFile, setCurrentUploadedFile] = useState({});
  const [selectedFiles, setSelectedFiles] = useState(null);
  const MAX_BATCH_SIZE = process.env.REACT_APP_FILE_UPLOAD_MAX_BATCH_SIZE;

  const [uploadedPhotoObj, setUploadedPhotoObj] = useState([]);

  const onDropFile = useCallback((acceptedFiles, rejectedFiles) => {
    setFileSizeError(false);
    setCurrentSelectedFile(0);
    setFileRequired(false);
    setFileTypeError(false);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setCurrentUploadedFile(reader.result);
      };
      setSelectedFiles(file);
      reader.readAsDataURL(file);
      setCurrentSelectedFile(1);
    });
    if (rejectedFiles.length > 0) {
      if (!rejectedFiles[0].file.type.includes("image")) {
        setFileTypeError(true);
      } else if (rejectedFiles[0].file.size > JSON.parse(MAX_BATCH_SIZE)) {
        setFileSizeError(true);
        setFileTypeError(false);
      } else {
        setFileSizeError(false);
        setFileTypeError(false);
      }
    }
  }, []);

  const formPhotoValidation = () => {
    let errors = [];
    if (currentSelectedFile === 0) {
      setFileRequired(true);
      setFileTypeError(false);
      errors.push(1);
    } else {
      setFileRequired(false);
    }

    return errors;
  };

  const uploadPhoto = (e) => {
    e.preventDefault();
    let valid = formPhotoValidation();
    if (valid.length === 0) {
      let temp = {
        file: currentUploadedFile,
        mediaType: "PhotosOrVideos",
        binaryFile: selectedFiles,
        fileSize: selectedFiles.size,
        mimeType: selectedFiles.type,
      };
      setUploadedPhotoObj((uploadedPhotoObj) => [...uploadedPhotoObj, temp]);
    }
    if (uploadedPhotoObj.length > 0) {
      setImageRequired(false);
    }
  };

  const removeImage = () => {
    setSelectedFiles(null);
    setCurrentUploadedFile({});
    setCurrentSelectedFile(0);
    setUploadedPhotoObj([]);
    setImageRequired(false);
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length >= 1) {
      if (e.target.name === "header") {
        setHeaderRequired(false);
      }
    } else {
      if (e.target.name === "header") {
        setHeaderRequired(false);
      }
    }
  };

  const formValidation = () => {
    let isvalid = true;
    let errors = [];


    if (uploadedPhotoObj.length === 0) {
      setImageRequired(true);
      isvalid = false;
      errors.push(1);
    } else {
      setImageRequired(false);
      isvalid = true;
    }

    var content = editorState.getCurrentContent();
    var isEditorEmpty = !content.hasText();
    var currentPlainText = content.getPlainText();   
    var lengthOfTrimmedContent = currentPlainText.trim().length;    

    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();
    let binaryImageFile;
    if (uploadedPhotoObj.length > 0) {
      binaryImageFile = uploadedPhotoObj[0].binaryFile;
    }
    let valid = formValidation();
    var convertedData = convertToRaw(editorState.getCurrentContent());
    if (valid.length === 0) {
      setShowAlert(true);
      setIsSubmitEnable(true);

      let temp = {
        id: initialId,
        name: executiveName,
        status: status,
        isEditableContent: editContent,
        editId: editableId,
        editName: executiveName,
        header: values.header !== null ? values.header : "",
        editorState: editorState,
        text: JSON.stringify(convertedData),
        executiveSummarySectionId: executiveId,
        auditReportId: aReportId,
        isImageContent: true,
        file: binaryImageFile,
        uploadedPhotoObj: uploadedPhotoObj,
      };

      setUploadHeaderImage(
        uploadHeaderImage.filter((obj) => {
          return obj.executiveSummarySectionId != executiveId;
        })
      );
      setUploadHeaderImage((uploadHeaderImage) => [...uploadHeaderImage, temp]);

      setShowSaveExecutiveSuccess(true);
      window.scrollTo(0, 0);
      setIsSubmitEnable(false);
      window.setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  return (
    <React.Fragment>
      <div className="audit-page-back">
        <img
          src={ChevronLeftCircle}
          width={40}
          height={40}
          alt="back"
          style={{ marginBottom: "5px", marginLeft: "-13px" }}
          onClick={() => viewExecutive()}
        />
        <span>Section:</span>
        <span className="username">Image & Text</span>
      </div>
      <hr />

      {showSaveExecutiveSuccess && showAlert && (
        <Row className="px-2">
          <Alert color="success" isOpen={showSaveExecutiveSuccess && showAlert}>
            <img src={SuccessfulValidation} alt="success" width={30} height={30} />
            Content saved successfully
          </Alert>
        </Row>
      )}

      <Form.Group className="mb-3 row mt-1">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className={`sort-form-lable`}>Image</Form.Label>

          {uploadedPhotoObj.length > 0 ? (
            <Row>
              {uploadedPhotoObj.map((photoData, i) => (
                <div className="screen-size" key={i}>
                  <img className="down-image" src={photoData.file} />
                  <img
                    src={EditImage}
                    alt=""
                    className="override-image"
                    onClick={
                      (fieldsDisabled === false || reportStatus === 0) ?
                      removeImage : undefined
                    }
                  />
                </div>
              ))}
            </Row>
          ) : (
            <Row>
              <Row>
                <DropZoneEditor
                  onDropFile={onDropFile}
                  MAX_BATCH_SIZE={MAX_BATCH_SIZE}
                  fieldsDisabled={fieldsDisabled}
                  reportStatus={reportStatus}
                />
                <span
                  className={`inline-form-error ${
                    imageRequired || fileRequired ? "display-error" : ""
                  }`}
                >
                  Please upload an Image.
                </span>

                {fileSizeError && (
                  <div className="form-group sort-form-group">
                    <Row className="px-2">
                      <Alert color="danger" isOpen={fileSizeError}>
                        <img src={ErrorValidation} alt="error" width={30} height={30} />
                        Please upload a Photo which is less than 28MB.
                      </Alert>
                    </Row>
                  </div>
                )}
                {fileTypeError && (
                  <div className="form-group sort-form-group">
                    <Row className="px-2">
                      <Alert color="danger" isOpen={fileTypeError}>
                        <img src={ErrorValidation} alt="error" width={30} height={30} />
                        Incorrect file type.
                      </Alert>
                    </Row>
                  </div>
                )}
              </Row>

              <Row>
                <Row>
                  <Button
                    className={`sort-primary-btn btn-min-width ${
                      fieldsDisabled ? "disable-fields" : ""
                    }`}
                    type="button"
                    onClick={uploadPhoto}
                    style={{ marginLeft: 10 }}
                    disabled={fieldsDisabled}
                  >
                    Upload Files
                  </Button>
                </Row>
              </Row>
            </Row>
          )}
        </Col>
        <Col lg={8} className="sort-form-field">
          <Row>
            <Form.Label className={`sort-form-lable`}>Header</Form.Label>
            <div className="sort-form-wrapper">
              <div className="form-group sort-form-group">
                <input
                  name="header"
                  id="header"
                  type="text"
                  className={`sort-form-input form-control ${
                    headerRequired ? "errorField" : ""
                  } ${fieldsDisabled ? "disable-fields" : ""}`}
                  placeholder="Type header"
                  value={values.header}
                  onChange={handleChange}
                  autoComplete="header"
                  disabled={fieldsDisabled && true}
                />
                {headerRequired && (
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={20}
                    height={20}
                    className="errorImg"
                  />
                )}
              </div>
              <span
                className={`inline-form-error ${
                  headerRequired ? "display-error" : ""
                }`}
              >
                Please enter a heading.
              </span>
            </div>
          </Row>
          <br />
          <Row>
            <div>
              <Form.Label className={`sort-form-lable`}>Text</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <RichTextEditor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    className={`sort-form-input form-control ${
                    textRequired ? "errorField" : ""
                    } ${fieldsDisabled ? "disable-fields" : ""}`}
                    fieldsDisabled={fieldsDisabled}/>

                  {textRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    textRequired ? "display-error" : ""
                  }`}
                >
                  Please enter the body content.
                </span>
              </div>
            </div>
          </Row>
        </Col>
      </Form.Group>

      <DiscardChangesPopup
        isShowDisacrdConfirmation={showConfirmation}
        handleDiscardConfirmation={handleCloseConfirmation}
        backToViewPage={handleConfirmationYes}
      />
      <hr />
      <div className="mt-4 text-center">
        <Button
          className={`sort-primary-btn btn-min-width ${
            fieldsDisabled ? "disable-fields" : ""
          }`}
          type="button"
          style={{ margin: "0 10px" }}
          onClick={submitForm}
          disabled={isSubmitEnable || fieldsDisabled}
        >
          Save
        </Button>
        <Button
          className={`sort-primary-outline-btn btn-min-width ${
            fieldsDisabled ? "disable-fields" : ""
          }`}
          type="button"
          onClick={handleConfirmationEdit}
          disabled={fieldsDisabled}
        >
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

EditImageText.propTypes = {
  getAuditReportDetail: PropTypes.func,
  auditReport: PropTypes.any,
  getExecutiveSummaries: PropTypes.func,
  executiveSummary: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getAuditReportDetail: state.Report.getAuditReportDetail,
    auditReport: state.Report.auditReport,
    getExecutiveSummaries: state.Report.getExecutiveSummaries,
    executiveSummary: state.Report.executiveSummary,
    error: state.Report.error,
  };
};

export default connect(mapStateToProps, {
  getAuditReportDetail,
  getExecutiveSummaries,
})(EditImageText);
