export const GET_AD_DETAILS = "GET_AD_DETAILS"
export const GET_AD_DETAILS_SUCCESS = "GET_AD_DETAILS_SUCCESS"
export const GET_AD_DETAILS_ERROR = "GET_AD_DETAILS_ERROR"
export const CREATE_STAFF_USER = "CREATE_STAFF_USER"
export const CREATE_STAFF_USER_SUCCESS = "CREATE_STAFF_USER_SUCCESS"
export const CREATE_STAFF_USER_ERROR = "CREATE_STAFF_USER_ERROR"
export const VIEW_STAFF_USER_ACCOUNTS = "VIEW_STAFF_USER_ACCOUNTS"
export const VIEW_STAFF_USER_ACCOUNTS_ERROR = "VIEW_STAFF_USER_ACCOUNTS_ERROR"
export const VIEW_STAFF_USER_ACCOUNTS_SUCCESS = "VIEW_STAFF_USER_ACCOUNTS_SUCCESS"
export const GET_CURRENT_UPDATE_ACCOUNT = "GET_CURRENT_UPDATE_ACCOUNT"
export const UPDATE_STAFF_USER_ACCOUNT = "UPDATE_STAFF_USER_ACCOUNT"
export const UPDATE_STAFF_USER_ACCOUNT_SUCCESS = "UPDATE_STAFF_USER_ACCOUNT_SUCCESS"
export const UPDATE_STAFF_USER_ACCOUNT_ERROR = "UPDATE_STAFF_USER_ACCOUNT_ERROR"
export const RESET_TO_DEFUALT_STATE = "RESET_TO_DEFUALT_STATE"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILED = "DELETE_USER_FAILED"






