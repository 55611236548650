import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import InfoTooltip from "../../../components/common/tooltip";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import { getGlobalSettings } from "../../../store/Settings/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Alert } from "reactstrap";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import { getLoggedUser, getDecodeAuthDetails } from "../../../helpers";

const UniversalSettings = (props) => {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;

  let decoded = getDecodeAuthDetails();
  const permissions = decoded.Permission;

  const [disablePermission, setDisablePermission] = useState(false);

  useEffect(() => {
    if (permissions.includes("PERMISSIONS.GLOBALSETTING.CREATE")) {
      setDisablePermission(false);
    } else {
      setDisablePermission(true);
    }
  }, []);

  const [id, setId] = useState("");

  const [untappedSection, setUntappedSection] = useState("");
  const [untappedSubSection, setUntappedSubSection] = useState("");
  const [potentialIOSubSection, setPotentialIOSubSection] = useState("");
  const [energySubSection, setEnergySubSection] = useState("");
  const [waterSubSection, setWaterSubSection] = useState("");
  const [carbonFootPrint, setCarbonFootPrint] = useState("");
  const [leftTitle, setLeftTitle] = useState("");
  const [leftSubTitle, setLeftSubTitle] = useState("");
  const [rightTitle, setRightTitle] = useState("");
  const [rightSubTitle, setRightSubTitle] = useState("");
  const [forecastTitle, setForecastTitle] = useState("");
  const [costInactionTitle, setCostInactionTitle] = useState("");
  const [capitalPlanning, setCapitalPlanning] = useState("");

  const [carbonFootPrintTitle, setCarbonFootPrintTitle] = useState("");
  const [carbonFootNote, setCarbonFootNote] = useState("");
  const [forecastCashFlow, setForecastCashFlow] = useState("");
  const [costInaction, setCostInaction] = useState("");

  const [energyToCarbon, setEnergyToCarbon] = useState("");
  const [waterToCarbon, setWaterToCarbon] = useState("");
  const [carbonToNoOfHours, setCarbonToNoOfHours] = useState("");
  const [carbonToNoOfNights, setCarbonToNoOfNights] = useState("");
  const [averageTravelling, setAverageTravelling] = useState("");
  const [annualEnergyCost, setAnnualEnergyCost] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [untappedSectionRequired, setUntappedSectionRequired] = useState(false);
  const [untappedSubSectionRequired, setUntappedSubSectionRequired] =
    useState(false);
  const [potentialIOSubSectionRequired, setPotentialIOSubSectionRequired] =
    useState(false);
  const [energySubSectionRequired, setEnergySubSectionRequired] =
    useState(false);
  const [waterSubSectionRequired, setWaterSubSectionRequired] = useState(false);
  const [carbonFootPrintRequired, setCarbonFootPrintRequired] = useState(false);
  const [leftTitleRequired, setLeftTitleRequired] = useState(false);
  const [leftSubTitleRequired, setLeftSubTitleRequired] = useState(false);
  const [rightTitleRequired, setRightTitleRequired] = useState(false);
  const [rightSubTitleRequired, setRightSubTitleRequired] = useState(false);
  const [forecastTitleRequired, setForecastTitleRequired] = useState(false);
  const [costInactionTitleRequired, setCostInactionTitleRequired] =
    useState(false);
  const [capitalPlanningRequired, setCapitalPlanningRequired] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitEnable, setIsSubmitEnable] = useState(false);
  const [error, setError] = useState([]);
  const [universalSuccess, setUniversalSuccess] = useState(false);
  const [universalError, setUniversalError] = useState(false);
  const [adjustedInvestmentRate, setAdjustedInvestmentRate] = useState("");
  const [welcomeMsgRequired, setWelcomeMsgRequired] = useState(false);
  const [welcomeMsg, setWelcomeMsg] = useState("");

  const handleUntappedSection = (e) => {
    setUntappedSection(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "untappedSection") {
        setUntappedSectionRequired(false);
      }
    } else {
      if (e.target.name === "untappedSection") {
        setUntappedSectionRequired(true);
      }
    }
  };

  const handleUntappedSubSection = (e) => {
    setUntappedSubSection(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "untappedSubSection") {
        setUntappedSubSectionRequired(false);
      }
    } else {
      if (e.target.name === "untappedSubSection") {
        setUntappedSubSectionRequired(true);
      }
    }
  };

  const handlePotentialIOSubSection = (e) => {
    setPotentialIOSubSection(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "potentialIOSubSection") {
        setPotentialIOSubSectionRequired(false);
      }
    } else {
      if (e.target.name === "potentialIOSubSection") {
        setPotentialIOSubSectionRequired(true);
      }
    }
  };

  const handleEnergySubSection = (e) => {
    setEnergySubSection(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "energySubSection") {
        setEnergySubSectionRequired(false);
      }
    } else {
      if (e.target.name === "energySubSection") {
        setEnergySubSectionRequired(true);
      }
    }
  };

  const handleWaterSubSection = (e) => {
    setWaterSubSection(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "waterSubSection") {
        setWaterSubSectionRequired(false);
      }
    } else {
      if (e.target.name === "waterSubSection") {
        setWaterSubSectionRequired(true);
      }
    }
  };

  const handleCarbonFootPrint = (e) => {
    setCarbonFootPrint(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonFootPrint") {
        setCarbonFootPrintRequired(false);
      }
    } else {
      if (e.target.name === "carbonFootPrint") {
        setCarbonFootPrintRequired(true);
      }
    }
  };

  const handleLeftTitle = (e) => {
    setLeftTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "leftTitle") {
        setLeftTitleRequired(false);
      }
    } else {
      if (e.target.name === "leftTitle") {
        setLeftTitleRequired(true);
      }
    }
  };

  const handleLeftSubTitle = (e) => {
    setLeftSubTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "leftSubTitle") {
        setLeftSubTitleRequired(false);
      }
    } else {
      if (e.target.name === "leftSubTitle") {
        setLeftSubTitleRequired(true);
      }
    }
  };

  const handleRightTitle = (e) => {
    setRightTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "rightTitle") {
        setRightTitleRequired(false);
      }
    } else {
      if (e.target.name === "rightTitle") {
        setRightTitleRequired(true);
      }
    }
  };

  const handleRightSubTitle = (e) => {
    setRightSubTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "rightSubTitle") {
        setRightSubTitleRequired(false);
      }
    } else {
      if (e.target.name === "rightSubTitle") {
        setRightSubTitleRequired(true);
      }
    }
  };

  const handleForecastTitle = (e) => {
    setForecastTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "forecastTitle") {
        setForecastTitleRequired(false);
      }
    } else {
      if (e.target.name === "forecastTitle") {
        setForecastTitleRequired(true);
      }
    }
  };

  const handleCostInactionTitle = (e) => {
    setCostInactionTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "costInactionTitle") {
        setCostInactionTitleRequired(false);
      }
    } else {
      if (e.target.name === "costInactionTitle") {
        setCostInactionTitleRequired(true);
      }
    }
  };

  const handleCapitalPlanning = (e) => {
    setCapitalPlanning(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "capitalPlanning") {
        setCapitalPlanningRequired(false);
      }
    } else {
      if (e.target.name === "capitalPlanning") {
        setCapitalPlanningRequired(true);
      }
    }
  };

  useEffect(() => {
    if (universalSuccess) {
      props.getGlobalSettings();
    }
  }, [universalSuccess]);

  useEffect(() => {
    if (props.globalParameters) {
      let settings = props.globalParameters && props.globalParameters.data;
      let clientDefaultSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.defaultClientSettings;
      let universalDashboardSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.universalSettings;
      let globalSettings =
        settings && props.globalParameters.data.globalSetting;
      let reportSettings =
        settings && props.globalParameters.data.reportSetting;

      setId(settings.id);

      if (universalDashboardSettings.UntappedSavingsTitle !== null) {
        setUntappedSection(universalDashboardSettings.UntappedSavingsTitle);
      }

      setUntappedSubSection(
        universalDashboardSettings.potentialCarbonSavingsTitle
      );
      setPotentialIOSubSection(
        universalDashboardSettings.potentialPAndLImprovementOpportunitiesTitle
      );
      setEnergySubSection(
        universalDashboardSettings.potentialEnergySavingsTitle
      );
      setWaterSubSection(universalDashboardSettings.potentialWaterSavingsTitle);

      if (universalDashboardSettings.CarbonFoodPrintTitle !== null) {
        setCarbonFootPrint(universalDashboardSettings.CarbonFoodPrintTitle);
      }

      if (universalDashboardSettings.CarbonFoodPrintLeftBlockTitle !== null) {
        setLeftTitle(universalDashboardSettings.CarbonFoodPrintLeftBlockTitle);
      }

      if (
        universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle !== null
      ) {
        setLeftSubTitle(
          universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle
        );
      }

      if (universalDashboardSettings.CarbonFoodPrintRightBlockTitle !== null) {
        setRightTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockTitle
        );
      }

      if (
        universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle !== null
      ) {
        setRightSubTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle
        );
      }

      if (universalDashboardSettings.ForcastCashFlowTitle !== null) {
        setForecastTitle(universalDashboardSettings.ForcastCashFlowTitle);
      }

      if (universalDashboardSettings.ProjectedCostofInactionTitle !== null) {
        setCostInactionTitle(
          universalDashboardSettings.ProjectedCostofInactionTitle
        );
      }

      if (universalDashboardSettings.CapitalPlanning !== null) {
        setCapitalPlanning(universalDashboardSettings.CapitalPlanning);
      }

      if (clientDefaultSettings.CarbonFoodPrintDescription !== null) {
        setCarbonFootPrintTitle(
          clientDefaultSettings.CarbonFoodPrintDescription
        );
      }

      if (clientDefaultSettings.CarbonFoodPrintRightBlockNote !== null) {
        setCarbonFootNote(clientDefaultSettings.CarbonFoodPrintRightBlockNote);
      }

      if (clientDefaultSettings.ForcastCashFlowDescription !== null) {
        setForecastCashFlow(clientDefaultSettings.ForcastCashFlowDescription);
      }

      if (clientDefaultSettings.ProjectedCostofInactionDescription !== null) {
        setCostInaction(
          clientDefaultSettings.ProjectedCostofInactionDescription
        );
      }

      if (globalSettings.energyToCarbon % 1 === 0) {
        setEnergyToCarbon(globalSettings.energyToCarbon.toFixed(2));
      } else {
        setEnergyToCarbon(globalSettings.energyToCarbon);
      }

      if (globalSettings.waterToCarbon % 1 === 0) {
        setWaterToCarbon(globalSettings.waterToCarbon.toFixed(2));
      } else {
        setWaterToCarbon(globalSettings.waterToCarbon);
      }

      if (globalSettings.carbonToTVHours % 1 === 0) {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours.toFixed(2));
      } else {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours);
      }

      if (globalSettings.carbonToNoOfNightUKHotel % 1 === 0) {
        setCarbonToNoOfNights(
          globalSettings.carbonToNoOfNightUKHotel.toFixed(2)
        );
      } else {
        setCarbonToNoOfNights(globalSettings.carbonToNoOfNightUKHotel);
      }

      if (globalSettings.averageTravelingOnLongHaulFlights % 1 === 0) {
        setAverageTravelling(
          globalSettings.averageTravelingOnLongHaulFlights.toFixed(2)
        );
      } else {
        setAverageTravelling(globalSettings.averageTravelingOnLongHaulFlights);
      }

      if (globalSettings.annualEnergyCostsIncrease % 1 === 0) {
        setAnnualEnergyCost(
          globalSettings.annualEnergyCostsIncrease.toFixed(2)
        );
      } else {
        setAnnualEnergyCost(globalSettings.annualEnergyCostsIncrease);
      }
      if (reportSettings.aboutSMCC !== null) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(reportSettings.aboutSMCC))
          )
        );
      } else {
        setEditorState(EditorState.createEmpty());
      }

      if (reportSettings.videoTitle !== null) {
        setTitle(reportSettings.videoTitle);
      }

      if (reportSettings.videoDescription !== null) {
        setDescription(reportSettings.videoDescription);
      }
      if (globalSettings.financingBrokerageFee !== null) {
        setAdjustedInvestmentRate(globalSettings.financingBrokerageFee);
      }
      if (universalDashboardSettings.welcomeMessage !== null) {
        setWelcomeMsg(universalDashboardSettings.welcomeMessage);
      }
    }
  }, [props.globalParameters]);

  const formValidation = () => {
    let errors = [];

    if (welcomeMsg === "") {
      setWelcomeMsgRequired(true);
      errors.push(1);
    } else {
      setWelcomeMsgRequired(false);
    }

    if (untappedSection === "") {
      setUntappedSectionRequired(true);
      errors.push(1);
    } else {
      setUntappedSectionRequired(false);
    }

    if (untappedSubSection === "") {
      setUntappedSubSectionRequired(true);
      errors.push(1);
    } else {
      setUntappedSubSectionRequired(false);
    }

    if (potentialIOSubSection === "") {
      setPotentialIOSubSectionRequired(true);
      errors.push(1);
    } else {
      setPotentialIOSubSectionRequired(false);
    }

    if (energySubSection === "") {
      setEnergySubSectionRequired(true);
      errors.push(1);
    } else {
      setEnergySubSectionRequired(false);
    }

    if (waterSubSection === "") {
      setWaterSubSectionRequired(true);
      errors.push(1);
    } else {
      setWaterSubSectionRequired(false);
    }

    if (carbonFootPrint === "") {
      setCarbonFootPrintRequired(true);
      errors.push(1);
    } else {
      setCarbonFootPrintRequired(false);
    }

    if (leftTitle === "") {
      setLeftTitleRequired(true);
      errors.push(1);
    } else {
      setLeftTitleRequired(false);
    }

    if (leftSubTitle === "") {
      setLeftSubTitleRequired(true);
      errors.push(1);
    } else {
      setLeftSubTitleRequired(false);
    }

    if (rightTitle === "") {
      setRightTitleRequired(true);
      errors.push(1);
    } else {
      setRightTitleRequired(false);
    }

    if (rightSubTitle === "") {
      setRightSubTitleRequired(true);
      errors.push(1);
    } else {
      setRightSubTitleRequired(false);
    }

    if (forecastTitle === "") {
      setForecastTitleRequired(true);
      errors.push(1);
    } else {
      setForecastTitleRequired(false);
    }

    if (costInactionTitle === "") {
      setCostInactionTitleRequired(true);
      errors.push(1);
    } else {
      setCostInactionTitleRequired(false);
    }

    if (capitalPlanning === "") {
      setCapitalPlanningRequired(true);
      errors.push(1);
    } else {
      setCapitalPlanningRequired(false);
    }

    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();
    let valid = formValidation();
    var convertedData = convertToRaw(editorState.getCurrentContent());

    if (valid.length === 0) {
      setShowAlert(true);
      setIsSubmitEnable(true);

      let form_data = new FormData();
      form_data.append("Id", id);

      form_data.append(
        "DashboardSetting.UniversalSettings.WelcomeMessage",
        welcomeMsg
      );
      form_data.append(
        "DashboardSetting.UniversalSettings.UntappedSavingsTitle",
        untappedSection.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialCarbonSavingsTitle",
        untappedSubSection.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialPAndLImprovementOpportunitiesTitle",
        potentialIOSubSection.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialEnergySavingsTitle",
        energySubSection.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialWaterSavingsTitle",
        waterSubSection.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintTitle",
        carbonFootPrint.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockTitle",
        leftTitle.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockSubtitle",
        leftSubTitle.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockTitle",
        rightTitle.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockSubtitle",
        rightSubTitle.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ForcastCashFlowTitle",
        forecastTitle.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ProjectedCostofInactionTitle",
        costInactionTitle.trim()
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CapitalPlanning",
        capitalPlanning.trim()
      );

      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintDescription",
        carbonFootPrintTitle
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintRightBlockNote",
        carbonFootNote
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ForcastCashFlowDescription",
        forecastCashFlow
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ProjectedCostofInactionDescription",
        costInaction
      );

      form_data.append("GlobalSetting.EnergyToCarbon", energyToCarbon);
      form_data.append("GlobalSetting.WaterToCarbon", waterToCarbon);
      form_data.append("GlobalSetting.CarbonToTVHours", carbonToNoOfHours);
      form_data.append(
        "GlobalSetting.CarbonToNoOfNightUKHotel",
        carbonToNoOfNights
      );
      form_data.append(
        "GlobalSetting.AverageTravelingOnLongHaulFlights",
        averageTravelling
      );
      form_data.append(
        "GlobalSetting.AnnualEnergyCostsIncrease",
        annualEnergyCost
      );
      form_data.append(
        "GlobalSetting.FinancingBrokerageFee",
        adjustedInvestmentRate
      );

      form_data.append(
        "ReportSetting.AboutSMCC",
        JSON.stringify(convertedData)
      );

      form_data.append("ReportSetting.VideoTitle", title);

      form_data.append("ReportSetting.VideoDescription", description);

      new Promise((resolve, reject) => {
        fetch(API_URL + "/GlobalSetting", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + getLoggedUser().accessToken,
          },
          body: form_data,
        })
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            if (data.isError) {
              setUniversalSuccess(false);
              setUniversalError(true);
              let emptyArray = [];
              for (const [key, value] of Object.entries(
                data.responseException.exceptionMessage.fieldErrors
              )) {
                emptyArray.push(value[0]);
              }
              setError(emptyArray);
              setIsSubmitEnable(false);
              window.setTimeout(() => {
                setUniversalError(false);
                setShowAlert(false);
              }, 5000);
            } else {
              setUniversalSuccess(true);
              setUniversalError(false);
              setIsSubmitEnable(false);
              window.setTimeout(() => {
                setUniversalSuccess(false);
                setShowAlert(false);
              }, 5000);
            }
            resolve();
          })
          .catch((err) => {
            console.log("err", JSON.stringify(err));
          });
      });

      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setShowAlert(false);
        setIsSubmitEnable(false);
      }, 5000);
    }
  };

  const handleWelcomeMsg = (e) => {
    setWelcomeMsg(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
        setWelcomeMsgRequired(false);
    } else {
        setWelcomeMsgRequired(true);
    }
  }

  return (
    <React.Fragment>
      {" "}
      {universalError && showAlert && (
        <Row className="px-2">
          {error.map((item) => {
            return (
              <Alert
                key={item}
                color="danger"
                isOpen={universalError && showAlert}
              >
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                {item}
              </Alert>
            );
          })}
        </Row>
      )}
      {universalError && showAlert && (
        <Row className="px-2">
          <Alert color="danger" isOpen={universalError && showAlert}>
            <img src={ErrorValidation} alt="error" width={30} height={30} />
            Changes could not be saved
          </Alert>
        </Row>
      )}
      {universalSuccess && showAlert && (
        <Row className="px-2">
          <Alert color="success" isOpen={universalSuccess && showAlert}>
            <img src={SuccessfulValidation} alt="success" width={30} />
            Changes saved successfully
          </Alert>
        </Row>
      )}
      {permissions.includes("PERMISSIONS.GLOBALSETTING.VIEW") ? (
        <Form className="py-1">
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Welcome Message &nbsp;
                <InfoTooltip
                  text={"Welcome message"}
                  id="welcomeMsg"
                />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="welcomeMsg"
                    id="welcome"
                    type="text"
                    className={`sort-form-input form-control ${
                      welcomeMsgRequired ? "errorField" : ""
                    }`}
                    value={welcomeMsg}
                    placeholder="Enter the welcome message"
                    onChange={handleWelcomeMsg}
                    required
                  />
                  {welcomeMsgRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    welcomeMsgRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a welcome message.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Your Untapped Savings &nbsp;
                <InfoTooltip
                  text={"Your Untapped Savings"}
                  id="untappedSavings"
                />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Section Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="untappedSection"
                    id="untappedSection"
                    type="text"
                    className={`sort-form-input form-control ${
                      untappedSectionRequired ? "errorField" : ""
                    }`}
                    value={untappedSection}
                    onChange={handleUntappedSection}
                  />
                  {untappedSectionRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    untappedSectionRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Potential Carbon Subsection Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="untappedSubSection"
                    id="untappedSubSection"
                    type="text"
                    className={`sort-form-input form-control ${
                      untappedSubSectionRequired ? "errorField" : ""
                    }`}
                    value={untappedSubSection}
                    onChange={handleUntappedSubSection}
                  />
                  {untappedSubSectionRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    untappedSubSectionRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the subsection.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Potential P&L Improvement Subsection Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="potentialIOSubSection"
                    id="potentialIOSubSection"
                    type="text"
                    className={`sort-form-input form-control ${
                      potentialIOSubSectionRequired ? "errorField" : ""
                    }`}
                    value={potentialIOSubSection}
                    onChange={handlePotentialIOSubSection}
                  />
                  {potentialIOSubSectionRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    potentialIOSubSectionRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the subsection.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Potential Energy Saving Subsection Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="energySubSection"
                    id="energySubSection"
                    type="text"
                    className={`sort-form-input form-control ${
                      energySubSectionRequired ? "errorField" : ""
                    }`}
                    value={energySubSection}
                    onChange={handleEnergySubSection}
                  />
                  {energySubSectionRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    energySubSectionRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the subsection.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Potential Water Saving Subsection Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="waterSubSection"
                    id="waterSubSection"
                    type="text"
                    className={`sort-form-input form-control ${
                      waterSubSectionRequired ? "errorField" : ""
                    }`}
                    value={waterSubSection}
                    onChange={handleWaterSubSection}
                  />
                  {waterSubSectionRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    waterSubSectionRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the subsection.
                </span>
              </div>
            </Col>
          </Form.Group>
          <br />
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Carbon Footprint &nbsp;
                <InfoTooltip text={"Carbon Footprint"} id="carbonFootprint" />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Section Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="carbonFootPrint"
                    id="carbonFootPrint"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonFootPrintRequired ? "errorField" : ""
                    }`}
                    value={carbonFootPrint}
                    onChange={handleCarbonFootPrint}
                  />
                  {carbonFootPrintRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    carbonFootPrintRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                <div className="setting-right-block">Left Block</div>
                Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="leftTitle"
                    id="leftTitle"
                    type="text"
                    className={`sort-form-input form-control ${
                      leftTitleRequired ? "errorField" : ""
                    }`}
                    value={leftTitle}
                    onChange={handleLeftTitle}
                  />
                  {leftTitleRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    leftTitleRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>Subtitle*</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="leftSubTitle"
                    id="leftSubTitle"
                    type="text"
                    className={`sort-form-input form-control ${
                      leftSubTitleRequired ? "errorField" : ""
                    }`}
                    value={leftSubTitle}
                    onChange={handleLeftSubTitle}
                  />
                  {leftSubTitleRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    leftSubTitleRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a subtitle for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                <div className="setting-right-block">Right Block</div>
                Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="rightTitle"
                    id="rightTitle"
                    type="text"
                    className={`sort-form-input form-control ${
                      rightTitleRequired ? "errorField" : ""
                    }`}
                    value={rightTitle}
                    onChange={handleRightTitle}
                  />
                  {rightTitleRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    rightTitleRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>Subtitle*</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="rightSubTitle"
                    id="rightSubTitle"
                    type="text"
                    className={`sort-form-input form-control ${
                      rightSubTitleRequired ? "errorField" : ""
                    }`}
                    value={rightSubTitle}
                    onChange={handleRightSubTitle}
                  />

                  {rightSubTitleRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    rightSubTitleRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a subtitle for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <br />
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Forecast Cashflow and Breakeven Cycle &nbsp;
                <InfoTooltip
                  text={"Forecast Cashflow and Breakeven Cycle"}
                  id="forecastCashflow"
                />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Section Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="forecastTitle"
                    id="forecastTitle"
                    type="text"
                    className={`sort-form-input form-control ${
                      forecastTitleRequired ? "errorField" : ""
                    }`}
                    value={forecastTitle}
                    onChange={handleForecastTitle}
                  />
                  {forecastTitleRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    forecastTitleRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <br />
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Projected Cost of Inaction &nbsp;
                <InfoTooltip
                  text={"Projected Cost of Inaction"}
                  id="projectedInaction"
                />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Section Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="costInactionTitle"
                    id="costInactionTitle"
                    type="text"
                    className={`sort-form-input form-control ${
                      costInactionTitleRequired ? "errorField" : ""
                    }`}
                    value={costInactionTitle}
                    onChange={handleCostInactionTitle}
                  />
                  {costInactionTitleRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    costInactionTitleRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <br />
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Capital Planning &nbsp;
                <InfoTooltip text={"Capital Planning"} id="capitalPlanning" />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Section Title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="capitalPlanning"
                    id="capitalPlanning"
                    type="text"
                    className={`sort-form-input form-control ${
                      capitalPlanningRequired ? "errorField" : ""
                    }`}
                    value={capitalPlanning}
                    onChange={handleCapitalPlanning}
                  />
                  {capitalPlanningRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    capitalPlanningRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a title for the section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <br />
          <br />
          <hr />
          <div className="mt-4 text-center">
            <Button
              className={`sort-primary-btn btn-min-width`}
              type="button"
              style={{ margin: "0px 10px 30px 10px" }}
              onClick={submitForm}
              disabled={disablePermission}
            >
              Save
            </Button>
          </div>
          {disablePermission && (
            <Row className="permission-denied-page m-0">
              <Card className="no-permission-card">
                You don't have permission to perform this action. Please contact
                the Admin.
              </Card>
            </Row>
          )}
        </Form>
      ) : (
        <Row className="permission-denied-page m-0">
          <Card className="no-permission-card">
            You don't have permission to access this content. Please contact the
            Admin.
          </Card>
        </Row>
      )}
    </React.Fragment>
  );
};

UniversalSettings.propTypes = {
  getGlobalSettings: PropTypes.func,
  globalParameters: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getGlobalSettings: state.Settings.getGlobalSettings,
    globalParameters: state.Settings.globalParm,
    error: state.Settings.settingsError,
  };
};

export default connect(mapStateToProps, {
  getGlobalSettings,
})(UniversalSettings);
