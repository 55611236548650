import React from "react";

const TableCaption = (props) => {
  const { icon, setOverrideEnable, benchmarkType, setIsBenchmarkTableUpdated } = props

  const handleChange = (e, benchmarkType) => {
    setIsBenchmarkTableUpdated(true)
    if (e.target.checked) {
      if (benchmarkType === "Savings") {
        setOverrideEnable(false)
      } if (benchmarkType === "Payback") {
        setOverrideEnable(false)
      } if (benchmarkType === "Install Complexity") {
        setOverrideEnable(false)
      }
    } else {
      if (benchmarkType === "Savings") {
        setOverrideEnable(true)
      } if (benchmarkType === "Payback") {
        setOverrideEnable(true)
      } if (benchmarkType === "Install Complexity") {
        setOverrideEnable(true)
      }
    }
  }
  return ( 
        <span className="score-table-caption-right">
          <label className="container-checkbox">
            <input type="checkbox"
              onChange={(e) => handleChange(e, benchmarkType)} />&nbsp;
            <span className="checkmark"></span>
            <span>{props.subCaption}</span>
          </label>
        </span>     
  );
};

export default TableCaption;
