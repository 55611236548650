import { useState, useEffect } from "react";
import { Row, Col, Button, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import co2 from "../../../assets/images/icons/gp2-icons/CarbonFootprint.svg";
import ReportsComplete from "../../../assets/images/icons/gp-dashboard-icons/ReportsCompleted.svg";
import Scope_1 from "../../../assets/images/icons/gp-dashboard-icons/Scope1.svg";
import Scope_2 from "../../../assets/images/icons/gp-dashboard-icons/Scope2.svg";
import Scope_3 from "../../../assets/images/icons/gp-dashboard-icons/Scope3.svg";
import preview from "../../../assets/images/icons/preview.svg";
import collapse from "../../../assets/images/icons/gp2-icons/collapse.svg";
import expand from "../../../assets/images/icons/gp2-icons/expand.svg";
import pdf from "../../../assets/images/icons/client/opportunities/OpportunitiesPDF.svg";
import CompanySearch from "./companySearch";
import { searchBaselineReports } from "../../../store/Dashboard/GP/staff/actions";

const BaselineReports = (props) => {
  const {
    setReportsExpanded,
    onePanelExpanded,
    data,
    expandPermission,
    carbonMentorExpanded,
    reportsExpanded,
    type,
  } = props;
  const [shouldExpand, setShouldExpand] = useState(false);
  const [searchParm, setSearchParm] = useState("");
  const [isClearable, setIsClearable] = useState(false);
  const [baselineReportTableData, setBaselineReportTableData] = useState();

  const handleExpand = (v) => {
    setShouldExpand(v);
    setReportsExpanded(v);
  };

  useEffect(() => {
    if (props.baselineReports && props.baselineReports.data.baselineReports) {
      setBaselineReportTableData(props.baselineReports.data.baselineReports);
    }
  }, [props.baselineReports]);

  useEffect(() => {
    if (
      props.isSuccessSearchBaselineReport &&
      !props.isSuccessBaseLineReports
    ) {
      setBaselineReportTableData(props.searchBaselineReport.data);
    }
  }, [props.isSuccessSearchBaselineReport]);

  useEffect(() => {
    if (
      !props.isSuccessSearchBaselineReport &&
      props.isSuccessBaseLineReports
    ) {
      setBaselineReportTableData(props.baselineReports.data.baselineReports);
    }
  }, [props.isSuccessSearchBaselineReport, props.isSuccessBaseLineReports]);

  if (!data) {
    return null;
  }
  return (
    <Col
      className={`p-3 position-relative ${onePanelExpanded ? "mt-3" : "m-0"}`}
      md={onePanelExpanded ? 12 : 6}
    >
      <h2 className="h4">
        2. Baseline Reports -{" "}
        <span>Completed Reports &amp; Carbon Emissions</span>
      </h2>
      <div className="widget-layout min-height-100">
        <Row className="mt-lg-3">
          <Col className="text-center">
            <div className="icon-wrapper mb-2">
              <div className="icon">
              <img src={ReportsComplete} alt="reports-completed" />
              </div>
            </div>
            <div></div>
            <span>Reports Completed</span>
            <div></div>
            <span className="fullwidth-flex sub-caption">
              {data.completedReportCount}
            </span>
          </Col>
          <Col className="text-center">
            <div className="icon-wrapper mb-2">
              <div className="icon">
                <img src={Scope_1} alt="icon-co2" />
              </div>
            </div>
            <div></div>
            <span>Scope 1 Emissions</span>
            <div></div>
            <span className="fullwidth-flex sub-caption">
              {data.totalCarbonEmmissionScope1
                .toFixed(2)
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
               {" "} tCO2e
            </span>
          </Col>
          <Col className="text-center">
            <div className="icon-wrapper mb-2">
              <div className="icon">
                <img src={Scope_2} alt="icon-co2" />
              </div>
            </div>
            <div></div>
            <span>Scope 2 Emissions</span>
            <div></div>
            <span className="fullwidth-flex sub-caption">
              {data.totalCarbonEmmissionScope2
                .toFixed(2)
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
               {" "} tCO2e
            </span>
          </Col>
          <Col className="text-center">
            <div className="icon-wrapper mb-2">
              <div className="icon">
                <img src={Scope_3} alt="icon-co2" />
              </div>
            </div>
            <div></div>
            <span>Scope 3 Emissions</span>
            <div></div>
            <span className="fullwidth-flex sub-caption">
              {data.totalCarbonEmmissionScope3
                .toFixed(2)
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
               {" "} tCO2e
            </span>
          </Col>
        </Row>
        {shouldExpand && (
          <Row className="mt-2 mb-5">
            <Col className="expand-panel">
              <CompanySearch
                searchLabel={"List of Reports Completed"}
                list={props.businessAccounts}
                searchParm={searchParm}
                setSearchParm={setSearchParm}
                searchFunction={props.searchBaselineReports}
                isClearable={isClearable}
                setIsClearable={setIsClearable}
                customClass={carbonMentorExpanded && reportsExpanded}
                type={type}
                onePanelExpanded={onePanelExpanded}
                widgetType={"baseline"}
              />
              <Row>
                <Col className="tbl-wrapper p-0">
                  {baselineReportTableData &&
                  baselineReportTableData.length > 0 ? (
                    <Table bordered className="mb-0 baseline-report-table">
                      <thead>
                        <tr>
                          <th className="col-left-padding">Company Name</th>
                          <th className="col-left-padding">Report</th>
                          <th className="col-left-padding">
                            Scope 1 Emmisions
                          </th>
                          <th className="col-left-padding">
                            Scope 2 Emmisions
                          </th>
                          <th className="col-left-padding">
                            Scope 3 Emmisions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {baselineReportTableData.length > 0 &&
                          baselineReportTableData.slice(0, 10).map((mCall, index) => (
                            <tr key={index}>
                              <td>{mCall.companyName}</td>
                              <td>
                                <a
                                  className="me-2 table-link-color"
                                  href={mCall.filePath}
                                  target="_blank"
                                >
                                  {mCall.fileName.substring(0, mCall.fileName.lastIndexOf("."))}
                                </a>
                                <a
                                  className="me-2"
                                  href={mCall.filePath}
                                  target="_blank"
                                >
                                  <img
                                    src={preview}
                                    alt="Preview report"
                                    width={25}
                                  />
                                </a>
                              </td>
                              <td>
                                {mCall.carbonEmissionScope1
                                  .toFixed(2)
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                {" "} tCO2e
                              </td>
                              <td>
                                {mCall.carbonEmissionScope2
                                  .toFixed(2)
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                {" "} tCO2e
                              </td>
                              <td>
                                {mCall.carbonEmissionScope3
                                  .toFixed(2)
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                {" "} tCO2e
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p className="text-center h5">No records available</p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {expandPermission == "True" && (
          <Row className="expand-row">
            <Col className="text-right">
              <Button
                className="panel-op"
                color="link"
                onClick={() => {
                  handleExpand(!shouldExpand);
                }}
              >
                <img
                  src={shouldExpand ? collapse : expand}
                  alt="expand/collapse"
                />
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </Col>
  );
};
BaselineReports.propTypes = {
  businessAccounts: PropTypes.any,
  searchBaselineReports: PropTypes.func,
  baselineReports: PropTypes.any,
  isSuccessBaseLineReports: PropTypes.any,
  isSuccessSearchBaselineReport: PropTypes.any,
  searchBaselineReport: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    businessAccounts: state.GPDashboard.businessAccounts,
    searchBaselineReports: state.GPDashboard.searchBaselineReports,
    baselineReports: state.GPDashboard.baselineReports,
    isSuccessBaseLineReports: state.GPDashboard.isSuccessBaseLineReports,
    isSuccessSearchBaselineReport:
      state.GPDashboard.isSuccessSearchBaselineReport,
    searchBaselineReport: state.GPDashboard.searchBaselineReport,
  };
};

export default connect(mapStateToProps, { searchBaselineReports })(
  BaselineReports
);
