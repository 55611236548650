import {
  GET_EPC_SCORE,
  GET_EPC_SCORE_SUCCESS,
  GET_EPC_SCORE_ERROR,
  GET_REPORTS_PER_SITE,
  GET_REPORTS_PER_SITE_SUCCESS,
  GET_REPORTS_PER_SITE_ERROR,
  GET_OPPORTUNITIES_PER_REPORT,
  GET_OPPORTUNITIES_PER_REPORT_SUCCESS,
  GET_OPPORTUNITIES_PER_REPORT_ERROR,
  RESET_EPC,
} from "./actionTypes";

export const getEPCDetails = (parm) => {
  return {
    type: GET_EPC_SCORE,
    payload: { parm },
  };
};
export const getEPCDetailsSuccess = (data) => {
  return {
    type: GET_EPC_SCORE_SUCCESS,
    payload: { data },
  };
};
export const getEPCDetailsFaild = (error) => {
  return {
    type: GET_EPC_SCORE_ERROR,
    payload: { error },
  };
};
export const getReportsPerSite = (parm) => {
  return {
    type: GET_REPORTS_PER_SITE,
    payload: { parm },
  };
};
export const getReportsPerSiteSuccess = (data) => {
  return {
    type: GET_REPORTS_PER_SITE_SUCCESS,
    payload: { data },
  };
};
export const getReportsPerSiteFaild = (error) => {
  return {
    type: GET_REPORTS_PER_SITE_ERROR,
    payload: { error },
  };
};
export const getOpportunitiesPerReport = (parm) => {
  return {
    type: GET_OPPORTUNITIES_PER_REPORT,
    payload: { parm },
  };
};
export const getOpportunitiesPerReportSuccess = (data) => {
  return {
    type: GET_OPPORTUNITIES_PER_REPORT_SUCCESS,
    payload: { data },
  };
};
export const getOpportunitiesPerReportFaild = (error) => {
  return {
    type: GET_OPPORTUNITIES_PER_REPORT_ERROR,
    payload: { error },
  };
};
export const resetEPC = (data) => {
  return {
    type: RESET_EPC,
    payload: { data },
  };
};
