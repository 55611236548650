export const GET_CLIENT_REPORT_DETAILS = "GET_CLIENT_REPORT_DETAILS"
export const GET_CLIENT_REPORT_DETAILS_SUCCESS = "GET_CLIENT_REPORT_DETAILS_SUCCESS"
export const GET_CLIENT_REPORT_DETAILS_ERROR = "GET_CLIENT_REPORT_DETAILS_ERROR"
export const GET_CLIENT_REPORT_LIST = "GET_CLIENT_REPORT_LIST"
export const GET_CLIENT_REPORT_LIST_SUCCESS = "GET_CLIENT_REPORT_LIST_SUCCESS"
export const GET_CLIENT_REPORT_LIST_ERROR = "GET_CLIENT_REPORT_LIST_ERROR"
export const SELECTED_REPORT = "SELECTED_REPORT"
export const GET_FINANCING_OPTIONS = "GET_FINANCING_OPTIONS"
export const GET_FINANCING_OPTIONS_SUCCESS = "GET_FINANCING_OPTIONS_SUCCESS"
export const GET_FINANCING_OPTIONS_ERROR = "GET_FINANCING_OPTIONS_ERROR"
export const GET_REPAYMENT_DATA = "GET_REPAYMENT_DATA"
export const GET_REPAYMENT_DATA_SUCCESS = "GET_REPAYMENT_DATA_SUCCESS"
export const GET_REPAYMENT_DATA_ERROR = "GET_REPAYMENT_DATA_ERROR"

