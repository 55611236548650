import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Form, Button, ProgressBar, Card } from "react-bootstrap";
import Breadcrumbs from "../../../components/common/Breadcrum";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import DocUploadIcon from "../../../assets/images/icons/DocUploadIcon.svg";
import DropzoneEditor from "../../../components/common/dropzoneEditor";
import ChevronLeftCircle from "../../../assets/images/icons/ChevronLeftCircle.png";
import InfoTooltip from "../../../components/common/tooltip";
import { Alert } from "reactstrap";
import DocumentIcon from "../../../assets/images/icons/DocumentIcon.svg";
import SearchComponent from "./subComponents/searchComponent";
import InlineErroMsg from "../../../components/common/inlineErrorMsg";
import { sliceUploadFiles, downloadExtrnal } from "./helpers";
import { Download, XCircle } from "react-feather";
import Pagination from "../../../components/common/pagination";
import RemoveFileConfirmation from "./subComponents/removeFileModal";
import { deleteAttachmentCarbon } from "../../../store/Admin/Client/actions";
import { useParams } from "react-router-dom";

const DocumentUpload = (props) => {
  const { setUploadedDocObj, uploadedDocObj, setIsFormUpdated, permissions } =
    props;

  const MAX_BATCH_SIZE = process.env.REACT_APP_FILE_UPLOAD_MAX_BATCH_SIZE;
  const maxSize = JSON.parse(MAX_BATCH_SIZE);
  let multipleFeature = false;
  let { id } = useParams();

  const [uploadDocumentSelected, setUploadDocumentSelected] = useState(false);
  const [docNameRequired, setDocNameRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const [docNameExist, setDocNameExist] = useState(false);
  const [docNameLenthError, setDocNameLenthError] = useState(false);
  const [currentSelectedDocs, setCurrentSelectedDocs] = useState(0);
  const [docFileName, setDocFileName] = useState("");
  const [progressDoc, setProgressDoc] = useState(0);
  const [docSizeError, setDocSizeError] = useState(false);
  const [docTypeError, setDocTypeError] = useState(false);
  const [fileRequired, setFileRequired] = useState(false);
  const [documentGridView, setDocumentGridView] = useState(false);
  const [documentThumbnailView, setDocumentThumbnailView] = useState(false);
  const [currentUploadedDoc, setCurrentUploadedDoc] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const [noDocumentSection, setNoDocumentSection] = useState(true);
  const [slicedArrayDoc, setSlicedArrayDoc] = useState([]);
  const [uploadSearchRequired, setUploadSearchRequired] = useState(false);
  const [paginateDocItems, setPaginateDocItems] = useState([]);
  const [totalUploadedDocs, setTotalUploadedDocs] = useState();
  const [docNameValidation, setDocNameValidation] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const fileNameRegex = /^[a-zA-Z0-9]+(\s+[a-zA-Z0-9-_]+)*$/g;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [valuesDoc, setValuesDoc] = useState({
    documentName: "",
    info: "",
  });

  const submitSearch = () => {
    setUploadSearchRequired(false);
    if (valuesUpload.searchBy !== "") {
      let temp = [...uploadedDocObj];
      let filtered = temp.filter((val) =>
        val.title.toLowerCase().includes(valuesUpload.searchBy.toLowerCase())
      );
      setSlicedArrayDoc(filtered.slice(0, itemsPerPage));
      setPaginateDocItems(sliceUploadFiles(filtered, itemsPerPage));
      setCurrentDocPage(1);
      setTotalUploadedDocs(slicedArrayDoc.length);
    } else {
      setUploadSearchRequired(true);
    }
  };

  const resetSearch = () => {
    setUploadSearchRequired(false);
    setValuesUpload({
      searchBy: "",
    });
    setSlicedArrayDoc(uploadedDocObj.slice(0, itemsPerPage));
    setPaginateDocItems(sliceUploadFiles(uploadedDocObj, itemsPerPage));
    setCurrentDocPage(1);
    setTotalUploadedDocs(uploadedDocObj.length);
  };

  const handleDocumentUpload = () => {
    setUploadDocumentSelected(true);
    setNoDocumentSection(false);
    setDocumentGridView(false);
    setDocumentThumbnailView(false);
    setValuesDoc({
      documentName: "",
      info: "",
    });
    setCurrentSelectedDocs(0);
    setDocNameExist(false);
    setDocNameExist(false);
    setDocNameRequired(false);
    setDescriptionRequired(false);
    setDocSizeError(false);
    setDocTypeError(false);
    setUploadSearchRequired(false);
  };

  const showDocumentGridView = () => {
    setUploadDocumentSelected(false);
    setNoDocumentSection(false);
    setDocumentGridView(true);
    setDocumentThumbnailView(false);
  };

  const docHandler = (e) => {
    setDocNameExist(false);
    setValuesDoc({
      ...valuesDoc,
      [e.target.name]: e.target.value,
    });
    setDocNameValidation(false);
    if (e.target.name === "documentName") {
      if (e.target.value.length >= 1 && e.target.value.length < 200) {
        setDocNameRequired(false);
        setDocNameLenthError(false);
      } else if (e.target.value.length === 0) {
        setDocNameRequired(true);
        setDocNameLenthError(false);
        setDocNameExist(false);
      } else {
        setDocNameExist(false);
        setDocNameLenthError(true);
        setDocNameRequired(false);
      }
    }

    if (e.target.value.length >= 1) {
      if (e.target.name === "info") {
        setDescriptionRequired(false);
      }
    } else {
      if (e.target.name === "info") {
        setDescriptionRequired(true);
      }
    }
  };

  const closeUploadDoc = () => {
    setSelectedDocuments({});
    setCurrentUploadedDoc({});
    setDocFileName("");
    setProgressDoc(0);
    setCurrentSelectedDocs(0);
  };

  const onDrop = useCallback((acceptedDocs, rejectedDocs) => {
    setDocSizeError(false);
    setDocTypeError(false);
    setCurrentSelectedDocs(0);
    setFileRequired(false);
    setIsFormUpdated(true);
    if (acceptedDocs.length > 0) {
      if (
        acceptedDocs[0].type.includes("image") ||
        acceptedDocs[0].type.includes("video")
      ) {
        setDocTypeError(true);
      } else if (acceptedDocs[0].size > JSON.parse(MAX_BATCH_SIZE)) {
        setDocSizeError(true);
        setDocTypeError(false);
      } else {
        setDocSizeError(false);
        setDocTypeError(false);
        acceptedDocs.forEach((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            setCurrentUploadedDoc(reader.result);
          };
          reader.fileName = file.title;
          setDocFileName(reader.fileName);
          reader.onprogress = (event) => {
            if (event.lengthComputable) {
              let percentage = (event.loaded / event.total) * 100;
              setTimeout(() => setProgressDoc(percentage), 100);
            }
          };
          setSelectedDocuments(file);
          reader.readAsDataURL(file);
          setCurrentSelectedDocs(1);
        });
      }
    }
    if (rejectedDocs.length > 0) {
      if (
        rejectedDocs[0].file.type.includes("image") ||
        rejectedDocs[0].file.type.includes("video")
      ) {
        setDocTypeError(true);
        setDocSizeError(false);
      } else if (rejectedDocs[0].file.size > JSON.parse(MAX_BATCH_SIZE)) {
        setDocSizeError(true);
        setDocTypeError(false);
      } else {
        setDocSizeError(false);
        setDocTypeError(false);
      }
    }
  }, []);

  const formDocValidation = () => {
    let errors = [];
    setDocNameValidation(false);
    setDocNameRequired(false);
    setFileRequired(false);
    setDocTypeError(false);
    if (valuesDoc.documentName === "") {
      setDocNameRequired(true);
      setDocNameValidation(false);
      errors.push(1);
    } else {
      setDocNameRequired(false);
      if (!valuesDoc.documentName.match(fileNameRegex)) {
        errors.push(1);
        setDocNameValidation(true);
      } else {
        setDocNameValidation(false);
      }
    }

    if (valuesDoc.info === "") {
      setDescriptionRequired(true);
      errors.push(1);
    } else {
      setDescriptionRequired(false);
    }

    if (currentSelectedDocs === 0) {
      setFileRequired(true);
      setDocTypeError(false);
      errors.push(1);
    } else {
      setFileRequired(false);
    }
    if (docNameLenthError) {
      errors.push(1);
    }
    return errors;
  };

  const uploadDoc = (e) => {
    e.preventDefault();
    let valid = formDocValidation();
    setDocNameExist(false);
    if (valid.length === 0) {
      if (
        uploadedDocObj.some(
          (item) =>
            item.title.toLowerCase() === valuesDoc.documentName.toLowerCase()
        )
      ) {
        setDocNameExist(true);
      } else {
        setDocNameExist(false);
        let temp = {};
        temp = {
          title: valuesDoc.documentName,
          description: valuesDoc.info,
          file: currentUploadedDoc,
          mediaType: "Documents",
          binaryFile: selectedDocuments,
          fileSize: (selectedDocuments.size / (1024 * 1024)).toFixed(2),
          mimeType: selectedDocuments.type,
        };
        setUploadedDocObj((uploadedDocObj) => [...uploadedDocObj, temp]);
        setProgressDoc(0);
        showDocumentGridView();
      }
    }
  };

  const [valuesUpload, setValuesUpload] = useState({
    searchBy: "",
  });
  const [selectedItemToRemove, setSelectedItemToRemove] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [selectedMediaSection, setSelectedMediaSection] = useState(null);
  const handleCloseConfirmation = () => setShowConfirmation(false);

  const handleChangeSearch = (e) => {
    setValuesUpload({
      ...valuesUpload,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length >= 1) {
      setUploadSearchRequired(false);
    }
  };

  const handleRemove = (list, fname, id) => {
    setShowConfirmation(true);
    setSelectedItemToRemove(fname);
    setDataList(list);

    if (id) {
      setSelectedMediaSection(id);
      setTotalUploadedDocs(totalUploadedDocs);
    } else {
      setSelectedMediaSection(null);
      setTotalUploadedDocs(totalUploadedDocs);
    }
  };

  const removeFile = () => {
    if (selectedMediaSection) {
      props.deleteAttachmentCarbon(id, selectedMediaSection);
    }
    let getIndex = dataList.findIndex(
      (obj) => obj.title === selectedItemToRemove
    );
    dataList.splice(getIndex, 1);
    setSlicedArrayDoc(uploadedDocObj.slice(0, itemsPerPage));
    setPaginateDocItems(sliceUploadFiles(uploadedDocObj, itemsPerPage));
    if (currentDocPage > 1) {
      setCurrentDocPage(1);
    }
    setShowConfirmation(false);

    setTotalUploadedDocs(totalUploadedDocs - 1);
  };

  const [currentDocPage, setCurrentDocPage] = useState(1);
  const [isHasNextDoc, setIsHasNaxtDoc] = useState(false);
  const [isHasPreviouseDoc, setIsHasPreviousDoc] = useState(false);

  useEffect(() => {
    if (uploadedDocObj.length > 0) {
      setSlicedArrayDoc(uploadedDocObj.slice(0, itemsPerPage));
      setPaginateDocItems(sliceUploadFiles(uploadedDocObj, itemsPerPage));
      setTotalUploadedDocs(uploadedDocObj.length);
      setDocumentGridView(true);
    }
  }, [uploadedDocObj]);

  useEffect(() => {
    modifyArrayDoc();
  }, [paginateDocItems, currentDocPage, slicedArrayDoc]);

  const modifyArrayDoc = () => {
    if (currentDocPage > 1) {
      setIsHasPreviousDoc(true);
    } else {
      setIsHasPreviousDoc(false);
    }
    if (currentDocPage < Math.ceil(totalUploadedDocs / itemsPerPage)) {
      setIsHasNaxtDoc(true);
    } else {
      setIsHasNaxtDoc(false);
    }
  };

  const paginateDoc = (pageNum) => {
    setCurrentDocPage(pageNum);
    let filterArray = [pageNum - 1];
    let filter = paginateDocItems.filter((_, index) =>
      filterArray.includes(index)
    );
    let items = filter.map((item) => item);
    setSlicedArrayDoc(items[0]);
    var element = document.getElementById("upload-carbon");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const nextPageDoc = async () => {
    setCurrentDocPage((page) => page + 1);
    let currentPage1 = currentDocPage + 1;
    let filterArray = [currentPage1 - 1];
    let filter = paginateDocItems.filter((_, index) =>
      filterArray.includes(index)
    );
    let items = filter.map((item) => item);
    setSlicedArrayDoc(items[0]);
    var element = document.getElementById("upload-carbon");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const prevPageDoc = async () => {
    setCurrentDocPage((page) => page - 1);
    let currentPage1 = currentDocPage - 1;
    let filterArray = [currentPage1 - 1];
    let filter = paginateDocItems.filter((_, index) =>
      filterArray.includes(index)
    );
    let items = filter.map((item) => item);
    setSlicedArrayDoc(items[0]);
    var element = document.getElementById("upload-carbon");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const lastPageDoc = () => {
    setCurrentDocPage(Math.ceil(uploadedDocObj.length / itemsPerPage));
    let filterArray = [Math.ceil(uploadedDocObj.length / itemsPerPage) - 1];
    let filter = paginateDocItems.filter((_, index) =>
      filterArray.includes(index)
    );
    let items = filter.map((item) => item);
    setSlicedArrayDoc(items[0]);
    var element = document.getElementById("upload-carbon");
    element.scrollIntoView({ behavior: "smooth" });
  };

  //pagination for video/photo
  const pageOptionsDoc = {
    itemsPerPage: itemsPerPage,
    totalPages: Math.ceil(totalUploadedDocs / itemsPerPage),
    paginate: paginateDoc,
    nextPage: nextPageDoc,
    prevPage: prevPageDoc,
    currentPage: currentDocPage,
    lastPage: lastPageDoc,
    hasPrevious: isHasPreviouseDoc,
    hasNext: isHasNextDoc,
    totalCount: totalUploadedDocs,
  };

  return (
    <React.Fragment>
      <div id="upload-carbon"></div>
      <Form.Group className="mb-3 row mt-5">
        <Col lg={4}>
          <Breadcrumbs title={"Documents"} breadcrumbItem={""} page={""} />
        </Col>
        <Col lg={8}>
          {(documentGridView || documentThumbnailView) &&
            uploadedDocObj.length > 0 && (
              <SearchComponent
                requiredText={uploadSearchRequired}
                errorMsg={"Please enter a document name to search."}
                search={submitSearch}
                setValuesUpload={setValuesUpload}
                valuesUpload={valuesUpload}
                handleChangeSearch={handleChangeSearch}
                placeholderText={"Search Documents"}
                resetSearch={resetSearch}
              />
            )}
        </Col>
      </Form.Group>
      <Form.Group
        className="row upload-section-header"
        controlId="formAuditData"
      >
        <Col lg={10}></Col>
        <Col lg={2}>
          <Row>
            <Button
              className="sort-primary-btn btn-min-width"
              type="button"
              onClick={handleDocumentUpload}
              style={{ width: 180, height: 40 }}
            >
              Upload Document
            </Button>
          </Row>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row mt-4" controlId="formAuditData">
        {noDocumentSection && uploadedDocObj.length === 0 && (
          <div className="upload-box">
            <section>
              <p className="upload-text">No documents to show.</p>
              <button
                onClick={handleDocumentUpload}
                className="click-browse-btn"
              >
                Click Upload document to start uploading.
              </button>
            </section>
          </div>
        )}

        {uploadDocumentSelected && (
          <div>
            <Form.Group className="mb-3 row" controlId="formAuditData">
              <div className="page-back m-0">
                <img
                  src={ChevronLeftCircle}
                  width={40}
                  height={40}
                  alt="back"
                  style={{ marginLeft: "-13px" }}
                  onClick={() => showDocumentGridView()}
                />
                <span>Upload Document</span>
              </div>
            </Form.Group>
            <hr />
            {permissions &&
            permissions.includes(
              "PERMISSIONS.CLIENTBUSINESSACCOUNT.ATTACHMENTUPLOAD"
            ) ? (
              <>
                <Form.Group className="mb-3 row" controlId="formAuditData">
                  <Col sm={4} md={3} lg={3}>
                    <Form.Label className="sort-form-section-lable">
                      Document Details
                    </Form.Label>
                    &nbsp;
                    <InfoTooltip text={"Document Details"} id="auditAccount" />
                  </Col>
                  <Col lg={8} className="sort-form-field">
                    <Form.Label className={`sort-form-lable`}>
                      File Title
                    </Form.Label>
                    <div className="sort-form-wrapper">
                      <div className="form-group sort-form-group">
                        <input
                          name="documentName"
                          type="text"
                          placeholder="Type file name"
                          className={`sort-form-input form-control ${
                            docNameRequired ||
                            docNameExist ||
                            docNameLenthError ||
                            docNameValidation
                              ? "errorField"
                              : ""
                          }`}
                          value={valuesDoc.documentName}
                          onChange={docHandler}
                        />
                        {(docNameRequired ||
                          docNameExist ||
                          docNameLenthError ||
                          docNameValidation) && (
                          <img
                            src={ErrorValidation}
                            alt="error"
                            width={20}
                            height={20}
                            className="errorImg"
                          />
                        )}
                      </div>
                      <InlineErroMsg
                        errorType={docNameRequired}
                        errorMsg={"Please enter a document name."}
                      />
                      <InlineErroMsg
                        errorType={docNameExist}
                        errorMsg={`A document by that name exists. Please enter a different
                    document name.`}
                      />
                      <InlineErroMsg
                        errorType={docNameLenthError}
                        errorMsg={`Please make sure that the file name of the document is less than 20 characters in length.`}
                      />
                      <InlineErroMsg
                        errorType={docNameValidation}
                        errorMsg={
                          "Document file name cannot contain any special characters including !@#$£%^&*( );:+=` ' “ ?<>[ ]{ }  |  / , ."
                        }
                      />
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3 row mt-5" controlId="formAuditData">
                  <Col sm={4} md={3} lg={3}>
                    <Form.Label className="sort-form-section-lable"></Form.Label>
                  </Col>
                  <Col lg={8} className="sort-form-field">
                    <Form.Label className={`sort-form-lable`}>
                      Description
                    </Form.Label>
                    <div className="sort-form-wrapper">
                      <div className="form-group sort-form-group">
                        <textarea
                          name="info"
                          className={`sort-form-input form-control ${
                            descriptionRequired ? "errorField" : ""
                          }`}
                          placeholder="Type description"
                          rows="5"
                          value={valuesDoc.info}
                          onChange={docHandler}
                        />
                        {descriptionRequired && (
                          <img
                            src={ErrorValidation}
                            alt="error"
                            width={20}
                            height={20}
                            className="errorImg"
                          />
                        )}
                      </div>
                      <InlineErroMsg
                        errorType={descriptionRequired}
                        errorMsg={"Please enter a description."}
                      />
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3 row" controlId="formAuditData">
                  <Col sm={4} md={3} lg={3}>
                    <Form.Label className="sort-form-section-lable"></Form.Label>
                  </Col>
                  <Col lg={8} className="sort-form-field">
                    <div className="sort-form-wrapper">
                      {currentSelectedDocs === 1 ? (
                        <div>
                          {" "}
                          <Row>
                            <Col lg={12}>
                              <Row>
                                <Col lg={2}>
                                  <img
                                    src={DocUploadIcon}
                                    alt="error"
                                    width={40}
                                    height={40}
                                  />
                                </Col>
                                <Col lg={10}>
                                  <div className="selected-file">
                                    {docFileName}
                                  </div>
                                  <ProgressBar
                                    now={progressDoc}
                                    // label={`${progressDoc}%`}
                                    style={{ backgroundColor: "#E3E4E8" }}
                                    className="file-progress-bar"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg={12} className="sort-form-field">
                              <Button
                                className="sort-primary-add-video-outline-btn"
                                style={{ width: "100%" }}
                                onClick={closeUploadDoc}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div className="form-group sort-form-group">
                          <DropzoneEditor
                            onDrop={onDrop}
                            maxSize={maxSize}
                            multipleFeature={multipleFeature}
                            caption={"Drop your document here"}
                            subCaption={"Click to browse"}
                          />
                        </div>
                      )}
                    </div>
                    {docSizeError && (
                      <div className="form-group sort-form-group">
                        <Row className="px-2">
                          <Alert color="danger" isOpen={docSizeError}>
                            <img
                              src={ErrorValidation}
                              alt="error"
                              width={30}
                              height={30}
                            />
                            Please upload a document which is less than 28MB.
                          </Alert>
                        </Row>
                      </div>
                    )}
                    {docTypeError && (
                      <div className="form-group sort-form-group">
                        <Row className="px-2">
                          <Alert color="danger" isOpen={docTypeError}>
                            <img
                              src={ErrorValidation}
                              alt="error"
                              width={30}
                              height={30}
                            />
                            Incorrect file type.
                          </Alert>
                        </Row>
                      </div>
                    )}
                    {fileRequired && (
                      <div className="form-group sort-form-group">
                        <Row className="px-2">
                          <Alert color="danger" isOpen={fileRequired}>
                            <img
                              src={ErrorValidation}
                              alt="error"
                              width={30}
                              height={30}
                            />
                            Please select a file.
                          </Alert>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3 row" controlId="formAuditData">
                  <Col sm={4} md={3} lg={3}></Col>
                  <Col lg={8} className="sort-form-field">
                    <Button
                      className="sort-primary-add-video-btn"
                      style={{ width: "100%" }}
                      // disabled={!selectedFile}
                      onClick={uploadDoc}
                      disabled={progressDoc !== 100}
                    >
                      Add Document
                    </Button>
                  </Col>
                </Form.Group>
              </>
            ) : (
              <Row className="permission-denied-page m-0">
                <Card className="no-permission-card">
                  You don't have permission to upload attachments. Please
                  contact the Admin.
                </Card>
              </Row>
            )}
          </div>
        )}
        {documentGridView && uploadedDocObj.length > 0 && (
          <Row className="mt-2 m-0">
            {slicedArrayDoc.length > 0 > 0 &&
              slicedArrayDoc.map((docData, i) => (
                <Card
                  className="card-video grid-view mt-2"
                  style={{ border: "none" }}
                  key={i}
                >
                  <Col lg={12}>
                    <Row style={{ margin: "20px 0" }}>
                      <Col lg={1}>
                        <img
                          className="edit-img"
                          src={DocumentIcon}
                          alt=""
                          width={35}
                          height={35}
                        />
                      </Col>
                      <Col lg={7}>
                        <div style={{ marginBottom: 10 }}>
                          <span
                            className="grid-filename"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            {docData.title}
                          </span>
                        </div>

                        <span className="grid-note">Description</span>
                        <br />
                        <div className={`grid-info`}>
                          {docData.description}&nbsp;
                        </div>
                      </Col>
                      <Col lg={4} className="grid-right-btn text-end">
                        <>
                          {docData.id ? (
                            <>
                              {permissions.includes(
                                "PERMISSIONS.CLIENTBUSINESSACCOUNT.ATTACHMENTDOWNLOAD"
                              ) ? (
                                <span
                                  style={{ marginRight: 20 }}
                                  onClick={() =>
                                    downloadExtrnal(
                                      docData.id,
                                      docData.mimeType,
                                      docData.title,
                                      id
                                    )
                                  }
                                >
                                  <Download size={25} />
                                </span>
                              ) : (
                                <span>
                                  <Download size={25} color="#737572" />
                                </span>
                              )}
                            </>
                          ) : (
                            <a
                              href={docData.file}
                              download={docData.title}
                              style={{ marginRight: 20 }}
                            >
                              <Download size={25} />
                            </a>
                          )}
                        </>
                        {docData.id ? (
                          <XCircle
                            size={25}
                            onClick={() =>
                              handleRemove(
                                uploadedDocObj,
                                docData.title,
                                docData.id
                              )
                            }
                          />
                        ) : (
                          <XCircle
                            size={25}
                            onClick={() =>
                              handleRemove(uploadedDocObj, docData.title, null)
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Card>
              ))}
            {slicedArrayDoc.length > 0 && (
              <Row className="text-center mt-3">
                <Pagination data={pageOptionsDoc} />
              </Row>
            )}

            <Row className="text-center">
              <InlineErroMsg
                errorType={slicedArrayDoc.length === 0}
                errorMsg={"There are no documents matching your search."}
              />
            </Row>
          </Row>
        )}
      </Form.Group>
      <RemoveFileConfirmation
        showConfirmation={showConfirmation}
        handleCloseConfirmation={handleCloseConfirmation}
        removeFile={removeFile}
        modalText="Are you sure you want remove the carbon roadmap?"
        permissions={permissions}
      />
    </React.Fragment>
  );
};

DocumentUpload.propTypes = {
  deleteAttachmentCarbon: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    deleteAttachmentCarbon: state.Client.deleteAttachmentCarbon,
  };
};

export default connect(mapStateToProps, {
  deleteAttachmentCarbon,
})(DocumentUpload);
