import {
  POST_AUDIT_ACCOUNT_CREATE,
  POST_AUDIT_ACCOUNT_CREATE_SUCCESS,
  POST_AUDIT_ACCOUNT_CREATE_ERROR,
  UPDATE_AUDIT_ACCOUNT,
  UPDATE_AUDIT_ACCOUNT_SUCCESS,
  UPDATE_AUDIT_ACCOUNT_ERROR,
  GET_AUDIT_ACCOUNT_DETAILS,
  GET_AUDIT_ACCOUNT_DETAILS_SUCCESS,
  GET_AUDIT_ACCOUNT_DETAILS_ERROR,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_AUDIT_LIST,
  GET_AUDIT_LIST_SUCCESS,
  GET_AUDIT_LIST_ERROR,
  GET_PRODUCT_MAIN_CATEGORIES,
  GET_PRODUCT_MAIN_CATEGORIES_SUCCESS,
  GET_PRODUCT_MAIN_CATEGORIES_ERROR,
  CREATE_AUDIT_SECTION,
  CREATE_AUDIT_SECTION_SUCCESS,
  CREATE_AUDIT_SECTION_ERROR,
  VIEW_AUDIT_SECTION,
  VIEW_AUDIT_SECTION_SUCCESS,
  VIEW_AUDIT_SECTION_ERROR,
  REMOVE_AUDIT_SECTION,
  REMOVE_AUDIT_SECTION_SUCCESS,
  REMOVE_AUDIT_SECTION_ERROR,
  UPDATE_AUDIT_SECTION,
  UPDATE_AUDIT_SECTION_SUCCESS,
  UPDATE_AUDIT_SECTION_ERROR,
  VIEW_AUDIT_FINDING_DATA_SET,
  VIEW_AUDIT_FINDING_DATA_SET_SUCCESS,
  VIEW_AUDIT_FINDING_DATA_SET_ERROR,
  CREATE_AUDIT_FINDINGS,
  CREATE_AUDIT_FINDINGS_SUCCESS,
  CREATE_AUDIT_FINDINGS_ERROR,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR,
  SET_TO_DEFAULT,
  UPDATE_AUDIT_FINDINGS,
  UPDATE_AUDIT_FINDINGS_SUCCESS,
  UPDATE_AUDIT_FINDINGS_ERROR,
  REMOVE_MEDIA_SECTION,
  REMOVE_MEDIA_SECTION_SUCCESS,
  REMOVE_MEDIA_SECTION_ERROR,
  REMOVE_AUDIT_FINDINGS,
  REMOVE_AUDIT_FINDINGS_SUCCESS,
  REMOVE_AUDIT_FINDINGS_ERROR,
  DOWNLOAD_MEDIA,
  DOWNLOAD_MEDIA_SUCCESS,
  DOWNLOAD_MEDIA_ERROR,
  GET_AUDIT_FINDINGS_DETAILS,
  GET_AUDIT_FINDINGS_DETAILS_SUCCESS,
  GET_AUDIT_FINDINGS_DETAILS_ERROR,
  RESET_TO_DEFAULT_STATE, 
  POST_AUDIT_OPPORTUNITY,
  POST_AUDIT_OPPORTUNITY_SUCCESS,
  POST_AUDIT_OPPORTUNITY_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  isCreateAuditAccountSuccess: false,
  isFailedCreateAudit: false,
  isSuccessUpdateAudit: false,
  isFailedUpdateAudit: false,
  auditAccountDetails: null,
  users: [],
  auditList: null,
  isFaildSearchAudits: false,
  productMainCategories: [],
  auditSection: null,
  isSuccessAuditSectionCreate: false,
  isFaildAuditSectionCreate: false,
  auditSectionList: [],
  isSuccessAuditSectionView: false,
  isFaildAuditSectionView: false,
  isSuccessAuditFindingDelete: false,
  isFaildAuditFindingDelete: false,
  isSuccessAuditSectionDelete: false,
  isFaildAuditSectionDelete: false,
  isSuccessAuditSectionUpdate: false,
  isFaildAuditSectionUpdate: false,
  auditFindingsDataSet: null,
  isFaildAuditFindingsDataSet: false,
  isSuccessAuditFindingsDataSet: false,
  auditFindingsDataID: null,
  isSuccessSaveAuditFindingsDataSet: false,
  isFailSaveAuditFindingsDataSet: false,
  fileUploadedObj: null,
  isSuccessUpdateAuditFindings: false,
  isFailUpdateAuditFindings: false,
  auditFindingsUpdatedObj: null,
  isSuccessMediaSectionDelete: false,
  isFaildMediaSectionDelete: false,
  isSuccessMediaDownload: false,
  isFaildMediaSDownload: false,
  downloadStreamData: null,
  accountDetails: null,
  getAuditFindingsError: null,  
  isCreateAuditOpportunitySuccess: false,
  isFailedCreateAuditOpportunity: false,
  createAuditOppError: null,
  opportunityAudit: null,
};

const Audit = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      state = {
        ...state,
        loading: false,
        users: [],
        error: null,
      };
      break;
    case GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload,
        error: null,
      };
      break;
    case GET_USERS_ERROR:
      state = {
        ...state,
        loading: false,
        users: [],
        error: action.payload,
      };
      break;
    case POST_AUDIT_ACCOUNT_CREATE:
      state = {
        ...state,
        loading: false,
        isCreateAuditAccountSuccess: false,
        error: null,
        isFailedCreateAudit: false,
      };
      break;
    case POST_AUDIT_ACCOUNT_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateAuditAccountSuccess: true,
        error: null,
        isFailedCreateAudit: false,
      };
      break;
    case POST_AUDIT_ACCOUNT_CREATE_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateAuditAccountSuccess: false,
        error: action.payload,
        isFailedCreateAudit: true,
      };
      break;
    case GET_AUDIT_LIST:
      state = {
        ...state,
        loading: true,
        auditList: null,
        error: null,
        isFaildSearchAudits: false,
        isFaildAuditSectionCreate: false,
        isFaildAuditSectionUpdate: false,
        auditAccountDetails: null,
        isSuccessUpdateAudit: false,
        isCreateAuditAccountSuccess: false,
        isSuccessAuditSectionDelete: false,
        isSuccessAuditSectionCreate: false,
        isSuccessAuditSectionUpdate: false,
        accountDetails: null,
        isCreateAuditOpportunitySuccess: false,
      };
      break;
    case GET_AUDIT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditList: action.payload,
        error: null,
        isFaildSearchAudits: false,
        isSuccessUpdateAudit: false,
      };
      break;
    case GET_AUDIT_LIST_ERROR:
      state = {
        ...state,
        loading: false,
        auditList: null,
        error: action.payload,
        isFaildSearchAudits: true,
      };
      break;
    case UPDATE_AUDIT_ACCOUNT:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateAudit: false,
        error: null,
        isFailedUpdateAudit: false,
        isFaildAuditSectionCreate: false,
        isFaildAuditSectionUpdate: false,
        isSuccessAuditSectionDelete: false,
        isSuccessAuditSectionCreate: false,
        isSuccessAuditSectionUpdate: false,
      };
      break;
    case UPDATE_AUDIT_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateAudit: true,
        error: null,
        isFailedUpdateAudit: false,
        auditAccountDetails: action.payload,
      };
      break;
    case UPDATE_AUDIT_ACCOUNT_ERROR:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateAudit: false,
        error: action.payload,
        isFailedUpdateAudit: true,
      };
      break;
    case GET_AUDIT_ACCOUNT_DETAILS:
      state = {
        ...state,
        loading: false,
        auditAccountDetails: null,
        error: null,
        isSuccessAuditSectionDelete: false,
        isSuccessAuditSectionCreate: false,
        isSuccessAuditSectionUpdate: false,
      };
      break;
    case GET_AUDIT_ACCOUNT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditAccountDetails: action.payload,
        error: null,
      };
      break;
    case GET_AUDIT_ACCOUNT_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        auditAccountDetails: null,
        error: action.payload,
      };
      break;
    case GET_PRODUCT_MAIN_CATEGORIES:
      state = {
        ...state,
        loading: false,
        productMainCategories: [],
        error: null,
      };
      break;
    case GET_PRODUCT_MAIN_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        productMainCategories: action.payload,
        error: null,
      };
      break;
    case GET_PRODUCT_MAIN_CATEGORIES_ERROR:
      state = {
        ...state,
        loading: false,
        productMainCategories: [],
        error: action.payload,
      };
      break;
    case CREATE_AUDIT_SECTION:
      state = {
        ...state,
        loading: true,
        auditSection: null,
        error: null,
        isSuccessAuditSectionCreate: false,
        isFaildAuditSectionCreate: false,
        isSuccessAuditSectionDelete: false,
        isSuccessAuditSectionUpdate: false,
        getAuditFindingsError: null,
      };
      break;
    case CREATE_AUDIT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditSection: action.payload,
        error: null,
        isSuccessAuditSectionCreate: true,
        isFaildAuditSectionCreate: false,
      };
      break;
    case CREATE_AUDIT_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
        auditSection: null,
        error: action.payload,
        isSuccessAuditSectionCreate: false,
        isFaildAuditSectionCreate: true,
      };
      break;
    case VIEW_AUDIT_SECTION:
      state = {
        ...state,
        loading: true,
        auditSectionList: [],
        error: null,
        isSuccessAuditSectionView: false,
        isFaildAuditSectionView: false,
        isFaildAuditSectionDelete: false,
        isSuccessAuditSectionDelete: false,
        isSuccessAuditSectionCreate: false,
        isSuccessAuditSectionUpdate: false,
        getAuditFindingsError: null,
      };
      break;
    case VIEW_AUDIT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditSectionList: action.payload,
        error: null,
        isSuccessAuditSectionView: true,
        isFaildAuditSectionView: false,
      };
      break;
    case VIEW_AUDIT_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
        auditSectionList: [],
        error: action.payload,
        isSuccessAuditSectionView: false,
        isFaildAuditSectionView: true,
      };
      break;
    case REMOVE_AUDIT_FINDINGS:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessAuditFindingDelete: false,
        isFaildAuditFindingDelete: false,
        getAuditFindingsError: null,
      };
      break;
    case REMOVE_AUDIT_FINDINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessAuditFindingDelete: true,
        isFaildAuditFindingDelete: false,
      };
      break;
    case REMOVE_AUDIT_FINDINGS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessAuditFindingDelete: false,
        isFaildAuditFindingDelete: true,
      };
      break;  
    case REMOVE_AUDIT_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessAuditSectionDelete: false,
        isFaildAuditSectionDelete: false,
        getAuditFindingsError: null,
      };
      break;
    case REMOVE_AUDIT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessAuditSectionDelete: true,
        isFaildAuditSectionDelete: false,
      };
      break;
    case REMOVE_AUDIT_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessAuditSectionDelete: false,
        isFaildAuditSectionDelete: true,
      };
      break;
    case UPDATE_AUDIT_SECTION:
      state = {
        ...state,
        loading: true,
        auditSection: null,
        error: null,
        isSuccessAuditSectionUpdate: false,
        isFaildAuditSectionUpdate: false,
        isSuccessAuditSectionDelete: false,
        isSuccessAuditSectionCreate: false,
      };
      break;
    case UPDATE_AUDIT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditSection: action.payload,
        error: null,
        isSuccessAuditSectionUpdate: true,
        isFaildAuditSectionUpdate: false,
      };
      break;
    case UPDATE_AUDIT_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
        auditSection: null,
        error: action.payload,
        isSuccessAuditSectionUpdate: false,
        isFaildAuditSectionUpdate: true,
      };
      break;
    case VIEW_AUDIT_FINDING_DATA_SET:
      state = {
        ...state,
        loading: true,
        auditFindingsDataSet: null,
        error: null,
        isSuccessAuditFindingsDataSet: false,
        isFaildAuditFindingsDataSet: false,
        auditFindingsUpdatedObj: null,
        isFaildAuditSectionDelete: false,
        isFaildAuditSectionCreate: false,
        isFaildAuditSectionUpdate: false,
        isSuccessAuditSectionDelete: false,
        isSuccessAuditSectionCreate: false,
        isSuccessAuditSectionUpdate: false,
      };
      break;
    case VIEW_AUDIT_FINDING_DATA_SET_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditFindingsDataSet: action.payload,
        error: null,
        isSuccessAuditFindingsDataSet: true,
        isFaildAuditFindingsDataSet: false,
        auditFindingsUpdatedObj: null,
      };
      break;
    case VIEW_AUDIT_FINDING_DATA_SET_ERROR:
      state = {
        ...state,
        loading: false,
        auditFindingsDataSet: null,
        error: action.payload,
        isSuccessAuditFindingsDataSet: false,
        isFaildAuditFindingsDataSet: true,
        auditFindingsUpdatedObj: null,
      };
      break;
    case CREATE_AUDIT_FINDINGS:
      state = {
        ...state,
        loading: true,
        auditFindingsDataID: null,
        error: null,
        isSuccessSaveAuditFindingsDataSet: false,
        isFailSaveAuditFindingsDataSet: false,
      };
      break;
    case CREATE_AUDIT_FINDINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditFindingsDataID: action.payload,
        error: null,
        isSuccessSaveAuditFindingsDataSet: true,
        isFailSaveAuditFindingsDataSet: false,
      };
      break;
    case CREATE_AUDIT_FINDINGS_ERROR:
      state = {
        ...state,
        loading: false,
        auditFindingsDataID: null,
        error: action.payload,
        isSuccessSaveAuditFindingsDataSet: false,
        isFailSaveAuditFindingsDataSet: true,
      };
      break;
    case UPLOAD_FILES:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessUploadFiles: false,
        isFailUploadFiles: false,
        fileUploadedObj: null,
      };
      break;
    case UPLOAD_FILES_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessUploadFiles: true,
        isFailUploadFiles: false,
        fileUploadedObj: action.payload,
      };
      break;
    case UPLOAD_FILES_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessUploadFiles: false,
        isFailUploadFiles: true,
        fileUploadedObj: null,
      };
      break;
    case SET_TO_DEFAULT:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessUploadFiles: false,
        isFailUploadFiles: false,
        fileUploadedObj: null,
        isSuccessSaveAuditFindingsDataSet: false,
        isSuccessUpdateAuditFindings: false,
        isFailUpdateAuditFindings: false,
      };
      break;
    case UPDATE_AUDIT_FINDINGS:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessUpdateAuditFindings: false,
        isFailUpdateAuditFindings: false,
        auditFindingsUpdatedObj: null,
        isFaildMediaSectionDelete: false,
      };
      break;
    case UPDATE_AUDIT_FINDINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessUpdateAuditFindings: true,
        isFailUpdateAuditFindings: false,
        auditFindingsUpdatedObj: action.payload,
      };
      break;
    case UPDATE_AUDIT_FINDINGS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessUpdateAuditFindings: false,
        isFailUpdateAuditFindings: true,
        auditFindingsUpdatedObj: null,
      };
      break;
    case REMOVE_MEDIA_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessMediaSectionDelete: false,
        isFaildMediaSectionDelete: false,
      };
      break;
    case REMOVE_MEDIA_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessMediaSectionDelete: true,
        isFaildMediaSectionDelete: false,
      };
      break;
    case REMOVE_MEDIA_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessMediaSectionDelete: false,
        isFaildMediaSectionDelete: true,
      };
      break;
    case DOWNLOAD_MEDIA:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessMediaDownload: false,
        isFaildMediaSDownload: false,
        downloadStreamData: null,
      };
      break;
    case DOWNLOAD_MEDIA_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessMediaDownload: true,
        isFaildMediaSDownload: false,
        downloadStreamData: action.payload,
      };
      break;
    case DOWNLOAD_MEDIA_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessMediaDownload: false,
        isFaildMediaSDownload: true,
        downloadStreamData: null,
      };
      break;
    case GET_AUDIT_FINDINGS_DETAILS:
      state = {
        ...state,
        loading: true,
        getAuditFindingsError: null,
        isSuccessAuditDetails: false,
        isFaildAuditDetails: false,
      };
      break;
    case GET_AUDIT_FINDINGS_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        getAuditFindingsError: null,
        isSuccessAuditDetails: true,
        isFaildAuditDetails: false,
        auditFindingsUpdatedObj: action.payload,
      };
      break;
    case GET_AUDIT_FINDINGS_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        getAuditFindingsError: action.payload,
        isSuccessAuditDetails: false,
        isFaildAuditDetails: true,
      };
      break;
    case RESET_TO_DEFAULT_STATE:
      state = {
        ...state,
        isCreateAuditAccountSuccess: false,
      };
      break;    
    case POST_AUDIT_OPPORTUNITY:
      state = {
        ...state,
        loading: false,
        isCreateAuditOpportunitySuccess: false,
        createAuditOppError: null,
        isFailedCreateAuditOpportunity: false,
        opportunityAudit: null,
      };
      break;
    case POST_AUDIT_OPPORTUNITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateAuditOpportunitySuccess: true,
        createAuditOppError: null,
        isFailedCreateAuditOpportunity: false,
        opportunityAudit: action.payload,
      };
      break;
    case POST_AUDIT_OPPORTUNITY_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateAuditOpportunitySuccess: false,
        createAuditOppError: action.payload,
        isFailedCreateAuditOpportunity: true,
        opportunityAudit: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Audit;
