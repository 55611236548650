import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import {
  CREATE_USER_ROLE, EDIT_USER_ROLE, GET_EDIT_USER_DETAILS,
  HADLE_EDIT_MODE,
  GET_PERMISSION,
  GET_ROLES
} from "./actionType"
import {
  createRoleSuccess, createRoleError, editRoleSuccess, editRoleError,
  getEditUserDetailsSuccess, getEditUserDetailsError, handleEditMode,
  permissionSuccess, permissionError, rolesSuccess, rolesError, reCheck
} from "./actions"
import {
  createUserRolePost,
  getUserRolePermission,
  userRoleGet,
  userRolesGet,
  updateUserRole,
} from "../../../helpers/api"


function* createUserRole({ payload: { role } }) {
  try {
    const response = yield call(createUserRolePost, role)
    if (response.statusCode === 200) {
      yield put(createRoleSuccess(response.message))
    } else {
      yield put(createRoleError(response.responseException.exceptionMessage))
    }
  } catch (error) {
    yield put(createRoleError(error.data.responseException.exceptionMessage.fieldErrors.Name[0]))
  }
}

function* editUserRole({ payload: { role } }) {
  try {
    const response = yield call(updateUserRole, role)
    if (response.statusCode === 200) {
      yield put(editRoleSuccess(response.message))
    } else {
      yield put(editRoleError(response.responseException.exceptionMessage))
    }


  } catch (error) {
    yield put(editRoleError(error.data.responseException.exceptionMessage.fieldErrors.Name[0]))
  }
}

function* getEditUser({ payload: { id } }) {
  try {
    const response = yield call(userRoleGet, id)
    if (response.statusCode === 200) {
      yield put(getEditUserDetailsSuccess(response.data))
    } else {
      yield put(getEditUserDetailsError(response.responseException.exceptionMessage))
    }
  } catch (error) {
    yield put(getEditUserDetailsError(error))
  }
}
function* handleEditModeFun() {
  yield call(handleEditMode)
}

function* getRolePermissions() {
  try {
    const response = yield call(getUserRolePermission)
    if (response.statusCode === 200) {
      yield put(permissionSuccess(response.data))
    } else {
      yield put(permissionError(response))
    }

  } catch (error) {
    yield put(permissionError(error))
  }
}

function* getRolesFunc() {
  try {
    const response = yield call(userRolesGet)
    if (response.statusCode === 200) {
      yield put(rolesSuccess(response.data))
    } else {
      yield put(rolesError(response))
    }
  } catch (error) {
    yield put(rolesError(error))
  }
}
function* reCheckFun() {
  yield call(reCheck)
}


export function* watchAdmin() {
  yield takeEvery(CREATE_USER_ROLE, createUserRole)
  yield takeEvery(EDIT_USER_ROLE, editUserRole)
  yield takeEvery(GET_EDIT_USER_DETAILS, getEditUser)
  yield takeEvery(HADLE_EDIT_MODE, handleEditModeFun)
  yield takeEvery(GET_PERMISSION, getRolePermissions)
  yield takeEvery(GET_ROLES, getRolesFunc)
  yield takeEvery(GET_ROLES, reCheckFun)
}

function* AdminSaga() {
  yield all([fork(watchAdmin)])
}


export default AdminSaga
