export const GET_GLOBAL_PARAMS = "GET_GLOBAL_PARAMS"
export const GET_GLOBAL_PARAMS_SUCCESS = "GET_GLOBAL_PARAMS_SUCCESS"
export const GET_GLOBAL_PARAMS_ERROR = "GET_GLOBAL_PARAMS_ERROR"
export const GET_DASHBOARD_SETTINGS = "GET_DASHBOARD_SETTINGS"
export const GET_DASHBOARD_SETTINGS_SUCCESS = "GET_DASHBOARD_SETTINGS_SUCCESS"
export const GET_DASHBOARD_SETTINGS_ERROR = "GET_DASHBOARD_SETTINGS_ERROR"
export const GET_FINANCE_SETTINGS = "GET_FINANCE_SETTINGS"
export const GET_FINANCE_SETTINGS_SUCCESS = "GET_FINANCE_SETTINGS_SUCCESS"
export const GET_FINANCE_SETTINGS_ERROR = "GET_FINANCE_SETTINGS_ERROR"
export const POST_FINANCE_CATEGORY_CREATE = "POST_FINANCE_CATEGORY_CREATE"
export const POST_FINANCE_CATEGORY_CREATE_SUCCESS = "POST_FINANCE_CATEGORY_CREATE_SUCCESS"
export const POST_FINANCE_CATEGORY_CREATE_ERROR = "POST_FINANCE_CATEGORY_CREATE_ERROR"
export const UPDATE_FINANCE_CATEGORY = "UPDATE_FINANCE_CATEGORY"
export const UPDATE_FINANCE_CATEGORY_SUCCESS = "UPDATE_FINANCE_CATEGORY_SUCCESS"
export const UPDATE_FINANCE_CATEGORY_ERROR = "UPDATE_FINANCE_CATEGORY_ERROR"
export const GET_FINANCE_CATEGORY_DETAILS = "GET_FINANCE_CATEGORY_DETAILS"
export const GET_FINANCE_CATEGORY_DETAILS_SUCCESS = "GET_FINANCE_CATEGORY_DETAILS_SUCCESS"
export const GET_FINANCE_CATEGORY_DETAILS_ERROR = "GET_FINANCE_CATEGORY_DETAILS_ERROR"
export const POST_FINANCE_REPAYMENT_CREATE = "POST_FINANCE_REPAYMENT_CREATE"
export const POST_FINANCE_REPAYMENT_CREATE_SUCCESS = "POST_FINANCE_REPAYMENT_CREATE_SUCCESS"
export const POST_FINANCE_REPAYMENT_CREATE_ERROR = "POST_FINANCE_REPAYMENT_CREATE_ERROR"
export const UPDATE_FINANCE_REPAYMENT = "UPDATE_FINANCE_REPAYMENT"
export const UPDATE_FINANCE_REPAYMENT_SUCCESS = "UPDATE_FINANCE_REPAYMENT_SUCCESS"
export const UPDATE_FINANCE_REPAYMENT_ERROR = "UPDATE_FINANCE_REPAYMENT_ERROR"
export const GET_FINANCE_REPAYMENT_DETAILS = "GET_FINANCE_REPAYMENT_DETAILS"
export const GET_FINANCE_REPAYMENT_DETAILS_SUCCESS = "GET_FINANCE_REPAYMENT_DETAILS_SUCCESS"
export const GET_FINANCE_REPAYMENT_DETAILS_ERROR = "GET_FINANCE_REPAYMENT_DETAILS_ERROR"
export const POST_CARBON_ROADMAP_SETTINGS = "POST_CARBON_ROADMAP_SETTINGS"
export const POST_CARBON_ROADMAP_SETTINGS_SUCCESS = "POST_CARBON_ROADMAP_SETTINGS_SUCCESS"
export const POST_CARBON_ROADMAP_SETTINGS_ERROR = "POST_CARBON_ROADMAP_SETTINGS_ERROR"
export const GET_CARBON_ROADMAP_SETTINGS = "GET_CARBON_ROADMAP_SETTINGS"
export const GET_CARBON_ROADMAP_SETTINGS_SUCCESS = "GET_CARBON_ROADMAP_SETTINGS_SUCCESS"
export const GET_CARBON_ROADMAP_SETTINGS_ERROR = "GET_CARBON_ROADMAP_SETTINGS_ERROR"
export const POST_GLOBAL_OPPORTUNITY = "POST_GLOBAL_OPPORTUNITY"
export const POST_GLOBAL_OPPORTUNITY_SUCCESS = "POST_GLOBAL_OPPORTUNITY_SUCCESS"
export const POST_GLOBAL_OPPORTUNITY_ERROR = "POST_GLOBAL_OPPORTUNITY_ERROR"
export const REMOVE_REPAYMENT_SECTION = "REMOVE_REPAYMENT_SECTION"
export const REMOVE_REPAYMENT_SECTION_SUCCESS = "REMOVE_REPAYMENT_SECTION_SUCCESS"
export const REMOVE_REPAYMENT_SECTION_ERROR = "REMOVE_REPAYMENT_SECTION_ERROR"
export const REMOVE_CATEGORY_SECTION = "REMOVE_CATEGORY_SECTION"
export const REMOVE_CATEGORY_SECTION_SUCCESS = "REMOVE_CATEGORY_SECTION_SUCCESS"
export const REMOVE_CATEGORY_SECTION_ERROR = "REMOVE_CATEGORY_SECTION_ERROR"