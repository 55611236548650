import { JSEncrypt } from 'jsencrypt'


const passwordEncryption = (password) => {
  const pubKey = process.env.REACT_APP_PUBLIC_KEY

  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(pubKey)

  let encryptedLoginData = encrypt.encrypt(password)

  return encryptedLoginData
}

export default passwordEncryption;