import {
  PROJECT_ACTIVATE,
  PROJECT_ACTIVATE_SUCCESS,
  PROJECT_ACTIVATE_ERROR,
  VIEW_CLIENT_PROJECT,
  VIEW_CLIENT_PROJECT_SUCCESS,
  VIEW_CLIENT_PROJECT_ERROR,
} from "./actionTypes";

const initialState = {
  clientProjectError: null,
  loading: false,
  isSuccessProjectActivate: false,
  isFaildProjectActivate: false,
  clientProjectList: null,
  error: null,
  isFailedSearchClientProjects: false,
};

const ClientProject = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_ACTIVATE:
      state = {
        ...state,
        loading: false,
        isSuccessProjectActivate: false,
        isFaildProjectActivate: false,
        clientProjectError: null,
      };
      break;
    case PROJECT_ACTIVATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isSuccessProjectActivate: true,
        isFaildProjectActivate: false,
        clientProjectError: null,
      };
      break;
    case PROJECT_ACTIVATE_ERROR:
      state = {
        ...state,
        loading: false,
        isSuccessProjectActivate: false,
        isFaildProjectActivate: true,
        clientProjectError: action.payload,
      };
      break;
    case VIEW_CLIENT_PROJECT:
      state = {
        ...state,
        loading: true,
        clientProjectList: null,
        error: null,
        isFailedSearchClientProjects: false,
      };
      break;
    case VIEW_CLIENT_PROJECT_SUCCESS:
      state = {
        ...state,
        loading: false,
        clientProjectList: action.payload,
        error: null,
        isFailedSearchClientProjects: false,
      };
      break;
    case VIEW_CLIENT_PROJECT_ERROR:
      state = {
        ...state,
        loading: false,
        clientProjectList: null,
        error: action.payload,
        isFailedSearchClientProjects: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ClientProject;
