import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Container, Alert } from "reactstrap";
import { Form, Col, Button, Row } from "react-bootstrap";
import SearchIcon from "../../../assets/images/icons/SearchIcon.svg";
import Edit from "../../../assets/images/icons/Edit.svg";
import Clone from "../../../assets/images/icons/CloneReport.svg";
import Preview from "../../../assets/images/icons/previewReport.svg";
import PreviewDisable from "../../../assets/images/icons/previewReportDisable.svg";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import DatePicker from "react-datepicker";
import { subMonths, addMonths } from "date-fns";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import { getAuditList } from "../../../store/Admin/Audit/actions";
import Pagination from "../../../components/common/pagination";
import Loader from "../../../components/common/Loader";
import moment from "moment";
import { BsCalendar4 } from "react-icons/bs";
import { getDecodeAuthDetails } from "../../../helpers";
import { postPreviewReport } from "../../../store/Report/Audit/actions";
import PreviewReport from "../PreviewReport";
import CustomReactTable from "../../../components/common/reactTable";

const ViewAuditAccount = (props) => {
  let decoded = getDecodeAuthDetails();
  let navigate = useNavigate();

  const permissions = decoded.Permission;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [auditSearchRequired, setAuditSearchRequired] = useState(false);
  const [alertShow, setAlertShow] = useState(false);

  const [startDateValidation, setStartDateValidation] = useState(false);
  const [endDateValidation, setEndDateValidation] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showAlertPreview, setShowAlertPreview] = useState(false);
  const [previewReportSuccess, setPreviewReportSuccess] = useState(false);
  const [aReportId, setAReportId] = useState("");
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  const [currentList, setCurrentList] = useState([]);
  const [alertPreviewDisable, setAlertPreviewDisable] = useState(false);
  const [data, setData] = useState();
  const [sorting, setSorting] = useState([]);

  const getAllAudits = () => {
    setValues({
      searchBy: "",
    });
    setStartDate(null);
    setEndDate(null);
    setStartDateValidation(false);
    setEndDateValidation(false);
    let param = {
      pageNumber: 1,
      pageSize: 10,
    };
    props.getAuditList(param);
    setAuditSearchRequired(false);
  };

  const viewAllAudits = (param) => {
    props.getAuditList(param);
  };

  useEffect(() => {
    let param = {
      pageNumber: 1,
      pageSize: 10,
    };
    viewAllAudits(param);
  }, []);

  const [values, setValues] = useState({
    searchBy: "",
  });

  useEffect(() => {
    if (props.auditList) {
      setCurrentPage(props.auditList.data.currentPage);
      setCurrentList(props.auditList.data.data);

      if (props.auditList.data) {
        setData(() => [...props.auditList.data.data]);
      }
    }
  }, [props.auditList, indexOfFirstPost, indexOfLastPost, currentPage]);

  const submitStartDateFilter = (date) => {
    setStartDate(date);
    setAuditSearchRequired(false);
    if (endDate && endDate <= date) {
      setStartDateValidation(true);
    } else {
      setStartDateValidation(false);
      setEndDateValidation(false);
      setAlertShow(true);
      if (endDate) {
        if (values.searchBy) {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            startDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            searchBy: values.searchBy,
          };
          viewAllAudits(param);
        } else {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            startDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          };
          viewAllAudits(param);
        }
      } else {
        if (values.searchBy) {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            startDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            searchBy: values.searchBy,
          };
          viewAllAudits(param);
        } else {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            startDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          };
          viewAllAudits(param);
        }
      }
    }
  };
  const submitEndDateFilter = (date) => {
    setEndDate(date);
    setAuditSearchRequired(false);
    if (date <= startDate) {
      setEndDateValidation(true);
    } else {
      setEndDateValidation(false);
      setStartDateValidation(false);
      setAlertShow(true);
      if (startDate) {
        if (values.searchBy) {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            endDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            searchBy: values.searchBy,
          };
          viewAllAudits(param);
        } else {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            endDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          };
          viewAllAudits(param);
        }
      } else {
        if (values.searchBy) {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            endDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            searchBy: values.searchBy,
          };
          viewAllAudits(param);
        } else {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            endDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          };
          viewAllAudits(param);
        }
      }
    }
  };

  const previewColumn = (auditReportId) => {
    if (auditReportId !== "00000000-0000-0000-0000-000000000000") {
      return (
        <img
          className="preview-img"
          src={Preview}
          alt=""
          width={25}
          height={25}
          onClick={() => {
            getRowDataPreview(auditReportId);
          }}
        />
      );
    } else {
      return (
        <img
          className="preview-img"
          src={PreviewDisable}
          disabled={true}
          alt=""
          width={25}
          height={25}
          onClick={() => scrollUpError()}
        />
      );
    }
  };

  const scrollUpError = () => {
    window.scroll(0, 0);
    setAlertPreviewDisable(true);
  };

  useEffect(() => {
    if (alertPreviewDisable === true) {
      window.setTimeout(() => {
        setAlertPreviewDisable(false);
      }, 4000);
    }
  }, [alertPreviewDisable]);

  const formatDIsplayDate = (cell) => {
    if (cell !== null) {
      let date = moment(cell).toISOString().slice(0, 10);
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "-";
    }
  };

  const getRowData = (id) => {
    showEditPage(id);
  };

  const showEditPage = (id) => {
    navigate("/audit-account/update/" + id, { state: columns[8].accessorKey });
  };

  const getRowDataClone = (id) => {
    showEditPageClone(id);
  };

  const getRowDataPreview = (id) => {
    showPreviewPage(id);
  };

  const showEditPageClone = (id) => {
    navigate("/audit-account/update/" + id, { state: columns[9].accessorKey });
  };

  const showPreviewPage = (id) => {
    setPreviewReportSuccess(true);
    setShowAlertPreview(true);
    setAReportId(id);
    let obj = {
      reportId: id,
    };
    props.postPreviewReport(obj);
  };

  const columns = [
    {
      header: "Audit No.",
      accessorKey: "auditNumber",
      sortingFn: "alphanumeric",
    },
    {
      header: "Audit Name",
      accessorKey: "name",
      cell: (info) => (
        <span
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        >
          {info.renderValue()}
        </span>
      ),
      sortingFn: "alphanumeric",
    },
    {
      header: "Client",
      accessorKey: "clientBusinessAccountName",
      sortingFn: "alphanumeric",
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      cell: (info) => formatDIsplayDate(info.renderValue()),
      sortingFn: "alphanumeric",
    },
    {
      header: "End Date",
      accessorKey: "endDate",
      cell: (info) => formatDIsplayDate(info.renderValue()),
      sortingFn: "alphanumeric",
    },
    {
      header: "Site",
      accessorKey: "site",
      sortingFn: "alphanumeric",
    },
    {
      header: "Auditor",
      accessorKey: "auditorsNames",
      sortingFn: "alphanumeric",
    },
    {
      header: "Analyst",
      accessorKey: "analystsNames",
      sortingFn: "alphanumeric",
    },
    {
      header: "Edit Report",
      accessorKey: "edit",
      enableSorting: false,
      cell: (info) => (
        <img
          className="edit-img"
          src={Edit}
          alt=""
          width={40}
          height={40}
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        />
      ),
    },
    {
      header: "Clone",
      accessorKey: "clone",
      enableSorting: false,
      cell: (info) => (
        <img
          className="clone-img"
          src={Clone}
          alt=""
          width={25}
          height={25}
          onClick={() => {
            getRowDataClone(info.row.original.id);
          }}
        />
      ),
    },

    {
      header: "Preview Report ",
      accessorKey: "previewReport",
      enableSorting: false,
      cell: (info) => previewColumn(info.row.original.auditReportId),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    let param = {};
    if (e.target.value.length >= 1) {
      setAuditSearchRequired(false);
    } else {
      param = {
        pageNumber: 1,
        pageSize: 10,
      };
      viewAllAudits(param);
    }
  };

  const submitSearch = () => {
    setAuditSearchRequired(false);
    if (values.searchBy !== "") {
      setAlertShow(true);
      if (startDate) {
        if (endDate) {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            searchBy: values.searchBy,
            endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          };
          viewAllAudits(param);
        } else {
          let param = {
            pageNumber: 1,
            pageSize: 10,
            startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            searchBy: values.searchBy,
          };
          viewAllAudits(param);
        }
      } else {
        let param = {
          pageNumber: 1,
          pageSize: 10,
          searchBy: values.searchBy,
        };
        viewAllAudits(param);
      }
    } else {
      setAuditSearchRequired(true);
    }
    window.setTimeout(() => {
      setAlertShow(false);
    }, 5000);
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitSearch();
    }
  };

  useEffect(() => {
    setCurrentList([]);
    window.setTimeout(() => {
      setAlertShow(false);
    }, 5000);
  }, [props.isFaildSearchAudits]);

  const CustomInputStartDate = React.forwardRef((props, ref) => {
    return (
      <div
        className={`sort-form-input form-control ${
          startDateValidation ? "errorField" : ""
        }`}
        onClick={props.onClick}
      >
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <BsCalendar4 onClick={props.onClick} style={{ float: "right" }} />
        {startDateValidation && (
          <img
            src={ErrorValidation}
            alt="error"
            width={20}
            height={20}
            style={{ position: "absolute", right: -20 }}
          />
        )}
      </div>
    );
  });

  const CustomInputEndDate = React.forwardRef((props, ref) => {
    return (
      <div
        className={`sort-form-input form-control ${
          endDateValidation ? "errorField" : ""
        }`}
        onClick={props.onClick}
      >
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <BsCalendar4 onClick={props.onClick} style={{ float: "right" }} />
        {endDateValidation && (
          <img
            src={ErrorValidation}
            alt="error"
            width={20}
            height={20}
            style={{ position: "absolute", right: -20 }}
          />
        )}
      </div>
    );
  });

  const paginate = (pageNum) => {
    if (startDate && !endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(pageNum),
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (endDate && !startDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(pageNum),
        pageSize: 10,
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (startDate && endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(pageNum),
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(pageNum),
        pageSize: 10,
      };
      viewAllAudits(searchParams);
    }
  };

  const nextPage = () => {
    if (startDate && !endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage + 1),
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (endDate && !startDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage + 1),
        pageSize: 10,
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (startDate && endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage + 1),
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage + 1),
        pageSize: 10,
      };
      viewAllAudits(searchParams);
    }
  };

  const prevPage = () => {
    if (startDate && !endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage - 1),
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (endDate && !startDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage - 1),
        pageSize: 10,
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (startDate && endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage - 1),
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: parseInt(currentPage - 1),
        pageSize: 10,
      };
      viewAllAudits(searchParams);
    }
  };

  const lastPage = () => {
    if (startDate && !endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: props.auditList && props.auditList.data.totalPages,
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (endDate && !startDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: props.auditList && props.auditList.data.totalPages,
        pageSize: 10,
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else if (startDate && endDate) {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: props.auditList && props.auditList.data.totalPages,
        pageSize: 10,
        startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      viewAllAudits(searchParams);
    } else {
      let searchParams = {
        searchBy: values.searchBy,
        pageNumber: props.auditList && props.auditList.data.totalPages,
        pageSize: 10,
      };
      viewAllAudits(searchParams);
    }
  };

  const pageOptions = {
    itemsPerPage: 10,
    totalPages: props.auditList && props.auditList.data.totalPages,
    paginate: paginate,
    nextPage: nextPage,
    prevPage: prevPage,
    currentPage: props.auditList && props.auditList.data.currentPage,
    lastPage,
    hasPrevious: props.auditList && props.auditList.data.hasPrevious,
    hasNext: props.auditList && props.auditList.data.hasNext,
    totalCount: props.auditList && props.auditList.data.totalCount,
  };

  const addNewAudit = () => {
    if (permissions.includes("PERMISSIONS.AUDIT.CREATE")) {
      navigate("/audit-account/create");
    } else {
      navigate("/permisssion-denied");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Audits"}</title>
        </Helmet>
        <Container fluid>
          {props.isFaildSearchAudits && alertShow && (
            <Row className="px-2">
              <Alert color="danger">
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                There are no audits matching your search.
              </Alert>
            </Row>
          )}

          {alertPreviewDisable && (
            <Row className="px-2">
              <Alert color="danger">
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                There is no report for audit.
              </Alert>
            </Row>
          )}
          <div className="d-flex justify-content-end">
            <Button className="sort-btn-link" onClick={() => getAllAudits()}>
              View all Audits
            </Button>
          </div>
          <Form>
            <Form.Group className="mb-3 row mt-4" controlId="formDate">
              <Col sm={2} md={2} lg={2}>
                <Form.Label className="sort-form-lable green-lable">
                  Filter by Start Date
                </Form.Label>
                <div className="viewDate search-content">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => submitStartDateFilter(date)}
                    customInput={<CustomInputStartDate />}
                    dateFormat="dd/MM/yyyy"
                    dateFormatCalendar={"MMM yyyy"}
                    minDate={subMonths(new Date(), 6)}
                    maxDate={addMonths(new Date(), 6)}
                    showMonthYearDropdown
                    placeholderText={"DD/MM/YYYY"}
                  />
                </div>
                <span
                  className={`inline-form-error ${
                    startDateValidation ? "display-error" : ""
                  }`}
                >
                  Please select a start date that is before the end date.
                </span>
              </Col>
              <Col sm={2} md={2} lg={2}>
                <Form.Label className="sort-form-lable green-lable">
                  Filter by End Date
                </Form.Label>
                {/* <Input type="select" name="endDate" /> */}
                <div className="viewDate search-content">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => submitEndDateFilter(date)}
                    customInput={<CustomInputEndDate />}
                    dateFormat="dd/MM/yyyy"
                    dateFormatCalendar={"MMM yyyy"}
                    minDate={subMonths(new Date(), 6)}
                    maxDate={addMonths(new Date(), 6)}
                    showMonthYearDropdown
                    placeholderText={"DD/MM/YYYY"}
                  />
                </div>
                <span
                  className={`inline-form-error ${
                    endDateValidation ? "display-error" : ""
                  }`}
                >
                  Please select a end date that is after the start date.
                </span>
              </Col>
              <Col sm={4} md={6} lg={6}>
                <Form.Label
                  className="sort-form-lable green-lable"
                  style={{ visibility: "hidden" }}
                >
                  audit
                </Form.Label>
                <Row>
                  <Col sm={9} md={9} lg={9} style={{ position: "relative" }}>
                    <div className="form-group search-content">
                      <input
                        placeholder="Search Audit by Audit No., Audit Name, or  Client Name"
                        className={`sort-form-input form-control ${
                          auditSearchRequired ? "errorField" : ""
                        }`}
                        name={"searchBy"}
                        type="text"
                        onChange={handleChange}
                        value={values.searchBy}
                        onKeyPress={handleEnterKey}
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "4px",
                        }}
                      >
                        <img
                          src={SearchIcon}
                          alt="success"
                          width={35}
                          height={35}
                          className="email-search-btn"
                        />
                      </span>
                      {auditSearchRequired && (
                        <img
                          src={ErrorValidation}
                          alt="error"
                          width={20}
                          height={20}
                          className="errorImg"
                          style={{
                            position: "absolute",
                            right: "-8px",
                            top: "10px",
                          }}
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={2} md={2} lg={2}>
                    <Button
                      className="sort-primary-outline-btn btn-min-width"
                      style={{
                        padding: "7px 20px",
                      }}
                      type="button"
                      onClick={() => submitSearch()}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>

                <span
                  className={`inline-form-error ${
                    auditSearchRequired ? "display-error" : ""
                  }`}
                >
                  Please enter an audit number, audit name or a company name.
                </span>
              </Col>
              <Col sm={2} md={2} lg={2}>
                <Button
                  className="sort-primary-btn"
                  style={{
                    padding: "7px 10px",
                    top: "0",
                    marginTop: 27,
                    width: 140,
                  }}
                  type="button"
                  onClick={() => addNewAudit()}
                >
                  Create New Audit
                </Button>
              </Col>
            </Form.Group>

            <Form.Group className="mb-3 row mt-4">
              <Col sm={12} md={12} lg={12}>
                <div className="audit-table">
                  {currentList.length > 0 ? (
                    <CustomReactTable
                      table={table}
                      tableClass={"table-nw-audit-table"}
                    />
                  ) : (
                    <>
                      {!props.isFaildSearchAudits && (
                        <Loader data={"page-spinner"} />
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Form.Group>
          </Form>
          <Pagination data={pageOptions} />
        </Container>
        <PreviewReport
          previewReportSuccess={previewReportSuccess}
          showAlertPreview={showAlertPreview}
          setShowAlertPreview={setShowAlertPreview}
          setPreviewReportSuccess={setPreviewReportSuccess}
          aReportId={aReportId}
        />
      </div>
    </React.Fragment>
  );
};
ViewAuditAccount.propTypes = {
  getAuditList: PropTypes.func,
  auditList: PropTypes.any,
  loading: PropTypes.any,
  isFaildSearchAudits: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getAuditList: state.Audit.getAuditList,
    auditList: state.Audit.auditList,
    loading: state.Audit.loading,
    isFaildSearchAudits: state.Audit.isFaildSearchAudits,
  };
};

export default connect(mapStateToProps, {
  getAuditList,
  postPreviewReport,
})(ViewAuditAccount);
