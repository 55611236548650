import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Accordion, Card, Row, Col } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Download } from "react-feather";
import OpportunitiesExpandDocs from "../../../../../assets/images/icons/client/opportunities/OpportunitiesExpandDocs.svg";
import OpportunitiesCollapseDocs from "../../../../../assets/images/icons/client/opportunities/OpportunitiesCollapseDocs.svg";
import OpportunitiesPDF from "../../../../../assets/images/icons/client/opportunities/OpportunitiesPDF.svg";
import OpportunitiesXLS from "../../../../../assets/images/icons/client/opportunities/OpportunitiesXLS.svg";
import OpportunitiesDoc from "../../../../../assets/images/icons/client/opportunities/OpportunitiesDoc.svg";
import { downloadExtrnal } from "../helpers";
import * as helper from "../helpers/helper";

const DocumentSection = (props) => {
  const { documents } = props;
  const CustomToggle = ({ eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => { });
    return (
      <img
        src={
          activeKey === eventKey
            ? OpportunitiesExpandDocs
            : OpportunitiesCollapseDocs
        }
        alt={""}
        width={13}
        height={13}
        onClick={decoratedOnClick}
        style={{ marginRight: 10, marginLeft: 10 }}
      />
    );
  };

  const [activeKey, setActiveKey] = useState("0");
  const [leftSideList, setLeftSideList] = useState();
  const [rightSideList, setRightSideList] = useState();

  useEffect(() => {
    if (documents.length > 0) {
      const halfWayIndex = Math.ceil(documents.length / 2);
      setLeftSideList(documents.slice(0, halfWayIndex));
      setRightSideList(documents.slice(halfWayIndex));
    }
  }, [documents]);

  return (
    <Accordion
      className={"client-project-report-row"}
      onSelect={(e) => setActiveKey(e)}
    >
      <Card>
        <Card.Header>
          View Documents
          <CustomToggle eventKey={"0"}></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={"0"}>
          <Card.Body>
            <Row className="m-0">
              <Col lg={6} md={6}>
                <ul className="document-list">
                  {leftSideList &&
                    leftSideList.map((item, i) => (
                      <li key={i}>
                        <Row>
                          <Col lg={6} md={12} sm={12}>
                            {item.mimeType === "application/pdf" && (
                              <img
                                src={OpportunitiesPDF}
                                alt={""}
                                width={13}
                                height={13}
                                style={{ marginRight: 10 }}
                              />
                            )}
                            {(item.mimeType === "application/msword" ||
                              item.mimeType ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                                <img
                                  src={OpportunitiesDoc}
                                  alt={""}
                                  width={13}
                                  height={13}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                            {item.mimeType ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                                <img
                                  src={OpportunitiesXLS}
                                  alt={""}
                                  width={13}
                                  height={13}
                                  style={{ marginRight: 10 }}
                                />
                              )}

                            {item.name}
                          </Col>
                          <Col lg={6} md={12} sm={12} className="text-right">
                            <span>
                              {(item.FileSize / helper.MEGABYTE).toFixed(2)}MB
                            </span>
                            <Download
                              style={{ marginLeft: 20 }}
                              size={13}
                              color="#77BF4B"
                              onClick={() =>
                                downloadExtrnal(
                                  item.id,
                                  item.mimeType,
                                  item.name
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </li>
                    ))}
                </ul>
              </Col>
              <Col lg={6} md={6}>
                <ul className="document-list">
                  {rightSideList &&
                    rightSideList.map((item, i) => (
                      <li key={i}>
                        <Row>
                          <Col lg={6} md={6}>
                            {item.mimeType === "application/pdf" && (
                              <img
                                src={OpportunitiesPDF}
                                alt={""}
                                width={13}
                                height={13}
                                style={{ marginRight: 10 }}
                              />
                            )}
                            {(item.mimeType === "application/msword" ||
                              item.mimeType ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                                <img
                                  src={OpportunitiesDoc}
                                  alt={""}
                                  width={13}
                                  height={13}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                            {item.mimeType ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                                <img
                                  src={OpportunitiesXLS}
                                  alt={""}
                                  width={13}
                                  height={13}
                                  style={{ marginRight: 10 }}
                                />
                              )}

                            {item.name}
                          </Col>
                          <Col lg={6} md={6} className="text-right">
                            <span>
                              {(item.FileSize / helper.MEGABYTE).toFixed(2)}MB
                            </span>
                            <Download
                              style={{ marginLeft: 20 }}
                              size={13}
                              color="#77BF4B"
                              onClick={() =>
                                downloadExtrnal(
                                  item.id,
                                  item.mimeType,
                                  item.name
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </li>
                    ))}
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
DocumentSection.propTypes = {};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(DocumentSection);
