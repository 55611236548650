import React from "react";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Loader from "../../../components/common/Loader";
import Edit from "../../../assets/images/icons/Edit.svg";
import { Row, Card } from "react-bootstrap";
import { getDecodeAuthDetails } from "../../../helpers";
import CustomReactTable from "../../../components/common/reactTable";

const ViewExecutiveSummary = (props) => {
  const { editExecutiveHeader, editExecutiveImage, data } = props;

  let decoded = getDecodeAuthDetails();
  const permissions = decoded.Permission;

  const nameColumn = (cell, row, rowIndex) => {
    return (
      <div
        className={row.isEditableContent === false ? "Nolink" : ""}
        onClick={() =>
          (row.isEditableContent === true &&
            row.name.startsWith("Header") &&
            editExecutiveHeader(rowIndex, row.id)) ||
          (row.isEditableContent === true &&
            row.name.startsWith("Image") &&
            editExecutiveImage(rowIndex, row.id))
        }
      >
        {cell}
      </div>
    );
  };

  const editColumn = (cell, row, rowIndex) => {
    if (cell === true && row.name.startsWith("Header")) {
      return (
        <img
          className="edit-img"
          src={Edit}
          alt=""
          width={40}
          height={40}
          onClick={() => editExecutiveHeader(rowIndex, row.id)}
        />
      );
    }
    if (cell === true && row.name.startsWith("Image")) {
      return (
        <img
          className="edit-img"
          src={Edit}
          alt=""
          width={40}
          height={40}
          onClick={() => editExecutiveImage(rowIndex, row.id)}
        />
      );
    }
  };

  const columns = [
    {
      header: "Type",
      accessorKey: "name",
      enableSorting: false,
      cell: (info) =>
        nameColumn(info.renderValue(), info.row.original, info.row.index),
    },
    {
      header: "",
      accessorKey: "isEditableContent",
      cell: (info) =>
        editColumn(info.renderValue(), info.row.original, info.row.index),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {permissions.includes(
        "PERMISSIONS.AUDITREPORT.EXECUTIVESUMMARYSECTIONS"
      ) ? (
        <div className="custom-data-table">
          <h5 className="opportSumm-header">Executive Summary Sections</h5>
          {data ? (
            <CustomReactTable table={table} tableClass={"exec-summary-table"} />
          ) : (
            <Loader />
          )}
          <hr />
        </div>
      ) : (
        <Row className="permission-denied-page m-0">
          <Card className="no-permission-card">
            You don't have permission to access this content. Please contact the
            Admin.
          </Card>
        </Row>
      )}
    </>
  );
};

export default ViewExecutiveSummary;
