import {
  GET_AUDIT_REPORT,
  GET_AUDIT_REPORT_SUCCESS,
  GET_AUDIT_REPORT_ERROR,
  GET_EXECUTIVE_SUMMARY,
  GET_EXECUTIVE_SUMMARY_SUCCESS,
  GET_EXECUTIVE_SUMMARY_ERROR,
  POST_UNPUBLISH_REPORT,
  POST_UNPUBLISH_REPORT_SUCCESS,
  POST_UNPUBLISH_REPORT_ERROR,
  POST_PREVIEW_REPORT,
  POST_PREVIEW_REPORT_SUCCESS,
  POST_PREVIEW_REPORT_ERROR,
  GET_FINANCING_OPTIONS_PREVIEW,
  GET_FINANCING_OPTIONS_PREVIEW_SUCCESS,
  GET_FINANCING_OPTIONS_PREVIEW_ERROR,
  GET_REPAYMENT_PREVIEW_DATA,
  GET_REPAYMENT_PREVIEW_DATA_SUCCESS,
  GET_REPAYMENT_PREVIEW_DATA_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  auditReport: [],
  executiveSummary: [],
  unPublishReport: null,
  isUnpublishSuccess: false,
  isUnpublishError: false,
  isPreviewReportSuccess: false,
  isFailedPreviewReport: false,
  previewReport: null,
  financingPreview: null,
  isSuccessFinancingPreview: false,
  repaymentPreviewData: null,
  isSuccessRepaymentPreviewData: false,
};

const Report = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIT_REPORT:
      state = {
        ...state,
        loading: false,
        auditReport: [],
        error: null,
        isUnpublishSuccess: false,
      };
      break;
    case GET_AUDIT_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditReport: action.payload,
        error: null,
        isUnpublishSuccess: false,
      };
      break;
    case GET_AUDIT_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
        auditReport: [],
        error: action.payload,
        isUnpublishSuccess: false,
      };
      break;
    case GET_EXECUTIVE_SUMMARY:
      state = {
        ...state,
        loading: false,
        executiveSummary: [],
        error: null,
      };
      break;
    case GET_EXECUTIVE_SUMMARY_SUCCESS:
      state = {
        ...state,
        loading: false,
        executiveSummary: action.payload,
        error: null,
      };
      break;
    case GET_EXECUTIVE_SUMMARY_ERROR:
      state = {
        ...state,
        loading: false,
        executiveSummary: [],
        error: action.payload,
      };
      break;
    case POST_UNPUBLISH_REPORT:
      state = {
        ...state,
        loading: false,
        isUnpublishSuccess: false,
        error: null,
        isUnpublishError: false,
        unPublishReport: null,
      };
      break;
    case POST_UNPUBLISH_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUnpublishSuccess: true,
        error: null,
        isUnpublishError: false,
        unPublishReport: action.payload,
      };
      break;
    case POST_UNPUBLISH_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
        isUnpublishSuccess: false,
        error: action.payload,
        isUnpublishError: true,
        unPublishReport: null,
      };
      break;
    case POST_PREVIEW_REPORT:
      state = {
        ...state,
        loading: false,
        isPreviewReportSuccess: false,
        error: null,
        isFailedPreviewReport: false,
        previewReport: null,
      };
      break;
    case POST_PREVIEW_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isPreviewReportSuccess: true,
        error: null,
        isFailedPreviewReport: false,
        previewReport: action.payload,
      };
      break;
    case POST_PREVIEW_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
        isPreviewReportSuccess: false,
        error: action.payload,
        isFailedPreviewReport: true,
        previewReport: null,
      };
      break;
    case GET_FINANCING_OPTIONS_PREVIEW:
      state = {
        ...state,
        loading: false,
        financingPreview: null,
        isSuccessFinancingPreview: false,
      };
      break;
    case GET_FINANCING_OPTIONS_PREVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        financingPreview: action.payload,
        isSuccessFinancingPreview: true,
      };
      break;
    case GET_FINANCING_OPTIONS_PREVIEW_ERROR:
      state = {
        ...state,
        loading: false,
        financingPreview: null,
        isSuccessFinancingPreview: false,
      };
      break;
    case GET_REPAYMENT_PREVIEW_DATA:
      state = {
        ...state,
        loading: false,
        repaymentPreviewData: null,
        isSuccessRepaymentPreviewData: false,
      };
      break;
    case GET_REPAYMENT_PREVIEW_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        repaymentPreviewData: action.payload,
        isSuccessRepaymentPreviewData: false,
      };
      break;
    case GET_REPAYMENT_PREVIEW_DATA_ERROR:
      state = {
        ...state,
        loading: false,
        repaymentPreviewData: null,
        isSuccessRepaymentPreviewData: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Report;
