import React, { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import InfoTooltip from "../../../components/common/tooltip";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import RichTextEditor from "../../../components/common/richTextEditor";
import { getAuditReportDetail } from "../../../store/Report/Audit/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as helper from "../Report/helpers/helper";

const NextSteps = (props) => {
  const {
    editorState,
    setEditorState,
    aReportId,
    textRequired,
    reportSelect,
    fieldsDisabled,
    setFieldsDisabled,
  } = props;

  let id = aReportId;

  useEffect(() => {
    if (id !== helper.ZEROID) {
      if (props.auditReport && reportSelect) {
        if (
          props.auditReport.data &&
          props.auditReport.data.nextSteps.details !== null
        ) {
          setEditorState(
            props.auditReport
              ? EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(
                      props.auditReport.data &&
                        props.auditReport.data.nextSteps.details
                    )
                  )
                )
              : EditorState.createEmpty()
          );
        }
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [props.auditReport, reportSelect]);

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Form.Group className="mb-3 row mt-1" controlId="formAuditFields">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Details &nbsp;
            <InfoTooltip text={"Details"} id="auditAccount" />
          </Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>Text</Form.Label>
          <div className="sort-form-wrapper">
            <div className="form-group sort-form-group">
              <RichTextEditor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                className={`sort-form-input form-control ${
                  textRequired ? "errorField" : ""
                }`}
                fieldsDisabled={fieldsDisabled}
              />
            </div>
            <span
              className={`inline-form-error ${
                textRequired ? "display-error" : ""
              }`}
            >
              Please enter content to the Next Steps section.
            </span>
          </div>
        </Col>
      </Form.Group>
      <hr />
    </div>
  );
};

NextSteps.propTypes = {
  getAuditReportDetail: PropTypes.func,
  auditReport: PropTypes.any,
  auditAccountDetails: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getAuditReportDetail: state.Report.getAuditReportDetail,
    auditReport: state.Report.auditReport,
    auditAccountDetails: state.Audit.auditAccountDetails,
    error: state.Report.error,
  };
};

export default connect(mapStateToProps, {
  getAuditReportDetail,
})(NextSteps);
