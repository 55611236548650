import { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

import CarbonMentorCall from "./carbonMentor";
import BaselineReports from "./baseLineReports";
import BuildingAudits from "./buildingAudits";
import ProjectFinance from "./projectFinance";
import ProjectDelivery from "./projectDelivery";
import StaffEngagment from "./staffEngagement";
import StepsMenu from "./stepsMenu";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import {
  getGPDMentorDetailCalls,
  getGPDBaseLineReports,
  getGPDBuildingAudits,
  getGPDProjectFinancing,
  getGPDProjectDelivery,
  getGPDStaffEngagement,
  getBusinessAccounts,
} from "../../../store/Dashboard/GP/staff/actions";
import { selectedReport } from "../../../store/Client/Report/actions";
import collapse from "../../../assets/images/icons/gp2-icons/collapse.svg";
import { getDecodeAuthDetails } from "../../../helpers";
import { resetEPC } from "../../../store/EPC/actions";
import {
  getClientDashboard,
} from "../../../store/Dashboard/Client/actions";

function GPClientfDashboard(props) {
  let navigate = useNavigate();
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;
  let decoded = getDecodeAuthDetails();

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [carbonMentorExpanded, setCarbonMentorExpanded] = useState(false);
  const [reportsExpanded, setReportsExpanded] = useState(false);
  const [onePanelExpanded, setOnePanelExpanded] = useState(false);
  const [expandInfo, setExpandInfo] = useState(true);
  const [businessAccountName, setBusinessAccountName] = useState(''); 

  const [carbonMentorCallsTooltip, setCarbonMentorCallsTooltip] = useState("");
  const [baselineReportTooltip, setBaselineReportTooltip] = useState("");
  const [buildingAuditsTooltip, setBuildingAuditsTooltip] = useState("");
  const [projectFinancingTooltip, setProjectFinancingTooltip] = useState("");
  const [projectDeliveryTooltip, setProjectDeliveryTooltip] = useState("");
  const [stakeholderEngageTooltip, setStakeholderEngageTooltip] = useState("");

  useEffect(() => {
    if (!props.isSuccessMentorDetailCalls) {
      props.getBusinessAccounts(decoded.ClientBussinessAccountId);
      props.getGPDMentorDetailCalls(decoded.ClientBussinessAccountId);
      props.getGPDBaseLineReports(decoded.ClientBussinessAccountId);
      props.getGPDBuildingAudits(decoded.ClientBussinessAccountId);
      props.getGPDProjectFinancing(decoded.ClientBussinessAccountId);
      props.getGPDProjectDelivery(decoded.ClientBussinessAccountId);
      props.getGPDStaffEngagement(decoded.ClientBussinessAccountId);
      props.getClientDashboard({
        isAllCompanies: false,
        companyId: decoded.ClientBussinessAccountId,
      })
    }
    props.resetEPC();
  }, []);

  useEffect(() => {
    if (carbonMentorExpanded || reportsExpanded) {
      setOnePanelExpanded(true);
    }
    if (
      (carbonMentorExpanded && reportsExpanded) ||
      (!carbonMentorExpanded && !reportsExpanded)
    ) {
      setOnePanelExpanded(false);
    }
  }, [carbonMentorExpanded, reportsExpanded]);

  useEffect(() => {
    if (props.clientDashboard && props.clientDashboard.data) {
      setCarbonMentorCallsTooltip(props.clientDashboard.data.defaultClientSettings.CarbonMentorCallsTooltip);
      setBaselineReportTooltip(props.clientDashboard.data.defaultClientSettings.BaselineReportsTooltip);
      setBuildingAuditsTooltip(props.clientDashboard.data.defaultClientSettings.BuildingAuditsTooltip);
      setProjectFinancingTooltip(props.clientDashboard.data.defaultClientSettings.ProjectFinancingTooltip);
      setProjectDeliveryTooltip(props.clientDashboard.data.defaultClientSettings.ProjectDeliveryTooltip);
      setStakeholderEngageTooltip(props.clientDashboard.data.defaultClientSettings.StakeholderEngageTooltip);
    }
  }, [props.clientDashboard]);

  const navigateToOpportunityPage = () => {
    navigate("/client-report");
  };

  const navigateToClientReportPage = (baName, dashboardType) => {
    setBusinessAccountName(baName);
    navigate('/client-report', { state: { businessAccountName: baName, dashboardType: dashboardType } }); 
  };

  const navigateToProjectsPage = (id) => {
    navigate(`/client-project/${id}`);
  };

  const handleExpandInfo = (v) => {
    setExpandInfo(v);
  };

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Dashboard"}</title>
        </Helmet>
        <Row className="dashboard">
          <Col xl={12} className="left-panel ml-0 mt-0">
            <StepsMenu 
              carbonMentorCallsTooltip={carbonMentorCallsTooltip}
              baselineReportsTooltip={baselineReportTooltip}
              buildingAuditsTooltip={buildingAuditsTooltip}
              projectFinancingTooltip={projectFinancingTooltip}
              projectDeliveryTooltip={projectDeliveryTooltip}
              stakeholderEngageTooltip={stakeholderEngageTooltip}
            />
            {/* <Row className="m-0">
              <Col>
                <div className="client-info-section">
                  <div
                    className={`client-info-span ${
                      expandInfo ? "increaseHeight" : ""
                    }`}
                    onClick={() => {
                      handleExpandInfo(true);
                    }}
                  >
                    <ol>
                      <li>
                        <span>Carbon Mentor Calls:</span> Track completed calls.
                        Expand for call recordings.
                      </li>
                      <li>
                        <span>Baseline Reports:</span> Monitor completed reports
                        and carbon emissions. Access full reports.
                      </li>
                      <li>
                        <span>Building Audits:</span> See audit totals and
                        investment opportunities with savings info.
                      </li>
                      <li>
                        <span>Project Financing:</span> View investment-grade
                        proposals for opportunities.
                      </li>
                      <li>
                        <span>Project Delivery:</span> Check completed
                        opportunities.
                      </li>
                      <li>
                        <span>Staff/Stakeholder Engagement:</span> Track Carbon
                        Literacy and savings from purchases.
                      </li>
                    </ol>
                    Explore for insights on sustainability and savings!
                  </div>
                  <Row>
                    {expandInfo && (
                      <Col className="text-right">
                        <Button
                          className="panel-op"
                          color="link"
                          onClick={() => {
                            handleExpandInfo(false);
                          }}
                        >
                          <img src={collapse} alt="collapse" />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row> */}
            {props.mentorDetailCalls ||
            props.baselineReports ||
            props.buildingAudits ||
            props.projectFinancing ||
            props.projectDelivery ? (
              <>
                <Row className="m-0">
                  <CarbonMentorCall
                    setCarbonMentorExpanded={setCarbonMentorExpanded}
                    onePanelExpanded={onePanelExpanded}
                    data={
                      props.mentorDetailCalls && props.mentorDetailCalls.data
                    }
                    expandPermission={decoded.IsMentorCallWidgetEnabled}
                    carbonMentorExpanded={carbonMentorExpanded}
                    reportsExpanded={reportsExpanded}
                    type={"client"}
                  />
                  <BaselineReports
                    setReportsExpanded={setReportsExpanded}
                    onePanelExpanded={onePanelExpanded}
                    data={props.baselineReports && props.baselineReports.data}
                    expandPermission={decoded.IsBaselineReportWidgetEnabled}
                    carbonMentorExpanded={carbonMentorExpanded}
                    reportsExpanded={reportsExpanded}
                    type={"client"}
                  />
                </Row>
                <BuildingAudits
                  data={props.buildingAudits && props.buildingAudits.data}
                  navigateToOpportunityPage={navigateToClientReportPage}
                  expandPermission={decoded.IsBuildingAuditWidgetEnabled}
                  type={"client"}
                  onePanelExpanded={true}
                />
                <ProjectFinance
                  data={props.projectFinancing && props.projectFinancing.data}
                  navigateToOpportunityPage={navigateToClientReportPage}
                  expandPermission={decoded.IsInvestmentReadyWidgetEnabled}
                  type={"client"}
                  onePanelExpanded={true}
                />
                <ProjectDelivery
                  data={props.projectDelivery && props.projectDelivery.data}
                  navigateToOpportunityPage={navigateToProjectsPage}
                  expandPermission={decoded.IsDeliveredProjectWidgetEnabled}
                  type={"client"}
                  onePanelExpanded={true}
                />
                <StaffEngagment
                  data={props.staffEngagement && props.staffEngagement.data}
                />
              </>
            ) : (
              <h4 className="pt-3 ps-3">Please wait. Dashboard loading..</h4>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

GPClientfDashboard.propTypes = {
  getGPDMentorDetailCalls: PropTypes.func,
  mentorDetailCalls: PropTypes.any,
  isSuccessMentorDetailCalls: PropTypes.any,
  getGPDBaseLineReports: PropTypes.func,
  baselineReports: PropTypes.any,
  getGPDBuildingAudits: PropTypes.func,
  buildingAudits: PropTypes.any,
  getGPDProjectFinancing: PropTypes.func,
  projectFinancing: PropTypes.any,
  getGPDProjectDelivery: PropTypes.func,
  projectDelivery: PropTypes.any,
  getGPDStaffEngagement: PropTypes.func,
  staffEngagement: PropTypes.any,
  selectedReport: PropTypes.func,
  getBusinessAccounts: PropTypes.func,
  businessAccounts: PropTypes.any,
  resetEPC: PropTypes.func,
  clientDashboard: PropTypes.any,
  getClientDashboard: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getGPDMentorDetailCalls: state.Dashboard.getGPDMentorDetailCalls,
    mentorDetailCalls: state.GPDashboard.mentorDetailCalls,
    isSuccessMentorDetailCalls: state.GPDashboard.isSuccessMentorDetailCalls,
    getGPDBaseLineReports: state.Dashboard.getGPDBaseLineReports,
    baselineReports: state.GPDashboard.baselineReports,
    getGPDBuildingAudits: state.Dashboard.getGPDBuildingAudits,
    buildingAudits: state.GPDashboard.buildingAudits,
    getGPDProjectFinancing: state.Dashboard.getGPDProjectFinancing,
    projectFinancing: state.GPDashboard.projectFinancing,
    getGPDProjectDelivery: state.Dashboard.getGPDProjectDelivery,
    projectDelivery: state.GPDashboard.projectDelivery,
    getGPDStaffEngagement: state.Dashboard.getGPDStaffEngagement,
    staffEngagement: state.GPDashboard.staffEngagement,
    selectedReport: state.ClientReport.selectedReport,
    getBusinessAccounts: state.GPDashboard.getBusinessAccounts,
    businessAccounts: state.GPDashboard.businessAccounts,
    resetEPC: state.EPCDetails.resetEPC,
    getClientDashboard: state.ClientDashboard.getClientDashboard,
    clientDashboard: state.ClientDashboard.clientDashboard,
  };
};

export default connect(mapStateToProps, {
  getGPDMentorDetailCalls,
  getGPDBaseLineReports,
  getGPDBuildingAudits,
  getGPDProjectFinancing,
  getGPDProjectDelivery,
  getGPDStaffEngagement,
  selectedReport,
  getBusinessAccounts,
  resetEPC,
  getClientDashboard
})(GPClientfDashboard);
