import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "reactstrap";
import { Row, Button, Card } from "react-bootstrap";
import DashBoardAudit from "../../../assets/images/icons/DashBoardAudit.svg";
import moment from "moment";
import Edit from "../../../assets/images/icons/Edit.svg";
import { getAdminAnalystDashboard } from "../../../store/Dashboard/Admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loader from "../../../components/common/Loader";
import { getDecodeAuthDetails } from "../../../helpers";
import { getGlobalSettings } from "../../../store/Settings/actions";
import ProjectDashboard from "./projectDashboard";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import CustomReactTable from "../../../components/common/reactTable";

const AdminAnalystDashboard = (props) => {
  let navigate = useNavigate();
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  const handleOnIdle = (event) => {
    window.location.href = "/login";
    window.location.reload();
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [audits, setAudits] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [data, setData] = useState(() => [...audits]);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    let decodedUserData = getDecodeAuthDetails();
    if (decodedUserData.RoleName) {
      props.getGlobalSettings();
      props.getAdminAnalystDashboard();
    }
    if (decodedUserData.Permission) {
      setPermissions(decodedUserData.Permission);
    }
  }, []);

  useEffect(() => {
    if (props.adminAnalystDashboard) {
      setAudits(
        props.adminAnalystDashboard.data &&
          props.adminAnalystDashboard.data.auditDashboard
      );
      if (props.adminAnalystDashboard.data) {
        setData(() => [...props.adminAnalystDashboard.data.auditDashboard]);
      }
    }
  }, [props.adminAnalystDashboard]);

  const backViewAccouts = () => {
    navigate("/audit-account/view");
  };

  const backViewProjects = () => {
    navigate("/project");
  };

  const getRowData = (row) => {
    showEditPage(row);
  };

  const showEditPage = (row) => {
    navigate("/audit-account/update/" + row, {state: columns[8].accessorKey});
  };

  const showProjectEditPage = (row) => {
    navigate("/project/" + row);
  };

  const formatDIsplayDate = (cell) => {
    if (cell !== null) {
      let date = moment(cell).toISOString().slice(0, 10);
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "-";
    }
  };

  const columns = [
    {
      header: "Audit No.",
      accessorKey: "auditNumber",
      sortingFn: "alphanumeric",
    },
    {
      header: "Audit Name",
      accessorKey: "name",
      sortingFn: "alphanumeric",
      cell: (info) => (
        <span
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        >
          {info.renderValue()}
        </span>
      ),
    },
    {
      header: "Client",
      accessorKey: "clientBusinessAccountName",
      sortingFn: "alphanumeric",
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      cell: (info) => formatDIsplayDate(info.renderValue()),
    },
    {
      header: "End Date",
      accessorKey: "endDate",
      cell: (info) => formatDIsplayDate(info.renderValue()),
    },
    {
      header: "Site",
      accessorKey: "site",
      sortingFn: "alphanumeric",
    },
    {
      header: "Auditor",
      accessorKey: "auditorsNames",
      sortingFn: "alphanumeric",
    },
    {
      header: "Analyst",
      accessorKey: "analystsNames",
      sortingFn: "alphanumeric",
    },
    {
      header: "",
      accessorKey: "edit",
      enableSorting: false,
      cell: (info) => (
        <img
          className="edit-img"
          src={Edit}
          alt=""
          width={40}
          height={40}
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Audit Summary"}</title>
        </Helmet>
        {permissions.includes("PERMISSIONS.DASHBOARD.VIEW") ? (
          <Container fluid>
            <hr />
            <Row className="m-0">
              <ProjectDashboard
                backViewProjects={backViewProjects}
                showProjectEditPage={showProjectEditPage}
                permissions={permissions}
              />
              <br />
              <Card className="dashboard-card mt-5 p-0">
                <Card.Header className="report-header-audit">
                  <img
                    src={DashBoardAudit}
                    alt="audit"
                    width={20}
                    height={20}
                  />
                  &nbsp;&nbsp;
                  <span className="dashboard-heading">Recent Audits</span>
                  <span style={{ float: "right" }}>
                    <Button
                      className="audit-sort-btn-link"
                      onClick={() => backViewAccouts()}
                    >
                      View Audits
                    </Button>
                  </span>
                </Card.Header>
              </Card>
              <br />
              <div className="audit-table p-0">
                {audits ? (
                  <CustomReactTable
                    table={table}
                    tableClass={"table-nw-audit-table"}
                  />
                ) : (
                  <Loader data={"page-spinner"} />
                )}
              </div>
            </Row>
          </Container>
        ) : (
          <Row className="permission-denied-page m-0">
            <Card className="no-permission-card">
              You don't have permission to access this content. Please contact
              the Admin.
            </Card>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

AdminAnalystDashboard.propTypes = {
  getAdminAnalystDashboard: PropTypes.func,
  adminAnalystDashboard: PropTypes.any,
  getGlobalSettings: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getAdminAnalystDashboard: state.Dashboard.getAdminAnalystDashboard,
    adminAnalystDashboard: state.Dashboard.adminAnalystDashboard,
    getGlobalSettings: state.Settings.getGlobalSettings,
  };
};

export default connect(mapStateToProps, {
  getAdminAnalystDashboard,
  getGlobalSettings,
})(AdminAnalystDashboard);
