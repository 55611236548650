import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const HideTooltips = (props) => {
  const { text, id } = props;

  return (
    <React.Fragment>
      <OverlayTrigger
        style={{ background: "white" }}
        key={""}
        placement={"right"}
        overlay={
          <Tooltip id="tooltip-view-client" className="client-tooltip">
            {text}
          </Tooltip>
        }
      >
        <span>...</span>
      </OverlayTrigger>
    </React.Fragment>
  );
};

export default HideTooltips;
