import ViewUserRole from "../pages/UserRole/viewUserRole";
import CreateRoleForm from "../pages/UserRole/createRoleForm";
import StaffUserAccount from "../pages/StaffUserAccount/staffUserAccount";
import ViewStaffUserAccounts from "../pages/StaffUserAccount/viewStaffUserAccounts";
import UpdateStaffUserAccount from "../pages/StaffUserAccount/updateStaffUserAccount";
import Login from "../pages/Authentication";
import CreateClientBusineesAccount from "../pages/ClientBusinessAccount/Create";
import ViewClientBusinessAccounts from "../pages/ClientBusinessAccount/View";
import UpdateClientBusineesAccount from "../pages/ClientBusinessAccount/Update";
import ClientDashboard from "../pages/Dashboard/Client";
import ViewAuditAccount from "../pages/Audits/View";
import CreateAuditAccount from "../pages/Audits/Create";
import UpdateAuditAccount from "../pages/Audits/Update";
import { Redirect } from "react-router-dom";
import AdminAnalystDashboard from "../pages/Dashboard/Staff";
import ReportSettings from "../pages/Settings/ReportSettings";
import GlobalParameters from "../pages/Settings/GlobalParameters";
import ClientReportList from "../pages/Client/Report/clientReportList";
import ClientReportDetails from "../pages/Client/Report/clientReportDetails";
import ViewProjects from "../pages/Projects/View";
import UpdateProject from "../pages/Projects/Update";
import ClientProject from "../pages/Client/Project/View";
import DashboardSettings from "../pages/Settings/DashboardSettings";
import FinanceSettings from "../pages/Settings/FinanceSettings";
import CarbonRoadMap from "../pages/Client/CarbonRoadMap";
import CarbonRoadMapSettings from "../pages/Settings/CarbonRoadMapSettings";
import PermisssionDenied from "../pages/permissionDenied";
import GPStaffDashboard from "../pages/Dashboard/GPDashboard/staffView";
import GPClientfDashboard from "../pages/Dashboard/GPDashboard/clientView";
import EPCSummary from "../pages/EPC";
import ProtectedRoute from "./protectedRoute";

const allRoutes = [
  { path: "/role-management/create", component: CreateRoleForm },
  { path: "/role-management/view", component: ViewUserRole },
  { path: "/role-management/update/:id", component: CreateRoleForm },
  { path: "/account-management/create", component: StaffUserAccount },
  { path: "/account-management/view", component: ViewStaffUserAccounts },
  { path: "/account-management/update/:id", component: UpdateStaffUserAccount },
  {
    path: "/client-account-management/create",
    component: CreateClientBusineesAccount,
  },
  {
    path: "/client-account-management/view",
    component: ViewClientBusinessAccounts,
  },
  {
    path: "/client-account-management/update/:id",
    component: UpdateClientBusineesAccount,
  },
  { path: "/auditSummary", component: AdminAnalystDashboard },
  { path: "/settings/report-settings", component: ReportSettings },
  { path: "/settings/global-parameters", component: GlobalParameters },
  { path: "/settings/dashboard-settings", component: DashboardSettings },
  { path: "/settings/finance-settings", component: FinanceSettings },
  {
    path: "/settings/carbon-roadmap-settings",
    component: CarbonRoadMapSettings,
  },
  { path: "/audit-account/view", component: ViewAuditAccount },
  { path: "/audit-account/create", component: CreateAuditAccount },
  { path: "/audit-account/update/:id", component: UpdateAuditAccount },
  { path: "/client-dashboard", component: GPClientfDashboard },
  { path: "/client-audit-summary", component: ClientDashboard },
  { path: "/client-report", component: ClientReportList },
  { path: "/client-report/:id", component: ClientReportDetails },
  { path: "/client-project/:id", component: ClientProject },
  { path: "/client-project", component: ClientProject },
  { path: "/client-carbon-roadmap", component: CarbonRoadMap },
  { path: "/project", component: ViewProjects },
  { path: "/project/list/:id", component: ViewProjects},
  { path: "/project/:id", component: UpdateProject },
  { path: "/permisssion-denied", component: PermisssionDenied },
  { path: "/myDashboard", component: GPStaffDashboard },
  { path: "/epcSummary", component: EPCSummary },
  { path: "/", exact: true, component: ProtectedRoute },
];
const authRoutes = [{ path: "/login", component: Login }];

export { allRoutes, authRoutes };
