import React, { useEffect, useState } from "react";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Edit from "../../../../../assets/images/icons/Edit.svg";
import {
  getFinanceSettings,
  removeCategorySection,
} from "../../../../../store/Settings/actions";
import PropTypes from "prop-types";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import CreateTransaction from "../Create";
import UpdateTransaction from "../Update";
import RemoveFileConfirmation from "../../subComponents/removeFileModal";
import { XCircle } from "react-feather";
import * as helper from "../../helpers/helper";
import { numberFormatToWhole } from "../../../../../helpers";
import CustomReactTable from "../../../../../components/common/reactTable";

const ViewTransaction = (props) => {
  const {
    showAlert,
    setShowAlert,
    categorySuccess,
    setCategorySuccess,
    errorCategory,
    setError,
    categoryError,
    setCategoryError,
    updateCategoryError,
    setUpdateCategoryError,
    updateCategorySuccess,
    setUpdateCategorySuccess,
    setShowDeleteCategorySectionSuccess,
    setShowDeleteCategorySectionFailed,
    setDeleteAlertMessageCategory,
  } = props;

  const [selectedRowToUpdate, setSelectedRowToUpdate] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [selectedCategorySection, setSelectedCategorySection] = useState();
  const [showRemoveCategorySection, setShowRemoveCategorySection] =
    useState(false);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    if (categorySuccess) {
      props.getFinanceSettings();
    }
  }, [categorySuccess]);

  useEffect(() => {
    if (updateCategorySuccess) {
      props.getFinanceSettings();
    }
  }, [updateCategorySuccess]);

  useEffect(() => {
    if (props.financeSettings) {
      setCategoryDetails(
        props.financeSettings.data &&
          props.financeSettings.data.viewFinancingOptionCategory
      );
    }
  }, [props.financeSettings]);

  const openRemoveCategorySection = (id) => {
    setShowRemoveCategorySection(true);
    setSelectedCategorySection(id);
  };

  const handleCloseRemoveCategorySection = () => {
    setShowRemoveCategorySection(false);
  };

  const removeCategorySection = () => {
    props.removeCategorySection(selectedCategorySection);
    setDeleteAlertMessageCategory(true);
  };

  useEffect(() => {
    if (props.isSuccessCategorySectionDelete) {
      props.getFinanceSettings();
      setShowRemoveCategorySection(false);
      setShowDeleteCategorySectionSuccess(true);
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setShowDeleteCategorySectionSuccess(false);
      }, 3000);
    }
  }, [props.isSuccessCategorySectionDelete]);

  useEffect(() => {
    if (props.isFaildCategorySectionDelete) {
      window.scrollTo(0, 0);
      setShowDeleteCategorySectionFailed(true);
      setShowRemoveCategorySection(false);
      window.setTimeout(() => {
        setShowDeleteCategorySectionFailed(false);
      }, 3000);
    }
  }, [props.isFaildCategorySectionDelete]);

  window.setTimeout(() => {
    setDeleteAlertMessageCategory(false);
  }, 5000);

  const CaptionElementCategory = () => (
    <p className="table-caption finance-table-header m-0">
      Transaction size adjustment categories
    </p>
  );

  const handleShowEdit = (index) => {
    setSelectedRowToUpdate(index);
    setShowEdit(true);
  };

  const handleOpenCategory = () => {
    setShowCreateCategory(true);
  };

  const editColumn = (row) => {
    return (
      <img
        className="edit-img"
        src={Edit}
        alt=""
        width={40}
        height={40}
        onClick={() => handleShowEdit(row.id)}
      />
    );
  };

  const deleteColumn = (row) => {
    return (
      <XCircle
        size={25}
        color="#77BF4B"
        onClick={() => openRemoveCategorySection(row.id)}
      />
    );
  };

  const minFormatter = (row) => {
    return (
      <span>
        {row.min
          .toFixed(helper.DECIMAL_TWO)
          .replace(helper.STRING_NUMBER_FORMAT, ",")}
      </span>
    );
  };

  const maxFormatter = (row) => {
    return (
      <span>
        {row.max
          .toFixed(helper.DECIMAL_TWO)
          .replace(helper.STRING_NUMBER_FORMAT, ",")}
      </span>
    );
  };

  const upliftFormatter = (row) => {
    return <span>{row.sizeUplift.toFixed(helper.DECIMAL_TWO)}</span>;
  };

  const columns = [
    {
      header: "Category",
      accessorKey: "category",
      sortingFn: "alphanumeric",
    },
    {
      header: "Min £",
      accessorKey: "min",
      cell: (info) => minFormatter(info.row.original),
    },
    {
      header: "Max £",
      accessorKey: "max",
      cell: (info) => maxFormatter(info.row.original),
    },
    {
      header: "Size Uplift (%)",
      accessorKey: "sizeUplift",
      cell: (info) => upliftFormatter(info.row.original),
    },
    {
      header: "",
      accessorKey: "edit",
      enableSorting: false,
      cell: (info) => editColumn(info.row.original),
    },
    {
      header: "",
      accessorKey: "delete",
      enableSorting: false,
      cell: (info) => deleteColumn(info.row.original),
    },
  ];

  const table = useReactTable({
    data: categoryDetails,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <React.Fragment>
      <Row className="mt-4">
        <Col lg={12}>
          <div
            className="table-wrapper-client-account"
            style={{ marginLeft: 10 }}
          >
            <CaptionElementCategory />
            <CustomReactTable
              table={table}
              tableClass={"finance-settings-table"}
            />
            <Row>
              <Col lg={10}></Col>
              <Col lg={2}>
                <Button
                  className="sort-primary-btn"
                  type={"button"}
                  onClick={() => handleOpenCategory()}
                >
                  Create Category
                </Button>
              </Col>
            </Row>
            <CreateTransaction
              showCreateCategory={showCreateCategory}
              setShowCreateCategory={setShowCreateCategory}
              categoryDetails={categoryDetails}
              categorySuccess={categorySuccess}
              setCategorySuccess={setCategorySuccess}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              errorCategory={errorCategory}
              setError={setError}
              categoryError={categoryError}
              setCategoryError={setCategoryError}
            />
            <UpdateTransaction
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              categoryDetails={categoryDetails}
              selectedRowToUpdate={selectedRowToUpdate}
              setSelectedRowToUpdate={setSelectedRowToUpdate}
              updateCategorySuccess={updateCategorySuccess}
              setUpdateCategorySuccess={setUpdateCategorySuccess}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              errorCategory={errorCategory}
              setError={setError}
              updateCategoryError={updateCategoryError}
              setUpdateCategoryError={setUpdateCategoryError}
            />
          </div>
        </Col>
      </Row>
      <RemoveFileConfirmation
        showRemoveSection={showRemoveCategorySection}
        handleCloseRemoveSection={handleCloseRemoveCategorySection}
        removeSection={removeCategorySection}
        modalText="Are you sure you want to delete the category?"
      />
    </React.Fragment>
  );
};

ViewTransaction.propTypes = {
  getFinanceSettings: PropTypes.func,
  financeSettings: PropTypes.any,
  removeCategorySection: PropTypes.func,
  isSuccessCategorySectionDelete: PropTypes.any,
  isFaildCategorySectionDelete: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getFinanceSettings: state.Settings.getFinanceSettings,
    financeSettings: state.Settings.financeSettings,
    removeCategorySection: state.Settings.removeCategorySection,
    isSuccessCategorySectionDelete:
      state.Settings.isSuccessCategorySectionDelete,
    isFaildCategorySectionDelete: state.Settings.isFaildCategorySectionDelete,
  };
};

export default connect(mapStateToProps, {
  getFinanceSettings,
  removeCategorySection,
})(ViewTransaction);
