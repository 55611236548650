import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_CLIENT_DASHBOARD,
  GET_FORECAST_DATA,
  GET_CATEGORY_AND_SUMMARY,
  GET_COMPANIES,
  GET_CLIENT_BUSINESS_ID,
  GET_GP_PARENT_COMPANIES,
} from "./actionTypes";

import {
  getClientrDashboardSuccess,
  getClientDashboardFaild,
  getForecashDataSuccess,
  getForecashDataFaild,
  getCategoryAndSummarySuccess,
  getCategoryAndSummaryFaild,
  getCompaniesSuccess,
  getCompaniesFaild,
  getClientBusinessAccountIDSuccess,
  getClientBusinessAccountIDFaild,
  getGpParentCompaniesSuccess,
  getGpParentCompaniesFaild,
} from "./actions";

import {
  getClientDashboard,
  getForecastCacheGraphData,
  getCategorySummary,
  getClientDashboardCompanies,
  getClientBusinessAccID,
  getGpParentCompanies,
} from "../../../helpers/api";

function* getClientDashboardData({ payload: { parm } }) {
  try {
    const response = yield call(getClientDashboard, parm);

    if (response.statusCode === 200) {
      yield put(getClientrDashboardSuccess(response.data));
    } else {
      yield put(getClientDashboardFaild(response.data));
    }
  } catch (error) {
    yield put(getClientDashboardFaild(error));
  }
}

function* getForecastChachFlowData({ payload: { year, company } }) {
  try {
    const response = yield call(getForecastCacheGraphData, year, company);

    if (response.statusCode === 200) {
      yield put(getForecashDataSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getForecashDataFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getForecashDataFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(getForecashDataFaild(error));
  }
}

function* getInitialCategoryAndSummary() {
  try {
    const response = yield call(getCategorySummary);

    if (response.statusCode === 200) {
      yield put(getCategoryAndSummarySuccess(response.data));
    } else {
      yield put(
        getCategoryAndSummaryFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(getCategoryAndSummaryFaild(error));
  }
}

function* getClientCompanies() {
  try {
    const response = yield call(getClientDashboardCompanies);

    if (response.statusCode === 200) {
      yield put(getCompaniesSuccess(response.data));
    } else {
      yield put(getCompaniesFaild(response.responseException.exceptionMessage));
    }
  } catch (error) {
    yield put(getCompaniesFaild(error));
  }
}

function* getGPParentCompaniesFunc() {
  try {
    const response = yield call(getGpParentCompanies);

    if (response.statusCode === 200) {
      yield put(getGpParentCompaniesSuccess(response.data.result));
    } else {
      yield put(
        getGpParentCompaniesFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(getGpParentCompaniesFaild(error));
  }
}

export function* watchDashboard() {
  yield takeEvery(GET_CLIENT_DASHBOARD, getClientDashboardData);
  yield takeEvery(GET_FORECAST_DATA, getForecastChachFlowData);
  yield takeEvery(GET_CATEGORY_AND_SUMMARY, getInitialCategoryAndSummary);
  yield takeEvery(GET_COMPANIES, getClientCompanies);
  yield takeEvery(GET_GP_PARENT_COMPANIES, getGPParentCompaniesFunc);
}

function* ClientDashboardSaga() {
  yield all([fork(watchDashboard)]);
}

export default ClientDashboardSaga;
