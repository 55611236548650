import {
  GET_AUDIT_REPORT,
  GET_AUDIT_REPORT_SUCCESS,
  GET_AUDIT_REPORT_ERROR,
  GET_EXECUTIVE_SUMMARY,
  GET_EXECUTIVE_SUMMARY_SUCCESS,
  GET_EXECUTIVE_SUMMARY_ERROR,
  POST_UNPUBLISH_REPORT,
  POST_UNPUBLISH_REPORT_SUCCESS,
  POST_UNPUBLISH_REPORT_ERROR,
  POST_PREVIEW_REPORT,
  POST_PREVIEW_REPORT_SUCCESS,
  POST_PREVIEW_REPORT_ERROR,
  GET_FINANCING_OPTIONS_PREVIEW,
  GET_FINANCING_OPTIONS_PREVIEW_SUCCESS,
  GET_FINANCING_OPTIONS_PREVIEW_ERROR,
  GET_REPAYMENT_PREVIEW_DATA,
  GET_REPAYMENT_PREVIEW_DATA_SUCCESS,
  GET_REPAYMENT_PREVIEW_DATA_ERROR,
} from "./actionTypes";

export const getAuditReportDetail = (id) => {
  return {
    type: GET_AUDIT_REPORT,
    payload: { id },
  };
};
export const getAuditReportDetailSuccess = (data) => {
  return {
    type: GET_AUDIT_REPORT_SUCCESS,
    payload: { data },
  };
};
export const getAuditReportDetailFaild = (error) => {
  return {
    type: GET_AUDIT_REPORT_ERROR,
    payload: { error },
  };
};
export const getExecutiveSummaries = () => {
  return {
    type: GET_EXECUTIVE_SUMMARY,
    payload: {},
  };
};
export const getExecutiveSummariesSuccess = (data) => {
  return {
    type: GET_EXECUTIVE_SUMMARY_SUCCESS,
    payload: { data },
  };
};
export const getExecutiveSummariesFaild = (error) => {
  return {
    type: GET_EXECUTIVE_SUMMARY_ERROR,
    payload: { error },
  };
};
export const postReportUnpublish = (id) => {
  return {
    type: POST_UNPUBLISH_REPORT,
    payload: { id },
  };
};
export const postReportUnpublishSuccess = (data) => {
  return {
    type: POST_UNPUBLISH_REPORT_SUCCESS,
    payload: { data },
  };
};
export const postReportUnpublishError = (error) => {
  return {
    type: POST_UNPUBLISH_REPORT_ERROR,
    payload: { error },
  };
};

export const postPreviewReport = (data) => {
  return {
    type: POST_PREVIEW_REPORT,
    payload: { data },
  };
};
export const postPreviewReportSuccess = (data) => {
  return {
    type: POST_PREVIEW_REPORT_SUCCESS,
    payload: { data },
  };
};
export const postPreviewReportError = (error) => {
  return {
    type: POST_PREVIEW_REPORT_ERROR,
    payload: { error },
  };
};
export const getFinancingOptionsPreview = (parm) => {
  return {
    type: GET_FINANCING_OPTIONS_PREVIEW,
    payload: { parm },
  };
};
export const getFinancingOptionsPreviewSuccess = (data) => {
  return {
    type: GET_FINANCING_OPTIONS_PREVIEW_SUCCESS,
    payload: { data },
  };
};
export const getFinancingOptionsPreviewFaild = (error) => {
  return {
    type: GET_FINANCING_OPTIONS_PREVIEW_ERROR,
    payload: { error },
  };
};
export const getRepaymentPreviewData = () => {
  return {
    type: GET_REPAYMENT_PREVIEW_DATA,
    payload: {},
  };
};
export const getRepaymentPreviewDataSuccess = (data) => {
  return {
    type: GET_REPAYMENT_PREVIEW_DATA_SUCCESS,
    payload: { data },
  };
};
export const getRepaymentPreviewDataFaild = (error) => {
  return {
    type: GET_REPAYMENT_PREVIEW_DATA_ERROR,
    payload: { error },
  };
};
