import axios from "axios";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const sliceUploadFiles = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const downloadExtrnal = (attachmentId, type, fname, id) => {
  axios({
    url:
      API_URL +
      "/clientbusinessaccount" +
      `/${id}` +
      `/download?attachmentId=` +
      `${attachmentId}`,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      let url = URL.createObjectURL(new Blob([response.data], { type: type }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => {});
};

const createRequest = (
  id,
  batch,
  setAttachmentFormData,
  authUser,
  uploadFilesError,
  setResponseArray1
) =>
  new Promise((resolve, reject) => {
    console.log("first upload api call", new Date().toUTCString());
    fetch(API_URL + "/clientBusinessAccount/" + id + "/upload", {
      method: "POST",
      body: setAttachmentFormData(batch, id),
      headers: {
        Authorization: "Bearer " + authUser.accessToken,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        console.log("upload api response", new Date().toUTCString());
        if (data.isError) {
          uploadFilesError(data.responseException.exceptionMessage.fieldErrors);
        } else {
          console.log("upload api success response", new Date().toUTCString());
          let tempArray = data.data;
          setResponseArray1(tempArray);
        }
        resolve();
      })
      .catch((err) => {
        console.log("err", JSON.stringify(err));
        reject();
      });
  });

export { sliceUploadFiles, downloadExtrnal, createRequest };
