import React from "react";
import { Row, Col } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import carbonMentor from "../../../assets/images/icons/1-carbon-mentor.svg";
import baselineReport from "../../../assets/images/icons/2-baseline-report.svg";
import buildingAudits from "../../../assets/images/icons/3-building-audits.svg";
import projectFinancing from "../../../assets/images/icons/4-project-financing.svg";
import projectDelivery from "../../../assets/images/icons/5-project-delivery.svg";
import staffEngagement from "../../../assets/images/icons/6-staff-engagement.svg";

const StepsMenu = (props) => {
  const {
    carbonMentorCallsTooltip,
    baselineReportsTooltip,
    buildingAuditsTooltip,
    projectFinancingTooltip,
    projectDeliveryTooltip,
    stakeholderEngageTooltip,
  } = props;

  const steps = [
    { icon: carbonMentor, label: "Carbon Mentor Calls", description: carbonMentorCallsTooltip },
    { icon: baselineReport, label: "Base Line Reports", description: baselineReportsTooltip },
    { icon: buildingAudits, label: "Building Audits", description: buildingAuditsTooltip},
    { icon: projectFinancing, label: "Project Financing", description: projectFinancingTooltip},
    { icon: projectDelivery, label: "Project Delivery", description: projectDeliveryTooltip},
    { icon: staffEngagement, label: "Staff / Stakeholder Engagement", description: stakeholderEngageTooltip},
  ];

  const colStyle = {
    cursor: "pointer",
  };

  return (
    <div>
      <Row className="m-0">
        <Col className="p-3">
          <div id="steps-menu">
            <Row md={6}>
              {steps.map((step, index) => (
                <Col className="mb-2 text-center" key={index} style={colStyle}>
                  <OverlayTrigger
                    key={index}
                    placement="top"
                    overlay={<Tooltip id={`tooltip-${index}`}>{step.description}</Tooltip>}
                  >
                    <div className="icon-steps">
                      <img className="icon-steps" src={step.icon} alt={`icon-${index}`} />
                    </div>
                  </OverlayTrigger>
                  <div>{index + 1}</div>
                  <span>{step.label}</span>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StepsMenu;
