import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { Download, XCircle } from "react-feather";
import { downloadExtrnal } from "../helpers";
import DocumentIcon from "../../../../assets/images/icons/DocumentIcon.svg";

const ThumbnailCard = (props) => {
  const { slicedArrayDoc, uploadedDocObj, handleRemove } = props;

  return (
    <React.Fragment>
      <div>
        <Row className="mt-2 m-0">
          {slicedArrayDoc.length > 0 &&
            slicedArrayDoc.map((docData, i) => (
              <Col lg={4} key={i}>
                <Card className="card-doc grid-view mt-2">
                  <div className="p-3" style={{ textAlign: "right" }}>
                    {docData.id ? (
                      <span
                        onClick={() =>
                          downloadExtrnal(
                            docData.id,
                            docData.mimeType,
                            docData.name
                          )
                        }
                      >
                        <Download size={25} color="#77BF4B" />
                      </span>
                    ) : (
                      <a href={docData.file} download={docData.name}>
                        <Download size={25} color="#77BF4B" />
                      </a>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {docData.id ? (
                      <XCircle
                        size={25}
                        color="#77BF4B"
                        onClick={() =>
                          handleRemove(uploadedDocObj, docData.name, docData.id)
                        }
                      />
                    ) : (
                      <XCircle
                        size={25}
                        color="#77BF4B"
                        onClick={() =>
                          handleRemove(uploadedDocObj, docData.name, null)
                        }
                      />
                    )}
                  </div>

                  <Card.Body className="card-body-doc text-center">
                    <Card.Img
                      className="card-image-doc"
                      variant="top"
                      src={DocumentIcon}
                    />
                  </Card.Body>
                  <Card.Footer>{docData.name}</Card.Footer>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    </React.Fragment>
  );
};
export default ThumbnailCard;
