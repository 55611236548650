import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { START_AUTHENTICATION } from "./actionTypes";
import { AuthenticationSuccess, AuthenticationError } from "./actions";
import { postAuthData } from "../../../helpers/api";
import jwt_decode from "jwt-decode";

function* loginFun({ payload: { userData } }) {
  try {
    const response = yield call(postAuthData, userData);
    if (response.statusCode === 200) {
      yield put(AuthenticationSuccess(response));
      localStorage.setItem("authUser", JSON.stringify(response.data.token));
      let decodedData = jwt_decode(response.data.token.accessToken);
      if (decodedData.RoleName) {
        window.location.href = "/myDashboard";
      } else {
        window.location.href = "/client-dashboard";
      }
    } else if (response.statusCode === 400 && response.responseException.exceptionMessage.fieldErrors) {
      yield put(
          AuthenticationError(
            response.responseException.exceptionMessage.fieldErrors
          )
        );
    }else{
      yield put(
          AuthenticationError(
            response.responseException
          )
        );
    }
  } catch (error) {
    yield put(
      AuthenticationError(error)
    );
  }
}

export function* watchAccount() {
  yield takeEvery(START_AUTHENTICATION, loginFun);
}

function* AccountSaga() {
  yield all([fork(watchAccount)]);
}

export default AccountSaga;
