export const GET_MENTOR_DETAIL_CALLS = "GET_MENTOR_DETAIL_CALLS";
export const GET_MENTOR_DETAIL_CALLS_SUCCESS =
  "GET_MENTOR_DETAIL_CALLS_SUCCESS";
export const GET_MENTOR_DETAIL_CALLS_ERROR = "GET_MENTOR_DETAIL_CALLS_ERROR";
export const GET_BASE_LINE_REPORTS = "GET_BASE_LINE_REPORTS";
export const GET_BASE_LINE_REPORTS_SUCCESS = "GET_BASE_LINE_REPORTS_SUCCESS";
export const GET_BASE_LINE_REPORTS_ERROR = "GET_BASE_LINE_REPORTS_ERROR";
export const GET_BUILDING_AUDITS = "GET_BUILDING_AUDITS";
export const GET_BUILDING_AUDITS_SUCCESS = "GET_BUILDING_AUDITS_SUCCESS";
export const GET_BUILDING_AUDITS_ERROR = "GET_BUILDING_AUDITS_ERROR";
export const GET_PROJECT_FINANCING = "GET_PROJECT_FINANCING";
export const GET_PROJECT_FINANCING_SUCCESS = "GET_PROJECT_FINANCING_SUCCESS";
export const GET_PROJECT_FINANCING_ERROR = "GET_PROJECT_FINANCING_ERROR";
export const GET_PROJECT_DELIVERY = "GET_PROJECT_DELIVERY";
export const GET_PROJECT_DELIVERY_SUCCESS = "GET_PROJECT_DELIVERY_SUCCESS";
export const GET_PROJECT_DELIVERY_ERROR = "GET_PROJECT_DELIVERY_ERROR";
export const GET_STAFF_ENGAGEMENT = "GET_STAFF_ENGAGEMENT";
export const GET_STAFF_ENGAGEMENT_SUCCESS = "GET_STAFF_ENGAGEMENT_SUCCESS";
export const GET_STAFF_ENGAGEMENT_ERROR = "GET_STAFF_ENGAGEMENT_ERROR";
export const GET_BUSINESS_ACCOUNTS = "GET_BUSINESS_ACCOUNTS";
export const GET_BUSINESS_ACCOUNTS_SUCCESS = "GET_BUSINESS_ACCOUNTS_SUCCESS";
export const GET_BUSINESS_ACCOUNTS_ERROR = "GET_BUSINESS_ACCOUNTS_ERROR";
export const POST_BUILDING_AUDITS = "POST_BUILDING_AUDITS";
export const POST_BUILDING_AUDITS_SUCCESS = "POST_BUILDING_AUDITS_SUCCESS";
export const POST_BUILDING_AUDITS_ERROR = "POST_BUILDING_AUDITS_ERROR";
export const POST_PROJECT_FINANCING = "POST_PROJECT_FINANCING";
export const POST_PROJECT_FINANCING_SUCCESS = "POST_PROJECT_FINANCING_SUCCESS";
export const POST_PROJECT_FINANCING_ERROR = "POST_PROJECT_FINANCING_ERROR";
export const POST_PROJECT_DELIVERY = "POST_PROJECT_DELIVERY";
export const POST_PROJECT_DELIVERY_SUCCESS = "POST_PROJECT_DELIVERY_SUCCESS";
export const POST_PROJECT_DELIVERY_ERROR = "POST_PROJECT_DELIVERY_ERROR";
export const POST_BASELINE_REPORT = "POST_BASELINE_REPORT";
export const POST_BASELINE_REPORT_SUCCESS = "POST_BASELINE_REPORT_SUCCESS";
export const POST_BASELINE_REPORT_ERROR = "POST_BASELINE_REPORT_ERROR";
export const POST_CARBON_MENTOR_CALLS = "POST_CARBON_MENTOR_CALLS";
export const POST_CARBON_MENTOR_CALLS_SUCCESS =
  "POST_CARBON_MENTOR_CALLS_SUCCESS";
export const POST_CARBON_MENTOR_CALLS_ERROR = "POST_CARBON_MENTOR_CALLS_ERROR";
export const CLEAR_SEARCH_FIELD_MENTOR_CALLS =
  "CLEAR_SEARCH_FIELD_MENTOR_CALLS";
export const CLEAR_SEARCH_FIELD_BASELINE = "CLEAR_SEARCH_FIELD_BASELINE";
export const CLEAR_SEARCH_FIELD_BUILDING = "CLEAR_SEARCH_FIELD_BUILDING";
export const CLEAR_SEARCH_FIELD_INVESTMENT = "CLEAR_SEARCH_FIELD_INVESTMENT";
export const CLEAR_SEARCH_FIELD_COMPLETED = "CLEAR_SEARCH_FIELD_COMPLETED";
export const GET_EPC_BUSINESS_ACCOUNTS = "GET_EPC_BUSINESS_ACCOUNTS";
export const GET_EPC_BUSINESS_ACCOUNTS_SUCCESS =
  "GET_EPC_BUSINESS_ACCOUNTS_SUCCESS";
export const GET_EPC_BUSINESS_ACCOUNTS_ERROR =
  "GET_EPC_BUSINESS_ACCOUNTS_ERROR";
export const GET_SAS_TOKEN = "GET_SAS_TOKEN";
export const GET_SAS_TOKEN_SUCCESS = "GET_SAS_TOKEN_SUCCESS";
export const GET_SAS_TOKEN_FAILED = "GET_SAS_TOKEN_FAILED";
