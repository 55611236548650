import {
  PROJECT_ACTIVATE,
  PROJECT_ACTIVATE_SUCCESS,
  PROJECT_ACTIVATE_ERROR,
  VIEW_CLIENT_PROJECT,
  VIEW_CLIENT_PROJECT_SUCCESS,
  VIEW_CLIENT_PROJECT_ERROR,
} from "./actionTypes";

export const projectActivate = (parm) => {
  return {
    type: PROJECT_ACTIVATE,
    payload: { parm },
  };
};
export const projectActivateSuccess = (data) => {
  return {
    type: PROJECT_ACTIVATE_SUCCESS,
    payload: { data },
  };
};
export const projectActivateFaild = (error) => {
  return {
    type: PROJECT_ACTIVATE_ERROR,
    payload: { error },
  };
};
export const viewProjectClient = (parm) => {
  return {
    type: VIEW_CLIENT_PROJECT,
    payload: { parm },
  };
};
export const viewProjectClientSuccess = (data) => {
  return {
    type: VIEW_CLIENT_PROJECT_SUCCESS,
    payload: { data },
  };
};
export const viewProjectClientFaild = (error) => {
  return {
    type: VIEW_CLIENT_PROJECT_ERROR,
    payload: { error },
  };
};
