import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";

const RemoveFileConfirmation = (props) => {
  const { showConfirmation, handleCloseConfirmation, removeFile, modalText, permissions } = props
  return (
    <Modal show={showConfirmation} centered className="confirmation-modal">
      <Modal.Header className="update-header">
        <Row>
          <Col lg={12}>
            <Modal.Title style={{ textAlign: "center", marginLeft: 30 }}>
              Confirmation
            </Modal.Title>
            <span className="close-icon" onClick={handleCloseConfirmation}>
              x
            </span>
            </Col>
        </Row>
      </Modal.Header>
      {permissions && permissions.includes("PERMISSIONS.PROJECT.ATTACHMENTDELETE") ? (
        <Modal.Body>
        <Row>
          <p className="update-modal-body">
            {modalText}
          </p>
        </Row>
        <Row>
          <Col lg={12} className={"text-center"}>
            <Button
              className="sort-primary-btn btn-min-width"
              style={{ margin: "0 10px" }}
              type={"button"}
              onClick={() => removeFile()}
            >
              Yes
            </Button>
            <Button
              className="sort-primary-outline-btn btn-min-width"
              onClick={handleCloseConfirmation}
            >
              No
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      ):(
        <Modal.Body>
          <Row>
          <p className="update-modal-body">
            You don't have permission to perform this action. Please contact the Admin.
          </p>
        </Row>
        </Modal.Body>
      )}
      
    </Modal>
  )

};
export default RemoveFileConfirmation;