import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Right from "../../../../assets/images/icons/Right.svg";
import Wrong from "../../../../assets/images/icons/Wrong.svg";

const SaveMessage = (props) => {
  const {
    saveReportSuccess,
    saveReportError,
    publishReportSuccess,
    publishReportError,
    unPublishReportSuccess,
    unPublishReportError,
    isShowCancelConfirmation,
    handleCloseConfirmation,
  } = props;

  return (
    <React.Fragment>
      <Modal
        show={isShowCancelConfirmation}
        centered
        className="confirmation-modal"
      >
        <Modal.Header className="update-header">
              <Modal.Title className="save-modal-title">
                {(saveReportSuccess || saveReportError) && (
                  <div>Save as Draft</div>
                )}
                {(publishReportSuccess || publishReportError) && (
                  <div>Publish Report</div>
                )}
                {(unPublishReportSuccess || unPublishReportError) && (
                  <div>Unpublish Report</div>
                )}
              </Modal.Title>
              <span className="close-icon" onClick={handleCloseConfirmation}>
                x
              </span>
        </Modal.Header>
        <Modal.Body style={{ marginTop: 30, marginBottom: 30 }}>
          <Row>
            <Col sm={5} md={5} lg={5}></Col>
            <Col sm={2} md={2} lg={2}>
              <Row>
                {(saveReportSuccess ||
                  publishReportSuccess ||
                  unPublishReportSuccess) && (
                  <img src={Right} alt="success" width={30} height={30}/>
                )}
                {(saveReportError ||
                  publishReportError ||
                  unPublishReportError) && (
                  <img src={Wrong} alt="unSuccess" width={30} height={30}/>
                )}
              </Row>
            </Col>

            <Col sm={5} md={5} lg={5}></Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            {saveReportSuccess && (
              <p className="update-modal-body">
                Audit Report saved as a draft successfully
              </p>
            )}
            {saveReportError && (
              <p className="update-modal-body">
                Audit Report could not be saved as a draft
              </p>
            )}
            {publishReportSuccess && (
              <p className="update-modal-body">
                Audit report published successfully
              </p>
            )}
            {publishReportError && (
              <p className="update-modal-body">
                Audit report could not be published
              </p>
            )}
            {unPublishReportSuccess && (
              <p className="update-modal-body">
                Audit report unpublished successfully
              </p>
            )}
            {unPublishReportError && (
              <p className="update-modal-body">
                Audit report could not be unpublished
              </p>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default SaveMessage;
