import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_BENCH_MARK_VALUES,
  GET_BUILDING_TYPES,
  GET_CLIENT_BUSINESS_ACCOUNT_DETAILS,
  GET_PARENT_COMPANIES,
  POST_CLIENT_BUSINESS_ACCOUNT_CREATE,
  UPDATE_CLIENT_BUSINESS_ACCOUNT,
  VIEW_CLIENT_BUSINESS_ACCOUNT,
  DELETE_ATTACHMENT_CARBON,
} from "./actionTypes";
import {
  getBuildingTypesSuccess,
  getBuildingTypesError,
  getParentCompaniesSuccess,
  getParentCompaniesError,
  getBenchMarkSuccess,
  getBenchMarkError,
  postClientBusinessAccountSuccess,
  postClientBusinessAccountError,
  viewClientBusinessAccountsError,
  viewClientBusinessAccountsSuccess,
  getClientBusinessAccountDetailsSuccess,
  getClientBusinessAccountDetailsError,
  updateClientBusinessAccountSuccess,
  updateClientBusinessAccountError,
  deleteAttachmentCarbonSuccess,
  deleteAttachmentCarbonFaild,
} from "./actions";
import {
  getBuildingTypes,
  getParentCompanies,
  getBenchmarkvalues,
  postClientBusinessAccount,
  getClientBusinessAccounts,
  getClientAccountDetails,
  updateClientBusiness,
  carbonDeleteAttachment,
} from "../../../helpers/api";

function* getBuildingTypeFunc() {
  try {
    const response = yield call(getBuildingTypes);
    if (response.statusCode === 200) {
      yield put(getBuildingTypesSuccess(response));
    } else {
      yield put(
        getBuildingTypesError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      getBuildingTypesError(error)
    );
  }
}

function* getParentCompaniesFunc({ payload: { parm } }) {
  try {
    const response = yield call(getParentCompanies, parm);
    if (response.data.statusCode === 200) {
      yield put(getParentCompaniesSuccess(response.data));
    } else {
      yield put(
        getParentCompaniesError(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    }
  } catch (error) {
    yield put(
      getParentCompaniesError(error)
    );
  }
}

function* getBenchmarkValueFunc() {
  try {
    const response = yield call(getBenchmarkvalues);
    if (response.statusCode === 200) {
      yield put(getBenchMarkSuccess(response));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getBenchMarkError(
          response.responseException.exceptionMessage
        )
      );
    } else {
      put(getBenchMarkError(response.responseException.exceptionMessage));
    }
  } catch (error) {
    yield put(getBenchMarkError(error));
  }
}

function* postCreateClientBusinessAccount({ payload: { data } }) {
  try {
    const response = yield call(postClientBusinessAccount, data);
    if (response.statusCode === 200) {
      yield put(postClientBusinessAccountSuccess(response));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        postClientBusinessAccountError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(
        postClientBusinessAccountError(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      postClientBusinessAccountError(
        error
      )
    );
  }
}

function* getClientBusinessAccountFun({ payload: { parm } }) {
  try {
    const response = yield call(getClientBusinessAccounts, parm);
    if (response.statusCode === 200) {
      yield put(viewClientBusinessAccountsSuccess(response.data));
    } else {
      yield put(
        viewClientBusinessAccountsError(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    }
  } catch (error) {
    yield put(
      viewClientBusinessAccountsError(
        error
      )
    );
  }
}

function* getClientBusinessAccountDetails({ payload: { id } }) {
  try {
    const response = yield call(getClientAccountDetails, id);
    if (response.statusCode === 200) {
      yield put(getClientBusinessAccountDetailsSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      // which field errors need to capture
      yield put(
        getClientBusinessAccountDetailsError(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getClientBusinessAccountDetailsError(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      getClientBusinessAccountDetailsError(
        error
      )
    );
  }
}

function* updateClientBusinessAccountFun({ payload: { data } }) {
  try {
    const response = yield call(updateClientBusiness, data);
    if (response.statusCode === 200) {
      yield put(updateClientBusinessAccountSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        updateClientBusinessAccountError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(
        updateClientBusinessAccountError(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      updateClientBusinessAccountError(
        error
      )
    );
  }
}

function* removeAttachmentCarbonFunc({ payload: { id, attachmentId } }) {
  try {
    const response = yield call(carbonDeleteAttachment, id, attachmentId);
    if (
      response.statusCode === 200 &&
      response.message === "Attachment deleted successfully"
    ) {
      yield put(deleteAttachmentCarbonSuccess(response.data));
    } else {
      yield put(deleteAttachmentCarbonFaild(response.message));
    }
  } catch (error) {
    yield put(
      deleteAttachmentCarbonFaild(error)
    );
  }
}

export function* watchClient() {
  yield takeEvery(GET_BUILDING_TYPES, getBuildingTypeFunc);
  yield takeEvery(GET_PARENT_COMPANIES, getParentCompaniesFunc);
  yield takeEvery(GET_BENCH_MARK_VALUES, getBenchmarkValueFunc);
  yield takeEvery(
    POST_CLIENT_BUSINESS_ACCOUNT_CREATE,
    postCreateClientBusinessAccount
  );
  yield takeEvery(VIEW_CLIENT_BUSINESS_ACCOUNT, getClientBusinessAccountFun);
  yield takeEvery(
    GET_CLIENT_BUSINESS_ACCOUNT_DETAILS,
    getClientBusinessAccountDetails
  );
  yield takeEvery(
    UPDATE_CLIENT_BUSINESS_ACCOUNT,
    updateClientBusinessAccountFun
  );
  yield takeEvery(DELETE_ATTACHMENT_CARBON, removeAttachmentCarbonFunc);
}

function* ClientSaga() {
  yield all([fork(watchClient)]);
}

export default ClientSaga;
