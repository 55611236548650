import {
  GET_ADMIN_ANALYST_DASHBOARD,
  GET_ADMIN_ANALYST_DASHBOARD_SUCCESS,
  GET_ADMIN_ANALYST_DASHBOARD_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  adminAnalystDashboard: [],
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_ANALYST_DASHBOARD:
      state = {
        ...state,
        loading: false,
        adminAnalystDashboard: [],
        error: null,
      };
      break;
    case GET_ADMIN_ANALYST_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        adminAnalystDashboard: action.payload,
        error: null,
      };
      break;
    case GET_ADMIN_ANALYST_DASHBOARD_ERROR:
      state = {
        ...state,
        loading: false,
        adminAnalystDashboard: [],
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Dashboard;
