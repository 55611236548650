import {
  GET_MENTOR_DETAIL_CALLS,
  GET_MENTOR_DETAIL_CALLS_SUCCESS,
  GET_MENTOR_DETAIL_CALLS_ERROR,
  GET_BASE_LINE_REPORTS,
  GET_BASE_LINE_REPORTS_SUCCESS,
  GET_BASE_LINE_REPORTS_ERROR,
  GET_BUILDING_AUDITS,
  GET_BUILDING_AUDITS_SUCCESS,
  GET_BUILDING_AUDITS_ERROR,
  GET_PROJECT_FINANCING,
  GET_PROJECT_FINANCING_SUCCESS,
  GET_PROJECT_FINANCING_ERROR,
  GET_PROJECT_DELIVERY,
  GET_PROJECT_DELIVERY_SUCCESS,
  GET_PROJECT_DELIVERY_ERROR,
  GET_STAFF_ENGAGEMENT,
  GET_STAFF_ENGAGEMENT_SUCCESS,
  GET_STAFF_ENGAGEMENT_ERROR,
  GET_BUSINESS_ACCOUNTS,
  GET_BUSINESS_ACCOUNTS_SUCCESS,
  GET_BUSINESS_ACCOUNTS_ERROR,
  POST_BUILDING_AUDITS,
  POST_BUILDING_AUDITS_SUCCESS,
  POST_BUILDING_AUDITS_ERROR,
  POST_PROJECT_FINANCING,
  POST_PROJECT_FINANCING_SUCCESS,
  POST_PROJECT_FINANCING_ERROR,
  POST_PROJECT_DELIVERY,
  POST_PROJECT_DELIVERY_SUCCESS,
  POST_PROJECT_DELIVERY_ERROR,
  POST_BASELINE_REPORT,
  POST_BASELINE_REPORT_SUCCESS,
  POST_BASELINE_REPORT_ERROR,
  POST_CARBON_MENTOR_CALLS,
  POST_CARBON_MENTOR_CALLS_SUCCESS,
  POST_CARBON_MENTOR_CALLS_ERROR,
  CLEAR_SEARCH_FIELD_MENTOR_CALLS,
  CLEAR_SEARCH_FIELD_BUILDING,
  CLEAR_SEARCH_FIELD_INVESTMENT,
  CLEAR_SEARCH_FIELD_BASELINE,
  CLEAR_SEARCH_FIELD_COMPLETED,
  GET_EPC_BUSINESS_ACCOUNTS,
  GET_EPC_BUSINESS_ACCOUNTS_SUCCESS,
  GET_EPC_BUSINESS_ACCOUNTS_ERROR,
  GET_SAS_TOKEN,
  GET_SAS_TOKEN_SUCCESS,
  GET_SAS_TOKEN_FAILED,
} from "./actionTypes";

export const getGPDMentorDetailCalls = (id) => {
  return {
    type: GET_MENTOR_DETAIL_CALLS,
    payload: { id },
  };
};
export const getGPDMentorDetailCallsSuccess = (data) => {
  return {
    type: GET_MENTOR_DETAIL_CALLS_SUCCESS,
    payload: { data },
  };
};
export const getGPDMentorDetailCallsFaild = (error) => {
  return {
    type: GET_MENTOR_DETAIL_CALLS_ERROR,
    payload: { error },
  };
};
export const getGPDBaseLineReports = (id) => {
  return {
    type: GET_BASE_LINE_REPORTS,
    payload: { id },
  };
};
export const getGPDBaseLineReportsSuccess = (data) => {
  return {
    type: GET_BASE_LINE_REPORTS_SUCCESS,
    payload: { data },
  };
};
export const getGPDBaseLineReportsFaild = (error) => {
  return {
    type: GET_BASE_LINE_REPORTS_ERROR,
    payload: { error },
  };
};
export const getGPDBuildingAudits = (id) => {
  return {
    type: GET_BUILDING_AUDITS,
    payload: { id },
  };
};
export const getGPDBuildingAuditsSuccess = (data) => {
  return {
    type: GET_BUILDING_AUDITS_SUCCESS,
    payload: { data },
  };
};
export const getGPDBuildingAuditsFaild = (error) => {
  return {
    type: GET_BUILDING_AUDITS_ERROR,
    payload: { error },
  };
};
export const getGPDProjectFinancing = (id) => {
  return {
    type: GET_PROJECT_FINANCING,
    payload: { id },
  };
};
export const getGPDProjectFinancingSuccess = (data) => {
  return {
    type: GET_PROJECT_FINANCING_SUCCESS,
    payload: { data },
  };
};
export const getGPDProjectFinancingFaild = (error) => {
  return {
    type: GET_PROJECT_FINANCING_ERROR,
    payload: { error },
  };
};
export const getGPDProjectDelivery = (id) => {
  return {
    type: GET_PROJECT_DELIVERY,
    payload: { id },
  };
};
export const getGPDProjectDeliverySuccess = (data) => {
  return {
    type: GET_PROJECT_DELIVERY_SUCCESS,
    payload: { data },
  };
};
export const getGPDProjectDeliveryFaild = (error) => {
  return {
    type: GET_PROJECT_DELIVERY_ERROR,
    payload: { error },
  };
};
export const getGPDStaffEngagement = (id) => {
  return {
    type: GET_STAFF_ENGAGEMENT,
    payload: { id },
  };
};
export const getGPDStaffEngagementSuccess = (data) => {
  return {
    type: GET_STAFF_ENGAGEMENT_SUCCESS,
    payload: { data },
  };
};
export const getGPDStaffEngagementFaild = (error) => {
  return {
    type: GET_STAFF_ENGAGEMENT_ERROR,
    payload: { error },
  };
};
export const getBusinessAccounts = (id) => {
  return {
    type: GET_BUSINESS_ACCOUNTS,
    payload: { id },
  };
};
export const getBusinessAccountsSuccess = (data) => {
  return {
    type: GET_BUSINESS_ACCOUNTS_SUCCESS,
    payload: { data },
  };
};
export const getBusinessAccountsFaild = (error) => {
  return {
    type: GET_BUSINESS_ACCOUNTS_ERROR,
    payload: { error },
  };
};
export const searchBuildingAudits = (param) => {
  return {
    type: POST_BUILDING_AUDITS,
    payload: { param },
  };
};
export const searchBuildingAuditsSuccess = (data) => {
  return {
    type: POST_BUILDING_AUDITS_SUCCESS,
    payload: { data },
  };
};
export const searchBuildingAuditsFaild = (error) => {
  return {
    type: POST_BUILDING_AUDITS_ERROR,
    payload: { error },
  };
};
export const searchProjectFinacing = (param) => {
  return {
    type: POST_PROJECT_FINANCING,
    payload: { param },
  };
};
export const searchProjectFinacingSuccess = (data) => {
  return {
    type: POST_PROJECT_FINANCING_SUCCESS,
    payload: { data },
  };
};
export const searchProjectFinacingFaild = (error) => {
  return {
    type: POST_PROJECT_FINANCING_ERROR,
    payload: { error },
  };
};
export const searchProjectDelivery = (param) => {
  return {
    type: POST_PROJECT_DELIVERY,
    payload: { param },
  };
};
export const searchProjectDeliverySuccess = (data) => {
  return {
    type: POST_PROJECT_DELIVERY_SUCCESS,
    payload: { data },
  };
};
export const searchProjectDeliveryFaild = (error) => {
  return {
    type: POST_PROJECT_DELIVERY_ERROR,
    payload: { error },
  };
};
export const searchBaselineReports = (param) => {
  return {
    type: POST_BASELINE_REPORT,
    payload: { param },
  };
};
export const searchBaselineReportSuccess = (data) => {
  return {
    type: POST_BASELINE_REPORT_SUCCESS,
    payload: { data },
  };
};
export const searchBaselineReportFaild = (error) => {
  return {
    type: POST_BASELINE_REPORT_ERROR,
    payload: { error },
  };
};
export const searchCarbonMentorCalls = (param) => {
  return {
    type: POST_CARBON_MENTOR_CALLS,
    payload: { param },
  };
};
export const searchCarbonMentorCallsSuccess = (data) => {
  return {
    type: POST_CARBON_MENTOR_CALLS_SUCCESS,
    payload: { data },
  };
};
export const searchCarbonMentorCallsFaild = (error) => {
  return {
    type: POST_CARBON_MENTOR_CALLS_ERROR,
    payload: { error },
  };
};
export const clearSearchFieldMentorCall = () => {
  return {
    type: CLEAR_SEARCH_FIELD_MENTOR_CALLS,
    payload: {},
  };
};
export const clearSearchFieldBuildingAudit = () => {
  return {
    type: CLEAR_SEARCH_FIELD_BUILDING,
    payload: {},
  };
};
export const clearSearchFieldInvestment = () => {
  return {
    type: CLEAR_SEARCH_FIELD_INVESTMENT,
    payload: {},
  };
};
export const clearSearchFieldBaseline = () => {
  return {
    type: CLEAR_SEARCH_FIELD_BASELINE,
    payload: {},
  };
};
export const clearSearchFieldCompleted = () => {
  return {
    type: CLEAR_SEARCH_FIELD_COMPLETED,
    payload: {},
  };
};
export const getEPCBusinessAccounts = (id) => {
  return {
    type: GET_EPC_BUSINESS_ACCOUNTS,
    payload: { id },
  };
};
export const getEPCBusinessAccountsSuccess = (data) => {
  return {
    type: GET_EPC_BUSINESS_ACCOUNTS_SUCCESS,
    payload: { data },
  };
};
export const getEPCBusinessAccountsFaild = (error) => {
  return {
    type: GET_EPC_BUSINESS_ACCOUNTS_ERROR,
    payload: { error },
  };
};
export const getSASToken = () => {
  return {
    type: GET_SAS_TOKEN,
  };
};
export const getSASTokenSuccess = (data) => {
  return {
    type: GET_SAS_TOKEN_SUCCESS,
    payload: { data },
  };
};
export const getSASTokenFaild = (error) => {
  return {
    type: GET_SAS_TOKEN_FAILED,
    payload: { error },
  };
};
