import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { Alert } from "reactstrap";
import InfoTooltip from "../../../components/common/tooltip";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import NumberFormat from "react-number-format";
import InlineErroMsg from "../../../components/common/inlineErrorMsg";
import {
  getGlobalSettings,
  postGlobalOpportunity,
} from "../../../store/Settings/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { getLoggedUser, getDecodeAuthDetails } from "../../../helpers";
import UpdateFileModal from "./subComponents/updateFileModal";
import { useIdleTimer } from "react-idle-timer";

const GlobalParameters = (props) => {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  const handleOnIdle = (event) => {
    window.location.href = "/login";
    window.location.reload();
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [id, setId] = useState("");
  const [energyToCarbon, setEnergyToCarbon] = useState("");
  const [waterToCarbon, setWaterToCarbon] = useState("");
  const [carbonToNoOfHours, setCarbonToNoOfHours] = useState("");
  const [carbonToNoOfNights, setCarbonToNoOfNights] = useState("");
  const [averageTravelling, setAverageTravelling] = useState("");
  const [annualEnergyCost, setAnnualEnergyCost] = useState("");
  const [adjustedInvestmentRate, setAdjustedInvestmentRate] = useState("");

  const [energyToCarbonOld, setEnergyToCarbonOld] = useState("");
  const [waterToCarbonOld, setWaterToCarbonOld] = useState("");
  const [othersToCarbonOld, setOthersToCarbonOld] = useState("");
  const [carbonToNoOfHoursOld, setCarbonToNoOfHoursOld] = useState("");
  const [carbonToNoOfNightsOld, setCarbonToNoOfNightsOld] = useState("");
  const [averageTravellingOld, setAverageTravellingOld] = useState("");
  const [annualEnergyCostOld, setAnnualEnergyCostOld] = useState("");
  const [adjustedInvestmentRateOld, setAdjustedInvestmentRateOld] =
    useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [carbonFootPrintTitle, setCarbonFootPrintTitle] = useState("");
  const [carbonFootNote, setCarbonFootNote] = useState("");
  const [forecastCashFlow, setForecastCashFlow] = useState("");
  const [costInaction, setCostInaction] = useState("");

  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [untappedSection, setUntappedSection] = useState("");
  const [untappedSubSection, setUntappedSubSection] = useState("");
  const [potentialIOSubSection, setPotentialIOSubSection] = useState("");
  const [energySubSection, setEnergySubSection] = useState("");
  const [waterSubSection, setWaterSubSection] = useState("");
  const [carbonFootPrint, setCarbonFootPrint] = useState("");
  const [leftTitle, setLeftTitle] = useState("");
  const [leftSubTitle, setLeftSubTitle] = useState("");
  const [rightTitle, setRightTitle] = useState("");
  const [rightSubTitle, setRightSubTitle] = useState("");
  const [forecastTitle, setForecastTitle] = useState("");
  const [costInactionTitle, setCostInactionTitle] = useState("");
  const [capitalPlanning, setCapitalPlanning] = useState("");

  const [energyCarbonRequired, setEnergyCarbonRequired] = useState(false);

  const [waterToCarbonRequired, setWaterToCarbonRequired] = useState(false);

  const [carbonToNoOfHoursRequired, setCarbonToNoOfHoursRequired] =
    useState(false);

  const [carbonToNoOfNightsRequired, setCarbonToNoOfNightsRequired] =
    useState(false);

  const [averageTravellingRequired, setAverageTravellingRequired] =
    useState(false);

  const [annualEnergyCostRequired, setAnnualEnergyCostRequired] =
    useState(false);

  const [adjustedInvestmentRateRequired, setAdjustedInvestmentRateRequired] =
    useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitEnable, setIsSubmitEnable] = useState(true);
  const [error, setError] = useState([]);
  const [settingsSuccess, setSettingsSuccess] = useState(false);
  const [opportunitySuccess, setOpportunitySuccess] = useState(false);
  const [settingsError, setSettingsError] = useState(false);
  const [opportunityError, setOpportunityError] = useState(false);

  const [updateConfirmation, setUpdateConfirmation] = useState(false);
  const [updatePublishReport, setUpdatePublishReport] = useState(false);
  const [updateDraftReport, setUpdateDraftReport] = useState(false);
  const [updateCarbonConversion, setUpdateCarbonConversion] = useState(false);

  const [showConfirmationPart, setShowConfirmationPart] = useState(false);

  const [dataOpportunity, setDataOpportunity] = useState([]);

  useEffect(() => {
    if (props.globalParameters === null) {
      props.getGlobalSettings();
    }
  }, []);

  useEffect(() => {
    if (props.opportunityGlobal) {
      setDataOpportunity(
        props.opportunityGlobal ? props.opportunityGlobal.data.data : []
      );
    }
  }, [props.opportunityGlobal]);

  let decodedUserData = getDecodeAuthDetails();
  const permissions = decodedUserData.Permission;

  useEffect(() => {
    if (settingsSuccess || opportunitySuccess) {
      props.getGlobalSettings();
    }
  }, [settingsSuccess, opportunitySuccess]);

  useEffect(() => {
    if (props.globalParameters) {
      let settings = props.globalParameters && props.globalParameters.data;
      let clientDefaultSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.defaultClientSettings;
      let universalDashboardSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.universalSettings;
      let globalSettings =
        settings && props.globalParameters.data.globalSetting;
      let reportSettings =
        settings && props.globalParameters.data.reportSetting;

      setId(settings.id);

      if (universalDashboardSettings.UntappedSavingsTitle !== null) {
        setUntappedSection(universalDashboardSettings.UntappedSavingsTitle);
      }

      setUntappedSubSection(
        universalDashboardSettings.potentialCarbonSavingsTitle
      );
      setPotentialIOSubSection(
        universalDashboardSettings.potentialPAndLImprovementOpportunitiesTitle
      );
      setEnergySubSection(
        universalDashboardSettings.potentialEnergySavingsTitle
      );
      setWaterSubSection(universalDashboardSettings.potentialWaterSavingsTitle);

      if (universalDashboardSettings.CarbonFoodPrintTitle !== null) {
        setCarbonFootPrint(universalDashboardSettings.CarbonFoodPrintTitle);
      }

      if (universalDashboardSettings.CarbonFoodPrintLeftBlockTitle !== null) {
        setLeftTitle(universalDashboardSettings.CarbonFoodPrintLeftBlockTitle);
      }

      if (
        universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle !== null
      ) {
        setLeftSubTitle(
          universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle
        );
      }

      if (universalDashboardSettings.CarbonFoodPrintRightBlockTitle !== null) {
        setRightTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockTitle
        );
      }

      if (
        universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle !== null
      ) {
        setRightSubTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle
        );
      }

      if (universalDashboardSettings.ForcastCashFlowTitle !== null) {
        setForecastTitle(universalDashboardSettings.ForcastCashFlowTitle);
      }

      if (universalDashboardSettings.ProjectedCostofInactionTitle !== null) {
        setCostInactionTitle(
          universalDashboardSettings.ProjectedCostofInactionTitle
        );
      }

      if (universalDashboardSettings.CapitalPlanning !== null) {
        setCapitalPlanning(universalDashboardSettings.CapitalPlanning);
      }

      if (clientDefaultSettings.CarbonFoodPrintDescription !== null) {
        setCarbonFootPrintTitle(
          clientDefaultSettings.CarbonFoodPrintDescription
        );
      }

      if (clientDefaultSettings.CarbonFoodPrintRightBlockNote !== null) {
        setCarbonFootNote(clientDefaultSettings.CarbonFoodPrintRightBlockNote);
      }

      if (clientDefaultSettings.ForcastCashFlowDescription !== null) {
        setForecastCashFlow(clientDefaultSettings.ForcastCashFlowDescription);
      }

      if (clientDefaultSettings.ProjectedCostofInactionDescription !== null) {
        setCostInaction(
          clientDefaultSettings.ProjectedCostofInactionDescription
        );
      }

      if (globalSettings.energyToCarbon % 1 === 0) {
        setEnergyToCarbon(globalSettings.energyToCarbon.toFixed(2));
        setEnergyToCarbonOld(globalSettings.energyToCarbon.toFixed(2));
      } else {
        setEnergyToCarbon(globalSettings.energyToCarbon);
        setEnergyToCarbonOld(globalSettings.energyToCarbon);
      }

      if (globalSettings.waterToCarbon % 1 === 0) {
        setWaterToCarbon(globalSettings.waterToCarbon.toFixed(2));
        setWaterToCarbonOld(globalSettings.waterToCarbon.toFixed(2));
      } else {
        setWaterToCarbon(globalSettings.waterToCarbon);
        setWaterToCarbonOld(globalSettings.waterToCarbon);
      }

      if (globalSettings.carbonToTVHours % 1 === 0) {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours.toFixed(2));
        setCarbonToNoOfHoursOld(globalSettings.carbonToTVHours.toFixed(2));
      } else {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours);
        setCarbonToNoOfHoursOld(globalSettings.carbonToTVHours);
      }

      if (globalSettings.carbonToNoOfNightUKHotel % 1 === 0) {
        setCarbonToNoOfNights(
          globalSettings.carbonToNoOfNightUKHotel.toFixed(2)
        );
        setCarbonToNoOfNightsOld(
          globalSettings.carbonToNoOfNightUKHotel.toFixed(2)
        );
      } else {
        setCarbonToNoOfNights(globalSettings.carbonToNoOfNightUKHotel);
        setCarbonToNoOfNightsOld(globalSettings.carbonToNoOfNightUKHotel);
      }

      if (globalSettings.averageTravelingOnLongHaulFlights % 1 === 0) {
        setAverageTravelling(
          globalSettings.averageTravelingOnLongHaulFlights.toFixed(2)
        );
        setAverageTravellingOld(
          globalSettings.averageTravelingOnLongHaulFlights.toFixed(2)
        );
      } else {
        setAverageTravelling(globalSettings.averageTravelingOnLongHaulFlights);
        setAverageTravellingOld(
          globalSettings.averageTravelingOnLongHaulFlights
        );
      }

      if (globalSettings.annualEnergyCostsIncrease % 1 === 0) {
        setAnnualEnergyCost(
          globalSettings.annualEnergyCostsIncrease.toFixed(2)
        );
        setAnnualEnergyCostOld(
          globalSettings.annualEnergyCostsIncrease.toFixed(2)
        );
      } else {
        setAnnualEnergyCost(globalSettings.annualEnergyCostsIncrease);
        setAnnualEnergyCostOld(globalSettings.annualEnergyCostsIncrease);
      }
      if (reportSettings.aboutSMCC !== null) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(reportSettings.aboutSMCC))
          )
        );
      } else {
        setEditorState(EditorState.createEmpty());
      }

      if (reportSettings.videoTitle !== null) {
        setTitle(reportSettings.videoTitle);
      }

      if (reportSettings.videoDescription !== null) {
        setDescription(reportSettings.videoDescription);
      }

      if (globalSettings.financingBrokerageFee !== null) {
        setAdjustedInvestmentRate(globalSettings.financingBrokerageFee);
        setAdjustedInvestmentRateOld(globalSettings.financingBrokerageFee);
      }

      if (universalDashboardSettings.welcomeMessage !== null) {
        setWelcomeMsg(universalDashboardSettings.welcomeMessage);
      }
    }
  }, [props.globalParameters]);

  const handleEnergyToCarbon = (val) => {
    setEnergyToCarbon(val.formattedValue);
    if (val.value !== "") {
      setEnergyCarbonRequired(false);
    }
  };

  const handleWaterToCarbon = (val) => {
    setWaterToCarbon(val.formattedValue);
    if (val.value !== "") {
      setWaterToCarbonRequired(false);
    }
  };

  const handleCarbonToNoOfHours = (val) => {
    setCarbonToNoOfHours(val.formattedValue);
    if (val.value !== "") {
      setCarbonToNoOfHoursRequired(false);
    }
  };

  const handleCarbonToNoOfNights = (val) => {
    setCarbonToNoOfNights(val.formattedValue);
    if (val.value !== "") {
      setCarbonToNoOfNightsRequired(false);
    }
  };

  const handleAverageTravelling = (val) => {
    setAverageTravelling(val.formattedValue);
    if (val.value !== "") {
      setAverageTravellingRequired(false);
    }
  };

  const handleAnnualEnergyCost = (val) => {
    const { value } = val;

    setAnnualEnergyCost(value);
    if (val.value !== "") {
      setAnnualEnergyCostRequired(false);
    }
  };

  const handleAdjustedInvestmentRate = (val) => {
    const { value } = val;

    setAdjustedInvestmentRate(value);
    if (val.value !== "") {
      setAdjustedInvestmentRateRequired(false);
    }
  };

  const formValidation = () => {
    let errors = [];

    if (energyToCarbon === "") {
      setEnergyCarbonRequired(true);
      errors.push(1);
    } else {
      setEnergyCarbonRequired(false);
    }

    if (waterToCarbon === "") {
      setWaterToCarbonRequired(true);
      errors.push(1);
    } else {
      setWaterToCarbonRequired(false);
    }

    if (carbonToNoOfHours === "") {
      setCarbonToNoOfHoursRequired(true);
      errors.push(1);
    } else {
      setCarbonToNoOfHoursRequired(false);
    }

    if (carbonToNoOfNights === "") {
      setCarbonToNoOfNightsRequired(true);
      errors.push(1);
    } else {
      setCarbonToNoOfNightsRequired(false);
    }

    if (averageTravelling === "") {
      setAverageTravellingRequired(true);
      errors.push(1);
    } else {
      setAverageTravellingRequired(false);
    }

    if (annualEnergyCost === "") {
      setAnnualEnergyCostRequired(true);
      errors.push(1);
    } else {
      setAnnualEnergyCostRequired(false);
    }

    if (adjustedInvestmentRate === "") {
      setAdjustedInvestmentRateRequired(true);
      errors.push(1);
    } else {
      setAdjustedInvestmentRateRequired(false);
    }

    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();
    let valid = formValidation();
    var convertedData = convertToRaw(editorState.getCurrentContent());

    if (valid.length === 0) {
      setIsSubmitEnable(false);
      let form_data = new FormData();
      form_data.append("Id", id);

      form_data.append(
        "DashboardSetting.UniversalSettings.WelcomeMessage",
        welcomeMsg
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.UntappedSavingsTitle",
        untappedSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialCarbonSavingsTitle",
        untappedSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialPAndLImprovementOpportunitiesTitle",
        potentialIOSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialEnergySavingsTitle",
        energySubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialWaterSavingsTitle",
        waterSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintTitle",
        carbonFootPrint
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockTitle",
        leftTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockSubtitle",
        leftSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockTitle",
        rightTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockSubtitle",
        rightSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ForcastCashFlowTitle",
        forecastTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ProjectedCostofInactionTitle",
        costInactionTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CapitalPlanning",
        capitalPlanning
      );

      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintDescription",
        carbonFootPrintTitle
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintRightBlockNote",
        carbonFootNote
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ForcastCashFlowDescription",
        forecastCashFlow
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ProjectedCostofInactionDescription",
        costInaction
      );

      form_data.append("GlobalSetting.EnergyToCarbon", energyToCarbon);
      form_data.append("GlobalSetting.WaterToCarbon", waterToCarbon);
      form_data.append("GlobalSetting.CarbonToTVHours", carbonToNoOfHours);
      form_data.append(
        "GlobalSetting.CarbonToNoOfNightUKHotel",
        carbonToNoOfNights
      );
      form_data.append(
        "GlobalSetting.AverageTravelingOnLongHaulFlights",
        averageTravelling
      );
      form_data.append(
        "GlobalSetting.AnnualEnergyCostsIncrease",
        annualEnergyCost
      );
      form_data.append(
        "GlobalSetting.FinancingBrokerageFee",
        adjustedInvestmentRate
      );

      form_data.append(
        "ReportSetting.AboutSMCC",
        JSON.stringify(convertedData)
      );

      form_data.append("ReportSetting.VideoTitle", title);

      form_data.append("ReportSetting.VideoDescription", description);

      if (
        parseFloat(energyToCarbon) === parseFloat(energyToCarbonOld) &&
        parseFloat(waterToCarbon) === parseFloat(waterToCarbonOld)
      ) {
        setShowAlert(true);
        new Promise((resolve, reject) => {
          fetch(API_URL + "/GlobalSetting", {
            method: "POST",
            headers: {
              Authorization: "Bearer " + getLoggedUser().accessToken,
            },
            body: form_data,
          })
            .then(function (response) {
              return response.json();
            })
            .then((data) => {
              if (data.isError) {
                setSettingsSuccess(false);
                setSettingsError(true);
                let emptyArray = [];
                for (const [key, value] of Object.entries(
                  data.responseException.exceptionMessage.fieldErrors
                )) {
                  emptyArray.push(value[0]);
                }
                setError(emptyArray);
                setIsSubmitEnable(true);
                window.scrollTo(0, 0);
                window.setTimeout(() => {
                  setSettingsError(false);
                  setShowAlert(false);
                }, 5000);
              } else {
                window.scrollTo(0, 0);
                setIsSubmitEnable(true);
                setSettingsSuccess(true);
                setSettingsError(false);
                window.setTimeout(() => {
                  setSettingsSuccess(false);
                  setShowAlert(false);
                }, 5000);
              }
              resolve();
            })
            .catch((err) => {
              console.log("err", JSON.stringify(err));
            });
        });
      } else {
        setShowConfirmationPart(true);
        let globalOppObj = {
          id: id,
          energyToCarbon: parseFloat(energyToCarbon),
          waterToCarbon: parseFloat(waterToCarbon),
        };
        props.postGlobalOpportunity(globalOppObj);
      }
    }
  };

  useEffect(() => {
    if (
      props.isCreateGlobalOpportunitySuccess &&
      props.opportunityGlobal &&
      showConfirmationPart
    ) {
      if (props.opportunityGlobal.data.data.length > 0) {
        setUpdateConfirmation(true);
      } else {
        setUpdateConfirmation(false);
        setShowAlert(true);
        var convertedData = convertToRaw(editorState.getCurrentContent());
        let form_data = new FormData();
        form_data.append("Id", id);

        form_data.append(
          "DashboardSetting.UniversalSettings.UntappedSavingsTitle",
          untappedSection
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.PotentialCarbonSavingsTitle",
          untappedSubSection
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.PotentialPAndLImprovementOpportunitiesTitle",
          potentialIOSubSection
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.PotentialEnergySavingsTitle",
          energySubSection
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.PotentialWaterSavingsTitle",
          waterSubSection
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.CarbonFoodPrintTitle",
          carbonFootPrint
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockTitle",
          leftTitle
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockSubtitle",
          leftSubTitle
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockTitle",
          rightTitle
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockSubtitle",
          rightSubTitle
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.ForcastCashFlowTitle",
          forecastTitle
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.ProjectedCostofInactionTitle",
          costInactionTitle
        );

        form_data.append(
          "DashboardSetting.UniversalSettings.CapitalPlanning",
          capitalPlanning
        );

        form_data.append(
          "DashboardSetting.defaultClientSettings.CarbonFoodPrintDescription",
          carbonFootPrintTitle
        );
        form_data.append(
          "DashboardSetting.defaultClientSettings.CarbonFoodPrintRightBlockNote",
          carbonFootNote
        );
        form_data.append(
          "DashboardSetting.defaultClientSettings.ForcastCashFlowDescription",
          forecastCashFlow
        );
        form_data.append(
          "DashboardSetting.defaultClientSettings.ProjectedCostofInactionDescription",
          costInaction
        );

        form_data.append("GlobalSetting.EnergyToCarbon", energyToCarbon);
        form_data.append("GlobalSetting.WaterToCarbon", waterToCarbon);
        form_data.append("GlobalSetting.CarbonToTVHours", carbonToNoOfHours);
        form_data.append(
          "GlobalSetting.CarbonToNoOfNightUKHotel",
          carbonToNoOfNights
        );
        form_data.append(
          "GlobalSetting.AverageTravelingOnLongHaulFlights",
          averageTravelling
        );
        form_data.append(
          "GlobalSetting.AnnualEnergyCostsIncrease",
          annualEnergyCost
        );
        form_data.append(
          "GlobalSetting.FinancingBrokerageFee",
          adjustedInvestmentRate
        );

        form_data.append(
          "ReportSetting.AboutSMCC",
          JSON.stringify(convertedData)
        );

        form_data.append("ReportSetting.VideoTitle", title);

        form_data.append("ReportSetting.VideoDescription", description);
        new Promise((resolve, reject) => {
          fetch(API_URL + "/GlobalSetting", {
            method: "POST",
            headers: {
              Authorization: "Bearer " + getLoggedUser().accessToken,
            },
            body: form_data,
          })
            .then(function (response) {
              return response.json();
            })
            .then((data) => {
              if (data.isError) {
                setSettingsSuccess(false);
                setSettingsError(true);
                let emptyArray = [];
                for (const [key, value] of Object.entries(
                  data.responseException.exceptionMessage.fieldErrors
                )) {
                  emptyArray.push(value[0]);
                }
                setError(emptyArray);
                setIsSubmitEnable(true);
                window.scrollTo(0, 0);
                window.setTimeout(() => {
                  setSettingsError(false);
                  setShowAlert(false);
                }, 5000);
              } else {
                window.scrollTo(0, 0);
                setIsSubmitEnable(true);
                setSettingsSuccess(true);
                setSettingsError(false);
                window.setTimeout(() => {
                  setSettingsSuccess(false);
                  setShowAlert(false);
                }, 5000);
              }
              resolve();
            })
            .catch((err) => {
              console.log("err", JSON.stringify(err));
            });
        });
      }
    }
  }, [
    props.isCreateGlobalOpportunitySuccess,
    props.opportunityGlobal,
    showConfirmationPart,
  ]);

  useEffect(() => {
    if (props.isFailedCreateGlobalOpportunity) {
      setShowAlert(true);
      setSettingsError(true);
      setIsSubmitEnable(true);
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setSettingsError(false);
        setShowAlert(false);
      }, 5000);
    }
  }, [props.isFailedCreateGlobalOpportunity]);

  const cancelUpdate = () => {
    setUpdateConfirmation(false);
    setIsSubmitEnable(true);
    setEnergyToCarbon(energyToCarbonOld);
    setWaterToCarbon(waterToCarbonOld);
    setCarbonToNoOfHours(carbonToNoOfHoursOld);
    setCarbonToNoOfNights(carbonToNoOfNightsOld);
    setAverageTravelling(averageTravellingOld);
    setAnnualEnergyCost(annualEnergyCostOld);
    setAdjustedInvestmentRate(adjustedInvestmentRateOld);
    setShowConfirmationPart(false);
    setUpdateCarbonConversion(false);
    setUpdateDraftReport(false);
    setUpdatePublishReport(false);
  };

  const updateSubmit = () => {
    var convertedData = convertToRaw(editorState.getCurrentContent());

    let updateOpportunitySelection;
    if (updateCarbonConversion) {
      updateOpportunitySelection = 0;
    } else if (updateDraftReport && !updatePublishReport) {
      updateOpportunitySelection = 1;
    } else if (updatePublishReport && !updateDraftReport) {
      updateOpportunitySelection = 2;
    } else if (updateDraftReport && updatePublishReport) {
      updateOpportunitySelection = 3;
    }

    let form_data = new FormData();
    form_data.append("Id", id);

    form_data.append(
      "DashboardSetting.UniversalSettings.UntappedSavingsTitle",
      untappedSection
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.PotentialCarbonSavingsTitle",
      untappedSubSection
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.PotentialPAndLImprovementOpportunitiesTitle",
      potentialIOSubSection
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.PotentialEnergySavingsTitle",
      energySubSection
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.PotentialWaterSavingsTitle",
      waterSubSection
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.CarbonFoodPrintTitle",
      carbonFootPrint
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockTitle",
      leftTitle
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockSubtitle",
      leftSubTitle
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockTitle",
      rightTitle
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockSubtitle",
      rightSubTitle
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.ForcastCashFlowTitle",
      forecastTitle
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.ProjectedCostofInactionTitle",
      costInactionTitle
    );

    form_data.append(
      "DashboardSetting.UniversalSettings.CapitalPlanning",
      capitalPlanning
    );

    form_data.append(
      "DashboardSetting.defaultClientSettings.CarbonFoodPrintDescription",
      carbonFootPrintTitle
    );
    form_data.append(
      "DashboardSetting.defaultClientSettings.CarbonFoodPrintRightBlockNote",
      carbonFootNote
    );
    form_data.append(
      "DashboardSetting.defaultClientSettings.ForcastCashFlowDescription",
      forecastCashFlow
    );
    form_data.append(
      "DashboardSetting.defaultClientSettings.ProjectedCostofInactionDescription",
      costInaction
    );

    form_data.append("GlobalSetting.EnergyToCarbon", energyToCarbon);
    form_data.append("GlobalSetting.WaterToCarbon", waterToCarbon);
    form_data.append("GlobalSetting.CarbonToTVHours", carbonToNoOfHours);
    form_data.append(
      "GlobalSetting.CarbonToNoOfNightUKHotel",
      carbonToNoOfNights
    );
    form_data.append(
      "GlobalSetting.AverageTravelingOnLongHaulFlights",
      averageTravelling
    );
    form_data.append(
      "GlobalSetting.AnnualEnergyCostsIncrease",
      annualEnergyCost
    );
    form_data.append(
      "GlobalSetting.FinancingBrokerageFee",
      adjustedInvestmentRate
    );
    form_data.append(
      "GlobalSetting.UpdateOpportunitiesSelection",
      updateOpportunitySelection
    );

    form_data.append("ReportSetting.AboutSMCC", JSON.stringify(convertedData));

    form_data.append("ReportSetting.VideoTitle", title);

    form_data.append("ReportSetting.VideoDescription", description);

    new Promise((resolve, reject) => {
      fetch(API_URL + "/GlobalSetting", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + getLoggedUser().accessToken,
        },
        body: form_data,
      })
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          if (data.isError) {
            setOpportunityError(true);
            setOpportunitySuccess(false);
            setSettingsSuccess(false);
            setSettingsError(false);
            let emptyArray = [];
            for (const [key, value] of Object.entries(
              data.responseException.exceptionMessage.fieldErrors
            )) {
              emptyArray.push(value[0]);
            }
            setError(emptyArray);
            setIsSubmitEnable(true);
            window.scrollTo(0, 0);
            setUpdateCarbonConversion(false);
            setUpdateDraftReport(false);
            setUpdatePublishReport(false);
            window.setTimeout(() => {
              setShowAlert(false);
              setOpportunityError(false);
            }, 5000);
          } else {
            window.scrollTo(0, 0);
            setIsSubmitEnable(true);
            setOpportunitySuccess(true);
            setOpportunityError(false);
            setSettingsSuccess(false);
            setSettingsError(false);
            setUpdateCarbonConversion(false);
            setUpdateDraftReport(false);
            setUpdatePublishReport(false);
            window.setTimeout(() => {
              setShowAlert(false);
              setOpportunitySuccess(false);
            }, 5000);
          }
          resolve();
        })
        .catch((err) => {
          console.log("err", JSON.stringify(err));
        });
    });

    setUpdateConfirmation(false);
    setShowAlert(true);
  };

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Helmet>
          <title>{"SORT - Global Parameters"}</title>
        </Helmet>
        {settingsError && showAlert && (
          <Row className="px-2">
            {error.map((item) => {
              return (
                <Alert
                  key={item}
                  color="danger"
                  isOpen={settingsError && showAlert}
                >
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={30}
                    height={30}
                  />
                  {item}
                </Alert>
              );
            })}
          </Row>
        )}
        {settingsError && showAlert && (
          <Row className="px-2">
            <Alert color="danger" isOpen={settingsError && showAlert}>
              <img src={ErrorValidation} alt="error" width={30} height={30} />
              Global parameter values could not be updated
            </Alert>
          </Row>
        )}
        {settingsSuccess && showAlert && (
          <Row className="px-2">
            <Alert color="success" isOpen={settingsSuccess && showAlert}>
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Global parameter values updated successfully
            </Alert>
          </Row>
        )}
        {opportunityError && showAlert && (
          <Row className="px-2">
            <Alert color="danger" isOpen={opportunityError && showAlert}>
              <img src={ErrorValidation} alt="error" width={30} height={30} />
              Changes could not be saved
            </Alert>
          </Row>
        )}
        {opportunitySuccess && showAlert && (
          <Row className="px-2">
            <Alert color="success" isOpen={opportunitySuccess && showAlert}>
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Changes saved successfully
            </Alert>
          </Row>
        )}
        {permissions.includes("PERMISSIONS.GLOBALSETTING.VIEW") ? (
          <Form className="py-1">
            <Form.Group
              className="mb-3 row mt-1"
              controlId="formSettingsFields"
            >
              <Col sm={4} md={3} lg={3}>
                <Form.Label className="sort-form-section-lable">
                  Carbon Conversion Factors &nbsp;
                  <InfoTooltip
                    text={"Carbon Conversion Factors"}
                    id="settingsAccount"
                  />
                </Form.Label>
              </Col>
              <Col lg={8} className="sort-form-field">
                <Form.Label className={`sort-form-lable`}>
                  Energy (kWh) to Carbon (Tonnes)*
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div className="form-group sort-form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={
                        energyToCarbon % 1 === 0
                          ? parseFloat(energyToCarbon).toFixed(2)
                          : energyToCarbon
                      }
                      prefix=""
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      name="energyToCarbon"
                      placeholder="Enter Value"
                      className={`sort-form-input form-control ${
                        energyCarbonRequired ? "errorField" : ""
                      }`}
                      onValueChange={handleEnergyToCarbon}
                    />
                    {energyCarbonRequired && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                  </div>
                  <InlineErroMsg
                    errorType={energyCarbonRequired}
                    errorMsg={
                      "Please enter a value for Energy (kWh) to Carbon (Tonnes)."
                    }
                  />
                </div>
              </Col>
            </Form.Group>
            <Form.Group
              className="mb-3 row mt-1"
              controlId="formSettingsFields"
            >
              <Col sm={4} md={3} lg={3}></Col>
              <Col lg={8} className="sort-form-field">
                <Form.Label className={`sort-form-lable`}>
                  Water (m³) to Carbon (Tonnes)*
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div className="form-group sort-form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={
                        waterToCarbon % 1 === 0
                          ? parseFloat(waterToCarbon).toFixed(2)
                          : waterToCarbon
                      }
                      prefix=""
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      name="waterToCarbon"
                      placeholder="Enter Value"
                      className={`sort-form-input form-control ${
                        waterToCarbonRequired ? "errorField" : ""
                      }`}
                      onValueChange={handleWaterToCarbon}
                    />
                    {waterToCarbonRequired && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                  </div>
                  <InlineErroMsg
                    errorType={waterToCarbonRequired}
                    errorMsg={
                      "Please enter a value for Water (m³) to Carbon (Tonnes)."
                    }
                  />
                </div>
              </Col>
            </Form.Group>
            <br />
            <br />
            <br />
            <Form.Group
              className="mb-3 row mt-1"
              controlId="formSettingsFields"
            >
              <Col sm={4} md={3} lg={3}>
                <Form.Label className="sort-form-section-lable">
                  Carbon Equivalent Conversion Factors &nbsp;
                  <InfoTooltip
                    text={"Carbon Equivalent Conversion Factors"}
                    id="settingsAccount"
                  />
                </Form.Label>
              </Col>
              <Col lg={8} className="sort-form-field">
                <Form.Label className={`sort-form-lable`}>
                  Carbon (Tonnes) to No. of TV hours*
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div className="form-group sort-form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={
                        carbonToNoOfHours % 1 === 0
                          ? parseFloat(carbonToNoOfHours).toFixed(2)
                          : carbonToNoOfHours
                      }
                      prefix=""
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      name="carbonToNoOfHours"
                      placeholder="Enter Value"
                      className={`sort-form-input form-control ${
                        carbonToNoOfHoursRequired ? "errorField" : ""
                      }`}
                      onValueChange={handleCarbonToNoOfHours}
                    />
                    {carbonToNoOfHoursRequired && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                  </div>
                  <InlineErroMsg
                    errorType={carbonToNoOfHoursRequired}
                    errorMsg={
                      "Please enter a value for Carbon (Tonnes) to No. of TV hours."
                    }
                  />
                </div>
              </Col>
            </Form.Group>
            <Form.Group
              className="mb-3 row mt-1"
              controlId="formSettingsFields"
            >
              <Col sm={4} md={3} lg={3}></Col>
              <Col lg={8} className="sort-form-field">
                <Form.Label className={`sort-form-lable`}>
                  Carbon (Tonnes) to No. of nights in a UK hotel room*
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div className="form-group sort-form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={
                        carbonToNoOfNights % 1 === 0
                          ? parseFloat(carbonToNoOfNights).toFixed(2)
                          : carbonToNoOfNights
                      }
                      prefix=""
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      name="carbonToNoOfNights"
                      placeholder="Enter Value"
                      className={`sort-form-input form-control ${
                        carbonToNoOfNightsRequired ? "errorField" : ""
                      }`}
                      onValueChange={handleCarbonToNoOfNights}
                    />
                    {carbonToNoOfNightsRequired && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                  </div>
                  <InlineErroMsg
                    errorType={carbonToNoOfNightsRequired}
                    errorMsg={
                      "Please enter a value for Carbon (Tonnes) to No. of nights in a UK hotel room."
                    }
                  />
                </div>
              </Col>
            </Form.Group>
            <Form.Group
              className="mb-3 row mt-1"
              controlId="formSettingsFields"
            >
              <Col sm={4} md={3} lg={3}></Col>
              <Col lg={8} className="sort-form-field">
                <Form.Label className={`sort-form-lable`}>
                  Average passenger travelling on long haul flights (miles)*
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div className="form-group sort-form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={
                        averageTravelling % 1 === 0
                          ? parseFloat(averageTravelling).toFixed(2)
                          : averageTravelling
                      }
                      prefix=""
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      name="averageTravelling"
                      placeholder="Enter Value"
                      className={`sort-form-input form-control ${
                        averageTravellingRequired ? "errorField" : ""
                      }`}
                      onValueChange={handleAverageTravelling}
                    />
                    {averageTravellingRequired && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                  </div>
                  <InlineErroMsg
                    errorType={averageTravellingRequired}
                    errorMsg={
                      "Please enter a value for Carbon (Tonnes) to Average passenger travelling on long haul flights (miles)"
                    }
                  />
                </div>
              </Col>
            </Form.Group>
            <br />
            <br />
            <br />
            <Form.Group
              className="mb-3 row mt-1"
              controlId="formSettingsFields"
            >
              <Col sm={4} md={3} lg={3}>
                <Form.Label className="sort-form-section-lable">
                  Financial Rates &nbsp;
                  <InfoTooltip text={"Financial Rates"} id="settingsAccount" />
                </Form.Label>
              </Col>
              <Col lg={8} className="sort-form-field">
                <Form.Label className={`sort-form-lable`}>
                  Annual energy costs increase (%)*
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div className="form-group sort-form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      suffix="%"
                      value={
                        annualEnergyCost % 1 === 0
                          ? parseFloat(annualEnergyCost).toFixed(2)
                          : annualEnergyCost
                      }
                      prefix=""
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      name="annualEnergyCost"
                      placeholder="Enter Value"
                      className={`sort-form-input form-control ${
                        annualEnergyCostRequired ? "errorField" : ""
                      }`}
                      onValueChange={handleAnnualEnergyCost}
                    />
                    {annualEnergyCostRequired && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                  </div>
                  <InlineErroMsg
                    errorType={annualEnergyCostRequired}
                    errorMsg={
                      "Please enter a value for Annual energy costs increase rate (%)."
                    }
                  />
                </div>
              </Col>
            </Form.Group>
            <Form.Group
              className="mb-3 row mt-1"
              controlId="formSettingsFields"
            >
              <Col sm={4} md={3} lg={3}>
                <Form.Label className="sort-form-section-lable"></Form.Label>
              </Col>
              <Col lg={8} className="sort-form-field">
                <Form.Label className={`sort-form-lable`}>
                  Financing brokerage fee (%)*
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div className="form-group sort-form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      suffix="%"
                      value={
                        adjustedInvestmentRate % 1 === 0
                          ? parseFloat(adjustedInvestmentRate).toFixed(2)
                          : adjustedInvestmentRate
                      }
                      prefix=""
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      name="adjustedInvestmentRate"
                      placeholder="Enter Value"
                      className={`sort-form-input form-control ${
                        adjustedInvestmentRateRequired ? "errorField" : ""
                      }`}
                      onValueChange={handleAdjustedInvestmentRate}
                    />
                    {adjustedInvestmentRateRequired && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                  </div>
                  <InlineErroMsg
                    errorType={adjustedInvestmentRateRequired}
                    errorMsg={
                      "Please enter a value for Adjusted Investment rates."
                    }
                  />
                </div>
              </Col>
            </Form.Group>
            <br />
            <br />
            <hr />
            <div className="mt-4 text-center">
              <Button
                className={`sort-primary-btn btn-min-width`}
                type="button"
                style={{ margin: "0 10px" }}
                onClick={submitForm}
                disabled={
                  (decodedUserData &&
                    !decodedUserData.Permission.includes(
                      "PERMISSIONS.GLOBALSETTING.CREATE"
                    )) ||
                  !isSubmitEnable
                }
              >
                Save
              </Button>
            </div>
            {decodedUserData &&
              !decodedUserData.Permission.includes(
                "PERMISSIONS.GLOBALSETTING.CREATE"
              ) && (
                <Row className="permission-denied-page m-0">
                  <Card className="no-permission-card">
                    You don't have permission to perform this action. Please
                    contact the Admin.
                  </Card>
                </Row>
              )}
            <UpdateFileModal
              updateConfirmation={updateConfirmation}
              cancelUpdate={cancelUpdate}
              updateSubmit={updateSubmit}
              updatePublishReport={updatePublishReport}
              setUpdatePublishReport={setUpdatePublishReport}
              updateDraftReport={updateDraftReport}
              setUpdateDraftReport={setUpdateDraftReport}
              updateCarbonConversion={updateCarbonConversion}
              setUpdateCarbonConversion={setUpdateCarbonConversion}
              dataOpportunity={dataOpportunity}
              energyToCarbon={energyToCarbon}
              energyToCarbonOld={energyToCarbonOld}
              waterToCarbon={waterToCarbon}
              waterToCarbonOld={waterToCarbonOld}
            />
          </Form>
        ) : (
          <Row className="permission-denied-page m-0">
            <Card className="no-permission-card">
              You don't have permission to access this content. Please contact
              the Admin.
            </Card>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

GlobalParameters.propTypes = {
  getGlobalSettings: PropTypes.func,
  globalParameters: PropTypes.any,
  error: PropTypes.any,
  postGlobalOpportunity: PropTypes.func,
  isCreateGlobalOpportunitySuccess: PropTypes.any,
  createGlobalOppError: PropTypes.any,
  isFailedCreateGlobalOpportunity: PropTypes.any,
  opportunityGlobal: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getGlobalSettings: state.Settings.getGlobalSettings,
    globalParameters: state.Settings.globalParm,
    error: state.Settings.settingsError,
    isCreateGlobalOpportunitySuccess:
      state.Settings.isCreateGlobalOpportunitySuccess,
    isFailedCreateGlobalOpportunity:
      state.Settings.isFailedCreateGlobalOpportunity,
    createGlobalOppError: state.Settings.createGlobalOppError,
    opportunityGlobal: state.Settings.opportunityGlobal,
  };
};

export default connect(mapStateToProps, {
  getGlobalSettings,
  postGlobalOpportunity,
})(GlobalParameters);
