import React, { useEffect } from "react";
import { Container } from "reactstrap";
import InfoTooltip from "../../../components/common/tooltip";
import { Col, Form, Row, Card } from "react-bootstrap";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import LeftImage from "./leftImage";
import RightImage from "./rightImage";
import { getAuditReportDetail } from "../../../store/Report/Audit/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as helper from "../Report/helpers/helper";
import { getDecodeAuthDetails } from "../../../helpers";

const ReportDetails = (props) => {
  const {
    setRightImageNameValidate,
    rightImageNameValidate,
    selectedLeftImage,
    setSelectedLeftImage,
    selectedRightImage,
    setSelectedRightImage,
    reportHeader,
    setReportHeader,
    headerRequired,
    setHeaderRequired,
    leftImageRequired,
    setLeftImageRequired,
    uploadedLeftImage,
    setUploadedLeftImage,
    rightImageRequired,
    setRightImageRequired,
    uploadedRightImage,
    setUploadedRightImage,
    aReportId,
    reportStatus,
    setReportStatus,
    reportSelect,
    fileNameLenthError,
    setFileNameLenthError,
    rightFileNameError,
    setRightFileNameError,
    fieldsDisabled,
    setFieldsDisabled,
    setErrorSrc,
    errorSrc,
    errorSrcRight,
    setErrorSrcRight,
  } = props;

  let decoded = getDecodeAuthDetails();
  const permissions = decoded.Permission;

  let returnLeftArray = [];
  const assignExistingLeftImage = (leftImage) => {
    let tempObj = {};

    tempObj = {
      file: leftImage,
      name: "",
      binaryFile: null,
    };
    returnLeftArray.push(tempObj);

    return returnLeftArray;
  };

  let returnRightArray = [];
  const assignExistingRightImage = (rightImage) => {
    let tempObj = {};

    tempObj = {
      file: rightImage,
      name: "",
      binaryFile: null,
    };
    returnRightArray.push(tempObj);

    return returnRightArray;
  };

  let id = aReportId;

  useEffect(() => {
    if (id !== helper.ZEROID) {
      if (id !== "") {
        if (reportSelect) {
          props.getAuditReportDetail(id);
        }
      }
    }
  }, [props.auditAccountDetails, reportSelect]);

  useEffect(() => {
    if (id !== helper.ZEROID) {
      if (props.auditReport && reportSelect) {
        setReportHeader(
          props.auditReport.data
            ? props.auditReport.data &&
                props.auditReport.data.reportDetail.reportName
            : ""
        );

        if (
          props.auditReport.data &&
          props.auditReport.data.reportDetail &&
          props.auditReport.data.reportDetail.leftImageTokenPath !== ""
        ) {
          setUploadedLeftImage(
            props.auditReport
              ? assignExistingLeftImage(
                  props.auditReport.data &&
                    props.auditReport.data.reportDetail &&
                    props.auditReport.data.reportDetail.leftImageTokenPath
                )
              : []
          );
        } else {
          setUploadedLeftImage([]);
        }

        if (
          props.auditReport.data &&
          props.auditReport.data.reportDetail &&
          props.auditReport.data.reportDetail.rightImageTokenPath !== ""
        ) {
          setUploadedRightImage(
            props.auditReport
              ? assignExistingRightImage(
                  props.auditReport.data &&
                    props.auditReport.data.reportDetail &&
                    props.auditReport.data.reportDetail.rightImageTokenPath
                )
              : []
          );
        } else {
          setUploadedRightImage([]);
        }

        setReportStatus(
          props.auditReport.data
            ? props.auditReport.data &&
                props.auditReport.data.reportDetail.status
            : 0
        );

        if (
          props.auditReport.data &&
          props.auditReport.data.reportDetail.status === 1
        ) {
          setFieldsDisabled(true);
        } else {
          setFieldsDisabled(false);
        }
      }
    }
  }, [props.auditReport, reportSelect]);

  const handleChange = (e) => {
    setReportHeader(e.target.value);
    if (e.target.value.length >= 1) {
      if (e.target.name === "reportHeader") {
        setHeaderRequired(false);
      }
    } else {
      if (e.target.name === "reportHeader") {
        setHeaderRequired(true);
      }
    }
  };

  return (
    <React.Fragment>
      {permissions.includes("PERMISSIONS.AUDITREPORT.VIEW") ? (
        <Container fluid>
          <Form.Group className="mb-3 row mt-1" controlId="formAuditData">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Report Details &nbsp;
                <InfoTooltip text={"Report Details"} id="auditAccount" />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Report/Header Name*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="reportHeader"
                    id="reportHeader"
                    type="text"
                    className={`sort-form-input form-control ${
                      headerRequired ? "errorField" : ""
                    } ${fieldsDisabled ? "disable-fields" : ""}`}
                    value={reportHeader}
                    onChange={handleChange}
                    disabled={fieldsDisabled && true}
                  />
                  {headerRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    headerRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a name for the report.
                </span>
              </div>
            </Col>
          </Form.Group>
          <LeftImage
            selectedLeftImage={selectedLeftImage}
            setSelectedLeftImage={setSelectedLeftImage}
            leftImageRequired={leftImageRequired}
            setLeftImageRequired={setLeftImageRequired}
            uploadedLeftImage={uploadedLeftImage}
            setUploadedLeftImage={setUploadedLeftImage}
            fileNameLenthError={fileNameLenthError}
            setFileNameLenthError={setFileNameLenthError}
            fieldsDisabled={fieldsDisabled}
            setFieldsDisabled={setFieldsDisabled}
            reportStatus={reportStatus}
            setErrorSrc={setErrorSrc}
            errorSrc={errorSrc}
          />
          <RightImage
            setRightImageNameValidate={setRightImageNameValidate}
            rightImageNameValidate={rightImageNameValidate}
            selectedRightImage={selectedRightImage}
            setSelectedRightImage={setSelectedRightImage}
            rightImageRequired={rightImageRequired}
            setRightImageRequired={setRightImageRequired}
            uploadedRightImage={uploadedRightImage}
            setUploadedRightImage={setUploadedRightImage}
            rightFileNameError={rightFileNameError}
            setRightFileNameError={setRightFileNameError}
            fieldsDisabled={fieldsDisabled}
            setFieldsDisabled={setFieldsDisabled}
            reportStatus={reportStatus}
            errorSrcRight={errorSrcRight}
            setErrorSrcRight={setErrorSrcRight}
          />

          <Form.Group className="mb-3 row mt-4" controlId="formAuditData">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Report Status &nbsp;
                <InfoTooltip text={"Report Status"} id="auditAccount" />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              {reportStatus === 1 && (
                <span>
                  <span className="sort-badge badge-active"></span>
                  &nbsp;&nbsp;Published
                </span>
              )}

              {reportStatus === 0 && (
                <span>
                  <span className="sort-badge badge-inactive"></span>
                  &nbsp;&nbsp;Draft
                </span>
              )}
            </Col>
          </Form.Group>
        </Container>
      ) : (
        <Container>
          <Row className="permission-denied-page m-0">
            <Card className="no-permission-card">
              You don't have permission to access this content. Please contact
              the Admin.
            </Card>
          </Row>
        </Container>
      )}
    </React.Fragment>
  );
};

ReportDetails.propTypes = {
  getAuditReportDetail: PropTypes.func,
  auditReport: PropTypes.any,
  auditAccountDetails: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getAuditReportDetail: state.Report.getAuditReportDetail,
    auditReport: state.Report.auditReport,
    auditAccountDetails: state.Audit.auditAccountDetails,
    error: state.Report.error,
  };
};

export default connect(mapStateToProps, {
  getAuditReportDetail,
})(ReportDetails);
