export const GET_BUILDING_TYPES = "GET_BUILDING_TYPES";
export const GET_BUILDING_TYPES_SUCCESS = "GET_BUILDING_TYPES_SUCCESS";
export const GET_BUILDING_TYPES_ERROR = "GET_BUILDING_TYPES_ERROR";
export const GET_PARENT_COMPANIES = "GET_PARENT_COMPANIES";
export const GET_PARENT_COMPANIES_SUCCESS = "GET_PARENT_COMPANIES_SUCCESS";
export const GET_PARENT_COMPANIES_ERROR = "GET_PARENT_COMPANIES_ERROR";
export const GET_BENCH_MARK_VALUES = "GET_BENCH_MARK_VALUES";
export const GET_BENCH_MARK_VALUES_SUCCESS = "GET_BENCH_MARK_VALUES_SUCCESS";
export const GET_BENCH_MARK_VALUES_ERROR = "GET_BENCH_MARK_VALUES_ERROR";
export const POST_CLIENT_BUSINESS_ACCOUNT_CREATE =
  "POST_CLIENT_BUSINESS_ACCOUNT_CREATE";
export const POST_CLIENT_BUSINESS_ACCOUNT_CREATE_SUCCESS =
  "POST_CLIENT_BUSINESS_ACCOUNT_CREATE_SUCCESS";
export const POST_CLIENT_BUSINESS_ACCOUNT_CREATE_ERROR =
  "POST_CLIENT_BUSINESS_ACCOUNT_CREATE_ERROR";
export const VIEW_CLIENT_BUSINESS_ACCOUNT = "VIEW_CLIENT_BUSINESS_ACCOUNT";
export const VIEW_CLIENT_BUSINESS_ACCOUNT_SUCCESS =
  "VIEW_CLIENT_BUSINESS_ACCOUNT_SUCCESS";
export const VIEW_CLIENT_BUSINESS_ACCOUNT_ERROR =
  "VIEW_CLIENT_BUSINESS_ACCOUNT_ERROR";
export const GET_CLIENT_BUSINESS_ACCOUNT_DETAILS =
  "GET_CLIENT_BUSINESS_ACCOUNT_DETAILS";
export const GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_SUCCESS =
  "GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_SUCCESS";
export const GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_ERROR =
  "GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_ERROR";
export const UPDATE_CLIENT_BUSINESS_ACCOUNT = "UPDATE_CLIENT_BUSINESS_ACCOUNT";
export const UPDATE_CLIENT_BUSINESS_ACCOUNT_SUCCESS =
  "UPDATE_CLIENT_BUSINESS_ACCOUNT_SUCCESS";
export const UPDATE_CLIENT_BUSINESS_ACCOUNT_ERROR =
  "UPDATE_CLIENT_BUSINESS_ACCOUNT_ERROR";
export const RESET_TO_DEFAULT = "RESET_TO_DEFAULT";
export const DELETE_ATTACHMENT_CARBON = "DELETE_ATTACHMENT_CARBON";
export const DELETE_ATTACHMENT_CARBON_SUCCESS =
  "DELETE_ATTACHMENT_CARBON_SUCCESS";
export const DELETE_ATTACHMENT_CARBON_ERROR = "DELETE_ATTACHMENT_CARBON_ERROR";
export const UPLOAD_FILES_CARBON = "UPLOAD_FILES_CARBON";
export const UPLOAD_FILES_CARBON_SUCCESS = "UPLOAD_FILES_CARBON_SUCCESS";
export const UPLOAD_FILES_CARBON_ERROR = "UPLOAD_FILES_CARBON_ERROR";
export const RESET_CLIENT_BUSINESS = "RESET_CLIENT_BUSINESS";
