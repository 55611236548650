import React from "react";
import Dropzone from "react-dropzone";
import { FiImage } from "react-icons/fi";

const DropZoneEditor = (props) => {
  const { onDropFile, MAX_BATCH_SIZE, fieldsDisabled, reportStatus } = props;
  return (
    <Dropzone
      onDrop={(fieldsDisabled === false || reportStatus === 0) ? onDropFile : undefined}
      multiple={false}
      accept="image/*"
      maxSize={JSON.parse(MAX_BATCH_SIZE)}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        rejectedFiles,
      }) => (
        <section>
          <div {...getRootProps({ className: "dropzone-editor" })}>
            {(fieldsDisabled === false || reportStatus === 0) && (
              <input {...getInputProps()} />
            )}

            <div>
              <FiImage size={40} color="#55636B" />
              <p className="click-browse-btn">Click to browse files</p>
            </div>
          </div>
          <aside className="selected-file-wrapper"></aside>
        </section>
      )}
    </Dropzone>
  );
};

export default DropZoneEditor;
