import { Row, Col } from "reactstrap";
import { useOutletContext } from "react-router-dom";

// import { useEcoWiseQuery } from "../../store";

import co2 from "../../../assets/images/icons/gp2-icons/CarbonFootprint.svg";
import energy from "../../../assets/images/icons/gp2-icons/Energy_1.svg";
import water from "../../../assets/images/icons/gp2-icons/Water_1.svg";
import savings from "../../../assets/images/icons/gp2-icons/Savings.svg";
import CompaniesEnrolled from "../../../assets/images/icons/gp-dashboard-icons/CompaniesEnrolled.svg";
import ActiveLearners from "../../../assets/images/icons/gp-dashboard-icons/ActiveLearners.svg";
import MeasuredLeaningExperience from "../../../assets/images/icons/gp-dashboard-icons/MeasuredLeaningExperience.svg";
import PlasticSaved from "../../../assets/images/icons/gp-dashboard-icons/PlasticSaved.svg";

function StaffEngagment({ data }) {
  if (!data) {
    return null;
  }
  return (
    <Row className="ms-0 me-0 mb-lg-5">
      <Col className="p-3">
        <h2 className="h4">
          6. Staff / Stakeholder engagement -{" "}
          <span>Individual Carbon Literacy & Impact</span>
        </h2>
        <div className="widget-layout">
          <Row>
            <Col className="border-end mb-2 text-center">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                <img src={CompaniesEnrolled} alt="companies-enrolled" />
                </div>
              </div>
              <div></div>
              <span>Companies Enrolled</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.count
                  ? data.count
                  : 0}
              </span>
            </Col>
            <Col className="border-end mb-2 text-center">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                <img src={ActiveLearners} alt="active-learners" />
                </div>
              </div>
              <div></div>
              <span>Active Learners</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.activeLearners
                  ? data.activeLearners
                  : 0}
              </span>
            </Col>
            <Col className="border-end mb-2 text-center">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                <img src={MeasuredLeaningExperience} alt="measured-leaning-experience" />
                </div>
              </div>
              <div></div>
              <span>Measured Learning Experiences</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.learningExperience
                  ? data.learningExperience
                  : 0}
              </span>
            </Col>
            <Col className="mb-2 text-center">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={savings} alt="icon-savings" />
                </div>
              </div>
              <div></div>
              <span>Money Saved &pound;</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                &pound;{" "}
                {data.moneySaved
                  ? data.moneySaved
                      .toFixed(2)
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  : 0}
              </span>
            </Col>
            <Row className="border-top m-0 p-0">
              <Col className="border-end mt-3 text-center">
                <span className="icon-wrapper mb-2">
                  <div className="icon">
                    <img src={energy} alt="icon-energy" />
                  </div>
                </span>
                <div></div>
                <span className="fullwidth-flex">Energy Savings (kWh)</span>
                <div></div>
                <span className="fullwidth-flex sub-caption">
                  {data.energySaving
                    ? data.energySaving
                        .toFixed(2)
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
              </Col>
              <Col className="border-end mt-3 text-center">
                <span className="icon-wrapper mb-2">
                  <div className="icon">
                    <img src={water} alt="icon-water" />
                  </div>
                </span>
                <div></div>
                <span className="fullwidth-flex">Water Savings (m³)</span>
                <div></div>
                <span className="fullwidth-flex sub-caption">
                  {data.waterSaving
                    ? data.waterSaving
                        .toFixed(2)
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
              </Col>
              <Col className="border-end mt-3 text-center">
                <div className="icon-wrapper mb-2">
                  <div className="icon">
                    <img src={co2} alt="icon-co2" />
                  </div>
                </div>
                <div></div>
                <span>Carbon Savings (t)</span>
                <div></div>
                <span className="fullwidth-flex sub-caption">
                  {data.carbonSaving
                    ? data.carbonSaving
                        .toFixed(2)
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
              </Col>
              <Col className="mt-3 text-center">
                <div className="icon-wrapper mb-2 text-center">
                  <div className="icon">
                    <img src={PlasticSaved} alt="plastic-saved" />
                  </div>
                </div>
                <div></div>
                <span>Plastic Saved (kg)</span>
                <div></div>
                <span className="fullwidth-flex sub-caption">
                  {data.plasticSaved
                    ? data.plasticSaved
                    : 0}
                </span>
              </Col>
            </Row>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default StaffEngagment;
