import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Table, Badge, Alert } from "reactstrap";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import {
  getEPCBusinessAccounts,
  getBusinessAccounts,
} from "../../store/Dashboard/GP/staff/actions";
import AccordionTable from "../../pages/Client/Report/Opportunity/subComponents/accordionTable";
import TotalComponent from "../../pages/Client/Report/Opportunity/subComponents/totalComponent";
import epc_legend from "../../assets/images/logos/epc_legend.png";
import {
  getEPCDetails,
  getReportsPerSite,
  getOpportunitiesPerReport,
} from "../../store/EPC/actions";
import * as helper from "../../pages/Client/Report/Opportunity/helpers/helper";
import ErrorValidation from "../../assets/images/icons/ErrorValidation.svg";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { getDecodeAuthDetails } from "../../helpers";
import { propTypes } from "react-bootstrap/esm/Image";
import {
  getClientDashboard,
} from "../../store/Dashboard/Client/actions";

const EPCSummary = (props) => {
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;
  let navigate = useNavigate();
  let decoded = getDecodeAuthDetails();

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [addressList, setAddressList] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isClearable, setIsClearable] = useState(false);
  const [searchParm, setSearchParm] = useState("");
  const [opportunityData, setOpportunityData] = useState([]);
  const [selectedClientBusiness, setSelectedClientBusiness] = useState();
  const [currentEpc, setCurrentEpc] = useState();
  const [currentEpcScore, setCurrentEpcScore] = useState();
  const [currentEpcStyle, setCurrentEpcStyle] = useState();
  const [reportList, setReportList] = useState([]);
  const [reportId, setReportId] = useState();

  const [investmentReadyOppList, setInvestmentReadyOppList] = useState([]);
  const [furtherInvestiationOppList, setFurtherInvestiationOppList] = useState(
    []
  );
  const [complianceOppList, setComplianceOppList] = useState([]);
  const [potentialEPC, setPotentialEPC] = useState("");
  const [potentialEPCScore, setPotentialEPCScore] = useState("");
  const [potentialEpcStyle, setPotentialEpcStyle] = useState();
  const [activeKey, setActiveKey] = useState("IR");
  const [showReportDropdown, setShowReportDropdown] = useState(false);
  const [showReportError, setShowReportError] = useState(false);
  const [currentEpcYear, setCurrentEpcYear] = useState("");
  const [currentEpcMonth, setCurrentEpcMonth] = useState("");
  const [showEPCSections, setShowEPCSections] = useState(false);
  const [noOppData, setNoOppData] = useState(false);
  const [potentialEPCError, setPotentialEPCError] = useState(false);
  const [permissionIssue, showPermissionIssue] = useState(false);
  const [noCurrentEpc, showNoCurrentEpc] = useState(false);
  const [currentEPCDesc, setCurrentEPCDesc] = useState("");
  const [potentialEPCDesc, setPotentialEPCDesc] = useState("");

  const loadAddresses = (list) => {
    const arr = list.filter((item) => item.country === "United Kingdom");
    let addressDetails = arr.map((item) => {
      const container = {};
      container.value = item.clientBussinessAccountId;
      container.label = item.address;
      return container;
    });
    setAddressList(addressDetails);
  };

  const loadReports = () => {
    if (
      props.reportsPerSite &&
      props.reportsPerSite.data &&
      props.reportsPerSite.data.data.length > 0
    ) {
      let reports = props.reportsPerSite.data.data.map((item) => {
        const container = {};
        container.value = item.reportId;
        container.label = item.reportName;
        return container;
      });
      setReportList(reports);
    }
  };

  useEffect(() => {
    setShowEPCSections(false);
    props.getBusinessAccounts(decoded.ClientBussinessAccountId);
    props.getEPCBusinessAccounts();
  }, []);

  useEffect(() => {
    if (props.epcScore) {
      if (
        props.epcScore.data &&
        props.epcScore.data.data &&
        props.epcScore.data.data["address"]
      ) {
        showNoCurrentEpc(false);
        props.getReportsPerSite(selectedClientBusiness);
        setCurrentEpc(props.epcScore.data.data["asset-rating"]);
        setCurrentEpcScore(props.epcScore.data.data["asset-rating-band"]);
        setCurrentEpcYear(
          new Date(props.epcScore.data.data["inspection-date"]).getFullYear()
        );
        setCurrentEpcMonth(
          new Date(props.epcScore.data.data["inspection-date"]).toLocaleString(
            "en-us",
            { month: "long" }
          )
        );
        if (props.epcScore.data.data["asset-rating-band"] == "A+") {
          setCurrentEpcStyle("epc-green-outliner");
        }
        if (props.epcScore.data.data["asset-rating-band"] == "A") {
          setCurrentEpcStyle("epc-darkGreen");
        }
        if (props.epcScore.data.data["asset-rating-band"] == "B") {
          setCurrentEpcStyle("epc-green");
        }
        if (props.epcScore.data.data["asset-rating-band"] == "C") {
          setCurrentEpcStyle("epc-lightGreen");
        }
        if (props.epcScore.data.data["asset-rating-band"] == "D") {
          setCurrentEpcStyle("epc-yellow");
        }
        if (props.epcScore.data.data["asset-rating-band"] == "E") {
          setCurrentEpcStyle("epc-chineeseYellow");
        }
        if (props.epcScore.data.data["asset-rating-band"] == "F") {
          setCurrentEpcStyle("epc-orange");
        }
        if (props.epcScore.data.data["asset-rating-band"] == "G") {
          setCurrentEpcStyle("epc-red");
        }
      } else {
        showNoCurrentEpc(true);
        showPermissionIssue(false);
      }
    }
  }, [props.epcScore]);

  useEffect(() => {
    if (props.reportsPerSite && searchParm !== "") {
      if (props.reportsPerSite.data.data.length > 0) {
        setShowReportDropdown(true);
        setShowReportError(false);
        loadReports();
      } else if (props.reportsPerSite.data.data.data.length == 0) {
        setShowReportError(true);
      }
    } else {
      setShowReportDropdown(false);
      setShowReportError(false);
      setPotentialEPC("");
    }
  }, [props.reportsPerSite]);

  const handleSearchChange = (e) => {
    if (e) {
      setSearchParm(e.label);
      setIsClearable(true);
      setSelectedClientBusiness(e.value);
    } else {
      setIsClearable(false);
      setSearchParm("");
      setSelectedClientBusiness(null);
      setShowReportDropdown(false);
      setShowReportError(false);
      setShowEPCSections(false);
      setOpportunityData([]);
      showPermissionIssue(false);
      showNoCurrentEpc(false);
      setCurrentEPCDesc("");
      setPotentialEPCDesc("");
    }
  };
  const handleSearch = () => {
    let selectedOnj = props.epcBusinessAccounts.data.filter(
      (item) => item.clientBussinessAccountId == selectedClientBusiness
    );

    let param = {
      postcode: selectedOnj[0].postCode,
      county: selectedOnj[0].county,
      address: selectedOnj[0].streetNumber + "," + selectedOnj[0].streetName,
    };

    let params = {
      isAllCompanies: false,
      companyId: selectedClientBusiness,
    };

    if (
      props.businessAccounts.data.filter(
        (item) => item.clientBussinessAccountId == selectedClientBusiness
      ).length > 0
    ) {
      showPermissionIssue(false);
      props.getEPCDetails(param);
      props.getClientDashboard(params);
    } else {
      showPermissionIssue(true);
    }
  };

  useEffect(() => {
    if (props.clientDashboard && props.clientDashboard.data) {
      setCurrentEPCDesc(props.clientDashboard.data.defaultClientSettings.CurrentEPCDescription);
      setPotentialEPCDesc(props.clientDashboard.data.defaultClientSettings.PotentialEPCDescription);
    }
  }, [props.clientDashboard]);

  const handleReportSearch = (e) => {
    setReportId(e.value);
    props.getOpportunitiesPerReport(e.value);
  };

  const checkPotentialEPCScore = (value) => {
    if (value < 0) {
      setPotentialEpcStyle("epc-green-outliner");
      setPotentialEPCScore("A+");
    }
    if (value >= 0 && value <= 25) {
      setPotentialEpcStyle("epc-darkGreen");
      setPotentialEPCScore("A");
    }
    if (value >= 26 && value < 51) {
      setPotentialEpcStyle("epc-green");
      setPotentialEPCScore("B");
    }
    if (value >= 51 && value < 76) {
      setPotentialEpcStyle("epc-lightGreen");
      setPotentialEPCScore("C");
    }
    if (value >= 76 && value < 101) {
      setPotentialEpcStyle("epc-yellow");
      setPotentialEPCScore("D");
    }
    if (value >= 101 && value < 126) {
      setPotentialEpcStyle("epc-chineeseYellow");
      setPotentialEPCScore("E");
    }
    if (value >= 126 && value < 151) {
      setPotentialEpcStyle("epc-orange");
      setPotentialEPCScore("F");
    }
    if (value >= 151) {
      setPotentialEpcStyle("epc-red");
      setPotentialEPCScore("G");
    }
  };

  useEffect(() => {
    if (props.opportunitiesPerReport) {
      setShowEPCSections(true);
      if (props.opportunitiesPerReport.data.data.length == 0) {
        setNoOppData(true);
        setOpportunityData([]);
        if (currentEpc !== "--") {
          setPotentialEPC(parseInt(currentEpc));
          checkPotentialEPCScore(parseInt(currentEpc));
          setPotentialEPCError(false);
        } else {
          setPotentialEPCError(true);
        }
      } else {
        setOpportunityData(props.opportunitiesPerReport);
        setNoOppData(false);
        let investmentReadyList = props.opportunitiesPerReport.data.data.filter(
          (x) => x.category === "Investment Ready"
        );
        let furtherInList = props.opportunitiesPerReport.data.data.filter(
          (x) => x.category === "Further Investigation Required"
        );
        let complianceList = props.opportunitiesPerReport.data.data.filter(
          (x) => x.category === "Compliance"
        );
        let epcSum = 0;
        investmentReadyList[0] &&
          investmentReadyList[0].Opportunities.forEach((obj) => {
            for (let property in obj) {
              if (property === helper.EPC_IMPROVEMENT_TYPE) {
                epcSum += obj[property];
              }
            }
          });
        furtherInList[0] &&
          furtherInList[0].Opportunities.forEach((obj) => {
            for (let property in obj) {
              if (property === helper.EPC_IMPROVEMENT_TYPE) {
                epcSum += obj[property];
              }
            }
          });
        complianceList[0] &&
          complianceList[0].Opportunities.forEach((obj) => {
            for (let property in obj) {
              if (property === helper.EPC_IMPROVEMENT_TYPE) {
                epcSum += obj[property];
              }
            }
          });
        if (currentEpc !== "--") {
          setPotentialEPC(parseInt(currentEpc) - epcSum);
          checkPotentialEPCScore(parseInt(currentEpc) - epcSum);
          setPotentialEPCError(false);
        } else {
          setPotentialEPCError(true);
        }

        setInvestmentReadyOppList(
          investmentReadyList[0] && investmentReadyList[0].Opportunities
        );
        setFurtherInvestiationOppList(
          furtherInList[0] && furtherInList[0].Opportunities
        );
        setComplianceOppList(
          complianceList[0] && complianceList[0].Opportunities
        );
      }
    }
  }, [props.opportunitiesPerReport]);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      fontSize: "15px",
    }),
  };

  useEffect(() => {
    if (props.error) {
      setCurrentEpc("--");
    }
  }, [props.error]);

  const handlePostCodeSearch = (input) => {
    if (input.length > 4) {
      setMenuIsOpen(true);
      let filteredList = props.epcBusinessAccounts.data.filter((element) =>
        element.postCode.toLowerCase().includes(input.toLowerCase())
      );
      if (filteredList.length > 0) {
        setTimeout(() => {
          loadAddresses(filteredList);
        }, 200);
      } else {
        setAddressList([]);
      }
    } else {
      setMenuIsOpen(false);
    }
  };

  return (
    <React.Fragment>
      {" "}
      <div className="page-content epc-summary-page">
        <Helmet>
          <title>{"SORT - EPC Summary"}</title>
        </Helmet>
        <Container fluid>
          <Row>
            <div className="carbon-title">
              Please select your site details below from the government EPC
              portal for non-domestic buildings
            </div>
            <div className="carbon-paragraph">
              <Col lg={6}>Get your energy efficiency plan</Col>
            </div>
          </Row>
          <Row className="mt-4">
            <Col md={4} sm={4}>
              <ReactSelect
                options={addressList || []}
                placeholder="Your postcode"
                onChange={handleSearchChange}
                isClearable={isClearable}
                onInputChange={(input) => handlePostCodeSearch(input)}
                menuIsOpen={menuIsOpen}
                styles={customStyles}
              />
              {showReportDropdown && !permissionIssue && (
                <div className="mt-4">
                  <ReactSelect
                    options={reportList || []}
                    placeholder="Select report"
                    onChange={handleReportSearch}
                    styles={customStyles}
                  />
                </div>
              )}
              {selectedClientBusiness && permissionIssue && (
                <span className="mt-3 inline-form-error display-error">
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={30}
                    height={30}
                  />{" "}
                  You are not authorized to view reports outside of your company
                  hierarchy.
                </span>
              )}
              {showReportError && (
                <span className="mt-3 inline-form-error display-error">
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={30}
                    height={30}
                  />{" "}
                  No reports available
                </span>
              )}
              {noCurrentEpc && (
                <span className="mt-3 inline-form-error display-error">
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={30}
                    height={30}
                  />{" "}
                  No EPC results found
                </span>
              )}
            </Col>

            <Col md={3} sm={3}>
              <Button
                className={`sort-primary-btn`}
                disabled={searchParm !== "" ? false : true}
                onClick={handleSearch}
              >
                Get your energy efficiency plan
              </Button>
            </Col>
            <Col
              md={5}
              sm={5}
              style={{ borderLeft: "1px solid #C4C4C4", paddingLeft: "40px" }}
            >
              <p>Energy efficiency rating for this property</p>
              <img src={epc_legend} width={"50%"} />
            </Col>
          </Row>
          <hr />
          {showEPCSections && (
            <Row className="mt-4 epc-section-break">
              <div className="carbon-title mb-0">Current EPC</div>
            </Row>
          )}
          {showEPCSections && (
            <Row>
              {currentEpc == "--" ? (
                <Col className="p-4">
                  <span>--</span>
                </Col>
              ) : (
                <Col className="p-4">
                  <h4 className="epc-legend-colors">
                    <Badge className={currentEpcStyle}>
                      {currentEpc} | {currentEpcScore}
                    </Badge>
                  </h4>
                  <p className="epc-section-lable">
                    Your lodged EPC from {currentEpcMonth} {currentEpcYear} is
                    rated as {currentEpcScore}
                  </p>
                  <p className="epc-section-sub-label">
                    {currentEPCDesc.replace('{currentEpcMonth}', currentEpcMonth).replace('{currentEpcYear}', currentEpcYear)}
                  </p>
                </Col>
              )}
            </Row>
          )}
          {showEPCSections && (
            <Row className="mt-4 epc-section-break">
              <div className="carbon-title">Potential EPC</div>
            </Row>
          )}
          {showEPCSections && (
            <Row>
              {potentialEPCError ? (
                <Col className="p-4 mb-3">
                  <span>Error Msg</span>
                </Col>
              ) : (
                <Col className="p-4">
                  <h4 className="epc-legend-colors">
                    <Badge className={potentialEpcStyle}>
                      {potentialEPC} | {potentialEPCScore}
                    </Badge>
                  </h4>
                  <p className="epc-section-lable">
                    We've calculated that your potential EPC could be a{" "}
                    {potentialEPCScore}
                  </p>
                  <p className="epc-section-sub-label">
                    {potentialEPCDesc}
                  </p>
                </Col>
              )}
            </Row>
          )}
          {opportunityData &&
            opportunityData.data &&
            opportunityData.data.data.length > 0 && (
              <Row className="client-view-opportunity">
                <h4 className="m-0 dashboard-section-lable">Opportunities</h4>
                <Table
                  className="table mt-4 table-oportunity-header"
                  responsive
                >
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Investment</th>
                      <th scope="col">Savings &pound; (PA)</th>
                      <th scope="col">Payback Time(Years)</th>
                      <th scope="col">Carbon Savings(Tonnes)</th>
                      <th scope="col">EPC Improvement</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                </Table>
                {investmentReadyOppList &&
                  investmentReadyOppList.length > 0 && (
                    <div>
                      <AccordionTable
                        summary={"Investment Ready"}
                        tableData={investmentReadyOppList}
                        defKey={"IR"}
                        urlInitialDownloadType={"clientreport"}
                        urlFinalDownloadType={"opportunity"}
                        setActiveKey={setActiveKey}
                        activeKey={activeKey}
                      />
                      <TotalComponent data={investmentReadyOppList} />
                    </div>
                  )}
                {furtherInvestiationOppList &&
                  furtherInvestiationOppList.length > 0 && (
                    <div>
                      <AccordionTable
                        summary={"Further Investigation Required"}
                        tableData={furtherInvestiationOppList}
                        defKey={"FIR"}
                        urlInitialDownloadType={"clientreport"}
                        urlFinalDownloadType={"opportunity"}
                        setActiveKey={setActiveKey}
                        activeKey={activeKey}
                      />
                      <TotalComponent data={furtherInvestiationOppList} />
                    </div>
                  )}
                {complianceOppList && complianceOppList.length > 0 && (
                  <div>
                    <AccordionTable
                      summary={"Compliance"}
                      tableData={complianceOppList}
                      defKey={"C"}
                      urlInitialDownloadType={"clientreport"}
                      urlFinalDownloadType={"opportunity"}
                      setActiveKey={setActiveKey}
                      activeKey={activeKey}
                    />
                    <TotalComponent data={complianceOppList} />
                  </div>
                )}
                {noOppData && (
                  <tbody>
                    <tr className="text-center">No Data</tr>
                  </tbody>
                )}
              </Row>
            )}
        </Container>
      </div>
    </React.Fragment>
  );
};
EPCSummary.propTypes = {
  getEPCBusinessAccounts: PropTypes.func,
  epcBusinessAccounts: PropTypes.any,
  isSuccessEPCBusinessAccounts: PropTypes.any,
  getEPCDetails: PropTypes.func,
  epcScore: PropTypes.any,
  getReportsPerSite: PropTypes.func,
  reportsPerSite: PropTypes.any,
  getOpportunitiesPerReport: PropTypes.func,
  opportunitiesPerReport: PropTypes.any,
  error: PropTypes.any,
  getBusinessAccounts: PropTypes.func,
  businessAccounts: PropTypes.any,
  clientDashboard: PropTypes.any,
  getClientDashboard: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getEPCBusinessAccounts: state.GPDashboard.getEPCBusinessAccounts,
    epcBusinessAccounts: state.GPDashboard.epcBusinessAccounts,
    isSuccessEPCBusinessAccounts:
      state.GPDashboard.isSuccessEPCBusinessAccounts,
    getEPCDetails: state.EPCDetails.getEPCDetails,
    epcScore: state.EPCDetails.epcScore,
    getReportsPerSite: state.EPCDetails.getReportsPerSite,
    reportsPerSite: state.EPCDetails.reportsPerSite,
    getOpportunitiesPerReport: state.EPCDetails.getOpportunitiesPerReport,
    opportunitiesPerReport: state.EPCDetails.opportunitiesPerReport,
    error: state.EPCDetails.error,
    getBusinessAccounts: state.GPDashboard.getBusinessAccounts,
    businessAccounts: state.GPDashboard.businessAccounts,
    getClientDashboard: state.ClientDashboard.getClientDashboard,
    clientDashboard: state.ClientDashboard.clientDashboard,
  };
};
export default connect(mapStateToProps, {
  getEPCBusinessAccounts,
  getEPCDetails,
  getReportsPerSite,
  getOpportunitiesPerReport,
  getBusinessAccounts,
  getClientDashboard,
})(EPCSummary);
