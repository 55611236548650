import {
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_SUCCESS,
  CREATE_USER_ROLE_ERROR,
  GET_PERMISSION,
  PERMISSION_SUCCESS,
  PERMISSION_ERROR,
  EDIT_USER_ROLE,
  EDIT_USER_ROLE_SUCCESS,
  EDIT_USER_ROLE_ERROR,
  GET_EDIT_USER_DETAILS_SUCCESS,
  GET_EDIT_USER_DETAILS_ERROR,
  HADLE_EDIT_MODE,
  GET_ROLES,
  ROLES_SUCCESS,
  ROLES_ERROR,
  RE_CHECK,
  UPDATE_PERMISSION_STATUS,
  UPDATE_PERMISSION_SUB_STATUS,
  HANDLE_CATEGORY_ONLY,
  UPDATE_GET_PERMISSIONS,
  RESET_TO_DEFUALT_STATE
} from "./actionType"

const initialState = {
  error: "",
  loading: false,
  roleDetails: null,
  isSuccessRoleCreate: false,
  isErrorRoleCreate: false,
  isSuccessRoleEdit: false,
  isErrorRoleEdit: false,
  isResetFields: false,
  isPermissionLoad: false,
  isRolesLoad: false,
  roles: null,
  isUpdatedStatus: false,
  isUpdatedSubStatus: false,
  isEditEnable: false,
  createSuccessRoleMsg: null,
  roleError:null
}

const Admin = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_ROLE:
      state = {
        ...state,
        loading: true,
        roleDetails: null,
        isSuccessRoleCreate: false,
        isErrorRoleCreate: false,
        isSuccessRoleEdit: false,
        isErrorRoleEdit: false,
        isResetFields: false,
        createSuccessRoleMsg: null,
        roleError:null
      }
      break
    case CREATE_USER_ROLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isSuccessRoleCreate: true,
        isErrorRoleCreate: false,
        isErrorRoleEdit: false,
        isResetFields: true,
        createSuccessRoleMsg: action.payload,
        roleError:null
      }
      break
    case CREATE_USER_ROLE_ERROR:
      state = {
        ...state,
        roleError: action.payload,
        loading: false,
        isSuccessRoleCreate: false,
        isErrorRoleCreate: true,
        isSuccessRoleEdit: false,
        isErrorRoleEdit: false,
        isResetFields: false,
        createSuccessRoleMsg: null
      }
      break
    case EDIT_USER_ROLE:
      state = {
        ...state,
        loading: true,
        isSuccessRoleEdit: false,
        isErrorRoleEdit: false,
        isSuccessRoleCreate: false,
        isErrorRoleCreate: false,
        roleError:null
      }
      break
    case EDIT_USER_ROLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isSuccessRoleEdit: true,
        isErrorRoleEdit: false,
        isSuccessRoleCreate: false,
        isErrorRoleCreate: false,
        roleError:null
      }
      break
    case EDIT_USER_ROLE_ERROR:
      state = {
        ...state, 
        roleError: action.payload, 
        loading: false,
        isSuccessRoleEdit: false,
        isErrorRoleEdit: true,
        isSuccessRoleCreate: false,
        isErrorRoleCreate: false,
      }
      break
    case GET_EDIT_USER_DETAILS_SUCCESS:
      state = {
        ...state,
        roleDetails: action.payload,
        loading: false,
        isEditEnable: true,
      }
      break
    case GET_EDIT_USER_DETAILS_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case GET_PERMISSION:
      state = {
        ...state,
        loading: true,
        accountDetails: null
      }
      break
    case PERMISSION_SUCCESS:
      state = {
        ...state,
        permissions: action.payload,
        loading: false,
        isPermissionLoad: true
      }
      break
    case PERMISSION_ERROR:
      state = {
        ...state,
        loading: false,
        isPermissionLoad: false
      }
      break
    case GET_ROLES:
      state = {
        ...state,
        loading: true,
        isSuccessRoleCreate: false,
        isErrorRoleCreate: false,
        isSuccessRoleEdit: false,
        isResetFields: false,
        isRolesLoad: false,
        isErrorRoleEdit:false
      }
      break
    case ROLES_SUCCESS:
      state = {
        ...state,
        roles: action.payload,
        loading: false,
        isRolesLoad: true
      }
      break
    case ROLES_ERROR:
      state = {
        ...state,
        loading: false,
        isRolesLoad: false
      }
      break
    case HADLE_EDIT_MODE:
      state = {
        ...state,
      }
      break
    case RE_CHECK:
      state = {
        ...state,
        isResetFields: false,
      }
      break
    case UPDATE_PERMISSION_STATUS:
      state = {
        ...state,
        permissions: {
          data: state.permissions.data.map(
            (permission, i) => permission.category === action.payload.id ?
              {
                ...permission, value: action.payload.status,
                permissions: permission.permissions.map(
                  item => ({
                    ...item,
                    value: action.payload.status
                  }))
              } :
              permission
          )
        },
        loading: false,
        isUpdatedStatus: true,
      }
      break
    case HANDLE_CATEGORY_ONLY:
      state = {
        ...state,
        permissions: {
          data: state.permissions.data.map(
            (permission, i) => i === action.payload.parentID ?
              {
                ...permission, value: action.payload.status,
              } :
              permission
          )
        },
        loading: false,
        isUpdatedStatus: true
      }
      break
    case UPDATE_PERMISSION_SUB_STATUS:
      state = {
        ...state,
        permissions: {
          data: state.permissions.data.map(
            (category, i) => i === action.payload.parentID ?
              {
                ...category,
                permissions: category.permissions.map(
                  (permission, i) => i === action.payload.id ? {
                    ...permission, value: action.payload.status,
                  } :
                    permission
                )
              } :
              category
          )
        },

        loading: false,
        isUpdatedSubStatus: true,
        isUpdatedStatus: false
      }
      break
    case UPDATE_GET_PERMISSIONS:

      state = {
        ...state,
        permissions: {
          data: state.permissions.data.reduce((acc, cur) => {
            const newPermissions = cur.permissions.map((i) => ({
              ...i,
              value: action.payload.IDArray.includes(i.id),
            }));

            return [
              ...acc,
              {
                ...cur,
                permissions: newPermissions,
                value: newPermissions.every((i) => i.value),
              },
            ];
          }, [])
        },
        loading: false,
        isUpdatedSubStatus: false,
        isUpdatedStatus: false,
      }
      break
      case RESET_TO_DEFUALT_STATE:
      state = {
        ...state,
        isSuccessRoleCreate:false,
        isErrorRoleEdit:false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Admin
