import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditorContentToHTML = (props) => {
  const { editorState, onEditorStateChange, title } = props;
  const [uploadedImages, setUploadedImages] = useState([]);

    const uploadImageCallBack = (file) => {
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    setUploadedImages(uploadedImages);

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  return (
    <>
      <div className="custom-editor-title mt-5">{title}</div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        readOnly={true}
        wrapperClassName={"wrapper-class"}
        editorClassName="editor-class"
        toolbarClassName={"main-toolbar"}
        toolbar={{
        image: {
          urlEnabled: true,
          uploadCallback: uploadImageCallBack,
          alignmentEnabled: true,
          previewImage: false,
          alt: { present: true, mandatory: true },
          defaultSize: {
            height: "auto",
            width: "auto",
          },
        },
        link: {
          showOpenOptionOnHover: false,         
        },
      }}
      />
    </>
  );
};

export default EditorContentToHTML;
