import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import SearchIcon from "../../../../assets/images/icons/SearchIcon.svg";
import ErrorValidation from "../../../../assets/images/icons/ErrorValidation.svg";
import InlineErroMsg from "../../../../components/common/inlineErrorMsg";

const SearchComponent = (props) => {
  const {
    requiredText,
    handleChangeSearch,
    valuesUpload,
    errorMsg,
    search,
    placeholderText,
    resetSearch,
  } = props;

  return (
    <React.Fragment>
      <Row>
        <Col lg={6}>
          <div
            className="form-group search-content"
            style={{ position: "relative" }}
          >
            <input
              placeholder={placeholderText}
              className={`sort-form-input form-control ${
                requiredText ? "errorField" : ""
              }`}
              name={"searchBy"}
              type="text"
              onChange={handleChangeSearch}
              value={valuesUpload.searchBy}
            />
            <span style={{ position: "absolute", right: "0", top: "0" }}>
              <img
                src={SearchIcon}
                alt="success"
                width={40}
                height={40}
                className="email-search-btn"
              />
            </span>
            {props.requiredText && (
              <img
                src={ErrorValidation}
                alt="error"
                width={20}
                height={20}
                className="errorImg"
                style={{
                  position: "absolute",
                  right: "-20px",
                  top: "10px",
                }}
              />
            )}
            <InlineErroMsg errorType={requiredText} errorMsg={errorMsg} />
          </div>
        </Col>
        <Col lg={3}>
          <Button
            className="sort-primary-outline-btn btn-min-width"
            type="button"
            onClick={() => search()}
          >
            Search
          </Button>
        </Col>
        <Col lg={2}>
          <Row>
            <Button
              className="sort-primary-outline-btn btn-min-width"
              type="button"
              onClick={() => resetSearch()}
              style={{minWidth: 170}}
            >
              Clear Search
            </Button>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default SearchComponent;
