import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { Row, Tabs, Tab, Col, Modal } from "react-bootstrap";
import VideoSection from "../../Client/Report/Guide/subComponents/videoSection";
import IconographyTable from "../../../components/common/icongrphyTable";
import ScoringTable from "../../../components/common/scroringTable";
import InstallComplexity from "../../../assets/images/icons/iconogrphy/InstallComplexity-Scoring.svg";
import Payback from "../../../assets/images/icons/iconogrphy/Payback-Scoring.svg";
import Savings from "../../../assets/images/icons/iconogrphy/Savings-Scoring.svg";
import { EditorState, convertFromRaw } from "draft-js";
import ExecutiveSummary from "../../Client/Report/ExecutiveSummarySection";
import Opportunity from "../../Client/Report/Opportunity";
import AuditFindings from "../../Client/Report/AuditFindings";
import NextStep from "../../Client/Report/NextStep";
import AboutSaveMoneyCutCarbon from "../../Client/Report/AboutSaveMoneyCutCarbon";
import Loader from "../../../components/common/Loader";
import {
  getFinancingOptionsPreview,
  getRepaymentPreviewData,
} from "../../../store/Report/Audit/actions";
import FinancingOptions from "../../Client/Report/FinancingOptions";
import {
  filterOpportunityIds,
  filterOpportunities,
} from "../../Client/Report/FinancingOptions/helpers";

const PreviewReport = (props) => {
  const {
    previewReportSuccess,
    showAlertPreview,
    setPreviewReportSuccess,
    setShowAlertPreview,
    aReportId,
  } = props;

  const [reportData, setReportData] = useState([]);
  const [previewDetails, setPreviewDetails] = useState(null);
  const [reportName, setReportName] = useState("");
  const [leftImage, setLeftImage] = useState("");
  const [rightImage, setRightImage] = useState("");
  const [videoData, setVideoData] = useState(null);
  const [videoDisplay, setVideoDisplay] = useState(null);
  const [benchmarkData, setBenchMarkData] = useState([]);
  const [savingsScoringData, setSavingScoringData] = useState([]);
  const [paybackScoringData, setPaybackvScoringData] = useState([]);
  const [installScoringData, setInstallScoringData] = useState([]);
  const [executiveSummaryData, setExecutiveSummaryData] = useState(null);
  const [editorState1, setEditorState1] = useState(null);
  const [editorState2, setEditorState2] = useState(null);
  const [editorState3, setEditorState3] = useState(null);
  const [editorState4, setEditorState4] = useState(null);
  const [editorState5, setEditorState5] = useState(null);
  const [editorState6, setEditorState6] = useState(null);
  const [editorState7, setEditorState7] = useState(null);
  const [editorState8, setEditorState8] = useState(null);
  const [editorState9, setEditorState9] = useState(null);
  const [editorState10, setEditorState10] = useState(null);
  const [editorState11, setEditorState11] = useState(null);
  const [editorState12, setEditorState12] = useState(null);
  const [editorState13, setEditorState13] = useState(null);
  const [textAreaTitle1, setTextAreaTitle1] = useState(null);
  const [textAreaTitle2, setTextAreaTitle2] = useState(null);
  const [textAreaTitle3, setTextAreaTitle3] = useState(null);

  const [oppTableData, setOppTableData] = useState([]);
  const [investmentReadyOppList, setInvestmentReadyOppList] = useState([]);
  const [investmentReadyTempList, setInvestmentReadyTempList] = useState([]);
  const [furtherInvestiationOppList, setFurtherInvestiationOppList] = useState(
    []
  );
  const [furtherInvestiationTempList, setFurtherInvestiationTempList] =
    useState([]);
  const [complianceOppList, setComplianceOppList] = useState([]);
  const [complianceTempList, setComplianceTempList] = useState([]);

  const [auditFindingsData, setAuditFindingsData] = useState([]);

  const [checkAll, setCheckAll] = useState(false);
  const [selectedRepaymentID, setSelectedRepaymentID] = useState(null);
  const [opportunityID, setOpportunityID] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState(null);
  const [isViewFinacingOption, setIsViewFinancingOption] = useState(false);
  const [selectedOpportunies, setSelectedOpportunities] = useState([]);
  const [opportunityList, setOpportunityList] = useState([]);
  const [actualOppList, setActualOppList] = useState(null);
  const [repaymentList, setRepaymentList] = useState(null);

  const [checkedStateIR, setCheckedStateIR] = useState();
  const [checkedStateFIR, setCheckedStateFIR] = useState();
  const [checkedStateC, setCheckedStateC] = useState();
  const [showAUditFindigNoDataSlection, setShowAUditFindigNoDataSlection] =
    useState(false);
  const [financeComponentData, setFinanceComponentData] = useState();
  const [checkLighting, setCheckLighting] = useState(false);
  const [checkEnergy, setCheckEnergy] = useState(false);
  const [checkWater, setCheckWater] = useState(false);
  const [checkGas, setCheckGas] = useState(false);
  const [checkOther, setCheckOther] = useState(false);
  const [dropdownOppList, setDropdownOppList] = useState([]);
  const [checkInvestmentReady, setCheckInvestmentReady] = useState(false);
  const [checkFurtherInvestigation, setCheckFurtherInvestigation] =
    useState(false);
  const [checkCompliance, setCheckCompliance] = useState(false);

  const handleClosePreviewReport = () => {
    setPreviewReportSuccess(false);
    setShowAlertPreview(false);
  };

  useEffect(() => {
    if (previewReportSuccess) {
      props.getRepaymentPreviewData();
    }
  }, [previewReportSuccess]);

  useEffect(() => {
    if (props.financingPreview && props.financingPreview.data) {
      setFinanceComponentData(props.financingPreview.data);
    }
  }, [props.financingPreview]);

  useEffect(() => {
    if (props.previewReport) {
      let reportDetails = props.previewReport.data.data;
      let clientReportDetails = reportDetails.clientReportDetail;
      let videoDetails = reportDetails.viewGuide;
      let executiveDetails = reportDetails.executiveSummary;
      let viewOpportunityDetails = reportDetails.viewOpportunities;
      let viewAuditDetails = reportDetails.viewAuditFindings;

      setReportData(reportDetails);
      setAuditFindingsData(viewAuditDetails);

      let investmentReadyList = viewOpportunityDetails.filter(
        (x) => x.category === "Investment Ready"
      );
      let furtherInList = viewOpportunityDetails.filter(
        (x) => x.category === "Further Investigation Required"
      );
      let complianceList = viewOpportunityDetails.filter(
        (x) => x.category === "Compliance"
      );
      setInvestmentReadyOppList(
        investmentReadyList[0] && investmentReadyList[0].Opportunities
      );
      setInvestmentReadyTempList(
        investmentReadyList[0] && investmentReadyList[0].Opportunities
      );
      setFurtherInvestiationOppList(
        furtherInList[0] && furtherInList[0].Opportunities
      );
      setFurtherInvestiationTempList(
        furtherInList[0] && furtherInList[0].Opportunities
      );
      setComplianceOppList(
        complianceList[0] && complianceList[0].Opportunities
      );
      setComplianceTempList(
        complianceList[0] && complianceList[0].Opportunities
      );

      setExecutiveSummaryData(executiveDetails ? executiveDetails : null);
      if (executiveDetails.executiveSummarySections.length > 0) {
        let filterTextContent1 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Header & Text Content Block 1"
          );
        let filterTextContent2 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Header & Text Content Block 2"
          );
        let filterTextContent3 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Header & Text Content Block 3"
          );
        let filterTextContent4 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 1"
          );
        let filterTextContent5 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 2"
          );
        let filterTextContent6 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 3"
          );
        let filterTextContent7 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 4"
          );
        let filterTextContent8 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 5"
          );
        let filterTextContent9 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 6"
          );
        let filterTextContent10 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 7"
          );
        let filterTextContent11 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 8"
          );
        let filterTextContent12 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 9"
          );
        let filterTextContent13 =
          executiveDetails.executiveSummarySections.filter(
            (x) => x.name === "Image & Text Editable Content Block 10"
          );
        if (filterTextContent1.length > 0) {
          setEditorState1(
            EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(filterTextContent1[0].editableContent.text)
              )
            )
          );
          setTextAreaTitle1(filterTextContent1[0].editableContent.header);
        }
        if (filterTextContent2.length > 0) {
          setEditorState2(
            EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(filterTextContent2[0].editableContent.text)
              )
            )
          );
          setTextAreaTitle2(filterTextContent2[0].editableContent.header);
        }
        if (filterTextContent3.length > 0) {
          setEditorState3(
            EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(filterTextContent3[0].editableContent.text)
              )
            )
          );
          setTextAreaTitle3(filterTextContent3[0].editableContent.header);
        }
        if (filterTextContent4.length > 0) {
          setEditorState4(filterTextContent4[0].editableContent);
        }
        if (filterTextContent5.length > 0) {
          setEditorState5(filterTextContent5[0].editableContent);
        }
        if (filterTextContent6.length > 0) {
          setEditorState6(filterTextContent6[0].editableContent);
        }
        if (filterTextContent7.length > 0) {
          setEditorState7(filterTextContent7[0].editableContent);
        }
        if (filterTextContent8.length > 0) {
          setEditorState8(filterTextContent8[0].editableContent);
        }
        if (filterTextContent9.length > 0) {
          setEditorState9(filterTextContent9[0].editableContent);
        }
        if (filterTextContent10.length > 0) {
          setEditorState10(filterTextContent10[0].editableContent);
        }
        if (filterTextContent11.length > 0) {
          setEditorState11(filterTextContent11[0].editableContent);
        }
        if (filterTextContent12.length > 0) {
          setEditorState12(filterTextContent12[0].editableContent);
        }
        if (filterTextContent13.length > 0) {
          setEditorState13(filterTextContent13[0].editableContent);
        }
      } else {
        setEditorState1(null);
        setEditorState2(null);
        setEditorState3(null);
        setEditorState4(null);
        setEditorState5(null);
        setEditorState6(null);
        setEditorState7(null);
        setEditorState8(null);
        setEditorState9(null);
        setEditorState10(null);
        setEditorState11(null);
        setEditorState12(null);
        setEditorState13(null);
        setTextAreaTitle1(null);
        setTextAreaTitle2(null);
        setTextAreaTitle3(null);
      }
      if (executiveDetails.opportunitySummaryTotal.length > 0) {
        setOppTableData(executiveDetails.opportunitySummaryTotal);
      } else {
        setOppTableData([]);
      }

      setReportName(clientReportDetails.reportName);
      setLeftImage(clientReportDetails.leftImageTokenPath);
      setRightImage(clientReportDetails.rightImageTokenPath);
      setVideoData(reportDetails.viewGuide);
      setBenchMarkData(videoDetails.clientBenchmark);

      setPreviewDetails(reportDetails);

      if (viewOpportunityDetails.length > 0) {
        let filterOpprtunityCategories = viewOpportunityDetails.map(
          ({ category }) => category
        );
        let tempIdArray = [];
        let tempIdArray2 = [];

        for (let i = 0; i < filterOpprtunityCategories.length; i++) {
          tempIdArray.push(
            filterOpportunityIds(
              viewOpportunityDetails,
              filterOpprtunityCategories[i]
            )
          );
        }
        for (let i = 0; i < filterOpprtunityCategories.length; i++) {
          tempIdArray2.push(
            filterOpportunities(
              viewOpportunityDetails,
              filterOpprtunityCategories[i]
            )
          );
        }
        setOpportunityID(tempIdArray.flat());
        setOpportunityList(tempIdArray2.flat());
        setActualOppList(tempIdArray2.flat());
      } else {
        setOpportunityID([]);
        setOpportunityList([]);
        setActualOppList([]);
      }
    } else {
      setLeftImage("");
      setRightImage("");
    }
  }, [props.previewReport]);

  useEffect(() => {
    if (benchmarkData.length > 0) {
      setSavingScoringData(
        benchmarkData.filter((x) => x.benchmarkType === "Saving")
      );
      setPaybackvScoringData(
        benchmarkData.filter((x) => x.benchmarkType === "Payback")
      );
      setInstallScoringData(
        benchmarkData.filter((x) => x.benchmarkType === "Install Complexity")
      );
    }
  }, [benchmarkData]);

  useEffect(() => {
    if (props.repaymentPreviewData && props.repaymentPreviewData.data) {
      let repaymentList = props.repaymentPreviewData.data.map((item) => {
        const container = {};

        container.value = item.repaymentTermId;
        container.label = item.repaymentYear;

        return container;
      });
      if (repaymentList.length > 0) {
        setRepaymentList(repaymentList);
        setSelectedRepaymentID(repaymentList[0].value);
      }
    }
  }, [props.repaymentPreviewData]);

  const updateFinancingOptions = (oppID) => {
    if (selectedRepaymentID && oppID.length > 0) {
      let parm = {
        reportId: aReportId,
        repaymentTermId: selectedRepaymentID,
        opportunities: oppID,
      };
      props.getFinancingOptionsPreview(parm);
    } else if (oppID.length === 0) {
      setFinanceComponentData();
    }
  };

  const [key, setKey] = useState(0);
  const [activeKey, setActiveKey] = useState("IR");
  const [activeKeyAudit, setActiveKeyAudit] = useState(0);

  const [currentTabState, setCurrentTabState] = useState(0);
  const handleKey = (e) => {
    setCurrentTabState(parseInt(e));
    setKey(parseInt(e));
    let emtyArray = [];
    setSelectedOpportunities(emtyArray);
    setSelectedOpp(emtyArray);
    setCheckLighting(false);
    setCheckEnergy(false);
    setCheckWater(false);
    setCheckGas(false);
    setCheckOther(false);
    setCheckInvestmentReady(false);
    setCheckFurtherInvestigation(false);
    setCheckCompliance(false);
    if (parseInt(e) === 2) {
      setCheckAll(false);
    }
    if (parseInt(e) === 3 && opportunityID) {
      updateFinancingOptions(opportunityID);
    }
  };

  const formatDropdownList = (selected, opp) => {
    let filteredList = opp
      .flat()
      .filter((item) => selected.includes(item.id))
      .map((item) => {
        const container = {};

        container.value = item.id;
        container.label = `${item.name} [${item.opportunitySummary},${item.productMainCategory}]`;

        return container;
      });
    setSelectedOpp(filteredList);
    updateFinancingOptions(selected);
  };

  const viewFinancingOptions = () => {
    if (aReportId && selectedRepaymentID && selectedOpportunies.length > 0) {
      if (checkAll) {
        formatDropdownList(selectedOpportunies, actualOppList);
        setKey(3);
        setIsViewFinancingOption(true);
      } else {
        setCheckedStateIR(
          new Array(
            investmentReadyOppList && investmentReadyOppList.length
          ).fill(false)
        );
        setCheckedStateFIR(
          new Array(
            furtherInvestiationOppList && furtherInvestiationOppList.length
          ).fill(false)
        );
        setCheckedStateC(
          new Array(complianceOppList && complianceOppList.length).fill(false)
        );
        let oppList = [];
        if (investmentReadyOppList && investmentReadyOppList.length > 0) {
          oppList.push(investmentReadyOppList);
        }
        if (
          furtherInvestiationOppList &&
          furtherInvestiationOppList.length > 0
        ) {
          oppList.push(furtherInvestiationOppList);
        }
        if (complianceOppList && complianceOppList.length > 0) {
          oppList.push(complianceOppList);
        }
        formatDropdownList(selectedOpportunies, oppList);
        setKey(3);
      }
    } else if (selectedOpportunies.length === 0) {
      setShowAUditFindigNoDataSlection(true);
    }
  };

  return reportData ? (
    <React.Fragment>
      <Modal
        show={previewReportSuccess && showAlertPreview}
        className="preview-modal"
      >
        <Modal.Header
          className="header-audit client-view-report"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <Row>
            <Col lg={10}>
              {" "}
              <span
                className="green-header client-report-header"
                style={{ margin: "0px 50px" }}
              >
                {reportName}
              </span>
            </Col>
            <Col lg={2}>
              <Button
                className="sort-primary-btn"
                type="button"
                onClick={handleClosePreviewReport}
              >
                Close Preview
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div className={leftImage || rightImage ? "" : "d-none"}>
            <br />
            <br />
            <Row>
              <Col lg={1}></Col>
              <Col lg={5} className={"prev-image-wrapper"}>
                {leftImage !== "" && (
                  <img
                    className="preview-image"
                    alt="leftImage"
                    src={leftImage}
                  />
                )}
              </Col>
              <Col lg={5} className={"prev-image-wrapper"}>
                {rightImage !== "" && (
                  <img
                    className="preview-image"
                    alt="rightImage"
                    src={rightImage}
                  />
                )}
              </Col>
              <Col lg={1}></Col>
            </Row>
            <br />
            <br />
            <hr />
            <br />
            <br />
          </div>
          <Row
            className="preview-tab-section preview-view"
            style={{ margin: 0 }}
          >
            <Tabs
              activeKey={key}
              transition={false}
              id="client-view-report-tabs"
              className="mb-3"
              onSelect={(k) => handleKey(k)}
            >
              <Tab eventKey={0} title="Guide" className="guide-tab">
                <VideoSection videoData={videoData} />
                <IconographyTable />
                <br />
                <span className="green-header">Scoring Criteria</span>
                {savingsScoringData.length > 0 &&
                  paybackScoringData.length > 0 &&
                  installScoringData.length > 0 && (
                    <div>
                      <ScoringTable
                        tableData={savingsScoringData}
                        icon={Savings}
                        tableCaption={"Savings"}
                      />
                      <ScoringTable
                        tableData={paybackScoringData && paybackScoringData}
                        icon={Payback}
                        tableCaption={"Payback"}
                      />
                      <ScoringTable
                        tableData={installScoringData && installScoringData}
                        icon={InstallComplexity}
                        tableCaption={"Install Complexity"}
                      />
                    </div>
                  )}
              </Tab>
              <Tab
                eventKey={1}
                title="Executive Summary"
                className="executive-tab"
              >
                <ExecutiveSummary
                  setKey={setKey}
                  setActiveKey={setActiveKey}
                  executiveSummaryData={executiveSummaryData}
                  editorState1={editorState1}
                  editorState2={editorState2}
                  editorState3={editorState3}
                  editorState4={editorState4}
                  editorState5={editorState5}
                  editorState6={editorState6}
                  editorState7={editorState7}
                  editorState8={editorState8}
                  editorState9={editorState9}
                  editorState10={editorState10}
                  editorState11={editorState11}
                  editorState12={editorState12}
                  editorState13={editorState13}
                  setEditorState1={setEditorState1}
                  setEditorState2={setEditorState2}
                  setEditorState3={setEditorState3}
                  textAreaTitle1={textAreaTitle1}
                  textAreaTitle2={textAreaTitle2}
                  textAreaTitle3={textAreaTitle3}
                  oppTableData={oppTableData}
                />
              </Tab>
              <Tab
                eventKey={2}
                title="Opportunities"
                className="opportunity-tab"
              >
                <Opportunity
                  reportID={aReportId}
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  checkAll={checkAll}
                  setCheckAll={setCheckAll}
                  selectedRepaymentID={selectedRepaymentID}
                  setKey={setKey}
                  setSelectedOpp={setSelectedOpp}
                  setOpportunityList={setOpportunityList}
                  opportunityList={opportunityList}
                  setIsViewFinancingOption={setIsViewFinancingOption}
                  selectedOpportunies={selectedOpportunies}
                  setSelectedOpportunities={setSelectedOpportunities}
                  investmentReadyOppList={investmentReadyOppList}
                  setInvestmentReadyOppList={setInvestmentReadyOppList}
                  investmentReadyTempList={investmentReadyTempList}
                  setInvestmentReadyTempList={setInvestmentReadyTempList}
                  furtherInvestiationOppList={furtherInvestiationOppList}
                  setFurtherInvestiationOppList={setFurtherInvestiationOppList}
                  furtherInvestiationTempList={furtherInvestiationTempList}
                  setFurtherInvestiationTempList={
                    setFurtherInvestiationTempList
                  }
                  complianceOppList={complianceOppList}
                  setComplianceOppList={setComplianceOppList}
                  complianceTempList={complianceTempList}
                  setComplianceTempList={setComplianceTempList}
                  checkedStateIR={checkedStateIR}
                  setCheckedStateIR={setCheckedStateIR}
                  checkedStateFIR={checkedStateFIR}
                  setCheckedStateFIR={setCheckedStateFIR}
                  checkedStateC={checkedStateC}
                  setCheckedStateC={setCheckedStateC}
                  showAUditFindigNoDataSlection={showAUditFindigNoDataSlection}
                  setShowAUditFindigNoDataSlection={
                    setShowAUditFindigNoDataSlection
                  }
                  viewFinancingOptions={viewFinancingOptions}
                  urlInitialDownloadType={"auditreport"}
                  urlFinalDownloadType={"opportunity"}
                  mode={"preview"}
                />
              </Tab>
              <Tab
                eventKey={3}
                title="Financing Options"
                className="financing-tab"
              >
                <FinancingOptions
                  financeData={financeComponentData}
                  repaymentList={repaymentList}
                  setSelectedRepaymentID={setSelectedRepaymentID}
                  selectedRepaymentID={selectedRepaymentID}
                  updateFinancingOptions={updateFinancingOptions}
                  opportunityList={opportunityList}
                  setOpportunityList={setOpportunityList}
                  actualOppList={actualOppList}
                  setOpportunityID={setOpportunityID}
                  selectedOpp={selectedOpp}
                  setSelectedOpp={setSelectedOpp}
                  isViewFinacingOption={isViewFinacingOption}
                  opportunityID={opportunityID}
                  checkLighting={checkLighting}
                  checkEnergy={checkEnergy}
                  checkWater={checkWater}
                  checkGas={checkGas}
                  checkOther={checkOther}
                  setCheckLighting={setCheckLighting}
                  setCheckEnergy={setCheckEnergy}
                  setCheckWater={setCheckWater}
                  setCheckGas={setCheckGas}
                  setCheckOther={setCheckOther}
                  dropdownOppList={dropdownOppList}
                  setDropdownOppList={setDropdownOppList}
                  checkInvestmentReady={checkInvestmentReady}
                  setCheckInvestmentReady={setCheckInvestmentReady}
                  checkFurtherInvestigation={checkFurtherInvestigation}
                  setCheckFurtherInvestigation={setCheckFurtherInvestigation}
                  checkCompliance={checkCompliance}
                  setCheckCompliance={setCheckCompliance}
                />
              </Tab>
              <Tab eventKey={4} title="Audit Findings">
                <AuditFindings
                  reportID={aReportId}
                  activeKey={activeKeyAudit}
                  setActiveKey={setActiveKeyAudit}
                  auditFindingsData={auditFindingsData}
                  urlAuditDownloadType={"auditreport"}
                />
              </Tab>
              <Tab eventKey={5} title="Next Steps">
                <NextStep nextStep={reportData} />
              </Tab>
              <Tab eventKey={6} title="About">
                <AboutSaveMoneyCutCarbon aboutSMCCData={reportData} />
              </Tab>
            </Tabs>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  ) : (
    <Loader data={"page-spinner"} />
  );
};

PreviewReport.propTypes = {
  previewReport: PropTypes.any,
  getFinancingOptionsPreview: PropTypes.func,
  financingPreview: PropTypes.any,
  getRepaymentPreviewData: PropTypes.func,
  repaymentPreviewData: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    previewReport: state.Report.previewReport,
    getFinancingOptionsPreview: state.Report.getFinancingOptionsPreview,
    financingPreview: state.Report.financingPreview,
    getRepaymentPreviewData: state.Report.getRepaymentPreviewData,
    repaymentPreviewData: state.Report.repaymentPreviewData,
  };
};

export default connect(mapStateToProps, {
  getFinancingOptionsPreview,
  getRepaymentPreviewData,
})(PreviewReport);
