//import axios from "axios"
import { get, post, put, del } from "../api_helper";
import * as url from "../url_helper";
import axios from "axios";
import jwt_decode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const config = {
  headers: {
    "Content-Type": "application/JSON",
    "Ocp-Apim-Subscription-Key": "3fcd09b1efc245b79152ed11e5568346",
    "Ocp-Apim-Trace": true,
  },
};

axios.interceptors.request.use(function (config) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  if (authUser && config.url !== "/authentication/sign-in") {
    config.headers.Authorization = "Bearer " + authUser.accessToken;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    return new Promise((resolve) => {
      const originalReq = err.config;
      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (err.response && err.response.status === 401) {
        originalReq._retry = true;

        const res = fetch(`${API_URL}/authentication/refresh`, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrer: "no-referrer",
          body: JSON.stringify({
            refreshToken: authUser.refreshToken,
            userId: jwt_decode(authUser.accessToken).UserId,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && !res.error) {
              localStorage.setItem(
                "authUser",
                JSON.stringify({
                  refreshToken: res.data.token.refreshToken,
                  accessToken: res.data.token.accessToken,
                })
              );
              originalReq.headers.Authorization =
                "Bearer " + res.data.token.accessToken;
              return axios(originalReq);
            }

            localStorage.clear();
            window.location.href = "/login";
            window.location.reload();
          });

        resolve(res);
      }
      resolve(err.response);
    });
  }
);

//Role
const createUserRolePost = (data) => post(url.ROLE, data, config);
const userRolesGet = () => get(url.ROLE);
const userRoleGet = (id) => get(url.ROLE + `/${id}`);
const updateUserRole = (data) => put(url.ROLE, data);

//Permission
const getUserRolePermission = () => get(url.GET_PERMISSION);

//Account
const getADAccountDetails = (email) => get(url.USER + `/${email}`);
const postStaffUserAccount = (data) => post(url.USER, data);

const getStaffUserAccounts = async (data) => {
  return await axios.get(API_URL + "/user", {
    params: {
      Status: data.Status,
      RoleId: data.RoleId,
      SearchBy: data.SearchBy,
      PageNumber: data.PageNumber,
      PageSize: data.PageSize,
    },
  });
};
const updateUserAccount = (data) => put(url.USER, data);

const deleteUser = (id) => del(url.USER + "?id=" + id);

const getBuildingTypes = () => get(url.GET_BUILDING_TYPE_LIST);
const getBenchmarkvalues = () => get(url.GET_BENCHMARK);
const getParentCompanies = async (data) => {
  if (data.name && data.isParentCompany) {
    return await axios.get(
      `${API_URL}/clientBusinessAccount/list?name=${data.name}&isParentCompany=${data.isParentCompany}`
    );
  } else {
    return await axios.get(
      `${API_URL}/clientBusinessAccount/list?isParentCompany=${data.isParentCompany}`
    );
  }
};

const getGpParentCompanies = () =>
  get(
    url.POST_CLIENT_BUSINESS_ACCOUNT + "/get-grandparent-and-parent-companies"
  );

const getClientBusinessAccounts = (data) =>
  post(url.POST_CLIENT_BUSINESS_ACCOUNT + "/list", data);

const postClientBusinessAccount = (data) =>
  post(url.POST_CLIENT_BUSINESS_ACCOUNT, data);

const getClientAccountDetails = (id) =>
  get(url.POST_CLIENT_BUSINESS_ACCOUNT + `/${id}`);

const updateClientBusiness = (data) =>
  put(url.POST_CLIENT_BUSINESS_ACCOUNT, data);

//Audit

const postAuditAccount = (data) => post(url.POST_AUDIT_ACCOUNT, data);

const updateAuditAccount = (data) => put(url.POST_AUDIT_ACCOUNT, data);

const getAuditAccountDetails = (id) => get(url.POST_AUDIT_ACCOUNT + `/${id}`);

const getAuditList = (data) => post(url.POST_AUDIT_ACCOUNT + "/list", data);

const postOpportunityAudit = (data) =>
  post(url.POST_AUDIT_ACCOUNT + "/opportunities", data);

//Login
const postAuthData = (data) => post(url.POST_AUTHENTICATION + "/sign-in", data);

const postLogout = (data) => post(url.POST_AUTHENTICATION + "/sign-out", data);

//User
const getUserList = async (data) => {
  if (data) {
    return await axios.get(`${API_URL}/user/name?SearchByName=${data.name}`);
  } else {
    return await axios.get(`${API_URL}/user/name`);
  }
};
//Opportunity
const getOpportunitySummary = () => get(url.OPPORTUNITY_SUMMARY);
const getOppBenchmarkScores = (id) => get(url.GET_BENCHMARK + "/client/" + id);
const createOpportunity = (data) => post(url.OPPORTUNITY, data);
const getOpportunityList = (data) => post(url.OPPORTUNITY + "/list", data);
const deleteOpportunity = (id) => del(url.OPPORTUNITY + "/" + id);
const getOpportunityDeatails = (id) => get(url.OPPORTUNITY + "/" + id);
const updateOpportunity = (data) => put(url.OPPORTUNITY, data);
const deleteAttachments = (id) =>
  del(url.OPPORTUNITY + "/" + id + "/attachment");

//Product
const getProductMainCategory = () => get(url.PRODUCT_CATEGORY);

//Audit section
const createAuidtSection = (data) => post(url.AUDIT_SECTION, data);
const getAuditSections = (data) => post(url.AUDIT_SECTION + "/list", data);
const removeAuditSection = (id) => del(url.AUDIT_SECTION_DELETE + id);
const updateAuidtSection = (data) => put(url.AUDIT_SECTION, data);

//Audit findings
const getAuditFindings = (data) => post(url.AUDIT_FINDINGS + "/list", data);
const createAuditFindings = (data) => post(url.AUDIT_FINDINGS, data);
const updateAuditFinding = (data) => put(url.AUDIT_FINDINGS, data);
const getAuditFindingsDetails = (id) => get(url.AUDIT_FINDINGS + "/" + id);
const removeAuditfindings = (id) => del(url.AUDIT_FINDINGS + `/${id}`);

const removeMediaSection = (id, mediaId) =>
  del(url.AUDIT_FINDINGS + `/${id}` + `/media?mediaId=` + mediaId);
const downloadMediaStream = (id, mediaId, type) =>
  get(url.AUDIT_FINDINGS + `/${id}` + `/media/download?mediaId=` + mediaId);

//GLobal settings
const getGlobalSettings = () => get(url.GLOBAL_SETTINGS);
const createCarbonRoadmapSetting = (data) =>
  post(url.GLOBAL_SETTINGS + "/carbon-roadmap-settings", data);
const getCarbonRoadmapSetting = () =>
  get(url.GLOBAL_SETTINGS + "/carbon-roadmap-settings");
const postOpportunityGlobal = (data) =>
  post(url.GLOBAL_SETTINGS + "/opportunities", data);

//Executive Summary
const getExecutiveSummary = () =>
  get(url.AUDIT_REPORT + "/executive-summary-sections");

//Report Section
const getAuditReport = (id) => get(url.AUDIT_REPORT + `/${id}`);
const postReportSection = (data) => post(url.AUDIT_REPORT + "/preview", data);
const getFinancingPreviewData = (data) =>
  post(url.AUDIT_REPORT + "/financing-options", data);
const getPreviewRepaymentData = () => get(url.AUDIT_REPORT + "/repayment");

const postUnpublishReport = (id) =>
  post(url.AUDIT_REPORT + "/un-publish?id=" + id);

//Admin Analyst Dashboard
const getDashboard = () => get(url.DASHBOARD + "/admin-analyst");

//Client view
const getClientReportDetails = (id) => post(url.CLIENT_REPORT, id);
const getClientReportList = (data) => post(url.CLIENT_REPORT + "/list", data);
const getClientReportProjects = (data) =>
  post(url.CLIENT_REPORT + "/projects", data);
const getClientDashboard = (data) =>
  post(url.CLIENT_REPORT + "/dashboard", data);
const getForecastCacheGraphData = (data, company) =>
  post(url.CLIENT_REPORT + "/dashboard/" + data, company);
const getFinancingOptionsData = (data) =>
  post(url.CLIENT_REPORT + "/financing-options", data);
const getRepaymentReportData = () => get(url.CLIENT_REPORT + "/repayment");
const getCategorySummary = () => get(url.CLIENT_REPORT + "/filter");
const getCarbonRoadMap = (data) => get(url.CLIENT_REPORT + "/carbon-roadmap");
const getClientDashboardCompanies = () => get(url.CLIENT_REPORT + "/companies");

//Project
const projectActivate = (data) => post(url.CLIENT_PROJECT + "/active", data);
const getProjectList = (data) => post(url.CLIENT_PROJECT + "/list", data);
const getProjectDetails = (id) => get(url.CLIENT_PROJECT + `/${id}`);
const updateProject = (data) => put(url.CLIENT_PROJECT, data);
const projectDeleteAttachment = (id, attachmentId) =>
  del(url.CLIENT_PROJECT + `/${id}` + `/delete?attachmentId=` + attachmentId);

//Update Client Business - Dashboard Settings
const getDashboardSettings = () =>
  get(url.GLOBAL_SETTINGS + "/clint-dashboard");
const carbonDeleteAttachment = (id, attachmentId) =>
  del(
    url.POST_CLIENT_BUSINESS_ACCOUNT +
      `/${id}` +
      `/delete?attachmentId=` +
      attachmentId
  );

//Finance Settings
const getFinanceSettingsList = () => get(url.FINANCE_SETTINGS);
const createFinanceCategory = (data) =>
  post(url.FINANCE_SETTINGS + "/category", data);
const updateFinanceCategory = (data) =>
  put(url.FINANCE_SETTINGS + "/category", data);
const getFinanceCategory = (id) =>
  get(url.FINANCE_SETTINGS + "/category/" + id);
const removeCategory = (id) => del(url.FINANCE_SETTINGS + "/category?id=" + id);
const createRepayment = (data) =>
  post(url.FINANCE_SETTINGS + "/repayment-term", data);
const updateRepayment = (data) =>
  put(url.FINANCE_SETTINGS + "/repayment-term", data);
const getRepayment = (id) =>
  get(url.FINANCE_SETTINGS + "/repayment-term/" + id);
const removeRepayment = (id) =>
  del(url.FINANCE_SETTINGS + "/repayment-term?id=" + id);

//GP Dashboard
const getGPDMentorCalls = async (organizationId) => {
  if (organizationId) {
    return await axios.get(
      `${API_URL}/dashboard/get-carbon-mentor-calls?id=${organizationId}`
    );
  } else {
    return await axios.get(`${API_URL}/dashboard/get-carbon-mentor-calls`);
  }
};

const getGPDBaseLineReports = async (organizationId) => {
  if (organizationId) {
    return await axios.get(
      `${API_URL}/dashboard/get-baseline-reports?id=${organizationId}`
    );
  } else {
    return await axios.get(`${API_URL}/dashboard/get-baseline-reports`);
  }
};

const getGPDBuildingAudits = async (organizationId) => {
  if (organizationId) {
    return await axios.get(
      `${API_URL}/dashboard/get-building-audits?id=${organizationId}`
    );
  } else {
    return await axios.get(`${API_URL}/dashboard/get-building-audits`);
  }
};

const getGPDProjectFinancing = async (organizationId) => {
  if (organizationId) {
    return await axios.get(
      `${API_URL}/dashboard/get-investment-proposals?id=${organizationId}`
    );
  } else {
    return await axios.get(`${API_URL}/dashboard/get-investment-proposals`);
  }
};

const getGPDProjectDelivery = async (organizationId) => {
  if (organizationId) {
    return await axios.get(
      `${API_URL}/dashboard/get-completed-projects?id=${organizationId}`
    );
  } else {
    return await axios.get(`${API_URL}/dashboard/get-completed-projects`);
  }
};

const getGPDEcoWise = async (organizationId) => {
  if (organizationId) {
    return await axios.get(
      `${API_URL}/dashboard/get-stakeholder-engagements?id=${organizationId}`
    );
  } else {
    return await axios.get(`${API_URL}/dashboard/get-stakeholder-engagements`);
  }
};

const getDasboardBusinessAccounts = async (organizationId) => {
  if (organizationId) {
    return await axios.post(
      `${API_URL}/dashboard/get-bussiness-account-detials?id=${organizationId}`
    );
  } else {
    return await axios.post(
      `${API_URL}/dashboard/get-bussiness-account-detials`
    );
  }
};

const searchBuildingAudits = (param) =>
  post(url.DASHBOARD + "/search-building-audits", param);

const searchInvestmentProposal = (param) =>
  post(url.DASHBOARD + "/search-investment-proposals", param);

const searchCompletedProjects = (param) =>
  post(url.DASHBOARD + "/search-completed-projects", param);

const searchBaselineReports = (param) =>
  post(url.DASHBOARD + "/search-baseline-reports", param);

const searchcarbonMentorCalls = (param) =>
  post(url.DASHBOARD + "/search-carbon-mentor-calls", param);

//EPC

const getEPCScore = async (data) => {
  return await axios.get(
    `${API_URL}/epc/get-current-epc?address=${data.address}&postcode=${data.postcode}&county=${data.county}`
  );
};

const getReportsPerSite = async (data) => {
  return await axios.get(
    `${API_URL}/epc/audit-report-list?clinetBussinessAccountId=${data}`
  );
};

const getOpportunitiesPerReport = async (data) => {
  return await axios.get(`${API_URL}/epc/get-opportunities?reportId=${data}`);
};

const getSASToken = async (data) => {
  return await axios.get(`${API_URL}/dashboard/get-SAS-token`);
};

export {
  createUserRolePost,
  getUserRolePermission,
  userRolesGet,
  userRoleGet,
  updateUserRole,
  getADAccountDetails,
  postStaffUserAccount,
  getStaffUserAccounts,
  updateUserAccount,
  getBuildingTypes,
  getParentCompanies,
  getBenchmarkvalues,
  postClientBusinessAccount,
  getClientBusinessAccounts,
  getClientAccountDetails,
  updateClientBusiness,
  postAuditAccount,
  updateAuditAccount,
  getAuditAccountDetails,
  postAuthData,
  postLogout,
  getUserList,
  getAuditList,
  getOpportunitySummary,
  getProductMainCategory,
  createAuidtSection,
  getAuditSections,
  removeAuditSection,
  removeAuditfindings,
  updateAuidtSection,
  getAuditFindings,
  createAuditFindings,
  updateAuditFinding,
  removeMediaSection,
  downloadMediaStream,
  getAuditFindingsDetails,
  getGlobalSettings,
  getOppBenchmarkScores,
  createOpportunity,
  getOpportunityList,
  deleteOpportunity,
  getOpportunityDeatails,
  updateOpportunity,
  deleteAttachments,
  getAuditReport,
  getExecutiveSummary,
  postUnpublishReport,
  getDashboard,
  getClientReportDetails,
  getClientReportList,
  getClientReportProjects,
  projectActivate,
  getProjectList,
  getProjectDetails,
  updateProject,
  projectDeleteAttachment,
  getClientDashboard,
  getDashboardSettings,
  getFinanceSettingsList,
  createFinanceCategory,
  updateFinanceCategory,
  getFinanceCategory,
  createRepayment,
  updateRepayment,
  getRepayment,
  getForecastCacheGraphData,
  getFinancingOptionsData,
  getRepaymentReportData,
  carbonDeleteAttachment,
  getCarbonRoadMap,
  getCategorySummary,
  getClientDashboardCompanies,
  postReportSection,
  getFinancingPreviewData,
  getPreviewRepaymentData,
  createCarbonRoadmapSetting,
  getCarbonRoadmapSetting,
  postOpportunityAudit,
  postOpportunityGlobal,
  removeRepayment,
  removeCategory,
  getGPDMentorCalls,
  getGPDBaseLineReports,
  getGPDBuildingAudits,
  getGPDProjectDelivery,
  getGPDProjectFinancing,
  getGPDEcoWise,
  getDasboardBusinessAccounts,
  searchBuildingAudits,
  searchInvestmentProposal,
  searchCompletedProjects,
  searchBaselineReports,
  searchcarbonMentorCalls,
  getGpParentCompanies,
  getEPCScore,
  getReportsPerSite,
  getOpportunitiesPerReport,
  getSASToken,
  deleteUser,
};
