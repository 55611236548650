import React, { useEffect, useState } from "react";
import { getClientCarbonRoadMap } from "../../../store/Client/CarbonRoadMap/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Container } from "reactstrap";
import { Col, Button, Row, Card } from "react-bootstrap";
import DocumentIcon from "../../../assets/images/icons/DocumentIcon.svg";
import { downloadExtrnal } from "./helpers/index";
import { Download } from "react-feather";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { resetEPC } from "../../../store/EPC/actions";

const CarbonRoadMap = (props) => {
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  let navigate = useNavigate();

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const SettingsType = {
    SECTIONTITLE: "Section title",
    SECTIONDESCRIPTION: "Description under the section title",
    READMOREURL: "'Read More' URL",
  };

  Object.freeze(SettingsType);

  const [slicedArrayDoc, setSlicedArrayDoc] = useState([]);

  const [carbonTitleBeforeUpload, setCarbonTitleBeforeUpload] = useState("");
  const [carbonDesBeforeUpload, setCarbonDesBeforeUpload] = useState("");
  const [readUrl, setReadUrl] = useState("");
  const [carbonTitleAfterUpload, setCarbonTitleAfterUpload] = useState("");
  const [carbonDesAfterUpload, setCarbonDesAfterUpload] = useState("");

  useEffect(() => {
    props.getClientCarbonRoadMap();
    props.resetEPC();
  }, []);

  useEffect(() => {
    if (props.clientCarbonDetails) {
      let carbonDetails = props.clientCarbonDetails.data;
      let carbonRoadMapSettings = carbonDetails.carbonRoadMapSettings;
      let carbonSettings = carbonRoadMapSettings.settings;

      let carbonAttachments = carbonDetails.carbonRoadMapAttachments;
      if (carbonAttachments.length > 0) {
        let AfterTitleObject = carbonSettings.find(
          (p) => p.type === SettingsType.SECTIONTITLE
        );
        let AfterDesObject = carbonSettings.find(
          (p) => p.type === SettingsType.SECTIONDESCRIPTION
        );
        setCarbonTitleAfterUpload(AfterTitleObject.value);
        setCarbonDesAfterUpload(AfterDesObject.value);
      } else {
        let BeforeTitleObject = carbonSettings.find(
          (p) => p.type === SettingsType.SECTIONTITLE
        );
        let BeforeDesObject = carbonSettings.find(
          (p) => p.type === SettingsType.SECTIONDESCRIPTION
        );
        let BeforeUrlObject = carbonSettings.find(
          (p) => p.type === SettingsType.READMOREURL
        );
        setCarbonTitleBeforeUpload(BeforeTitleObject.value);
        setCarbonDesBeforeUpload(BeforeDesObject.value);
        setReadUrl(BeforeUrlObject.value);
      }
      setSlicedArrayDoc(carbonAttachments);
    }
  }, [props.clientCarbonDetails]);

  return (
    <React.Fragment>
      {" "}
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Carbon Roadmap"}</title>
        </Helmet>
        <Container fluid>
          {slicedArrayDoc.length > 0 ? (
            <div>
              <Row>
                <div className="carbon-title">{carbonTitleAfterUpload}</div>
                <div className="carbon-paragraph">
                  <Col lg={6}>{carbonDesAfterUpload}</Col>
                </div>
              </Row>
              <hr />

              <Row className="mt-2 m-0">
                {slicedArrayDoc.length > 0 &&
                  slicedArrayDoc.map((docData, i) => (
                    <Card className="card-video grid-view mt-2" key={i}>
                      <Col lg={12}>
                        <Row style={{ margin: "20px 0" }}>
                          <Col lg={1} xs={2}>
                            <img
                              className="edit-img"
                              src={DocumentIcon}
                              alt=""
                              width={35}
                              height={35}
                            />
                          </Col>
                          <Col lg={7} xs={8}>
                            <div style={{ marginBottom: 10 }}>
                              <span
                                className="grid-filename"
                                style={{
                                  textDecoration: "underline",
                                }}
                              >
                                {docData.title}
                              </span>
                            </div>

                            <span className="grid-note">Description</span>
                            <br />
                            <div className={`grid-info`}>
                              {docData.description}&nbsp;
                            </div>
                          </Col>
                          <Col
                            lg={4}
                            xs={2}
                            className="grid-right-btn text-end"
                          >
                            <span
                              onClick={() =>
                                downloadExtrnal(
                                  docData.id,
                                  docData.mimeType,
                                  docData.title
                                )
                              }
                            >
                              <Download size={25} />
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Card>
                  ))}
              </Row>
            </div>
          ) : (
            <div>
              <Row>
                <div className="carbon-title">{carbonTitleBeforeUpload}</div>

                <div className="carbon-paragraph">
                  <Col lg={6}>{carbonDesBeforeUpload}</Col>
                </div>
              </Row>
              <hr />
              <Row>
                <Col lg={3}>
                  <Button
                    className="sort-primary-btn btn-min-width"
                    type="button"
                    onClick={() => window.open(`${readUrl}`, "_blank")}
                  >
                    Click here to learn more
                  </Button>
                </Col>
                <Col lg={9}></Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

CarbonRoadMap.propTypes = {
  getClientCarbonRoadMap: PropTypes.func,
  clientCarbonDetails: PropTypes.any,
  resetEPC: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getClientCarbonRoadMap: state.ClientCarbon.getClientCarbonRoadMap,
    clientCarbonDetails: state.ClientCarbon.clientCarbonDetails,
    resetEPC: state.EPCDetails.resetEPC,
  };
};

export default connect(mapStateToProps, {
  getClientCarbonRoadMap,
  resetEPC,
})(CarbonRoadMap);
