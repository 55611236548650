import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Repayment from "../repayment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DiscardChangesPopup from "../../../../../components/common/discardChangesPopup";
import {
  updateFinanceRepayment,
  getFinanceRepaymentDetails,
} from "../../../../../store/Settings/actions";
import { Alert } from "reactstrap";
import ErrorValidation from "../../../../../assets/images/icons/ErrorValidation.svg";
import { getDecodeAuthDetails } from "../../../../../helpers";

const UpdateRepayment = (props) => {
  const {
    showEditRepay,
    setShowEditRepay,
    selectedRowRepay,
    setUpdateRepaySuccess,
    setUpdateRepayError,
    setShowAlertRepay,
    updateRepayError,
    showAlertRepay,
    errorRepay,
    setSelectedRowRepay,
  } = props;

  let decoded = getDecodeAuthDetails();

  const permissions = decoded.Permission;
  const [term, setTerm] = useState("");
  const [rate, setRate] = useState("");

  const [termRequired, setTermRequired] = useState(false);
  const [termPositive, setTermPositive] = useState(false);
  const [termValidation, setTermValidation] = useState(false);
  const [termMessage, setTermMessage] = useState("");
  const [rateRequired, setRateRequired] = useState(false);
  const [ratePositive, setRatePositive] = useState(false);

  const [isSubmitEnable, setIsSubmitEnable] = useState(false);

  useEffect(() => {
    if (showEditRepay) {
      props.getFinanceRepaymentDetails(selectedRowRepay);
    }
  }, [showEditRepay]);

  useEffect(() => {
    if (props.financeRepaymentDetails) {
      let financeRepayData = props.financeRepaymentDetails.data;
      setTerm(financeRepayData.term.toString());
      setRate(financeRepayData.rate.toString());
    }
  }, [props.financeRepaymentDetails]);

  const [isShowDisacrdConfirmation, setIsShowDisacrdConfirmation] =
    useState(false);
  const [createCancel, setCreateCancel] = useState(false);

  const handleDiscardConfirmation = () => {
    setIsShowDisacrdConfirmation(false);
  };

  const handleCloseEditRepay = () => {
    setShowEditRepay(false);
    resetForm();
    setShowAlertRepay(false);
    setUpdateRepaySuccess(false);
    setUpdateRepayError(false);
  };

  const backToViewPage = () => {
    setIsShowDisacrdConfirmation(false);
    setShowEditRepay(false);
    resetForm();
    setShowAlertRepay(false);
    setUpdateRepaySuccess(false);
    setUpdateRepayError(false);
  };

  const cancelForm = () => {
    if (createCancel) {
      setIsShowDisacrdConfirmation(true);
    } else {
      backToViewPage();
    }
  };

  const formValidation = () => {
    let errors = [];

    if (term === "") {
      setTermRequired(true);
      setTermPositive(false);
      errors.push(1);
    } else if (parseFloat(term.replace(/,/g, "")) === 0) {
      setTermPositive(true);
      setTermRequired(false);
      errors.push(1);
    } else {
      setTermRequired(false);
      setTermPositive(false);
    }

    if (rate === "") {
      setRateRequired(true);
      errors.push(1);
    } else if (parseFloat(rate) < 0) {
      setRatePositive(true);
      setRateRequired(false);
      errors.push(1);
    } else {
      setRateRequired(false);
      setRatePositive(false);
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = formValidation();

    if (valid.length === 0) {
      setIsSubmitEnable(true);
      let financeRepaymentObj = {
        id: selectedRowRepay,
        term: parseInt(term),
        rate: parseFloat(rate),
      };
      props.updateFinanceRepayment(financeRepaymentObj);
    }
  };

  useEffect(() => {
    if (props.isSuccessUpdateFinanceRepayment) {
      setShowAlertRepay(true);
      setShowEditRepay(false);
      setUpdateRepaySuccess(true);
      window.scrollTo(0, 0);
      resetForm();

      window.setTimeout(() => {
        setShowAlertRepay(false);
        setUpdateRepaySuccess(false);
      }, 3000);
      setIsSubmitEnable(false);
    }
  }, [props.isSuccessUpdateFinanceRepayment]);

  useEffect(() => {
    if (props.isFailedUpdateFinanceRepayment) {
      setIsSubmitEnable(false);
      setUpdateRepayError(true);
      setShowAlertRepay(true);
      window.scroll(0, 0);
      window.setTimeout(() => {
        setShowAlertRepay(false);
        setUpdateRepayError(false);
      }, 5000);
    }
  }, [props.isFailedUpdateFinanceRepayment]);

  useEffect(() => {
    if (props.isFailedUpdateFinanceRepayment) {
      if (props.error && props.error.error) {
        if (props.error.error.Term) {
          setTermValidation(true);
          setTermMessage(props.error.error.Term[0]);
        }
      }
    } else {
      setTermValidation(false);
      setTermMessage("");
    }
  }, [props.isFailedUpdateFinanceRepayment]);

  const resetForm = () => {
    setTerm("");
    setRate("");
    setTermRequired(false);
    setTermPositive(false);
    setTermValidation(false);
    setTermMessage("");
    setRateRequired(false);

    setRatePositive(false);
    setCreateCancel(false);
    setSelectedRowRepay(null);
  };

  return (
    <React.Fragment>
      {" "}
      <Modal show={showEditRepay} className="modal-repayment">
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12}>
              <Modal.Title className="text-center">
                Edit Repayment Terms & Rates
              </Modal.Title>
              <span className="close-icon" onClick={handleCloseEditRepay}>
                x
              </span>
            </Col>
          </Row>
        </Modal.Header>
        {permissions.includes("PERMISSIONS.FINANCINGOPTIONSETTING.REPAYMENTTERMEDIT") ? (
          <Modal.Body style={{ padding: 40 }}>
          {updateRepayError && showAlertRepay && (
            <Row className="px-2">
              {errorRepay.map((item) => {
                return (
                  <Alert
                    key={item}
                    color="danger"
                    isOpen={updateRepayError && showAlertRepay}
                  >
                    <img src={ErrorValidation} alt="error" width={30} height={30}/>
                    {item}
                  </Alert>
                );
              })}
            </Row>
          )}
          {updateRepayError && showAlertRepay && (
            <Row className="px-2">
              <Alert color="danger" isOpen={updateRepayError && showAlertRepay}>
                <img src={ErrorValidation} alt="error" width={30} height={30}/>
                Repayment term and rate could not be updated
              </Alert>
            </Row>
          )}
          <Repayment
            term={term}
            setTerm={setTerm}
            rate={rate}
            setRate={setRate}
            termRequired={termRequired}
            setTermRequired={setTermRequired}
            termPositive={termPositive}
            setTermPositive={setTermPositive}
            termValidation={termValidation}
            setTermValidation={setTermValidation}
            termMessage={termMessage}
            setTermMessage={setTermMessage}
            rateRequired={rateRequired}
            setRateRequired={setRateRequired}
            ratePositive={ratePositive}
            setRatePositive={setRatePositive}
            setCreateCancel={setCreateCancel}
          />
          <DiscardChangesPopup
            isShowDisacrdConfirmation={isShowDisacrdConfirmation}
            handleDiscardConfirmation={handleDiscardConfirmation}
            backToViewPage={backToViewPage}
          />
          <Row className={"mt-3"}>
            <Col lg={12} className={"text-center"}>
              <Button
                className="sort-primary-btn btn-min-width"
                style={{ margin: "0 10px" }}
                type={"button"}
                onClick={handleSubmit}
                disabled={isSubmitEnable}
              >
                Save
              </Button>
              <Button
                className="sort-primary-outline-btn btn-min-width"
                onClick={() => cancelForm()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        ):(
          <Modal.Body style={{ padding: 40 }}>
            <Row>
              <p className="update-modal-body">
                You don't have permission to perform this action. Please contact
                the Admin.
              </p>
            </Row>
          </Modal.Body>
        )}
        
      </Modal>
    </React.Fragment>
  );
};

UpdateRepayment.propTypes = {
  updateFinanceRepayment: PropTypes.func,
  getFinanceRepaymentDetails: PropTypes.func,
  financeRepaymentDetails: PropTypes.any,
  isSuccessUpdateFinanceRepayment: PropTypes.any,
  error: PropTypes.any,
  isFailedUpdateFinanceRepayment: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isSuccessUpdateFinanceRepayment:
      state.Settings.isSuccessUpdateFinanceRepayment,
    isFailedUpdateFinanceRepayment:
      state.Settings.isFailedUpdateFinanceRepayment,
    error: state.Settings.error,
    financeRepaymentDetails: state.Settings.financeRepaymentDetails,
  };
};

export default connect(mapStateToProps, {
  updateFinanceRepayment,
  getFinanceRepaymentDetails,
})(UpdateRepayment);
