import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { Alert } from "reactstrap";
import InfoTooltip from "../../../components/common/tooltip";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import UploadVideo from "./subComponents/uploadVideo";
import IconographyTable from "../../../components/common/icongrphyTable";
import { getGlobalSettings } from "../../../store/Settings/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { getLoggedUser, getDecodeAuthDetails } from "../../../helpers";

const Guide = (props) => {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;

  let decoded = getDecodeAuthDetails();
  const permissions = decoded.Permission;

  const [disablePermission, setDisablePermission] = useState(false);

  useEffect(() => {
    if (permissions.includes("PERMISSIONS.GLOBALSETTING.CREATE")) {
      setDisablePermission(false);
    } else {
      setDisablePermission(true);
    }
  }, []);

  let returnVideoArray = [];
  const assignExistingVideo = (video) => {
    let tempObj = {};

    tempObj = {
      file: video,
      name: "",
      binaryFile: null,
    };
    returnVideoArray.push(tempObj);

    return returnVideoArray;
  };

  const [id, setId] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [energyToCarbon, setEnergyToCarbon] = useState("");
  const [waterToCarbon, setWaterToCarbon] = useState("");
  const [carbonToNoOfHours, setCarbonToNoOfHours] = useState("");
  const [carbonToNoOfNights, setCarbonToNoOfNights] = useState("");
  const [averageTravelling, setAverageTravelling] = useState("");
  const [annualEnergyCost, setAnnualEnergyCost] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [statusChange, setStatusChange] = useState(false);

  const [carbonFootPrintTitle, setCarbonFootPrintTitle] = useState("");
  const [carbonFootNote, setCarbonFootNote] = useState("");
  const [forecastCashFlow, setForecastCashFlow] = useState("");
  const [costInaction, setCostInaction] = useState("");

  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [untappedSection, setUntappedSection] = useState("");
  const [untappedSubSection, setUntappedSubSection] = useState("");
  const [potentialIOSubSection, setPotentialIOSubSection] = useState("");
  const [energySubSection, setEnergySubSection] = useState("");
  const [waterSubSection, setWaterSubSection] = useState("");
  const [carbonFootPrint, setCarbonFootPrint] = useState("");
  const [leftTitle, setLeftTitle] = useState("");
  const [leftSubTitle, setLeftSubTitle] = useState("");
  const [rightTitle, setRightTitle] = useState("");
  const [rightSubTitle, setRightSubTitle] = useState("");
  const [forecastTitle, setForecastTitle] = useState("");
  const [costInactionTitle, setCostInactionTitle] = useState("");
  const [capitalPlanning, setCapitalPlanning] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitEnable, setIsSubmitEnable] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const [guideSuccess, setGuideSuccess] = useState(false);
  const [guideError, setGuideError] = useState(false);
  const [error, setError] = useState([]);
  const [uploadClick, setUploadClick] = useState(false);
  const [videoRequired, setVideoRequired] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState([]);
  const [adjustedInvestmentRate, setAdjustedInvestmentRate] = useState("");

  useEffect(() => {
    if (guideSuccess) {
      props.getGlobalSettings();
    }
  }, [guideSuccess]);

  useEffect(() => {
    if (props.globalParameters) {
      let settings = props.globalParameters && props.globalParameters.data;
      let clientDefaultSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.defaultClientSettings;
      let universalDashboardSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.universalSettings;
      let globalSettings =
        settings && props.globalParameters.data.globalSetting;
      let reportSettings =
        settings && props.globalParameters.data.reportSetting;

      setId(settings.id);

      if (universalDashboardSettings.UntappedSavingsTitle !== null) {
        setUntappedSection(universalDashboardSettings.UntappedSavingsTitle);
      }

      setUntappedSubSection(
        universalDashboardSettings.potentialCarbonSavingsTitle
      );
      setPotentialIOSubSection(
        universalDashboardSettings.potentialPAndLImprovementOpportunitiesTitle
      );
      setEnergySubSection(
        universalDashboardSettings.potentialEnergySavingsTitle
      );
      setWaterSubSection(universalDashboardSettings.potentialWaterSavingsTitle);

      if (universalDashboardSettings.CarbonFoodPrintTitle !== null) {
        setCarbonFootPrint(universalDashboardSettings.CarbonFoodPrintTitle);
      }

      if (universalDashboardSettings.CarbonFoodPrintLeftBlockTitle !== null) {
        setLeftTitle(universalDashboardSettings.CarbonFoodPrintLeftBlockTitle);
      }

      if (
        universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle !== null
      ) {
        setLeftSubTitle(
          universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle
        );
      }

      if (universalDashboardSettings.CarbonFoodPrintRightBlockTitle !== null) {
        setRightTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockTitle
        );
      }

      if (
        universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle !== null
      ) {
        setRightSubTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle
        );
      }

      if (universalDashboardSettings.ForcastCashFlowTitle !== null) {
        setForecastTitle(universalDashboardSettings.ForcastCashFlowTitle);
      }

      if (universalDashboardSettings.ProjectedCostofInactionTitle !== null) {
        setCostInactionTitle(
          universalDashboardSettings.ProjectedCostofInactionTitle
        );
      }

      if (universalDashboardSettings.CapitalPlanning !== null) {
        setCapitalPlanning(universalDashboardSettings.CapitalPlanning);
      }

      if (clientDefaultSettings.CarbonFoodPrintDescription !== null) {
        setCarbonFootPrintTitle(
          clientDefaultSettings.CarbonFoodPrintDescription
        );
      }

      if (clientDefaultSettings.CarbonFoodPrintRightBlockNote !== null) {
        setCarbonFootNote(clientDefaultSettings.CarbonFoodPrintRightBlockNote);
      }

      if (clientDefaultSettings.ForcastCashFlowDescription !== null) {
        setForecastCashFlow(clientDefaultSettings.ForcastCashFlowDescription);
      }

      if (clientDefaultSettings.ProjectedCostofInactionDescription !== null) {
        setCostInaction(
          clientDefaultSettings.ProjectedCostofInactionDescription
        );
      }

      if (globalSettings.energyToCarbon % 1 === 0) {
        setEnergyToCarbon(globalSettings.energyToCarbon.toFixed(2));
      } else {
        setEnergyToCarbon(globalSettings.energyToCarbon);
      }

      if (globalSettings.waterToCarbon % 1 === 0) {
        setWaterToCarbon(globalSettings.waterToCarbon.toFixed(2));
      } else {
        setWaterToCarbon(globalSettings.waterToCarbon);
      }

      if (globalSettings.carbonToTVHours % 1 === 0) {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours.toFixed(2));
      } else {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours);
      }

      if (globalSettings.carbonToNoOfNightUKHotel % 1 === 0) {
        setCarbonToNoOfNights(
          globalSettings.carbonToNoOfNightUKHotel.toFixed(2)
        );
      } else {
        setCarbonToNoOfNights(globalSettings.carbonToNoOfNightUKHotel);
      }

      if (globalSettings.averageTravelingOnLongHaulFlights % 1 === 0) {
        setAverageTravelling(
          globalSettings.averageTravelingOnLongHaulFlights.toFixed(2)
        );
      } else {
        setAverageTravelling(globalSettings.averageTravelingOnLongHaulFlights);
      }

      if (globalSettings.annualEnergyCostsIncrease % 1 === 0) {
        setAnnualEnergyCost(
          globalSettings.annualEnergyCostsIncrease.toFixed(2)
        );
      } else {
        setAnnualEnergyCost(globalSettings.annualEnergyCostsIncrease);
      }
      if (reportSettings.aboutSMCC !== null) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(reportSettings.aboutSMCC))
          )
        );
      } else {
        setEditorState(EditorState.createEmpty());
      }

      if (reportSettings.videoTitle !== null) {
        setTitle(reportSettings.videoTitle);
      }

      if (reportSettings.videoDisplay === 0) {
        setStatusChange(false);
      }

      if (reportSettings.videoDisplay === 1) {
        setStatusChange(true);
      }
      if (reportSettings.videoDescription !== null) {
        setDescription(reportSettings.videoDescription);
      }
      if (globalSettings.financingBrokerageFee !== null) {
        setAdjustedInvestmentRate(globalSettings.financingBrokerageFee);
      }

      if (universalDashboardSettings.welcomeMessage !== null) {
        setWelcomeMsg(universalDashboardSettings.welcomeMessage);
      }

      if (reportSettings.videoPath !== "") {
        setUploadedVideo(
          props.globalParameters
            ? assignExistingVideo(reportSettings.videoPath)
            : []
        );
      }
    }
  }, [props.globalParameters]);

  const handleTitle = (e) => {
    setTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "title") {
        setTitleRequired(false);
      }
    } else {
      if (e.target.name === "title") {
        setTitleRequired(true);
      }
    }
  };

  const handleDescription = (e) => {
    setDescription(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "description") {
        setDescriptionRequired(false);
      }
    } else {
      if (e.target.name === "description") {
        setDescriptionRequired(true);
      }
    }
  };

  const formValidation = () => {
    let isvalid = true;
    let errors = [];

    if (uploadedVideo.length === 0) {
      setVideoRequired(true);
      isvalid = false;
      errors.push(1);
    } else {
      setVideoRequired(false);
      isvalid = true;
    }

    if (title === "") {
      setTitleRequired(true);
      isvalid = false;
      errors.push(1);
    } else {
      setTitleRequired(false);
      isvalid = true;
    }

    if (description === "") {
      setDescriptionRequired(true);
      isvalid = false;
      errors.push(1);
    } else {
      setDescriptionRequired(false);
      isvalid = true;
    }

    return errors;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    let valid = formValidation();
    var convertedData = convertToRaw(editorState.getCurrentContent());
    let videoFile;
    if (uploadedVideo.length > 0) {
      videoFile = uploadedVideo[0].binaryFile;
    }

    if (valid.length === 0) {
      setShowAlert(true);
      setIsSubmitEnable(true);
      setGuideSuccess(false);
      setGuideError(false);

      let form_data = new FormData();
      form_data.append("Id", id);

      form_data.append(
        "DashboardSetting.UniversalSettings.WelcomeMessage",
        welcomeMsg
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.UntappedSavingsTitle",
        untappedSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialCarbonSavingsTitle",
        untappedSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialPAndLImprovementOpportunitiesTitle",
        potentialIOSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialEnergySavingsTitle",
        energySubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialWaterSavingsTitle",
        waterSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintTitle",
        carbonFootPrint
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockTitle",
        leftTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockSubtitle",
        leftSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockTitle",
        rightTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockSubtitle",
        rightSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ForcastCashFlowTitle",
        forecastTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ProjectedCostofInactionTitle",
        costInactionTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CapitalPlanning",
        capitalPlanning
      );

      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintDescription",
        carbonFootPrintTitle
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintRightBlockNote",
        carbonFootNote
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ForcastCashFlowDescription",
        forecastCashFlow
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ProjectedCostofInactionDescription",
        costInaction
      );

      form_data.append("GlobalSetting.EnergyToCarbon", energyToCarbon);
      form_data.append("GlobalSetting.WaterToCarbon", waterToCarbon);
      form_data.append("GlobalSetting.CarbonToTVHours", carbonToNoOfHours);
      form_data.append(
        "GlobalSetting.CarbonToNoOfNightUKHotel",
        carbonToNoOfNights
      );
      form_data.append(
        "GlobalSetting.AverageTravelingOnLongHaulFlights",
        averageTravelling
      );
      form_data.append(
        "GlobalSetting.AnnualEnergyCostsIncrease",
        annualEnergyCost
      );
      form_data.append(
        "GlobalSetting.FinancingBrokerageFee",
        adjustedInvestmentRate
      );

      form_data.append(
        "ReportSetting.AboutSMCC",
        JSON.stringify(convertedData)
      );

      form_data.append("ReportSetting.Video", videoFile);

      form_data.append("ReportSetting.VideoTitle", title.trim());

      form_data.append(
        "ReportSetting.VideoDisplay",
        statusChange === true ? 1 : 0
      );

      form_data.append("ReportSetting.VideoDescription", description.trim());

      new Promise((resolve, reject) => {
        fetch(API_URL + "/GlobalSetting", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + getLoggedUser().accessToken,
          },
          body: form_data,
        })
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            if (data.isError) {
              setGuideSuccess(false);
              setGuideError(true);
              let emptyArray = [];
              for (const [key, value] of Object.entries(
                data.responseException.exceptionMessage.fieldErrors
              )) {
                emptyArray.push(value[0]);
              }
              setError(emptyArray);
              setIsSubmitEnable(false);
              window.scrollTo(0, 0);
              window.setTimeout(() => {
                setGuideError(false);
                setShowAlert(false);
              }, 5000);
            } else {
              setGuideSuccess(true);
              setGuideError(false);
              setIsSubmitEnable(false);
              window.scrollTo(0, 0);
              window.setTimeout(() => {
                setGuideSuccess(false);
                setShowAlert(false);
              }, 5000);
            }
            resolve();
          })
          .catch((err) => {
            console.log("err", JSON.stringify(err));
          });
      });
    }
  };

  const uploadVideo = (e) => {
    e.preventDefault();
    setUploadClick(true);
    setUploadedVideo([]);
  };

  const handleStatusChange = (e) => {
    console.log(e.target.checked);
    setStatusChange(e.target.checked);
  };

  return (
    <React.Fragment>
      {guideError && showAlert && (
        <Row className="px-2">
          {error.map((item) => {
            return (
              <Alert key={item} color="danger" isOpen={guideError && showAlert}>
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                {item}
              </Alert>
            );
          })}
        </Row>
      )}
      {guideError && showAlert && (
        <Row className="px-2">
          <Alert color="danger" isOpen={guideError && showAlert}>
            <img src={ErrorValidation} alt="error" width={30} height={30} />
            Changes could not be saved
          </Alert>
        </Row>
      )}
      {guideSuccess && showAlert && (
        <Row className="px-2">
          <Alert color="success" isOpen={guideSuccess && showAlert}>
            <img
              src={SuccessfulValidation}
              alt="success"
              width={30}
              height={30}
            />
            Changes saved successfully
          </Alert>
        </Row>
      )}
      {permissions.includes("PERMISSIONS.GLOBALSETTING.VIEW") ? (
        <Form className="py-1">
          <Form.Group className="mb-3 row mt-1" controlId="formSettingsFields">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Video Demo &nbsp;
                <InfoTooltip text={"Video Demo"} id="settingsAccount" />
              </Form.Label>
            </Col>
            <Col sm={4} md={3} lg={3} className="sort-form-field">
              <Row>
                <Col>
                  {statusChange === true && (
                    <p style={{ margin: 0 }}>
                      <span className="sort-badge badge-active"></span>
                      &nbsp;
                      {"Video guide displayed in report"}
                    </p>
                  )}
                  {statusChange === false && (
                    <p style={{ margin: 0 }}>
                      <span className="sort-badge badge-inactive"></span>
                      &nbsp;
                      {"Video guide removed from report"}
                    </p>
                  )}
                </Col>
                <Col lg={2}>
                  <div className="toggle-switch3">
                    <input
                      type="checkbox"
                      className="checkbox3"
                      name="childCompany"
                      id="toggle3"
                      checked={statusChange}
                      onChange={handleStatusChange}
                    />
                    <label className="label3" htmlFor="toggle3">
                      <span className="inner3" />
                      <span className="switch3" />
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
            {statusChange === true && (
              <Col sm={4} md={3} lg={5} className="sort-form-field">
                <Button
                  className={`sort-primary-btn btn-min-width`}
                  type="button"
                  onClick={uploadVideo}
                  style={{ marginLeft: 10, float: "right" }}
                >
                  Upload Video
                </Button>
              </Col>
            )}
          </Form.Group>
          {statusChange === true && (
            <div>
              <UploadVideo
                uploadClick={uploadClick}
                setUploadClick={setUploadClick}
                uploadedVideo={uploadedVideo}
                setUploadedVideo={setUploadedVideo}
                videoRequired={videoRequired}
                setVideoRequired={setVideoRequired}
              />

              <Form.Group
                className="mb-3 row mt-1"
                controlId="formSettingsFields"
              >
                <Col sm={4} md={3} lg={3}>
                  <Form.Label className="sort-form-section-lable"></Form.Label>
                </Col>
                <Col lg={8} className="sort-form-field">
                  <Form.Label className={`sort-form-lable`}>Title*</Form.Label>
                  <div className="sort-form-wrapper">
                    <div className="form-group sort-form-group">
                      <input
                        name="title"
                        id="title"
                        type="text"
                        className={`sort-form-input form-control ${
                          titleRequired ? "errorField" : ""
                        } `}
                        placeholder="Type video title"
                        value={title}
                        onChange={handleTitle}
                      />
                      {titleRequired && (
                        <img
                          src={ErrorValidation}
                          alt="error"
                          width={20}
                          height={20}
                          className="errorImg"
                        />
                      )}
                    </div>
                    <span
                      className={`inline-form-error ${
                        titleRequired ? "display-error" : ""
                      }`}
                    >
                      Please enter the video title.
                    </span>
                  </div>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3 row mt-1">
                <Col sm={4} md={3} lg={3}>
                  <Form.Label className="sort-form-section-lable"></Form.Label>
                </Col>
                <Col lg={8} className="sort-form-field">
                  <Form.Label className={`sort-form-lable`}>
                    Description*
                  </Form.Label>
                  <div className="sort-form-wrapper">
                    <div className="form-group sort-form-group">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        id="description"
                        type="text"
                        placeholder="Type video description"
                        className={`sort-form-input form-control ${
                          descriptionRequired ? "errorField" : ""
                        } `}
                        value={description}
                        onChange={handleDescription}
                      />

                      {descriptionRequired && (
                        <img
                          src={ErrorValidation}
                          alt="error"
                          width={20}
                          height={20}
                          className="errorImg"
                          style={{ top: 30, right: -30 }}
                        />
                      )}
                    </div>
                    <span
                      className={`inline-form-error ${
                        descriptionRequired ? "display-error" : ""
                      }`}
                    >
                      Please enter the description of the video.
                    </span>
                  </div>
                </Col>
              </Form.Group>
            </div>
          )}
          <hr />
          <Form.Group className="mb-3 row mt-1" controlId="formSettingsFields">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Iconography &nbsp;
                <InfoTooltip text={"Iconography"} id="settingsAccount" />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <IconographyTable />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group className="mb-3 row mt-1" controlId="formSettingsFields">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Scoring Criteria &nbsp;
                <InfoTooltip text={"Scoring Criteria"} id="settingsAccount" />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <div className="score-criteria">
                <p>
                  Please to navigate to the scoring criteria section in a client
                  business account in the SORT system to view the default
                  scoring criteria or the specific scoring criteria that has
                  been assigned to the particular client of SaveMoneyCutCarbon.
                </p>
              </div>
            </Col>
          </Form.Group>
          <hr />
          <div className="mt-4 text-center mb-3">
            <Button
              className={`sort-primary-btn btn-min-width`}
              type="button"
              style={{ margin: "0 10px" }}
              onClick={(e) => submitForm(e)}
              disabled={isSubmitEnable || disablePermission}
            >
              Save
            </Button>
          </div>
          {disablePermission && (
            <Row className="permission-denied-page m-0">
              <Card className="no-permission-card">
                You don't have permission to perform this action. Please contact
                the Admin.
              </Card>
            </Row>
          )}
        </Form>
      ) : (
        <Row className="permission-denied-page m-0">
          <Card className="no-permission-card">
            You don't have permission to access this content. Please contact the
            Admin.
          </Card>
        </Row>
      )}
    </React.Fragment>
  );
};

Guide.propTypes = {
  getGlobalSettings: PropTypes.func,
  globalParameters: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getGlobalSettings: state.Settings.getGlobalSettings,
    globalParameters: state.Settings.globalParm,
    error: state.Settings.settingsError,
  };
};

export default connect(mapStateToProps, {
  getGlobalSettings,
})(Guide);
