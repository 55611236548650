import React, { useEffect, useState } from "react";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { postAuditOpportunity } from "../../../../store/Admin/Audit/actions";
import Loader from "../../../../components/common/Loader";
import Pagination from "../../../../components/common/pagination";
import HideTooltips from "../../../../components/common/hideToolTip";
import CustomReactTable from "../../../../components/common/reactTable";

const BulkUpdate = (props) => {
  const {
    id,
    costPerKiloWattHour,
    costPerKiloWattHourGas,
    costPerCubicMeter,
    currentPage,
    setCurrentPage,
    currentList,
    setCurrentList,
  } = props;

  const viewAllAuditOpportunities = (param) => {
    props.postAuditOpportunity(param);
  };

  const paginate = (pageNum) => {
    let searchParams = {
      pageNumber: parseInt(pageNum),
      pageSize: 10,
      id: id,
      costPerKiloWattHour: costPerKiloWattHour,
      costPerKiloWattHourGas: costPerKiloWattHourGas,
      costPerCubicMeter: costPerCubicMeter,
    };
    viewAllAuditOpportunities(searchParams);
  };

  const nextPage = () => {
    let searchParams = {
      pageNumber: parseInt(currentPage + 1),
      pageSize: 10,
      id: id,
      costPerKiloWattHour: costPerKiloWattHour,
      costPerKiloWattHourGas: costPerKiloWattHourGas,
      costPerCubicMeter: costPerCubicMeter,
    };
    viewAllAuditOpportunities(searchParams);
  };

  const prevPage = () => {
    let searchParams = {
      pageNumber: parseInt(currentPage - 1),
      pageSize: 10,
      id: id,
      costPerKiloWattHour: costPerKiloWattHour,
      costPerKiloWattHourGas: costPerKiloWattHourGas,
      costPerCubicMeter: costPerCubicMeter,
    };
    viewAllAuditOpportunities(searchParams);
  };

  const lastPage = () => {
    let searchParams = {
      pageNumber:
        props.opportunityAudit && props.opportunityAudit.data.data.totalPages,
      pageSize: 10,
      id: id,
      costPerKiloWattHour: costPerKiloWattHour,
      costPerKiloWattHourGas: costPerKiloWattHourGas,
      costPerCubicMeter: costPerCubicMeter,
    };
    viewAllAuditOpportunities(searchParams);
  };

  const pageOptions = {
    itemsPerPage: 10,
    totalPages:
      props.opportunityAudit && props.opportunityAudit.data.data.totalPages,
    paginate: paginate,
    nextPage: nextPage,
    prevPage: prevPage,
    currentPage:
      props.opportunityAudit && props.opportunityAudit.data.data.currentPage,
    lastPage,
    hasPrevious:
      props.opportunityAudit && props.opportunityAudit.data.data.hasPrevious,
    hasNext: props.opportunityAudit && props.opportunityAudit.data.data.hasNext,
    totalCount:
      props.opportunityAudit && props.opportunityAudit.data.data.totalCount,
  };

  const nameFormatter = (cell, row) => {
    if (row.name.length > 10) {
      return (
        <div style={{ whiteSpace: "normal" }}>
          {row.name.substring(0, 10)}
          <HideTooltips text={row.name} />
        </div>
      );
    } else {
      return cell;
    }
  };

  let nf = new Intl.NumberFormat("en-US");

  const investmentFormatter = (row) => {
    return <span>{nf.format(row.investment)}</span>;
  };
  const currentSavingsPAFormatter = (row) => {
    return <span>{nf.format(row.currentSavingsPA)}</span>;
  };
  const newSavingsPAFormatter = (row) => {
    return <span>{nf.format(row.newSavingsPA)}</span>;
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "Opportunity",
      cell: (info) => nameFormatter(info.renderValue(), info.row.original),
    },
    {
      header: "Category",
      accessorKey: "productMainCategory",
    },
    {
      header: "Investment",
      accessorKey: "investment",
      cell: (info) => investmentFormatter(info.row.original),
    },
    {
      header: "Current Savings per Annum",
      accessorKey: "currentSavingsPA",
      cell: (info) => currentSavingsPAFormatter(info.renderValue()),
    },
    {
      header: "New Savings per Annum",
      accessorKey: "newSavingsPA",
      cell: (info) => newSavingsPAFormatter(info.renderValue()),
    },
  ];

  const table = useReactTable({
    data: currentList,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <React.Fragment>
      {" "}
      <div className="">
        {!props.loading && currentList ? (
          <CustomReactTable
            table={table}
            tableClass={"table-bulk-update-account"}
          />
        ) : (
          <Loader />
        )}
      </div>
      <Row>
        <Pagination data={pageOptions} />
      </Row>
    </React.Fragment>
  );
};

BulkUpdate.propTypes = {
  loading: PropTypes.any,
  postAuditOpportunity: PropTypes.func,
  isCreateAuditOpportunitySuccess: PropTypes.any,
  createAuditOppError: PropTypes.any,
  isFailedCreateAuditOpportunity: PropTypes.any,
  opportunityAudit: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    loading: state.Audit.loading,
    isCreateAuditOpportunitySuccess:
      state.Audit.isCreateAuditOpportunitySuccess,
    isFailedCreateAuditOpportunity: state.Audit.isFailedCreateAuditOpportunity,
    createAuditOppError: state.Audit.createAuditOppError,
    opportunityAudit: state.Audit.opportunityAudit,
  };
};

export default connect(mapStateToProps, {
  postAuditOpportunity,
})(BulkUpdate);
