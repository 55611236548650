export const GET_OPPORTUNITY_SUMMARY = "GET_OPPORTUNITY_SUMMARY"
export const GET_OPPORTUNITY_SUMMARY_SUCCESS = "GET_OPPORTUNITY_SUMMARY_SUCCESS"
export const GET_OPPORTUNITY_SUMMARY_ERROR = "GET_OPPORTUNITY_SUMMARY_ERROR"
export const GET_CLIENT_BENCHMARK = "GET_CLIENT_BENCHMARK"
export const GET_CLIENT_BENCHMARK_SUCCESS = "GET_CLIENT_BENCHMARK_SUCCESS"
export const GET_CLIENT_BENCHMARK_ERROR = "GET_CLIENT_BENCHMARK_ERROR"
export const CREATE_OPPORTUNIY = "CREATE_OPPORTUNIY"
export const CREATE_OPPORTUNIY_SUCCESS = "CREATE_OPPORTUNIY_SUCCESS"
export const CREATE_OPPORTUNIY_ERROR = "CREATE_OPPORTUNIY_ERROR"
export const VIEW_OPPORTUNITY = "VIEW_OPPORTUNITY"
export const VIEW_OPPORTUNITY_SUCCESS = "VIEW_OPPORTUNITY_SUCCESS"
export const VIEW_OPPORTUNITY_ERROR = "VIEW_OPPORTUNITY_ERROR"
export const DELETE_OPPORTUNITY = "DELETE_OPPORTUNITY"
export const DELETE_OPPORTUNITY_SUCCESS = "DELETE_OPPORTUNITY_SUCCESS"
export const DELETE_OPPORTUNITY_ERROR = "DELETE_OPPORTUNITY_ERROR"
export const GET_OPPORTUNITY_DETAILS = "GET_OPPORTUNITY_DETAILS"
export const GET_OPPORTUNITY_DETAILS_SUCCESS = "GET_OPPORTUNITY_DETAILS_SUCCESS"
export const GET_OPPORTUNITY_DETAILS_ERROR = "GET_OPPORTUNITY_DETAILS_ERROR"
export const UPDATE_OPPORTUNIY = "UPDATE_OPPORTUNIY"
export const UPDATE_OPPORTUNIY_SUCCESS = "UPDATE_OPPORTUNIY_SUCCESS"
export const UPDATE_OPPORTUNIY_ERROR = "UPDATE_OPPORTUNIY_ERROR"
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT"
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS"
export const DELETE_ATTACHMENT_ERROR = "DELETE_ATTACHMENT_ERROR"
export const UPLOAD_FILES = "UPLOAD_FILES"
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS"
export const UPLOAD_FILES_ERROR = "UPLOAD_FILES_ERROR"





