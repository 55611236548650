import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { Alert } from "reactstrap";
import InfoTooltip from "../../../components/common/tooltip";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  convertToHTML,
} from "draft-js";
import RichTextEditor from "../../../components/common/richTextEditor";
import { getGlobalSettings } from "../../../store/Settings/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getLoggedUser, getDecodeAuthDetails } from "../../../helpers";

const AboutSMCC = (props) => {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;

  let decoded = getDecodeAuthDetails();
  const permissions = decoded.Permission;

  const [disablePermission, setDisablePermission] = useState(false);

  useEffect(() => {
    if (permissions.includes("PERMISSIONS.GLOBALSETTING.CREATE")) {
      setDisablePermission(false);
    } else {
      setDisablePermission(true);
    }
  }, []);

  const [id, setId] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [energyToCarbon, setEnergyToCarbon] = useState("");
  const [waterToCarbon, setWaterToCarbon] = useState("");
  const [carbonToNoOfHours, setCarbonToNoOfHours] = useState("");
  const [carbonToNoOfNights, setCarbonToNoOfNights] = useState("");
  const [averageTravelling, setAverageTravelling] = useState("");
  const [annualEnergyCost, setAnnualEnergyCost] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [carbonFootPrintTitle, setCarbonFootPrintTitle] = useState("");
  const [carbonFootNote, setCarbonFootNote] = useState("");
  const [forecastCashFlow, setForecastCashFlow] = useState("");
  const [costInaction, setCostInaction] = useState("");

  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [untappedSection, setUntappedSection] = useState("");
  const [untappedSubSection, setUntappedSubSection] = useState("");
  const [potentialIOSubSection, setPotentialIOSubSection] = useState("");
  const [energySubSection, setEnergySubSection] = useState("");
  const [waterSubSection, setWaterSubSection] = useState("");
  const [carbonFootPrint, setCarbonFootPrint] = useState("");
  const [leftTitle, setLeftTitle] = useState("");
  const [leftSubTitle, setLeftSubTitle] = useState("");
  const [rightTitle, setRightTitle] = useState("");
  const [rightSubTitle, setRightSubTitle] = useState("");
  const [forecastTitle, setForecastTitle] = useState("");
  const [costInactionTitle, setCostInactionTitle] = useState("");
  const [capitalPlanning, setCapitalPlanning] = useState("");

  const [textRequired, setTextRequired] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitEnable, setIsSubmitEnable] = useState(false);
  const [error, setError] = useState([]);
  const [settingsSuccess, setSettingsSuccess] = useState(false);
  const [settingsError, setSettingsError] = useState(false);
  const [adjustedInvestmentRate, setAdjustedInvestmentRate] = useState("");

  useEffect(() => {
    if (props.globalParameters === null) {
      props.getGlobalSettings();
    }
  }, []);

  useEffect(() => {
    if (settingsSuccess) {
      props.getGlobalSettings();
    }
  }, [settingsSuccess]);

  useEffect(() => {
    if (props.globalParameters) {
      let settings = props.globalParameters && props.globalParameters.data;
      let clientDefaultSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.defaultClientSettings;
      let universalDashboardSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.universalSettings;
      let globalSettings =
        settings && props.globalParameters.data.globalSetting;
      let reportSettings =
        settings && props.globalParameters.data.reportSetting;

      setId(settings.id);

      if (universalDashboardSettings.UntappedSavingsTitle !== null) {
        setUntappedSection(universalDashboardSettings.UntappedSavingsTitle);
      }

      setUntappedSubSection(
        universalDashboardSettings.potentialCarbonSavingsTitle
      );
      setPotentialIOSubSection(
        universalDashboardSettings.potentialPAndLImprovementOpportunitiesTitle
      );
      setEnergySubSection(
        universalDashboardSettings.potentialEnergySavingsTitle
      );
      setWaterSubSection(universalDashboardSettings.potentialWaterSavingsTitle);

      if (universalDashboardSettings.CarbonFoodPrintTitle !== null) {
        setCarbonFootPrint(universalDashboardSettings.CarbonFoodPrintTitle);
      }

      if (universalDashboardSettings.CarbonFoodPrintLeftBlockTitle !== null) {
        setLeftTitle(universalDashboardSettings.CarbonFoodPrintLeftBlockTitle);
      }

      if (
        universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle !== null
      ) {
        setLeftSubTitle(
          universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle
        );
      }

      if (universalDashboardSettings.CarbonFoodPrintRightBlockTitle !== null) {
        setRightTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockTitle
        );
      }

      if (
        universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle !== null
      ) {
        setRightSubTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle
        );
      }

      if (universalDashboardSettings.ForcastCashFlowTitle !== null) {
        setForecastTitle(universalDashboardSettings.ForcastCashFlowTitle);
      }

      if (universalDashboardSettings.ProjectedCostofInactionTitle !== null) {
        setCostInactionTitle(
          universalDashboardSettings.ProjectedCostofInactionTitle
        );
      }

      if (universalDashboardSettings.CapitalPlanning !== null) {
        setCapitalPlanning(universalDashboardSettings.CapitalPlanning);
      }

      if (clientDefaultSettings.CarbonFoodPrintDescription !== null) {
        setCarbonFootPrintTitle(
          clientDefaultSettings.CarbonFoodPrintDescription
        );
      }

      if (clientDefaultSettings.CarbonFoodPrintRightBlockNote !== null) {
        setCarbonFootNote(clientDefaultSettings.CarbonFoodPrintRightBlockNote);
      }

      if (clientDefaultSettings.ForcastCashFlowDescription !== null) {
        setForecastCashFlow(clientDefaultSettings.ForcastCashFlowDescription);
      }

      if (clientDefaultSettings.ProjectedCostofInactionDescription !== null) {
        setCostInaction(
          clientDefaultSettings.ProjectedCostofInactionDescription
        );
      }

      if (globalSettings.energyToCarbon % 1 === 0) {
        setEnergyToCarbon(globalSettings.energyToCarbon.toFixed(2));
      } else {
        setEnergyToCarbon(globalSettings.energyToCarbon);
      }

      if (globalSettings.waterToCarbon % 1 === 0) {
        setWaterToCarbon(globalSettings.waterToCarbon.toFixed(2));
      } else {
        setWaterToCarbon(globalSettings.waterToCarbon);
      }

      if (globalSettings.carbonToTVHours % 1 === 0) {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours.toFixed(2));
      } else {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours);
      }

      if (globalSettings.carbonToNoOfNightUKHotel % 1 === 0) {
        setCarbonToNoOfNights(
          globalSettings.carbonToNoOfNightUKHotel.toFixed(2)
        );
      } else {
        setCarbonToNoOfNights(globalSettings.carbonToNoOfNightUKHotel);
      }

      if (globalSettings.averageTravelingOnLongHaulFlights % 1 === 0) {
        setAverageTravelling(
          globalSettings.averageTravelingOnLongHaulFlights.toFixed(2)
        );
      } else {
        setAverageTravelling(globalSettings.averageTravelingOnLongHaulFlights);
      }

      if (globalSettings.annualEnergyCostsIncrease % 1 === 0) {
        setAnnualEnergyCost(
          globalSettings.annualEnergyCostsIncrease.toFixed(2)
        );
      } else {
        setAnnualEnergyCost(globalSettings.annualEnergyCostsIncrease);
      }
      if (reportSettings.aboutSMCC !== null) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(reportSettings.aboutSMCC))
          )
        );
      } else {
        setEditorState(EditorState.createEmpty());
      }

      if (reportSettings.videoTitle !== null) {
        setTitle(reportSettings.videoTitle);
      }

      if (reportSettings.videoDescription !== null) {
        setDescription(reportSettings.videoDescription);
      }
      if (globalSettings.financingBrokerageFee !== null) {
        setAdjustedInvestmentRate(globalSettings.financingBrokerageFee);
      }

      if (universalDashboardSettings.welcomeMessage !== null) {
        setWelcomeMsg(universalDashboardSettings.welcomeMessage);
      }
    }
  }, [props.globalParameters]);

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  const formValidation = () => {
    let errors = [];

    var content = editorState.getCurrentContent();
    var isEditorEmpty = !content.hasText();
    var convertVariable = convertToRaw(content);

    if (isEditorEmpty) {
      setTextRequired(true);
      errors.push(1);
    } else {
      var varEntity = convertVariable.entityMap;

      if (Object.keys(varEntity).length === 0) {
        var currentPlainText = content.getPlainText();
        var lengthOfTrimmedContent = currentPlainText.trim().length;
        if (!!lengthOfTrimmedContent) {
          setTextRequired(false);
        } else {
          setTextRequired(true);
          errors.push(1);
        }
      } else {
        setTextRequired(false);
      }
    }

    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();
    let valid = formValidation();
    var convertedData = convertToRaw(editorState.getCurrentContent());

    if (valid.length === 0) {
      setShowAlert(true);
      setIsSubmitEnable(true);

      let form_data = new FormData();
      form_data.append("Id", id);

      form_data.append(
        "DashboardSetting.UniversalSettings.WelcomeMessage",
        welcomeMsg
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.UntappedSavingsTitle",
        untappedSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialCarbonSavingsTitle",
        untappedSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialPAndLImprovementOpportunitiesTitle",
        potentialIOSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialEnergySavingsTitle",
        energySubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialWaterSavingsTitle",
        waterSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintTitle",
        carbonFootPrint
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockTitle",
        leftTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockSubtitle",
        leftSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockTitle",
        rightTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockSubtitle",
        rightSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ForcastCashFlowTitle",
        forecastTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ProjectedCostofInactionTitle",
        costInactionTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CapitalPlanning",
        capitalPlanning
      );

      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintDescription",
        carbonFootPrintTitle
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintRightBlockNote",
        carbonFootNote
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ForcastCashFlowDescription",
        forecastCashFlow
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ProjectedCostofInactionDescription",
        costInaction
      );

      form_data.append(
        "ReportSetting.AboutSMCC",
        JSON.stringify(convertedData)
      );

      form_data.append("GlobalSetting.EnergyToCarbon", energyToCarbon);
      form_data.append("GlobalSetting.WaterToCarbon", waterToCarbon);
      form_data.append("GlobalSetting.CarbonToTVHours", carbonToNoOfHours);
      form_data.append(
        "GlobalSetting.CarbonToNoOfNightUKHotel",
        carbonToNoOfNights
      );
      form_data.append(
        "GlobalSetting.AverageTravelingOnLongHaulFlights",
        averageTravelling
      );
      form_data.append(
        "GlobalSetting.AnnualEnergyCostsIncrease",
        annualEnergyCost
      );
      form_data.append(
        "GlobalSetting.FinancingBrokerageFee",
        adjustedInvestmentRate
      );

      form_data.append("ReportSetting.VideoTitle", title);

      form_data.append("ReportSetting.VideoDescription", description);

      new Promise((resolve, reject) => {
        fetch(API_URL + "/GlobalSetting", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + getLoggedUser().accessToken,
          },
          body: form_data,
        })
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            if (data.isError) {
              setSettingsSuccess(false);
              setSettingsError(true);
              let emptyArray = [];
              for (const [key, value] of Object.entries(
                data.responseException.exceptionMessage.fieldErrors
              )) {
                emptyArray.push(value[0]);
              }
              setError(emptyArray);
              setIsSubmitEnable(false);
              window.setTimeout(() => {
                setSettingsError(false);
                setShowAlert(false);
              }, 5000);
            } else {
              setSettingsSuccess(true);
              setSettingsError(false);
              setIsSubmitEnable(false);
              window.setTimeout(() => {
                setSettingsSuccess(false);
                setShowAlert(false);
              }, 5000);
            }
            resolve();
          })
          .catch((err) => {
            console.log("err", JSON.stringify(err));
          });
      });

      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setShowAlert(false);
        setIsSubmitEnable(false);
      }, 5000);
    }
  };

  return (
    <React.Fragment>
      {settingsError && showAlert && (
        <Row className="px-2">
          {error.map((item) => {
            return (
              <Alert
                key={item}
                color="danger"
                isOpen={settingsError && showAlert}
              >
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                {item}
              </Alert>
            );
          })}
        </Row>
      )}
      {settingsError && showAlert && (
        <Row className="px-2">
          <Alert color="danger" isOpen={settingsError && showAlert}>
            <img src={ErrorValidation} alt="error" width={30} height={30} />
            Changes could not be saved
          </Alert>
        </Row>
      )}
      {settingsSuccess && showAlert && (
        <Row className="px-2">
          <Alert color="success" isOpen={settingsSuccess && showAlert}>
            <img
              src={SuccessfulValidation}
              alt="success"
              width={30}
              height={30}
            />
            Changes saved successfully
          </Alert>
        </Row>
      )}
      {permissions.includes("PERMISSIONS.GLOBALSETTING.VIEW") ? (
        <div style={{ marginTop: 30 }}>
          <Form.Group className="mb-3 row mt-1" controlId="formSettingsFields">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                About SaveMoneyCutCarbon &nbsp;
                <InfoTooltip
                  text={"About SaveMoneyCutCarbon"}
                  id="settingsAccount"
                />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <RichTextEditor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    className={`sort-form-input form-control ${
                      textRequired ? "errorField" : ""
                    }`}
                  />
                </div>
                <span
                  className={`inline-form-error ${
                    textRequired ? "display-error" : ""
                  }`}
                >
                  Please enter content to the About SaveMoneyCutCarbon section.
                </span>
              </div>
            </Col>
          </Form.Group>
          <hr />
          <div className="mt-4 text-center">
            <Button
              className={`sort-primary-btn btn-min-width`}
              type="button"
              style={{ margin: "0 10px" }}
              onClick={(e) => submitForm(e)}
              disabled={isSubmitEnable || disablePermission}
            >
              Save
            </Button>
          </div>
          {disablePermission && (
            <Row className="permission-denied-page m-0">
              <Card className="no-permission-card">
                You don't have permission to perform this action. Please contact
                the Admin.
              </Card>
            </Row>
          )}
        </div>
      ) : (
        <Row className="permission-denied-page m-0">
          <Card className="no-permission-card">
            You don't have permission to access this content. Please contact the
            Admin.
          </Card>
        </Row>
      )}
    </React.Fragment>
  );
};

AboutSMCC.propTypes = {
  getGlobalSettings: PropTypes.func,
  globalParameters: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getGlobalSettings: state.Settings.getGlobalSettings,
    globalParameters: state.Settings.globalParm,
    error: state.Settings.settingsError,
  };
};

export default connect(mapStateToProps, {
  getGlobalSettings,
})(AboutSMCC);
