import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  POST_AUDIT_ACCOUNT_CREATE,
  UPDATE_AUDIT_ACCOUNT,
  GET_AUDIT_ACCOUNT_DETAILS,
  GET_USERS,
  GET_AUDIT_LIST,
  GET_PRODUCT_MAIN_CATEGORIES,
  CREATE_AUDIT_SECTION,
  VIEW_AUDIT_SECTION,
  REMOVE_AUDIT_SECTION,
  REMOVE_AUDIT_FINDINGS,
  UPDATE_AUDIT_SECTION,
  VIEW_AUDIT_FINDING_DATA_SET,
  CREATE_AUDIT_FINDINGS,
  UPDATE_AUDIT_FINDINGS,
  REMOVE_MEDIA_SECTION,
  DOWNLOAD_MEDIA,
  GET_AUDIT_FINDINGS_DETAILS,
  POST_AUDIT_OPPORTUNITY,
} from "./actionTypes";

import {
  postAuditAccountSuccess,
  postAuditAccountError,
  updateAuditAccountSuccess,
  updateAuditAccountError,
  getAuditAccountDetailsSuccess,
  getAuditAccountDetailsError,
  getUsersSuccess,
  getUsersFaild,
  getAuditListSuccess,
  getAuditListFaild,
  getMainProductCategoriesSuccess,
  getMainProductCategoriesFaild,
  createAuditSectionSucess,
  createAuditSectionFaild,
  viewAuditSectionSucess,
  viewAuditSectionFaild,
  removeAuditSectionSucess,
  removeAuditSectionFaild,
  updateAuditSectionSucess,
  updateAuditSectionFaild,
  viewAuditFindingsDataSetSucess,
  viewAuditFindingsDataSetFaild,
  createAuditFindingsSucess,
  updateAuditFindingsSucess,
  updateAuditFindingsFaild,
  removeMediaSectionSucess,
  removeMediaSectionFaild,
  downloadFileSucess,
  downloadFileFaild,
  getAuditFindingsDetailsSucess,
  getAuditFindingsDetailsFaild,
  postAuditOpportunitySuccess,
  postAuditOpportunityError,
  removeAuditFindingsSucess,
  removeAuditFindingsFaild,
} from "./actions";

import {
  postAuditAccount,
  updateAuditAccount,
  getAuditAccountDetails,
  getUserList,
  getAuditList,
  getProductMainCategory,
  createAuidtSection,
  getAuditSections,
  removeAuditSection,
  removeAuditfindings,
  updateAuidtSection,
  getAuditFindings,
  createAuditFindings,
  updateAuditFinding,
  removeMediaSection,
  downloadMediaStream,
  getAuditFindingsDetails,
  postOpportunityAudit,
} from "../../../helpers/api";

function* getStaffUserList({ payload: { name } }) {
  try {
    const response = yield call(getUserList, name);
    if (response.data.statusCode === 200) {
      yield put(getUsersSuccess(response.data.data));
    } else {
      put(getUsersFaild(response.data.responseException.exceptionMessage));
    }
  } catch (error) {
    yield put(getUsersFaild(error));
  }
}

function* postCreateAuditAccount({ payload: { data } }) {
  try {
    const response = yield call(postAuditAccount, data);
    if (
      response.statusCode === 200 &&
      response.message === "Audit created successfully"
    ) {
      yield put(postAuditAccountSuccess(response));
    } else {
      put(postAuditAccountError(response.message));
    }
  } catch (error) {
    yield put(postAuditAccountError(error));
  }
}

function* getAuditListFunc({ payload: { parm } }) {
  try {
    const response = yield call(getAuditList, parm);
    if (
      response.statusCode === 200 &&
      response.message === "POST Request successful."
    ) {
      yield put(getAuditListSuccess(response.data));
    } else {
      yield put(getAuditListFaild(response.message));
    }
  } catch (error) {
    yield put(getAuditListFaild(error));
  }
}

function* updateAuditAccountFun({ payload: { data } }) {
  try {
    const response = yield call(updateAuditAccount, data);
    if (
      response.statusCode === 200 &&
      response.message === "Audit details updated successfully"
    ) {
      yield put(updateAuditAccountSuccess(response.data));
    } else if (response.responseException.exceptionMessage) {
      yield put(
        updateAuditAccountError(response.responseException.exceptionMessage)
      );
    } else {
      yield put(updateAuditAccountError(response.message));
    }
  } catch (error) {
    yield put(updateAuditAccountError(error));
  }
}

function* getAuditAccountDetailsFunc({ payload: { id } }) {
  try {
    const response = yield call(getAuditAccountDetails, id);
    if (
      response.statusCode === 200 &&
      response.message === "GET Request successful."
    ) {
      yield put(getAuditAccountDetailsSuccess(response.data));
    } else {
      yield put(
        getAuditAccountDetailsError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      getAuditAccountDetailsError(error)
    );
  }
}

function* getMainProductCategoriesFun() {
  try {
    const response = yield call(getProductMainCategory);
    if (response.statusCode === 200) {
      yield put(getMainProductCategoriesSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getMainProductCategoriesFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getMainProductCategoriesFaild(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      getMainProductCategoriesFaild(
        error
      )
    );
  }
}

function* createAuditSectionFun({ payload: { data } }) {
  try {
    const response = yield call(createAuidtSection, data);
    if (response.statusCode === 200) {
      yield put(createAuditSectionSucess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        createAuditSectionFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        createAuditSectionFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      createAuditSectionFaild(error)
    );
  }
}

function* viewAuditSectionList({ payload: { parm } }) {
  try {
    const response = yield call(getAuditSections, parm);
    if (
      response.statusCode === 200 &&
      response.message === "POST Request successful."
    ) {
      yield put(viewAuditSectionSucess(response.data));
    } else if (response.statusCode === 401) {
      yield put(
        viewAuditSectionFaild(response.responseException.exceptionMessage)
      );
    } else {
      yield put(viewAuditSectionFaild(response.message));
    }
  } catch (error) {
    yield put(viewAuditSectionFaild(error));
  }
}

function* removeAuditFindingsFunc({ payload: { id } }) {
  try {
    const response = yield call(removeAuditfindings, id);
    if (
      response.statusCode === 200 &&
      response.message === "Audit section details deleted successfully"
    ) {
      yield put(removeAuditFindingsSucess(response.data));
    } else {
      if (response.responseException) {
        yield put(removeAuditFindingsFaild(response.responseException.message));
      } else {
        yield put(removeAuditFindingsFaild(response.message));
      }
    }
  } catch (error) {
    yield put(removeAuditFindingsFaild(error));
  }
}

function* removeAuditSectionFunc({ payload: { id } }) {
  try {
    const response = yield call(removeAuditSection, id);
    if (
      response.statusCode === 200 &&
      response.message === "Audit section details deleted successfully"
    ) {
      yield put(removeAuditSectionSucess(response.data));
    } else {
      if (response.responseException) {
        yield put(removeAuditSectionFaild(response.responseException.message));
      } else {
        yield put(removeAuditSectionFaild(response.message));
      }
    }
  } catch (error) {
    yield put(removeAuditSectionFaild(error));
  }
}

function* updateAuditSectionFun({ payload: { data } }) {
  try {
    const response = yield call(updateAuidtSection, data);
    if (
      response.statusCode === 200 &&
      response.message === "Audit section details updated successfully"
    ) {
      yield put(updateAuditSectionSucess(response.data));
    } else if(response.responseException){
      yield put(updateAuditSectionFaild(response.responseException.exceptionMessage.fieldErrors.Name[0]));
    }else{
      yield put(updateAuditSectionFaild(response.message));
    }
  } catch (error) {
    yield put(
      updateAuditSectionFaild(error)
    );
  }
}

function* viewAuditFindingsList({ payload: { parm } }) {
  try {
    const response = yield call(getAuditFindings, parm);
    if (response.statusCode === 200 && response.message === "POST Request successful.") {
      yield put(viewAuditFindingsDataSetSucess(response.data));
    } else if(response.responseException){
      yield put(
        viewAuditFindingsDataSetFaild(
          response.responseException.exceptionMessage
        )
      );
    }else{
      yield put(
        viewAuditFindingsDataSetFaild(
          response.message
        )
      );
    }
  } catch (error) {
    yield put(
      viewAuditFindingsDataSetFaild(
        error
      )
    );
  }
}

function* createAuditFindingsDataSetFun({ payload: { data } }) {
  try {
    const response = yield call(createAuditFindings, data);
    if (response.statusCode === 200) {
      yield put(createAuditFindingsSucess(response.data));
    } else if (response.statusCode === 400) {
      yield put(createAuditSectionFaild(response.responseException.message));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        createAuditSectionFaild(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      yield put(
        createAuditSectionFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      createAuditSectionFaild(error)
    );
  }
}

function* updateAuditFindingsDataSetFun({ payload: { data } }) {
  try {
    const response = yield call(updateAuditFinding, data);
    if (
      response.statusCode === 200 &&
      response.message === "Audit findings updated successfully"
    ) {
      yield put(updateAuditFindingsSucess(response.data));
    } else {
      yield put(updateAuditFindingsFaild(response.message));
    }
  } catch (error) {
    yield put(
      updateAuditFindingsFaild(error)
    );
  }
}

function* removeMediaSectionFunc({ payload: { id, mediaId } }) {
  try {
    const response = yield call(removeMediaSection, id, mediaId);
    if (
      response.statusCode === 200 &&
      response.message === "Audit findings media deleted successfully"
    ) {
      yield put(removeMediaSectionSucess(response.data));
    } else {
      yield put(
        removeMediaSectionFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      removeMediaSectionFaild(error)
    );
  }
}

function* downloadMediaFiles({ payload: { id, mediaId, type } }) {
  try {
    const response = yield call(downloadMediaStream, id, mediaId, type);
    if (response.statusCode === 200) {
      yield put(downloadFileSucess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        downloadFileFaild(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      yield put(downloadFileFaild(response.responseException.exceptionMessage));
    }
  } catch (error) {
    yield put(downloadFileFaild(error));
  }
}

function* getAuditFindingsDetailsFunc({ payload: { id } }) {
  try {
    const response = yield call(getAuditFindingsDetails, id);
    if (
      response.statusCode === 200 &&
      response.message === "GET Request successful."
    ) {
      yield put(getAuditFindingsDetailsSucess(response.data));
    } else {
      yield put(getAuditFindingsDetailsFaild(response.message));
    }
  } catch (error) {
    yield put(getAuditFindingsDetailsFaild(error));
  }
}

function* postAuditOpportunityAccount({ payload: { data } }) {
  try {
    const response = yield call(postOpportunityAudit, data);
    if (response.statusCode === 200) {
      yield put(postAuditOpportunitySuccess(response));
    } else {
      put(postAuditOpportunityError(response.message));
    }
  } catch (error) {
    yield put(postAuditOpportunityError(error));
  }
}

export function* watchAudit() {
  yield takeEvery(GET_USERS, getStaffUserList);
  yield takeEvery(POST_AUDIT_ACCOUNT_CREATE, postCreateAuditAccount);
  yield takeEvery(GET_AUDIT_LIST, getAuditListFunc);
  yield takeEvery(UPDATE_AUDIT_ACCOUNT, updateAuditAccountFun);
  yield takeEvery(GET_AUDIT_ACCOUNT_DETAILS, getAuditAccountDetailsFunc);
  yield takeEvery(GET_PRODUCT_MAIN_CATEGORIES, getMainProductCategoriesFun);
  yield takeEvery(CREATE_AUDIT_SECTION, createAuditSectionFun);
  yield takeEvery(VIEW_AUDIT_SECTION, viewAuditSectionList);
  yield takeEvery(REMOVE_AUDIT_FINDINGS, removeAuditFindingsFunc);
  yield takeEvery(REMOVE_AUDIT_SECTION, removeAuditSectionFunc);
  yield takeEvery(UPDATE_AUDIT_SECTION, updateAuditSectionFun);
  yield takeEvery(VIEW_AUDIT_FINDING_DATA_SET, viewAuditFindingsList);
  yield takeEvery(CREATE_AUDIT_FINDINGS, createAuditFindingsDataSetFun);
  yield takeEvery(UPDATE_AUDIT_FINDINGS, updateAuditFindingsDataSetFun);
  yield takeEvery(REMOVE_MEDIA_SECTION, removeMediaSectionFunc);
  yield takeEvery(DOWNLOAD_MEDIA, downloadMediaFiles);
  yield takeEvery(GET_AUDIT_FINDINGS_DETAILS, getAuditFindingsDetailsFunc);
  yield takeEvery(POST_AUDIT_OPPORTUNITY, postAuditOpportunityAccount);
}

function* AuditSaga() {
  yield all([fork(watchAudit)]);
}

export default AuditSaga;
