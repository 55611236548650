import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Form, Card } from "react-bootstrap";
import BulkUpdate from "./bulkUpdate";
import { AlertTriangle } from "react-feather";

const UpdateFileModal = (props) => {
  const {
    updateConfirmation,
    cancelUpdate,
    updateSubmit,
    updatePublishReport,
    setUpdatePublishReport,
    updateDraftReport,
    setUpdateDraftReport,
    updateCarbonConversion,
    setUpdateCarbonConversion,
    dataOpportunity,
    energyToCarbon,
    energyToCarbonOld,
    waterToCarbon,
    waterToCarbonOld,
  } = props;

  const handleCarbonConversion = (e) => {
    setUpdateCarbonConversion(e.target.checked);
    if (e.target.checked) {
      setUpdatePublishReport(false);
      setUpdateDraftReport(false);
    }
  };

  return (
    <React.Fragment>
      <Modal show={updateConfirmation} centered className="confirmation-modal">
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12} className="text-center">
              <Modal.Title>Update Opportunities</Modal.Title>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {parseFloat(energyToCarbon) !== parseFloat(energyToCarbonOld) &&
            parseFloat(waterToCarbon) === parseFloat(waterToCarbonOld) ? (
              <p className="bulk-modal-body">
                There are existing opportunities which refers to 'KwH to Carbon'
                conversion factor
              </p>
            ) : parseFloat(energyToCarbon) === parseFloat(energyToCarbonOld) &&
              parseFloat(waterToCarbon) !== parseFloat(waterToCarbonOld) ? (
              <p className="bulk-modal-body">
                There are existing opportunities which refers to 'M3 to Carbon'
                conversion factor
              </p>
            ) : (
              <p className="bulk-modal-body">
                There are existing opportunities which refers to 'KwH to Carbon'
                & 'M3 to Carbon' conversion factors
              </p>
            )}

            <br />
            <br />
            <b>Please select the update to be performed:</b>
            <br />
            <p className="bulk-modal-body">
              <b>
                Summary of the opportunities which will be impacted by the
                change
              </b>
            </p>
            <br />

            <BulkUpdate
              updatePublishReport={updatePublishReport}
              setUpdatePublishReport={setUpdatePublishReport}
              updateDraftReport={updateDraftReport}
              setUpdateDraftReport={setUpdateDraftReport}
              setUpdateCarbonConversion={setUpdateCarbonConversion}
              dataOpportunity={dataOpportunity}
            />

            <br />
            <Row>
              <Col lg={3}></Col>
              <Col lg={7}>
                <Form.Check
                  type="checkbox"
                  label="Update Carbon Conversion Factor only"
                  checked={updateCarbonConversion}
                  onChange={handleCarbonConversion}
                />
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Row>
          <Row>
            <Card className="warning-message-cancel">
              <span>
                <AlertTriangle size={25} />
                &nbsp; Please note that cancelling will also cancel the update
                to the global parameters
              </span>
            </Card>
          </Row>
          <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                className="sort-primary-btn btn-min-width"
                style={{ margin: "0 10px" }}
                type={"button"}
                onClick={updateSubmit}
                disabled={
                  !updatePublishReport &&
                  !updateDraftReport &&
                  !updateCarbonConversion
                }
              >
                Update
              </Button>
              <Button
                className="sort-primary-outline-btn btn-min-width"
                onClick={cancelUpdate}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateFileModal;
