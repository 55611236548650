import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Container, Alert, Input } from "reactstrap";
import { Form, Col, Button, Row } from "react-bootstrap";
import SearchIcon from "../../../assets/images/icons/SearchIcon.svg";
import Edit from "../../../assets/images/icons/Edit.svg";
// import BootstrapTable from "react-bootstrap-table-next";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import Energy_1 from "../../../assets/images/icons/iconogrphy/Energy_1.svg";
import Lighting_1 from "../../../assets/images/icons/iconogrphy/Lighting_1.svg";
import Water_1 from "../../../assets/images/icons/iconogrphy/Water_1.svg";
import Gas_1 from "../../../assets/images/icons/iconogrphy/Water_1.svg";
import Other_1 from "../../../assets/images/icons/iconogrphy/Water_1.svg";
import Loader from "../../../components/common/Loader";
import { default as ReactSelect } from "react-select";
import { getMainProductCategories } from "../../../store/Admin/Audit/actions";
import { getProjectList } from "../../../store/Project/Analyst/actions";
import InlineErroMsg from "../../../components/common/inlineErrorMsg";
import Pagination from "../../../components/common/pagination";
import { getDecodeAuthDetails } from "../../../helpers";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import CustomReactTable from "../../../components/common/reactTable";

const ViewProjects = (props) => {
  let navigate = useNavigate();
  const { id } = useParams();
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [productCategories, setProductCategories] = useState([]);
  const [showCategoryPermissionMsg, setShowCategoryPermissionMsg] =
    useState(false);

  const [values, setValues] = useState({
    searchBy: "",
  });

  const [selectedStatus, setSelectedStatus] = useState();
  const [categorySelected, setCategorySelected] = useState(null);
  const [projectSearchRequired, setProjectSearchRequired] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  const [currentList, setCurrentList] = useState([]);
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    props.getMainProductCategories();
  }, []);

  let decodedUserData = getDecodeAuthDetails();
  const permissions = decodedUserData.Permission;

  useEffect(() => {
    if (props.productMainCategories && props.productMainCategories.data) {
      let categories =
        props.productMainCategories.data.length > 0 &&
        props.productMainCategories.data.map((item) => {
          const container = {};
          container.value = item.id;
          container.label = item.productMainCategoryName;
          return container;
        });

      setProductCategories(categories);
    }
  }, [props.productMainCategories]);

  const viewAll = (param) => {
    setValues({
      searchBy: "",
    });
    setSelectedStatus("");
    setCategorySelected(null);
    props.getProjectList(param);
    setProjectSearchRequired(false);
  };

  const viewAllProjects = (param) => {
    props.getProjectList(param);
  };

  useEffect(() => {
    let param = {
      pageNumber: 1,
      pageSize: 10,
      clientBusinessAccountId: id
    };
    viewAllProjects(param);
  }, []);

  const handleStatusFilter = (e) => {
    setSelectedStatus(e.target.value);
    setShowMessage(true);
    let categoryList;
    if (categorySelected) {
      categoryList = categorySelected.map((i) => i.value);
    }
    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let parms = {
      status: parseInt(e.target.value),
      productMainCategoryId: categoryList,
      clientBusinessAccountId: id,
      searchBy: searchProjectClient,
    };
    viewAllProjects(parms);
    window.setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const handleSelect = (selected) => {
    setCategorySelected(selected);
    setShowMessage(false);
    setIsSearched(true);
    setProjectSearchRequired(false);

    let categoryList;
    if (selected) {
      categoryList = selected.map((i) => i.value);
    }
    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let parms = {
      productMainCategoryId: categoryList,
      searchBy: searchProjectClient,
      clientBusinessAccountId: id,
      status: selectStatus,
    };
    viewAllProjects(parms);
  };

  useEffect(() => {
    if (props.projectList && props.projectList.data) {
      if (props.projectList.data.data.length === 0 && isSearched) {
        setShowMessage(true);
      }
      setCurrentPage(props.projectList.data.currentPage);
      setCurrentList(props.projectList.data.data);
      setData(props.projectList.data.data);
    } else {
      setCurrentList([]);
      setData([]);
    }
  }, [props.projectList, indexOfFirstPost, indexOfLastPost, currentPage]);

  useEffect(() => {
    setCurrentList([]);
    setData([]);
    setShowMessage(true);
    window.setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [props.isFailedSearchProjects]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length >= 1) {
      setProjectSearchRequired(false);
    } else {
      let param = {
        pageNumber: 1,
        pageSize: 10,
        clientBusinessAccountId: id
      };
      viewAll(param);
    }
  };

  const submitSearch = () => {
    setShowMessage(false);
    setIsSearched(true);
    setProjectSearchRequired(false);
    if (values.searchBy) {
      let categoryList;
      if (categorySelected) {
        categoryList = categorySelected.map((i) => i.value);
      }
      let selectStatus;
      if (selectedStatus) {
        selectStatus = parseInt(selectedStatus);
      }
      let parms = {
        status: selectStatus,
        searchBy: values.searchBy,
        clientBusinessAccountId: id,
        productMainCategoryId: categoryList,
      };
      viewAllProjects(parms);
    } else {
      setProjectSearchRequired(true);
    }

    window.setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const paginate = (pageNum) => {
    let categoryList;
    if (categorySelected) {
      categoryList = categorySelected.map((i) => i.value);
    }
    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }

    let searchParams = {
      pageNumber: parseInt(pageNum),
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllProjects(searchParams);
    var element = document.getElementById("paginate-view-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const nextPage = () => {
    let categoryList;
    if (categorySelected) {
      categoryList = categorySelected.map((i) => i.value);
    }
    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let searchParams = {
      pageNumber: parseInt(currentPage + 1),
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllProjects(searchParams);
    var element = document.getElementById("paginate-view-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const prevPage = () => {
    let categoryList;
    if (categorySelected) {
      categoryList = categorySelected.map((i) => i.value);
    }
    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let searchParams = {
      pageNumber: parseInt(currentPage - 1),
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllProjects(searchParams);
    var element = document.getElementById("paginate-view-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const lastPage = () => {
    let categoryList;
    if (categorySelected) {
      categoryList = categorySelected.map((i) => i.value);
    }
    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let searchParams = {
      pageNumber: props.projectList && props.projectList.data.totalPages,
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllProjects(searchParams);
    var element = document.getElementById("paginate-view-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const getRowData = (id) => {
    showEditPage(id);
  };

  const showEditPage = (id) => {
    navigate("/project/" + id);
  };

  const nameClick = (cell, id) => {
    return (
      <span
        onClick={() => {
          getRowData(id);
        }}
      >
        {cell}
      </span>
    );
  };

  const editColumn = (id) => {
    return (
      <img
        className="edit-img"
        src={Edit}
        alt=""
        width={40}
        height={40}
        onClick={() => {
          getRowData(id);
        }}
      />
    );
  };

  const statusColumnFormatter = (status) => {
    if (status === 0) {
      return <p style={{ margin: 0, color: "#f03738" }}>Inactive</p>;
    } else if (status === 1) {
      return <p style={{ margin: 0, color: "#77BF4B" }}>Active</p>;
    } else if (status === 2) {
      return <p style={{ margin: 0, color: "orange" }}>Requested</p>;
    } else {
      return <p style={{ margin: 0 }}>Completed</p>;
    }
  };

  const productFormatter = (category) => {
    if (category === "Lighting") {
      return <img src={Lighting_1} alt={""} width={20} height={20} />;
    } else if (category === "Electricity") {
      return <img src={Energy_1} alt={""} width={20} height={20} />;
    } else if (category === "Water") {
      return <img src={Water_1} alt={""} width={20} height={20} />;
    } else if (category === "Gas") {
      return <img src={Gas_1} alt={""} width={20} height={20} />;
    } else {
      return <img src={Other_1} alt={""} width={20} height={20} />;
    }
  };
  const columns = [
    {
      header: "Project Name",
      accessorKey: "name",
      sortingFn: "alphanumeric",
      cell: (info) => nameClick(info.renderValue(), info.row.original.id),
    },
    {
      header: "Client",
      accessorKey: "clientBusinessAccountName",
      sortingFn: "alphanumeric",
    },
    {
      header: "Site",
      accessorKey: "site",
      sortingFn: "alphanumeric",
    },
    {
      header: "Category",
      accessorKey: "category",
      cell: (info) => productFormatter(info.row.original.productMainCategory),
      enableSorting: false,
    },
    {
      header: "Status",
      accessorKey: "status",
      sortingFn: "alphanumeric",
      cell: (info) => statusColumnFormatter(info.row.original.status),
    },
    {
      header: "",
      accessorKey: "edit",
      cell: (info) => editColumn(info.row.original.id),
      enableSorting: false,
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const pageOptions = {
    itemsPerPage: props.projectList && props.projectList.data.pageSize,
    totalPages: props.projectList && props.projectList.data.totalPages,
    paginate: paginate,
    nextPage: nextPage,
    prevPage: prevPage,
    currentPage: props.projectList && props.projectList.data.currentPage,
    lastPage,
    hasPrevious: props.projectList && props.projectList.data.hasPrevious,
    hasNext: props.projectList && props.projectList.data.hasNext,
    totalCount: props.projectList && props.projectList.data.totalCount,
  };

  const handleMouseOver = () => {
    setShowCategoryPermissionMsg(true);
  };

  const handleMouseLeave = () => {
    setShowCategoryPermissionMsg(false);
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitSearch();
    }
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      padding: "2px",
      fontSize: "15px",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#daebcc",
        borderRadius: "20px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#77bf4b",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#77bf4b",
      ":hover": {
        backgroundColor: "#daebcc",
        color: "#77bf4b",
        borderRadius: "20px",
      },
    }),
  };

  return (
    <React.Fragment>
      <div id="paginate-view-projects"></div>
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Projects"}</title>
        </Helmet>
        <Container fluid>
          {showMessage && props.isFailedSearchProjects && (
            <Row className="px-2">
              <Alert color="danger">
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                There are no projects matching your search.
              </Alert>
            </Row>
          )}
          <div className="d-flex justify-content-end">
            <Button className="sort-btn-link" onClick={() => viewAll()}>
              View All Projects
            </Button>
          </div>
          <Form>
            <Form.Group className="mb-3 row mt-4" controlId="formCategory">
              <Col sm={3} md={3} lg={3}>
                <Form.Label className="sort-form-lable green-lable">
                  Filter by Status
                </Form.Label>
                <Input
                  type="select"
                  name="status"
                  onChange={(e) => handleStatusFilter(e)}
                  value={selectedStatus}
                >
                  <option value="">Select status</option>
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                  <option value={2}>Requested</option>
                  <option value={3}>Completed</option>
                </Input>
              </Col>
              <Col sm={3} md={3} lg={3}>
                <Form.Label className="sort-form-lable green-lable">
                  Filter by Category
                </Form.Label>
                <div
                  className={`d-inline-block view-filter-section project-view`}
                  data-toggle="popover"
                  data-trigger="focus"
                  data-content="Please selecet account(s)"
                  style={{ width: "100%" }}
                  onMouseOver={handleMouseOver}
                  onMouseLeave={handleMouseLeave}
                >
                  <ReactSelect
                    isMulti
                    options={productCategories}
                    placeholder="Select Category"
                    value={categorySelected}
                    onChange={handleSelect}
                    isDisabled={
                      !permissions.includes(
                        "PERMISSIONS.PRODUCTMAINCATEGORY.VIEW"
                      )
                    }
                    styles={customStyles}
                  />
                </div>
                {showCategoryPermissionMsg &&
                  !permissions.includes(
                    "PERMISSIONS.PRODUCTMAINCATEGORY.VIEW"
                  ) && (
                    <span>
                      You don't have permission to view product categories.
                      Please contact the Admin
                    </span>
                  )}
              </Col>
              <Col sm={4} md={4} lg={4}>
                <Form.Label
                  className="sort-form-lable green-lable"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  Search Project
                </Form.Label>

                <div
                  className="form-group search-content"
                  style={{ position: "relative" }}
                >
                  <input
                    placeholder="Search Project Name/Client"
                    className={`sort-form-input form-control ${
                      projectSearchRequired ? "errorField" : ""
                    }`}
                    name={"searchBy"}
                    type="text"
                    onChange={handleChange}
                    value={values.searchBy}
                    onKeyPress={handleEnterKey}
                  />
                  <span style={{ position: "absolute", right: "0", top: "0" }}>
                    <img
                      src={SearchIcon}
                      alt="success"
                      width={35}
                      height={35}
                      className="email-search-btn"
                      style={{
                        position: "absolute",
                        right: "-5px",
                        top: "5px",
                      }}
                    />
                  </span>
                  {projectSearchRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{
                        position: "absolute",
                        right: "-20px",
                        top: "13px",
                      }}
                    />
                  )}
                  <InlineErroMsg
                    errorType={projectSearchRequired}
                    errorMsg={
                      "Please enter a project or client name to search."
                    }
                  />
                </div>
              </Col>
              <Col sm={2} md={2} lg={2}>
                <Form.Label
                  className="sort-form-lable green-lable"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  Search Project Button
                </Form.Label>
                <Button
                  className="sort-primary-outline-btn btn-min-width"
                  type="button"
                  onClick={() => submitSearch()}
                >
                  Search
                </Button>
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row mt-4">
              <Col sm={12} md={12} lg={12}>
                {data ? (
                  <CustomReactTable
                    table={table}
                    tableClass="table-first-row-link"
                  />
                ) : (
                  <Loader />
                )}
              </Col>
              <br />
              <Pagination data={pageOptions} />
            </Form.Group>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

ViewProjects.propTypes = {
  getMainProductCategories: PropTypes.func,
  productMainCategories: PropTypes.any,
  getProjectList: PropTypes.func,
  projectList: PropTypes.any,
  loading: PropTypes.any,
  isFailedSearchProjects: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getMainProductCategories: state.Audit.getMainProductCategories,
    productMainCategories: state.Audit.productMainCategories,
    getProjectList: state.ProjectAnalyst.getProjectList,
    projectList: state.ProjectAnalyst.projectList,
    loading: state.ProjectAnalyst.loading,
    isFailedSearchProjects: state.ProjectAnalyst.isFailedSearchProjects,
  };
};

export default connect(mapStateToProps, {
  getMainProductCategories,
  getProjectList,
})(ViewProjects);
