import {
  GET_GLOBAL_PARAMS,
  GET_GLOBAL_PARAMS_SUCCESS,
  GET_GLOBAL_PARAMS_ERROR,
  GET_DASHBOARD_SETTINGS,
  GET_DASHBOARD_SETTINGS_SUCCESS,
  GET_DASHBOARD_SETTINGS_ERROR,
  GET_FINANCE_SETTINGS,
  GET_FINANCE_SETTINGS_SUCCESS,
  GET_FINANCE_SETTINGS_ERROR,
  POST_FINANCE_CATEGORY_CREATE,
  POST_FINANCE_CATEGORY_CREATE_SUCCESS,
  POST_FINANCE_CATEGORY_CREATE_ERROR,
  UPDATE_FINANCE_CATEGORY,
  UPDATE_FINANCE_CATEGORY_SUCCESS,
  UPDATE_FINANCE_CATEGORY_ERROR,
  GET_FINANCE_CATEGORY_DETAILS,
  GET_FINANCE_CATEGORY_DETAILS_SUCCESS,
  GET_FINANCE_CATEGORY_DETAILS_ERROR,
  POST_FINANCE_REPAYMENT_CREATE,
  POST_FINANCE_REPAYMENT_CREATE_SUCCESS,
  POST_FINANCE_REPAYMENT_CREATE_ERROR,
  UPDATE_FINANCE_REPAYMENT,
  UPDATE_FINANCE_REPAYMENT_SUCCESS,
  UPDATE_FINANCE_REPAYMENT_ERROR,
  GET_FINANCE_REPAYMENT_DETAILS,
  GET_FINANCE_REPAYMENT_DETAILS_SUCCESS,
  GET_FINANCE_REPAYMENT_DETAILS_ERROR,
  POST_CARBON_ROADMAP_SETTINGS,
  POST_CARBON_ROADMAP_SETTINGS_SUCCESS,
  POST_CARBON_ROADMAP_SETTINGS_ERROR,
  GET_CARBON_ROADMAP_SETTINGS,
  GET_CARBON_ROADMAP_SETTINGS_SUCCESS,
  GET_CARBON_ROADMAP_SETTINGS_ERROR,
  POST_GLOBAL_OPPORTUNITY,
  POST_GLOBAL_OPPORTUNITY_SUCCESS,
  POST_GLOBAL_OPPORTUNITY_ERROR,
  REMOVE_REPAYMENT_SECTION,
  REMOVE_REPAYMENT_SECTION_SUCCESS,
  REMOVE_REPAYMENT_SECTION_ERROR,
  REMOVE_CATEGORY_SECTION,
  REMOVE_CATEGORY_SECTION_SUCCESS,
  REMOVE_CATEGORY_SECTION_ERROR,
} from "./actionTypes";

const initialState = {
  settingsError: null,
  loading: false,
  globalParm: null,
  dashboardError: null,
  dashboardSettings: null,
  financeSettings: null,
  financeSettingsError: null,
  isCreateFinanceCategorySuccess: false,
  isFailedCreateFinanceCategory: false,
  isSuccessUpdateFinanceCategory: false,
  isFailedUpdateFinanceCategory: false,
  financeCategoryDetails: null,
  isCreateFinanceRepaymentSuccess: false,
  isFailedCreateFinanceRepayment: false,
  isSuccessUpdateFinanceRepayment: false,
  isFailedUpdateFinanceRepayment: false,
  financeRepaymentDetails: null,
  error: null,
  errorCarbonSettings: null,
  isCreateCarbonRoadmapSettingsSuccess: false,
  isFailedCarbonRoadmapSettings: false,
  carbonRoadmapSettingsDetails: null,
  isCreateGlobalOpportunitySuccess: false,
  isFailedCreateGlobalOpportunity: false,
  createGlobalOppError: null,
  opportunityGlobal: null,
  isSuccessRepaymentSectionDelete: false,
  isFaildRepaymentSectionDelete: false,
  errorDeleteRepayment: null,
  isSuccessCategorySectionDelete: false,
  isFaildCategorySectionDelete: false,
  errorDeleteCategory: null,
};

const Settings = (state = initialState, action) => {
  switch (action.type) {
    case GET_GLOBAL_PARAMS:
      state = {
        ...state,
        loading: true,
        settingsError: null,
        globalParm: null,
        accountDetails: null,
        isCreateGlobalOpportunitySuccess: false,
        isFailedCreateGlobalOpportunity: false,
      };
      break;
    case GET_GLOBAL_PARAMS_SUCCESS:
      state = {
        ...state,
        loading: true,
        settingsError: null,
        globalParm: action.payload,
        isCreateGlobalOpportunitySuccess: false,
        isFailedCreateGlobalOpportunity: false,
      };
      break;
    case GET_GLOBAL_PARAMS_ERROR:
      state = {
        ...state,
        loading: true,
        settingsError: action.payload,
        globalParm: null,
        isCreateGlobalOpportunitySuccess: false,
        isFailedCreateGlobalOpportunity: false,
      };
      break;
    case GET_DASHBOARD_SETTINGS:
      state = {
        ...state,
        loading: true,
        dashboardError: null,
        dashboardSettings: null,
      };
      break;
    case GET_DASHBOARD_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: true,
        dashboardError: null,
        dashboardSettings: action.payload,
      };
      break;
    case GET_DASHBOARD_SETTINGS_ERROR:
      state = {
        ...state,
        loading: true,
        dashboardError: action.payload,
        dashboardSettings: null,
      };
      break;
    case GET_FINANCE_SETTINGS:
      state = {
        ...state,
        loading: false,
        financeSettings: null,
        financeSettingsError: null,
        isCreateFinanceCategorySuccess: false,
        isFailedCreateFinanceCategory: false,
        isSuccessUpdateFinanceCategory: false,
        isFailedUpdateFinanceCategory: false,
        isCreateFinanceRepaymentSuccess: false,
        isFailedCreateFinanceRepayment: false,
        isSuccessUpdateFinanceRepayment: false,
        isFailedUpdateFinanceRepayment: false,
        isSuccessCategorySectionDelete: false,
        isFaildCategorySectionDelete: false,
        isSuccessRepaymentSectionDelete: false,
        isFaildRepaymentSectionDelete: false,
        accountDetails: null,
      };
      break;
    case GET_FINANCE_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        financeSettings: action.payload,
        financeSettingsError: null,
      };
      break;
    case GET_FINANCE_SETTINGS_ERROR:
      state = {
        ...state,
        loading: false,
        financeSettings: null,
        financeSettingsError: action.payload,
      };
      break;
    case POST_FINANCE_CATEGORY_CREATE:
      state = {
        ...state,
        loading: false,
        isCreateFinanceCategorySuccess: false,
        error: null,
        isFailedCreateFinanceCategory: false,
      };
      break;
    case POST_FINANCE_CATEGORY_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateFinanceCategorySuccess: true,
        error: null,
        isFailedCreateFinanceCategory: false,
      };
      break;
    case POST_FINANCE_CATEGORY_CREATE_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateFinanceCategorySuccess: false,
        error: action.payload,
        isFailedCreateFinanceCategory: true,
      };
      break;
    case UPDATE_FINANCE_CATEGORY:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateFinanceCategory: false,
        error: null,
        isFailedUpdateFinanceCategory: false,
      };
      break;
    case UPDATE_FINANCE_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateFinanceCategory: true,
        error: null,
        isFailedUpdateFinanceCategory: false,
        financeCategoryDetails: action.payload,
      };
      break;
    case UPDATE_FINANCE_CATEGORY_ERROR:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateFinanceCategory: false,
        error: action.payload,
        isFailedUpdateFinanceCategory: true,
      };
      break;
    case GET_FINANCE_CATEGORY_DETAILS:
      state = {
        ...state,
        loading: false,
        financeCategoryDetails: null,
        error: null,
      };
      break;
    case GET_FINANCE_CATEGORY_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        financeCategoryDetails: action.payload,
        error: null,
      };
      break;
    case GET_FINANCE_CATEGORY_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        financeCategoryDetails: null,
        error: action.payload,
      };
      break;
    case POST_FINANCE_REPAYMENT_CREATE:
      state = {
        ...state,
        loading: false,
        isCreateFinanceRepaymentSuccess: false,
        error: null,
        isFailedCreateFinanceRepayment: false,
      };
      break;
    case POST_FINANCE_REPAYMENT_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateFinanceRepaymentSuccess: true,
        error: null,
        isFailedCreateFinanceRepayment: false,
      };
      break;
    case POST_FINANCE_REPAYMENT_CREATE_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateFinanceRepaymentSuccess: false,
        error: action.payload,
        isFailedCreateFinanceRepayment: true,
      };
      break;
    case UPDATE_FINANCE_REPAYMENT:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateFinanceRepayment: false,
        error: null,
        isFailedUpdateFinanceRepayment: false,
      };
      break;
    case UPDATE_FINANCE_REPAYMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateFinanceRepayment: true,
        error: null,
        isFailedUpdateFinanceRepayment: false,
        financeRepaymentDetails: action.payload,
      };
      break;
    case UPDATE_FINANCE_REPAYMENT_ERROR:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateFinanceRepayment: false,
        error: action.payload,
        isFailedUpdateFinanceRepayment: true,
      };
      break;
    case GET_FINANCE_REPAYMENT_DETAILS:
      state = {
        ...state,
        loading: false,
        financeRepaymentDetails: null,
        error: null,
      };
      break;
    case GET_FINANCE_REPAYMENT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        financeRepaymentDetails: action.payload,
        error: null,
      };
      break;
    case GET_FINANCE_REPAYMENT_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        financeRepaymentDetails: null,
        error: action.payload,
      };
      break;
    case POST_CARBON_ROADMAP_SETTINGS:
      state = {
        ...state,
        loading: false,
        isCreateCarbonRoadmapSettingsSuccess: false,
        errorCarbonSettings: null,
        isFailedCarbonRoadmapSettings: false,
      };
      break;
    case POST_CARBON_ROADMAP_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateCarbonRoadmapSettingsSuccess: true,
        errorCarbonSettings: null,
        isFailedCarbonRoadmapSettings: false,
      };
      break;
    case POST_CARBON_ROADMAP_SETTINGS_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateCarbonRoadmapSettingsSuccess: false,
        errorCarbonSettings: action.payload,
        isFailedCarbonRoadmapSettings: true,
      };
      break;
    case GET_CARBON_ROADMAP_SETTINGS:
      state = {
        ...state,
        loading: true,
        errorCarbonSettings: null,
        carbonRoadmapSettingsDetails: null,
        accountDetails: null,
        isCreateCarbonRoadmapSettingsSuccess: false,
        isFailedCarbonRoadmapSettings: false,
      };
      break;
    case GET_CARBON_ROADMAP_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: true,
        errorCarbonSettings: null,
        carbonRoadmapSettingsDetails: action.payload,
      };
      break;
    case GET_CARBON_ROADMAP_SETTINGS_ERROR:
      state = {
        ...state,
        loading: true,
        errorCarbonSettings: action.payload,
        carbonRoadmapSettingsDetails: null,
      };
      break;
    case POST_GLOBAL_OPPORTUNITY:
      state = {
        ...state,
        loading: false,
        isCreateGlobalOpportunitySuccess: false,
        createGlobalOppError: null,
        isFailedCreateGlobalOpportunity: false,
        opportunityGlobal: null,
      };
      break;
    case POST_GLOBAL_OPPORTUNITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateGlobalOpportunitySuccess: true,
        createGlobalOppError: null,
        isFailedCreateGlobalOpportunity: false,
        opportunityGlobal: action.payload,
      };
      break;
    case POST_GLOBAL_OPPORTUNITY_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateGlobalOpportunitySuccess: false,
        createGlobalOppError: action.payload,
        isFailedCreateGlobalOpportunity: true,
        opportunityGlobal: null,
      };
      break;
    case REMOVE_REPAYMENT_SECTION:
      state = {
        ...state,
        loading: true,
        errorDeleteRepayment: null,
        isSuccessRepaymentSectionDelete: false,
        isFaildRepaymentSectionDelete: false,
      };
      break;
    case REMOVE_REPAYMENT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        errorDeleteRepayment: null,
        isSuccessRepaymentSectionDelete: true,
        isFaildRepaymentSectionDelete: false,
      };
      break;
    case REMOVE_REPAYMENT_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
        errorDeleteRepayment: action.payload,
        isSuccessRepaymentSectionDelete: false,
        isFaildRepaymentSectionDelete: true,
      };
      break;
    case REMOVE_CATEGORY_SECTION:
      state = {
        ...state,
        loading: true,
        errorDeleteCategory: null,
        isSuccessCategorySectionDelete: false,
        isFaildCategorySectionDelete: false,
      };
      break;
    case REMOVE_CATEGORY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        errorDeleteCategory: null,
        isSuccessCategorySectionDelete: true,
        isFaildCategorySectionDelete: false,
      };
      break;
    case REMOVE_CATEGORY_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
        errorDeleteCategory: action.payload,
        isSuccessCategorySectionDelete: false,
        isFaildCategorySectionDelete: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Settings;
