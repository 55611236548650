import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_OPPORTUNITY_SUMMARY,
  GET_CLIENT_BENCHMARK,
  CREATE_OPPORTUNIY,
  VIEW_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  GET_OPPORTUNITY_DETAILS,
  UPDATE_OPPORTUNIY,
  DELETE_ATTACHMENT
} from "./actionTypes";

import {
  getOpportunitySummariesSuccess,
  getOpportunitySummariesFaild,
  getBenchmarkScoreSuccess,
  getBenchmarkScoreFaild,
  createOpportunitySuccess,
  createOpportunityFaild,
  viewOpportunitiesSuccess,
  viewOpportunitiesFaild,
  deleteOpportunitySuccess,
  deleteOpportunityFaild,
  getOpportunityDetailsSuccess,
  getOpportunityDetailsFaild,
  updateOpportunitySuccess,
  updateOpportunityFaild,
  deleteAttachmentSuccess,
  deleteAttachmentFaild
} from "./actions";

import {
  getOpportunitySummary,
  getOppBenchmarkScores,
  createOpportunity,
  getOpportunityList,
  deleteOpportunity,
  getOpportunityDeatails,
  updateOpportunity,
  deleteAttachments
} from "../../../../helpers/api";


function* getOpportunitySummaryList() {
  try {
    const response = yield call(getOpportunitySummary)
    if (response.statusCode === 200) {
      yield put(getOpportunitySummariesSuccess(response.data))
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(getOpportunitySummariesFaild(response.responseException.exceptionMessage.fieldErrors.Name))
    } else {
      yield put(getOpportunitySummariesFaild(response.responseException.exceptionMessage))
    }

  } catch (error) {
    yield put(getOpportunitySummariesFaild(error))
  }
}

function* getOpportunityBenchmarkScors({ payload: { id } }) {
  try {
    const response = yield call(getOppBenchmarkScores, id)
    if (response.statusCode === 200) {
      yield put(getBenchmarkScoreSuccess(response.data))
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(getBenchmarkScoreFaild(response.responseException.exceptionMessage.fieldErrors.Name))
    } else {
      yield put(getBenchmarkScoreFaild(response.responseException.exceptionMessage))
    }

  } catch (error) {
    yield put(getBenchmarkScoreFaild(error))
  }
}

function* createOpportunityFunc({ payload: { data } }) {
  try {
    const response = yield call(createOpportunity, data)
    if (response.statusCode === 200) {
      yield put(createOpportunitySuccess(response.data))
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(createOpportunityFaild(response.responseException.exceptionMessage.fieldErrors))
    } else {
      yield put(createOpportunityFaild(response.responseException.exceptionMessage))
    }

  } catch (error) {
    yield put(createOpportunityFaild(error))
  }
}

function* viewOpportunityList({ payload: { parms } }) {
  try {
    const response = yield call(getOpportunityList, parms)
    if (response.statusCode === 200) {
      yield put(viewOpportunitiesSuccess(response.data))
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(viewOpportunitiesFaild(response.responseException.exceptionMessage.fieldErrors))
    } else {
      yield put(viewOpportunitiesFaild(response.responseException.exceptionMessage))
    }

  } catch (error) {
    yield put(viewOpportunitiesFaild(error))
  }
}

function* deleteOpportunityFunc({ payload: { id } }) {
  try {
    const response = yield call(deleteOpportunity, id)
    if (response.statusCode === 200 && response.message === "DELETE Request successful.") {
      yield put(deleteOpportunitySuccess(response.data))
    } else {
      yield put(deleteOpportunityFaild(response.message))
    }

  } catch (error) {
    yield put(deleteOpportunityFaild(error))
  }
}

function* getOpportunityDetailsFunc({ payload: { id } }) {
  try {
    const response = yield call(getOpportunityDeatails, id)
    if (response.statusCode === 200) {
      yield put(getOpportunityDetailsSuccess(response.data))
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(getOpportunityDetailsFaild(response.responseException.exceptionMessage.fieldErrors))
    } else {
      yield put(getOpportunityDetailsFaild(response.responseException.exceptionMessage))
    }

  } catch (error) {
    yield put(getOpportunityDetailsFaild(error))
  }
}

function* updateOpportunityFunc({ payload: { data } }) {
  try {
    const response = yield call(updateOpportunity, data)
    if (response.statusCode === 200) {
      yield put(updateOpportunitySuccess(response.data))
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(updateOpportunityFaild(response.responseException.exceptionMessage.fieldErrors))
    } else {
      yield put(updateOpportunityFaild(response.responseException.exceptionMessage))
    }

  } catch (error) {
    yield put(updateOpportunityFaild(error))
  }
}

function* deleteAttachmentsFunc({ payload: { id } }) {
  try {
    const response = yield call(deleteAttachments, id)
    if (response.statusCode === 200 && response.message === "DELETE Request successful.") {
      yield put(deleteAttachmentSuccess(response.data))
    } else {
      yield put(deleteAttachmentFaild(response.message))
    }

  } catch (error) {
    yield put(deleteAttachmentFaild(error))
  }
}

export function* watchOpportunity() {
  yield takeEvery(GET_OPPORTUNITY_SUMMARY, getOpportunitySummaryList);
  yield takeEvery(GET_CLIENT_BENCHMARK, getOpportunityBenchmarkScors);
  yield takeEvery(CREATE_OPPORTUNIY, createOpportunityFunc);
  yield takeEvery(VIEW_OPPORTUNITY, viewOpportunityList);
  yield takeEvery(DELETE_OPPORTUNITY, deleteOpportunityFunc);
  yield takeEvery(GET_OPPORTUNITY_DETAILS, getOpportunityDetailsFunc);
  yield takeEvery(UPDATE_OPPORTUNIY, updateOpportunityFunc);
  yield takeEvery(DELETE_ATTACHMENT, deleteAttachmentsFunc);

}

function* OpportunitySaga() {
  yield all([fork(watchOpportunity)]);
}

export default OpportunitySaga;