import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_AUDIT_REPORT,
  GET_EXECUTIVE_SUMMARY,
  POST_UNPUBLISH_REPORT,
  POST_PREVIEW_REPORT,
  GET_FINANCING_OPTIONS_PREVIEW,
  GET_REPAYMENT_PREVIEW_DATA
} from "./actionTypes";

import {
  getAuditReportDetailSuccess,
  getAuditReportDetailFaild,
  getExecutiveSummariesSuccess,
  getExecutiveSummariesFaild,
  postReportUnpublishSuccess,
  postReportUnpublishError,
  postPreviewReportSuccess,
  postPreviewReportError,
  getFinancingOptionsPreviewSuccess,
  getFinancingOptionsPreviewFaild,
  getRepaymentPreviewDataSuccess,
  getRepaymentPreviewDataFaild
} from "./actions";

import {
  getAuditReport,
  getExecutiveSummary,
  postUnpublishReport,
  postReportSection,
  getFinancingPreviewData,
  getPreviewRepaymentData
} from "../../../helpers/api";

function* getAuditReportList({ payload: { id } }) {
  try {
    const response = yield call(getAuditReport, id);
    if (response.statusCode === 200 && response.message === "GET Request successful.") {
      yield put(getAuditReportDetailSuccess(response.data));
    } else {
      yield put(
        getAuditReportDetailFaild(response.message)
      );
    }
  } catch (error) {
    yield put(
      getAuditReportDetailFaild(error)
    );
  }
}

function* getExecutiveSummaryList() {
  try {
    const response = yield call(getExecutiveSummary);

    if (response.statusCode === 200 && response.message === "GET Request successful.") {
      yield put(getExecutiveSummariesSuccess(response.data));
    }  else {
      yield put(
        getExecutiveSummariesFaild(response.message)
      );
    }
  } catch (error) {
    yield put(
      getExecutiveSummariesFaild(error)
    );
  }
}

function* postUnpublishFun({ payload: { id } }) {
  try {
    const response = yield call(postUnpublishReport, id);
    if (response.statusCode === 200 && response.message === "POST Request successful.") {
      yield put(postReportUnpublishSuccess(response.data));
    } else {
      yield put(
        postReportUnpublishError(response.message)
      );
    }
  } catch (error) {
    yield put(
      postReportUnpublishError(error)
    );
  }
}

function* postPreviewReportAccount({ payload: { data } }) {
  try {
    const response = yield call(postReportSection, data);
    if (response.statusCode === 200) {
      yield put(postPreviewReportSuccess(response));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        postPreviewReportError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(postPreviewReportError(response.responseException.exceptionMessage));
    }
  } catch (error) {
    yield put(
      postPreviewReportError(error)
    );
  }
}

function* getFinancingPreviewFunc({payload:{parm}}) {
  try {
    const response = yield call(getFinancingPreviewData, parm);
    if (response.statusCode === 200) {
      yield put(getFinancingOptionsPreviewSuccess(response.data));
    } else if (response.statusCode === 204) {
      yield put(getFinancingOptionsPreviewFaild(response.data));
    }
    else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getFinancingOptionsPreviewFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getFinancingOptionsPreviewFaild(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      getFinancingOptionsPreviewFaild(
        error
      )
    );
  }
}

function* getRepaymentPreviewFunc() {
  try {
    const response = yield call(getPreviewRepaymentData);
    if (response.statusCode === 200) {
      yield put(getRepaymentPreviewDataSuccess(response.data));
    } else if (response.statusCode === 204) {
      yield put(getRepaymentPreviewDataFaild(response.data));
    }
     else {
      yield put(
        getRepaymentPreviewDataFaild(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      getRepaymentPreviewDataFaild(
        error
      )
    );
  }
}

export function* watchReport() {
  yield takeEvery(GET_AUDIT_REPORT, getAuditReportList);
  yield takeEvery(GET_EXECUTIVE_SUMMARY, getExecutiveSummaryList);
  yield takeEvery(POST_UNPUBLISH_REPORT, postUnpublishFun);
  yield takeEvery(POST_PREVIEW_REPORT, postPreviewReportAccount);
  yield takeEvery(GET_FINANCING_OPTIONS_PREVIEW, getFinancingPreviewFunc);
  yield takeEvery(GET_REPAYMENT_PREVIEW_DATA, getRepaymentPreviewFunc);
}

function* ReportSaga() {
  yield all([fork(watchReport)]);
}

export default ReportSaga;
