import React, { useEffect, useState } from "react";
import ChevronLeftCircle from "../../../assets/images/icons/ChevronLeftCircle.png";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Alert } from "reactstrap";
import InfoTooltip from "../../../components/common/tooltip";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import RichTextEditor from "../../../components/common/richTextEditor";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  getAuditReportDetail,
  getExecutiveSummaries,
} from "../../../store/Report/Audit/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as helper from "../Report/helpers/helper";
import DiscardChangesPopup from "../../../components/common/discardChangesPopup";

const EditHeaderText = (props) => {
  const {
    viewExecutive,
    executiveId,
    aReportId,
    setAReportId,
    uploadHeaderImage,
    setUploadHeaderImage,
    fieldsDisabled,
    setFieldsDisabled,
    reportSelect,
  } = props;

  const [values, setValues] = useState({
    header: "",
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editableId, setEditableId] = useState(executiveId);
  const [editTest, setEditTest] = useState(EditorState.createEmpty());
  const [initialId, setInitialId] = useState("");
  const [executiveName, setExecutiveName] = useState("");
  const [status, setStatus] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [headerOldValue, setHeaderOldValue] = useState("");
  const [textOldValue, setTextOldValue] = useState(editorState);

  let id = aReportId;

  useEffect(() => {
    if (id !== helper.ZEROID) {
      props.getAuditReportDetail(id);
    }
    props.getExecutiveSummaries();
  }, []);

  useEffect(() => {
    if (props.executiveSummary) {
      let executiveSummary = props.executiveSummary.data?.filter((obj) => {
        return obj.id === executiveId;
      });

      if (executiveSummary && executiveSummary.length > 0) {
        setInitialId(executiveSummary[0].id);
        setExecutiveName(executiveSummary[0].name);
        setStatus(executiveSummary[0].status);
        setEditContent(executiveSummary[0].isEditableContent);
      }
    }
  }, [props.executiveSummary]);

  let beforeSaveDraft = uploadHeaderImage?.filter((obj) => {
    return obj.executiveSummarySectionId === executiveId;
  });

  useEffect(() => {
    if (beforeSaveDraft && beforeSaveDraft.length > 0) {
      setValues({
        header: beforeSaveDraft[0].header,
      });
      setEditorState(beforeSaveDraft[0].editorState);
      setHeaderOldValue(beforeSaveDraft[0].header);
      setTextOldValue(beforeSaveDraft[0].editorState);
      if (id !== helper.ZEROID) {
        if (props.auditReport) {
          let executiveSections =
            props.auditReport.data &&
            props.auditReport.data.executiveSummary.executiveSummarySections?.filter(
              (obj) => {
                return obj.id === executiveId;
              }
            );

          if (executiveSections && executiveSections.length > 0) {
            setEditableId(
              props.auditReport
                ? executiveSections[0].editableContent.id
                : executiveId
            );
          }
        }
        if (
          props.auditReport.data &&
          props.auditReport.data.reportDetail.status === 1
        ) {
          setFieldsDisabled(true);
        } else {
          setFieldsDisabled(false);
        }
      }
    } else {
      if (id !== helper.ZEROID) {
        if (props.auditReport) {
          let executiveSections =
            props.auditReport.data &&
            props.auditReport.data.executiveSummary.executiveSummarySections?.filter(
              (obj) => {
                return obj.id === executiveId;
              }
            );

          if (executiveSections && executiveSections.length > 0) {
            setHeaderOldValue(
              props.auditReport
                ? executiveSections[0].editableContent.header
                : ""
            );
            setValues({
              header: props.auditReport
                ? executiveSections[0].editableContent.header
                : "",
            });
            setTextOldValue(
              props.auditReport
                ? EditorState.createWithContent(
                    convertFromRaw(
                      JSON.parse(executiveSections[0].editableContent.text)
                    )
                  )
                : editorState
            );
            setEditorState(
              props.auditReport
                ? EditorState.createWithContent(
                    convertFromRaw(
                      JSON.parse(executiveSections[0].editableContent.text)
                    )
                  )
                : EditorState.createEmpty()
            );
            setEditableId(
              props.auditReport
                ? executiveSections[0].editableContent.id
                : executiveId
            );
          } else {
            setValues({
              header: "",
            });
            setEditorState(editTest);
            setTextOldValue(editTest);
          }
        }
      }
    }
  }, [props.auditReport]);

  const [headerRequired, setHeaderRequired] = useState(false);
  const [textRequired, setTextRequired] = useState(false);

  const [checkEditor, setCheckEditor] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitEnable, setIsSubmitEnable] = useState(false);

  const [showSaveExecutiveSuccess, setShowSaveExecutiveSuccess] =
    useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCloseConfirmation = () => setShowConfirmation(false);

  const handleConfirmationEdit = () => {
    if (
      headerOldValue === values.header &&
      JSON.stringify(convertToRaw(textOldValue.getCurrentContent())) ===
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    ) {
      setShowConfirmation(false);
      viewExecutive();
    } else {
      setShowConfirmation(true);
    }
  };

  const handleConfirmationYes = () => {
    setValues({
      header: headerOldValue,
    });
    setEditorState(textOldValue);
    viewExecutive();
  };

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length >= 1) {
      if (e.target.name === "header") {
        setHeaderRequired(false);
      }
    } else {
      if (e.target.name === "header") {
        setHeaderRequired(true);
      }
    }
  };

  const formValidation = () => {
    let isvalid = true;
    let errors = [];

    if (values.header === "") {
      setHeaderRequired(true);
      isvalid = false;
      errors.push(1);
    } else {
      setHeaderRequired(false);
      isvalid = true;
    }

    var content = editorState.getCurrentContent();
    var isEditorEmpty = !content.hasText();
    var currentPlainText = content.getPlainText();   
    var lengthOfTrimmedContent = currentPlainText.trim().length;    

    if (!!(!isEditorEmpty && lengthOfTrimmedContent)) {
      setTextRequired(false);
    } else {
      setTextRequired(true);
      errors.push(1);
    }

    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();

    let valid = formValidation();
    var convertedData = convertToRaw(editorState.getCurrentContent());

    if (valid.length === 0) {
      setShowAlert(true);
      setIsSubmitEnable(true);

      let temp = {
        id: initialId,
        name: executiveName,
        status: status,
        isEditableContent: editContent,
        editId: editableId,
        editName: executiveName,
        header: values.header,
        editorState: editorState,
        text: JSON.stringify(convertedData),
        executiveSummarySectionId: executiveId,
        auditReportId: aReportId,
        isImageContent: false,
        file: null,
      };

      setUploadHeaderImage(
        uploadHeaderImage.filter((obj) => {
          return obj.executiveSummarySectionId != executiveId;
        })
      );

      setUploadHeaderImage((uploadHeaderImage) => [...uploadHeaderImage, temp]);

      setShowSaveExecutiveSuccess(true);
      window.scrollTo(0, 0);
      setIsSubmitEnable(false);
      window.setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  return (
    <div>
      <div className="audit-page-back">
        <img
          src={ChevronLeftCircle}
          width={40}
          height={40}
          alt="back"
          style={{ marginBottom: "5px", marginLeft: "-13px" }}
          onClick={() => viewExecutive()}
        />
        <span>Section:</span>
        <span className="username">Header & Text</span>
      </div>
      <hr />

      {showSaveExecutiveSuccess && showAlert && (
        <Row className="px-2">
          <Alert color="success" isOpen={showSaveExecutiveSuccess && showAlert}>
            <img
              src={SuccessfulValidation}
              alt="success"
              width={30}
              height={30}
            />
            Content saved successfully
          </Alert>
        </Row>
      )}

      <Form.Group className="mb-3 row mt-1" controlId="formAuditFields">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Header &nbsp;
            <InfoTooltip text={"Header"} id="auditAccount" />
          </Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>Header</Form.Label>
          <div className="sort-form-wrapper">
            <div className="form-group sort-form-group">
              <input
                name="header"
                id="header"
                type="text"
                className={`sort-form-input form-control ${
                  headerRequired ? "errorField" : ""
                } ${fieldsDisabled ? "disable-fields" : ""}`}
                value={values.header}
                onChange={handleChange}
                autoComplete="header"
                disabled={fieldsDisabled && true}
              />
              {headerRequired && (
                <img
                  src={ErrorValidation}
                  alt="error"
                  width={20}
                  height={20}
                  className="errorImg"
                />
              )}
            </div>
            <span
              className={`inline-form-error ${
                headerRequired ? "display-error" : ""
              }`}
            >
              Please enter a heading.
            </span>
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row mt-1" controlId="formAuditFields">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Text &nbsp;
            <InfoTooltip text={"Text"} id="auditAccount" />
          </Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>Text</Form.Label>
          <div className="sort-form-wrapper">
            <div className="form-group sort-form-group">
              <RichTextEditor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                className={`sort-form-input form-control ${
                  textRequired ? "errorField" : ""
                } ${fieldsDisabled ? "disable-fields" : ""}`}
                fieldsDisabled={fieldsDisabled}
              />
              {textRequired && (
                <img
                  src={ErrorValidation}
                  alt="error"
                  width={20}
                  height={20}
                  className="errorImg"
                />
              )}
            </div>
            <span
              className={`inline-form-error ${
                textRequired ? "display-error" : ""
              }`}
            >
              Please enter the body content.
            </span>
          </div>
        </Col>
      </Form.Group>
      <DiscardChangesPopup
        isShowDisacrdConfirmation={showConfirmation}
        handleDiscardConfirmation={handleCloseConfirmation}
        backToViewPage={handleConfirmationYes}
      />
      <hr />
      <div className="mt-4 text-center">
        <Button
          className={`sort-primary-btn btn-min-width ${
            fieldsDisabled ? "disable-fields" : ""
          }`}
          type="button"
          style={{ margin: "0 10px" }}
          onClick={submitForm}
          disabled={isSubmitEnable || fieldsDisabled}
        >
          Save
        </Button>
        <Button
          className={`sort-primary-outline-btn btn-min-width ${
            fieldsDisabled ? "disable-fields" : ""
          }`}
          type="button"
          onClick={handleConfirmationEdit}
          disabled={fieldsDisabled}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

EditHeaderText.propTypes = {
  getAuditReportDetail: PropTypes.func,
  auditReport: PropTypes.any,
  getExecutiveSummaries: PropTypes.func,
  executiveSummary: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getAuditReportDetail: state.Report.getAuditReportDetail,
    auditReport: state.Report.auditReport,
    getExecutiveSummaries: state.Report.getExecutiveSummaries,
    executiveSummary: state.Report.executiveSummary,
    error: state.Report.error,
  };
};

export default connect(mapStateToProps, {
  getAuditReportDetail,
  getExecutiveSummaries,
})(EditHeaderText);
