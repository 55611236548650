import { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Row, Col } from "reactstrap";
import { useState } from "react";
import PropTypes from "prop-types";

import CarbonMentorCall from "./carbonMentor";
import BaselineReports from "./baseLineReports";
import BuildingAudits from "./buildingAudits";
import ProjectFinance from "./projectFinance";
import ProjectDelivery from "./projectDelivery";
import StaffEngagment from "./staffEngagement";
import StepsMenu from "./stepsMenu";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import {
  getGPDMentorDetailCalls,
  getGPDBaseLineReports,
  getGPDBuildingAudits,
  getGPDProjectFinancing,
  getGPDProjectDelivery,
  getGPDStaffEngagement,
  getBusinessAccounts,
} from "../../../store/Dashboard/GP/staff/actions";
import { getGlobalSettings } from "../../../store/Settings/actions";

function GPStaffDashboard(props) {
  let navigate = useNavigate();
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [carbonMentorExpanded, setCarbonMentorExpanded] = useState(false);
  const [reportsExpanded, setReportsExpanded] = useState(false);
  const [onePanelExpanded, setOnePanelExpanded] = useState(false);

  const [carbonMentorCallsTooltip, setCarbonMentorCallsTooltip] = useState("");
  const [baselineReportTooltip, setBaselineReportTooltip] = useState("");
  const [buildingAuditsTooltip, setBuildingAuditsTooltip] = useState("");
  const [projectFinancingTooltip, setProjectFinancingTooltip] = useState("");
  const [projectDeliveryTooltip, setProjectDeliveryTooltip] = useState("");
  const [stakeholderEngageTooltip, setStakeholderEngageTooltip] = useState("");

  useEffect(() => {
    props.getBusinessAccounts();
    props.getGPDMentorDetailCalls();
    props.getGPDBaseLineReports();
    props.getGPDBuildingAudits();
    props.getGPDProjectFinancing();
    props.getGPDProjectDelivery();
    props.getGPDStaffEngagement();
    props.getGlobalSettings();
  }, []);

  useEffect(() => {
    if (carbonMentorExpanded || reportsExpanded) {
      setOnePanelExpanded(true);
    }
    if (
      (carbonMentorExpanded && reportsExpanded) ||
      (!carbonMentorExpanded && !reportsExpanded)
    ) {
      setOnePanelExpanded(false);
    }
  }, [carbonMentorExpanded, reportsExpanded]);

  useEffect(() => {
    if (props.globalParameters) {
      let clientDefaultSettings =
        props.globalParameters.data.dashboardSetting.defaultClientSettings;
        
      setCarbonMentorCallsTooltip(clientDefaultSettings.CarbonMentorCallsTooltip);
      setBaselineReportTooltip(clientDefaultSettings.BaselineReportsTooltip);
      setBuildingAuditsTooltip(clientDefaultSettings.BuildingAuditsTooltip);
      setProjectFinancingTooltip(clientDefaultSettings.ProjectFinancingTooltip);
      setProjectDeliveryTooltip(clientDefaultSettings.ProjectDeliveryTooltip);
      setStakeholderEngageTooltip(clientDefaultSettings.StakeholderEngageTooltip);
    }
  }, [props.globalParameters]);

  const navigateToOpportunityPage = () => {
    navigate("/audit-account/view");
  };

  const navigateToProjectsPage = (id) => {
    navigate(`/project/list/${id}`);
  };

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Dashboard"}</title>
        </Helmet>
        <Row className="dashboard">
          <Col xl={12} className="left-panel ml-0 mt-0">
            <StepsMenu 
              carbonMentorCallsTooltip={carbonMentorCallsTooltip}
              baselineReportsTooltip={baselineReportTooltip}
              buildingAuditsTooltip={buildingAuditsTooltip}
              projectFinancingTooltip={projectFinancingTooltip}
              projectDeliveryTooltip={projectDeliveryTooltip}
              stakeholderEngageTooltip={stakeholderEngageTooltip}
            />
            {props.mentorDetailCalls ||
            props.baselineReports ||
            props.buildingAudits ||
            props.projectFinancing ||
            props.projectDelivery ? (
              <>
                <Row className="m-0">
                  <CarbonMentorCall
                    setCarbonMentorExpanded={setCarbonMentorExpanded}
                    onePanelExpanded={onePanelExpanded}
                    data={
                      props.mentorDetailCalls && props.mentorDetailCalls.data
                    }
                    expandPermission={"True"}
                    carbonMentorExpanded={carbonMentorExpanded}
                    reportsExpanded={reportsExpanded}
                    type={"staff"}
                  />
                  <BaselineReports
                    setReportsExpanded={setReportsExpanded}
                    onePanelExpanded={onePanelExpanded}
                    data={props.baselineReports && props.baselineReports.data}
                    expandPermission={"True"}
                    carbonMentorExpanded={carbonMentorExpanded}
                    reportsExpanded={reportsExpanded}
                    type={"staff"}
                  />
                </Row>
                <BuildingAudits
                  data={props.buildingAudits && props.buildingAudits.data}
                  navigateToOpportunityPage={navigateToOpportunityPage}
                  expandPermission={"True"}
                  type={"staff"}
                  onePanelExpanded={true}
                />
                <ProjectFinance
                  data={props.projectFinancing && props.projectFinancing.data}
                  navigateToOpportunityPage={navigateToOpportunityPage}
                  expandPermission={"True"}
                  type={"staff"}
                  onePanelExpanded={true}
                />
                <ProjectDelivery
                  data={props.projectDelivery && props.projectDelivery.data}
                  navigateToOpportunityPage={navigateToProjectsPage}
                  expandPermission={"True"}
                  type={"staff"}
                  onePanelExpanded={true}
                />
                <StaffEngagment
                  data={props.staffEngagement && props.staffEngagement.data}
                />
              </>
            ) : (
              <h4 className="pt-3 ps-3">Please wait. Dashboard loading..</h4>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

GPStaffDashboard.propTypes = {
  getGPDMentorDetailCalls: PropTypes.func,
  mentorDetailCalls: PropTypes.any,
  isSuccessMentorDetailCalls: PropTypes.any,
  getGPDBaseLineReports: PropTypes.func,
  baselineReports: PropTypes.any,
  getGPDBuildingAudits: PropTypes.func,
  buildingAudits: PropTypes.any,
  getGPDProjectFinancing: PropTypes.func,
  projectFinancing: PropTypes.any,
  getGPDProjectDelivery: PropTypes.func,
  projectDelivery: PropTypes.any,
  getGPDStaffEngagement: PropTypes.func,
  staffEngagement: PropTypes.any,
  getBusinessAccounts: PropTypes.func,
  businessAccounts: PropTypes.any,
  getGlobalSettings: PropTypes.func,
  globalParameters: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getGPDMentorDetailCalls: state.Dashboard.getGPDMentorDetailCalls,
    mentorDetailCalls: state.GPDashboard.mentorDetailCalls,
    isSuccessMentorDetailCalls: state.GPDashboard.isSuccessMentorDetailCalls,
    getGPDBaseLineReports: state.Dashboard.getGPDBaseLineReports,
    baselineReports: state.GPDashboard.baselineReports,
    getGPDBuildingAudits: state.Dashboard.getGPDBuildingAudits,
    buildingAudits: state.GPDashboard.buildingAudits,
    getGPDProjectFinancing: state.Dashboard.getGPDProjectFinancing,
    projectFinancing: state.GPDashboard.projectFinancing,
    getGPDProjectDelivery: state.Dashboard.getGPDProjectDelivery,
    projectDelivery: state.GPDashboard.projectDelivery,
    getGPDStaffEngagement: state.Dashboard.getGPDStaffEngagement,
    staffEngagement: state.GPDashboard.staffEngagement,
    getBusinessAccounts: state.GPDashboard.func,
    businessAccounts: state.GPDashboard.businessAccounts,
    getGlobalSettings: state.Settings.getGlobalSettings,
    globalParameters: state.Settings.globalParm,
  };
};

export default connect(mapStateToProps, {
  getGPDMentorDetailCalls,
  getGPDBaseLineReports,
  getGPDBuildingAudits,
  getGPDProjectFinancing,
  getGPDProjectDelivery,
  getGPDStaffEngagement,
  getBusinessAccounts,
  getGlobalSettings
})(GPStaffDashboard);
