import {
  GET_EPC_SCORE,
  GET_EPC_SCORE_SUCCESS,
  GET_EPC_SCORE_ERROR,
  GET_REPORTS_PER_SITE,
  GET_REPORTS_PER_SITE_SUCCESS,
  GET_REPORTS_PER_SITE_ERROR,
  GET_OPPORTUNITIES_PER_REPORT,
  GET_OPPORTUNITIES_PER_REPORT_SUCCESS,
  GET_OPPORTUNITIES_PER_REPORT_ERROR,
  RESET_EPC,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  epcScore: null,
  reportsPerSite: null,
  opportunitiesPerReport: null,
};

const EPCDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_EPC_SCORE:
      state = {
        ...state,
        loading: true,
        epcScore: null,
        error: null,
      };
      break;
    case GET_EPC_SCORE_SUCCESS:
      state = {
        ...state,
        loading: false,
        epcScore: action.payload,
        error: null,
      };
      break;
    case GET_EPC_SCORE_ERROR:
      state = {
        ...state,
        loading: false,
        epcScore: null,
        error: action.payload,
      };
      break;
    case GET_REPORTS_PER_SITE:
      state = {
        ...state,
        loading: true,
        reportsPerSite: null,
        error: null,
      };
      break;
    case GET_REPORTS_PER_SITE_SUCCESS:
      state = {
        ...state,
        loading: false,
        reportsPerSite: action.payload,
        error: null,
      };
      break;
    case GET_REPORTS_PER_SITE_ERROR:
      state = {
        ...state,
        loading: false,
        reportsPerSite: null,
        error: action.payload,
      };
      break;
    case GET_OPPORTUNITIES_PER_REPORT:
      state = {
        ...state,
        loading: true,
        opportunitiesPerReport: null,
        error: null,
      };
      break;
    case GET_OPPORTUNITIES_PER_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        opportunitiesPerReport: action.payload,
        error: null,
      };
      break;
    case GET_OPPORTUNITIES_PER_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
        opportunitiesPerReport: null,
        error: action.payload,
      };
      break;
    case RESET_EPC:
      state = {
        ...state,
        loading: false,
        opportunitiesPerReport: null,
        reportsPerSite: null,
        epcScore: null,
        error: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EPCDetails;
