import React from "react";
import { Container, Row, Form} from "react-bootstrap";
import ErrorValidation from "../../../../assets/images/icons/ErrorValidation.svg";
import InlineErroMsg from "../../../../components/common/inlineErrorMsg";
import NumberFormat from "react-number-format";

const Category = (props) => {
  const {
    category,
    setCategory,
    min,
    setMin,
    max,
    setMax,
    sizeUplift,
    setSizeUplift,
    categoryRequired,
    setCategoryRequired,
    minRequired,
    setMinRequired,
    maxRequired,
    setMaxRequired,
    sizeRequired,
    setSizeRequired,
    positiveSize,
    setPositiveSize,
    setCreateCancel,
    positiveMin,
    setPositiveMin,
    positiveMax,
    setPositiveMax,
    highMin,
    setHighMin,
    lowMax,
    setLowMax,
    categoryValidation,
    setCategoryValidation,
    maxValidation,
    setMaxValidation,
    minValidation,
    setMinValidation,
    categoryMessage,
    setCategoryMessage,
    maxMessage,
    setMaxMessage,
    minMessage,
    setMinMessage,
  } = props;

  const handleCategory = (e) => {
    setCategory(e.target.value.trimStart());

    setCreateCancel(true);

    setCategoryValidation(false);
    setCategoryMessage("");

    if (e.target.value.length >= 1) {
      if (e.target.name === "category") {
        setCategoryRequired(false);
      }
    } else {
      if (e.target.name === "category") {
        setCategoryRequired(true);
      }
    }
  };

  const handleMin = (e) => {
    setMin(e.target.value);
    setCreateCancel(true);

    setMinValidation(false);
    setMinMessage("");

    if (e.target.value.length >= 1) {
      setMinRequired(false);

      if (parseFloat(e.target.value) >= 0) {
        setPositiveMin(false);
      } else {
        setPositiveMin(true);
      }
    }

    if (parseFloat(max) && parseFloat(max) <= parseFloat(e.target.value)) {
      setHighMin(true);
    } else {
      setHighMin(false);
      setLowMax(false);
    }
  };

  const handleMax = (e) => {
    setMax(e.target.value);

    setCreateCancel(true);

    setMaxValidation(false);
    setMaxMessage("");

    if (e.target.value.length >= 1) {
      setMaxRequired(false);

      if (parseFloat(e.target.value) >= 0) {
        setPositiveMax(false);
      } else {
        setPositiveMax(true);
      }
    }

    if (parseFloat(e.target.value) <= parseFloat(min)) {
      setLowMax(true);
    } else {
      setLowMax(false);
      setHighMin(false);
    }
  };

  const handleSizeUplift = (e) => {
    setSizeUplift(e.target.value);
    setCreateCancel(true);

    setPositiveSize(false);

    if (e.target.value.length >= 1) {
      setSizeRequired(false);

      if (parseFloat(e.target.value) >= 0) {
        setPositiveSize(false);
      } else {
        setPositiveSize(true);
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Form>
          <Row>
            <Form.Group controlId="formBasicCategory">
              <Form.Label className="update-form-label">Category*</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="category"
                    type="text"
                    className={`sort-form-input form-control ${
                      categoryRequired || categoryValidation ? "errorField" : ""
                    }`}
                    value={category}
                    onChange={handleCategory}
                  />
                  {(categoryRequired || categoryValidation) && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={categoryRequired}
                  errorMsg={"Please enter a category name."}
                />

                <InlineErroMsg
                  errorType={categoryValidation}
                  errorMsg={categoryMessage}
                />
              </div>
              <br />
            </Form.Group>
            <Form.Group controlId="formBasicMin">
              <Form.Label className="update-form-label">Min £*</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={min}
                    prefix=""
                    decimalSeparator="."
                    displayType="input"
                    type="text"                    
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true} 
                    name="min"
                    className={`sort-form-input form-control ${
                      minRequired || positiveMin || highMin || minValidation
                        ? "errorField"
                        : ""
                    }`}
                    onChange={handleMin}
                  />
                  {(minRequired || positiveMin || highMin || minValidation) && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={minRequired}
                  errorMsg={
                    "Please enter the minimum transaction value for the category."
                  }
                />
                <InlineErroMsg
                  errorType={positiveMin}
                  errorMsg={
                    "Please enter a value which is 0 or more for the minimum transaction value."
                  }
                />
                <InlineErroMsg
                  errorType={highMin}
                  errorMsg={"Min Should be lesser than Max"}
                />
                <InlineErroMsg
                  errorType={minValidation}
                  errorMsg={minMessage}
                />
              </div>
              <br />
            </Form.Group>

            <Form.Group controlId="formBasicMax">
              <Form.Label className="update-form-label">Max £*</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={max}
                    prefix=""
                    decimalSeparator="."
                    displayType="input"
                    type="text"                   
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}      
                    name="max"
                    className={`sort-form-input form-control ${
                      maxRequired || positiveMax || lowMax || maxValidation
                        ? "errorField"
                        : ""
                    }`}
                    onChange={handleMax}
                  />
                  {(maxRequired || positiveMax || lowMax || maxValidation) && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={maxRequired}
                  errorMsg={
                    "Please enter the maximum transaction value for the category."
                  }
                />
                <InlineErroMsg
                  errorType={positiveMax}
                  errorMsg={
                    "Please enter a value which is 0 or more for the maximum transaction value."
                  }
                />
                <InlineErroMsg
                  errorType={lowMax}
                  errorMsg={"Max Should be greater than Min"}
                />
                <InlineErroMsg
                  errorType={maxValidation}
                  errorMsg={maxMessage}
                />
              </div>
              <br />
            </Form.Group>
            <Form.Group controlId="formBasicSizeUplift">
              <Form.Label className="update-form-label">
                Size Uplift (%)*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    suffix="%"
                    value={sizeUplift}
                    prefix=""
                    decimalSeparator="."
                    displayType="input"
                    type="text"                   
                    allowNegative={false}                    
                    decimalScale={2}
                    fixedDecimalScale={true}  
                    name="sizeUplift"
                    className={`sort-form-input form-control ${
                      sizeRequired || positiveSize ? "errorField" : ""
                    }`}
                    onChange={handleSizeUplift}
                  />
                  {(sizeRequired || positiveSize) && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={sizeRequired}
                  errorMsg={"Please enter the size uplift percentage."}
                />
                <InlineErroMsg
                  errorType={positiveSize}
                  errorMsg={
                    "Please enter a number which is 0 or more for the size uplift percentage."
                  }
                />
              </div>
              <br />
            </Form.Group>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  );
};

export default Category;
