export const generatePassword = (length = 10, specialChar = false) => {
    let password = '';
    let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        `abcdefghijklmnopqrstuvwxyz0123456789${specialChar ? '@#$' : ''}`;


    for (let i = 1; i <= length; i++) {
        let char = Math.floor(Math.random()
            * str.length + 1);

        password += str.charAt(char)
    }
    return password;
}