import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Category from "../category";
import {
  updateFinanceCategory,
  getFinanceCategoryDetails,
} from "../../../../../store/Settings/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DiscardChangesPopup from "../../../../../components/common/discardChangesPopup";
import { Alert } from "reactstrap";
import ErrorValidation from "../../../../../assets/images/icons/ErrorValidation.svg";
import { getDecodeAuthDetails } from "../../../../../helpers";

const UpdateTransaction = (props) => {
  const {
    showEdit,
    setShowEdit,
    selectedRowToUpdate,
    setUpdateCategorySuccess,
    setShowAlert,
    setUpdateCategoryError,
    updateCategoryError,
    showAlert,
    errorCategory,
  } = props;
  let decoded = getDecodeAuthDetails();

  const permissions = decoded.Permission;

  const [categoryRequired, setCategoryRequired] = useState(false);
  const [minRequired, setMinRequired] = useState(false);
  const [positiveMin, setPositiveMin] = useState(false);
  const [maxRequired, setMaxRequired] = useState(false);
  const [positiveMax, setPositiveMax] = useState(false);
  const [sizeRequired, setSizeRequired] = useState(false);
  const [positiveSize, setPositiveSize] = useState(false);
  const [highMin, setHighMin] = useState(false);
  const [lowMax, setLowMax] = useState(false);

  const [categoryValidation, setCategoryValidation] = useState(false);
  const [maxValidation, setMaxValidation] = useState(false);
  const [minValidation, setMinValidation] = useState(false);
  const [categoryMessage, setCategoryMessage] = useState("");
  const [maxMessage, setMaxMessage] = useState("");
  const [minMessage, setMinMessage] = useState("");

  const [category, setCategory] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [sizeUplift, setSizeUplift] = useState("");

  useEffect(() => {
    if (showEdit) {
      props.getFinanceCategoryDetails(selectedRowToUpdate);
    }
  }, [showEdit]);

  useEffect(() => {
    if (props.financeCategoryDetails) {
      let financeCategoryData = props.financeCategoryDetails.data;
      setCategory(financeCategoryData.category);
      setMin(financeCategoryData.min.toString());
      setMax(financeCategoryData.max.toString());
      setSizeUplift(financeCategoryData.sizeUplift.toString());
    }
  }, [props.financeCategoryDetails]);

  const [isShowDisacrdConfirmation, setIsShowDisacrdConfirmation] =
    useState(false);
  const [createCancel, setCreateCancel] = useState(false);

  const [isSubmitEnable, setIsSubmitEnable] = useState(false);

  const handleDiscardConfirmation = () => {
    setIsShowDisacrdConfirmation(false);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    resetForm();
    setShowAlert(false);
    setUpdateCategorySuccess(false);
    setUpdateCategoryError(false);
  };

  const backToViewPage = () => {
    setIsShowDisacrdConfirmation(false);
    setShowEdit(false);
    resetForm();
    setShowAlert(false);
    setUpdateCategorySuccess(false);
    setUpdateCategoryError(false);
  };

  const cancelForm = () => {
    if (createCancel) {
      setIsShowDisacrdConfirmation(true);
    } else {
      backToViewPage();
    }
  };

  const formValidation = () => {
    let errors = [];

    if (category === "") {
      setCategoryRequired(true);
      errors.push(1);
    } else {
      setCategoryRequired(false);
    }

    if (min === "") {
      setMinRequired(true);

      errors.push(1);
    } else if (parseFloat(min) < 0) {
      setPositiveMin(true);
      setMinRequired(false);

      errors.push(1);
    } else {
      setMinRequired(false);

      setPositiveMin(false);
    }

    if (highMin) {
      errors.push(1);
    } else {
      setHighMin(false);
    }

    if (max === "") {
      setMaxRequired(true);

      errors.push(1);
    } else if (parseFloat(max) < 0) {
      setPositiveMax(true);
      setMaxRequired(false);
      errors.push(1);
    } else {
      setMaxRequired(false);
      setPositiveMax(false);
    }

    if (lowMax) {
      errors.push(1);
    } else {
      setLowMax(false);
    }

    if (sizeUplift === "") {
      setSizeRequired(true);
      setPositiveSize(false);
      errors.push(1);
    } else if (parseFloat(sizeUplift) < 0) {
      setPositiveSize(true);
      setSizeRequired(false);
      errors.push(1);
    } else {
      setSizeRequired(false);
      setPositiveSize(false);
    }

    if (categoryValidation) {
      errors.push(1);
    } else {
      setCategoryValidation(false);
    }

    if (minValidation) {
      errors.push(1);
    } else {
      setMinValidation(false);
    }

    if (maxValidation) {
      errors.push(1);
    } else {
      setMaxValidation(false);
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = formValidation();

    if (valid.length === 0) {
      setIsSubmitEnable(true);
      let financeTransactionObj = {
        id: selectedRowToUpdate,
        category: category.trim(),
        min: parseFloat(min),
        max: parseFloat(max),
        sizeUplift: parseFloat(sizeUplift),
      };
      props.updateFinanceCategory(financeTransactionObj);
    }
  };

  useEffect(() => {
    if (props.isSuccessUpdateFinanceCategory) {
      setShowAlert(true);
      setShowEdit(false);
      setUpdateCategorySuccess(true);
      window.scrollTo(0, 0);
      resetForm();

      window.setTimeout(() => {
        setShowAlert(false);
        setUpdateCategorySuccess(false);
      }, 3000);
      setIsSubmitEnable(false);
    }
  }, [props.isSuccessUpdateFinanceCategory]);

  useEffect(() => {
    if (props.isFailedUpdateFinanceCategory) {
      setIsSubmitEnable(false);
      setUpdateCategoryError(true);
      setShowAlert(true);
      window.scroll(0, 0);
      window.setTimeout(() => {
        setShowAlert(false);
        setUpdateCategoryError(false);
      }, 5000);
    }
  }, [props.isFailedUpdateFinanceCategory]);

  useEffect(() => {
    if (props.isFailedUpdateFinanceCategory) {
      if (props.error && props.error.error) {
        if (props.error.error.Category) {
          setCategoryValidation(true);
          setCategoryMessage(props.error.error.Category[0]);
        }
        if (props.error.error.Min) {
          setMinValidation(true);
          setMinMessage(props.error.error.Min[0]);
        }
        if (props.error.error.Max) {
          setMaxValidation(true);
          setMaxMessage(props.error.error.Max[0]);
        }
      }
    } else {
      setCategoryValidation(false);
      setCategoryMessage("");
      setMinValidation(false);
      setMinMessage("");
      setMaxValidation(false);
      setMaxMessage("");
    }
  }, [props.isFailedUpdateFinanceCategory]);

  const resetForm = () => {
    setCategory("");
    setMin("");
    setMax("");
    setSizeUplift("");
    setCategoryRequired(false);
    setMinRequired(false);
    setPositiveMin(false);
    setMaxRequired(false);
    setPositiveMax(false);
    setSizeRequired(false);
    setPositiveSize(false);
    setHighMin(false);
    setLowMax(false);
    setCreateCancel(false);
    setCategoryValidation(false);
    setCategoryMessage("");
    setMinValidation(false);
    setMinMessage("");
    setMaxValidation(false);
    setMaxMessage("");
  };

  return (
    <React.Fragment>
      {" "}
      <Modal show={showEdit}>
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12}>
              <Modal.Title className="text-center">
                Edit Category
              </Modal.Title>
              <span className="close-icon" onClick={handleCloseEdit}>
                x
              </span>
            </Col>
          </Row>
        </Modal.Header>
        {permissions.includes("PERMISSIONS.FINANCINGOPTIONSETTING.CATEGORYEDIT") ? (
          <Modal.Body style={{ padding: 40 }}>
          {updateCategoryError && showAlert && (
            <Row className="px-2">
              {errorCategory.map((item) => {
                return (
                  <Alert
                    key={item}
                    color="danger"
                    isOpen={updateCategoryError && showAlert}
                  >
                    <img src={ErrorValidation} alt="error" width={30} height={30}/>
                    {item}
                  </Alert>
                );
              })}
            </Row>
          )}
          {updateCategoryError && showAlert && (
            <Row className="px-2">
              <Alert color="danger" isOpen={updateCategoryError && showAlert}>
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                Transaction size adjustment category could not be updated
              </Alert>
            </Row>
          )}
          <Category
            category={category}
            setCategory={setCategory}
            min={min}
            setMin={setMin}
            max={max}
            setMax={setMax}
            sizeUplift={sizeUplift}
            setSizeUplift={setSizeUplift}
            categoryRequired={categoryRequired}
            setCategoryRequired={setCategoryRequired}
            minRequired={minRequired}
            setMinRequired={setMinRequired}
            maxRequired={maxRequired}
            setMaxRequired={setMaxRequired}
            sizeRequired={sizeRequired}
            setSizeRequired={setSizeRequired}
            positiveSize={positiveSize}
            setPositiveSize={setPositiveSize}
            setCreateCancel={setCreateCancel}
            positiveMin={positiveMin}
            setPositiveMin={setPositiveMin}
            positiveMax={positiveMax}
            setPositiveMax={setPositiveMax}
            highMin={highMin}
            setHighMin={setHighMin}
            lowMax={lowMax}
            setLowMax={setLowMax}
            categoryValidation={categoryValidation}
            setCategoryValidation={setCategoryValidation}
            maxValidation={maxValidation}
            setMaxValidation={setMaxValidation}
            minValidation={minValidation}
            setMinValidation={setMinValidation}
            categoryMessage={categoryMessage}
            setCategoryMessage={setCategoryMessage}
            maxMessage={maxMessage}
            setMaxMessage={setMaxMessage}
            minMessage={minMessage}
            setMinMessage={setMinMessage}
          />
          <DiscardChangesPopup
            isShowDisacrdConfirmation={isShowDisacrdConfirmation}
            handleDiscardConfirmation={handleDiscardConfirmation}
            backToViewPage={backToViewPage}
          />
          <Row className={"mt-3"}>
            <Col lg={12} className={"text-center"}>
              <Button
                className="sort-primary-btn btn-min-width"
                style={{ margin: "0 10px" }}
                type={"button"}
                onClick={handleSubmit}
                disabled={isSubmitEnable}
              >
                Save
              </Button>
              <Button
                className="sort-primary-outline-btn btn-min-width"
                onClick={() => cancelForm()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        ):(
          <Modal.Body style={{ padding: 40 }}>
            <Row>
              <p className="update-modal-body">
                You don't have permission to perform this action. Please contact
                the Admin.
              </p>
            </Row>
          </Modal.Body>
        )}
        
      </Modal>
    </React.Fragment>
  );
};

UpdateTransaction.propTypes = {
  updateFinanceCategory: PropTypes.func,
  getFinanceCategoryDetails: PropTypes.func,
  financeCategoryDetails: PropTypes.any,
  isSuccessUpdateFinanceCategory: PropTypes.any,
  error: PropTypes.any,
  isFailedUpdateFinanceCategory: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isSuccessUpdateFinanceCategory:
      state.Settings.isSuccessUpdateFinanceCategory,
    isFailedUpdateFinanceCategory: state.Settings.isFailedUpdateFinanceCategory,
    error: state.Settings.error,
    financeCategoryDetails: state.Settings.financeCategoryDetails,
  };
};

export default connect(mapStateToProps, {
  updateFinanceCategory,
  getFinanceCategoryDetails,
})(UpdateTransaction);
