import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_CARBON_ROADMAP } from "./actionTypes";

import {
  getClientCarbonRoadMapSuccess,
  getClientCarbonRoadMapFaild,
} from "./actions";

import { getCarbonRoadMap } from "../../../helpers/api";

function* getCarbonRoadMapList() {
  try {
    const response = yield call(getCarbonRoadMap);
    if (response.statusCode === 200) {
      yield put(getClientCarbonRoadMapSuccess(response.data));
    } else {
      yield put(getClientCarbonRoadMapFaild(response.message));
    }
  } catch (error) {
    yield put(
      getClientCarbonRoadMapFaild(error)
    );
  }
}

export function* watchCarbonRoadMap() {
  yield takeEvery(GET_CARBON_ROADMAP, getCarbonRoadMapList);
}

function* ClientCarbonSaga() {
  yield all([fork(watchCarbonRoadMap)]);
}

export default ClientCarbonSaga;
