import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_ADMIN_ANALYST_DASHBOARD } from "./actionTypes";

import {
  getAdminAnalystDashboardSuccess,
  getAdminAnalystDashboardFaild,
} from "./actions";

import { getDashboard } from "../../../helpers/api";

function* getAdminAnalystDashboardList() {
  try {
    const response = yield call(getDashboard);

    if (response.statusCode === 200) {
      yield put(getAdminAnalystDashboardSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getAdminAnalystDashboardFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getAdminAnalystDashboardFaild(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      getAdminAnalystDashboardFaild(
        error
      )
    );
  }
}

export function* watchDashboard() {
  yield takeEvery(GET_ADMIN_ANALYST_DASHBOARD, getAdminAnalystDashboardList);
}

function* DashboardSaga() {
  yield all([fork(watchDashboard)]);
}

export default DashboardSaga;
