const filterOpportunityIds = (arr, category) => {
  let filteredList = arr.filter(x=>x.category === category)
  return filteredList[0].Opportunities.map(({ id }) => id);
};

const filterOpportunities = (arr, category) => {
  let filteredList = arr.filter(x=>x.category === category)
  return filteredList[0].Opportunities;
};

export {
  filterOpportunityIds,
  filterOpportunities
};