import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_CLIENT_REPORT_DETAILS, GET_CLIENT_REPORT_LIST,GET_FINANCING_OPTIONS,GET_REPAYMENT_DATA } from "./actionTypes";

import {
  getClientReportDetailsSuccess,
  getClientReportDetailsFaild,
  getReportListSuccess,
  getReportListFaild,
  getFinancingOptionsSuccess,
  getFinancingOptionsFaild,
  getRepaymentDataSuccess,
  getRepaymentDataFaild
} from "./actions";

import { getClientReportDetails,getClientReportList,
getFinancingOptionsData, getRepaymentReportData } from "../../../helpers/api";

function* getClientReportDetailsFun({ payload: { parm } }) {
  try {
    const response = yield call(getClientReportDetails, parm);
    if (response.statusCode === 200) {
      yield put(getClientReportDetailsSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getClientReportDetailsFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getClientReportDetailsFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      getClientReportDetailsFaild(error)
    );
  }
}

function* getClientReportListFun({payload:{parm}}) {
  try {
    const response = yield call(getClientReportList, parm);
    if (response.statusCode === 200) {
      yield put(getReportListSuccess(response.data));
    } else {
      yield put(
        getReportListFaild(
          response.message
        )
      );
    }
  } catch (error) {
    yield put(
      getReportListFaild(
        error
      )
    );
  }
}

function* getFinancingOptionsFunc({payload:{parm}}) {
  try {
    const response = yield call(getFinancingOptionsData, parm);
    if (response.statusCode === 200) {
      yield put(getFinancingOptionsSuccess(response.data));
    } else if (response.statusCode === 204) {
      yield put(getFinancingOptionsFaild(response.data));
    }
    else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getFinancingOptionsFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getFinancingOptionsFaild(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      getFinancingOptionsFaild(
        error
      )
    );
  }
}

function* getRepaymentFunc() {
  try {
    const response = yield call(getRepaymentReportData);
    if (response.statusCode === 200) {
      yield put(getRepaymentDataSuccess(response.data));
    } else if (response.statusCode === 204) {
      yield put(getRepaymentDataFaild(response.data));
    }
     else {
      yield put(
        getRepaymentDataFaild(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      getRepaymentDataFaild(
        error
      )
    );
  }
}

export function* watchClientReport() {
  yield takeEvery(GET_CLIENT_REPORT_DETAILS, getClientReportDetailsFun);
  yield takeEvery(GET_CLIENT_REPORT_LIST, getClientReportListFun);
  yield takeEvery(GET_FINANCING_OPTIONS, getFinancingOptionsFunc);
  yield takeEvery(GET_REPAYMENT_DATA, getRepaymentFunc);
}

function* ClientReportSaga() {
  yield all([fork(watchClientReport)]);
}

export default ClientReportSaga;
