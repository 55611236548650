import {
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_ERROR,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_ERROR,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  DELETE_ATTACHMENT_PROJECT,
  DELETE_ATTACHMENT_PROJECT_SUCCESS,
  DELETE_ATTACHMENT_PROJECT_ERROR,
  UPLOAD_FILES_PROJECT,
  UPLOAD_FILES_PROJECT_SUCCESS,
  UPLOAD_FILES_PROJECT_ERROR,
} from "./actionTypes";

export const getProjectList = (parm) => {
  return {
    type: GET_PROJECT_LIST,
    payload: { parm },
  };
};
export const getProjectListSuccess = (data) => {
  return {
    type: GET_PROJECT_LIST_SUCCESS,
    payload: { data },
  };
};
export const getProjectListFaild = (error) => {
  return {
    type: GET_PROJECT_LIST_ERROR,
    payload: { error },
  };
};
export const getProjectDetails = (id) => {
  return {
    type: GET_PROJECT_DETAILS,
    payload: { id },
  };
};
export const getProjectDetailsSuccess = (data) => {
  return {
    type: GET_PROJECT_DETAILS_SUCCESS,
    payload: { data },
  };
};
export const getProjectDetailsFaild = (error) => {
  return {
    type: GET_PROJECT_DETAILS_ERROR,
    payload: { error },
  };
};
export const updateProject = (data) => {
  return {
    type: UPDATE_PROJECT,
    payload: { data },
  };
};
export const updateProjectSuccess = (data) => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload: { data },
  };
};
export const updateProjectFaild = (error) => {
  return {
    type: UPDATE_PROJECT_ERROR,
    payload: { error },
  };
};
export const deleteAttachmentProject = (id, attachmentId) => {
  return {
    type: DELETE_ATTACHMENT_PROJECT,
    payload: { id, attachmentId },
  };
};
export const deleteAttachmentProjectSuccess = (data) => {
  return {
    type: DELETE_ATTACHMENT_PROJECT_SUCCESS,
    payload: { data },
  };
};
export const deleteAttachmentProjectFaild = (error) => {
  return {
    type: DELETE_ATTACHMENT_PROJECT_ERROR,
    payload: { error },
  };
};
export const uploadFilesProject = (data, id) => {
  return {
    type: UPLOAD_FILES_PROJECT,
    payload: { data, id },
  };
};
export const uploadFilesProjectSuccess = (data) => {
  return {
    type: UPLOAD_FILES_PROJECT_SUCCESS,
    payload: { data },
  };
};
export const uploadFilesProjectError = (error) => {
  return {
    type: UPLOAD_FILES_PROJECT_ERROR,
    payload: { error },
  };
};
