import {
  GET_OPPORTUNITY_SUMMARY,
  GET_OPPORTUNITY_SUMMARY_SUCCESS,
  GET_OPPORTUNITY_SUMMARY_ERROR,
  GET_CLIENT_BENCHMARK,
  GET_CLIENT_BENCHMARK_SUCCESS,
  GET_CLIENT_BENCHMARK_ERROR,
  CREATE_OPPORTUNIY,
  CREATE_OPPORTUNIY_SUCCESS,
  CREATE_OPPORTUNIY_ERROR,
  VIEW_OPPORTUNITY,
  VIEW_OPPORTUNITY_SUCCESS,
  VIEW_OPPORTUNITY_ERROR,
  DELETE_OPPORTUNITY,
  DELETE_OPPORTUNITY_SUCCESS,
  DELETE_OPPORTUNITY_ERROR,
  GET_OPPORTUNITY_DETAILS,
  GET_OPPORTUNITY_DETAILS_SUCCESS,
  GET_OPPORTUNITY_DETAILS_ERROR,
  UPDATE_OPPORTUNIY,
  UPDATE_OPPORTUNIY_SUCCESS,
  UPDATE_OPPORTUNIY_ERROR,
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_ERROR,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR
} from "./actionTypes";


export const getOpportunitySummaries = () => {
  return {
    type: GET_OPPORTUNITY_SUMMARY,
    payload: {},
  }
}
export const getOpportunitySummariesSuccess = (data) => {
  return {
    type: GET_OPPORTUNITY_SUMMARY_SUCCESS,
    payload: { data },
  }
}
export const getOpportunitySummariesFaild = (error) => {
  return {
    type: GET_OPPORTUNITY_SUMMARY_ERROR,
    payload: { error },
  }
}
export const getBenchmarkScore = (id) => {
  return {
    type: GET_CLIENT_BENCHMARK,
    payload: { id },
  }
}
export const getBenchmarkScoreSuccess = (data) => {
  return {
    type: GET_CLIENT_BENCHMARK_SUCCESS,
    payload: { data },
  }
}
export const getBenchmarkScoreFaild = (error) => {
  return {
    type: GET_CLIENT_BENCHMARK_ERROR,
    payload: { error },
  }
}
export const createOpportunity = (data) => {
  return {
    type: CREATE_OPPORTUNIY,
    payload: { data },
  }
}
export const createOpportunitySuccess = (data) => {
  return {
    type: CREATE_OPPORTUNIY_SUCCESS,
    payload: { data },
  }
}
export const createOpportunityFaild = (error) => {
  return {
    type: CREATE_OPPORTUNIY_ERROR,
    payload: { error },
  }
}
export const viewOpportunities = (parms) => {
  return {
    type: VIEW_OPPORTUNITY,
    payload: { parms },
  }
}
export const viewOpportunitiesSuccess = (data) => {
  return {
    type: VIEW_OPPORTUNITY_SUCCESS,
    payload: { data },
  }
}
export const viewOpportunitiesFaild = (error) => {
  return {
    type: VIEW_OPPORTUNITY_ERROR,
    payload: { error },
  }
}

export const deleteOpportunity = (id) => {
  return {
    type: DELETE_OPPORTUNITY,
    payload: { id },
  }
}
export const deleteOpportunitySuccess = (data) => {
  return {
    type: DELETE_OPPORTUNITY_SUCCESS,
    payload: { data },
  }
}
export const deleteOpportunityFaild = (error) => {
  return {
    type: DELETE_OPPORTUNITY_ERROR,
    payload: { error },
  }
}
export const getOpportunityDetails = (id) => {
  return {
    type: GET_OPPORTUNITY_DETAILS,
    payload: { id },
  }
}
export const getOpportunityDetailsSuccess = (data) => {
  return {
    type: GET_OPPORTUNITY_DETAILS_SUCCESS,
    payload: { data },
  }
}
export const getOpportunityDetailsFaild = (error) => {
  return {
    type: GET_OPPORTUNITY_DETAILS_ERROR,
    payload: { error },
  }
}
export const updateOpportunity = (data) => {
  return {
    type: UPDATE_OPPORTUNIY,
    payload: { data },
  }
}
export const updateOpportunitySuccess = (data) => {
  return {
    type: UPDATE_OPPORTUNIY_SUCCESS,
    payload: { data },
  }
}
export const updateOpportunityFaild = (error) => {
  return {
    type: UPDATE_OPPORTUNIY_ERROR,
    payload: { error },
  }
}
export const deleteAttachment = (id) => {
  return {
    type: DELETE_ATTACHMENT,
    payload: { id },
  }
}
export const deleteAttachmentSuccess = (data) => {
  return {
    type: DELETE_ATTACHMENT_SUCCESS,
    payload: { data },
  }
}
export const deleteAttachmentFaild = (error) => {
  return {
    type: DELETE_ATTACHMENT_ERROR,
    payload: { error },
  }
}
export const uploadFiles = (data, id) => {
  return {
    type: UPLOAD_FILES,
    payload: { data, id },
  }
}
export const uploadFilesSuccess = (data) => {
  return {
    type: UPLOAD_FILES_SUCCESS,
    payload: { data },
  }
}
export const uploadOppFilesError = (error) => {
  return {
    type: UPLOAD_FILES_ERROR,
    payload: { error },
  }
}
