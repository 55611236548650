import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_GLOBAL_PARAMS,
  GET_DASHBOARD_SETTINGS,
  GET_FINANCE_SETTINGS,
  POST_FINANCE_CATEGORY_CREATE,
  UPDATE_FINANCE_CATEGORY,
  GET_FINANCE_CATEGORY_DETAILS,
  POST_FINANCE_REPAYMENT_CREATE,
  UPDATE_FINANCE_REPAYMENT,
  GET_FINANCE_REPAYMENT_DETAILS,
  POST_CARBON_ROADMAP_SETTINGS,
  GET_CARBON_ROADMAP_SETTINGS,
  POST_GLOBAL_OPPORTUNITY,
  REMOVE_REPAYMENT_SECTION,
  REMOVE_CATEGORY_SECTION,
} from "./actionTypes";
import {
  getGlobalSettingsSuccess,
  getGlobalSettingsError,
  getDashboardSettingsSuccess,
  getDashboardSettingsError,
  getFinanceSettingsSuccess,
  getFinanceSettingsFaild,
  postFinanceCategorySuccess,
  postFinanceCategoryError,
  updateFinanceCategorySuccess,
  updateFinanceCategoryError,
  getFinanceCategoryDetailsSuccess,
  getFinanceCategoryDetailsError,
  postFinanceRepaymentSuccess,
  postFinanceRepaymentError,
  updateFinanceRepaymentSuccess,
  updateFinanceRepaymentError,
  getFinanceRepaymentDetailsSuccess,
  getFinanceRepaymentDetailsError,
  postCarbonRoadmapSettingsSuccess,
  postCarbonRoadmapSettingsError,
  getCarbonRoadmapSettingsSuccess,
  getCarbonRoadmapSettingsError,
  postGlobalOpportunitySuccess,
  postGlobalOpportunityError,
  removeRepaymentSectionSuccess,
  removeRepaymentSectionFailed,
  removeCategorySectionSuccess,
  removeCategorySectionFailed,
} from "./actions";
import {
  getGlobalSettings,
  getDashboardSettings,
  getFinanceSettingsList,
  createFinanceCategory,
  updateFinanceCategory,
  getFinanceCategory,
  createRepayment,
  updateRepayment,
  getRepayment,
  createCarbonRoadmapSetting,
  getCarbonRoadmapSetting,
  postOpportunityGlobal,
  removeRepayment,
  removeCategory,
} from "../../helpers/api";

function* getGlobalSettingsFun() {
  try {
    const response = yield call(getGlobalSettings);
    if (response.statusCode === 200) {
      yield put(getGlobalSettingsSuccess(response.data));
      localStorage.setItem("globalParm", JSON.stringify(response.data));
    } else {
      yield put(
        getGlobalSettingsError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      getGlobalSettingsError(error)
    );
  }
}

function* getDashboardSettingsFun() {
  try {
    const response = yield call(getDashboardSettings);
    if (response.statusCode === 200) {
      yield put(getDashboardSettingsSuccess(response.data));
      localStorage.setItem("globalParm", JSON.stringify(response.data));
    } else {
      yield put(
        getDashboardSettingsError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      getDashboardSettingsError(error)
    );
  }
}

function* getFinanceSettingsFunc() {
  try {
    const response = yield call(getFinanceSettingsList);
    if (
      response.statusCode === 200 &&
      response.message === "GET Request successful."
    ) {
      yield put(getFinanceSettingsSuccess(response.data));
    } else {
      yield put(getFinanceSettingsFaild(response.message));
    }
  } catch (error) {
    yield put(
      getFinanceSettingsFaild(error)
    );
  }
}

function* postCreateFinanceCategory({ payload: { data } }) {
  try {
    const response = yield call(createFinanceCategory, data);
    if (response.statusCode === 200) {
      yield put(postFinanceCategorySuccess(response));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        postFinanceCategoryError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(
        postFinanceCategoryError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      postFinanceCategoryError(error)
    );
  }
}

function* updateFinanceCategoryFun({ payload: { data } }) {
  try {
    const response = yield call(updateFinanceCategory, data);
    if (response.statusCode === 200) {
      yield put(updateFinanceCategorySuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        updateFinanceCategoryError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(
        updateFinanceCategoryError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      updateFinanceCategoryError(error)
    );
  }
}

function* getFinanceCategoryDetailsFunc({ payload: { id } }) {
  try {
    const response = yield call(getFinanceCategory, id);
    if (
      response.statusCode === 200 &&
      response.message === "GET Request successful."
    ) {
      yield put(getFinanceCategoryDetailsSuccess(response.data));
    } else {
      yield put(getFinanceCategoryDetailsError(response.message));
    }
  } catch (error) {
    yield put(
      getFinanceCategoryDetailsError(
        error
      )
    );
  }
}

function* postCreateFinanceRepayment({ payload: { data } }) {
  try {
    const response = yield call(createRepayment, data);
    if (response.statusCode === 200) {
      yield put(postFinanceRepaymentSuccess(response));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        postFinanceRepaymentError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(
        postFinanceRepaymentError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      postFinanceRepaymentError(error)
    );
  }
}

function* updateFinanceRepaymentFun({ payload: { data } }) {
  try {
    const response = yield call(updateRepayment, data);
    if (response.statusCode === 200) {
      yield put(updateFinanceRepaymentSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        updateFinanceRepaymentError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(
        updateFinanceRepaymentError(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      updateFinanceRepaymentError(error)
    );
  }
}

function* getFinanceRepaymentDetailsFunc({ payload: { id } }) {
  try {
    const response = yield call(getRepayment, id);
    if (
      response.statusCode === 200 &&
      response.message === "GET Request successful."
    ) {
      yield put(getFinanceRepaymentDetailsSuccess(response.data));
    } else {
      yield put(getFinanceRepaymentDetailsError(response.message));
    }
  } catch (error) {
    yield put(
      getFinanceRepaymentDetailsError(
        error
      )
    );
  }
}

function* postCreateCarbonSettingsFunc({ payload: { data } }) {
  try {
    const response = yield call(createCarbonRoadmapSetting, data);
    if (response.statusCode === 200) {
      yield put(postCarbonRoadmapSettingsSuccess(response));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        postCarbonRoadmapSettingsError(
          response.responseException.exceptionMessage.fieldErrors
        )
      );
    } else {
      put(
        postCarbonRoadmapSettingsError(
          response.responseException.exceptionMessage
        )
      );
    }
  } catch (error) {
    yield put(
      postCarbonRoadmapSettingsError(
        error
      )
    );
  }
}

function* getCarbonRoadmapSettingsFunc() {
  try {
    const response = yield call(getCarbonRoadmapSetting);
    if (
      response.statusCode === 200 &&
      response.message === "Carbon road map settings"
    ) {
      yield put(getCarbonRoadmapSettingsSuccess(response.data));
    } else {
      yield put(getCarbonRoadmapSettingsError(response.message));
    }
  } catch (error) {
    yield put(
      getCarbonRoadmapSettingsError(
        error
      )
    );
  }
}

function* postGlobalOpportunityAccount({ payload: { data } }) {
  try {
    const response = yield call(postOpportunityGlobal, data);
    if (response.statusCode === 200) {
      yield put(postGlobalOpportunitySuccess(response));
    } else if (response.responseException.exceptionMessage) {
      yield put(
        postGlobalOpportunityError(response.responseException.exceptionMessage)
      );
    } else {
      put(postGlobalOpportunityError(response.message));
    }
  } catch (error) {
    yield put(postGlobalOpportunityError(error));
  }
}

function* removeRepaymentSectionFunc({ payload: { id } }) {
  try {
    const response = yield call(removeRepayment, id);
    if (response.statusCode === 200) {
      yield put(removeRepaymentSectionSuccess(response.data));
    } else if (response.responseException) {
      yield put(
        removeRepaymentSectionFailed(response.responseException.message)
      );
    } else {
      yield put(removeRepaymentSectionFailed(response.message));
    }
  } catch (error) {
    yield put(
      removeRepaymentSectionFailed(error)
    );
  }
}

function* removeCategorySectionFunc({ payload: { id } }) {
  try {
    const response = yield call(removeCategory, id);
    if (response.statusCode === 200) {
      yield put(removeCategorySectionSuccess(response.data));
    } else if (response.responseException) {
      yield put(
        removeCategorySectionFailed(response.responseException.message)
      );
    } else {
      yield put(removeCategorySectionFailed(response.message));
    }
  } catch (error) {
    yield put(
      removeCategorySectionFailed(error)
    );
  }
}

export function* watchSettings() {
  yield takeEvery(GET_GLOBAL_PARAMS, getGlobalSettingsFun);
  yield takeEvery(GET_DASHBOARD_SETTINGS, getDashboardSettingsFun);
  yield takeEvery(GET_FINANCE_SETTINGS, getFinanceSettingsFunc);
  yield takeEvery(POST_FINANCE_CATEGORY_CREATE, postCreateFinanceCategory);
  yield takeEvery(UPDATE_FINANCE_CATEGORY, updateFinanceCategoryFun);
  yield takeEvery(GET_FINANCE_CATEGORY_DETAILS, getFinanceCategoryDetailsFunc);
  yield takeEvery(POST_FINANCE_REPAYMENT_CREATE, postCreateFinanceRepayment);
  yield takeEvery(UPDATE_FINANCE_REPAYMENT, updateFinanceRepaymentFun);
  yield takeEvery(
    GET_FINANCE_REPAYMENT_DETAILS,
    getFinanceRepaymentDetailsFunc
  );
  yield takeEvery(POST_CARBON_ROADMAP_SETTINGS, postCreateCarbonSettingsFunc);
  yield takeEvery(GET_CARBON_ROADMAP_SETTINGS, getCarbonRoadmapSettingsFunc);
  yield takeEvery(POST_GLOBAL_OPPORTUNITY, postGlobalOpportunityAccount);
  yield takeEvery(REMOVE_REPAYMENT_SECTION, removeRepaymentSectionFunc);
  yield takeEvery(REMOVE_CATEGORY_SECTION, removeCategorySectionFunc);
}

function* SettingsSaga() {
  yield all([fork(watchSettings)]);
}

export default SettingsSaga;
