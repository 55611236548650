import React from "react";
import { IconContext } from "react-icons";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { flexRender } from "@tanstack/react-table";

const CustomReactTable = (props) => {
  const { table, tableClass } = props;

  return (
    <div className="custom-data-table">
      <table
        className={`table table-striped table-bordered table-sm ${tableClass}`}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {
                      {
                        asc: (
                          <IconContext.Provider value={{ size: "20" }}>
                            <MdArrowDropUp data-testid="contact-icon" />
                          </IconContext.Provider>
                        ),
                        desc: (
                          <IconContext.Provider value={{ size: "20" }}>
                            <MdArrowDropDown data-testid="contact-icon" />
                          </IconContext.Provider>
                        ),
                      }[header.column.getIsSorted() ?? null]
                    }
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomReactTable;
