export const GET_CLIENT_DASHBOARD = "GET_CLIENT_DASHBOARD";
export const GET_CLIENT_DASHBOARD_SUCCESS = "GET_CLIENT_DASHBOARD_SUCCESS";
export const GET_CLIENT_DASHBOARD_ERROR = "GET_CLIENT_DASHBOARD_ERROR";
export const GET_FORECAST_DATA = "GET_FORECAST_DATA";
export const GET_FORECAST_DATA_SUCCESS = "GET_FORECAST_DATA_SUCCESS";
export const GET_FORECAST_DATA_ERROR = "GET_FORECAST_DATA_ERROR";
export const GET_CATEGORY_AND_SUMMARY = "GET_CATEGORY_AND_SUMMARY";
export const GET_CATEGORY_AND_SUMMARY_SUCCESS =
  "GET_CATEGORY_AND_SUMMARY_SUCCESS";
export const GET_CATEGORY_AND_SUMMARY_ERROR = "GET_CATEGORY_AND_SUMMARY_ERROR";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const GET_GP_PARENT_COMPANIES = "GET_GP_PARENT_COMPANIES";
export const GET_GP_PARENT_COMPANIES_SUCCESS =
  "GET_GP_PARENT_COMPANIES_SUCCESS";
export const GET_GP_PARENT_COMPANIES_ERROR = "GET_GP_PARENT_COMPANIES_ERROR";
