import { combineReducers } from "redux";

// Front
import Admin from "./user/admin/reducer";
import Account from "./Admin/Accounts/reducers";
import Client from "./Admin/Client/reducers";
import Auth from "./Admin/Auth/reducers";
import Audit from "./Admin/Audit/reducers";
import Opportunity from "./Admin/Audit/Opportunity/reducers";
import Settings from "./Settings/reducers";
import Report from "./Report/Audit/reducers";
import Dashboard from "./Dashboard/Admin/reducers";
import ClientReport from "./Client/Report/reducers";
import ClientProject from "./Client/Project/reducers";
import ClientCarbon from "./Client/CarbonRoadMap/reducers";
import ProjectAnalyst from "./Project/Analyst/reducers";
import ClientDashboard from "./Dashboard/Client/reducers";
import GPDashboard from "./Dashboard/GP/staff/reducres";
import EPCDetails from "./EPC/reducers";

const rootReducer = combineReducers({
  Admin,
  Account,
  Client,
  Auth,
  Audit,
  Opportunity,
  Settings,
  Report,
  Dashboard,
  ClientReport,
  ClientProject,
  ClientCarbon,
  ProjectAnalyst,
  ClientDashboard,
  GPDashboard,
  EPCDetails,
});

export default rootReducer;
