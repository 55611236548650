import {
  GET_CLIENT_DASHBOARD,
  GET_CLIENT_DASHBOARD_SUCCESS,
  GET_CLIENT_DASHBOARD_ERROR,
  GET_FORECAST_DATA,
  GET_FORECAST_DATA_SUCCESS,
  GET_FORECAST_DATA_ERROR,
  GET_CATEGORY_AND_SUMMARY,
  GET_CATEGORY_AND_SUMMARY_SUCCESS,
  GET_CATEGORY_AND_SUMMARY_ERROR,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_CLIENT_BUSINESS_ID,
  GET_CLIENT_BUSINESS_ID_SUCCESS,
  GET_CLIENT_BUSINESS_ID_ERROR,
  GET_GP_PARENT_COMPANIES,
  GET_GP_PARENT_COMPANIES_SUCCESS,
  GET_GP_PARENT_COMPANIES_ERROR,
} from "./actionTypes";

export const getClientDashboard = (parm) => {
  return {
    type: GET_CLIENT_DASHBOARD,
    payload: { parm },
  };
};
export const getClientrDashboardSuccess = (data) => {
  return {
    type: GET_CLIENT_DASHBOARD_SUCCESS,
    payload: { data },
  };
};
export const getClientDashboardFaild = (error) => {
  return {
    type: GET_CLIENT_DASHBOARD_ERROR,
    payload: { error },
  };
};
export const getForecashData = (year, company) => {
  return {
    type: GET_FORECAST_DATA,
    payload: { year, company },
  };
};
export const getForecashDataSuccess = (data) => {
  return {
    type: GET_FORECAST_DATA_SUCCESS,
    payload: { data },
  };
};
export const getForecashDataFaild = (error) => {
  return {
    type: GET_FORECAST_DATA_ERROR,
    payload: { error },
  };
};
export const getCategoryAndSummary = () => {
  return {
    type: GET_CATEGORY_AND_SUMMARY,
    payload: {},
  };
};
export const getCategoryAndSummarySuccess = (data) => {
  return {
    type: GET_CATEGORY_AND_SUMMARY_SUCCESS,
    payload: { data },
  };
};
export const getCategoryAndSummaryFaild = (error) => {
  return {
    type: GET_CATEGORY_AND_SUMMARY_ERROR,
    payload: { error },
  };
};
export const getCompanies = () => {
  return {
    type: GET_COMPANIES,
    payload: {},
  };
};
export const getCompaniesSuccess = (data) => {
  return {
    type: GET_COMPANIES_SUCCESS,
    payload: { data },
  };
};
export const getCompaniesFaild = (error) => {
  return {
    type: GET_COMPANIES_ERROR,
    payload: { error },
  };
};

export const getGpParentCompanies = () => {
  return {
    type: GET_GP_PARENT_COMPANIES,
    payload: {},
  };
};
export const getGpParentCompaniesSuccess = (data) => {
  return {
    type: GET_GP_PARENT_COMPANIES_SUCCESS,
    payload: { data },
  };
};
export const getGpParentCompaniesFaild = (error) => {
  return {
    type: GET_GP_PARENT_COMPANIES_ERROR,
    payload: { error },
  };
};
