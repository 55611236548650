import {
  POST_AUDIT_ACCOUNT_CREATE,
  POST_AUDIT_ACCOUNT_CREATE_SUCCESS,
  POST_AUDIT_ACCOUNT_CREATE_ERROR,
  UPDATE_AUDIT_ACCOUNT,
  UPDATE_AUDIT_ACCOUNT_SUCCESS,
  UPDATE_AUDIT_ACCOUNT_ERROR,
  GET_AUDIT_ACCOUNT_DETAILS,
  GET_AUDIT_ACCOUNT_DETAILS_SUCCESS,
  GET_AUDIT_ACCOUNT_DETAILS_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_AUDIT_LIST,
  GET_AUDIT_LIST_SUCCESS,
  GET_AUDIT_LIST_ERROR,
  GET_PRODUCT_MAIN_CATEGORIES,
  GET_PRODUCT_MAIN_CATEGORIES_SUCCESS,
  GET_PRODUCT_MAIN_CATEGORIES_ERROR,
  CREATE_AUDIT_SECTION,
  CREATE_AUDIT_SECTION_SUCCESS,
  CREATE_AUDIT_SECTION_ERROR,
  VIEW_AUDIT_SECTION,
  VIEW_AUDIT_SECTION_SUCCESS,
  VIEW_AUDIT_SECTION_ERROR,
  REMOVE_AUDIT_FINDINGS,
  REMOVE_AUDIT_FINDINGS_SUCCESS,
  REMOVE_AUDIT_FINDINGS_ERROR,
  REMOVE_AUDIT_SECTION,
  REMOVE_AUDIT_SECTION_SUCCESS,
  REMOVE_AUDIT_SECTION_ERROR,
  UPDATE_AUDIT_SECTION,
  UPDATE_AUDIT_SECTION_SUCCESS,
  UPDATE_AUDIT_SECTION_ERROR,
  VIEW_AUDIT_FINDING_DATA_SET,
  VIEW_AUDIT_FINDING_DATA_SET_SUCCESS,
  VIEW_AUDIT_FINDING_DATA_SET_ERROR,
  CREATE_AUDIT_FINDINGS,
  CREATE_AUDIT_FINDINGS_SUCCESS,
  CREATE_AUDIT_FINDINGS_ERROR,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR,
  SET_TO_DEFAULT,
  UPDATE_AUDIT_FINDINGS,
  UPDATE_AUDIT_FINDINGS_SUCCESS,
  UPDATE_AUDIT_FINDINGS_ERROR,
  REMOVE_MEDIA_SECTION,
  REMOVE_MEDIA_SECTION_SUCCESS,
  REMOVE_MEDIA_SECTION_ERROR,
  DOWNLOAD_MEDIA,
  DOWNLOAD_MEDIA_SUCCESS,
  DOWNLOAD_MEDIA_ERROR,
  GET_AUDIT_FINDINGS_DETAILS,
  GET_AUDIT_FINDINGS_DETAILS_SUCCESS,
  GET_AUDIT_FINDINGS_DETAILS_ERROR,
  RESET_TO_DEFAULT_STATE,  
  POST_AUDIT_OPPORTUNITY,
  POST_AUDIT_OPPORTUNITY_SUCCESS,
  POST_AUDIT_OPPORTUNITY_ERROR
} from "./actionTypes";

export const postAuditAccount = (data) => {
  return {
    type: POST_AUDIT_ACCOUNT_CREATE,
    payload: { data },
  };
};
export const postAuditAccountSuccess = (data) => {
  return {
    type: POST_AUDIT_ACCOUNT_CREATE_SUCCESS,
    payload: { data },
  };
};
export const postAuditAccountError = (error) => {
  return {
    type: POST_AUDIT_ACCOUNT_CREATE_ERROR,
    payload: { error },
  };
};
export const updateAuditAccount = (data) => {
  return {
    type: UPDATE_AUDIT_ACCOUNT,
    payload: { data },
  };
};
export const updateAuditAccountSuccess = (data) => {
  return {
    type: UPDATE_AUDIT_ACCOUNT_SUCCESS,
    payload: { data },
  };
};
export const updateAuditAccountError = (error) => {
  return {
    type: UPDATE_AUDIT_ACCOUNT_ERROR,
    payload: { error },
  };
};
export const getAuditAccountDetails = (id) => {
  return {
    type: GET_AUDIT_ACCOUNT_DETAILS,
    payload: { id },
  };
};
export const getAuditAccountDetailsSuccess = (data) => {
  return {
    type: GET_AUDIT_ACCOUNT_DETAILS_SUCCESS,
    payload: { data },
  };
};
export const getAuditAccountDetailsError = (error) => {
  return {
    type: GET_AUDIT_ACCOUNT_DETAILS_ERROR,
    payload: { error },
  };
};
export const getUsers = (name) => {
  return {
    type: GET_USERS,
    payload: { name },
  };
};
export const getUsersSuccess = (data) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: { data },
  };
};
export const getUsersFaild = (error) => {
  return {
    type: GET_USERS_ERROR,
    payload: { error },
  };
};
export const getAuditList = (parm) => {
  return {
    type: GET_AUDIT_LIST,
    payload: { parm },
  };
};
export const getAuditListSuccess = (data) => {
  return {
    type: GET_AUDIT_LIST_SUCCESS,
    payload: { data },
  };
};
export const getAuditListFaild = (error) => {
  return {
    type: GET_AUDIT_LIST_ERROR,
    payload: { error },
  };
};
export const getMainProductCategories = () => {
  return {
    type: GET_PRODUCT_MAIN_CATEGORIES,
    payload: {},
  };
};
export const getMainProductCategoriesSuccess = (data) => {
  return {
    type: GET_PRODUCT_MAIN_CATEGORIES_SUCCESS,
    payload: { data },
  };
};
export const getMainProductCategoriesFaild = (error) => {
  return {
    type: GET_PRODUCT_MAIN_CATEGORIES_ERROR,
    payload: { error },
  };
};
export const createAuditSection = (data) => {
  return {
    type: CREATE_AUDIT_SECTION,
    payload: { data },
  };
};
export const createAuditSectionSucess = (data) => {
  return {
    type: CREATE_AUDIT_SECTION_SUCCESS,
    payload: { data },
  };
};
export const createAuditSectionFaild = (error) => {
  return {
    type: CREATE_AUDIT_SECTION_ERROR,
    payload: { error },
  };
};
export const viewAuditSection = (parm) => {
  return {
    type: VIEW_AUDIT_SECTION,
    payload: { parm },
  };
};
export const viewAuditSectionSucess = (data) => {
  return {
    type: VIEW_AUDIT_SECTION_SUCCESS,
    payload: { data },
  };
};
export const viewAuditSectionFaild = (error) => {
  return {
    type: VIEW_AUDIT_SECTION_ERROR,
    payload: { error },
  };
};
export const removeAuditFindings = (id) => {
  return {
    type: REMOVE_AUDIT_FINDINGS,
    payload: { id },
  };
};
export const removeAuditFindingsSucess = (data) => {
  return {
    type: REMOVE_AUDIT_FINDINGS_SUCCESS,
    payload: { data },
  };
};
export const removeAuditFindingsFaild = (error) => {
  return {
    type: REMOVE_AUDIT_FINDINGS_ERROR,
    payload: { error },
  };
};
export const removeAuditSection = (id) => {
  return {
    type: REMOVE_AUDIT_SECTION,
    payload: { id },
  };
};
export const removeAuditSectionSucess = (data) => {
  return {
    type: REMOVE_AUDIT_SECTION_SUCCESS,
    payload: { data },
  };
};
export const removeAuditSectionFaild = (error) => {
  return {
    type: REMOVE_AUDIT_SECTION_ERROR,
    payload: { error },
  };
};
export const updateAuditSection = (data) => {
  return {
    type: UPDATE_AUDIT_SECTION,
    payload: { data },
  };
};
export const updateAuditSectionSucess = (data) => {
  return {
    type: UPDATE_AUDIT_SECTION_SUCCESS,
    payload: { data },
  };
};
export const updateAuditSectionFaild = (error) => {
  return {
    type: UPDATE_AUDIT_SECTION_ERROR,
    payload: { error },
  };
};
export const viewAuditFindingsDataSet = (parm) => {
  return {
    type: VIEW_AUDIT_FINDING_DATA_SET,
    payload: { parm },
  };
};
export const viewAuditFindingsDataSetSucess = (data) => {
  return {
    type: VIEW_AUDIT_FINDING_DATA_SET_SUCCESS,
    payload: { data },
  };
};
export const viewAuditFindingsDataSetFaild = (error) => {
  return {
    type: VIEW_AUDIT_FINDING_DATA_SET_ERROR,
    payload: { error },
  };
};
export const createAuditFindings = (data) => {
  return {
    type: CREATE_AUDIT_FINDINGS,
    payload: { data },
  };
};
export const createAuditFindingsSucess = (data) => {
  return {
    type: CREATE_AUDIT_FINDINGS_SUCCESS,
    payload: { data },
  };
};
export const createAuditFindingsFaild = (error) => {
  return {
    type: CREATE_AUDIT_FINDINGS_ERROR,
    payload: { error },
  };
};
export const uploadFiles = (data, id) => {
  return {
    type: UPLOAD_FILES,
    payload: { data, id },
  };
};
export const uploadFilesSuccess = (data) => {
  return {
    type: UPLOAD_FILES_SUCCESS,
    payload: { data },
  };
};
export const uploadFilesError = (error) => {
  return {
    type: UPLOAD_FILES_ERROR,
    payload: { error },
  };
};
export const auditFindingsSetToDefault = () => {
  return {
    type: SET_TO_DEFAULT,
    payload: {},
  };
};
export const updateAuditFindings = (data) => {
  return {
    type: UPDATE_AUDIT_FINDINGS,
    payload: { data },
  };
};
export const updateAuditFindingsSucess = (data) => {
  return {
    type: UPDATE_AUDIT_FINDINGS_SUCCESS,
    payload: { data },
  };
};
export const updateAuditFindingsFaild = (error) => {
  return {
    type: UPDATE_AUDIT_FINDINGS_ERROR,
    payload: { error },
  };
};
export const removeMediaSection = (id, mediaId) => {
  return {
    type: REMOVE_MEDIA_SECTION,
    payload: { id, mediaId },
  };
};
export const removeMediaSectionSucess = (data) => {
  return {
    type: REMOVE_MEDIA_SECTION_SUCCESS,
    payload: { data },
  };
};
export const removeMediaSectionFaild = (error) => {
  return {
    type: REMOVE_MEDIA_SECTION_ERROR,
    payload: { error },
  };
};
export const downloadFile = (id, mediaId, type) => {
  return {
    type: DOWNLOAD_MEDIA,
    payload: { id, mediaId, type },
  };
};
export const downloadFileSucess = (data) => {
  return {
    type: DOWNLOAD_MEDIA_SUCCESS,
    payload: { data },
  };
};
export const downloadFileFaild = (error) => {
  return {
    type: DOWNLOAD_MEDIA_ERROR,
    payload: { error },
  };
};
export const getAuditFindingsDetails = (id) => {
  return {
    type: GET_AUDIT_FINDINGS_DETAILS,
    payload: { id },
  };
};
export const getAuditFindingsDetailsSucess = (data) => {
  return {
    type: GET_AUDIT_FINDINGS_DETAILS_SUCCESS,
    payload: { data },
  };
};
export const getAuditFindingsDetailsFaild = (error) => {
  return {
    type: GET_AUDIT_FINDINGS_DETAILS_ERROR,
    payload: { error },
  };
};
export const resetToDefaultState = () => {
  return {
    type: RESET_TO_DEFAULT_STATE,
    payload: {},
  };
};
export const postAuditOpportunity = (data) => {
  return {
    type: POST_AUDIT_OPPORTUNITY,
    payload: { data },
  };
};
export const postAuditOpportunitySuccess = (data) => {
  return {
    type: POST_AUDIT_OPPORTUNITY_SUCCESS,
    payload: { data },
  };
};
export const postAuditOpportunityError = (error) => {
  return {
    type: POST_AUDIT_OPPORTUNITY_ERROR,
    payload: { error },
  };
};
