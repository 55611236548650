import React, { useState, useCallback } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  ProgressBar,
} from "react-bootstrap";
// import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import { FiImage } from "react-icons/fi";
import { Alert } from "reactstrap";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import UploadIcon from "../../../assets/images/icons/UploadIcon.svg";

const LeftImage = (props) => {
  const {
    selectedLeftImage,
    setSelectedLeftImage,
    leftImageRequired,
    setLeftImageRequired,
    uploadedLeftImage,
    setUploadedLeftImage,
    fileNameLenthError,
    setFileNameLenthError,
    fieldsDisabled,
    setFieldsDisabled,
    reportStatus,
    setErrorSrc,
    errorSrc,
  } = props;
  const MAX_BATCH_SIZE = process.env.REACT_APP_FILE_UPLOAD_MAX_BATCH_SIZE;;

  const [fileSizeError, setFileSizeError] = useState(false);

  const [currentSelectedFile, setCurrentSelectedFile] = useState(0);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [currentUploadedFile, setCurrentUploadedFile] = useState({});
  const [imageFileName, setImageFileName] = useState("");
  const [progressFile, setProgressFile] = useState(0);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFileNameLenthError(false);
    setErrorSrc(false);
    setFileSizeError(false);
    setUploadedLeftImage([]);
    setCurrentSelectedFile(0);
    setFileTypeError(false);
    acceptedFiles.forEach((file) => {
      setShowProgress(true);
      const reader = new FileReader();

      reader.onload = () => {
        setCurrentUploadedFile(reader.result);

        if (reader.result) {
          setShowProgress(false);
        }
      };

      reader.fileName = file.name;
      setImageFileName(reader.fileName);
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          let percentage = (event.loaded / event.total) * 100;
          setTimeout(() => setProgressFile(percentage), 100);
        }
      };
      setSelectedLeftImage(file);
      reader.readAsDataURL(file);
      setCurrentSelectedFile(1);
    });
    if (rejectedFiles.length > 0) {
      if (!rejectedFiles[0].file.type.includes("image")) {
        setFileTypeError(true);
      } else if (rejectedFiles[0].file.size > JSON.parse(MAX_BATCH_SIZE)) {
        setFileSizeError(true);
        setFileTypeError(false);
      } else {
        setFileSizeError(false);
        setFileTypeError(false);
      }
    }
  }, []);

  const formPhotoValidation = () => {
    let errors = [];
    if (currentSelectedFile === 0) {
      setLeftImageRequired(false);
      setFileTypeError(false);
      errors.push(1);
    } else {
      setLeftImageRequired(false);
    }

    if (uploadedLeftImage.length > 0) {
      if (
        uploadedLeftImage[0].name !== "" &&
        uploadedLeftImage[0].name.length > 200
      ) {
        setFileNameLenthError(true);

        errors.push(1);
      } else {
        setFileNameLenthError(false);
      }
    }

    return errors;
  };

  const uploadPhoto = (e) => {
    e.preventDefault();
    let valid = formPhotoValidation();
    if (valid.length === 0) {
      let temp = {
        file: currentUploadedFile,
        binaryFile: selectedLeftImage,
        fileSize: selectedLeftImage.size,
        mimeType: selectedLeftImage.type,
        name: selectedLeftImage.name,
      };

      if (selectedLeftImage.name.length > 200) {
        setFileNameLenthError(true);
      }

      uploadedLeftImage.pop();

      setUploadedLeftImage((uploadedLeftImage) => [...uploadedLeftImage, temp]);
      setCurrentSelectedFile(0);
      setProgressFile(0);
    }
  };

  const addErrorinSRC = (ev) => {
    setErrorSrc(true);
  };

  return (
    <React.Fragment>
      <Form.Group className="mb-3 row mt-4" controlId="formRoleStatus">
        <Col sm={4} md={3} lg={3}></Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}><b>Left Image</b> - Recommended image size - 1120*630px</Form.Label>
          {currentSelectedFile === 1 ? (
            <div className="dropzone-uploading-editor">
              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    {progressFile !== 100 && (
                      <div className="uploading-sentence">Loading</div>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={2}>
                    <img src={UploadIcon} alt="error" width={50} height={50}/>
                  </Col>
                  <Col lg={10}>
                    <div className="selected-image-name">{imageFileName}</div>
                    <ProgressBar
                      now={progressFile}
                      style={{ backgroundColor: "#E3E4E8" }}
                      className="file-progress-bar"
                    />
                  </Col>
                </Row>
              </Col>
            </div>
          ) : (
            <Dropzone
              onDrop={(fieldsDisabled === false || reportStatus === 0) ? onDrop : undefined}
              multiple={false}
              accept="image/*"
              maxSize={JSON.parse(MAX_BATCH_SIZE)}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                rejectedFiles,
              }) => (
                <section>
                  <div {...getRootProps({ className: "dropzone-editor" })}>
                    {(fieldsDisabled === false || reportStatus === 0) && (
                      <input {...getInputProps()} />                      
                    )}

                    {uploadedLeftImage.length > 0 &&
                    currentSelectedFile === 0 ? (
                      <div>
                        {uploadedLeftImage.map((photoData, i) => (
                          <div key={i}>
                            <img
                              onError={addErrorinSRC}
                              className="dropzone-image-inside"
                              src={photoData.file}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <FiImage size={40} color="#55636B" />
                        <p className="click-browse-btn">
                          Click to browse
                        </p>
                      </div>
                    )}
                  </div>
                  <aside className="selected-file-wrapper"></aside>
                </section>
              )}
            </Dropzone>
          )}

          <span
            className={`inline-form-error ${
              leftImageRequired ? "display-error" : ""
            }`}
          >
            Please upload an image which appears in the left side of the report
            header.
          </span>
          <span
            className={`inline-form-error ${
              fileNameLenthError ? "display-error" : ""
            }`}
          >
            Please ensure that the image file name length is less than 200
            characters.
          </span>
          <span
            className={`inline-form-error ${errorSrc ? "display-error" : ""}`}
          >
            Image could not be loaded for uploading. Please re-select the file.
          </span>
          {fileSizeError && (
            <div className="form-group sort-form-group">
              <Row className="px-2">
                <Alert color="danger" isOpen={fileSizeError}>
                  <img src={ErrorValidation} alt="error" width={30} height={30}/>
                  Please upload an Image which is less than 28MB.
                </Alert>
              </Row>
            </div>
          )}
          {fileTypeError && (
            <div className="form-group sort-form-group">
              <Row className="px-2">
                <Alert color="danger" isOpen={fileTypeError}>
                  <img src={ErrorValidation} alt="error" width={30} height={30}/>
                  Incorrect file type.
                </Alert>
              </Row>
            </div>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row" controlId="formAuditData">
        <Col sm={4} md={3} lg={3}></Col>
        <Col lg={8} className="sort-form-field">
          <Button
            className={`sort-primary-add-video-btn ${
              fieldsDisabled ? "disable-fields" : ""
            }`}
            style={{ width: "100%" }}
            onClick={uploadPhoto}
            disabled={progressFile !== 100 || fieldsDisabled}
          >
            Add Image
          </Button>
        </Col>
      </Form.Group>
    </React.Fragment>
  );
};

export default LeftImage;
