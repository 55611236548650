import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { Input } from "reactstrap";
import ErrorValidation from "../../assets/images/icons/ErrorValidation.svg";
import { isEmail } from "validator";
import { generatePassword } from "../../helpers/password_helper";

const UpdateUserDetails = (props) => {
  const {
    setCreateClientObj,
    setShowEdit,
    setUserAdded,
    selectedRowToUpdate,
    createCientObj,
    setShowConfirmation,
    isSubmitEnabled,
    setSubmitEnabled
  } = props;

  const [password, setPassword] = useState(
    createCientObj
      ? createCientObj[selectedRowToUpdate].password &&
      createCientObj[selectedRowToUpdate].password
      : ""
  );
  const [isPasswordChanged, setIsPasswordChange] = useState(false);

  const [values, setValues] = useState({
    firstName: createCientObj
      ? createCientObj[selectedRowToUpdate].firstName
      : "",
    lastName: createCientObj
      ? createCientObj[selectedRowToUpdate].lastName
      : "",
    email: createCientObj ? createCientObj[selectedRowToUpdate].email : "",
    status: createCientObj ? createCientObj[selectedRowToUpdate].status : "",
  });

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length >= 1) {
      if (e.target.name === "firstName") {
        setFnameRequired(false);
      }
      if (e.target.name === "lastName") {
        setLnameRequired(false);
      }
    } else {
      if (e.target.name === "firstName") {
        setFnameRequired(true);
      }
      if (e.target.name === "lastName") {
        setLnameRequired(true);
      }
    }
  };

  const handleGeneratePassword = () => {
    setIsPasswordChange(true);
    setPassword(generatePassword());
  };

  const [fnameRequired, setFnameRequired] = useState(false);
  const [lnameRequired, setLnameRequired] = useState(false);

  const handleSubmit = () => {
    var data = [...createCientObj];
    setFnameRequired(false);
    setLnameRequired(false);
    if (values.firstName === "") {
      setFnameRequired(true);
    } else {
      setFnameRequired(false);
    }
    if (values.lastName === "") {
      setLnameRequired(true);
    } else {
      setLnameRequired(false);
    }
    if (
      values.firstName !== "" &&
      values.lastName !== "" &&
      values.email !== "" &&
      isEmail(values.email)
    ) {
      setUserAdded(true);
      data[selectedRowToUpdate].firstName = values.firstName;
      data[selectedRowToUpdate].lastName = values.lastName;
      data[selectedRowToUpdate].email = values.email;
      data[selectedRowToUpdate].status = JSON.parse(values.status);
      data[selectedRowToUpdate].password = password;
      data[selectedRowToUpdate].isNewPassword = isPasswordChanged;
      setCreateClientObj([...data]);
      setShowEdit(false);
      setSubmitEnabled(true);
    }
  };

  const handleConfirmationEdit = () => {
    if (
      createCientObj[selectedRowToUpdate].firstName !== values.firstName ||
      createCientObj[selectedRowToUpdate].lastName !== values.lastName ||
      createCientObj[selectedRowToUpdate].password !== null
    ) {
      setShowConfirmation(true);
    } else {
      setShowEdit(false);
    }
  };

  return (
    <Container>
      <Form>
        <Row>
          <Col lg={6}>
            <Form.Group controlId="formBasicFirstName">
              <Form.Label className="update-form-label">First Name*</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="firstName"
                    type="text"
                    placeholder="Type the first name"
                    className={`sort-form-input form-control ${fnameRequired ? "errorField" : ""
                      }`}
                    value={values.firstName}
                    onChange={handleChange}
                    autoComplete="fname"
                  />
                  {fnameRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${fnameRequired ? "display-error" : ""
                    }`}
                >
                  Please enter the first name.
                </span>
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="formBasicLastName">
              <Form.Label className="update-form-label">Last Name*</Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="lastName"
                    type="text"
                    placeholder="Type the last name"
                    className={`sort-form-input form-control ${lnameRequired ? "errorField" : ""
                      }`}
                    value={values.lastName}
                    onChange={handleChange}
                    autoComplete="lname"
                  />
                  {lnameRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${lnameRequired ? "display-error" : ""
                    }`}
                >
                  Please enter the last name.
                </span>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="update-form-label">Email*</Form.Label>
            <div className="sort-form-wrapper">
              <div className="form-group sort-form-group">
                <input
                  name="email"
                  type="email"
                  placeholder="Type the email"
                  className={`sort-form-input form-control`}
                  value={values.email}
                  onChange={handleChange}
                  autoComplete="emailAdd"
                  readOnly
                />
              </div>
            </div>
          </Form.Group>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Form.Group controlId="formBasicStatus">
            <Form.Label className="update-form-label">Status</Form.Label>
            <Input
              type="select"
              name="status"
              onChange={handleChange}
              value={values.status}
              className="update-select-option"
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </Input>
          </Form.Group>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className="update-form-label">Password</Form.Label>
            <input
              name="pass"
              type="text"
              className={`sort-form-input form-control`}
              value={password && password.length > 10 ? "**********" : password}
              // onChange={setPassword}
              autoComplete="pass"
              disabled
            />
          </Form.Group>
        </Row>
        <Row style={{ marginTop: "5px" }}>
          <Button
            className="sort-primary-outline-btn"
            style={{ width: 395, marginLeft: 12 }}
            onClick={handleGeneratePassword}
          >
            Generate New Password
          </Button>
        </Row>
        <Row className={"mt-3"}>
          <Col lg={12} className={"text-center"}>
            <Button
              className="sort-primary-btn btn-min-width"
              style={{ margin: "0 10px" }}
              type={"button"}
              onClick={handleSubmit}
            >
              Submit Changes
            </Button>
            <Button
              className="sort-primary-outline-btn btn-min-width"
              onClick={handleConfirmationEdit}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default UpdateUserDetails;
