import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Col, Button, Row, Card } from "react-bootstrap";
import {
  viewClientBusinessAccounts,
  getBuildingTypes,
  getClientBusinessAccountDetails,
  resetClientBusiness,
} from "../../../store/Admin/Client/actions";
import Edit from "../../../assets/images/icons/Edit.svg";
import Breadcrumbs from "../../../components/common/Breadcrum";
import { Helmet } from "react-helmet-async";
import { Container, Alert } from "reactstrap";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Pagination from "../../../components/common/pagination";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import SearchIcon from "../../../assets/images/icons/SearchIcon.svg";
import { default as ReactSelect } from "react-select";
import Loader from "../../../components/common/Loader";
import { getDecodeAuthDetails } from "../../../helpers";
import Tooltips from "./subComponents/tooltip";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import CustomReactTable from "../../../components/common/reactTable";

const ViewClientBusinessAccounts = (props) => {
  let navigate = useNavigate();
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;
  const [data, setData] = useState();
  const [sorting, setSorting] = useState([]);

  const handleOnIdle = (event) => {
    window.location.href = "/login";
    window.location.reload();
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  let decoded = getDecodeAuthDetails();

  const permissions = decoded.Permission;

  const [disablePermission, setDisablePermission] = useState(false);

  useEffect(() => {
    if (permissions.includes("PERMISSIONS.BUILDINGTYPE.VIEW")) {
      setDisablePermission(false);
    } else {
      setDisablePermission(true);
    }
    props.resetClientBusiness();
  }, []);

  const getBuilding = () => {
    props.getBuildingTypes();
  };

  const getAccountList = (param) => {
    props.viewClientBusinessAccounts(param);
  };
  useEffect(() => {
    getBuilding();
    let param = {
      pageNumber: 1,
      pageSize: 10,
      buildingTypeIds: [],
    };
    getAccountList(param);
  }, []);

  const [buildingTypeList, setBuildingTypeList] = useState([]);
  const [buildingTypeSelected, setBuildingTypeSelected] = useState(null);
  const [values, setValues] = useState({
    companyName: "",
  });
  const [companyNameError, setCompanyNameError] = useState(false);
  const [buildingTypeError, setBuildingTypeError] = useState(false);
  const [alertShow, setAlertShow] = useState(false);

  const parentCompanyColumnFormatter = (clientType) => {
    if (clientType === "GrandParent") {
      return <span>Grand parent</span>;
    } else if (clientType === "Parent") {
      return <span>Parent</span>;
    } else {
      return <span>Subsidiary</span>;
    }
  };

  const getRowData = (id) => {
    showEditPage(id);
  };

  const showEditPage = (id) => {
    navigate("/client-account-management/update/" + id);
  };

  const buildingFormatter = (types, value) => {
    if (types.length > 40) {
      return (
        <div style={{ whiteSpace: "normal" }}>
          {types.substring(0, 40)}
          <Tooltips text={types} />
        </div>
      );
    } else {
      return value;
    }
  };

  const [showClientPermissionMsg, setShowClientPermissionMsg] = useState(false);

  const handleMouseOver = () => {
    if (!permissions.includes("PERMISSIONS.BUILDINGTYPE.VIEW")) {
      setShowClientPermissionMsg(true);
    }
  };

  const handleMouseLeave = () => {
    setShowClientPermissionMsg(false);
  };

  const columns = [
    {
      header: "Company Name",
      accessorKey: "companyName",
      cell: (info) => (
        <span
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        >
          {info.renderValue()}
        </span>
      ),
      sortingFn: "alphanumeric",
    },
    {
      header: "Building Type",
      accessorKey: "buildingTypes",
      cell: (info) =>
        buildingFormatter(info.row.original.buildingTypes, info.renderValue()),
      sortingFn: "alphanumeric",
    },
    {
      header: "Client Type",
      accessorKey: "clientType",
      cell: (info) =>
        parentCompanyColumnFormatter(info.row.original.clientType),
      sortingFn: "alphanumeric",
    },
    {
      header: "Client Users",
      accessorKey: "noOfClientUsers",
      sortingFn: "alphanumeric",
    },
    {
      header: "",
      accessorKey: "edit",
      enableSorting: false,
      cell: (info) => (
        <img
          className="edit-img"
          src={Edit}
          alt=""
          width={40}
          height={40}
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const paginate = (pageNum) => {
    if (values.companyName) {
      if (buildingTypeSelected) {
        let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
        let searchParams = {
          searchBy: values.companyName,
          buildingTypeIds: buildingTypeIds,
          pageNumber: parseInt(pageNum),
          pageSize: itemsPerPage,
        };
        getAccountList(searchParams);
      } else {
        let searchParams = {
          searchBy: values.companyName,
          pageNumber: parseInt(pageNum),
          pageSize: itemsPerPage,
          buildingTypeIds: [],
        };
        getAccountList(searchParams);
      }
    } else if (buildingTypeSelected) {
      let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
      let searchParams = {
        pageNumber: parseInt(pageNum),
        pageSize: itemsPerPage,
        buildingTypeIds: buildingTypeIds,
      };
      getAccountList(searchParams);
    } else {
      let searchParams = {
        pageNumber: parseInt(pageNum),
        pageSize: itemsPerPage,
        buildingTypeIds: [],
      };
      getAccountList(searchParams);
    }
    var element = document.getElementById("paginate-client-business");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const nextPage = () => {
    if (values.companyName) {
      if (buildingTypeSelected) {
        let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
        let searchParams = {
          searchBy: values.companyName,
          pageNumber: parseInt(currentPage + 1),
          pageSize: itemsPerPage,
          buildingTypeIds: buildingTypeIds,
        };
        getAccountList(searchParams);
      } else {
        let searchParams = {
          searchBy: values.companyName,
          pageNumber: parseInt(currentPage + 1),
          pageSize: itemsPerPage,
          buildingTypeIds: [],
        };
        getAccountList(searchParams);
      }
    } else if (buildingTypeSelected) {
      let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
      let searchParams = {
        pageNumber: parseInt(currentPage + 1),
        pageSize: itemsPerPage,
        buildingTypeIds: buildingTypeIds,
      };
      getAccountList(searchParams);
    } else {
      let searchParams = {
        pageNumber: parseInt(currentPage + 1),
        pageSize: itemsPerPage,
        buildingTypeIds: [],
      };
      getAccountList(searchParams);
    }
    var element = document.getElementById("paginate-client-business");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const prevPage = () => {
    if (values.companyName) {
      if (buildingTypeSelected) {
        let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
        let searchParams = {
          searchBy: values.companyName,
          pageNumber: parseInt(currentPage - 1),
          pageSize: itemsPerPage,
          buildingTypeIds: buildingTypeIds,
        };
        getAccountList(searchParams);
      } else {
        let searchParams = {
          searchBy: values.companyName,
          pageNumber: parseInt(currentPage - 1),
          pageSize: itemsPerPage,
          buildingTypeIds: [],
        };
        getAccountList(searchParams);
      }
    } else if (buildingTypeSelected) {
      let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
      let searchParams = {
        pageNumber: parseInt(currentPage - 1),
        pageSize: itemsPerPage,
        buildingTypeIds: buildingTypeIds,
      };
      getAccountList(searchParams);
    } else {
      let searchParams = {
        pageNumber: parseInt(currentPage - 1),
        pageSize: itemsPerPage,
        buildingTypeIds: [],
      };
      getAccountList(searchParams);
    }
    var element = document.getElementById("paginate-client-business");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const lastPage = () => {
    if (values.companyName) {
      if (buildingTypeSelected) {
        let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
        let searchParams = {
          searchBy: values.companyName,
          pageNumber:
            props.clientAccountList && props.clientAccountList.data.totalPages,
          pageSize: itemsPerPage,
          buildingTypeIds: buildingTypeIds,
        };
        getAccountList(searchParams);
      } else {
        let searchParams = {
          searchBy: values.companyName,
          pageNumber:
            props.clientAccountList && props.clientAccountList.data.totalPages,
          pageSize: itemsPerPage,
          buildingTypeIds: [],
        };
        getAccountList(searchParams);
      }
    } else if (buildingTypeSelected) {
      let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
      let searchParams = {
        pageNumber:
          props.clientAccountList && props.clientAccountList.data.totalPages,
        pageSize: itemsPerPage,
        buildingTypeIds: buildingTypeIds,
      };
      getAccountList(searchParams);
    } else {
      let searchParams = {
        pageNumber:
          props.clientAccountList && props.clientAccountList.data.totalPages,
        pageSize: itemsPerPage,
        buildingTypeIds: [],
      };
      getAccountList(searchParams);
    }
    var element = document.getElementById("paginate-client-business");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const pageOptions = {
    itemsPerPage: itemsPerPage,
    totalPages:
      props.clientAccountList && props.clientAccountList.data.totalPages,
    paginate: paginate,
    nextPage: nextPage,
    prevPage: prevPage,
    currentPage:
      props.clientAccountList && props.clientAccountList.data.currentPage,
    lastPage,
    hasPrevious:
      props.clientAccountList && props.clientAccountList.data.hasPrevious,
    hasNext: props.clientAccountList && props.clientAccountList.data.hasNext,
    totalCount:
      props.clientAccountList && props.clientAccountList.data.totalCount,
  };
  const [currentList, setCurrentList] = useState([]);

  useEffect(() => {
    if (props.clientAccountList) {
      setCurrentPage(props.clientAccountList.data.currentPage);
      setCurrentList(props.clientAccountList.data.response);
      setData(() => [...props.clientAccountList.data.response]);
    }
  }, [props.clientAccountList, indexOfFirstPost, indexOfLastPost, currentPage]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length >= 1) {
      setCompanyNameError(false);
    } else {
      viewAllAccounts();
    }
  };

  const submitFilter = () => {
    setBuildingTypeError(false);
    setAlertShow(true);
    if (buildingTypeSelected) {
      let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
      if (values.companyName) {
        let searchParams = {
          searchBy: values.companyName,
          buildingTypeIds: buildingTypeIds,
          pageNumber: 1,
          pageSize: itemsPerPage,
        };
        getAccountList(searchParams);
      } else {
        let searchParams = {
          buildingTypeIds: buildingTypeIds,
          pageNumber: 1,
          pageSize: itemsPerPage,
        };
        getAccountList(searchParams);
      }
      window.setTimeout(() => {
        setAlertShow(false);
      }, 5000);
    } else {
      setBuildingTypeError(true);
    }
  };

  const submitSearch = () => {
    setCompanyNameError(false);
    if (values.companyName) {
      setAlertShow(true);
      if (buildingTypeSelected) {
        let buildingTypeIds = buildingTypeSelected.map((i) => i.value);
        let searchParams = {
          searchBy: values.companyName,
          pageNumber: 1,
          pageSize: itemsPerPage,
          buildingTypeIds: buildingTypeIds,
        };
        getAccountList(searchParams);
      } else {
        let searchParams = {
          searchBy: values.companyName,
          pageNumber: 1,
          pageSize: itemsPerPage,
          buildingTypeIds: [],
        };
        getAccountList(searchParams);
      }
      window.setTimeout(() => {
        setAlertShow(false);
      }, 5000);
    } else {
      setCompanyNameError(true);
    }
  };

  const viewAllAccounts = () => {
    setBuildingTypeSelected(null);
    setCompanyNameError(false);
    setBuildingTypeError(false);
    setValues({
      companyName: "",
    });
    let param = {
      pageSize: itemsPerPage,
      pageNumber: 1,
      buildingTypeIds: [],
    };
    getAccountList(param);
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitSearch();
    }
  };

  const handleSelect = (selected) => {
    setBuildingTypeSelected(selected);
  };

  useEffect(() => {
    let buildings =
      props.buildingTypes &&
      props.buildingTypes.buildings.data.map((item) => {
        const container = {};

        container.value = item.buildingTypeId;
        container.label = item.buildingTypeName;

        return container;
      });
    setBuildingTypeList(buildings);
  }, [props.buildingTypes]);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      padding: "2px",
      fontSize: "15px",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#daebcc",
        borderRadius: "20px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#77bf4b",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#77bf4b",
      ":hover": {
        backgroundColor: "#daebcc",
        color: "#77bf4b",
        borderRadius: "20px",
      },
    }),
  };

  return (
    <React.Fragment>
      <div id="paginate-client-business"></div>
      <div className="page-content">
        <Helmet>
          <title>{"SORT - User Management"}</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs
            title="View Client Business Accounts"
            breadcrumbItem=""
            page=""
          />
          <hr />
          {props.clientAccountList &&
            props.clientAccountList.data.response.length === 0 &&
            alertShow && (
              <Row className="px-2">
                <Alert color="danger">
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={30}
                    height={30}
                  />
                  There are no client business accounts matching your search.
                </Alert>
              </Row>
            )}
          {props.error && alertShow && (
            <Row className="px-2">
              <Alert color="danger">
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                {props.error}
              </Alert>
            </Row>
          )}

          <div className="d-flex justify-content-end">
            <Button className="sort-btn-link" onClick={() => viewAllAccounts()}>
              View all Accounts
            </Button>
          </div>
          <Form>
            <Form.Group className="mb-1 row mt-3" controlId="formBuildingType">
              <Col
                sm={3}
                md={3}
                lg={6}
                style={{ position: "relative" }}
                className="sort-form-field"
              >
                <Form.Label className="sort-form-lable green-lable">
                  Filter by Building Type
                </Form.Label>
                <div className="sort-form-wrapper">
                  <div
                    className={`form-group sort-form-group`}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                  >
                    <ReactSelect
                      isMulti
                      options={buildingTypeList}
                      placeholder="Choose building types"
                      value={buildingTypeSelected}
                      onChange={handleSelect}
                      isDisabled={disablePermission}
                      styles={customStyles}
                      className={`country-select ${buildingTypeError ? "errorField" : ""}`}
                    />

                    {buildingTypeError && (
                      <img
                        src={ErrorValidation}
                        alt="error"
                        width={20}
                        height={20}
                        className="errorImg"
                      />
                    )}
                    <span
                      className={`inline-form-error ${
                        buildingTypeError ? "display-error" : ""
                      }`}
                    >
                      Please select the building type(s).
                    </span>
                  </div>
                </div>
              </Col>
              <Col sm={1} md={1} lg={1} style={{ position: "relative" }}>
                <Button
                  className="sort-primary-btn"
                  type="button"
                  style={{
                    padding: "7px 26px",
                    marginTop: 27,
                  }}
                  onClick={submitFilter}
                  disabled={disablePermission}
                >
                  Filter
                </Button>
              </Col>
            </Form.Group>
            {showClientPermissionMsg && (
              <span>
                You don't have permission to access this content. Please contact
                the Admin.
              </span>
            )}
            <Form.Group className="row">
              <Col sm={9} md={9} lg={6} style={{ position: "relative" }}>
                <Form.Label
                  className="sort-form-lable green-lable"
                  style={{ visibility: "hidden" }}
                >
                  companyName
                </Form.Label>
                <div
                  className="form-group search-content"
                  style={{ position: "relative", width: "100%" }}
                >
                  <input
                    placeholder="Search Company Name"
                    className={`sort-form-input form-control ${
                      companyNameError ? "errorField" : ""
                    }`}
                    onChange={handleChange}
                    value={values.companyName}
                    name="companyName"
                    type="text"
                    onKeyDown={handleEnterKey}
                    style={{ padding: "12px 15px" }}
                  />
                  <span
                    style={{ position: "absolute", right: "0", top: "1px" }}
                  >
                    <img
                      src={SearchIcon}
                      alt="success"
                      width={40}
                      height={40}
                      className="email-search-btn"
                    />
                  </span>
                  {companyNameError && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      style={{
                        position: "absolute",
                        right: "-20px",
                        top: "10px",
                      }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    companyNameError ? "display-error" : ""
                  }`}
                >
                  Please enter a company name.
                </span>
              </Col>
              <Col sm={1} md={1} lg={1} style={{ position: "relative" }}>
                <Button
                  className="sort-primary-btn"
                  style={{
                    padding: "8px 20px",
                    marginTop: 27,
                  }}
                  type="button"
                  onClick={() => submitSearch()}
                >
                  Search
                </Button>
              </Col>
            </Form.Group>

            <Form.Group className="mb-3 row mt-4">
              <Col sm={12} md={12} lg={12}>
                {currentList.length > 0 && (
                  <CustomReactTable
                    table={table}
                    tableClass="table-first-row-link"
                  />
                )}
                {currentList.length == 0 && props.loading && (
                  <Loader data={"page-spinner"} />
                )}
              </Col>
            </Form.Group>
          </Form>
          <Pagination data={pageOptions} />
        </Container>
      </div>
    </React.Fragment>
  );
};

ViewClientBusinessAccounts.propTypes = {
  viewClientBusinessAccounts: PropTypes.func,
  getBuildingTypes: PropTypes.func,
  clientAccountList: PropTypes.any,
  buildingTypes: PropTypes.any,
  getClientBusinessAccountDetails: PropTypes.func,
  error: PropTypes.any,
  loading: PropTypes.any,
  resetClientBusiness: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    clientAccountList: state.Client.clientAccountList,
    buildingTypes: state.Client.buildingTypes,
    error: state.Client.error,
    loading: state.Client.loading,
    resetClientBusiness: state.Client.resetClientBusiness,
  };
};

export default connect(mapStateToProps, {
  getBuildingTypes,
  viewClientBusinessAccounts,
  getClientBusinessAccountDetails,
  resetClientBusiness,
})(ViewClientBusinessAccounts);
