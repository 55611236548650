import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet-async';
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/common/Breadcrum";
import ChevronLeftCircle from "../../../assets/images/icons/ChevronLeftCircle.png";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import InfoTooltip from "../../../components/common/tooltip";
import { default as ReactSelect } from "react-select";
import DocumentUpload from "./documentUpload";
import UploadingModal from "./subComponents/uploadingModal";
import ReuploadModal from "./subComponents/reuploadModal";
import CancelConfirmation from "./subComponents/cancelConfirmation";
import DiscardChangesPopup from "../../../components/common/discardChangesPopup";
import {
  getProjectDetails,
  updateProject,
  uploadFilesProjectError,
} from "../../../store/Project/Analyst/actions";
import { useParams } from "react-router-dom";
import {
  sliceIntoChunks,
  setAttachmentProject,
  scrollToTop,
  getLoggedUser,
} from "../../../helpers";
import ResponseAlert from "../../../components/common/responseAlert";
import { createRequest } from "./helpers";
import { getDecodeAuthDetails } from "../../../helpers";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const UpdateProject = (props) => {
  const MAX_BATCH_SIZE = process.env.REACT_APP_FILE_UPLOAD_MAX_BATCH_SIZE;
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  let navigate = useNavigate();

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  let decoded = getDecodeAuthDetails();

  const permissions = decoded.Permission;

  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [uploadedDocObj, setUploadedDocObj] = useState([]);
  const [isShowUploadingModal, setIsShowUploadingModal] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [faildDocuments, setFaildDocuments] = useState([]);
  const [uploadFaildCount, setUploadFaildCount] = useState(0);
  const [disableFields, setDisableFields] = useState(false);
  const [isShowCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [responseArrray, setResponseArray1] = useState([]);
  const [isShowDisacrdConfirmation, setIsShowDisacrdConfirmation] =
    useState(false);
  const [error, setError] = useState([]);
  const [showProjectUpdateError, setShowProjectUpdateError] = useState(false);
  const [showUpdateProjectSuccess, setShowUpdateProjectSuccess] =
    useState(false);
  const [showReuploadedFileSuccess, setShowReuploadedFileSuccess] =
    useState(false);
  const [showFileUploadError, setShowFileUploadError] = useState(false);
  const [reupploadValidation, setReupploadValidation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const statusList = [
    { value: 0, label: "Inactive" },
    { value: 1, label: "Active" },
    { value: 2, label: "Requested" },
    { value: 3, label: "Completed" },
  ];

  const backToViewPage = () => {
    navigate("/project");
  };

  const [values, setValues] = useState({
    opportunityName: "",
    client: "",
    site: "",
    productCategory: "",
  });

  let { id } = useParams();
  useEffect(() => {
    if (id) {
      props.getProjectDetails(id);
    }
  }, []);

  const assignExisitingFileDetails = (data) => {
    let tempObj = {};
    let returnArray = [];
    for (let i = 0; i < data.length; i++) {
      tempObj = {
        id: data[i].id,
        name: data[i].name.split(".")[0],
        file: data[i].tokenPath,
        mimeType: data[i].mimeType,
      };
      returnArray.push(tempObj);
    }
    return returnArray;
  };

  useEffect(() => {
    if (props.projectDetails) {
      setValues({
        opportunityName: props.projectDetails.data.name,
        client: props.projectDetails.data.clientBusinessAccountName,
        site: props.projectDetails.data.site,
        productCategory: props.projectDetails.data.productMainCategory,
      });
      setStatus(props.projectDetails.data.status);
      setStatusOldValue(props.projectDetails.data.status);
      if (
        props.projectDetails.data.attachments &&
        props.projectDetails.data.attachments.length !== 0
      ) {
        let array = assignExisitingFileDetails(
          props.projectDetails.data.attachments
        );
        let tempCombinedArray = [...array, ...uploadedDocObj];
        const uniqueArray = tempCombinedArray.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );
        setUploadedDocObj(uniqueArray);
      } else {
        setUploadedDocObj([]);
      }
    } else {
      setValues({
        opportunityName: "",
        client: "",
        site: "",
        productCategory: "",
      });
      setStatus(null);
      setStatusOldValue(null);
      setUploadedDocObj([]);
    }
  }, [props.projectDetails]);

  const [status, setStatus] = useState(null);
  const [statusOldValue, setStatusOldValue] = useState(null);
  const [disableSaveUnchange, setDisableSaveUnchange] = useState(true);
  const [disableSaveUpload, setDisableSaveUpload] = useState(true);
  const [isFormUpdatedUpload, setIsFormUpdatedUpload] = useState(false);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleStatus = (e) => {
    setStatus(e.value);
    if (e.value === statusOldValue) {
      setDisableSaveUnchange(true);
      setIsFormUpdated(false);
    } else {
      setIsFormUpdated(true);
      setDisableSaveUnchange(false);
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    window.scrollTo(0, 0);
    setDisableSave(false);
  };

  const handleCloseReuploadModal = () => {
    setShowConfirmation(false);
    window.scrollTo(0, 0);
    setDisableSave(false);
    setFaildDocuments([]);
  };

  const handleReuploadNow = () => {
    setUploadFaildCount(1);
    setDisableFields(true);
    let removedFailedFiles = uploadedDocObj.filter(
      (ar) => !faildDocuments.find((rm) => rm.name.split(".")[0] === ar.name)
    );
    setUploadedDocObj(removedFailedFiles);
    setShowConfirmation(false);
    setFaildDocuments([]);
  };

  const handleCancelConfirmation = () => {
    setShowCancelConfirmation(false);
  };

  const handleRestForm = () => {
    setShowCancelConfirmation(false);
    setResponseArray1([]);
  };

  const handleDiscardConfirmation = () => {
    setIsShowDisacrdConfirmation(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setDisableSave(true);
    setShowAlert(true);
    let projectData = {
      id: id,
      status: status,
    };
    props.updateProject(projectData);
    window.setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const cancelForm = () => {
    setReupploadValidation(false);
    if (faildDocuments.length > 0) {
      setShowCancelConfirmation(true);
    } else if (isFormUpdated || isFormUpdatedUpload) {
      setIsShowDisacrdConfirmation(true);
    } else {
      backToViewPage();
    }
  };

  const uploadFileFunc = (arr, id) => {
    arr
      .reduce((acc, cur, index) => {
        return acc.then(function () {
          console.log("upload api next batch", new Date().toUTCString());
          return createRequest(
            id,
            cur,
            setAttachmentProject,
            getLoggedUser(),
            props.uploadFilesProjectError,
            setResponseArray1,
            responseArrray
          );
        });
      }, Promise.resolve())
      .then(function () {
        setIsShowUploadingModal(false);
        setDisableSave(false);
        setShowConfirmation(true);
        setShowUpdateProjectSuccess(true);
        setDisableSaveUpload(true);
        setDisableSaveUnchange(true);
        setIsFormUpdated(false);
        setIsFormUpdatedUpload(false);
        console.log("show reupload modal", new Date().toUTCString());
      });
  };

  const fileUploadFunc = (arr) => {
    console.log("show modal", new Date().toUTCString());
    setIsShowUploadingModal(true);
    let slicedArray = sliceIntoChunks(arr, MAX_BATCH_SIZE);
    uploadFileFunc(
      slicedArray,
      props.projectDetails && props.projectDetails.data.id
    );
  };

  useEffect(() => {
    if (props.isSuccessProjectUpdate) {
      const filteredArray = uploadedDocObj.filter((item) => item.binaryFile);
      if (filteredArray.length > 0) {
        fileUploadFunc(filteredArray);
      } else {
        window.scrollTo(0, 0);
        setShowUpdateProjectSuccess(true);
        setDisableSaveUnchange(true);
        setDisableSaveUpload(true);
        setIsFormUpdated(false);
        setIsFormUpdatedUpload(false);
        setDisableSave(false);
      }
      window.setTimeout(() => {
        setShowUpdateProjectSuccess(false);
      }, 5000);
    }
  }, [props.isSuccessProjectUpdate]);

  useEffect(() => {
    if (props.isFailedProjectUpdate) {
      setShowProjectUpdateError(true);
      setDisableSave(false);
      window.setTimeout(() => {
        setShowProjectUpdateError(false);
      }, 5000);
      scrollToTop();
    }
  }, [props.isFailedProjectUpdate]);

  useEffect(() => {
    if (props.error && props.error.error) {
      setError(props.error.error);
    }
  }, [props.error]);

  useEffect(() => {
    if (showConfirmation) {
      setDisableSave(false);
      let tempArray = [...responseArrray];
      let docList = tempArray.filter((a) => a.isSuccess === false);
      setFaildDocuments((faildDocuments) => [...faildDocuments, ...docList]);
      if (docList.length === 0) {
        setShowConfirmation(false);
        setShowReuploadedFileSuccess(true);
        window.scrollTo(0, 0);
        setResponseArray1([]);
        window.setTimeout(() => {
          setShowReuploadedFileSuccess(false);
        }, 5000);
      }
      setUploadedDocObj([]);
      props.getProjectDetails(id);
    }
  }, [showConfirmation]);

  window.setTimeout(() => {
    setShowUpdateProjectSuccess(false);
  }, 5000);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      padding: "6px",
      fontSize: "15px",
    }),
  };

  return (
    <React.Fragment>
      {" "}
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Projects"}</title>
        </Helmet>
        {!permissions.includes("PERMISSIONS.PROJECT.VIEW") && (
          <Row className="permission-denied-page m-0">
            <Card className="no-permission-card">
              You don't have permission to access this content. Please contact
              the Admin.
            </Card>
          </Row>
        )}
        {permissions.includes("PERMISSIONS.PROJECT.VIEW") && (
          <Container fluid>
            <Breadcrumbs
              title={""}
              breadcrumbItem={`${
                props.projectDetails ? props.projectDetails.data.name : ""
              }`}
              page={"View Projects"}
              back={"/project"}
            />
            <Row>
              <div className="header-audit">
                <img
                  src={ChevronLeftCircle}
                  width={40}
                  height={40}
                  alt="back"
                  style={{ marginBottom: "5px", marginLeft: "-13px" }}
                  onClick={() => backToViewPage()}
                />
                <span>{`${
                  props.projectDetails ? props.projectDetails.data.name : ""
                }`}</span>
              </div>
            </Row>
            {props.isFailedProjectUpdate &&
              showProjectUpdateError &&
              showAlert && (
                <Row className="px-2">
                  <ResponseAlert
                    color={"danger"}
                    validationType={ErrorValidation}
                    msg={error}
                    alt={"error"}
                  />
                </Row>
              )}
            {props.isSuccessProjectUpdate &&
              showUpdateProjectSuccess &&
              showAlert && (
                <ResponseAlert
                  color={"success"}
                  validationType={SuccessfulValidation}
                  msg={"Project updated successfully."}
                  alt={"success"}
                />
              )}
            {showReuploadedFileSuccess && uploadFaildCount > 0 && (
              <ResponseAlert
                color={"success"}
                validationType={SuccessfulValidation}
                msg={"The files were re-uploaded successfully."}
                alt={"success"}
              />
            )}
            {props.isFailUploadFilesProject && showFileUploadError && (
              <Row className="px-2">
                {error.map((item) => {
                  return (
                    <ResponseAlert
                      color={"danger"}
                      validationType={ErrorValidation}
                      msg={item}
                      alt={"error"}
                    />
                  );
                })}
              </Row>
            )}
            <hr />
            <Form className="py-1">
              <Form.Group className="mb-3 row mt-1" controlId="formProjectData">
                <Col sm={4} md={3} lg={3}>
                  <Form.Label className="sort-form-section-lable">
                    Opportunity Name &nbsp;
                    <InfoTooltip
                      text={"Opportunity Name"}
                      id="projectAccount"
                    />
                  </Form.Label>
                </Col>
                <Col lg={4} className="sort-form-field">
                  <div className="sort-form-wrapper">
                    <div className="form-group sort-form-group">
                      <input
                        name="opportunityName"
                        type="text"
                        className={`sort-form-input form-control`}
                        value={values.opportunityName}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>
              <br />
              <Form.Group className="mb-3 row mt-1" controlId="formProjectData">
                <Col sm={4} md={3} lg={3}>
                  <Form.Label className="sort-form-section-lable">
                    Project Status
                  </Form.Label>
                  <InfoTooltip text={"Project Status"} id="projectAccount" />
                </Col>
                <Col lg={4} className="sort-form-field">
                  <div
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please select"
                    style={{ width: "100%" }}
                    className={`d-inline-block select-wrapper `}
                  >
                    <ReactSelect
                      options={statusList}
                      className="country-select"
                      value={statusList.filter(function (option) {
                        return option.value === status;
                      })}
                      onChange={handleStatus}
                      styles={customStyles}
                    />
                  </div>
                </Col>
              </Form.Group>
              <br />
              <Form.Group className="mb-3 row mt-1" controlId="formProjectData">
                <Col sm={4} md={3} lg={3}>
                  <Form.Label className="sort-form-section-lable">
                    Client &nbsp;
                    <InfoTooltip text={"Client"} id="projectAccount" />
                  </Form.Label>
                </Col>
                <Col lg={4} className="sort-form-field">
                  <div className="sort-form-wrapper">
                    <div className="form-group sort-form-group">
                      <input
                        name="client"
                        type="text"
                        className={`sort-form-input form-control`}
                        value={values.client}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>
              <br />
              <Form.Group className="mb-3 row mt-1" controlId="formProjectData">
                <Col sm={4} md={3} lg={3}>
                  <Form.Label className="sort-form-section-lable">
                    Site &nbsp;
                    <InfoTooltip text={"Site"} id="projectAccount" />
                  </Form.Label>
                </Col>
                <Col lg={4} className="sort-form-field">
                  <div className="sort-form-wrapper">
                    <div className="form-group sort-form-group">
                      <input
                        name="site"
                        type="text"
                        className={`sort-form-input form-control`}
                        value={values.site}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>
              <br />
              <Form.Group className="mb-3 row mt-1" controlId="formProjectData">
                <Col sm={4} md={3} lg={3}>
                  <Form.Label className="sort-form-section-lable">
                    Product Category &nbsp;
                    <InfoTooltip
                      text={"Product Category"}
                      id="projectAccount"
                    />
                  </Form.Label>
                </Col>
                <Col lg={4} className="sort-form-field">
                  <div className="sort-form-wrapper">
                    <div className="form-group sort-form-group">
                      <input
                        name="productCategory"
                        type="text"
                        className={`sort-form-input form-control`}
                        value={values.productCategory}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>
              <br />
              <DocumentUpload
                setUploadedDocObj={setUploadedDocObj}
                uploadedDocObj={uploadedDocObj}
                setIsFormUpdatedUpload={setIsFormUpdatedUpload}
                setDisableSaveUpload={setDisableSaveUpload}
                permissions={permissions}
              />
              <UploadingModal
                isShowUploadingModal={isShowUploadingModal}
                permissions={permissions}
              />
              <ReuploadModal
                showConfirmation={showConfirmation}
                handleCloseConfirmation={handleCloseConfirmation}
                faildDocuments={faildDocuments}
                handleReuploadNow={handleReuploadNow}
                handleCloseReuploadModal={handleCloseReuploadModal}
              />
              <CancelConfirmation
                isShowCancelConfirmation={isShowCancelConfirmation}
                handleCancelConfirmation={handleCancelConfirmation}
                handleRestForm={handleRestForm}
              />
              <DiscardChangesPopup
                isShowDisacrdConfirmation={isShowDisacrdConfirmation}
                handleDiscardConfirmation={handleDiscardConfirmation}
                backToViewPage={backToViewPage}
              />
              {permissions.includes("PERMISSIONS.PROJECT.EDIT") ? (
                <div className="mt-4 text-center" style={{ marginBottom: 200 }}>
                  <Button
                    className={`sort-primary-btn btn-min-width`}
                    type="button"
                    style={{ margin: "0 10px" }}
                    onClick={submitForm}
                    disabled={
                      disableSave || (disableSaveUnchange && disableSaveUpload)
                    }
                  >
                    Save
                  </Button>
                  <Button
                    className="sort-primary-outline-btn btn-min-width"
                    type="button"
                    disabled={disableSave}
                    onClick={() => cancelForm()}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div className="mt-4 text-center" style={{ marginBottom: 200 }}>
                  <Button
                    className={`sort-primary-btn btn-min-width`}
                    type="button"
                    style={{ margin: "0 10px" }}
                    onClick={submitForm}
                    disabled
                  >
                    Save
                  </Button>
                  <Button
                    className="sort-primary-outline-btn btn-min-width"
                    type="button"
                    disabled={disableSave}
                    onClick={() => cancelForm()}
                  >
                    Cancel
                  </Button>
                  <Row className="permission-denied-page m-0">
                    <Card className="no-permission-card">
                      You don't have permission to perform this action. Please
                      contact the Admin.
                    </Card>
                  </Row>
                </div>
              )}
            </Form>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

UpdateProject.propTypes = {
  getProjectDetails: PropTypes.func,
  projectDetails: PropTypes.any,
  error: PropTypes.any,
  updateProject: PropTypes.func,
  isSuccessProjectUpdate: PropTypes.any,
  isFailedProjectUpdate: PropTypes.any,
  isFailUploadFilesProject: PropTypes.any,
  uploadFilesProjectError: PropTypes.func,
  isSuccessUploadFilesProject: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getProjectDetails: state.ProjectAnalyst.getProjectDetails,
    projectDetails: state.ProjectAnalyst.projectDetails,
    error: state.ProjectAnalyst.error,
    updateProject: state.ProjectAnalyst.updateProject,
    isSuccessProjectUpdate: state.ProjectAnalyst.isSuccessProjectUpdate,
    isFailedProjectUpdate: state.ProjectAnalyst.isFailedProjectUpdate,
    isFailUploadFilesProject: state.ProjectAnalyst.isFailUploadFilesProject,
    isSuccessUploadFilesProject:
      state.ProjectAnalyst.isSuccessUploadFilesProject,
    uploadFilesProjectError: state.ProjectAnalyst.uploadFilesProjectError,
  };
};

export default connect(mapStateToProps, {
  getProjectDetails,
  updateProject,
  uploadFilesProjectError,
})(UpdateProject);
