import {
  GET_CLIENT_REPORT_DETAILS,
  GET_CLIENT_REPORT_DETAILS_SUCCESS,
  GET_CLIENT_REPORT_DETAILS_ERROR,
  GET_CLIENT_REPORT_LIST,
  GET_CLIENT_REPORT_LIST_SUCCESS,
  GET_CLIENT_REPORT_LIST_ERROR,
  SELECTED_REPORT,
  GET_FINANCING_OPTIONS,
  GET_FINANCING_OPTIONS_SUCCESS,
  GET_FINANCING_OPTIONS_ERROR,
  GET_REPAYMENT_DATA,
  GET_REPAYMENT_DATA_SUCCESS,
  GET_REPAYMENT_DATA_ERROR
} from "./actionTypes";

export const getClientReportDetails = (parm) => {
    return {
      type: GET_CLIENT_REPORT_DETAILS,
      payload: {parm},
    };
  };
  export const getClientReportDetailsSuccess = (data) => {
    return {
      type: GET_CLIENT_REPORT_DETAILS_SUCCESS,
      payload: { data },
    };
  };
  export const getClientReportDetailsFaild = (error) => {
    return {
      type: GET_CLIENT_REPORT_DETAILS_ERROR,
      payload: { error },
    };
  };
  export const getReportList = (parm) => {
    return {
      type: GET_CLIENT_REPORT_LIST,
      payload: {parm},
    };
  };
  export const getReportListSuccess = (data) => {
    return {
      type: GET_CLIENT_REPORT_LIST_SUCCESS,
      payload: { data },
    };
  };
  export const getReportListFaild = (error) => {
    return {
      type: GET_CLIENT_REPORT_LIST_ERROR,
      payload: { error },
    };
  };
  export const selectedReport = (data) => {
    return {
      type: SELECTED_REPORT,
      payload: { data },
    };
  };
    export const getFinancingOptions = (parm) => {
    return {
      type: GET_FINANCING_OPTIONS,
      payload: {parm},
    };
  };
  export const getFinancingOptionsSuccess = (data) => {
    return {
      type: GET_FINANCING_OPTIONS_SUCCESS,
      payload: { data },
    };
  };
  export const getFinancingOptionsFaild = (error) => {
    return {
      type: GET_FINANCING_OPTIONS_ERROR,
      payload: { error },
    };
  };
  export const getRepaymentData = () => {
    return {
      type: GET_REPAYMENT_DATA,
      payload: {},
    };
  };
  export const getRepaymentDataSuccess = (data) => {
    return {
      type: GET_REPAYMENT_DATA_SUCCESS,
      payload: { data },
    };
  };
  export const getRepaymentDataFaild = (error) => {
    return {
      type: GET_REPAYMENT_DATA_ERROR,
      payload: { error },
    };
  };