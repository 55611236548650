import React from "react";
import { Helmet } from 'react-helmet-async';
import { Row, Tabs, Tab } from "react-bootstrap";
import ClientDashboardItems from "./clientDashboardItems";
import UniversalSettings from "./universalSettings";
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from "react-router-dom";

const DashboardSettings = () => {
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  let navigate = useNavigate();

  const handleOnIdle = event => {
    navigate('/');
    localStorage.clear();
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500
  })
  
  return (
    <React.Fragment>
      {" "}
      <div className="page-content">
        <Helmet>
          <title>{"SORT - Dashboard Settings"}</title>
        </Helmet>
        <Row className="dashboard-settings-tab">
          <Tabs
            defaultActiveKey="universal"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="universal" title="Universal Settings">
              <UniversalSettings/>
            </Tab>
            <Tab
              eventKey="clientDashboard"
              title="Default Values for Client Specific Dashboards Settings"
            >
              <ClientDashboardItems/>
            </Tab>
          </Tabs>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DashboardSettings;
