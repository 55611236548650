export const GET_EPC_SCORE = "GET_EPC_SCORE";
export const GET_EPC_SCORE_SUCCESS = "GET_EPC_SCORE_SUCCESS";
export const GET_EPC_SCORE_ERROR = "GET_EPC_SCORE_ERROR";
export const GET_REPORTS_PER_SITE = "GET_REPORTS_PER_SITE";
export const GET_REPORTS_PER_SITE_SUCCESS = "GET_REPORTS_PER_SITE_SUCCESS";
export const GET_REPORTS_PER_SITE_ERROR = "GET_REPORTS_PER_SITE_ERROR";
export const GET_OPPORTUNITIES_PER_REPORT = "GET_OPPORTUNITIES_PER_REPORT";
export const GET_OPPORTUNITIES_PER_REPORT_SUCCESS =
  "GET_OPPORTUNITIES_PER_REPORT_SUCCESS";
export const GET_OPPORTUNITIES_PER_REPORT_ERROR =
  "GET_OPPORTUNITIES_PER_REPORT_ERROR";
export const RESET_EPC = "RESET_EPC";
