import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_PROJECT_LIST,
  GET_PROJECT_DETAILS,
  UPDATE_PROJECT,
  DELETE_ATTACHMENT_PROJECT,
} from "./actionTypes";

import {
  getProjectListSuccess,
  getProjectListFaild,
  getProjectDetailsSuccess,
  getProjectDetailsFaild,
  updateProjectSuccess,
  updateProjectFaild,
  deleteAttachmentProjectSuccess,
  deleteAttachmentProjectFaild,
} from "./actions";

import {
  getProjectList,
  getProjectDetails,
  updateProject,
  projectDeleteAttachment,
} from "../../../helpers/api";

function* getProjectListFunc({ payload: { parm } }) {
  try {
    const response = yield call(getProjectList, parm);
    if (response.statusCode === 200 && response.message === "POST Request successful.") {
      yield put(getProjectListSuccess(response.data));
    } else {
      yield put(
        getProjectListFaild(
          response.message
        )
      );
    }
  } catch (error) {
    yield put(getProjectListFaild(error));
  }
}

function* getProjectAccountDetailsFunc({ payload: { id } }) {
  try {
    const response = yield call(getProjectDetails, id);
    if (response.statusCode === 200) {
      yield put(getProjectDetailsSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        getProjectDetailsFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        getProjectDetailsFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      getProjectDetailsFaild(error)
    );
  }
}

function* updateProjectFunc({ payload: { data } }) {
  try {
    const response = yield call(updateProject, data);
    if (response.statusCode === 200 && response.message === "PUT Request successful.") {
      yield put(updateProjectSuccess(response.data));
    } else {
      yield put(
        updateProjectFaild(response.message)
      );
    }
  } catch (error) {
    yield put(
      updateProjectFaild(error)
    );
  }
}

function* removeAttachmentFunc({ payload: { id, attachmentId } }) {
  try {
    const response = yield call(projectDeleteAttachment, id, attachmentId);
    if (response.statusCode === 200 && response.message === "Attachment deleted successfully") {
      yield put(deleteAttachmentProjectSuccess(response.data));
    } else {
      yield put(
        deleteAttachmentProjectFaild(
          response.message
        )
      );
    }
  } catch (error) {
    yield put(
      deleteAttachmentProjectFaild(
        error
      )
    );
  }
}

export function* watchProjectAnalyst() {
  yield takeEvery(GET_PROJECT_LIST, getProjectListFunc);
  yield takeEvery(GET_PROJECT_DETAILS, getProjectAccountDetailsFunc);
  yield takeEvery(UPDATE_PROJECT, updateProjectFunc);
  yield takeEvery(DELETE_ATTACHMENT_PROJECT, removeAttachmentFunc);
}

function* ProjectAnalystSaga() {
  yield all([fork(watchProjectAnalyst)]);
}

export default ProjectAnalystSaga;
