import React from "react";
import Dropzone from "react-dropzone";
import DocumentIcon from "../../assets/images/icons/DocumentIcon.svg"

const DropzoneEditor = (props) => {
  const { onDrop, maxSize, multipleFeature } = props;
  return (
    <Dropzone onDrop={onDrop} multiple={multipleFeature} maxSize={maxSize}>
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        rejectedFiles,
      }) => (
        <section>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div>
              <img src={DocumentIcon} width={40} height={40} color="#3E66FB" alt="doc" />
              <p className="image-text">{props.caption}</p>
              <p className="click-browse-btn">{props.subCaption}</p>
            </div>
          </div>
          <aside className="selected-file-wrapper"></aside>
        </section>
      )}
    </Dropzone>
  );
};

export default DropzoneEditor;
