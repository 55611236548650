import {
  GET_AD_DETAILS,
  GET_AD_DETAILS_SUCCESS,
  GET_AD_DETAILS_ERROR,
  CREATE_STAFF_USER,
  CREATE_STAFF_USER_SUCCESS,
  CREATE_STAFF_USER_ERROR,
  VIEW_STAFF_USER_ACCOUNTS,
  VIEW_STAFF_USER_ACCOUNTS_ERROR,
  VIEW_STAFF_USER_ACCOUNTS_SUCCESS,
  GET_CURRENT_UPDATE_ACCOUNT,
  UPDATE_STAFF_USER_ACCOUNT,
  UPDATE_STAFF_USER_ACCOUNT_SUCCESS,
  UPDATE_STAFF_USER_ACCOUNT_ERROR,
  RESET_TO_DEFUALT_STATE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  accountDetails: null,
  accountError: null,
  createAccountSuccess: false,
  accountSearchSuccess: false,
  currentAccount: null,
  updateAccount: false,
  accountSuccessMsg: null,
  accountSearchError: null,
  accountList: null,
  isSuccessUserDelete: false,
  isFaildUserDelete: false
};

const Account = (state = initialState, action) => {
  switch (action.type) {
    case GET_AD_DETAILS:
      state = {
        ...state,
        loading: true,
        accountDetails: null,
        accountError: null,
        accountSearchSuccess: false,
        createAccountSuccess: false,
      };
      break;
    case GET_AD_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountDetails: action.payload,
        accountSearchSuccess: true,
        accountError: null,
        createAccountSuccess: false,
      };
      break;
    case GET_AD_DETAILS_ERROR:
      state = {
        ...state,
        accountError: action.payload,
        loading: false,
        accountSearchSuccess: false,
        createAccountSuccess: false,
      };
      break;
    case CREATE_STAFF_USER:
      state = {
        ...state,
        createAccountSuccess: false,
        accountError: null,
        updateAccount: false,
        accountSuccessMsg: null,
      };
      break;
    case CREATE_STAFF_USER_SUCCESS:
      state = {
        ...state,
        createAccountSuccess: true,
        accountError: null,
        updateAccount: false,
        accountSuccessMsg: action.payload,
      };
      break;
    case CREATE_STAFF_USER_ERROR:
      state = {
        ...state,
        accountError: action.payload,
        createAccountSuccess: false,
        updateAccount: false,
        accountSuccessMsg: null,
      };
      break;
    case VIEW_STAFF_USER_ACCOUNTS:
      state = {
        ...state,
        loading: true,
        accountList: null,
        accountSearchError: null,
        updateAccount: false,
      };
      break;
    case VIEW_STAFF_USER_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountList: action.payload,
        updateAccount: false,
        accountSearchError: null,
      };
      break;
    case VIEW_STAFF_USER_ACCOUNTS_ERROR:
      state = {
        ...state,
        accountSearchError: action.payload,
        loading: false,
        updateAccount: false,
      };
      break;
    case GET_CURRENT_UPDATE_ACCOUNT:
      state = {
        ...state,
        loading: false,
        currentAccount: action.payload,
        updateAccount: false,
      };
      break;
    case UPDATE_STAFF_USER_ACCOUNT:
      state = {
        ...state,
        updateAccount: false,
        loading: false,
        accountError: null,
      };
      break;
    case UPDATE_STAFF_USER_ACCOUNT_SUCCESS:
      state = {
        ...state,
        updateAccount: true,
        loading: false,
        currentAccount: action.payload,
      };
      break;
    case UPDATE_STAFF_USER_ACCOUNT_ERROR:
      state = {
        ...state,
        currentAccount: action.payload,
        loading: false,
        updateAccount: false,
      };
      break;
    case RESET_TO_DEFUALT_STATE:
      state = {
        ...state,
        createAccountSuccess: false,
      };
      break;
      case DELETE_USER:
        state = {
          ...state,
          loading: true,
          isSuccessUserDelete: false,
          isFaildUserDelete: false
        };
        break;
      case DELETE_USER_SUCCESS:
        state = {
          ...state,
          loading: false,
          isSuccessUserDelete: true,
          isFaildUserDelete: false
        };
        break;
      case DELETE_USER_FAILED:
        state = {
          ...state,
          loading: false,
          isSuccessUserDelete: false,
          isFaildUserDelete: true
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Account;
