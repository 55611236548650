import {
  START_AUTHENTICATION,
  START_AUTHENTICATION_SUCCESS,
  START_AUTHENTICATION_ERROR,
} from "./actionTypes"

export const startAuthentication = (userData) => {
  return {
    type: START_AUTHENTICATION,
    payload: { userData },
  }
}

export const AuthenticationSuccess = (data) => {
  return {
    type: START_AUTHENTICATION_SUCCESS,
    payload: { data },
  }
}

export const AuthenticationError = (errormsg) => {
  return {
    type: START_AUTHENTICATION_ERROR,
    payload: { errormsg },
  }
}




