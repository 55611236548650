import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { PROJECT_ACTIVATE, VIEW_CLIENT_PROJECT } from "./actionTypes";

import {
  projectActivateSuccess,
  projectActivateFaild,
  viewProjectClientSuccess,
  viewProjectClientFaild,
} from "./actions";

import { projectActivate, getClientReportProjects } from "../../../helpers/api";

function* clientProjectActivate({ payload: { parm } }) {
  try {
    const response = yield call(projectActivate, parm);
    if (response.statusCode === 200) {
      yield put(projectActivateSuccess(response.data));
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(
        projectActivateFaild(
          response.responseException.exceptionMessage.fieldErrors.Name
        )
      );
    } else {
      yield put(
        projectActivateFaild(response.responseException.exceptionMessage)
      );
    }
  } catch (error) {
    yield put(
      projectActivateFaild(error)
    );
  }
}

function* getClientProjectListFunc({ payload: { parm } }) {
  try {
    const response = yield call(getClientReportProjects, parm);
    if (response.statusCode === 200 && response.message === "POST Request successful.") {
      yield put(viewProjectClientSuccess(response.data));
    } else {
      yield put(
        viewProjectClientFaild(
          response.message
        )
      );
    }
  } catch (error) {
    yield put(viewProjectClientFaild(error));
  }
}

export function* watchClientReport() {
  yield takeEvery(PROJECT_ACTIVATE, clientProjectActivate);
  yield takeEvery(VIEW_CLIENT_PROJECT, getClientProjectListFunc);
}

function* ClientReportSaga() {
  yield all([fork(watchClientReport)]);
}

export default ClientReportSaga;
