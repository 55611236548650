import React, { useEffect, useState } from "react";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Edit from "../../../../../assets/images/icons/Edit.svg";
import {
  getFinanceSettings,
  removeRepaymentSection,
} from "../../../../../store/Settings/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import CreateRepayment from "../Create";
import UpdateRepayment from "../Update";
import { XCircle } from "react-feather";
import RemoveFileConfirmation from "../../subComponents/removeFileModal";
import * as helper from "../../helpers/helper";
import CustomReactTable from "../../../../../components/common/reactTable";

const ViewRepayment = (props) => {
  const {
    showAlertRepay,
    setShowAlertRepay,
    errorRepay,
    setErrorRepay,
    repaySuccess,
    setRepaySuccess,
    repayError,
    setRepayError,
    updateRepaySuccess,
    setUpdateRepaySuccess,
    updateRepayError,
    setUpdateRepayError,
    setShowDeleteRepaymentSectionSuccess,
    setShowDeleteRepaymentSectionFailed,
    setDeleteAlertMessageRepayment,
  } = props;

  const [selectedRowRepay, setSelectedRowRepay] = useState();
  const [showEditRepay, setShowEditRepay] = useState(false);
  const [showCreateRepay, setShowCreateRepay] = useState(false);
  const [repaymentDetails, setRepaymentDetails] = useState([]);
  const [selectedRepaymentSection, setSelectedRepaymentSection] = useState();
  const [showRemoveRepaymentSection, setShowRemoveRepaymentSection] =
    useState(false);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    if (repaySuccess) {
      props.getFinanceSettings();
    }
  }, [repaySuccess]);

  useEffect(() => {
    if (updateRepaySuccess) {
      props.getFinanceSettings();
    }
  }, [updateRepaySuccess]);

  useEffect(() => {
    if (props.financeSettings) {
      setRepaymentDetails(
        props.financeSettings.data &&
          props.financeSettings.data.viewFinancingOptionRepaymentTerm
      );
    }
  }, [props.financeSettings]);

  const CaptionElementRepayment = () => (
    <p className="table-caption finance-table-header m-0">
      Repayment Terms & Rates
    </p>
  );

  const handleShowEditRepay = (index) => {
    setSelectedRowRepay(index);
    setShowEditRepay(true);
  };

  const handleOpenRepayment = () => {
    setShowCreateRepay(true);
  };

  const openRemoveRepaymentSection = (id) => {
    setShowRemoveRepaymentSection(true);
    setSelectedRepaymentSection(id);
  };

  const handleCloseRemoveRepaymentSection = () => {
    setShowRemoveRepaymentSection(false);
  };

  const removeRepaymentSection = () => {
    props.removeRepaymentSection(selectedRepaymentSection);
    setDeleteAlertMessageRepayment(true);
  };

  useEffect(() => {
    if (props.isSuccessRepaymentSectionDelete) {
      props.getFinanceSettings();
      setShowRemoveRepaymentSection(false);
      setShowDeleteRepaymentSectionSuccess(true);
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setShowDeleteRepaymentSectionSuccess(false);
      }, 5000);
    }
  }, [props.isSuccessRepaymentSectionDelete]);

  useEffect(() => {
    if (props.isFaildRepaymentSectionDelete) {
      setShowRemoveRepaymentSection(false);
      setShowDeleteRepaymentSectionFailed(true);
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setShowDeleteRepaymentSectionFailed(false);
      }, 5000);
    }
  }, [props.isFaildRepaymentSectionDelete]);

  window.setTimeout(() => {
    setDeleteAlertMessageRepayment(false);
  }, 5000);

  const editColumn = (row) => {
    return (
      <img
        className="edit-img"
        src={Edit}
        alt=""
        width={40}
        height={40}
        onClick={() => handleShowEditRepay(row.id)}
      />
    );
  };

  const rateColumn = (row) => {
    return row.rate.toFixed(helper.DECIMAL_TWO);
  };

  const deleteColumn = (row) => {
    return (
      <XCircle
        size={25}
        color="#77BF4B"
        onClick={() => openRemoveRepaymentSection(row.id)}
      />
    );
  };

  const columns = [
    {
      header: "Term",
      accessorKey: "term",
      sortingFn: "alphanumeric",
    },
    {
      header: "Standard Rate/£1000.00",
      accessorKey: "rate",
      cell: (info) => rateColumn(info.row.original),
    },
    {
      header: "",
      accessorKey: "edit",
      cell: (info) => editColumn(info.row.original),
    },
    {
      header: "",
      accessorKey: "delete",
      cell: (info) => deleteColumn(info.row.original),
    },
  ];

  const table = useReactTable({
    data: repaymentDetails,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <React.Fragment>
      {" "}
      <Row className="mt-4">
        <Col lg={12}>
          <div
            className="table-wrapper-client-account"
            style={{ marginLeft: 10 }}
          >
            <CaptionElementRepayment />
            <CustomReactTable
              table={table}
              tableClass={"repayment-settings-table"}
            />
            <Row style={{ margin: 0 }}>
              <Col lg={8}></Col>
              <Col lg={4} style={{ textAlign: "right" }}>
                <Button
                  className="repayment-primary-btn"
                  type={"button"}
                  onClick={() => handleOpenRepayment()}
                >
                  Create Repayment Term
                </Button>
              </Col>
            </Row>
            <CreateRepayment
              showCreateRepay={showCreateRepay}
              setShowCreateRepay={setShowCreateRepay}
              repaymentDetails={repaymentDetails}
              repaySuccess={repaySuccess}
              setRepaySuccess={setRepaySuccess}
              showAlertRepay={showAlertRepay}
              setShowAlertRepay={setShowAlertRepay}
              errorRepay={errorRepay}
              setErrorRepay={setErrorRepay}
              repayError={repayError}
              setRepayError={setRepayError}
            />
            <UpdateRepayment
              showEditRepay={showEditRepay}
              setShowEditRepay={setShowEditRepay}
              repaymentDetails={repaymentDetails}
              selectedRowRepay={selectedRowRepay}
              setSelectedRowRepay={setSelectedRowRepay}
              updateRepaySuccess={updateRepaySuccess}
              setUpdateRepaySuccess={setUpdateRepaySuccess}
              showAlertRepay={showAlertRepay}
              setShowAlertRepay={setShowAlertRepay}
              errorRepay={errorRepay}
              setErrorRepay={setErrorRepay}
              updateRepayError={updateRepayError}
              setUpdateRepayError={setUpdateRepayError}
            />
          </div>
        </Col>
      </Row>
      <RemoveFileConfirmation
        showRemoveSection={showRemoveRepaymentSection}
        handleCloseRemoveSection={handleCloseRemoveRepaymentSection}
        removeSection={removeRepaymentSection}
        modalText="Are you sure you want to delete the repayment?"
      />
    </React.Fragment>
  );
};

ViewRepayment.propTypes = {
  getFinanceSettings: PropTypes.func,
  financeSettings: PropTypes.any,
  removeRepaymentSection: PropTypes.func,
  isSuccessRepaymentSectionDelete: PropTypes.any,
  isFaildRepaymentSectionDelete: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getFinanceSettings: state.Settings.getFinanceSettings,
    financeSettings: state.Settings.financeSettings,
    removeRepaymentSection: state.Settings.removeRepaymentSection,
    isSuccessRepaymentSectionDelete:
      state.Settings.isSuccessRepaymentSectionDelete,
    isFaildRepaymentSectionDelete: state.Settings.isFaildRepaymentSectionDelete,
  };
};

export default connect(mapStateToProps, {
  getFinanceSettings,
  removeRepaymentSection,
})(ViewRepayment);
