export const POST_AUDIT_ACCOUNT_CREATE = "POST_AUDIT_ACCOUNT_CREATE"
export const POST_AUDIT_ACCOUNT_CREATE_SUCCESS = "POST_AUDIT_ACCOUNT_CREATE_SUCCESS"
export const POST_AUDIT_ACCOUNT_CREATE_ERROR = "POST_AUDIT_ACCOUNT_CREATE_ERROR"
export const UPDATE_AUDIT_ACCOUNT = "UPDATE_AUDIT_ACCOUNT"
export const UPDATE_AUDIT_ACCOUNT_SUCCESS = "UPDATE_AUDIT_ACCOUNT_SUCCESS"
export const UPDATE_AUDIT_ACCOUNT_ERROR = "UPDATE_AUDIT_ACCOUNT_ERROR"
export const GET_AUDIT_ACCOUNT_DETAILS = "GET_AUDIT_ACCOUNT_DETAILS"
export const GET_AUDIT_ACCOUNT_DETAILS_SUCCESS = "GET_AUDIT_ACCOUNT_DETAILS_SUCCESS"
export const GET_AUDIT_ACCOUNT_DETAILS_ERROR = "GET_AUDIT_ACCOUNT_DETAILS_ERROR"
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_ERROR = "GET_USERS_ERROR"
export const GET_AUDIT_LIST = "GET_AUDIT_LIST"
export const GET_AUDIT_LIST_SUCCESS = "GET_AUDIT_LIST_SUCCESS"
export const GET_AUDIT_LIST_ERROR = "GET_AUDIT_LIST_ERROR"
export const GET_PRODUCT_MAIN_CATEGORIES = "GET_PRODUCT_MAIN_CATEGORIES"
export const GET_PRODUCT_MAIN_CATEGORIES_SUCCESS = "GET_PRODUCT_MAIN_CATEGORIES_SUCCESS"
export const GET_PRODUCT_MAIN_CATEGORIES_ERROR = "GET_PRODUCT_MAIN_CATEGORIES_ERROR"
export const CREATE_AUDIT_SECTION = "CREATE_AUDIT_SECTION"
export const CREATE_AUDIT_SECTION_SUCCESS = "CREATE_AUDIT_SECTION_SUCCESS"
export const CREATE_AUDIT_SECTION_ERROR = "CREATE_AUDIT_SECTION_ERROR"
export const VIEW_AUDIT_SECTION = "VIEW_AUDIT_SECTION_SECTION"
export const VIEW_AUDIT_SECTION_SUCCESS = "VIEW_AUDIT_SECTION_SUCCESS"
export const VIEW_AUDIT_SECTION_ERROR = "VIEW_AUDIT_SECTION_ERROR"
export const REMOVE_AUDIT_FINDINGS = "REMOVE_AUDIT_FINDINGS"
export const REMOVE_AUDIT_FINDINGS_SUCCESS = "REMOVE_AUDIT_FINDINGS_SUCCESS"
export const REMOVE_AUDIT_FINDINGS_ERROR = "REMOVE_AUDIT_FINDINGS_ERROR"
export const REMOVE_AUDIT_SECTION = "REMOVE_AUDIT_SECTION"
export const REMOVE_AUDIT_SECTION_SUCCESS = "REMOVE_AUDIT_SECTION_SUCCESS"
export const REMOVE_AUDIT_SECTION_ERROR = "REMOVE_AUDIT_SECTION_ERROR"
export const UPDATE_AUDIT_SECTION = "UPDATE_AUDIT_SECTION"
export const UPDATE_AUDIT_SECTION_SUCCESS = "UPDATE_AUDIT_SECTION_SUCCESS"
export const UPDATE_AUDIT_SECTION_ERROR = "UPDATE_AUDIT_SECTION_ERROR"
export const VIEW_AUDIT_FINDING_DATA_SET = "VIEW_AUDIT_FINDING_DATA_SET"
export const VIEW_AUDIT_FINDING_DATA_SET_SUCCESS = "VIEW_AUDIT_FINDING_DATA_SET_SUCCESS"
export const VIEW_AUDIT_FINDING_DATA_SET_ERROR = "VIEW_AUDIT_FINDING_DATA_SET_ERROR"
export const CREATE_AUDIT_FINDINGS = "CREATE_AUDIT_FINDINGS_SET"
export const CREATE_AUDIT_FINDINGS_SUCCESS = "CREATE_AUDIT_FINDINGS_SUCCESS"
export const CREATE_AUDIT_FINDINGS_ERROR = "CREATE_AUDIT_FINDINGS_ERROR"
export const UPLOAD_FILES = "UPLOAD_FILES"
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS"
export const UPLOAD_FILES_ERROR = "UPLOAD_FILES_ERROR"
export const SET_TO_DEFAULT = "SET_TO_DEFAULT"
export const UPDATE_AUDIT_FINDINGS = "UPDATE_AUDIT_FINDINGS"
export const UPDATE_AUDIT_FINDINGS_SUCCESS = "UPDATE_AUDIT_FINDINGS_SUCCESS"
export const UPDATE_AUDIT_FINDINGS_ERROR = "UPDATE_AUDIT_FINDINGS_ERROR"
export const REMOVE_MEDIA_SECTION = "REMOVE_MEDIA_SECTION"
export const REMOVE_MEDIA_SECTION_SUCCESS = "REMOVE_MEDIA_SECTION_SUCCESS"
export const REMOVE_MEDIA_SECTION_ERROR = "REMOVE_MEDIA_SECTION_ERROR"
export const DOWNLOAD_MEDIA = "DOWNLOAD_MEDIA"
export const DOWNLOAD_MEDIA_SUCCESS = "DOWNLOAD_MEDIA_SUCCESS"
export const DOWNLOAD_MEDIA_ERROR = "DOWNLOAD_MEDIA_ERROR"
export const GET_AUDIT_FINDINGS_DETAILS = "GET_AUDIT_FINDINGS_DETAILS"
export const GET_AUDIT_FINDINGS_DETAILS_SUCCESS = "GET_AUDIT_FINDINGS_DETAILS_SUCCESS"
export const GET_AUDIT_FINDINGS_DETAILS_ERROR = "GET_AUDIT_FINDINGS_DETAILS_ERROR"
export const RESET_TO_DEFAULT_STATE = "RESET_TO_DEFAULT_STATE"
export const POST_AUDIT_OPPORTUNITY = "POST_AUDIT_OPPORTUNITY"
export const POST_AUDIT_OPPORTUNITY_SUCCESS = "POST_AUDIT_OPPORTUNITY_SUCCESS"
export const POST_AUDIT_OPPORTUNITY_ERROR = "POST_AUDIT_OPPORTUNITY_ERROR"


