import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Card } from "react-bootstrap";
import BulkUpdate from "./bulkUpdate";
import { AlertTriangle } from "react-feather";

const UpdateFileModal = (props) => {
  const {
    updateConfirmation,
    cancelUpdate,
    updateSubmit,
    id,
    costPerKiloWattHour,
    costPerKiloWattHourGas,
    costPerCubicMeter,
    currentPage,
    setCurrentPage,
    currentList,
    setCurrentList,
    costPerKW,
    costPerKWGas,
    costPerKWOld,
    costPerKWGasOld,
    costPerM,
    costPerMOld,
  } = props;

  return (
    <React.Fragment>
      <Modal
        show={updateConfirmation}
        centered
        className="confirmation-modal update-bulk-modal"
      >
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12} className="text-center">
              <Modal.Title>Update Opportunities</Modal.Title>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {costPerKW !== costPerKWOld && costPerM === costPerMOld && costPerKWGas===costPerKWGasOld ? (
              <p className="bulk-modal-body">
                {" "}
                There are existing opportunities associated with this Audit,
                which refers to the 'Cost per Kilo Watt Hour' for calculating
                the Savings per Annum (£).
              </p>
            ) : costPerKW === costPerKWOld && costPerM !== costPerMOld && costPerKWGas!==costPerKWGasOld ? (
              <p className="bulk-modal-body">
                {" "}
                There are existing opportunities associated with this Audit,
                which refers to the 'Cost per M&sup3;' for calculating the
                Savings per Annum (£).
              </p>
            ) : (
              <p className="bulk-modal-body">
                There are existing opportunities associated with this Audit,
                which refers to the 'Cost per Kilo Watt Hour' and 'Cost per
                M&sup3;' for calculating the Savings per Annum (£).
              </p>
            )}

            <p className="bulk-modal-body">
              Do you want to update the opportunities as well?
            </p>

            <BulkUpdate
              id={id}
              costPerKiloWattHour={costPerKiloWattHour}
              costPerKiloWattHourGas={costPerKiloWattHourGas}
              costPerCubicMeter={costPerCubicMeter}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentList={currentList}
              setCurrentList={setCurrentList}
            />
          </Row>
          <Row>
            <Card className="warning-message-cancel">
              <span>
                <AlertTriangle size={25} />
                &nbsp; Please note that cancelling will also cancel the update
                to the audit
              </span>
            </Card>
          </Row>

          <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                className="sort-primary-btn btn-min-width"
                style={{ margin: "0 10px" }}
                type={"button"}
                onClick={updateSubmit}
              >
                Yes
              </Button>
              <Button
                className="sort-primary-outline-btn btn-min-width"
                onClick={cancelUpdate}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateFileModal;
