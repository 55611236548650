export const GET_AUDIT_REPORT = "GET_AUDIT_REPORT"
export const GET_AUDIT_REPORT_SUCCESS = "GET_AUDIT_REPORT_SUCCESS"
export const GET_AUDIT_REPORT_ERROR = "GET_AUDIT_REPORT_ERROR"
export const GET_EXECUTIVE_SUMMARY = "GET_EXECUTIVE_SUMMARY"
export const GET_EXECUTIVE_SUMMARY_SUCCESS = "GET_EXECUTIVE_SUMMARY_SUCCESS"
export const GET_EXECUTIVE_SUMMARY_ERROR = "GET_EXECUTIVE_SUMMARY_ERROR"
export const POST_UNPUBLISH_REPORT = "POST_UNPUBLISH_REPORT"
export const POST_UNPUBLISH_REPORT_SUCCESS = "POST_UNPUBLISH_REPORT_SUCCESS"
export const POST_UNPUBLISH_REPORT_ERROR = "POST_UNPUBLISH_REPORT_ERROR"
export const POST_PREVIEW_REPORT = "POST_PREVIEW_REPORT"
export const POST_PREVIEW_REPORT_SUCCESS = "POST_PREVIEW_REPORT_SUCCESS"
export const POST_PREVIEW_REPORT_ERROR = "POST_PREVIEW_REPORT_ERROR"
export const GET_FINANCING_OPTIONS_PREVIEW = "GET_FINANCING_OPTIONS_PREVIEW"
export const GET_FINANCING_OPTIONS_PREVIEW_SUCCESS = "GET_FINANCING_OPTIONS_PREVIEW_SUCCESS"
export const GET_FINANCING_OPTIONS_PREVIEW_ERROR = "GET_FINANCING_OPTIONS_PREVIEW_ERROR"
export const GET_REPAYMENT_PREVIEW_DATA = "GET_REPAYMENT_PREVIEW_DATA"
export const GET_REPAYMENT_PREVIEW_DATA_SUCCESS = "GET_REPAYMENT_PREVIEW_DATA_SUCCESS"
export const GET_REPAYMENT_PREVIEW_DATA_ERROR = "GET_REPAYMENT_PREVIEW_DATA_ERROR"

