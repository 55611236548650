import axios from "axios"


const API_URL = process.env.REACT_APP_API_ENDPOINT


const downloadExtrnal = (id, type, fname) => {
  axios({
    url: API_URL + '/clientreport' + `/${id}` + `/download/project`,
    method: 'GET',
    responseType: 'blob'
  })
    .then((response) => {
      let url = URL.createObjectURL(new Blob([response.data], { type: type }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        fname
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link)
    })
    .catch((error) => {
    });
}

export {
  downloadExtrnal
}