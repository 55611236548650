import {
  GET_GLOBAL_PARAMS,
  GET_GLOBAL_PARAMS_SUCCESS,
  GET_GLOBAL_PARAMS_ERROR,
  GET_DASHBOARD_SETTINGS,
  GET_DASHBOARD_SETTINGS_SUCCESS,
  GET_DASHBOARD_SETTINGS_ERROR,
  GET_FINANCE_SETTINGS,
  GET_FINANCE_SETTINGS_SUCCESS,
  GET_FINANCE_SETTINGS_ERROR,
  POST_FINANCE_CATEGORY_CREATE,
  POST_FINANCE_CATEGORY_CREATE_SUCCESS,
  POST_FINANCE_CATEGORY_CREATE_ERROR,
  UPDATE_FINANCE_CATEGORY,
  UPDATE_FINANCE_CATEGORY_SUCCESS,
  UPDATE_FINANCE_CATEGORY_ERROR,
  GET_FINANCE_CATEGORY_DETAILS,
  GET_FINANCE_CATEGORY_DETAILS_SUCCESS,
  GET_FINANCE_CATEGORY_DETAILS_ERROR,
  POST_FINANCE_REPAYMENT_CREATE,
  POST_FINANCE_REPAYMENT_CREATE_SUCCESS,
  POST_FINANCE_REPAYMENT_CREATE_ERROR,
  UPDATE_FINANCE_REPAYMENT,
  UPDATE_FINANCE_REPAYMENT_SUCCESS,
  UPDATE_FINANCE_REPAYMENT_ERROR,
  GET_FINANCE_REPAYMENT_DETAILS,
  GET_FINANCE_REPAYMENT_DETAILS_SUCCESS,
  GET_FINANCE_REPAYMENT_DETAILS_ERROR,
  POST_CARBON_ROADMAP_SETTINGS,
  POST_CARBON_ROADMAP_SETTINGS_SUCCESS,
  POST_CARBON_ROADMAP_SETTINGS_ERROR,
  GET_CARBON_ROADMAP_SETTINGS,
  GET_CARBON_ROADMAP_SETTINGS_SUCCESS,
  GET_CARBON_ROADMAP_SETTINGS_ERROR,
  POST_GLOBAL_OPPORTUNITY,
  POST_GLOBAL_OPPORTUNITY_SUCCESS,
  POST_GLOBAL_OPPORTUNITY_ERROR,
  REMOVE_REPAYMENT_SECTION,
  REMOVE_REPAYMENT_SECTION_SUCCESS,
  REMOVE_REPAYMENT_SECTION_ERROR,
  REMOVE_CATEGORY_SECTION,
  REMOVE_CATEGORY_SECTION_SUCCESS,
  REMOVE_CATEGORY_SECTION_ERROR,
} from "./actionTypes";

export const getGlobalSettings = () => {
  return {
    type: GET_GLOBAL_PARAMS,
    payload: {},
  };
};

export const getGlobalSettingsSuccess = (data) => {
  return {
    type: GET_GLOBAL_PARAMS_SUCCESS,
    payload: { data },
  };
};

export const getGlobalSettingsError = (error) => {
  return {
    type: GET_GLOBAL_PARAMS_ERROR,
    payload: { error },
  };
};

export const getDashboardSettings = () => {
  return {
    type: GET_DASHBOARD_SETTINGS,
    payload: {},
  };
};

export const getDashboardSettingsSuccess = (data) => {
  return {
    type: GET_DASHBOARD_SETTINGS_SUCCESS,
    payload: { data },
  };
};

export const getDashboardSettingsError = (error) => {
  return {
    type: GET_DASHBOARD_SETTINGS_ERROR,
    payload: { error },
  };
};

export const getFinanceSettings = () => {
  return {
    type: GET_FINANCE_SETTINGS,
    payload: {},
  };
};
export const getFinanceSettingsSuccess = (data) => {
  return {
    type: GET_FINANCE_SETTINGS_SUCCESS,
    payload: { data },
  };
};
export const getFinanceSettingsFaild = (error) => {
  return {
    type: GET_FINANCE_SETTINGS_ERROR,
    payload: { error },
  };
};

export const postFinanceCategory = (data) => {
  return {
    type: POST_FINANCE_CATEGORY_CREATE,
    payload: { data },
  };
};
export const postFinanceCategorySuccess = (data) => {
  return {
    type: POST_FINANCE_CATEGORY_CREATE_SUCCESS,
    payload: { data },
  };
};
export const postFinanceCategoryError = (error) => {
  return {
    type: POST_FINANCE_CATEGORY_CREATE_ERROR,
    payload: { error },
  };
};
export const updateFinanceCategory = (data) => {
  return {
    type: UPDATE_FINANCE_CATEGORY,
    payload: { data },
  };
};
export const updateFinanceCategorySuccess = (data) => {
  return {
    type: UPDATE_FINANCE_CATEGORY_SUCCESS,
    payload: { data },
  };
};
export const updateFinanceCategoryError = (error) => {
  return {
    type: UPDATE_FINANCE_CATEGORY_ERROR,
    payload: { error },
  };
};
export const getFinanceCategoryDetails = (id) => {
  return {
    type: GET_FINANCE_CATEGORY_DETAILS,
    payload: { id },
  };
};
export const getFinanceCategoryDetailsSuccess = (data) => {
  return {
    type: GET_FINANCE_CATEGORY_DETAILS_SUCCESS,
    payload: { data },
  };
};
export const getFinanceCategoryDetailsError = (error) => {
  return {
    type: GET_FINANCE_CATEGORY_DETAILS_ERROR,
    payload: { error },
  };
};

export const postFinanceRepayment = (data) => {
  return {
    type: POST_FINANCE_REPAYMENT_CREATE,
    payload: { data },
  };
};
export const postFinanceRepaymentSuccess = (data) => {
  return {
    type: POST_FINANCE_REPAYMENT_CREATE_SUCCESS,
    payload: { data },
  };
};
export const postFinanceRepaymentError = (error) => {
  return {
    type: POST_FINANCE_REPAYMENT_CREATE_ERROR,
    payload: { error },
  };
};
export const updateFinanceRepayment = (data) => {
  return {
    type: UPDATE_FINANCE_REPAYMENT,
    payload: { data },
  };
};
export const updateFinanceRepaymentSuccess = (data) => {
  return {
    type: UPDATE_FINANCE_REPAYMENT_SUCCESS,
    payload: { data },
  };
};
export const updateFinanceRepaymentError = (error) => {
  return {
    type: UPDATE_FINANCE_REPAYMENT_ERROR,
    payload: { error },
  };
};
export const getFinanceRepaymentDetails = (id) => {
  return {
    type: GET_FINANCE_REPAYMENT_DETAILS,
    payload: { id },
  };
};
export const getFinanceRepaymentDetailsSuccess = (data) => {
  return {
    type: GET_FINANCE_REPAYMENT_DETAILS_SUCCESS,
    payload: { data },
  };
};
export const getFinanceRepaymentDetailsError = (error) => {
  return {
    type: GET_FINANCE_REPAYMENT_DETAILS_ERROR,
    payload: { error },
  };
};
export const postCarbonRoadmapSettings = (data) => {
  return {
    type: POST_CARBON_ROADMAP_SETTINGS,
    payload: { data },
  };
};
export const postCarbonRoadmapSettingsSuccess = (data) => {
  return {
    type: POST_CARBON_ROADMAP_SETTINGS_SUCCESS,
    payload: { data },
  };
};
export const postCarbonRoadmapSettingsError = (error) => {
  return {
    type: POST_CARBON_ROADMAP_SETTINGS_ERROR,
    payload: { error },
  };
};
export const getCarbonRoadmapSettings = () => {
  return {
    type: GET_CARBON_ROADMAP_SETTINGS,
    payload: {},
  };
};

export const getCarbonRoadmapSettingsSuccess = (data) => {
  return {
    type: GET_CARBON_ROADMAP_SETTINGS_SUCCESS,
    payload: { data },
  };
};
export const getCarbonRoadmapSettingsError = (error) => {
  return {
    type: GET_CARBON_ROADMAP_SETTINGS_ERROR,
    payload: { error },
  };
};
export const postGlobalOpportunity = (data) => {
  return {
    type: POST_GLOBAL_OPPORTUNITY,
    payload: { data },
  };
};
export const postGlobalOpportunitySuccess = (data) => {
  return {
    type: POST_GLOBAL_OPPORTUNITY_SUCCESS,
    payload: { data },
  };
};
export const postGlobalOpportunityError = (error) => {
  return {
    type: POST_GLOBAL_OPPORTUNITY_ERROR,
    payload: { error },
  };
};
export const removeRepaymentSection = (id) => {
  return {
    type: REMOVE_REPAYMENT_SECTION,
    payload: { id },
  };
};
export const removeRepaymentSectionSuccess = (data) => {
  return {
    type: REMOVE_REPAYMENT_SECTION_SUCCESS,
    payload: { data },
  };
};
export const removeRepaymentSectionFailed = (error) => {
  return {
    type: REMOVE_REPAYMENT_SECTION_ERROR,
    payload: { error },
  };
};
export const removeCategorySection = (id) => {
  return {
    type: REMOVE_CATEGORY_SECTION,
    payload: { id },
  };
};
export const removeCategorySectionSuccess = (data) => {
  return {
    type: REMOVE_CATEGORY_SECTION_SUCCESS,
    payload: { data },
  };
};
export const removeCategorySectionFailed = (error) => {
  return {
    type: REMOVE_CATEGORY_SECTION_ERROR,
    payload: { error },
  };
};
