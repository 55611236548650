import {
  START_AUTHENTICATION,
  START_AUTHENTICATION_SUCCESS,
  START_AUTHENTICATION_ERROR,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  isLoginSuccess: false
}

const Auth = (state = initialState, action) => {
  switch (action.type) {
    case START_AUTHENTICATION:
      state = {
        ...state,
        loading: true,
        isLoginSuccess: false,
        error: null
      }
      break
    case START_AUTHENTICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        isLoginSuccess: true,
        error: null
      }
      break
    case START_AUTHENTICATION_ERROR:
      state = {
        ...state,
        loading: false,
        isLoginSuccess: false,
        error: action.payload
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Auth
