import React from "react";
import { Row, Col, Button, Modal, ListGroup } from "react-bootstrap";
import Doc from "../../../../assets/images/icons/Doc.svg";

const ReuploadModal = (props) => {
  const {
    showConfirmation,
    handleCloseReuploadModal,
    faildDocuments,
    handleReuploadNow,
  } = props;
  return (
    <React.Fragment>
      <Modal show={showConfirmation} centered className="confirmation-modal">
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12} className="text-center">
              <Modal.Title>Re-upload Files</Modal.Title>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="text-center reupload-body">
            <h4>Client business account updated successfully</h4>
            <p>
              Please re-upload the following carbon roadmap document(s) as they
              could not be uploaded due to an error:
            </p>
          </Row>

          <Row className="p-3">
            {faildDocuments.length > 0 && (
              <section className="custom-fieldset mt-4">
                <h1>Documents</h1>
                <ListGroup>
                  {faildDocuments.map((item, index) => (
                    <ListGroup.Item key={index} className="reupload-form-list">
                      <img
                        src={Doc}
                        className="upload-img"
                        width={30}
                        height={30}
                        alt="phto"
                      />
                      <div className="failed-file-name">
                        {item.title}
                        <span
                          className={
                            "inline-form-error display-error file-error"
                          }
                        >
                          {item.message}
                        </span>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </section>
            )}
          </Row>

          <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                className="sort-primary-btn btn-min-width"
                style={{ margin: "0 10px" }}
                type={"button"}
                onClick={() => handleReuploadNow()}
              >
                Ok
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default ReuploadModal;
