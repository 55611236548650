import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ViewTransaction from "./Transaction/View/index";
import { Row } from "react-bootstrap";
import { Alert } from "reactstrap";
import ViewRepayment from "./Repayment/View";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import { getFinanceSettings } from "../../../store/Settings/actions";
import { getDecodeAuthDetails } from "../../../helpers";
import { useIdleTimer } from "react-idle-timer";

const FinanceSettings = (props) => {
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  const handleOnIdle = (event) => {
    window.location.href = "/login";
    window.location.reload();
    localStorage.clear();  
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    props.getFinanceSettings();
  }, []);

  let decoded = getDecodeAuthDetails();

  const permissions = decoded.Permission;

  const [showAlert, setShowAlert] = useState(false);
  const [errorCategory, setError] = useState([]);
  const [categorySuccess, setCategorySuccess] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [updateCategorySuccess, setUpdateCategorySuccess] = useState(false);
  const [updateCategoryError, setUpdateCategoryError] = useState(false);

  const [showAlertRepay, setShowAlertRepay] = useState(false);
  const [errorRepay, setErrorRepay] = useState([]);
  const [repaySuccess, setRepaySuccess] = useState(false);
  const [repayError, setRepayError] = useState(false);
  const [updateRepaySuccess, setUpdateRepaySuccess] = useState(false);
  const [updateRepayError, setUpdateRepayError] = useState(false);
  const [deleteAlertMessageRepayment, setDeleteAlertMessageRepayment] =
    useState(false);
  const [deleteAlertMessageCategory, setDeleteAlertMessageCategory] =
    useState(false);

  const [
    showDeleteRepaymentSectionSuccess,
    setShowDeleteRepaymentSectionSuccess,
  ] = useState(false);

  const [
    showDeleteRepaymentSectionFailed,
    setShowDeleteRepaymentSectionFailed,
  ] = useState(false);

  const [
    showDeleteCategorySectionSuccess,
    setShowDeleteCategorySectionSuccess,
  ] = useState(false);

  const [showDeleteCategorySectionFailed, setShowDeleteCategorySectionFailed] =
    useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        {" "}
        <Helmet>
          <title>{"SORT - Finance Settings"}</title>
        </Helmet>
        {categorySuccess && showAlert && (
          <Row className="px-2">
            <Alert color="success" isOpen={categorySuccess && showAlert}>
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Transaction size adjustment category created successfully
            </Alert>
          </Row>
        )}
        {updateCategorySuccess && showAlert && (
          <Row className="px-2">
            <Alert color="success" isOpen={updateCategorySuccess && showAlert}>
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Transaction size adjustment category updated successfully
            </Alert>
          </Row>
        )}
        {repaySuccess && showAlertRepay && (
          <Row className="px-2">
            <Alert color="success" isOpen={repaySuccess && showAlertRepay}>
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Repayment term and rate created successfully
            </Alert>
          </Row>
        )}
        {updateRepaySuccess && showAlertRepay && (
          <Row className="px-2">
            <Alert
              color="success"
              isOpen={updateRepaySuccess && showAlertRepay}
            >
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Repayment term and rate updated successfully
            </Alert>
          </Row>
        )}
        {showDeleteRepaymentSectionSuccess && (
          <Row className="px-2">
            <Alert color="success" isOpen={showDeleteRepaymentSectionSuccess}>
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Repayment deleted successfully
            </Alert>
          </Row>
        )}
        {showDeleteRepaymentSectionFailed && deleteAlertMessageRepayment && (
          <Row className="px-2">
            <Alert
              color="danger"
              isOpen={
                showDeleteRepaymentSectionFailed && deleteAlertMessageRepayment
              }
            >
              <img src={ErrorValidation} alt="error" width={30} height={30} />
              Repayment term and rate could not be deleted
            </Alert>
          </Row>
        )}
        {showDeleteCategorySectionSuccess && (
          <Row className="px-2">
            <Alert color="success" isOpen={showDeleteCategorySectionSuccess}>
              <img
                src={SuccessfulValidation}
                alt="success"
                width={30}
                height={30}
              />
              Category deleted successfully
            </Alert>
          </Row>
        )}
        {showDeleteCategorySectionFailed && deleteAlertMessageCategory && (
          <Row className="px-2">
            <Alert
              color="danger"
              isOpen={
                showDeleteCategorySectionFailed && deleteAlertMessageCategory
              }
            >
              <img src={ErrorValidation} alt="error" width={30} height={30} />
              Category could not be deleted
            </Alert>
          </Row>
        )}
        {permissions.includes(
          "PERMISSIONS.FINANCINGOPTIONSETTING.CATEGORYVIEW"
        ) && (
          <Row>
            <ViewTransaction
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              errorCategory={errorCategory}
              setError={setError}
              categoryError={categoryError}
              setCategoryError={setCategoryError}
              categorySuccess={categorySuccess}
              setCategorySuccess={setCategorySuccess}
              updateCategorySuccess={updateCategorySuccess}
              setUpdateCategorySuccess={setUpdateCategorySuccess}
              updateCategoryError={updateCategoryError}
              setUpdateCategoryError={setUpdateCategoryError}
              setShowDeleteCategorySectionSuccess={
                setShowDeleteCategorySectionSuccess
              }
              setShowDeleteCategorySectionFailed={
                setShowDeleteCategorySectionFailed
              }
              setDeleteAlertMessageCategory={setDeleteAlertMessageCategory}
            />
          </Row>
        )}
        <br />
        {permissions.includes(
          "PERMISSIONS.FINANCINGOPTIONSETTING.REPAYMENTTERMVIEW"
        ) && (
          <Row className="mb-5">
            <ViewRepayment
              showAlertRepay={showAlertRepay}
              setShowAlertRepay={setShowAlertRepay}
              errorRepay={errorRepay}
              setErrorRepay={setErrorRepay}
              repaySuccess={repaySuccess}
              setRepaySuccess={setRepaySuccess}
              repayError={repayError}
              setRepayError={setRepayError}
              updateRepaySuccess={updateRepaySuccess}
              setUpdateRepaySuccess={setUpdateRepaySuccess}
              updateRepayError={updateRepayError}
              setUpdateRepayError={setUpdateRepayError}
              setShowDeleteRepaymentSectionSuccess={
                setShowDeleteRepaymentSectionSuccess
              }
              setShowDeleteRepaymentSectionFailed={
                setShowDeleteRepaymentSectionFailed
              }
              setDeleteAlertMessageRepayment={setDeleteAlertMessageRepayment}
            />
          </Row>
        )}
        <br />
      </div>
    </React.Fragment>
  );
};
FinanceSettings.propTypes = {
  getFinanceSettings: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getFinanceSettings: state.Settings.getFinanceSettings,
    financeSettings: state.Settings.financeSettings,
  };
};

export default connect(mapStateToProps, {
  getFinanceSettings,
})(FinanceSettings);
