import {
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_ERROR,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_ERROR,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  DELETE_ATTACHMENT_PROJECT,
  DELETE_ATTACHMENT_PROJECT_SUCCESS,
  DELETE_ATTACHMENT_PROJECT_ERROR,
  UPLOAD_FILES_PROJECT,
  UPLOAD_FILES_PROJECT_SUCCESS,
  UPLOAD_FILES_PROJECT_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  projectList: null,
  isFailedSearchProjects: false,
  projectDetails: null,
  isSuccessProjectUpdate: false,
  isFailedProjectUpdate: false,
  isSuccessAttachmentDelete: false,
  isFailedAttachmentDelete: false,
  fileUploadedObj: null,
  isSuccessUploadFilesProject: false,
  isFailUploadFilesProject: false,
};

const ProjectAnalyst = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_LIST:
      state = {
        ...state,
        loading: true,
        projectList: null,
        error: null,
        isFailedSearchProjects: false,
        isSuccessProjectUpdate: false,
        isFailedProjectUpdate: false,
        isFailUploadFilesProject: false,
        isSuccessAttachmentDelete: false,
        accountDetails: null,
      };
      break;
    case GET_PROJECT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectList: action.payload,
        error: null,
        isFailedSearchProjects: false,
      };
      break;
    case GET_PROJECT_LIST_ERROR:
      state = {
        ...state,
        loading: false,
        projectList: null,
        error: action.payload,
        isFailedSearchProjects: true,
      };
      break;
    case GET_PROJECT_DETAILS:
      state = {
        ...state,
        loading: false,
        projectDetails: null,
        error: null,
      };
      break;
    case GET_PROJECT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectDetails: action.payload,
        error: null,
      };
      break;
    case GET_PROJECT_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        projectDetails: null,
        error: action.payload,
      };
      break;
    case UPDATE_PROJECT:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessProjectUpdate: false,
        isFailedProjectUpdate: false,
      };
      break;
    case UPDATE_PROJECT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessProjectUpdate: true,
        isFailedProjectUpdate: false,
        projectDetails: action.payload,
      };
      break;
    case UPDATE_PROJECT_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessProjectUpdate: false,
        isFailedProjectUpdate: true,
      };
      break;
    case DELETE_ATTACHMENT_PROJECT:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessAttachmentDelete: false,
        isFailedAttachmentDelete: false,
      };
      break;
    case DELETE_ATTACHMENT_PROJECT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessAttachmentDelete: true,
        isFailedAttachmentDelete: false,
      };
      break;
    case DELETE_ATTACHMENT_PROJECT_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessAttachmentDelete: false,
        isFailedAttachmentDelete: true,
      };
      break;
    case UPLOAD_FILES_PROJECT:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessUploadFilesProject: false,
        isFailUploadFilesProject: false,
        fileUploadedObj: null,
      };
      break;
    case UPLOAD_FILES_PROJECT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessUploadFilesProject: true,
        isFailUploadFilesProject: false,
        fileUploadedObj: action.payload,
      };
      break;
    case UPLOAD_FILES_PROJECT_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessUploadFilesProject: false,
        isFailUploadFilesProject: true,
        fileUploadedObj: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ProjectAnalyst;
