import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Col, Button, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { Container, Alert, Input } from "reactstrap";
import InlineErroMsg from "../../../../components/common/inlineErrorMsg";
import Pagination from "../../../../components/common/pagination";
import ErrorValidation from "../../../../assets/images/icons/ErrorValidation.svg";
import SearchIcon from "../../../../assets/images/icons/SearchIcon.svg";
import { viewProjectClient } from "../../../../store/Client/Project/actions";
import { getCategoryAndSummary } from "../../../../store/Dashboard/Client/actions";
import Energy_1 from "../../../../assets/images/icons/iconogrphy/Energy_1.svg";
import Lighting_1 from "../../../../assets/images/icons/iconogrphy/Lighting_1.svg";
import Water_1 from "../../../../assets/images/icons/iconogrphy/Water_1.svg";
import Gas_1 from "../../../../assets/images/icons/iconogrphy/Gas_1.svg";
import Other_1 from "../../../../assets/images/icons/iconogrphy/Other_1.svg";
import DocumentSection from "./subComponents/documentSection";
// import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../../../../components/common/Loader";
import * as helper from "./helpers/helper";
import { numberFormatToWhole } from "../../../../helpers";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useParams } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { resetEPC } from "../../../../store/EPC/actions";

const ClientProject = (props) => {
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;
  let navigate = useNavigate();
  const { id } = useParams();

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [values, setValues] = useState({
    searchBy: "",
  });

  const [categorySelected, setCategorySelected] = useState(null);
  const [checkLighting, setCheckLighting] = useState(false);
  const [checkEnergy, setCheckEnergy] = useState(false);
  const [checkWater, setCheckWater] = useState(false);
  const [checkGas, setCheckGas] = useState(false);
  const [checkOther, setCheckOther] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [projectSearchRequired, setProjectSearchRequired] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const [currentList, setCurrentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [listId, setListId] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [data, setData] = useState([]);

  const viewAll = (param) => {
    setValues({
      searchBy: "",
    });
    setSelectedStatus("");
    setCheckLighting(false);
    setCheckEnergy(false);
    setCheckWater(false);
    setCheckGas(false);
    setCheckOther(false);
    setCategorySelected(null);
    props.viewProjectClient(param);
    setProjectSearchRequired(false);
  };

  const viewAllClientProjects = (param) => {
    props.viewProjectClient(param);
    props.getCategoryAndSummary();
  };

  useEffect(() => {
    let param = {
      pageNumber: 1,
      pageSize: 10,
      clientBusinessAccountId: id
    };
    viewAllClientProjects(param);
    props.resetEPC();
  }, []);

  useEffect(() => {
    if (props.clientProjectList && props.clientProjectList.data) {
      if (props.clientProjectList.data.data.length === 0 && isSearched) {
        setShowMessage(true);
      }
      setCurrentPage(props.clientProjectList.data.currentPage);
      setCurrentList(props.clientProjectList.data.data);
      setData(props.clientProjectList.data.data);
      let idList =
        props.clientProjectList.data.data.length > 0 &&
        props.clientProjectList.data.data.map((item) => {
          return item.id;
        });
      setListId(idList);
    }
  }, [props.clientProjectList]);

  useEffect(() => {
    setCurrentList([]);
    setData([]);
    setShowMessage(true);
    window.setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [props.isFailedSearchClientProjects]);

  const handleLightCheck = (e) => {
    setCheckLighting(e.target.checked);
    let lightning =
      props.categoryAndSummaryData &&
      props.categoryAndSummaryData.data.productMainCategory?.filter((obj) => {
        return obj.productMainCategoryName === helper.LIGHTING;
      });

    if (e.target.checked) {
      categoryList.push(lightning[0].id);
      setCategoryList(categoryList);
    } else {
      categoryList.splice(categoryList.indexOf(lightning[0].id), 1);
      setCategoryList(categoryList);
    }

    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let parms = {
      productMainCategoryId: categoryList,
      searchBy: searchProjectClient,
      clientBusinessAccountId: id,
      status: selectStatus,
    };
    viewAllClientProjects(parms);
  };

  const handleEnergyCheck = (e) => {
    let energy =
      props.categoryAndSummaryData &&
      props.categoryAndSummaryData.data.productMainCategory?.filter((obj) => {
        return obj.productMainCategoryName === helper.ENERGY;
      });
    if (e.target.checked) {
      categoryList.push(energy[0].id);
      setCategoryList(categoryList);
    } else {
      categoryList.splice(categoryList.indexOf(energy[0].id), 1);
      setCategoryList(categoryList);
    }
    setCheckEnergy(e.target.checked);

    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let parms = {
      productMainCategoryId: categoryList,
      searchBy: searchProjectClient,
      clientBusinessAccountId: id,
      status: selectStatus,
    };
    viewAllClientProjects(parms);
  };

  const handleWaterCheck = (e) => {
    let water =
      props.categoryAndSummaryData &&
      props.categoryAndSummaryData.data.productMainCategory?.filter((obj) => {
        return obj.productMainCategoryName === helper.WATER;
      });
    if (e.target.checked) {
      categoryList.push(water[0].id);
      setCategoryList(categoryList);
    } else {
      categoryList.splice(categoryList.indexOf(water[0].id), 1);
      setCategoryList(categoryList);
    }
    setCheckWater(e.target.checked);

    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let parms = {
      productMainCategoryId: categoryList,
      searchBy: searchProjectClient,
      clientBusinessAccountId: id,
      status: selectStatus,
    };
    viewAllClientProjects(parms);
  };

  const handleGasCheck = (e) => {
    let gas =
      props.categoryAndSummaryData &&
      props.categoryAndSummaryData.data.productMainCategory?.filter((obj) => {
        return obj.productMainCategoryName === helper.GAS;
      });
    if (e.target.checked) {
      categoryList.push(gas[0].id);
      setCategoryList(categoryList);
    } else {
      categoryList.splice(categoryList.indexOf(gas[0].id), 1);
      setCategoryList(categoryList);
    }
    setCheckGas(e.target.checked);

    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let parms = {
      productMainCategoryId: categoryList,
      searchBy: searchProjectClient,
      clientBusinessAccountId: id,
      status: selectStatus,
    };
    viewAllClientProjects(parms);
  };

  const handleOtherCheck = (e) => {
    let other =
      props.categoryAndSummaryData &&
      props.categoryAndSummaryData.data.productMainCategory?.filter((obj) => {
        return obj.productMainCategoryName === helper.OTHER;
      });
    if (e.target.checked) {
      categoryList.push(other[0].id);
      setCategoryList(categoryList);
    } else {
      categoryList.splice(categoryList.indexOf(other[0].id), 1);
      setCategoryList(categoryList);
    }
    setCheckOther(e.target.checked);

    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let selectStatus;
    if (selectedStatus) {
      selectStatus = parseInt(selectedStatus);
    }
    let parms = {
      productMainCategoryId: categoryList,
      searchBy: searchProjectClient,
      clientBusinessAccountId: id,
      status: selectStatus,
    };
    viewAllClientProjects(parms);
  };

  const handleStatusFilter = (e) => {
    setSelectedStatus(e.target.value);
    setShowMessage(true);

    let searchProjectClient;
    if (values.searchBy) {
      searchProjectClient = values.searchBy;
    }
    let parms = {
      status: parseInt(e.target.value),
      searchBy: searchProjectClient,
      clientBusinessAccountId: id,
      productMainCategoryId: categoryList,
    };
    viewAllClientProjects(parms);
    window.setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length >= 1) {
      setProjectSearchRequired(false);
    } else {
      let searchParams = {
        pageNumber: 1,
        pageSize: 10,
        searchBy: e.target.value,
        status: parseInt(selectedStatus),
        clientBusinessAccountId: id,
        productMainCategoryId: categoryList,
      };
      viewAllClientProjects(searchParams);
    }
  };

  const submitSearch = () => {
    setShowMessage(false);
    setIsSearched(true);
    setProjectSearchRequired(false);
    if (values.searchBy !== "") {
      let selectStatus;
      if (selectedStatus) {
        selectStatus = parseInt(selectedStatus);
      }
      let parms = {
        status: selectStatus,
        searchBy: values.searchBy,
        clientBusinessAccountId: id,
        productMainCategoryId: categoryList,
      };
      viewAllClientProjects(parms);
    } else {
      setProjectSearchRequired(true);
    }
    window.setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const paginate = (pageNum) => {
    let searchParams = {
      pageNumber: parseInt(pageNum),
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllClientProjects(searchParams);
    var element = document.getElementById("paginate-client-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const nextPage = () => {
    let searchParams = {
      pageNumber: parseInt(currentPage + 1),
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllClientProjects(searchParams);
    var element = document.getElementById("paginate-client-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const prevPage = () => {
    let searchParams = {
      pageNumber: parseInt(currentPage - 1),
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllClientProjects(searchParams);
    var element = document.getElementById("paginate-client-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const lastPage = () => {
    let searchParams = {
      pageNumber:
        props.clientProjectList && props.clientProjectList.data.totalPages,
      pageSize: 10,
      clientBusinessAccountId: id,
      searchBy: values.searchBy,
      status: parseInt(selectedStatus),
      productMainCategoryId: categoryList,
    };
    viewAllClientProjects(searchParams);
    var element = document.getElementById("paginate-client-projects");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const pageOptions = {
    itemsPerPage:
      props.clientProjectList && props.clientProjectList.data.pageSize,
    totalPages:
      props.clientProjectList && props.clientProjectList.data.totalPages,
    paginate: paginate,
    nextPage: nextPage,
    prevPage: prevPage,
    currentPage:
      props.clientProjectList && props.clientProjectList.data.currentPage,
    lastPage,
    hasPrevious:
      props.clientProjectList && props.clientProjectList.data.hasPrevious,
    hasNext: props.clientProjectList && props.clientProjectList.data.hasNext,
    totalCount:
      props.clientProjectList && props.clientProjectList.data.totalCount,
  };

  const statusColumnFormatter = (status) => {
    if (status === 0) {
      return <p style={{ margin: 0 }}>Inactive</p>;
    } else if (status === 1) {
      return <p style={{ margin: 0 }}>Active</p>;
    } else if (status === 2) {
      return <p style={{ margin: 0 }}>Requested</p>;
    } else {
      return <p style={{ margin: 0 }}>Completed</p>;
    }
  };

  const productFormatter = (category) => {
    if (category === "Lighting") {
      return <img src={Lighting_1} alt={""} width={20} height={20} />;
    } else if (category === "Electricity") {
      return <img src={Energy_1} alt={""} width={20} height={20} />;
    } else if (category === "Water") {
      return <img src={Water_1} alt={""} width={20} height={20} />;
    } else if (category === "Gas") {
      return <img src={Gas_1} alt={""} width={20} height={20} />;
    } else {
      return <img src={Other_1} alt={""} width={20} height={20} />;
    }
  };

  const formatValue = (cell) => {
    return <span>{cell.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>;
  };
  const formatPaybackValue = (cell) => {
    return <span>{cell.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>;
  };
  const formatValueToRound = (cell) => {
    return <span>{numberFormatToWhole(cell)}</span>;
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      enableSorting: false,
      size: 120,
    },
    {
      header: "Category",
      accessorKey: "productMainCategory",
      Cell: ({ cell }) =>
        productFormatter(cell.row.original.productMainCategory),
      enableSorting: false,
      size: 100,
    },
    {
      header: "Organization",
      accessorKey: "clientBusinessAccountName",
      enableSorting: true,
      size: 150,
    },
    {
      header: "Investment",
      accessorKey: "investment",
      enableSorting: false,
      Cell: ({ cell }) => formatValueToRound(cell.renderValue()),
      size: 100,
    },
    {
      header: "Savings£ (PA)",
      accessorKey: "savingsPA",
      enableSorting: false,
      Cell: ({ cell }) => formatValueToRound(cell.renderValue()),
      size: 100,
    },
    {
      header: "Payback Time (Years)",
      accessorKey: "paybackTimeInYears",
      enableSorting: false,
      Cell: ({ cell }) => formatPaybackValue(cell.renderValue()),
      size: 120,
    },
    {
      header: "Carbon Savings (Tons)",
      accessorKey: "carbonSavingsinTons",
      enableSorting: false,
      Cell: ({ cell }) => formatValue(cell.renderValue()),
      size: 120,
    },
    {
      header: "Status",
      accessorKey: "status",
      enableSorting: false,
      Cell: ({ cell }) => statusColumnFormatter(cell.renderValue()),
      size: 50,
    },
  ];

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitSearch();
    }
  };


  return (
    <React.Fragment>
      <div id="paginate-client-projects"></div>
      <div className="page-content">
        {" "}
        <Helmet>
          <title>{"SORT - Projects"}</title>
        </Helmet>
        <Container fluid>
          {showMessage && props.isFailedSearchClientProjects && (
            <Row className="px-2">
              <Alert color="danger">
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                There are no projects matching your search.
              </Alert>
            </Row>
          )}
          <div className="d-flex justify-content-end">
            <Button
              className="sort-btn-link"
              type="button"
              onClick={() => viewAll()}
            >
              View All Projects
            </Button>
          </div>
          <Form className="client-view-opportunity">
            <Form.Group className="mb-3 row mt-4" controlId="formProject">
              <Col sm={3} md={3} lg={3} xs={7}>
                <Form.Label className="sort-form-lable green-lable">
                  Filter by Product Category
                </Form.Label>

                <Row className="category-client">
                  <Col sm={4} md={4} lg={4} xs={4}>
                    <Form.Check
                      type="checkbox"
                      label="Lighting"
                      checked={checkLighting}
                      onChange={handleLightCheck}
                      className="category-client-checkbox"
                    />
                  </Col>
                  <Col sm={4} md={4} lg={4} xs={4}>
                    <Form.Check
                      type="checkbox"
                      label="Electricity"
                      checked={checkEnergy}
                      onChange={handleEnergyCheck}
                      className="category-client-checkbox"
                    />
                  </Col>
                  <Col sm={4} md={4} lg={4} xs={4}>
                    <Form.Check
                      type="checkbox"
                      label="Water"
                      checked={checkWater}
                      onChange={handleWaterCheck}
                      className="category-client-checkbox"
                    />
                  </Col>
                  <Col sm={4} md={4} lg={4} xs={4}>
                    <Form.Check
                      type="checkbox"
                      label="Gas"
                      checked={checkGas}
                      onChange={handleGasCheck}
                      className="category-client-checkbox"
                    />
                  </Col>
                  <Col sm={4} md={4} lg={4} xs={4}>
                    <Form.Check
                      type="checkbox"
                      label="Other"
                      checked={checkOther}
                      onChange={handleOtherCheck}
                      className="category-client-checkbox"
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={3} md={3} lg={3} xs={5}>
                <Form.Label className="sort-form-lable green-lable">
                  Filter by Status
                </Form.Label>
                <Input
                  type="select"
                  name="status"
                  onChange={(e) => handleStatusFilter(e)}
                  value={selectedStatus}
                >
                  <option value="">Select status</option>
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                  <option value={2}>Requested</option>
                  <option value={3}>Completed</option>
                </Input>
              </Col>
              <Col sm={4} md={4} lg={4} xs={7}>
                <Form.Label
                  className="sort-form-lable green-lable"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  Search Project
                </Form.Label>
                <div
                  className="form-group search-content"
                  style={{ position: "relative" }}
                >
                  <input
                    placeholder="Search Project Name/Organization"
                    className={` client-project-search sort-form-input form-control ${
                      projectSearchRequired ? "errorField" : ""
                    }`}
                    name={"searchBy"}
                    type="text"
                    onChange={handleChange}
                    value={values.searchBy}
                    onKeyPress={handleEnterKey}
                  />
                  <span
                    className="search-client"
                    style={{
                      position: "absolute",
                    }}
                  >
                    <img
                      src={SearchIcon}
                      alt="success"
                      className="project-search-btn"
                    />
                  </span>
                  {projectSearchRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                    />
                  )}
                  <InlineErroMsg
                    errorType={projectSearchRequired}
                    errorMsg={"Please enter a project name to search."}
                  />
                </div>
              </Col>
              <Col sm={2} md={2} lg={2} xs={5}>
                <Form.Label
                  className="sort-form-lable green-lable"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  Search Project Button
                </Form.Label>
                <Button
                  className="sort-primary-outline-btn btn-project-min-width"
                  type="button"
                  onClick={() => submitSearch()}
                >
                  Search
                </Button>
              </Col>
            </Form.Group>
            <hr />

            <Form.Group className="mb-3 row mt-4">
              <Col sm={12} md={12} lg={12}>
                {currentList ? (
                  <>
                    <div className="client-project-table">
                      <MaterialReactTable
                        muiTablePaperProps={{
                          sx: {
                            boxShadow: "none",
                            borderRadius: "0px",
                            marginBottom: "15px",
                          },
                        }}
                        muiTableHeadCellProps={{
                          sx: {
                            fontSize: "inherit !important",
                            fontFamily: "inherit !important",
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            fontSize: "inherit !important",
                            fontFamily: "inherit !important",
                          },
                        }}
                        muiTableDetailPanelProps={{
                          sx: { padding: "0px !important" },
                        }}
                        muiExpandAllButtonProps={{ sx: { display: "none" } }}
                        muiExpandButtonProps={{ sx: { display: "none" } }}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableTopToolbar={false}
                        muiTableBodyRowProps={{ hover: false }}
                        columns={columns}
                        data={data}
                        initialState={{ expanded: true }}
                        renderDetailPanel={({ row }) => (
                          <div>
                            {row?.original?.attachments &&
                              row?.original?.attachments?.length > 0 && (
                                <DocumentSection
                                  documents={row.original.attachments}
                                />
                              )}
                          </div>
                        )}
                        positionExpandColumn="last"
                      />
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </Col>
              <br />
              <Pagination data={pageOptions} />
            </Form.Group>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

ClientProject.propTypes = {
  getCategoryAndSummary: PropTypes.func,
  categoryAndSummaryData: PropTypes.any,
  viewProjectClient: PropTypes.func,
  clientProjectList: PropTypes.any,
  loading: PropTypes.any,
  isFailedSearchClientProjects: PropTypes.any,
  resetEPC: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getCategoryAndSummary: state.ClientDashboard.getCategoryAndSummary,
    categoryAndSummaryData: state.ClientDashboard.categoryAndSummaryData,
    viewProjectClient: state.ClientProject.viewProjectClient,
    clientProjectList: state.ClientProject.clientProjectList,
    loading: state.ClientProject.loading,
    isFailedSearchClientProjects:
      state.ClientProject.isFailedSearchClientProjects,
    resetEPC: state.EPCDetails.resetEPC,
  };
};

export default connect(mapStateToProps, {
  getCategoryAndSummary,
  viewProjectClient,
  resetEPC,
})(ClientProject);
