import {
  GET_CLIENT_DASHBOARD,
  GET_CLIENT_DASHBOARD_SUCCESS,
  GET_CLIENT_DASHBOARD_ERROR,
  GET_FORECAST_DATA,
  GET_FORECAST_DATA_SUCCESS,
  GET_FORECAST_DATA_ERROR,
  GET_CATEGORY_AND_SUMMARY,
  GET_CATEGORY_AND_SUMMARY_SUCCESS,
  GET_CATEGORY_AND_SUMMARY_ERROR,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_GP_PARENT_COMPANIES,
  GET_GP_PARENT_COMPANIES_SUCCESS,
  GET_GP_PARENT_COMPANIES_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  clientDashboard: null,
  isSuccessClientDashboardData: false,
  isSuccessForecastData: false,
  forecastData: null,
  categoryAndSummaryData: null,
  companyList: null,
  isCompanyListSuccess: false,
  gpParentCompanies: null,
  isGpParentCompanySuccess: false,
  isGpParentCompanyFaild: false,
};

const ClientDashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_DASHBOARD:
      state = {
        ...state,
        loading: false,
        clientDashboard: null,
        error: null,
        isSuccessClientDashboardData: false,
      };
      break;
    case GET_CLIENT_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        clientDashboard: action.payload,
        error: null,
        isSuccessClientDashboardData: true,
      };
      break;
    case GET_CLIENT_DASHBOARD_ERROR:
      state = {
        ...state,
        loading: false,
        clientDashboard: null,
        error: action.payload,
        isSuccessClientDashboardData: false,
      };
      break;
    case GET_FORECAST_DATA:
      state = {
        ...state,
        loading: false,
        forecastData: null,
        error: null,
        isSuccessForecastData: false,
      };
      break;
    case GET_FORECAST_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        forecastData: action.payload,
        error: null,
        isSuccessForecastData: true,
      };
      break;
    case GET_FORECAST_DATA_ERROR:
      state = {
        ...state,
        loading: false,
        forecastData: null,
        error: action.payload,
        isSuccessForecastData: false,
      };
      break;
    case GET_CATEGORY_AND_SUMMARY:
      state = {
        ...state,
        loading: false,
        categoryAndSummaryData: null,
      };
      break;
    case GET_CATEGORY_AND_SUMMARY_SUCCESS:
      state = {
        ...state,
        loading: false,
        categoryAndSummaryData: action.payload,
      };
      break;
    case GET_CATEGORY_AND_SUMMARY_ERROR:
      state = {
        ...state,
        loading: false,
        categoryAndSummaryData: null,
      };
      break;
    case GET_COMPANIES:
      state = {
        ...state,
        loading: false,
        companyList: null,
        isCompanyListSuccess: false,
      };
      break;
    case GET_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyList: action.payload,
        isCompanyListSuccess: true,
      };
      break;
    case GET_COMPANIES_ERROR:
      state = {
        ...state,
        loading: false,
        companyList: null,
        isCompanyListSuccess: false,
      };
      break;
    case GET_GP_PARENT_COMPANIES:
      state = {
        ...state,
        loading: false,
        gpParentCompanies: null,
        isGpParentCompanySuccess: false,
        isGpParentCompanyFaild: false,
      };
      break;
    case GET_GP_PARENT_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        gpParentCompanies: action.payload,
        isGpParentCompanySuccess: true,
        isGpParentCompanyFaild: false,
      };
      break;
    case GET_GP_PARENT_COMPANIES_ERROR:
      state = {
        ...state,
        loading: false,
        gpParentCompanies: null,
        isGpParentCompanySuccess: false,
        isGpParentCompanyFaild: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ClientDashboard;
