import { all } from "redux-saga/effects";

import AdminSaga from "./user/admin/saga";
// import Permission from "./admin/Permissions/saga"
// import Roles from "./Admin/Roles/saga"
import AccountSaga from "./Admin/Accounts/saga";
import ClientSaga from "./Admin/Client/saga";
import Auth from "./Admin/Auth/saga";
import AuditSaga from "./Admin/Audit/saga";
import OpportunitySaga from "./Admin/Audit/Opportunity/saga";
import SettingsSaga from "./Settings/saga";
import ReportSaga from "./Report/Audit/saga";
import DashboardSaga from "./Dashboard/Admin/saga";
import ClientReportSaga from "./Client/Report/saga";
import ClientProjectSaga from "./Client/Project/saga";
import ClientCarbonSaga from "./Client/CarbonRoadMap/saga";
import ProjectAnalystSaga from "./Project/Analyst/saga";
import ClientDashboardSaga from "./Dashboard/Client/saga";
import GPDashboardSaga from "./Dashboard/GP/staff/saga";
import EPCSaga from "./EPC/saga";

export default function* rootSaga() {
  yield all([
    AdminSaga(),
    AccountSaga(),
    ClientSaga(),
    Auth(),
    AuditSaga(),
    OpportunitySaga(),
    SettingsSaga(),
    ReportSaga(),
    DashboardSaga(),
    ClientReportSaga(),
    ClientProjectSaga(),
    ClientCarbonSaga(),
    ProjectAnalystSaga(),
    ClientDashboardSaga(),
    GPDashboardSaga(),
    EPCSaga(),
  ]);
}
