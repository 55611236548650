import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CompanyNameTooltip = (props) => {
  const { text, customStyle } = props;

  return (
    <React.Fragment>
      <OverlayTrigger
        style={{ background: "white"}}
        key={""}
        overlay={
          <Tooltip id="company-name-tooltip" className="client-tooltip company-name-tooltip">
            {text}
          </Tooltip>
        }
      >
        <span className={customStyle}>...</span>
      </OverlayTrigger>
    </React.Fragment>
  );
};

export default CompanyNameTooltip;
