import React from "react";
import { Col, Form } from "react-bootstrap";
import InfoTooltip from "../../../components/common/tooltip";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";

const DashboardSettings = (props) => {
  const {
    untappedSavings,
    setUntappedSavings,
    carbonFootPrintTitle,
    setCarbonFootPrintTitle,
    disabledCarbonTitle,
    setDisabledCarbonTitle,
    carbonTitleRequired,
    setCarbonTitleRequired,
    overrideCarbonTitle,
    setOverrideCarbonTitle,
    carbonFootNote,
    setCarbonFootNote,
    disabledCarbonFootNote,
    setDisabledCarbonFootNote,
    carbonFootNoteRequired,
    setCarbonFootNoteRequired,
    overrideCarbonFootNote,
    setOverrideCarbonFootNote,
    forecastCashFlow,
    setForecastCashFlow,
    disabledForecastCashFlow,
    setDisabledForecastCashFlow,
    forecastCashFlowRequired,
    setForecastCashFlowRequired,
    overrideForecastCashFlow,
    setOverrideForecastCashFlow,
    costInaction,
    setCostInaction,
    disabledCostInaction,
    setDisabledCostInaction,
    costInactionRequired,
    setCostInactionRequired,
    overrideCostInaction,
    setOverrideCostInaction,
    handleOverrideCarbonTitle,
    handleOverrideCarbonFootNote,
    handleOverrideForecastCashFlow,
    handleOverrideCostInaction,
    setIsUpdatedDashboardSettings
  } = props;

  const handleUSavingsChange = (e) => {
    if (e) {
      setUntappedSavings(e.target.checked);
      setIsUpdatedDashboardSettings(true);
    }
  };

  const handleCarbonFootPrintTitle = (e) => {
    setCarbonFootPrintTitle(e.target.value);
    setIsUpdatedDashboardSettings(true);
    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonFootPrintTitle") {
        setCarbonTitleRequired(false);
      }
    } else {
      if (e.target.name === "carbonFootPrintTitle") {
        setCarbonTitleRequired(true);
      }
    }
  };

  const handleCarbonFootNote = (e) => {
    setCarbonFootNote(e.target.value);
    setIsUpdatedDashboardSettings(true);
    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonFootNote") {
        setCarbonFootNoteRequired(false);
      }
    } else {
      if (e.target.name === "carbonFootNote") {
        setCarbonFootNoteRequired(true);
      }
    }
  };

  const handleForecastCashFlow = (e) => {
    setForecastCashFlow(e.target.value);
    setIsUpdatedDashboardSettings(true);
    if (e.target.value.length >= 1) {
      if (e.target.name === "forecastCashFlow") {
        setForecastCashFlowRequired(false);
      }
    } else {
      if (e.target.name === "forecastCashFlow") {
        setForecastCashFlowRequired(true);
      }
    }
  };

  const handleCostInaction = (e) => {
    setCostInaction(e.target.value);
    setIsUpdatedDashboardSettings(true);
    if (e.target.value.length >= 1) {
      if (e.target.name === "costInaction") {
        setCostInactionRequired(false);
      }
    } else {
      if (e.target.name === "costInaction") {
        setCostInactionRequired(true);
      }
    }
  };

  return (
    <React.Fragment>
      <Form.Group className="mb-3 row mt-4">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Your Untapped Savings &nbsp;
            <InfoTooltip text={"Your Untapped Savings"} id="untappedSavings" />
          </Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Display ‘Equivalent to’ section?
          </Form.Label>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="checkbox"
              name="untappedSavings"
              id="toggle_settings"
              onChange={handleUSavingsChange}
              checked={untappedSavings}
            />
            <label className="label1" htmlFor="toggle_settings">
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row mt-4">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Carbon Footprint &nbsp;
            <InfoTooltip text={"Carbon Footprint"} id="carbonFootprint" />
          </Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Description under the main title
          </Form.Label>
          <div className="sort-form-wrapper">
            <div className="form-group sort-form-group">
              <Form.Control
                as="textarea"
                rows={3}
                name="carbonFootPrintTitle"
                id="carbonFootPrintTitle"
                type="text"
                className={`sort-form-input form-control ${
                  carbonTitleRequired ? "errorField" : ""
                } ${disabledCarbonTitle ? "disable-fields" : ""}`}
                value={carbonFootPrintTitle}
                onChange={handleCarbonFootPrintTitle}
                disabled={disabledCarbonTitle && true}
              />
              {carbonTitleRequired && (
                <img
                  src={ErrorValidation}
                  alt="error"
                  width={20}
                  height={20}
                  className="errorImg"
                  style={{ top: 30, right: -30 }}
                />
              )}
            </div>
            <span
              className={`inline-form-error ${
                carbonTitleRequired ? "display-error" : ""
              }`}
            >
              Please enter the description.
            </span>
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row">
        <Col sm={4} md={3} lg={3}></Col>
        <Col lg={8} className="sort-form-field">
          <div style={{ float: "right" }}>
            <Form.Check
              type="checkbox"
              label="Override default settings for Carbon Footprint"
              className="override-checkbox"
              checked={overrideCarbonTitle}
              onChange={handleOverrideCarbonTitle}
            />
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row mt-4">
        <Col sm={4} md={3} lg={3}></Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            <div className="setting-right-block">Right Block</div>
            Footnote for the resulting carbon footprint
          </Form.Label>
          <div className="sort-form-wrapper">
            <div className="form-group sort-form-group">
              <Form.Control
                as="textarea"
                rows={3}
                name="carbonFootNote"
                id="carbonFootNote"
                type="text"
                className={`sort-form-input form-control ${
                  carbonFootNoteRequired ? "errorField" : ""
                } ${disabledCarbonFootNote ? "disable-fields" : ""}`}
                value={carbonFootNote}
                onChange={handleCarbonFootNote}
                disabled={disabledCarbonFootNote && true}
              />
              {carbonFootNoteRequired && (
                <img
                  src={ErrorValidation}
                  alt="error"
                  width={20}
                  height={20}
                  className="errorImg"
                  style={{ top: 30, right: -30 }}
                />
              )}
            </div>
            <span
              className={`inline-form-error ${
                carbonFootNoteRequired ? "display-error" : ""
              }`}
            >
              Please enter the footnote.
            </span>
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row">
        <Col sm={4} md={3} lg={3}></Col>
        <Col lg={8} className="sort-form-field">
          <div style={{ float: "right" }}>
            <Form.Check
              type="checkbox"
              label="Override default settings for Resulting Carbon Footprint"
              className="override-checkbox"
              checked={overrideCarbonFootNote}
              onChange={handleOverrideCarbonFootNote}
            />
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row mt-4">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Forecast Cashflow and Breakeven Cycle &nbsp;
            <InfoTooltip
              text={"Forecast Cashflow and Breakeven Cycle"}
              id="forecastCashFlow"
            />
          </Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Description under the main title
          </Form.Label>
          <div className="sort-form-wrapper">
            <div className="form-group sort-form-group">
              <Form.Control
                as="textarea"
                rows={3}
                name="forecastCashFlow"
                id="forecastCashFlow"
                type="text"
                className={`sort-form-input form-control ${
                  forecastCashFlowRequired ? "errorField" : ""
                } ${disabledForecastCashFlow ? "disable-fields" : ""}`}
                value={forecastCashFlow}
                onChange={handleForecastCashFlow}
                disabled={disabledForecastCashFlow && true}
              />
              {forecastCashFlowRequired && (
                <img
                  src={ErrorValidation}
                  alt="error"
                  width={20}
                  height={20}
                  className="errorImg"
                  style={{ top: 30, right: -30 }}
                />
              )}
            </div>
            <span
              className={`inline-form-error ${
                forecastCashFlowRequired ? "display-error" : ""
              }`}
            >
              Please enter the description.
            </span>
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row">
        <Col sm={4} md={3} lg={3}></Col>
        <Col lg={8} className="sort-form-field">
          <div style={{ float: "right" }}>
            <Form.Check
              type="checkbox"
              label="Override default settings for Cashflow and Breakeven Cycle"
              className="override-checkbox"
              checked={overrideForecastCashFlow}
              onChange={handleOverrideForecastCashFlow}
            />
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row mt-4">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Projected Cost of Inaction &nbsp;
            <InfoTooltip
              text={"Projected Cost of Inaction"}
              id="costInaction"
            />
          </Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Description under the main title
          </Form.Label>
          <div className="sort-form-wrapper">
            <div className="form-group sort-form-group">
              <Form.Control
                as="textarea"
                rows={3}
                name="costInaction"
                id="costInaction"
                type="text"
                className={`sort-form-input form-control ${
                  costInactionRequired ? "errorField" : ""
                } ${disabledCostInaction ? "disable-fields" : ""}`}
                value={costInaction}
                onChange={handleCostInaction}
                disabled={disabledCostInaction && true}
              />
              {costInactionRequired && (
                <img
                  src={ErrorValidation}
                  alt="error"
                  width={20}
                  height={20}
                  className="errorImg"
                  style={{ top: 30, right: -30 }}
                />
              )}
            </div>
            <span
              className={`inline-form-error ${
                costInactionRequired ? "display-error" : ""
              }`}
            >
              Please enter the description.
            </span>
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row">
        <Col sm={4} md={3} lg={3}></Col>
        <Col lg={8} className="sort-form-field">
          <div style={{ float: "right" }}>
            <Form.Check
              type="checkbox"
              label="Override default settings for Projected Cost of Inaction"
              className="override-checkbox"
              checked={overrideCostInaction}
              onChange={handleOverrideCostInaction}
            />
          </div>
        </Col>
      </Form.Group>
    </React.Fragment>
  );
};

export default DashboardSettings;
