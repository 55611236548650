import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_EPC_SCORE,
  GET_REPORTS_PER_SITE,
  GET_OPPORTUNITIES_PER_REPORT,
} from "./actionTypes";

import {
  getEPCDetailsSuccess,
  getEPCDetailsFaild,
  getReportsPerSiteSuccess,
  getReportsPerSiteFaild,
  getOpportunitiesPerReportSuccess,
  getOpportunitiesPerReportFaild,
} from "./actions";

import {
  getEPCScore,
  getReportsPerSite,
  getOpportunitiesPerReport,
} from "../../helpers/api";

function* getEPCScoreDetailsFunc({ payload: { parm } }) {
  try {
    const response = yield call(getEPCScore, parm);
    if (response.data.statusCode === 200) {
      yield put(getEPCDetailsSuccess(response.data));
    } else {
      yield put(
        getEPCDetailsFaild(
          response.data.responseException.exceptionMessage.message
        )
      );
    }
  } catch (error) {
    yield put(getEPCDetailsFaild(error));
  }
}

function* getReportsPerSiteFunc({ payload: { parm } }) {
  try {
    const response = yield call(getReportsPerSite, parm);
    if (response.data.statusCode === 200) {
      yield put(getReportsPerSiteSuccess(response.data));
    } else {
      yield put(getReportsPerSiteFaild(response.message));
    }
  } catch (error) {
    yield put(getReportsPerSiteFaild(error));
  }
}

function* getOpportunitiesPerReportFunc({ payload: { parm } }) {
  try {
    const response = yield call(getOpportunitiesPerReport, parm);
    if (response.data.statusCode === 200) {
      yield put(getOpportunitiesPerReportSuccess(response.data));
    } else {
      yield put(getOpportunitiesPerReportFaild(response.message));
    }
  } catch (error) {
    yield put(getOpportunitiesPerReportFaild(error));
  }
}

export function* watchProjectAnalyst() {
  yield takeEvery(GET_EPC_SCORE, getEPCScoreDetailsFunc);
  yield takeEvery(GET_REPORTS_PER_SITE, getReportsPerSiteFunc);
  yield takeEvery(GET_OPPORTUNITIES_PER_REPORT, getOpportunitiesPerReportFunc);
}

function* EPCSaga() {
  yield all([fork(watchProjectAnalyst)]);
}

export default EPCSaga;
