import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";


const CancelConfirmation = (props) => {
  const { isShowCancelConfirmation, handleCancelConfirmation, handleRestForm } = props
  return (
    <React.Fragment>
      <Modal show={isShowCancelConfirmation} centered className="confirmation-modal">
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12} className="text-center">
              <Modal.Title>
                Confirmation
              </Modal.Title>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p className="update-modal-body">
              Are you sure you want to cancel the file re-upload?
            </p>
          </Row>
          <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                className="sort-primary-btn btn-min-width"
                style={{ margin: "0 10px" }}
                type={"button"}
                onClick={() => handleRestForm()}
              >
                Yes
              </Button>
              <Button
                className="sort-primary-outline-btn btn-min-width"
                onClick={handleCancelConfirmation}
              >
                No
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default CancelConfirmation;