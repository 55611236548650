import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";

const RemoveFileConfirmation = (props) => {
  const { showRemoveSection, handleCloseRemoveSection, removeSection, modalText } =
    props;
  return (
    <Modal show={showRemoveSection} centered className="confirmation-modal">
      <Modal.Header className="update-header">
        <Row>
          <Col lg={12} className="text-center">
            <Modal.Title>Confirmation</Modal.Title>
            <span className="close-icon" onClick={handleCloseRemoveSection}>
              x
            </span>
          </Col>
        </Row>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <p className="update-modal-body">{modalText}</p>
        </Row>
        <Row>
          <Col lg={12} className={"text-center"}>
            <Button
              className="sort-primary-btn btn-min-width"
              style={{ margin: "0 10px" }}
              type={"button"}
              onClick={() => removeSection()}
            >
              Yes
            </Button>
            <Button
              className="sort-primary-outline-btn btn-min-width"
              onClick={handleCloseRemoveSection}
            >
              No
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
export default RemoveFileConfirmation;
