import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Repayment from "../repayment";
import { postFinanceRepayment } from "../../../../../store/Settings/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DiscardChangesPopup from "../../../../../components/common/discardChangesPopup";
import { Alert } from "reactstrap";
import ErrorValidation from "../../../../../assets/images/icons/ErrorValidation.svg";
import { getDecodeAuthDetails } from "../../../../../helpers";

const CreateRepayment = (props) => {
  const {
    showCreateRepay,
    setShowCreateRepay,
    setRepaySuccess,
    setShowAlertRepay,
    setRepayError,
    repayError,
    errorRepay,
    showAlertRepay,
  } = props;

  let decoded = getDecodeAuthDetails();

  const permissions = decoded.Permission;

  const [term, setTerm] = useState("");
  const [rate, setRate] = useState("");

  const [termRequired, setTermRequired] = useState(false);
  const [termPositive, setTermPositive] = useState(false);
  const [termValidation, setTermValidation] = useState(false);
  const [termMessage, setTermMessage] = useState("");

  const [rateRequired, setRateRequired] = useState(false);

  const [ratePositive, setRatePositive] = useState(false);

  const [isShowDisacrdConfirmation, setIsShowDisacrdConfirmation] =
    useState(false);
  const [createCancel, setCreateCancel] = useState(false);

  const [isSubmitEnable, setIsSubmitEnable] = useState(false);

  const handleDiscardConfirmation = () => {
    setIsShowDisacrdConfirmation(false);
  };

  const handleCloseRepayment = () => {
    setShowCreateRepay(false);
    setShowAlertRepay(false);
    setRepaySuccess(false);
    setRepayError(false);
    resetForm();
  };

  const backToViewPage = () => {
    setIsShowDisacrdConfirmation(false);
    setShowCreateRepay(false);
    resetForm();
    setShowAlertRepay(false);
    setRepaySuccess(false);
    setRepayError(false);
  };

  const cancelForm = () => {
    if (createCancel) {
      setIsShowDisacrdConfirmation(true);
    } else {
      backToViewPage();
    }
  };

  const formValidation = () => {
    let errors = [];

    if (term === "") {
      setTermRequired(true);
      setTermPositive(false);
      errors.push(1);
    } else if (parseFloat(term.replace(/,/g, "")) === 0) {
      setTermPositive(true);
      setTermRequired(false);
      errors.push(1);
    } else {
      setTermRequired(false);
      setTermPositive(false);
    }

    if (rate === "") {
      setRateRequired(true);
      errors.push(1);
    } else if (parseFloat(rate) < 0) {
      setRatePositive(true);
      setRateRequired(false);
      errors.push(1);
    } else {
      setRateRequired(false);
      setRatePositive(false);
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = formValidation();

    if (valid.length === 0) {
      setIsSubmitEnable(true);
      let financeRepaymentObj = {
        term: parseInt(term),
        rate: parseFloat(rate),
      };
      props.postFinanceRepayment(financeRepaymentObj);
    }
  };

  useEffect(() => {
    if (props.isCreateFinanceRepaymentSuccess) {
      window.scrollTo(0, 0);
      setShowAlertRepay(true);
      setShowCreateRepay(false);
      setRepaySuccess(true);
      resetForm();

      window.setTimeout(() => {
        setShowAlertRepay(false);
        setRepaySuccess(false);
      }, 3000);
      setIsSubmitEnable(false);
    }
  }, [props.isCreateFinanceRepaymentSuccess]);

  useEffect(() => {
    if (props.isFailedCreateFinanceRepayment) {
      setIsSubmitEnable(false);
      setRepayError(true);
      setShowAlertRepay(true);
      window.scroll(0, 0);
      window.setTimeout(() => {
        setShowAlertRepay(false);
        setRepayError(false);
      }, 5000);
    }
  }, [props.isFailedCreateFinanceRepayment]);

  useEffect(() => {
    if (props.isFailedCreateFinanceRepayment) {
      if (props.error && props.error.error) {
        if (props.error.error.Term) {
          setTermValidation(true);
          setTermMessage(props.error.error.Term[0]);
        }
      }
    } else {
      setTermValidation(false);
      setTermMessage("");
    }
  }, [props.isFailedCreateFinanceRepayment]);

  const resetForm = () => {
    setTerm("");
    setRate("");
    setTermRequired(false);
    setTermPositive(false);
    setTermValidation(false);
    setTermMessage("");
    setRateRequired(false);
    setRatePositive(false);
    setCreateCancel(false);
  };

  return (
    <React.Fragment>
      {" "}
      <Modal show={showCreateRepay} className="modal-repayment">
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12}>
              <Modal.Title className="text-center">
                Create new Repayment Term & Rate
              </Modal.Title>
              <span className="close-icon" onClick={handleCloseRepayment}>
                x
              </span>
            </Col>
          </Row>
        </Modal.Header>
        {permissions.includes("PERMISSIONS.FINANCINGOPTIONSETTING.REPAYMENTTERMCREATE") ? (
          <Modal.Body style={{ padding: 40 }}>
          {repayError && showAlertRepay && (
            <Row className="px-2">
              {errorRepay.map((item) => {
                return (
                  <Alert
                    key={item}
                    color="danger"
                    isOpen={repayError && showAlertRepay}
                  >
                    <img src={ErrorValidation} alt="error" width={30} height={30}/>
                    {item}
                  </Alert>
                );
              })}
            </Row>
          )}
          {repayError && showAlertRepay && (
            <Row className="px-2">
              <Alert color="danger" isOpen={repayError && showAlertRepay}>
                <img src={ErrorValidation} alt="error" width={30} height={30}/>
                Repayment term and rate could not be created
              </Alert>
            </Row>
          )}

          <Repayment
            term={term}
            setTerm={setTerm}
            rate={rate}
            setRate={setRate}
            termRequired={termRequired}
            setTermRequired={setTermRequired}
            termPositive={termPositive}
            setTermPositive={setTermPositive}
            termValidation={termValidation}
            setTermValidation={setTermValidation}
            termMessage={termMessage}
            setTermMessage={setTermMessage}
            rateRequired={rateRequired}
            setRateRequired={setRateRequired}
            ratePositive={ratePositive}
            setRatePositive={setRatePositive}
            setCreateCancel={setCreateCancel}
          />
          <DiscardChangesPopup
            isShowDisacrdConfirmation={isShowDisacrdConfirmation}
            handleDiscardConfirmation={handleDiscardConfirmation}
            backToViewPage={backToViewPage}
          />
          <Row className={"mt-3"}>
            <Col lg={12} className={"text-center"}>
              <Button
                className="sort-primary-btn btn-min-width"
                style={{ margin: "0 10px" }}
                type={"button"}
                onClick={handleSubmit}
                disabled={isSubmitEnable}
              >
                Create
              </Button>
              <Button
                className="sort-primary-outline-btn btn-min-width"
                onClick={() => cancelForm()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        ):(
          <Modal.Body style={{ padding: 40 }}>
            <Row>
              <p className="update-modal-body">
                You don't have permission to perform this action. Please contact
                the Admin.
              </p>
            </Row>
          </Modal.Body>
        )}
        
      </Modal>
    </React.Fragment>
  );
};

CreateRepayment.propTypes = {
  postFinanceRepayment: PropTypes.func,
  isCreateFinanceRepaymentSuccess: PropTypes.any,
  error: PropTypes.any,
  isFailedCreateFinanceRepayment: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isCreateFinanceRepaymentSuccess:
      state.Settings.isCreateFinanceRepaymentSuccess,
    isFailedCreateFinanceRepayment:
      state.Settings.isFailedCreateFinanceRepayment,
    error: state.Settings.error,
  };
};

export default connect(mapStateToProps, {
  postFinanceRepayment,
})(CreateRepayment);
