import {
  GET_CLIENT_REPORT_DETAILS,
  GET_CLIENT_REPORT_DETAILS_SUCCESS,
  GET_CLIENT_REPORT_DETAILS_ERROR,
  GET_CLIENT_REPORT_LIST,
  GET_CLIENT_REPORT_LIST_SUCCESS,
  GET_CLIENT_REPORT_LIST_ERROR,
  SELECTED_REPORT,
  GET_FINANCING_OPTIONS,
  GET_FINANCING_OPTIONS_SUCCESS,
  GET_FINANCING_OPTIONS_ERROR,
  GET_REPAYMENT_DATA,
  GET_REPAYMENT_DATA_SUCCESS,
  GET_REPAYMENT_DATA_ERROR
} from "./actionTypes";

const initialState = {
  clientReportError: null,
  loading: false,
  clientReportDetails: [],
  clientReportList:null,
  clientReportListError:null,
  isSuccessReportList:false,
  selectedReportName:null
};

const ClientReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_REPORT_DETAILS:
      state = {
        ...state,
        loading: false,
        clientReportDetails: [],
        clientReportError: null,
        clientReportListError:null,
        isSuccessReportList:false
      };
      break;
    case GET_CLIENT_REPORT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        clientReportDetails: action.payload,
        clientReportError: null,
      };
      break;
    case GET_CLIENT_REPORT_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        clientReportDetails: [],
        clientReportError: action.payload,
      };
      break;
    case GET_CLIENT_REPORT_LIST:
      state = {
        ...state,
        loading: false,
        clientReportList: null,
        clientReportListError: null,
        isSuccessReportList:false,
        selectedReportName:null
      };
      break;
    case GET_CLIENT_REPORT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        clientReportList: action.payload,
        clientReportListError: null,
        isSuccessReportList:true
      };
      break;
    case GET_CLIENT_REPORT_LIST_ERROR:
      state = {
        ...state,
        loading: false,
        clientReportList: null,
        clientReportListError: action.payload,
        isSuccessReportList:false
      };
      break;
      case GET_FINANCING_OPTIONS:
      state = {
        ...state,
        loading: false,
        financingOptions: null,
        isSuccessFinancingOptions:false,
      };
      break;
    case GET_FINANCING_OPTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        financingOptions: action.payload,
        isSuccessFinancingOptions:true,
      };
      break;
    case GET_FINANCING_OPTIONS_ERROR:
      state = {
        ...state,
        loading: false,
        financingOptions: null,
        isSuccessFinancingOptions:false,
      };
      break;
      case GET_REPAYMENT_DATA:
      state = {
        ...state,
        loading: false,
        repaymentData: null,
        isSuccessRepaymentData:false,
      };
      break;
    case GET_REPAYMENT_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        repaymentData: action.payload,
        isSuccessRepaymentData:false,
      };
      break;
    case GET_REPAYMENT_DATA_ERROR:
      state = {
        ...state,
        loading: false,
        repaymentData: null,
        isSuccessRepaymentData:false,
      };
      break;
      case SELECTED_REPORT:
      state = {
        ...state,
        selectedReportName:action.payload
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ClientReport;
