import {
  GET_MENTOR_DETAIL_CALLS,
  GET_MENTOR_DETAIL_CALLS_SUCCESS,
  GET_MENTOR_DETAIL_CALLS_ERROR,
  GET_BASE_LINE_REPORTS,
  GET_BASE_LINE_REPORTS_SUCCESS,
  GET_BASE_LINE_REPORTS_ERROR,
  GET_BUILDING_AUDITS,
  GET_BUILDING_AUDITS_SUCCESS,
  GET_BUILDING_AUDITS_ERROR,
  GET_PROJECT_FINANCING,
  GET_PROJECT_FINANCING_SUCCESS,
  GET_PROJECT_FINANCING_ERROR,
  GET_PROJECT_DELIVERY,
  GET_PROJECT_DELIVERY_SUCCESS,
  GET_PROJECT_DELIVERY_ERROR,
  GET_STAFF_ENGAGEMENT,
  GET_STAFF_ENGAGEMENT_SUCCESS,
  GET_STAFF_ENGAGEMENT_ERROR,
  GET_BUSINESS_ACCOUNTS,
  GET_BUSINESS_ACCOUNTS_SUCCESS,
  GET_BUSINESS_ACCOUNTS_ERROR,
  POST_BUILDING_AUDITS,
  POST_BUILDING_AUDITS_SUCCESS,
  POST_BUILDING_AUDITS_ERROR,
  POST_PROJECT_FINANCING,
  POST_PROJECT_FINANCING_SUCCESS,
  POST_PROJECT_FINANCING_ERROR,
  POST_PROJECT_DELIVERY,
  POST_PROJECT_DELIVERY_SUCCESS,
  POST_PROJECT_DELIVERY_ERROR,
  POST_BASELINE_REPORT,
  POST_BASELINE_REPORT_SUCCESS,
  POST_BASELINE_REPORT_ERROR,
  POST_CARBON_MENTOR_CALLS,
  POST_CARBON_MENTOR_CALLS_SUCCESS,
  POST_CARBON_MENTOR_CALLS_ERROR,
  CLEAR_SEARCH_FIELD_MENTOR_CALLS,
  CLEAR_SEARCH_FIELD_BUILDING,
  CLEAR_SEARCH_FIELD_INVESTMENT,
  CLEAR_SEARCH_FIELD_BASELINE,
  CLEAR_SEARCH_FIELD_COMPLETED,
  GET_EPC_BUSINESS_ACCOUNTS,
  GET_EPC_BUSINESS_ACCOUNTS_SUCCESS,
  GET_EPC_BUSINESS_ACCOUNTS_ERROR,
  GET_SAS_TOKEN,
  GET_SAS_TOKEN_SUCCESS,
  GET_SAS_TOKEN_FAILED,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  mentorDetailCalls: null,
  isFailedMentorDetailCalls: false,
  isSuccessMentorDetailCalls: false,
  baselineReports: null,
  isFailedBaseLineReports: false,
  isSuccessBaseLineReports: false,
  buildingAudits: null,
  isFailedBuildingAudits: false,
  isSuccessBuildingAudits: false,
  projectFinancing: null,
  isFailedProjectFinancing: false,
  isSuccessProjectFinancing: false,
  projectDelivery: null,
  isFailedProjectDelivery: false,
  isSuccessProjectDelivery: false,
  staffEngagement: null,
  isFailedStaffEngagement: false,
  isSuccessStaffEngagement: false,
  businessAccounts: null,
  isFailedBusinessAccounts: false,
  isSuccessBusinessAccounts: false,
  searchBuidingAudits: null,
  isSuccessSearchBuildingAudits: false,
  isFaildSearchBuildingAudits: false,
  searchedProjectFinancing: null,
  isSuccessSearchProjectFinancing: false,
  isFaildSearchProjectFinancing: false,
  searchedProjectDelivery: null,
  isSuccessSearchProjectDelivery: false,
  isFaildSearchProjectDelivery: false,
  searchBaselineReport: null,
  isSuccessSearchBaselineReport: false,
  isfaildSearchBaselineReport: false,
  searchedCarbonMentorCalls: null,
  isSuccessSearchCarbonMentorCall: false,
  isFaildSearchCarbonMentorCall: false,
  epcBusinessAccounts: null,
  isFailedEPCBusinessAccounts: false,
  isSuccessEPCBusinessAccounts: false,
  sasToken: null,
  isSuccessSasToken: false,
  isFailedSasToken: false,
};

const GPDashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENTOR_DETAIL_CALLS:
      state = {
        ...state,
        loading: true,
        mentorDetailCalls: null,
        error: null,
        isFailedMentorDetailCalls: false,
        isSuccessMentorDetailCalls: false,
        baselineReports: null,
        isFailedBaseLineReports: false,
        isSuccessBaseLineReports: false,
      };
      break;
    case GET_MENTOR_DETAIL_CALLS_SUCCESS:
      state = {
        ...state,
        loading: false,
        mentorDetailCalls: action.payload,
        error: null,
        isFailedMentorDetailCalls: false,
        isSuccessMentorDetailCalls: true,
      };
      break;
    case GET_MENTOR_DETAIL_CALLS_ERROR:
      state = {
        ...state,
        loading: false,
        mentorDetailCalls: null,
        error: null,
        isFailedMentorDetailCalls: true,
        isSuccessMentorDetailCalls: false,
      };
      break;
    case GET_BASE_LINE_REPORTS:
      state = {
        ...state,
        loading: true,
        baselineReports: null,
        error: null,
        isFailedBaseLineReports: false,
        isSuccessBaseLineReports: false,
      };
      break;
    case GET_BASE_LINE_REPORTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        baselineReports: action.payload,
        error: null,
        isFailedBaseLineReports: false,
        isSuccessBaseLineReports: true,
      };
      break;
    case GET_BASE_LINE_REPORTS_ERROR:
      state = {
        ...state,
        loading: false,
        baselineReports: null,
        error: null,
        isFailedBaseLineReports: true,
        isSuccessBaseLineReports: false,
      };
      break;
    case GET_BUILDING_AUDITS:
      state = {
        ...state,
        loading: true,
        buildingAudits: null,
        error: null,
        isFailedBuildingAudits: false,
        isSuccessBuildingAudits: false,
      };
      break;
    case GET_BUILDING_AUDITS_SUCCESS:
      state = {
        ...state,
        loading: false,
        buildingAudits: action.payload,
        error: null,
        isFailedBuildingAudits: false,
        isSuccessBuildingAudits: true,
      };
      break;
    case GET_BUILDING_AUDITS_ERROR:
      state = {
        ...state,
        loading: false,
        buildingAudits: null,
        error: null,
        isFailedBuildingAudits: true,
        isSuccessBuildingAudits: false,
      };
      break;
    case GET_PROJECT_FINANCING:
      state = {
        ...state,
        loading: true,
        projectFinancing: null,
        error: null,
        isFailedProjectFinancing: false,
        isSuccessProjectFinancing: false,
      };
      break;
    case GET_PROJECT_FINANCING_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectFinancing: action.payload,
        error: null,
        isFailedProjectFinancing: false,
        isSuccessProjectFinancing: false,
      };
      break;
    case GET_PROJECT_FINANCING_ERROR:
      state = {
        ...state,
        loading: false,
        projectFinancing: null,
        error: null,
        isFailedProjectFinancing: false,
        isSuccessProjectFinancing: false,
      };
      break;
    case GET_PROJECT_DELIVERY:
      state = {
        ...state,
        loading: true,
        projectDelivery: null,
        error: null,
        isFailedProjectDelivery: false,
        isSuccessProjectDelivery: false,
      };
      break;
    case GET_PROJECT_DELIVERY_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectDelivery: action.payload,
        error: null,
        isFailedProjectDelivery: false,
        isSuccessProjectDelivery: false,
      };
      break;
    case GET_PROJECT_DELIVERY_ERROR:
      state = {
        ...state,
        loading: false,
        projectDelivery: null,
        error: null,
        isFailedProjectDelivery: false,
        isSuccessProjectDelivery: false,
      };
      break;
    case GET_STAFF_ENGAGEMENT:
      state = {
        ...state,
        loading: true,
        staffEngagement: null,
        error: null,
        isFailedStaffEngagement: false,
        isSuccessStaffEngagement: false,
      };
      break;
    case GET_STAFF_ENGAGEMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        staffEngagement: action.payload,
        error: null,
        isFailedStaffEngagement: false,
        isSuccessStaffEngagement: true,
      };
      break;
    case GET_STAFF_ENGAGEMENT_ERROR:
      state = {
        ...state,
        loading: false,
        staffEngagement: null,
        error: null,
        isFailedStaffEngagement: true,
        isSuccessStaffEngagement: false,
      };
      break;
    case GET_BUSINESS_ACCOUNTS:
      state = {
        ...state,
        loading: false,
        businessAccounts: null,
        error: null,
        isFailedBusinessAccounts: false,
        isSuccessBusinessAccounts: false,
      };
      break;
    case GET_BUSINESS_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        businessAccounts: action.payload,
        error: null,
        isFailedBusinessAccounts: false,
        isSuccessBusinessAccounts: true,
      };
      break;
    case GET_BUSINESS_ACCOUNTS_ERROR:
      state = {
        ...state,
        loading: false,
        businessAccounts: null,
        error: null,
        isFailedBusinessAccounts: true,
        isSuccessBusinessAccounts: false,
      };
      break;
    case POST_BUILDING_AUDITS:
      state = {
        ...state,
        loading: false,
        searchBuidingAudits: null,
        isSuccessSearchBuildingAudits: false,
        isFaildSearchBuildingAudits: false,
        isFailedBuildingAudits: false,
        isSuccessBuildingAudits: false,
      };
      break;
    case POST_BUILDING_AUDITS_SUCCESS:
      state = {
        ...state,
        loading: false,
        searchBuidingAudits: action.payload,
        isSuccessSearchBuildingAudits: true,
        isFaildSearchBuildingAudits: false,
      };
      break;
    case POST_BUILDING_AUDITS_ERROR:
      state = {
        ...state,
        loading: false,
        searchBuidingAudits: null,
        isSuccessSearchBuildingAudits: false,
        isFaildSearchBuildingAudits: true,
      };
      break;
    case POST_PROJECT_FINANCING:
      state = {
        ...state,
        loading: false,
        searchedProjectFinancing: null,
        isSuccessSearchProjectFinancing: false,
        isFaildSearchProjectFinancing: false,
        isFailedProjectFinancing: false,
        isSuccessProjectFinancing: false,
      };
      break;
    case POST_PROJECT_FINANCING_SUCCESS:
      state = {
        ...state,
        loading: false,
        searchedProjectFinancing: action.payload,
        isSuccessSearchProjectFinancing: true,
        isFaildSearchProjectFinancing: false,
      };
      break;
    case POST_PROJECT_FINANCING_ERROR:
      state = {
        ...state,
        loading: false,
        searchedProjectFinancing: null,
        isSuccessSearchProjectFinancing: false,
        isFaildSearchProjectFinancing: true,
      };
      break;
    case POST_PROJECT_DELIVERY:
      state = {
        ...state,
        loading: false,
        searchedProjectDelivery: null,
        isSuccessSearchProjectDelivery: false,
        isFaildSearchProjectDelivery: false,
        isFailedProjectDelivery: false,
        isSuccessProjectDelivery: false,
      };
      break;
    case POST_PROJECT_DELIVERY_SUCCESS:
      state = {
        ...state,
        loading: false,
        searchedProjectDelivery: action.payload,
        isSuccessSearchProjectDelivery: true,
        isFaildSearchProjectDelivery: false,
      };
      break;
    case POST_PROJECT_DELIVERY_ERROR:
      state = {
        ...state,
        loading: false,
        searchedProjectDelivery: null,
        isSuccessSearchProjectDelivery: false,
        isFaildSearchProjectDelivery: true,
      };
      break;
    case POST_BASELINE_REPORT:
      state = {
        ...state,
        loading: false,
        searchBaselineReport: null,
        isSuccessSearchBaselineReport: false,
        isfaildSearchBaselineReport: false,
        isSuccessBaseLineReports: false,
      };
      break;
    case POST_BASELINE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        searchBaselineReport: action.payload,
        isSuccessSearchBaselineReport: true,
        isfaildSearchBaselineReport: false,
      };
      break;
    case POST_BASELINE_REPORT_ERROR:
      state = {
        ...state,
        loading: false,
        searchBaselineReport: null,
        isSuccessSearchBaselineReport: false,
        isfaildSearchBaselineReport: true,
      };
      break;
    case POST_CARBON_MENTOR_CALLS:
      state = {
        ...state,
        loading: false,
        searchedCarbonMentorCalls: null,
        isSuccessSearchCarbonMentorCall: false,
        isFaildSearchCarbonMentorCall: false,
        isSuccessMentorDetailCalls: false,
      };
      break;
    case POST_CARBON_MENTOR_CALLS_SUCCESS:
      state = {
        ...state,
        loading: false,
        searchedCarbonMentorCalls: action.payload,
        isSuccessSearchCarbonMentorCall: true,
        isFaildSearchCarbonMentorCall: false,
      };
      break;
    case POST_CARBON_MENTOR_CALLS_ERROR:
      state = {
        ...state,
        loading: false,
        searchedCarbonMentorCalls: null,
        isSuccessSearchCarbonMentorCall: false,
        isFaildSearchCarbonMentorCall: true,
      };
      break;
    case CLEAR_SEARCH_FIELD_MENTOR_CALLS:
      state = {
        ...state,
        loading: false,
        searchedCarbonMentorCalls: null,
        isSuccessSearchCarbonMentorCall: false,
        isSuccessMentorDetailCalls: true,
      };
      break;
    case CLEAR_SEARCH_FIELD_BASELINE:
      state = {
        ...state,
        loading: false,
        searchBaselineReport: null,
        isSuccessSearchBaselineReport: false,
        isSuccessBaseLineReports: true,
      };
      break;
    case CLEAR_SEARCH_FIELD_BUILDING:
      state = {
        ...state,
        loading: false,
        searchBuidingAudits: null,
        isSuccessSearchBuildingAudits: false,
        isSuccessBuildingAudits: true,
      };
      break;
    case CLEAR_SEARCH_FIELD_INVESTMENT:
      state = {
        ...state,
        loading: false,
        searchedProjectFinancing: null,
        isSuccessSearchProjectFinancing: false,
        isSuccessProjectFinancing: true,
      };
      break;
    case CLEAR_SEARCH_FIELD_COMPLETED:
      state = {
        ...state,
        loading: false,
        searchedProjectDelivery: null,
        isSuccessSearchProjectDelivery: false,
        isSuccessProjectDelivery: true,
      };
      break;
    case GET_EPC_BUSINESS_ACCOUNTS:
      state = {
        ...state,
        loading: false,
        epcBusinessAccounts: null,
        isFailedEPCBusinessAccounts: false,
        isSuccessEPCBusinessAccounts: false,
      };
      break;
    case GET_EPC_BUSINESS_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        epcBusinessAccounts: action.payload,
        isFailedEPCBusinessAccounts: false,
        isSuccessEPCBusinessAccounts: true,
      };
      break;
    case GET_EPC_BUSINESS_ACCOUNTS_ERROR:
      state = {
        ...state,
        loading: false,
        epcBusinessAccounts: null,
        isFailedEPCBusinessAccounts: true,
        isSuccessEPCBusinessAccounts: false,
      };
      break;
    case GET_SAS_TOKEN:
      state = {
        ...state,
        loading: false,
        sasToken: null,
        isSuccessSasToken: false,
        isFailedSasToken: false,
      };
      break;
    case GET_SAS_TOKEN_SUCCESS:
      state = {
        ...state,
        loading: false,
        sasToken: action.payload,
        isSuccessSasToken: true,
        isFailedSasToken: false,
      };
      break;
    case GET_SAS_TOKEN_FAILED:
      state = {
        ...state,
        loading: false,
        sasToken: null,
        isSuccessSasToken: false,
        isFailedSasToken: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GPDashboard;
