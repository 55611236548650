import {
  GET_ADMIN_ANALYST_DASHBOARD,
  GET_ADMIN_ANALYST_DASHBOARD_SUCCESS,
  GET_ADMIN_ANALYST_DASHBOARD_ERROR,
} from "./actionTypes";

export const getAdminAnalystDashboard = () => {
    return {
      type: GET_ADMIN_ANALYST_DASHBOARD,
      payload: {},
    };
  };
  export const getAdminAnalystDashboardSuccess = (data) => {
    return {
      type: GET_ADMIN_ANALYST_DASHBOARD_SUCCESS,
      payload: { data },
    };
  };
  export const getAdminAnalystDashboardFaild = (error) => {
    return {
      type: GET_ADMIN_ANALYST_DASHBOARD_ERROR,
      payload: { error },
    };
  };