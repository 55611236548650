import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, Carousel } from "react-bootstrap";
import { getMainProductCategories } from "../../../store/Admin/Audit/actions";
import {
  getClientDashboard,
  getForecashData,
  getCategoryAndSummary,
  getCompanies,
} from "../../../store/Dashboard/Client/actions";
import {
  getBusinessAccounts,
} from "../../../store/Dashboard/GP/staff/actions";
import CarbonFootprintCalculator from "./subComponents/carbonFootprintCalculator";
import UntappedSavingsCard from "./subComponents/untappedSavingsCard";
import CapitalPlaningTable from "./subComponents/capitalPlaningTable";
import CapitalPlaningGraph from "./subComponents/capitalPlaningGraph";
import ForecastCashFlowSection from "./subComponents/forcastCashflowSection";
import ForecastGraph from "./subComponents/forecastGraph";
import CostOfInactionGraph from "./subComponents/costOfInactionGraph";
import CostOfInactionTable from "./subComponents/costOfInactionTable";
import CompanySelector from "./subComponents/companySelctor";
import Loader from "../../../components/common/Loader";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { resetEPC } from "../../../store/EPC/actions";
import { getDecodeAuthDetails } from "../../../helpers";

const ClientDashboard = (props) => {
  let navigate = useNavigate();
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;
  let decoded = getDecodeAuthDetails();

  const handleOnIdle = (event) => {
    navigate("/");
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const [isEquivalentCheck, setIsEquivalentCheck] = useState(true);
  const [forecastGraphData, setForecastGraphData] = useState(null);
  const [defaultSelectedYear, setDefaultSelectedYear] = useState(10);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isCompanySwitcherShow, setIsCompanySwitcherShow] = useState(false);
  const [selectedCompanyID, setSelectedCompayID] = useState(null);
  const [selectedCompanyType, setSelectedCompayType] = useState("");
  const [selectedCompanyName, setSelectedCompayName] = useState("");

  useEffect(() => {
    if (!props.isCompanyListSuccess) {
      props.getCompanies();
      props.getBusinessAccounts(decoded.ClientBussinessAccountId);
    }
    props.resetEPC();
  }, [props.isCompanyListSuccess]);

  useEffect(() => {
    if (props.companyList) {
      let param = {
        isAllCompanies: false,
        companyId: props.companyList.data.parentCompany.id,
      };
      props.getClientDashboard(param);
      props.getForecashData(10, param);
      props.getCategoryAndSummary();
      setSelectedCompayID(props.companyList.data.parentCompany.id);
    }
  }, [props.companyList]);

  const handleEquivalentCheck = (e) => {
    setIsEquivalentCheck(e.target.checked);
  };

  useEffect(() => {
    if (props.clientDashboard && props.clientDashboard.data) {
      setIsEquivalentCheck(props.clientDashboard.data.isEquivalentTo);
      setIsCompanySwitcherShow(props.clientDashboard.data.isParentCompany);
    }
    setShowOverlay(false);
  }, [props.clientDashboard]);

  useEffect(() => {
    if (props.forecastData && props.forecastData.data) {
      setForecastGraphData(props.forecastData.data);
    }
  }, [props.forecastData]);

  const handleParentSelect = () => {
    setShowOverlay(true);
  };

  return (
    <React.Fragment>
      <div className={`page-content client-dashboard`}>
        <div className={`add-overlay ${showOverlay ? "show" : ""}`}></div>
        <Helmet>
          <title>{"SORT - Audit Summary"}</title>
        </Helmet>
        <Container fluid>
          <Row>
            <Col lg={8} md={8} xs={12}>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="description-area no-border">
                    {props.clientDashboard &&
                      props.clientDashboard.data.universalSettings
                        .welcomeMessage}
                  </div>
                </Col>
              </Row>
            </Col>
            {isCompanySwitcherShow && (
              <Col lg={4} md={4} xs={12}>
                <Form.Label className='sort-form-lable client-dropdown'>
                  View Dashboard for
                </Form.Label>
                <CompanySelector
                  handleParentSelect={handleParentSelect}
                  defaultSelectedYear={defaultSelectedYear}
                  setSelectedCompayID={setSelectedCompayID}
                  selectedCompanyType={selectedCompanyType}
                  setSelectedCompayType={setSelectedCompayType}
                  selectedCompanyName={selectedCompanyName}
                  setSelectedCompayName={setSelectedCompayName}
                />
              </Col>
            )}
          </Row>
          <hr />
          {props.clientDashboard && props.clientDashboard.data ? (
            <Row>
              <Row>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <p className="dashboard-section-lable">
                    {
                      props.clientDashboard.data.universalSettings
                        .UntappedSavingsTitle
                    }
                  </p>
                </Col>
                {/* <Col lg={6} md={6} sm={6} xs={6}>
                  <div className="untapped-toggle-section">
                    <Form.Label className={`sort-form-lable`}>
                      Display 'Equivalent to' section?
                    </Form.Label>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="parentCompany"
                        id="toggle1"
                        onChange={handleEquivalentCheck}
                        checked={isEquivalentCheck}
                      />
                      <label className="label1" htmlFor="toggle1">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Col> */}
              </Row>
              <Row className="mt-4 untapped-large-view">
                <Col lg={3} md={3} sm={3} xs={12}>
                  <UntappedSavingsCard
                    untappedSavingsData={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialCarbonSavings
                    }
                    subTitleType={"Tonnes Per Year"}
                    isEquivalentCheck={false} //isEquivalentCheck
                    headerTitle={
                      props.clientDashboard.data.universalSettings
                        .potentialCarbonSavingsTitle
                    }
                    prefixForVal={""}
                    totalTonnes={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialCarbonSavings.carbonSavings
                    }
                  />
                </Col>
                <Col lg={3} md={3} sm={3} xs={12}>
                  <UntappedSavingsCard
                    untappedSavingsData={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialOpportunities
                    }
                    subTitleType={"Per Year"}
                    isEquivalentCheck={false} //isEquivalentCheck
                    headerTitle={
                      props.clientDashboard.data.universalSettings
                        .potentialPAndLImprovementOpportunitiesTitle
                    }
                    prefixForVal={"£"}
                    totalTonnes={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialOpportunities.perYear
                    }
                  />
                </Col>
                <Col lg={3} md={3} sm={3} xs={12}>
                  <UntappedSavingsCard
                    untappedSavingsData={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialEnergySaving
                    }
                    subTitleType={"kWh Per Year"}
                    isEquivalentCheck={false} //isEquivalentCheck
                    headerTitle={
                      props.clientDashboard.data.universalSettings
                        .potentialEnergySavingsTitle
                    }
                    prefixForVal={""}
                    totalTonnes={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialEnergySaving.kwHPerYear
                    }
                  />
                </Col>
                <Col lg={3} md={3} sm={3} xs={12}>
                  <UntappedSavingsCard
                    untappedSavingsData={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialWaterSavings
                    }
                    subTitleType={"Meters³ Per Year"}
                    isEquivalentCheck={false} //isEquivalentCheck
                    headerTitle={
                      props.clientDashboard.data.universalSettings
                        .potentialWaterSavingsTitle
                    }
                    prefixForVal={""}
                    totalTonnes={
                      props.clientDashboard.data.untappedSavingsGraphs
                        .potentialWaterSavings.literPerYear
                    }
                  />
                </Col>
              </Row>
              <Row className="untapped-mobile-view m-0">
                <Carousel interval={null}>
                  <Carousel.Item>
                    <UntappedSavingsCard
                      untappedSavingsData={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialCarbonSavings
                      }
                      subTitleType={"Tonnes Per Year"}
                      isEquivalentCheck={false} //isEquivalentCheck
                      headerTitle={
                        props.clientDashboard.data.universalSettings
                          .potentialCarbonSavingsTitle
                      }
                      prefixForVal={""}
                      totalTonnes={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialCarbonSavings.carbonSavings
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <UntappedSavingsCard
                      untappedSavingsData={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialEnergySaving
                      }
                      subTitleType={"Per Year"}
                      isEquivalentCheck={false} //isEquivalentCheck
                      headerTitle={
                        props.clientDashboard.data.universalSettings
                          .potentialPAndLImprovementOpportunitiesTitle
                      }
                      prefixForVal={"£"}
                      totalTonnes={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialOpportunities.perYear
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <UntappedSavingsCard
                      untappedSavingsData={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialOpportunities
                      }
                      subTitleType={"kWh Per Year"}
                      isEquivalentCheck={false} //isEquivalentCheck
                      headerTitle={
                        props.clientDashboard.data.universalSettings
                          .potentialEnergySavingsTitle
                      }
                      prefixForVal={""}
                      totalTonnes={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialEnergySaving.kwHPerYear
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <UntappedSavingsCard
                      untappedSavingsData={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialWaterSavings
                      }
                      subTitleType={"Meters³ Per Year"}
                      isEquivalentCheck={false} //isEquivalentCheck
                      headerTitle={
                        props.clientDashboard.data.universalSettings
                          .potentialWaterSavingsTitle
                      }
                      prefixForVal={""}
                      totalTonnes={
                        props.clientDashboard.data.untappedSavingsGraphs
                          .potentialWaterSavings.literPerYear
                      }
                    />
                  </Carousel.Item>
                </Carousel>
              </Row>
              <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                <CarbonFootprintCalculator
                  carbonFootprintData={
                    props.clientDashboard.data.defaultClientSettings
                  }
                  carbonSavingsonnes={
                    props.clientDashboard.data.untappedSavingsGraphs
                      .potentialCarbonSavings.carbonSavings
                  }
                  universalSettings={
                    props.clientDashboard.data.universalSettings
                  }
                />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                <ForecastCashFlowSection
                  sectionTitle={props.clientDashboard.data.universalSettings}
                  sectionDes={props.clientDashboard.data.defaultClientSettings}
                  setDefaultSelectedYear={setDefaultSelectedYear}
                  defaultSelectedYear={defaultSelectedYear}
                  selectedCompanyID={selectedCompanyID}
                />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                <ForecastGraph graphData={forecastGraphData} />
              </Col>
              <Col lg={6} md={6} sm={6} xs={12} className="mt-5">
                <p className="dashboard-section-lable">
                  {
                    props.clientDashboard.data.universalSettings
                      .ProjectedCostofInactionTitle
                  }
                </p>
              </Col>

              <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                <Row className="m-0">
                  <Col lg={7} md={7} sm={7} xs={12}>
                    <CostOfInactionGraph
                      graphData1={
                        props.clientDashboard.data.costOfInactionGraphLine1
                      }
                      graphData2={
                        props.clientDashboard.data.costOfInactionGraphLine2
                      }
                    />
                  </Col>
                  <Col lg={5} md={5} sm={5} xs={12}>
                    <CostOfInactionTable
                      tableData={
                        props.clientDashboard.data.projectedCostOfInactionTable
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Row className="mt-5">
                <Col lg={6} md={6} sm={6} xs={12}>
                  <p className="dashboard-section-lable">
                    {
                      props.clientDashboard.data.universalSettings
                        .CapitalPlanning
                    }
                  </p>
                </Col>
              </Row>
              <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                <Row className="m-0">
                  <Col lg={5} md={5} sm={5} xs={12}>
                    <CapitalPlaningGraph
                      data={props.clientDashboard.data.capitalPlanningGraph}
                    />
                  </Col>
                  <Col lg={7} md={7} sm={7} xs={12}>
                    <CapitalPlaningTable
                      tableData={
                        props.clientDashboard.data.capitalPlanningTable
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Loader data={"page-spinner"} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
ClientDashboard.propTypes = {
  getGlobalSettings: PropTypes.func,
  getMainProductCategories: PropTypes.func,
  getClientDashboard: PropTypes.func,
  isSuccessClientDashboardData: PropTypes.any,
  clientDashboard: PropTypes.any,
  getForecashData: PropTypes.func,
  forecastData: PropTypes.any,
  getCategoryAndSummary: PropTypes.func,
  getCompanies: PropTypes.func,
  companyList: PropTypes.any,
  isCompanyListSuccess: PropTypes.any,
  resetEPC: PropTypes.func,
  getBusinessAccounts: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getMainProductCategories: state.Audit.getMainProductCategories,
    getClientDashboard: state.ClientDashboard.getClientDashboard,
    isSuccessClientDashboardData:
      state.ClientDashboard.isSuccessClientDashboardData,
    clientDashboard: state.ClientDashboard.clientDashboard,
    getForecashData: state.ClientDashboard.getForecashData,
    forecastData: state.ClientDashboard.forecastData,
    getCategoryAndSummary: state.ClientDashboard.getCategoryAndSummary,
    getCompanies: state.ClientDashboard.getCompanies,
    companyList: state.ClientDashboard.companyList,
    isCompanyListSuccess: state.ClientDashboard.isCompanyListSuccess,
    resetEPC: state.EPCDetails.resetEPC,
    getBusinessAccounts: state.GPDashboard.getBusinessAccounts,
  };
};

export default connect(mapStateToProps, {
  getMainProductCategories,
  getClientDashboard,
  getForecashData,
  getCategoryAndSummary,
  getCompanies,
  resetEPC,
  getBusinessAccounts,
})(ClientDashboard);
