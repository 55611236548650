import React from "react";
import { Form } from "react-bootstrap";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import CustomReactTable from "../../../../components/common/reactTable";

const BulkUpdate = (props) => {
  const {
    updatePublishReport,
    setUpdatePublishReport,
    updateDraftReport,
    setUpdateDraftReport,
    setUpdateCarbonConversion,
    dataOpportunity,
  } = props;

  const handlePublishReport = (e) => {
    setUpdatePublishReport(e.target.checked);
    if (e.target.checked) {
      setUpdateCarbonConversion(false);
    }
  };

  const handleDraftReport = (e) => {
    setUpdateDraftReport(e.target.checked);
    if (e.target.checked) {
      setUpdateCarbonConversion(false);
    }
  };

  function publishReportFormatter() {
    return (
      <Form.Check
        type="checkbox"
        label="In Published Reports"
        className="updateCheckbox"
        checked={updatePublishReport}
        onChange={handlePublishReport}
      />
    );
  }

  function draftReportFormatter() {
    return (
      <Form.Check
        type="checkbox"
        label="In Draft Reports"
        className="updateCheckbox"
        checked={updateDraftReport}
        onChange={handleDraftReport}
      />
    );
  }

  const columns = [
    {
      header: "Category",
      accessorKey: "productMainCategory",
    },
    {
      header: "In Published Reports",
      accessorKey: "inPublishedReport",
      cell: (info) => publishReportFormatter(),
    },
    {
      header: "In Draft Reports",
      accessorKey: "inDraffReport",
      cell: (info) => draftReportFormatter(info.row.original),
    },
  ];

  const table = useReactTable({
    data: dataOpportunity,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <React.Fragment>
      {" "}
      <CustomReactTable
        table={table}
        tableClass={"table-bulk-update-account"}
      />
    </React.Fragment>
  );
};

export default BulkUpdate;
