import React from "react";
import { Container,Form } from "react-bootstrap";
import ErrorValidation from "../../../../assets/images/icons/ErrorValidation.svg";
import InlineErroMsg from "../../../../components/common/inlineErrorMsg";
import NumberFormat from "react-number-format";

const Repayment = (props) => {
  const {
    term,
    setTerm,
    rate,
    setRate,
    termRequired,
    setTermRequired,
    termPositive,
    setTermPositive,
    termValidation,
    setTermValidation,
    termMessage,
    setTermMessage,
    rateRequired,
    setRateRequired,
    ratePositive,
    setRatePositive,
    setCreateCancel,
  } = props;

  const handleTerm = (e) => {
    setTerm(e.target.value);
    setCreateCancel(true);
    setTermValidation(false);
    setTermMessage("");

    if (e.target.value.length >= 1) {
      setTermRequired(false);

      if (parseFloat(e.target.value) !== 0) {
        setTermPositive(false);
      } else {
        setTermPositive(true);
      }
    }
  };

  const handleRate = (e) => {
    setRate(e.target.value);
    setCreateCancel(true);

    if (e.target.value.length >= 1) {
      setRateRequired(false);

      if (parseFloat(e.target.value) >= 0) {
        setRatePositive(false);
      } else {
        setRatePositive(true);
      }
    }
  };

  return (
    <React.Fragment>
      {" "}
      <Container>
        <Form>
          <Form.Group controlId="formBasicRepaymentTerm">
            <Form.Label className="update-form-label">
              Repayment Term*
            </Form.Label>
            <div className="sort-form-wrapper">
              <div className="form-group sort-form-group">
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  value={term}
                  prefix=""
                  displayType="input"
                  decimalScale={0}
                  type="text"
                  allowNegative={false}
                  name="term"
                  className={`sort-form-input form-control ${
                    termRequired || termPositive || termValidation
                      ? "errorField"
                      : ""
                  }`}
                  onChange={handleTerm}
                />
                {(termRequired || termPositive || termValidation) && (
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={20}
                    height={20}
                    className="errorImg"
                  />
                )}
              </div>
              <InlineErroMsg
                errorType={termRequired}
                errorMsg={"Please enter a repayment term."}
              />
              <InlineErroMsg
                errorType={termPositive}
                errorMsg={
                  "Please enter a value which is 1 or more for the term."
                }
              />
              <InlineErroMsg
                errorType={termValidation}
                errorMsg={termMessage}
              />
            </div>
            <br />
          </Form.Group>
          <Form.Group controlId="formBasicRepaymentRate">
            <Form.Label className="update-form-label">
              Standard Rate/£1000.00*
            </Form.Label>
            <div className="sort-form-wrapper">
              <div className="form-group sort-form-group">
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  value={rate}
                  prefix=""
                  decimalSeparator="."
                  displayType="input"
                  type="text"                  
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}  
                  name="rate"
                  className={`sort-form-input form-control ${
                    rateRequired || ratePositive ? "errorField" : ""
                  }`}
                  onChange={handleRate}
                />
                {(rateRequired || ratePositive) && (
                  <img
                    src={ErrorValidation}
                    alt="error"
                    width={20}
                    height={20}
                    className="errorImg"
                  />
                )}
              </div>
              <InlineErroMsg
                errorType={rateRequired}
                errorMsg={"Please enter the rate for the repayment term."}
              />
              <InlineErroMsg
                errorType={ratePositive}
                errorMsg={
                  "Please enter a value which is 0 or more for the rate."
                }
              />
            </div>
            <br />
          </Form.Group>
        </Form>
      </Container>
    </React.Fragment>
  );
};

export default Repayment;
