import {
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_SUCCESS,
  CREATE_USER_ROLE_ERROR,
  GET_PERMISSION,
  PERMISSION_SUCCESS,
  PERMISSION_ERROR,
  EDIT_USER_ROLE,
  EDIT_USER_ROLE_SUCCESS,
  EDIT_USER_ROLE_ERROR,
  GET_EDIT_USER_DETAILS,
  GET_EDIT_USER_DETAILS_SUCCESS,
  GET_EDIT_USER_DETAILS_ERROR,
  HADLE_EDIT_MODE,
  GET_ROLES,
  ROLES_SUCCESS,
  ROLES_ERROR,
  RE_CHECK,
  UPDATE_PERMISSION_STATUS,
  UPDATE_PERMISSION_SUB_STATUS,
  HANDLE_CATEGORY_ONLY,
  UPDATE_GET_PERMISSIONS,
  RESET_TO_DEFUALT_STATE
} from "./actionType"

export const createNewUserRole = (role) => {
  return {
    type: CREATE_USER_ROLE,
    payload: { role },
  }
}

export const editUserRole = (role) => {
  return {
    type: EDIT_USER_ROLE,
    payload: { role },
  }
}

export const createRoleSuccess = (successMsg) => {
  return {
    type: CREATE_USER_ROLE_SUCCESS,
    payload: { successMsg },
  }
}

export const createRoleError = (errorMsg) => {
  return {
    type: CREATE_USER_ROLE_ERROR,
    payload: { errorMsg },
  }
}

export const editRoleSuccess = (editSuccess) => {
  return {
    type: EDIT_USER_ROLE_SUCCESS,
    payload: { editSuccess },
  }
}

export const editRoleError = (errorMsg) => {
  return {
    type: EDIT_USER_ROLE_ERROR,
    payload: { errorMsg },
  }
}

export const getEditUserDetails = (id) => {
  return {
    type: GET_EDIT_USER_DETAILS,
    payload: { id },
  }
}
export const getEditUserDetailsSuccess = (data) => {
  return {
    type: GET_EDIT_USER_DETAILS_SUCCESS,
    payload: { data },
  }
}
export const getEditUserDetailsError = () => {
  return {
    type: GET_EDIT_USER_DETAILS_ERROR,
    payload: {},
  }
}
export const handleEditMode = () => {
  return {
    type: HADLE_EDIT_MODE,
    payload: {},
  }
}
export const getPermission = () => {
  return {
    type: GET_PERMISSION,
    payload: {},
  }
}
export const permissionSuccess = (data) => {
  return {
    type: PERMISSION_SUCCESS,
    payload: { data },
  }
}

export const permissionError = (error) => {
  return {
    type: PERMISSION_ERROR,
    payload: { error },
  }
}
export const getRoles = () => {
  return {
    type: GET_ROLES,
    payload: {},
  }
}
export const rolesSuccess = (data) => {
  return {
    type: ROLES_SUCCESS,
    payload: { data },
  }
}

export const rolesError = (error) => {
  return {
    type: ROLES_ERROR,
    payload: { error },
  }
}

export const reCheck = () => {
  return {
    type: RE_CHECK,
    payload: {},
  }
}
export const updatePermissionStatus = (id, status) => {
  return {
    type: UPDATE_PERMISSION_STATUS,
    payload: { id, status },
  }
}

export const updatePermissionSubStatus = (id, status, parentID) => {
  return {
    type: UPDATE_PERMISSION_SUB_STATUS,
    payload: { id, status, parentID },
  }
}

export const handleCategoryOnly = (parentID, status) => {
  return {
    type: HANDLE_CATEGORY_ONLY,
    payload: { parentID, status },
  }
}

export const updateGetPermissions = (IDArray, total) => {
  return {
    type: UPDATE_GET_PERMISSIONS,
    payload: { IDArray, total },
  }
}

export const resetToDefaultState = () => {
  return {
    type: RESET_TO_DEFUALT_STATE,
    payload: {},
  }
}