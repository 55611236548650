import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Edit from "../../../assets/images/icons/Edit.svg";
import { Row, Button, Card } from "react-bootstrap";
import Loader from "../../../components/common/Loader";
import DashboardProject from "../../../assets/images/icons/DashboardProject.svg";
import Energy_1 from "../../../assets/images/icons/iconogrphy/Energy_1.svg";
import Lighting_1 from "../../../assets/images/icons/iconogrphy/Lighting_1.svg";
import Water_1 from "../../../assets/images/icons/iconogrphy/Water_1.svg";
import Gas_1 from "../../../assets/images/icons/iconogrphy/Gas_1.svg";
import Other_1 from "../../../assets/images/icons/iconogrphy/Other_1.svg";
import CustomReactTable from "../../../components/common/reactTable";

const ProjectDashboard = (props) => {
  const { backViewProjects, showProjectEditPage, permissions } = props;
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState(() => [...projects]);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    if (props.adminAnalystDashboard) {
      setProjects(
        props.adminAnalystDashboard.data &&
          props.adminAnalystDashboard.data.projectDashboard
      );
      if (props.adminAnalystDashboard.data) {
        setData(() => [...props.adminAnalystDashboard.data.projectDashboard]);
      }
    }
  }, [props.adminAnalystDashboard]);

  const getRowData = (row) => {
    showProjectEditPage(row);
  };

  const statusColumnFormatter = (row) => {
    if (row === 0) {
      return `${"Inactive"}`;
    } else if (row === 1) {
      return `${"Active"}`;
    } else if (row === 2) {
      return `${"Requested"}`;
    } else {
      return `${"Completed"}`;
    }
  };

  const productFormatter = (row) => {
    if (row === "Lighting") {
      return <img src={Lighting_1} alt={""} width={20} height={20} />;
    } else if (row === "Electricity") {
      return <img src={Energy_1} alt={""} width={20} height={20} />;
    } else if (row === "Water") {
      return <img src={Water_1} alt={""} width={20} height={20} />;
    } else if (row === "Gas") {
      return <img src={Gas_1} alt={""} width={20} height={20} />;
    } else if (row === "Other") {
      return <img src={Other_1} alt={""} width={20} height={20} />;
    }
  };

  const columns = [
    {
      header: "Project Name",
      accessorKey: "name",
      sortingFn: "alphanumeric",
      cell: (info) => (
        <span
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        >
          {info.renderValue()}
        </span>
      ),
    },
    {
      header: "Client",
      accessorKey: "clientBusinessAccountName",
      sortingFn: "alphanumeric",
    },
    {
      header: "Site",
      accessorKey: "site",
      sortingFn: "alphanumeric",
    },
    {
      header: "Category",
      accessorKey: "productCategory",
      cell: (info) => productFormatter(info.renderValue()),
      enableSorting: false,
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (info) => statusColumnFormatter(info.renderValue()),
      sortingFn: "alphanumeric",
    },
    {
      header: "",
      accessorKey: "edit",
      enableSorting: false,
      cell: (info) => (
        <img
          className="edit-img"
          src={Edit}
          alt=""
          width={40}
          height={40}
          onClick={() => {
            getRowData(info.row.original.id);
          }}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <React.Fragment>
      <Card className="dashboard-card p-0">
        <Card.Header className="report-header-audit">
          <img src={DashboardProject} alt="audit" width={20} height={20} />
          &nbsp;&nbsp;
          <span className="dashboard-heading">Recent Projects</span>
          <span style={{ float: "right" }}>
            <Button
              className="audit-sort-btn-link"
              onClick={() => backViewProjects()}
            >
              View Projects
            </Button>
          </span>
        </Card.Header>
      </Card>
      <br />
      <div className="p-0">
        {projects ? (
          <CustomReactTable
            table={table}
            tableClass={"dashboard-project-table"}
          />
        ) : (
          <Loader data={"page-spinner"} />
        )}
      </div>
    </React.Fragment>
  );
};

ProjectDashboard.propTypes = {
  adminAnalystDashboard: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    adminAnalystDashboard: state.Dashboard.adminAnalystDashboard,
  };
};

export default connect(mapStateToProps, {})(ProjectDashboard);
