import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import InfoTooltip from "../../../components/common/tooltip";
import { Helmet } from "react-helmet-async";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import InlineErroMsg from "../../../components/common/inlineErrorMsg";
import {
  postCarbonRoadmapSettings,
  getCarbonRoadmapSettings,
} from "../../../store/Settings/actions";
import { NIL as NIL_UUID } from "uuid";
import { getDecodeAuthDetails } from "../../../helpers";
import { useIdleTimer } from "react-idle-timer";

const CarbonRoadMapSettings = (props) => {
  const userIdleTimeout = process.env.REACT_APP_USER_IDLE_TIME;

  const handleOnIdle = (event) => {
    window.location.href = "/login";
    window.location.reload();
    localStorage.clear();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userIdleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const rx_live =
    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  const SettingsType = {
    SECTIONTITLE: "Section title",
    SECTIONDESCRIPTION: "Description under the section title",
    READMOREURL: "'Read More' URL",
  };

  const SettingsCategory = {
    BEFOREUPLOAD: "Before Carbon Roadmaps are uploaded",
    AFTERUPLOAD: "After Carbon Roadmaps are uploaded",
  };

  Object.freeze(SettingsType);
  Object.freeze(SettingsCategory);

  const [idBeforeTitle, setIdBeforeTitle] = useState(NIL_UUID);
  const [idBeforeDescription, setIdBeforeDescription] = useState(NIL_UUID);
  const [idBeforeUrl, setIdBeforeUrl] = useState(NIL_UUID);
  const [idAfterTitle, setIdAfterTitle] = useState(NIL_UUID);
  const [idAfterDescription, setIdAfterDescription] = useState(NIL_UUID);
  const [carbonTitleBeforeUpload, setCarbonTitleBeforeUpload] = useState("");
  const [carbonDesBeforeUpload, setCarbonDesBeforeUpload] = useState("");
  const [readUrl, setReadUrl] = useState("");
  const [carbonTitleAfterUpload, setCarbonTitleAfterUpload] = useState("");
  const [carbonDesAfterUpload, setCarbonDesAfterUpload] = useState("");

  const [carbonTitleBeforeUploadRequired, setCarbonTitleBeforeUploadRequired] =
    useState(false);
  const [carbonDesBeforeUploadRequired, setCarbonDesBeforeUploadRequired] =
    useState(false);
  const [readUrlRequired, setReadUrlRequired] = useState(false);
  const [urlValidation, setUrlValidation] = useState(false);
  const [carbonTitleAfterUploadRequired, setCarbonTitleAfterUploadRequired] =
    useState(false);
  const [carbonDesAfterUploadRequired, setCarbonDesAfterUploadRequired] =
    useState(false);

  const [showAlertCarbonSettings, setShowAlertCarbonSettings] = useState(false);
  const [isSubmitEnableCarbonSettings, setIsSubmitEnableCarbonSettings] =
    useState(false);
  const [errorCarbonSettings, setErrorCarbonSettings] = useState([]);
  const [carbonSettingsSuccess, setCarbonSettingsSuccess] = useState(false);
  const [carbonSettingsError, setCarbonSettingsError] = useState(false);

  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    props.getCarbonRoadmapSettings();
    let decodedUserData = getDecodeAuthDetails();
    if (
      decodedUserData.Permission.includes(
        "PERMISSIONS.GLOBALSETTING.CARBONROADMAPSETTINGSCREATE"
      )
    ) {
      setIsSubmitEnableCarbonSettings(true);
    } else {
      setIsSubmitEnableCarbonSettings(false);
    }
  }, []);

  useEffect(() => {
    if (carbonSettingsSuccess) {
      props.getCarbonRoadmapSettings();
    }
  }, [carbonSettingsSuccess]);

  useEffect(() => {
    if (props.carbonRoadmapSettingsDetails) {
      let carbonDetails = props.carbonRoadmapSettingsDetails.data;
      let BeforeTitleObject = carbonDetails.find(
        (p) =>
          p.type === SettingsType.SECTIONTITLE &&
          p.category === SettingsCategory.BEFOREUPLOAD
      );
      let BeforeDesObject = carbonDetails.find(
        (p) =>
          p.type === SettingsType.SECTIONDESCRIPTION &&
          p.category === SettingsCategory.BEFOREUPLOAD
      );
      let BeforeUrlObject = carbonDetails.find(
        (p) =>
          p.type === SettingsType.READMOREURL &&
          p.category === SettingsCategory.BEFOREUPLOAD
      );
      let AfterTitleObject = carbonDetails.find(
        (p) =>
          p.type === SettingsType.SECTIONTITLE &&
          p.category === SettingsCategory.AFTERUPLOAD
      );
      let AfterDesObject = carbonDetails.find(
        (p) =>
          p.type === SettingsType.SECTIONDESCRIPTION &&
          p.category === SettingsCategory.AFTERUPLOAD
      );
      if (carbonDetails.length > 0) {
        setIdBeforeTitle(BeforeTitleObject.id);
        setCarbonTitleBeforeUpload(BeforeTitleObject.value);
        setIdBeforeDescription(BeforeDesObject.id);
        setCarbonDesBeforeUpload(BeforeDesObject.value);
        setIdBeforeUrl(BeforeUrlObject.id);
        setReadUrl(BeforeUrlObject.value);
        setIdAfterTitle(AfterTitleObject.id);
        setCarbonTitleAfterUpload(AfterTitleObject.value);
        setIdAfterDescription(AfterDesObject.id);
        setCarbonDesAfterUpload(AfterDesObject.value);
      } else {
        setIdBeforeTitle(NIL_UUID);
        setCarbonTitleBeforeUpload("");
        setIdBeforeDescription(NIL_UUID);
        setCarbonDesBeforeUpload("");
        setIdBeforeUrl(NIL_UUID);
        setReadUrl("");
        setIdAfterTitle(NIL_UUID);
        setCarbonTitleAfterUpload("");
        setIdAfterDescription(NIL_UUID);
        setCarbonDesAfterUpload("");
      }
    }
  }, [props.carbonRoadmapSettingsDetails]);

  const handleCarbonTitleBeforeUpload = (e) => {
    setCarbonTitleBeforeUpload(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonTitleBeforeUpload") {
        setCarbonTitleBeforeUploadRequired(false);
      }
    } else {
      if (e.target.name === "carbonTitleBeforeUpload") {
        setCarbonTitleBeforeUploadRequired(true);
      }
    }
  };

  const handleCarbonDesBeforeUpload = (e) => {
    setCarbonDesBeforeUpload(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonDesBeforeUpload") {
        setCarbonDesBeforeUploadRequired(false);
      }
    } else {
      if (e.target.name === "carbonDesBeforeUpload") {
        setCarbonDesBeforeUploadRequired(true);
      }
    }
  };

  const handleReadUrl = (e) => {
    setReadUrl(e.target.value.trimStart());
    setUrlValidation(false);

    if (e.target.value.length >= 1) {
      setReadUrlRequired(false);
      setUrlValidation(true);
      if (rx_live.test(e.target.value)) {
        setUrlValidation(false);
      }
    } else {
      setReadUrlRequired(true);
    }
  };

  const handleCarbonTitleAfterUpload = (e) => {
    setCarbonTitleAfterUpload(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonTitleAfterUpload") {
        setCarbonTitleAfterUploadRequired(false);
      }
    } else {
      if (e.target.name === "carbonTitleAfterUpload") {
        setCarbonTitleAfterUploadRequired(true);
      }
    }
  };

  const handleCarbonDesAfterUpload = (e) => {
    setCarbonDesAfterUpload(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonDesAfterUpload") {
        setCarbonDesAfterUploadRequired(false);
      }
    } else {
      if (e.target.name === "carbonDesAfterUpload") {
        setCarbonDesAfterUploadRequired(true);
      }
    }
  };

  const formValidation = () => {
    let errors = [];

    if (carbonTitleBeforeUpload === "") {
      setCarbonTitleBeforeUploadRequired(true);
      errors.push(1);
    } else {
      setCarbonTitleBeforeUploadRequired(false);
    }

    if (carbonDesBeforeUpload === "") {
      setCarbonDesBeforeUploadRequired(true);
      errors.push(1);
    } else {
      setCarbonDesBeforeUploadRequired(false);
    }

    if (readUrl === "") {
      setReadUrlRequired(true);
      setUrlValidation(false);
      errors.push(1);
    } else if (!rx_live.test(readUrl)) {
      setUrlValidation(true);
      setReadUrlRequired(false);
      errors.push(1);
    } else {
      setReadUrlRequired(false);
      setUrlValidation(false);
    }

    if (carbonTitleAfterUpload === "") {
      setCarbonTitleAfterUploadRequired(true);
      errors.push(1);
    } else {
      setCarbonTitleAfterUploadRequired(false);
    }

    if (carbonDesAfterUpload === "") {
      setCarbonDesAfterUploadRequired(true);
      errors.push(1);
    } else {
      setCarbonDesAfterUploadRequired(false);
    }

    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();
    let valid = formValidation();
    if (valid.length === 0) {
      setIsSubmitEnableCarbonSettings(true);
      setAlertMessage(true);
      let carbonRoadmapSettingsObj = {
        settings: [
          {
            id: idBeforeTitle,
            type: SettingsType.SECTIONTITLE,
            value: carbonTitleBeforeUpload.trim(),
            category: SettingsCategory.BEFOREUPLOAD,
          },
          {
            id: idBeforeDescription,
            type: SettingsType.SECTIONDESCRIPTION,
            value: carbonDesBeforeUpload.trim(),
            category: SettingsCategory.BEFOREUPLOAD,
          },
          {
            id: idBeforeUrl,
            type: SettingsType.READMOREURL,
            value: readUrl,
            category: SettingsCategory.BEFOREUPLOAD,
          },
          {
            id: idAfterTitle,
            type: SettingsType.SECTIONTITLE,
            value: carbonTitleAfterUpload.trim(),
            category: SettingsCategory.AFTERUPLOAD,
          },
          {
            id: idAfterDescription,
            type: SettingsType.SECTIONDESCRIPTION,
            value: carbonDesAfterUpload.trim(),
            category: SettingsCategory.AFTERUPLOAD,
          },
        ],
      };
      props.postCarbonRoadmapSettings(carbonRoadmapSettingsObj);
      window.setTimeout(() => {
        setAlertMessage(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (props.isCreateCarbonRoadmapSettingsSuccess) {
      setCarbonSettingsSuccess(true);
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setCarbonSettingsSuccess(false);
      }, 3000);
      setIsSubmitEnableCarbonSettings(true);
    }
  }, [props.isCreateCarbonRoadmapSettingsSuccess]);

  useEffect(() => {
    if (props.isFailedCarbonRoadmapSettings) {
      setIsSubmitEnableCarbonSettings(true);
      setCarbonSettingsError(true);

      window.scroll(0, 0);
      window.setTimeout(() => {
        setCarbonSettingsError(false);
      }, 5000);
    }
  }, [props.isFailedCarbonRoadmapSettings]);

  useEffect(() => {
    if (props.errorCarbonSettings && props.errorCarbonSettings.error) {
      setShowAlertCarbonSettings(true);
      setErrorCarbonSettings(props.errorCarbonSettings.error);
    }
    window.setTimeout(() => {
      setShowAlertCarbonSettings(false);
    }, 3000);
  }, [props.errorCarbonSettings]);

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Helmet>
          <title>{"SORT - Carbon Roadmap Settings"}</title>
        </Helmet>
        {carbonSettingsSuccess && alertMessage && (
          <Row className="px-2">
            <Alert color="success">
              <img src={SuccessfulValidation} alt="success" width={30} />
              Carbon roadmap settings values updated successfully
            </Alert>
          </Row>
        )}
        {carbonSettingsError && showAlertCarbonSettings && alertMessage && (
          <Row className="px-2">
            <Alert color="danger">
              <img src={ErrorValidation} alt="error" width={30} />
              Carbon roadmap settings values could not be updated
            </Alert>
          </Row>
        )}
        {carbonSettingsError && alertMessage && (
          <Row className="px-2">
            {errorCarbonSettings.map((item) => {
              return (
                <Alert key={item} color="danger">
                  <img src={ErrorValidation} alt="error" width={30} />
                  {item}
                </Alert>
              );
            })}
          </Row>
        )}
        {showAlertCarbonSettings && alertMessage && (
          <Row className="px-2">
            <Alert color="danger">
              <img src={ErrorValidation} alt="error" width={30} />
              {errorCarbonSettings}
            </Alert>
          </Row>
        )}
        <Form className="py-1">
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Carbon Roadmap Main Section &nbsp;
                <InfoTooltip
                  text={"Carbon Roadmap Main Section"}
                  id="carbonMainSection"
                />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                <div className="setting-right-block">
                  {SettingsCategory.BEFOREUPLOAD}
                </div>
                {SettingsType.SECTIONTITLE}*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="carbonTitleBeforeUpload"
                    id="carbonTitleBeforeUpload"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonTitleBeforeUploadRequired ? "errorField" : ""
                    }`}
                    value={carbonTitleBeforeUpload}
                    onChange={handleCarbonTitleBeforeUpload}
                  />
                  {carbonTitleBeforeUploadRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={carbonTitleBeforeUploadRequired}
                  errorMsg={
                    "Please enter a value for carbon Title Before Upload"
                  }
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                {SettingsType.SECTIONDESCRIPTION}*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="carbonDesBeforeUpload"
                    id="carbonDesBeforeUpload"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonDesBeforeUploadRequired ? "errorField" : ""
                    }`}
                    value={carbonDesBeforeUpload}
                    onChange={handleCarbonDesBeforeUpload}
                  />
                  {carbonDesBeforeUploadRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={carbonDesBeforeUploadRequired}
                  errorMsg={
                    "Please enter a value for carbon Description Before Upload"
                  }
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                {SettingsType.READMOREURL}*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="readUrl"
                    id="readUrl"
                    type="text"
                    className={`sort-form-input form-control ${
                      readUrlRequired || urlValidation ? "errorField" : ""
                    }`}
                    value={readUrl}
                    onChange={handleReadUrl}
                  />
                  {(readUrlRequired || urlValidation) && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={readUrlRequired}
                  errorMsg={"Please enter a value for read Url"}
                />
                <InlineErroMsg
                  errorType={urlValidation}
                  errorMsg={"Please enter a valid url."}
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                <div className="setting-right-block">
                  {SettingsCategory.AFTERUPLOAD}
                </div>
                {SettingsType.SECTIONTITLE}*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <input
                    name="carbonTitleAfterUpload"
                    id="carbonTitleAfterUpload"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonTitleAfterUploadRequired ? "errorField" : ""
                    }`}
                    value={carbonTitleAfterUpload}
                    onChange={handleCarbonTitleAfterUpload}
                  />
                  {carbonTitleAfterUploadRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      className="errorImg"
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={carbonTitleAfterUploadRequired}
                  errorMsg={
                    "Please enter a value for carbon Title After Upload"
                  }
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                {SettingsType.SECTIONDESCRIPTION}*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="carbonDesAfterUpload"
                    id="carbonDesAfterUpload"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonDesAfterUploadRequired ? "errorField" : ""
                    }`}
                    value={carbonDesAfterUpload}
                    onChange={handleCarbonDesAfterUpload}
                  />
                  {carbonDesAfterUploadRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <InlineErroMsg
                  errorType={carbonDesAfterUploadRequired}
                  errorMsg={
                    "Please enter a value for carbon Description After Upload"
                  }
                />
              </div>
            </Col>
          </Form.Group>
          <br />
          <br />
          <hr />
          <div className="mt-4 text-center">
            <Button
              className={`sort-primary-btn btn-min-width`}
              type="button"
              style={{ margin: "0 10px" }}
              onClick={submitForm}
              disabled={!isSubmitEnableCarbonSettings}
            >
              Save
            </Button>
          </div>
          {!isSubmitEnableCarbonSettings && (
            <Row className="permission-denied-page m-0">
              <Card className="no-permission-card">
                You don't have permission to perform this action. Please contact
                the Admin.
              </Card>
            </Row>
          )}
        </Form>
      </div>
    </React.Fragment>
  );
};

CarbonRoadMapSettings.propTypes = {
  postCarbonRoadmapSettings: PropTypes.func,
  isCreateCarbonRoadmapSettingsSuccess: PropTypes.any,
  errorCarbonSettings: PropTypes.any,
  isFailedCarbonRoadmapSettings: PropTypes.any,
  getCarbonRoadmapSettings: PropTypes.func,
  carbonRoadmapSettingsDetails: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isCreateCarbonRoadmapSettingsSuccess:
      state.Settings.isCreateCarbonRoadmapSettingsSuccess,
    isFailedCarbonRoadmapSettings: state.Settings.isFailedCarbonRoadmapSettings,
    errorCarbonSettings: state.Settings.errorCarbonSettings,
    getCarbonRoadmapSettings: state.Settings.getCarbonRoadmapSettings,
    carbonRoadmapSettingsDetails: state.Settings.carbonRoadmapSettingsDetails,
  };
};

export default connect(mapStateToProps, {
  postCarbonRoadmapSettings,
  getCarbonRoadmapSettings,
})(CarbonRoadMapSettings);
