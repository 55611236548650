import {
  GET_OPPORTUNITY_SUMMARY,
  GET_OPPORTUNITY_SUMMARY_SUCCESS,
  GET_OPPORTUNITY_SUMMARY_ERROR,
  GET_CLIENT_BENCHMARK,
  GET_CLIENT_BENCHMARK_SUCCESS,
  GET_CLIENT_BENCHMARK_ERROR,
  CREATE_OPPORTUNIY,
  CREATE_OPPORTUNIY_SUCCESS,
  CREATE_OPPORTUNIY_ERROR,
  VIEW_OPPORTUNITY,
  VIEW_OPPORTUNITY_SUCCESS,
  VIEW_OPPORTUNITY_ERROR,
  DELETE_OPPORTUNITY,
  DELETE_OPPORTUNITY_SUCCESS,
  DELETE_OPPORTUNITY_ERROR,
  GET_OPPORTUNITY_DETAILS,
  GET_OPPORTUNITY_DETAILS_SUCCESS,
  GET_OPPORTUNITY_DETAILS_ERROR,
  UPDATE_OPPORTUNIY,
  UPDATE_OPPORTUNIY_SUCCESS,
  UPDATE_OPPORTUNIY_ERROR,
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_ERROR,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR
} from "./actionTypes";

const initialState = {
  oppoError: null,
  loading: false,
  opportunitySummary: [],
  benchmarkScore: [],
  benchMarkError: null,
  isCreateOpportunitySuccess: false,
  isCreateOpportunityFaild: false,
  createdOpportunityDetails: [],
  oppoListError: null,
  opportunityList: [],
  isSuccessOpportunities: false,
  isSuccessOpportunityDelete: false,
  isFaildOpportunityDelete: false,
  isSuccessOpportunityDetails: false,
  isFaildOpportunityDetails: false,
  opportunityDetails: null,
  isSuccessOpportunityUpdate: false,
  isFaildOpportunityUpdate: false,
  fileUploadedObj: null,
  isSuccessUploadFilesOpp: false,
  isFailUploadFilesOpp: false,
};

const Opportunity = (state = initialState, action) => {
  switch (action.type) {

    case GET_OPPORTUNITY_SUMMARY:
      state = {
        ...state,
        loading: false,
        opportunitySummary: [],
        oppoError: null,
        isFaildOpportunityDelete: false,
      };
      break;
    case GET_OPPORTUNITY_SUMMARY_SUCCESS:
      state = {
        ...state,
        loading: false,
        opportunitySummary: action.payload,
        oppoError: null,
      };
      break;
    case GET_OPPORTUNITY_SUMMARY_ERROR:
      state = {
        ...state,
        loading: false,
        opportunitySummary: [],
        oppoError: action.payload,
      };
      break;
    case GET_CLIENT_BENCHMARK:
      state = {
        ...state,
        loading: false,
        benchmarkScore: [],
        benchMarkError: null,
      };
      break;
    case GET_CLIENT_BENCHMARK_SUCCESS:
      state = {
        ...state,
        loading: false,
        benchmarkScore: action.payload,
        benchMarkError: null,
      };
      break;
    case GET_CLIENT_BENCHMARK_ERROR:
      state = {
        ...state,
        loading: false,
        benchmarkScore: [],
        benchMarkError: action.payload,
      };
      break;
    case CREATE_OPPORTUNIY:
      state = {
        ...state,
        loading: false,
        isCreateOpportunitySuccess: false,
        isCreateOpportunityFaild: false,
        createdOpportunityDetails: [],
        oppoError: null,
        isFaildOpportunityDelete: false,
      };
      break;
    case CREATE_OPPORTUNIY_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateOpportunitySuccess: true,
        isCreateOpportunityFaild: false,
        createdOpportunityDetails: action.payload,
        oppoError: null
      };
      break;
    case CREATE_OPPORTUNIY_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateOpportunitySuccess: false,
        isCreateOpportunityFaild: true,
        createdOpportunityDetails: [],
        oppoError: action.payload
      };
      break;
    case VIEW_OPPORTUNITY:
      state = {
        ...state,
        loading: false,
        opportunityList: [],
        oppoListError: null,
        isSuccessOpportunities: false,
        isCreateOpportunitySuccess: false,
        opportunityDetails: null,
        isFaildOpportunityUpdate: false,
        isFailUploadFilesOpp: false,
        isSuccessOpportunityUpdate: false,
        isSuccessOpportunityDelete: false,
        isFaildOpportunityDelete: false,
      };
      break;
    case VIEW_OPPORTUNITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        opportunityList: action.payload,
        oppoListError: null,
        isSuccessOpportunities: true
      };
      break;
    case VIEW_OPPORTUNITY_ERROR:
      state = {
        ...state,
        loading: false,
        opportunityList: [],
        oppoListError: action.payload,
        isSuccessOpportunities: false
      };
      break;
    case DELETE_OPPORTUNITY:
      state = {
        ...state,
        loading: false,
        oppoListError: null,
        isSuccessOpportunityDelete: false,
        isFaildOpportunityDelete: false
      };
      break;
    case DELETE_OPPORTUNITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        oppoListError: null,
        isSuccessOpportunityDelete: true,
        isFaildOpportunityDelete: false
      };
      break;
    case DELETE_OPPORTUNITY_ERROR:
      state = {
        ...state,
        loading: false,
        oppoListError: action.payload,
        isSuccessOpportunityDelete: false,
        isFaildOpportunityDelete: true
      };
      break;
    case GET_OPPORTUNITY_DETAILS:
      state = {
        ...state,
        loading: false,
        oppoListError: null,
        isSuccessOpportunityDetails: false,
        isFaildOpportunityDetails: false,
        opportunityDetails: null,
        isFaildOpportunityDelete: false,
      };
      break;
    case GET_OPPORTUNITY_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        oppoListError: null,
        isSuccessOpportunityDetails: true,
        isFaildOpportunityDetails: false,
        opportunityDetails: action.payload
      };
      break;
    case GET_OPPORTUNITY_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        oppoListError: action.payload,
        isSuccessOpportunityDetails: false,
        isFaildOpportunityDetails: true,
        opportunityDetails: null
      };
      break;
    case UPDATE_OPPORTUNIY:
      state = {
        ...state,
        loading: false,
        oppoError: null,
        isSuccessOpportunityUpdate: false,
        isFaildOpportunityUpdate: false
      };
      break;
    case UPDATE_OPPORTUNIY_SUCCESS:
      state = {
        ...state,
        loading: false,
        oppoError: null,
        isSuccessOpportunityUpdate: true,
        isFaildOpportunityUpdate: false,
        opportunityDetails: action.payload
      };
      break;
    case UPDATE_OPPORTUNIY_ERROR:
      state = {
        ...state,
        loading: false,
        oppoError: action.payload,
        isSuccessOpportunityUpdate: false,
        isFaildOpportunityUpdate: true,
      };
      break;
    case DELETE_ATTACHMENT:
      state = {
        ...state,
        loading: false,
        oppoError: null,
        isSuccessDeleteAttachment: false,
        isFaildDeleteAttachment: false,
      };
      break;
    case DELETE_ATTACHMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        oppoError: null,
        isSuccessDeleteAttachment: true,
        isFaildDeleteAttachment: false,
      };
      break;
    case DELETE_ATTACHMENT_ERROR:
      state = {
        ...state,
        loading: false,
        oppoError: action.payload,
        isSuccessDeleteAttachment: false,
        isFaildDeleteAttachment: true,
      };
      break;
    case UPLOAD_FILES:
      state = {
        ...state,
        loading: true,
        oppoError: null,
        isSuccessUploadFilesOpp: false,
        isFailUploadFilesOpp: false,
        fileUploadedObj: null,
      };
      break;
    case UPLOAD_FILES_SUCCESS:
      state = {
        ...state,
        loading: false,
        oppoError: null,
        isSuccessUploadFilesOpp: true,
        isFailUploadFilesOpp: false,
        fileUploadedObj: action.payload,
      };
      break;
    case UPLOAD_FILES_ERROR:
      state = {
        ...state,
        loading: false,
        oppoError: action.payload,
        isSuccessUploadFilesOpp: false,
        isFailUploadFilesOpp: true,
        fileUploadedObj: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Opportunity;