import {
  GET_CARBON_ROADMAP,
  GET_CARBON_ROADMAP_SUCCESS,
  GET_CARBON_ROADMAP_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  clientCarbonDetails: null,
  error: null,
};

const ClientCarbon = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARBON_ROADMAP:
      state = {
        ...state,
        loading: false,
        clientCarbonDetails: null,
        error: null,
      };
      break;
    case GET_CARBON_ROADMAP_SUCCESS:
      state = {
        ...state,
        loading: false,
        clientCarbonDetails: action.payload,
        error: null,
      };
      break;
    case GET_CARBON_ROADMAP_ERROR:
      state = {
        ...state,
        loading: false,
        clientCarbonDetails: null,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ClientCarbon;
