import {
  GET_BUILDING_TYPES,
  GET_PARENT_COMPANIES,
  GET_BENCH_MARK_VALUES,
  GET_BUILDING_TYPES_SUCCESS,
  GET_BUILDING_TYPES_ERROR,
  GET_PARENT_COMPANIES_SUCCESS,
  GET_PARENT_COMPANIES_ERROR,
  GET_BENCH_MARK_VALUES_SUCCESS,
  GET_BENCH_MARK_VALUES_ERROR,
  POST_CLIENT_BUSINESS_ACCOUNT_CREATE,
  POST_CLIENT_BUSINESS_ACCOUNT_CREATE_SUCCESS,
  POST_CLIENT_BUSINESS_ACCOUNT_CREATE_ERROR,
  VIEW_CLIENT_BUSINESS_ACCOUNT,
  VIEW_CLIENT_BUSINESS_ACCOUNT_SUCCESS,
  VIEW_CLIENT_BUSINESS_ACCOUNT_ERROR,
  GET_CLIENT_BUSINESS_ACCOUNT_DETAILS,
  GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_SUCCESS,
  GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_ERROR,
  UPDATE_CLIENT_BUSINESS_ACCOUNT,
  UPDATE_CLIENT_BUSINESS_ACCOUNT_SUCCESS,
  UPDATE_CLIENT_BUSINESS_ACCOUNT_ERROR,
  RESET_TO_DEFAULT,
  DELETE_ATTACHMENT_CARBON,
  DELETE_ATTACHMENT_CARBON_SUCCESS,
  DELETE_ATTACHMENT_CARBON_ERROR,
  UPLOAD_FILES_CARBON,
  UPLOAD_FILES_CARBON_SUCCESS,
  UPLOAD_FILES_CARBON_ERROR,
  RESET_CLIENT_BUSINESS,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  buildingTypes: null,
  isBuildingTypeSucces: false,
  parentCompanies: [],
  isParentCompaniesSucces: false,
  benchmarkValues: null,
  isBenchmarkSuccess: false,
  isCreateClientAccountSuccess: false,
  viewClientAccounts: false,
  clientAccountList: null,
  clientAccountDetails: null,
  isSuccessUpdateClientBusiness: false,
  isFaildCreateClient: false,
  isFaildUpdateClient: false,
  isSuccessCarbonAttachmentDelete: false,
  isFailedCarbonAttachmentDelete: false,
  isSuccessUploadFilesCarbon: false,
  isFailUploadFilesCarbon: false,
  fileUploadedCarbonObj: null,
  accountDetails: null,
};

const Client = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUILDING_TYPES:
      state = {
        ...state,
        loading: true,
        buildingTypes: null,
        isBuildingTypeSucces: false,
        accountDetails: null,
      };
      break;
    case GET_BUILDING_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        buildingTypes: action.payload,
        isBuildingTypeSucces: true,
      };
      break;
    case GET_BUILDING_TYPES_ERROR:
      state = {
        ...state,
        loading: false,
        buildingTypes: null,
        isBuildingTypeError: true,
      };
      break;
    case GET_PARENT_COMPANIES:
      state = {
        ...state,
        loading: false,
        isParentCompaniesSucces: false,
      };
      break;
    case GET_PARENT_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        parentCompanies: action.payload,
        isParentCompaniesSucces: true,
      };
      break;
    case GET_PARENT_COMPANIES_ERROR:
      state = {
        ...state,
        loading: false,
        parentCompanies: action.payload,
        isParentCompaniesSucces: false,
      };
      break;
    case GET_BENCH_MARK_VALUES:
      state = {
        ...state,
        loading: false,
        isBenchmarkSuccess: false,
      };
      break;
    case GET_BENCH_MARK_VALUES_SUCCESS:
      state = {
        ...state,
        loading: false,
        isBenchmarkSuccess: true,
        benchmarkValues: action.payload,
      };
      break;
    case GET_BENCH_MARK_VALUES_ERROR:
      state = {
        ...state,
        loading: false,
        isBenchmarkSuccess: false,
      };
      break;
    case POST_CLIENT_BUSINESS_ACCOUNT_CREATE:
      state = {
        ...state,
        loading: false,
        isCreateClientAccountSuccess: false,
        error: null,
        isFaildCreateClient: false,
      };
      break;
    case POST_CLIENT_BUSINESS_ACCOUNT_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isCreateClientAccountSuccess: true,
        error: null,
        isFaildCreateClient: false,
      };
      break;
    case POST_CLIENT_BUSINESS_ACCOUNT_CREATE_ERROR:
      state = {
        ...state,
        loading: false,
        isCreateClientAccountSuccess: false,
        error: action.payload,
        isFaildCreateClient: true,
      };
      break;
    case VIEW_CLIENT_BUSINESS_ACCOUNT:
      state = {
        ...state,
        loading: true,
        viewClientAccounts: false,
        clientAccountList: null,
        error: null,
        isCreateClientAccountSuccess: false,
        isFaildCreateClient: false,
        isFaildUpdateClient: false,
        isSuccessUpdateClientBusiness: false,
      };
      break;
    case VIEW_CLIENT_BUSINESS_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        viewClientAccounts: true,
        clientAccountList: action.payload,
        error: null,
      };
      break;
    case VIEW_CLIENT_BUSINESS_ACCOUNT_ERROR:
      state = {
        ...state,
        loading: false,
        viewClientAccounts: false,
        clientAccountList: null,
        error: action.payload,
      };
      break;
    case GET_CLIENT_BUSINESS_ACCOUNT_DETAILS:
      state = {
        ...state,
        loading: false,
        clientAccountDetails: null,
        error: null,
      };
      break;
    case GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        clientAccountDetails: action.payload,
        error: null,
      };
      break;
    case GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_ERROR:
      state = {
        ...state,
        loading: false,
        clientAccountDetails: null,
        error: action.payload,
      };
      break;
    case UPDATE_CLIENT_BUSINESS_ACCOUNT:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateClientBusiness: false,
        error: null,
        isFaildUpdateClient: false,
      };
      break;
    case UPDATE_CLIENT_BUSINESS_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateClientBusiness: true,
        error: null,
        isFaildUpdateClient: false,
        clientAccountDetails: action.payload,
      };
      break;
    case UPDATE_CLIENT_BUSINESS_ACCOUNT_ERROR:
      state = {
        ...state,
        loading: false,
        isSuccessUpdateClientBusiness: false,
        error: action.payload,
        isFaildUpdateClient: true,
      };
      break;
    case RESET_TO_DEFAULT:
      state = {
        ...state,
        isCreateClientAccountSuccess: false,
      };
      break;
    case DELETE_ATTACHMENT_CARBON:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessCarbonAttachmentDelete: false,
        isFailedCarbonAttachmentDelete: false,
      };
      break;
    case DELETE_ATTACHMENT_CARBON_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessCarbonAttachmentDelete: true,
        isFailedCarbonAttachmentDelete: false,
      };
      break;
    case DELETE_ATTACHMENT_CARBON_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessCarbonAttachmentDelete: false,
        isFailedCarbonAttachmentDelete: true,
      };
      break;
    case UPLOAD_FILES_CARBON:
      state = {
        ...state,
        loading: true,
        error: null,
        isSuccessUploadFilesCarbon: false,
        isFailUploadFilesCarbon: false,
        fileUploadedCarbonObj: null,
      };
      break;
    case UPLOAD_FILES_CARBON_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isSuccessUploadFilesCarbon: true,
        isFailUploadFilesCarbon: false,
        fileUploadedCarbonObj: action.payload,
      };
      break;
    case UPLOAD_FILES_CARBON_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isSuccessUploadFilesCarbon: false,
        isFailUploadFilesCarbon: true,
        fileUploadedCarbonObj: null,
      };
      break;
    case RESET_CLIENT_BUSINESS:
      state = {
        ...state,
        loading: false,
        error: null,
        clientAccountDetails: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Client;
