import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import {
  GET_AD_DETAILS,
  CREATE_STAFF_USER,
  VIEW_STAFF_USER_ACCOUNTS,
  UPDATE_STAFF_USER_ACCOUNT,
  DELETE_USER
} from "./actionTypes"
import {
  getADDetailsSuccess,
  getADDetailsError,
  createStaffUserSuccess,
  createStaffUserError,
  getUserAccountsSuccess,
  getUserAccountsError,
  updateStaffUserAccountSuccess,
  updateStaffUserAccountErroe,
  deleteUserFaild,
  deleteUserSuccess
} from "./actions"
import {
  getADAccountDetails,
  postStaffUserAccount,
  getStaffUserAccounts,
  updateUserAccount,
  deleteUser
} from "../../../helpers/api"


function* getADAccountDetailsFun({ payload: { email } }) {
  try {
    const response = yield call(getADAccountDetails, email)
    if (response.statusCode === 200 && response.message === "GET Request successful.") {
      yield put(getADDetailsSuccess(response))
    } else {
      yield put(getADDetailsError(response.message))
    }
  } catch (error) {
    yield put(getADDetailsError(error))
  }
}

function* createStaffUserAccount({ payload: { data } }) {
  try {
    const response = yield call(postStaffUserAccount, data)
    if (response.statusCode === 200) {
      yield put(createStaffUserSuccess(response.message))
    } else if (response.responseException.exceptionMessage.fieldErrors) {
      yield put(createStaffUserError(response.responseException.exceptionMessage.fieldErrors.Email[0]))
    } else {
      yield put(createStaffUserError(response.responseException.message))
    }
  } catch (error) {
    yield put(createStaffUserError(error))
  }
}

function* getStaffUserAccountList({ payload: { parm } }) {
  try {
    const response = yield call(getStaffUserAccounts, parm)
    if (response.data.statusCode === 200 && response.data.data.data.length > 0) {
      yield put(getUserAccountsSuccess(response.data.data))
    } else {
      yield put(getUserAccountsError("No matching staff user accounts found for your search."))
    }
  } catch (error) {
    yield put(getUserAccountsError("No matching staff user accounts found for your search."))
  }
}

function* updateStaffUserAccountFun({ payload: { data } }) {
  try {
    const response = yield call(updateUserAccount, data)
    if (response.statusCode === 200) {
      yield put(updateStaffUserAccountSuccess(response.data))
    } else {
      yield put(updateStaffUserAccountErroe(response.responseException.exceptionMessage))
    }

  } catch (error) {
    yield put(updateStaffUserAccountErroe(error))
  }
}

function* deleteUserFunc({ payload: { id } }) {
  try {
    const response = yield call(deleteUser, id)
    if (response.statusCode === 200 && response.message === "User deleted successfully") {
      yield put(deleteUserSuccess(response.data))
    } else {
      yield put(deleteUserFaild(response.message))
    }

  } catch (error) {
    yield put(deleteUserFaild(error))
  }
}

export function* watchAccount() {
  yield takeEvery(GET_AD_DETAILS, getADAccountDetailsFun)
  yield takeEvery(CREATE_STAFF_USER, createStaffUserAccount)
  yield takeEvery(VIEW_STAFF_USER_ACCOUNTS, getStaffUserAccountList)
  yield takeEvery(UPDATE_STAFF_USER_ACCOUNT, updateStaffUserAccountFun)
  yield takeEvery(DELETE_USER, deleteUserFunc)
}

function* AccountSaga() {
  yield all([fork(watchAccount)])
}


export default AccountSaga
