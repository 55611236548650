import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { Player, ControlBar } from "video-react";
// import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import { Alert } from "reactstrap";
import ErrorValidation from "../../../../assets/images/icons/ErrorValidation.svg";
import VideoUploadIcon from "../../../../assets/images/icons/VideoUploadIcon.svg";
import UploadIcon from "../../../../assets/images/icons/UploadIcon.svg";

const UploadVideo = (props) => {
  const {
    uploadClick,
    setUploadClick,
    uploadedVideo,
    setUploadedVideo,
    videoRequired,
    setVideoRequired,
  } = props;

  const MAX_BATCH_SIZE = process.env.REACT_APP_FILE_UPLOAD_MAX_BATCH_SIZE;

  const [fileSizeError, setFileSizeError] = useState(false);
  const [currentSelectedFile, setCurrentSelectedFile] = useState(0);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [currentUploadedFile, setCurrentUploadedFile] = useState({});
  const [videoFileName, setVideoFileName] = useState("");
  const [progressFile, setProgressFile] = useState(0);

  const [errorSrc, setErrorSrc] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setErrorSrc(false);
    setFileSizeError(false);
    setUploadedVideo([]);
    setCurrentSelectedFile(0);
    setFileTypeError(false);
    acceptedFiles.forEach((file) => {
      setShowProgress(true);
      const reader = new FileReader();

      reader.onload = () => {
        setCurrentUploadedFile(reader.result);

        if (reader.result) {
          setShowProgress(false);
        }
      };

      reader.fileName = file.name;
      setVideoFileName(reader.fileName);
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          let percentage = (event.loaded / event.total) * 100;
          setTimeout(() => setProgressFile(percentage), 100);
        }
      };
      setSelectedVideo(file);
      reader.readAsDataURL(file);
      setCurrentSelectedFile(1);
    });
    if (rejectedFiles.length > 0) {
      if (!rejectedFiles[0].file.type.includes("video")) {
        setFileTypeError(true);
      } else if (rejectedFiles[0].file.size > JSON.parse(MAX_BATCH_SIZE)) {
        setFileSizeError(true);
        setFileTypeError(false);
      } else {
        setFileSizeError(false);
        setFileTypeError(false);
      }
    }
  }, []);

  const formVideoValidation = () => {
    let errors = [];
    if (currentSelectedFile === 0) {
      setVideoRequired(true);
      setFileTypeError(false);
      errors.push(1);
    } else {
      setVideoRequired(false);
    }

    return errors;
  };

  const uploadVideoLocal = (e) => {
    e.preventDefault();
    let valid = formVideoValidation();
    if (valid.length === 0) {
      let temp = {
        file: currentUploadedFile,
        binaryFile: selectedVideo,
        fileSize: selectedVideo.size,
        mimeType: selectedVideo.type,
        name: selectedVideo.name,
      };

      uploadedVideo.pop();

      setUploadedVideo((uploadedVideo) => [...uploadedVideo, temp]);
      setCurrentSelectedFile(0);
      setProgressFile(0);
      setUploadClick(false);
    }
  };

  const addErrorinSRC = (ev) => {
    setErrorSrc(true);
  };
  return (
    <React.Fragment>
      <Form.Group className="mb-3 row mt-1" controlId="formSettingsFields">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable"></Form.Label>
        </Col>
        <Col lg={8} className="sort-form-field">
          {uploadClick ? (
            <div>
              {currentSelectedFile === 1 ? (
                <div className="dropzone-uploading-editor">
                  <Col lg={12}>
                    <Row>
                      <Col lg={2}>
                        {progressFile !== 100 && (
                          <div className="uploading-sentence">Loading</div>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg={2}>
                        <img src={UploadIcon} alt="error" width={50} height={50}/>
                      </Col>
                      <Col lg={10}>
                        <div className="selected-image-name">
                          {videoFileName}
                        </div>
                        <ProgressBar
                          now={progressFile}
                          style={{ backgroundColor: "#E3E4E8" }}
                          className="file-progress-bar"
                        />
                      </Col>
                    </Row>
                  </Col>
                </div>
              ) : (
                <Dropzone
                  onDrop={onDrop}
                  multiple={false}
                  accept="video/*"
                  maxSize={JSON.parse(MAX_BATCH_SIZE)}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                    rejectedFiles,
                  }) => (
                    <section>
                      <div {...getRootProps({ className: "dropzone-editor" })}>
                        <input {...getInputProps()} />

                        <img src={VideoUploadIcon} alt="video" width={40} height={40}/>
                        <p className="sub-head-video">Drop your video here</p>
                        <p className="click-browse-btn">Click to browse</p>
                      </div>
                      <aside className="selected-file-wrapper"></aside>
                    </section>
                  )}
                </Dropzone>
              )}

              {fileSizeError && (
                <div className="form-group sort-form-group">
                  <Row className="px-2">
                    <Alert color="danger" isOpen={fileSizeError}>
                      <img src={ErrorValidation} alt="error" width={30} height={30}/>
                      Please upload a video that is less than 250MB
                    </Alert>
                  </Row>
                </div>
              )}
              {fileTypeError && (
                <div className="form-group sort-form-group">
                  <Row className="px-2">
                    <Alert color="danger" isOpen={fileTypeError}>
                      <img src={ErrorValidation} alt="error" width={30} height={30}/>
                      Incorrect file type.
                    </Alert>
                  </Row>
                </div>
              )}
              <Button
                className={`sort-primary-add-video-btn`}
                style={{ width: "100%" }}
                onClick={uploadVideoLocal}
                disabled={progressFile !== 100}
              >
                Add Video
              </Button>
            </div>
          ) : (
            <div>
              {uploadedVideo.length > 0 ? (
                <div>
                  {uploadedVideo.map((videoData, i) => (
                    <div key={i}>
                      <Player
                        autoPlay={false}
                        src={videoData.file}
                        onError={addErrorinSRC}
                      >
                        <ControlBar autoHide={false} className="my-class" />
                      </Player>
                    </div>
                  ))}
                  <span
                    className={`inline-form-error ${
                      errorSrc ? "display-error" : ""
                    }`}
                  >
                    Video could not be loaded for uploading. Please re-select
                    the video.
                  </span>
                </div>
              ) : (
                <Player autoPlay={false} src={""}>
                  <ControlBar autoHide={false} className="my-class" />
                </Player>
              )}
            </div>
          )}
          <span
            className={`inline-form-error ${
              videoRequired ? "display-error" : ""
            }`}
          >
            Please upload a Video
          </span>
        </Col>
      </Form.Group>
    </React.Fragment>
  );
};

export default UploadVideo;
