import {
  GET_AD_DETAILS,
  GET_AD_DETAILS_SUCCESS,
  GET_AD_DETAILS_ERROR,
  CREATE_STAFF_USER,
  CREATE_STAFF_USER_SUCCESS,
  CREATE_STAFF_USER_ERROR,
  VIEW_STAFF_USER_ACCOUNTS,
  VIEW_STAFF_USER_ACCOUNTS_ERROR,
  VIEW_STAFF_USER_ACCOUNTS_SUCCESS,
  GET_CURRENT_UPDATE_ACCOUNT,
  UPDATE_STAFF_USER_ACCOUNT,
  UPDATE_STAFF_USER_ACCOUNT_SUCCESS,
  UPDATE_STAFF_USER_ACCOUNT_ERROR,
  RESET_TO_DEFUALT_STATE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED
} from "./actionTypes"

export const getADDetails = (email) => {
  return {
    type: GET_AD_DETAILS,
    payload: { email },
  }
}

export const getADDetailsSuccess = (account) => {
  return {
    type: GET_AD_DETAILS_SUCCESS,
    payload: { account },
  }
}

export const getADDetailsError = (errormsg) => {
  return {
    type: GET_AD_DETAILS_ERROR,
    payload: { errormsg },
  }
}

export const createStaffUser = (data) => {
  return {
    type: CREATE_STAFF_USER,
    payload: { data },
  }
}

export const createStaffUserSuccess = (successMsg) => {
  return {
    type: CREATE_STAFF_USER_SUCCESS,
    payload: { successMsg },
  }
}

export const createStaffUserError = (errormsg) => {
  return {
    type: CREATE_STAFF_USER_ERROR,
    payload: { errormsg },
  }
}

export const getUserAccounts = (parm) => {
  return {
    type: VIEW_STAFF_USER_ACCOUNTS,
    payload: { parm },
  }
}

export const getUserAccountsSuccess = (data) => {
  return {
    type: VIEW_STAFF_USER_ACCOUNTS_SUCCESS,
    payload: { data },
  }
}

export const getUserAccountsError = (errormsg) => {
  return {
    type: VIEW_STAFF_USER_ACCOUNTS_ERROR,
    payload: { errormsg },
  }
}

export const getCurrentUpdateAccount = (data) => {
  return {
    type: GET_CURRENT_UPDATE_ACCOUNT,
    payload: { data },
  }
}
export const updateStaffUserAccount = (data) => {
  return {
    type: UPDATE_STAFF_USER_ACCOUNT,
    payload: { data },
  }
}

export const updateStaffUserAccountSuccess = (data) => {
  return {
    type: UPDATE_STAFF_USER_ACCOUNT_SUCCESS,
    payload: { data },
  }
}

export const updateStaffUserAccountErroe = (error) => {
  return {
    type: UPDATE_STAFF_USER_ACCOUNT_ERROR,
    payload: { error },
  }
}

export const resetToDefaultState = () => {
  return {
    type: RESET_TO_DEFUALT_STATE,
    payload: { },
  }
}

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: { id },
  }
}
export const deleteUserSuccess = (data) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: { data },
  }
}
export const deleteUserFaild = (error) => {
  return {
    type: DELETE_USER_FAILED,
    payload: { error },
  }
}



