export const GET_PROJECT_LIST = "GET_PROJECT_LIST"
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS"
export const GET_PROJECT_LIST_ERROR = "GET_PROJECT_LIST_ERROR"
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS"
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS"
export const GET_PROJECT_DETAILS_ERROR = "GET_PROJECT_DETAILS_ERROR"
export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR"
export const DELETE_ATTACHMENT_PROJECT = "DELETE_ATTACHMENT_PROJECT"
export const DELETE_ATTACHMENT_PROJECT_SUCCESS = "DELETE_ATTACHMENT_PROJECT_SUCCESS"
export const DELETE_ATTACHMENT_PROJECT_ERROR = "DELETE_ATTACHMENT_PROJECT_ERROR"
export const UPLOAD_FILES_PROJECT = "UPLOAD_FILES_PROJECT"
export const UPLOAD_FILES_PROJECT_SUCCESS = "UPLOAD_FILES_PROJECT_SUCCESS"
export const UPLOAD_FILES_PROJECT_ERROR = "UPLOAD_FILES_PROJECT_ERROR"