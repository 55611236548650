//Role
export const ROLE = "/role";

//Account
export const USER = "/user";
export const POST_CREATE_ACCOUNT = "/";

//Permission
export const GET_PERMISSION = "/permission";

//Client
export const GET_BUILDING_TYPE_LIST = "/buildingType";
export const GET_BENCHMARK = "/benchmark";
export const POST_CLIENT_BUSINESS_ACCOUNT = "/clientBusinessAccount";

//Audit
export const POST_AUDIT_ACCOUNT = "/audit";

//User
export const GET_USER_LIST = "/user/name";

//Login
export const POST_AUTHENTICATION = "/authentication";

//Opportunity
export const OPPORTUNITY = "/opportunity";
export const OPPORTUNITY_SUMMARY = "/opportunitysummary";

//Product
export const PRODUCT_CATEGORY = "/productmaincategory";

//Audit Section
export const AUDIT_SECTION = "/auditsection";
export const AUDIT_SECTION_DELETE = "/auditsection?id=";

export const AUDIT_FINDINGS = "auditfindings";

//Settings
export const GLOBAL_SETTINGS = "GlobalSetting";

//Executive Summary
export const EXECUTIVE_SUMMARY = "/executivesummarysection";

//Audit Report
export const AUDIT_REPORT = "/auditreport";

//Admin Analyst Dashboard
export const DASHBOARD = "/dashboard";

//Client view
export const CLIENT_REPORT = "/ClientReport";

export const CLIENT_PROJECT = "/project";

//Finance Settings
export const FINANCE_SETTINGS = "/financingoptionsetting";

//EPC
export const EPC = "/epc";
