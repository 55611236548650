export const CREATE_USER_ROLE = "CREATE_USER_ROLE"
export const CREATE_USER_ROLE_SUCCESS = "CREATE_USER_ROLE_SUCCESS"
export const CREATE_USER_ROLE_ERROR = "CREATE_USER_ROLE_ERROR"
export const GET_PERMISSION = "GET_PERMISSION"
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS"
export const PERMISSION_ERROR = "PERMISSION_ERROR"
export const EDIT_USER_ROLE = "EDIT_USER_ROLE"
export const EDIT_USER_ROLE_SUCCESS = "EDIT_USER_ROLE_SUCCESS"
export const EDIT_USER_ROLE_ERROR = "EDIT_USER_ROLE_ERROR"
export const GET_EDIT_USER_DETAILS = "GET_EDIT_USER_DETAILS"
export const GET_EDIT_USER_DETAILS_SUCCESS = "GET_EDIT_USER_DETAILS_SUCCESS"
export const GET_EDIT_USER_DETAILS_ERROR = "GET_EDIT_USER_DETAILS_ERROR"
export const HADLE_EDIT_MODE = "HADLE_EDIT_MODE"
export const GET_ROLES = "GET_ROLES"
export const ROLES_SUCCESS = "ROLES_SUCCESS"
export const ROLES_ERROR = "ROLES_ERROR"
export const RE_CHECK = "RE_CHECK"
export const UPDATE_PERMISSION_STATUS = "UPDATE_PERMISSION_STATUS"
export const UPDATE_PERMISSION_SUB_STATUS = "UPDATE_PERMISSION_SUB_STATUS"
export const HANDLE_CATEGORY_ONLY = "HANDLE_CATEGORY_ONLY"
export const UPDATE_GET_PERMISSIONS = "UPDATE_GET_PERMISSIONS"
export const RESET_TO_DEFUALT_STATE = "RESET_TO_DEFUALT_STATE"







