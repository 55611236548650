import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Loader from "../../../../components/common/Loader";


const UploadingModal = (props) => {
  const { isShowUploadingModal } = props
  return (
    <React.Fragment>
      <Modal show={isShowUploadingModal} centered className="confirmation-modal">
        <Modal.Header className="update-header">
          <Row>
            <Col lg={12} className="text-center">
              <Modal.Title>
                Uploading files...
              </Modal.Title>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Loader data={"page-spinner modal-spinner"} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default UploadingModal;