import {
  GET_CARBON_ROADMAP,
  GET_CARBON_ROADMAP_SUCCESS,
  GET_CARBON_ROADMAP_ERROR,
} from "./actionTypes";

export const getClientCarbonRoadMap = () => {
  return {
    type: GET_CARBON_ROADMAP,
    payload: {},
  };
};
export const getClientCarbonRoadMapSuccess = (data) => {
  return {
    type: GET_CARBON_ROADMAP_SUCCESS,
    payload: { data },
  };
};
export const getClientCarbonRoadMapFaild = (error) => {
  return {
    type: GET_CARBON_ROADMAP_ERROR,
    payload: { error },
  };
};
