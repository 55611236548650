import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";

import {
  GET_MENTOR_DETAIL_CALLS,
  GET_BASE_LINE_REPORTS,
  GET_BUILDING_AUDITS,
  GET_PROJECT_FINANCING,
  GET_PROJECT_DELIVERY,
  GET_STAFF_ENGAGEMENT,
  GET_BUSINESS_ACCOUNTS,
  POST_BUILDING_AUDITS,
  POST_PROJECT_FINANCING,
  POST_PROJECT_DELIVERY,
  POST_BASELINE_REPORT,
  POST_CARBON_MENTOR_CALLS,
  GET_EPC_BUSINESS_ACCOUNTS,
  GET_SAS_TOKEN,
} from "./actionTypes";

import {
  getGPDMentorDetailCallsSuccess,
  getGPDMentorDetailCallsFaild,
  getGPDBaseLineReportsSuccess,
  getGPDBaseLineReportsFaild,
  getGPDBuildingAuditsSuccess,
  getGPDBuildingAuditsFaild,
  getGPDProjectFinancingSuccess,
  getGPDProjectFinancingFaild,
  getGPDProjectDeliverySuccess,
  getGPDProjectDeliveryFaild,
  getGPDStaffEngagementSuccess,
  getGPDStaffEngagementFaild,
  getBusinessAccountsSuccess,
  getBusinessAccountsFaild,
  searchBuildingAuditsSuccess,
  searchBuildingAuditsFaild,
  searchProjectFinacingSuccess,
  searchProjectFinacingFaild,
  searchProjectDeliverySuccess,
  searchProjectDeliveryFaild,
  searchBaselineReportSuccess,
  searchBaselineReportFaild,
  searchCarbonMentorCallsSuccess,
  searchCarbonMentorCallsFaild,
  getEPCBusinessAccountsSuccess,
  getEPCBusinessAccountsFaild,
  getSASTokenSuccess,
  getSASTokenFaild,
} from "./actions";

import {
  getGPDMentorCalls,
  getGPDBaseLineReports,
  getGPDBuildingAudits,
  getGPDProjectDelivery,
  getGPDProjectFinancing,
  getGPDEcoWise,
  getDasboardBusinessAccounts,
  searchBuildingAudits,
  searchInvestmentProposal,
  searchCompletedProjects,
  searchBaselineReports,
  searchcarbonMentorCalls,
  getSASToken,
} from "../../../../helpers/api";

function* getGPDMentorCallFunc({ payload: { id } }) {
  try {
    const response = yield call(getGPDMentorCalls, id);
    if (response.data.statusCode === 200) {
      yield put(getGPDMentorDetailCallsSuccess(response.data.data.result));
    } else {
      yield put(getGPDMentorDetailCallsFaild(response.data.message));
    }
  } catch (error) {
    yield put(getGPDMentorDetailCallsFaild(error));
  }
}

function* getGPDBaseLineReportFunc({ payload: { id } }) {
  try {
    const response = yield call(getGPDBaseLineReports, id);
    if (response.data.statusCode === 200) {
      yield put(getGPDBaseLineReportsSuccess(response.data.data.result));
    } else {
      yield put(getGPDBaseLineReportsFaild(response.data.message));
    }
  } catch (error) {
    yield put(getGPDBaseLineReportsFaild(error));
  }
}

function* getGPDBuildingAuditsFunc({ payload: { id } }) {
  try {
    const response = yield call(getGPDBuildingAudits, id);
    if (response.data.statusCode === 200) {
      yield put(getGPDBuildingAuditsSuccess(response.data.data.result));
    } else {
      yield put(getGPDBuildingAuditsFaild(response.data.message));
    }
  } catch (error) {
    yield put(getGPDBuildingAuditsFaild(error));
  }
}

function* getGPDProjectFinancingFunc({ payload: { id } }) {
  try {
    const response = yield call(getGPDProjectFinancing, id);
    if (response.data.statusCode === 200) {
      yield put(getGPDProjectFinancingSuccess(response.data.data.result));
    } else {
      yield put(getGPDProjectFinancingFaild(response.data.message));
    }
  } catch (error) {
    yield put(getGPDProjectFinancingFaild(error));
  }
}

function* getGPDProjectDeliveryFunc({ payload: { id } }) {
  try {
    const response = yield call(getGPDProjectDelivery, id);
    if (response.data.statusCode === 200) {
      yield put(getGPDProjectDeliverySuccess(response.data.data.result));
    } else {
      yield put(getGPDProjectDeliveryFaild(response.data.message));
    }
  } catch (error) {
    yield put(getGPDProjectDeliveryFaild(error));
  }
}

function* getGPDEcoWiseFunc({ payload: { id } }) {
  try {
    const response = yield call(getGPDEcoWise, id);
    if (response.data.statusCode === 200) {
      yield put(getGPDStaffEngagementSuccess(response.data.data.result));
    } else {
      yield put(getGPDStaffEngagementFaild(response.data.message));
    }
  } catch (error) {
    yield put(getGPDStaffEngagementFaild(error));
  }
}

function* getBusinessAccountsFunc({ payload: { id } }) {
  try {
    const response = yield call(getDasboardBusinessAccounts, id);
    if (response.data.statusCode === 200) {
      yield put(getBusinessAccountsSuccess(response.data.data.result));
    } else {
      yield put(getBusinessAccountsFaild(response.data.message));
    }
  } catch (error) {
    yield put(getBusinessAccountsFaild(error));
  }
}

function* searchBuildingAuditsFunc({ payload: { param } }) {
  try {
    const response = yield call(searchBuildingAudits, param);
    if (response.data.statusCode === 200) {
      yield put(
        searchBuildingAuditsSuccess(
          response.data.result.businessAccountBuildingAudits
        )
      );
    } else {
      yield put(searchBuildingAuditsFaild(response.data.message));
    }
  } catch (error) {
    yield put(searchBuildingAuditsFaild(error));
  }
}

function* searchInvestmentProposalFunc({ payload: { param } }) {
  try {
    const response = yield call(searchInvestmentProposal, param);
    if (response.data.statusCode === 200) {
      yield put(
        searchProjectFinacingSuccess(
          response.data.result.businessAccountInvestmentProposals
        )
      );
    } else {
      yield put(searchProjectFinacingFaild(response.data.message));
    }
  } catch (error) {
    yield put(searchProjectFinacingFaild(error));
  }
}

function* searchCompletedProjectsFunc({ payload: { param } }) {
  try {
    const response = yield call(searchCompletedProjects, param);
    if (response.data.statusCode === 200) {
      yield put(
        searchProjectDeliverySuccess(
          response.data.result.businessAccountDeliveredProjects
        )
      );
    } else {
      yield put(searchProjectDeliveryFaild(response.data.message));
    }
  } catch (error) {
    yield put(searchProjectFinacingFaild(error));
  }
}

function* searchBaseLineReportFunc({ payload: { param } }) {
  try {
    const response = yield call(searchBaselineReports, param);
    if (response.data.statusCode === 200) {
      yield put(
        searchBaselineReportSuccess(response.data.result.baselineReports)
      );
    } else {
      yield put(searchBaselineReportFaild(response.data.message));
    }
  } catch (error) {
    yield put(searchBaselineReportFaild(error));
  }
}

function* searchCarbonMentorCallFunc({ payload: { param } }) {
  try {
    const response = yield call(searchcarbonMentorCalls, param);
    if (response.data.statusCode === 200) {
      yield put(
        searchCarbonMentorCallsSuccess(response.data.result.mentorCalls)
      );
    } else {
      yield put(searchCarbonMentorCallsFaild(response.data.message));
    }
  } catch (error) {
    yield put(searchCarbonMentorCallsFaild(error));
  }
}
function* getEPCBusinessAcoountsFunc() {
  try {
    const response = yield call(getDasboardBusinessAccounts);
    if (response.data.statusCode === 200) {
      yield put(getEPCBusinessAccountsSuccess(response.data.data.result));
    } else {
      yield put(getEPCBusinessAccountsFaild(response.data.message));
    }
  } catch (error) {
    yield put(getEPCBusinessAccountsFaild(error));
  }
}

function* getSasTokenFunc() {
  try {
    const response = yield call(getSASToken);
    if (response.data.statusCode === 200) {
      yield put(getSASTokenSuccess(response.data.data));
    } else {
      yield put(getSASTokenFaild(response.data.message));
    }
  } catch (error) {
    yield put(getSASTokenFaild(error));
  }
}

export function* watchGPDashboard() {
  yield takeEvery(GET_BUSINESS_ACCOUNTS, getBusinessAccountsFunc);
  yield takeEvery(GET_MENTOR_DETAIL_CALLS, getGPDMentorCallFunc);
  yield takeEvery(GET_BASE_LINE_REPORTS, getGPDBaseLineReportFunc);
  yield takeEvery(GET_BUILDING_AUDITS, getGPDBuildingAuditsFunc);
  yield takeEvery(GET_PROJECT_FINANCING, getGPDProjectFinancingFunc);
  yield takeEvery(GET_PROJECT_DELIVERY, getGPDProjectDeliveryFunc);
  yield takeEvery(GET_STAFF_ENGAGEMENT, getGPDEcoWiseFunc);
  yield takeEvery(POST_BUILDING_AUDITS, searchBuildingAuditsFunc);
  yield takeEvery(POST_PROJECT_FINANCING, searchInvestmentProposalFunc);
  yield takeEvery(POST_PROJECT_DELIVERY, searchCompletedProjectsFunc);
  yield takeEvery(POST_BASELINE_REPORT, searchBaseLineReportFunc);
  yield takeEvery(POST_CARBON_MENTOR_CALLS, searchCarbonMentorCallFunc);
  yield takeEvery(GET_EPC_BUSINESS_ACCOUNTS, getEPCBusinessAcoountsFunc);
  yield takeEvery(GET_SAS_TOKEN, getSasTokenFunc);
}

function* GPDashboardSaga() {
  yield all([fork(watchGPDashboard)]);
}

export default GPDashboardSaga;
