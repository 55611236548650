import React from "react";
import { Col, Form } from "react-bootstrap";
import InfoTooltip from "../../components/common/tooltip";

const PermissionToExpand = (props) => {
  const {
    isMentorCallWidgetEnabled,
    setIsMentorCallWidgetEnabled,
    isBaseLineReportWidgetEnabled,
    setIsBaseLineReportWidgetEnabled,
    isBuildingAuditWidgetEnabled,
    setIsBuildingAuditWidgetEnabled,
    isDeliveredProjectWidgetEnabled,
    setIsDeliveredProjectWidgetEnabled,
    isInvestmentReadyWidgetEnabled,
    setIsInvestmentReadyWidgetEnabled,
  } = props;

  const handleCarbonMentorCallChange = (e) => {
    if (e) {
      setIsMentorCallWidgetEnabled(e.target.checked);
    }
  };
  const handleBaseLineReportChange = (e) => {
    if (e) {
      setIsBaseLineReportWidgetEnabled(e.target.checked);
    }
  };
  const handleBuildingAuditChange = (e) => {
    if (e) {
      setIsBuildingAuditWidgetEnabled(e.target.checked);
    }
  };
  const handleInvestmentChange = (e) => {
    if (e) {
      setIsInvestmentReadyWidgetEnabled(e.target.checked);
    }
  };
  const handleProjectDeliveryChange = (e) => {
    if (e) {
      setIsDeliveredProjectWidgetEnabled(e.target.checked);
    }
  };

  return (
    <React.Fragment>
      <Form.Group className="mb-3 row mt-4" controlId="formRoleStatus">
        <Col sm={4} md={3} lg={3}>
          <Form.Label className="sort-form-section-lable">
            Permission to expand
            <InfoTooltip text={"Permission to expand"} id="permissionDetails" />
          </Form.Label>
        </Col>
        <Col lg={2} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Enable Carbon mentor call
          </Form.Label>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="checkbox"
              name="mentorCall"
              id="toggle11"
              checked={isMentorCallWidgetEnabled}
              onChange={handleCarbonMentorCallChange}
            />
            <label className="label1" htmlFor="toggle11">
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </Col>
        <Col lg={2} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Enable Base line reports
          </Form.Label>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="checkbox"
              name="baselineReport"
              id="toggle22"
              checked={isBaseLineReportWidgetEnabled}
              onChange={handleBaseLineReportChange}
            />
            <label className="label1" htmlFor="toggle22">
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </Col>
        <Col lg={2} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Enable Building audits
          </Form.Label>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="checkbox"
              name="buildingAudit"
              id="toggle33"
              checked={isBuildingAuditWidgetEnabled}
              onChange={handleBuildingAuditChange}
            />
            <label className="label1" htmlFor="toggle33">
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row mt-4" controlId="formRoleStatus">
        <Col sm={4} md={3} lg={3}></Col>

        <Col lg={2} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Enable Project financing
          </Form.Label>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="checkbox"
              name="projectFinance"
              id="toggle44"
              checked={isInvestmentReadyWidgetEnabled}
              onChange={handleInvestmentChange}
            />
            <label className="label1" htmlFor="toggle44">
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </Col>
        <Col lg={2} className="sort-form-field">
          <Form.Label className={`sort-form-lable`}>
            Enable Project delivery
          </Form.Label>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="checkbox"
              name="projectDelivery"
              id="toggle5"
              checked={isDeliveredProjectWidgetEnabled}
              onChange={handleProjectDeliveryChange}
            />
            <label className="label1" htmlFor="toggle5">
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </Col>
      </Form.Group>
    </React.Fragment>
  );
};

export default PermissionToExpand;
