import PropTypes from "prop-types";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";
// Import Routes all
import { allRoutes, authRoutes } from "./routes/allRoute";
import Authmiddleware from "./routes/middleware";
import NonAuthLayout from "./components/common/NonAuthLayout";
import LayoutWrapper from "./components/common/LayoutWrapper";

// Import scss
import "./assets/scss/theme.scss";


const App = () => {
  return (
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route key={idx} path={"/login"} element={<route.component />} />
        ))}
        {allRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware
                component={route.component}
                isAuthProtected={true}
                layout={LayoutWrapper}
              />
            }
            key={idx}
          />
        ))}
      </Routes>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);