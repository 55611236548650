import { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { default as ReactSelect } from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  clearSearchFieldBaseline,
  clearSearchFieldBuildingAudit,
  clearSearchFieldMentorCall,
  clearSearchFieldCompleted,
  clearSearchFieldInvestment,
} from "../../../store/Dashboard/GP/staff/actions";

const CompanySearch = (props) => {
  const {
    searchLabel,
    list,
    searchParm,
    setSearchParm,
    searchFunction,
    isClearable,
    setIsClearable,
    type,
    onePanelExpanded,
    widgetType,
  } = props;

  const [companyList, setCompanyList] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    if (list && list.data.length > 0) {
      let companies = list.data.map((item) => {
        const container = {};

        container.value = item.clientBussinessAccountId;
        container.label = item.companyName;

        return container;
      });
      setCompanyList(companies);
    }
  }, []);

  const handleSearchChange = (e) => {
    if (e) {
      setSearchParm(e.label);
      setIsClearable(true);
    } else {
      setIsClearable(false);
      setSearchParm("");
      if (widgetType == "baseline") {
        props.clearSearchFieldBaseline();
      }
      if (widgetType == "building") {
        props.clearSearchFieldBuildingAudit();
      }
      if (widgetType == "mentorcall") {
        props.clearSearchFieldMentorCall();
      }
      if (widgetType == "completedProjects") {
        props.clearSearchFieldCompleted();
      }
      if (widgetType == "projectFinance") {
        props.clearSearchFieldInvestment();
      }
    }
  };
  const handleSearch = () => {
    let param = {
      pageNumber: 1,
      pageSize: 10,
      searchBy: searchParm,
    };
    searchFunction(param);
  };

  return (
    <Row className="header">
      <Col className={`${onePanelExpanded ? "col-5" : "col-4"}`}>
        {searchLabel}
      </Col>
      {type === "staff" ? (
        <>
          <Col className={`${onePanelExpanded ? "col-5" : "col-6"}`}>
            <ReactSelect
              options={companyList || []}
              placeholder="Company name"
              onChange={handleSearchChange}
              isClearable={isClearable}
              onInputChange={(input) => {
                if (input.length > 3) {
                  setMenuIsOpen(true);
                } else {
                  setMenuIsOpen(false);
                }
              }}
              menuIsOpen={menuIsOpen}
            />
          </Col>
          <Col
            className={`${onePanelExpanded ? "col-2 p-l-0" : "col-2 p-l-0"}`}
          >
            <Button
              className={`gpd-btn-search`}
              disabled={searchParm !== "" ? false : true}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Col>
        </>
      ) : (
        <>
          {!(
            props.businessAccounts &&
            props.businessAccounts.data.length == 1 &&
            props.businessAccounts.data[0].bussinessAccountType === 3
          ) && (
            <>
              <Col className={`${onePanelExpanded ? "col-5" : "col-6"}`}>
                <ReactSelect
                  options={companyList || []}
                  placeholder="Company name"
                  onChange={handleSearchChange}
                  isClearable={isClearable}
                  onInputChange={(input) => {
                    if (input.length > 3) {
                      setMenuIsOpen(true);
                    } else {
                      setMenuIsOpen(false);
                    }
                  }}
                  menuIsOpen={menuIsOpen}
                />
              </Col>
              <Col
                className={`${
                  onePanelExpanded ? "col-2 p-l-0" : "col-2 p-l-0"
                }`}
              >
                <Button
                  className={`gpd-btn-search`}
                  disabled={searchParm !== "" ? false : true}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Col>
            </>
          )}
        </>
      )}
    </Row>
  );
};

CompanySearch.propTypes = {
  clearSearchFieldBaseline: PropTypes.func,
  businessAccounts: PropTypes.any,
  clearSearchFieldBuildingAudit: PropTypes.func,
  clearSearchFieldCompleted: PropTypes.func,
  clearSearchFieldInvestment: PropTypes.func,
  clearSearchFieldMentorCall: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    clearSearchFieldBaseline: state.GPDashboard.clearSearchFieldBaseline,
    businessAccounts: state.GPDashboard.businessAccounts,
    clearSearchFieldBuildingAudit:
      state.GPDashboard.clearSearchFieldBuildingAudit,
    clearSearchFieldCompleted: state.GPDashboard.clearSearchFieldCompleted,
    clearSearchFieldInvestment: state.GPDashboard.clearSearchFieldInvestment,
    clearSearchFieldMentorCall: state.GPDashboard.clearSearchFieldMentorCall,
  };
};

export default connect(mapStateToProps, {
  clearSearchFieldBaseline,
  clearSearchFieldBuildingAudit,
  clearSearchFieldCompleted,
  clearSearchFieldInvestment,
  clearSearchFieldMentorCall,
})(CompanySearch);
