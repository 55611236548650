import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import InfoTooltip from "../../../components/common/tooltip";
import ErrorValidation from "../../../assets/images/icons/ErrorValidation.svg";
import { getGlobalSettings } from "../../../store/Settings/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Alert } from "reactstrap";
import SuccessfulValidation from "../../../assets/images/icons/SuccessfulValidation.svg";
import { getLoggedUser, getDecodeAuthDetails } from "../../../helpers";

const ClientDashboardItems = (props) => {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;

  let decoded = getDecodeAuthDetails();
  const permissions = decoded.Permission;

  const [disablePermission, setDisablePermission] = useState(false);

  useEffect(() => {
    if (permissions.includes("PERMISSIONS.GLOBALSETTING.CREATE")) {
      setDisablePermission(false);
    } else {
      setDisablePermission(true);
    }
  }, []);

  const [id, setId] = useState("");

  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [untappedSection, setUntappedSection] = useState("");
  const [untappedSubSection, setUntappedSubSection] = useState("");
  const [potentialIOSubSection, setPotentialIOSubSection] = useState("");
  const [energySubSection, setEnergySubSection] = useState("");
  const [waterSubSection, setWaterSubSection] = useState("");
  const [carbonFootPrint, setCarbonFootPrint] = useState("");
  const [leftTitle, setLeftTitle] = useState("");
  const [leftSubTitle, setLeftSubTitle] = useState("");
  const [rightTitle, setRightTitle] = useState("");
  const [rightSubTitle, setRightSubTitle] = useState("");
  const [forecastTitle, setForecastTitle] = useState("");
  const [costInactionTitle, setCostInactionTitle] = useState("");
  const [capitalPlanning, setCapitalPlanning] = useState("");

  const [carbonFootPrintTitle, setCarbonFootPrintTitle] = useState("");
  const [carbonFootNote, setCarbonFootNote] = useState("");
  const [forecastCashFlow, setForecastCashFlow] = useState("");
  const [costInaction, setCostInaction] = useState("");
  const [carbonMentorCallsTooltip, setcarbonMentorCallsTooltip] = useState("");
  const [baselineReportsTooltip, setBaselineReportsTooltip] = useState("");
  const [buildingAuditsTooltip, setBuildingAuditsTooltip] = useState("");
  const [projectFinancingTooltip, setProjectFinancingTooltip] = useState("");
  const [projectDeliveryTooltip, setProjectDeliveryTooltip] = useState("");
  const [stakeholderEngageTooltip, setStakeholderEngageTooltip] = useState("");
  const [currentEPC, setCurrentEPC] = useState("");
  const [potentialEPC, setPotentialEPC] = useState("");

  const [energyToCarbon, setEnergyToCarbon] = useState("");
  const [waterToCarbon, setWaterToCarbon] = useState("");
  const [carbonToNoOfHours, setCarbonToNoOfHours] = useState("");
  const [carbonToNoOfNights, setCarbonToNoOfNights] = useState("");
  const [averageTravelling, setAverageTravelling] = useState("");
  const [annualEnergyCost, setAnnualEnergyCost] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitEnable, setIsSubmitEnable] = useState(false);
  const [error, setError] = useState([]);
  const [dashboardSuccess, setDashboardSuccess] = useState(false);
  const [dashboardError, setDashboardError] = useState(false);

  const [carbonTitleRequired, setCarbonTitleRequired] = useState(false);
  const [carbonFootNoteRequired, setCarbonFootNoteRequired] = useState(false);
  const [forecastCashFlowRequired, setForecastCashFlowRequired] =
    useState(false);
  const [costInactionRequired, setCostInactionRequired] = useState(false);
  const [carbonMentorCallsRequired, setCarbonMentorCallsRequired] = useState(false);
  const [baseLineReportsRequired, setBaseLineReportsRequired] = useState(false);
  const [buildingAuditsRequired, setbuildingAuditsRequired] = useState(false);
  const [projectFinancingRequired, setprojectFinancingRequired] = useState(false);
  const [projectDeliveryRequired, setprojectDeliveryRequired] = useState(false);
  const [stakeholderEngageRequired, setStakeholderEngageRequired] = useState(false);
  const [currentEPCRequired, setCurrentEPCRequired] = useState(false);
  const [potencialEPCRequired, setPotencialEPCRequired] = useState(false);
  const [adjustedInvestmentRate, setAdjustedInvestmentRate] = useState("");

  const handleCarbonFootPrintTitle = (e) => {
    setCarbonFootPrintTitle(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonFootPrintTitle") {
        setCarbonTitleRequired(false);
      }
    } else {
      if (e.target.name === "carbonFootPrintTitle") {
        setCarbonTitleRequired(true);
      }
    }
  };

  const handleCarbonFootNote = (e) => {
    setCarbonFootNote(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonFootNote") {
        setCarbonFootNoteRequired(false);
      }
    } else {
      if (e.target.name === "carbonFootNote") {
        setCarbonFootNoteRequired(true);
      }
    }
  };

  const handleForecastCashFlow = (e) => {
    setForecastCashFlow(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "forecastCashFlow") {
        setForecastCashFlowRequired(false);
      }
    } else {
      if (e.target.name === "forecastCashFlow") {
        setForecastCashFlowRequired(true);
      }
    }
  };

  const handleCostInaction = (e) => {
    setCostInaction(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "costInaction") {
        setCostInactionRequired(false);
      }
    } else {
      if (e.target.name === "costInaction") {
        setCostInactionRequired(true);
      }
    }
  };

  const handlecarbonMentorCalls = (e) => {
    setcarbonMentorCallsTooltip(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "carbonMentorCalls") {
        setCarbonMentorCallsRequired(false);
      }
    } else {
      if (e.target.name === "carbonMentorCalls") {
        setCarbonMentorCallsRequired(true);
      }
    }
  };

  const handleBaseLineReports = (e) => {
    setBaselineReportsTooltip(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "baselineReports") {
        setBaseLineReportsRequired(false);
      }
    } else {
      if (e.target.name === "baselineReports") {
        setBaseLineReportsRequired(true);
      }
    }
  };

  const handleBuildingAudits = (e) => {
    setBuildingAuditsTooltip(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "buildingAudits") {
        setbuildingAuditsRequired(false);
      }
    } else {
      if (e.target.name === "buildingAudits") {
        setbuildingAuditsRequired(true);
      }
    }
  };

  const handleProjectFinance = (e) => {
    setProjectFinancingTooltip(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "projectFinance") {
        setprojectFinancingRequired(false);
      }
    } else {
      if (e.target.name === "projectFinance") {
        setprojectFinancingRequired(true);
      }
    }
  };

  const handleProjectDelivery = (e) => {
    setProjectDeliveryTooltip(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "projectDelivery") {
        setprojectDeliveryRequired(false);
      }
    } else {
      if (e.target.name === "projectDelivery") {
        setprojectDeliveryRequired(true);
      }
    }
  };

  const handleStakeholderEngage = (e) => {
    setStakeholderEngageTooltip(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "stakeholderEngage") {
        setStakeholderEngageRequired(false);
      }
    } else {
      if (e.target.name === "stakeholderEngage") {
        setStakeholderEngageRequired(true);
      }
    }
  };

  const handlecurrentEPC = (e) => {
    setCurrentEPC(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "currentEpc") {
        setCurrentEPCRequired(false);
      }
    } else {
      if (e.target.name === "currentEpc") {
        setCurrentEPCRequired(true);
      }
    }
  };

  const handlePotentialEPC = (e) => {
    setPotentialEPC(e.target.value.trimStart());

    if (e.target.value.length >= 1) {
      if (e.target.name === "potentialEpc") {
        setPotencialEPCRequired(false);
      }
    } else {
      if (e.target.name === "potentialEpc") {
        setPotencialEPCRequired(true);
      }
    }
  };

  useEffect(() => {
    if (props.globalParameters === null) {
      props.getGlobalSettings();
    }
  }, []);

  useEffect(() => {
    if (dashboardSuccess) {
      props.getGlobalSettings();
    }
  }, [dashboardSuccess]);

  useEffect(() => {
    if (props.globalParameters) {
      let settings = props.globalParameters && props.globalParameters.data;
      let clientDefaultSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.defaultClientSettings;
      let universalDashboardSettings =
        settings &&
        props.globalParameters.data.dashboardSetting.universalSettings;
      let globalSettings =
        settings && props.globalParameters.data.globalSetting;
      let reportSettings =
        settings && props.globalParameters.data.reportSetting;

      setId(settings.id);

      if (universalDashboardSettings.UntappedSavingsTitle !== null) {
        setUntappedSection(universalDashboardSettings.UntappedSavingsTitle);
      }

      setUntappedSubSection(
        universalDashboardSettings.potentialCarbonSavingsTitle
      );
      setPotentialIOSubSection(
        universalDashboardSettings.potentialPAndLImprovementOpportunitiesTitle
      );
      setEnergySubSection(
        universalDashboardSettings.potentialEnergySavingsTitle
      );
      setWaterSubSection(universalDashboardSettings.potentialWaterSavingsTitle);

      if (universalDashboardSettings.CarbonFoodPrintTitle !== null) {
        setCarbonFootPrint(universalDashboardSettings.CarbonFoodPrintTitle);
      }

      if (universalDashboardSettings.CarbonFoodPrintLeftBlockTitle !== null) {
        setLeftTitle(universalDashboardSettings.CarbonFoodPrintLeftBlockTitle);
      }

      if (
        universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle !== null
      ) {
        setLeftSubTitle(
          universalDashboardSettings.CarbonFoodPrintLeftBlockSubtitle
        );
      }

      if (universalDashboardSettings.CarbonFoodPrintRightBlockTitle !== null) {
        setRightTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockTitle
        );
      }

      if (
        universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle !== null
      ) {
        setRightSubTitle(
          universalDashboardSettings.CarbonFoodPrintRightBlockSubtitle
        );
      }

      if (universalDashboardSettings.ForcastCashFlowTitle !== null) {
        setForecastTitle(universalDashboardSettings.ForcastCashFlowTitle);
      }

      if (universalDashboardSettings.ProjectedCostofInactionTitle !== null) {
        setCostInactionTitle(
          universalDashboardSettings.ProjectedCostofInactionTitle
        );
      }

      if (universalDashboardSettings.CapitalPlanning !== null) {
        setCapitalPlanning(universalDashboardSettings.CapitalPlanning);
      }

      if (clientDefaultSettings.CarbonFoodPrintDescription !== null) {
        setCarbonFootPrintTitle(
          clientDefaultSettings.CarbonFoodPrintDescription
        );
      }

      if (clientDefaultSettings.CarbonFoodPrintRightBlockNote !== null) {
        setCarbonFootNote(clientDefaultSettings.CarbonFoodPrintRightBlockNote);
      }

      if (clientDefaultSettings.ForcastCashFlowDescription !== null) {
        setForecastCashFlow(clientDefaultSettings.ForcastCashFlowDescription);
      }

      if (clientDefaultSettings.ProjectedCostofInactionDescription !== null) {
        setCostInaction(
          clientDefaultSettings.ProjectedCostofInactionDescription
        );
      }
      if (clientDefaultSettings.CarbonMentoCallsTooltip !== null) {
        setcarbonMentorCallsTooltip(
          clientDefaultSettings.CarbonMentorCallsTooltip
        );
      }
      if (clientDefaultSettings.BaselineReportsTooltip !== null) {
        setBaselineReportsTooltip(
          clientDefaultSettings.BaselineReportsTooltip
        );
      }
      if (clientDefaultSettings.BuildingAuditsTooltip !== null) {
        setBuildingAuditsTooltip(
          clientDefaultSettings.BuildingAuditsTooltip
        );
      }
      if (clientDefaultSettings.ProjectFinancingTooltip !== null) {
        setProjectFinancingTooltip(
          clientDefaultSettings.ProjectFinancingTooltip
        );
      }
      if (clientDefaultSettings.ProjectDeliveryTooltip !== null) {
        setProjectDeliveryTooltip(
          clientDefaultSettings.ProjectDeliveryTooltip
        );
      }
      if (clientDefaultSettings.StakeholderEngageTooltip !== null) {
        setStakeholderEngageTooltip(
          clientDefaultSettings.StakeholderEngageTooltip
        );
      }
      if (clientDefaultSettings.CurrentEPCDescription !== null) {
        setCurrentEPC(
          clientDefaultSettings.CurrentEPCDescription
        );
      }
      if (clientDefaultSettings.PotentialEPCDescription !== null) {
        setPotentialEPC(
          clientDefaultSettings.PotentialEPCDescription
        );
      }
      if (globalSettings.energyToCarbon % 1 === 0) {
        setEnergyToCarbon(globalSettings.energyToCarbon.toFixed(2));
      } else {
        setEnergyToCarbon(globalSettings.energyToCarbon);
      }

      if (globalSettings.waterToCarbon % 1 === 0) {
        setWaterToCarbon(globalSettings.waterToCarbon.toFixed(2));
      } else {
        setWaterToCarbon(globalSettings.waterToCarbon);
      }

      if (globalSettings.carbonToTVHours % 1 === 0) {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours.toFixed(2));
      } else {
        setCarbonToNoOfHours(globalSettings.carbonToTVHours);
      }

      if (globalSettings.carbonToNoOfNightUKHotel % 1 === 0) {
        setCarbonToNoOfNights(
          globalSettings.carbonToNoOfNightUKHotel.toFixed(2)
        );
      } else {
        setCarbonToNoOfNights(globalSettings.carbonToNoOfNightUKHotel);
      }

      if (globalSettings.averageTravelingOnLongHaulFlights % 1 === 0) {
        setAverageTravelling(
          globalSettings.averageTravelingOnLongHaulFlights.toFixed(2)
        );
      } else {
        setAverageTravelling(globalSettings.averageTravelingOnLongHaulFlights);
      }

      if (globalSettings.annualEnergyCostsIncrease % 1 === 0) {
        setAnnualEnergyCost(
          globalSettings.annualEnergyCostsIncrease.toFixed(2)
        );
      } else {
        setAnnualEnergyCost(globalSettings.annualEnergyCostsIncrease);
      }
      if (reportSettings.aboutSMCC !== null) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(reportSettings.aboutSMCC))
          )
        );
      } else {
        setEditorState(EditorState.createEmpty());
      }

      if (reportSettings.videoTitle !== null) {
        setTitle(reportSettings.videoTitle);
      }

      if (reportSettings.videoDescription !== null) {
        setDescription(reportSettings.videoDescription);
      }
      if (globalSettings.financingBrokerageFee !== null) {
        setAdjustedInvestmentRate(globalSettings.financingBrokerageFee);
      }
      if (universalDashboardSettings.welcomeMessage !== null) {
        setWelcomeMsg(universalDashboardSettings.welcomeMessage);
      }
    }
  }, [props.globalParameters]);

  const formValidation = () => {
    let errors = [];

    if (carbonFootPrintTitle === "") {
      setCarbonTitleRequired(true);
      errors.push(1);
    } else {
      setCarbonTitleRequired(false);
    }

    if (carbonFootNote === "") {
      setCarbonFootNoteRequired(true);
      errors.push(1);
    } else {
      setCarbonFootNoteRequired(false);
    }

    if (forecastCashFlow === "") {
      setForecastCashFlowRequired(true);
      errors.push(1);
    } else {
      setForecastCashFlowRequired(false);
    }

    if (costInaction === "") {
      setCostInactionRequired(true);
      errors.push(1);
    } else {
      setCostInactionRequired(false);
    }

    if (carbonMentorCallsTooltip === "") {
      setCarbonMentorCallsRequired(true);
      errors.push(1);
    } else {
      setCarbonMentorCallsRequired(false);
    }

    if (baselineReportsTooltip === "") {
      setBaseLineReportsRequired(true);
      errors.push(1);
    } else {
      setBaseLineReportsRequired(false);
    }

    if (buildingAuditsTooltip === "") {
      setbuildingAuditsRequired(true);
      errors.push(1);
    } else {
      setbuildingAuditsRequired(false);
    }

    if (projectFinancingTooltip === "") {
      setprojectFinancingRequired(true);
      errors.push(1);
    } else {
      setprojectFinancingRequired(false);
    }

    if (projectDeliveryTooltip === "") {
      setprojectDeliveryRequired(true);
      errors.push(1);
    } else {
      setprojectDeliveryRequired(false);
    }

    if (stakeholderEngageTooltip === "") {
      setStakeholderEngageRequired(true);
      errors.push(1);
    } else {
      setStakeholderEngageRequired(false);
    }

    if (currentEPC === "") {
      setCurrentEPCRequired(true);
      errors.push(1);
    } else {
      setCurrentEPCRequired(false);
    }

    if (potentialEPC === "") {
      setPotencialEPCRequired(true);
      errors.push(1);
    } else {
      setPotencialEPCRequired(false);
    }

    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();
    let valid = formValidation();
    var convertedData = convertToRaw(editorState.getCurrentContent());

    if (valid.length === 0) {
      setShowAlert(true);
      setIsSubmitEnable(true);

      let form_data = new FormData();
      form_data.append("Id", id);

      form_data.append(
        "DashboardSetting.UniversalSettings.WelcomeMessage",
        welcomeMsg
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.UntappedSavingsTitle",
        untappedSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialCarbonSavingsTitle",
        untappedSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialPAndLImprovementOpportunitiesTitle",
        potentialIOSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialEnergySavingsTitle",
        energySubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.PotentialWaterSavingsTitle",
        waterSubSection
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintTitle",
        carbonFootPrint
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockTitle",
        leftTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintLeftBlockSubtitle",
        leftSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockTitle",
        rightTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CarbonFoodPrintRightBlockSubtitle",
        rightSubTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ForcastCashFlowTitle",
        forecastTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.ProjectedCostofInactionTitle",
        costInactionTitle
      );

      form_data.append(
        "DashboardSetting.UniversalSettings.CapitalPlanning",
        capitalPlanning
      );

      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintDescription",
        carbonFootPrintTitle.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonFoodPrintRightBlockNote",
        carbonFootNote.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ForcastCashFlowDescription",
        forecastCashFlow.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ProjectedCostofInactionDescription",
        costInaction.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.CurrentEPCDescription",
        currentEPC.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.PotentialEPCDescription",
        potentialEPC.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.CarbonMentorCallsTooltip",
        carbonMentorCallsTooltip.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.BaselineReportsTooltip",
        baselineReportsTooltip.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.BuildingAuditsTooltip",
        buildingAuditsTooltip.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ProjectFinancingTooltip",
        projectFinancingTooltip.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.ProjectDeliveryTooltip",
        projectDeliveryTooltip.trim()
      );
      form_data.append(
        "DashboardSetting.defaultClientSettings.StakeholderEngageTooltip",
        stakeholderEngageTooltip.trim()
      );
      form_data.append("GlobalSetting.EnergyToCarbon", energyToCarbon);
      form_data.append("GlobalSetting.WaterToCarbon", waterToCarbon);
      form_data.append("GlobalSetting.CarbonToTVHours", carbonToNoOfHours);
      form_data.append(
        "GlobalSetting.CarbonToNoOfNightUKHotel",
        carbonToNoOfNights
      );
      form_data.append(
        "GlobalSetting.AverageTravelingOnLongHaulFlights",
        averageTravelling
      );
      form_data.append(
        "GlobalSetting.AnnualEnergyCostsIncrease",
        annualEnergyCost
      );
      form_data.append(
        "GlobalSetting.FinancingBrokerageFee",
        adjustedInvestmentRate
      );

      form_data.append(
        "ReportSetting.AboutSMCC",
        JSON.stringify(convertedData)
      );

      form_data.append("ReportSetting.VideoTitle", title);

      form_data.append("ReportSetting.VideoDescription", description);

      new Promise((resolve, reject) => {
        fetch(API_URL + "/GlobalSetting", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + getLoggedUser().accessToken,
          },
          body: form_data,
        })
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            if (data.isError) {
              setDashboardSuccess(false);
              setDashboardError(true);
              let emptyArray = [];
              for (const [key, value] of Object.entries(
                data.responseException.exceptionMessage.fieldErrors
              )) {
                emptyArray.push(value[0]);
              }
              setError(emptyArray);
              setIsSubmitEnable(false);
              window.setTimeout(() => {
                setDashboardError(false);
                setShowAlert(false);
              }, 5000);
            } else {
              setDashboardSuccess(true);
              setDashboardError(false);
              setIsSubmitEnable(false);
              window.setTimeout(() => {
                setDashboardSuccess(false);
                setShowAlert(false);
              }, 5000);
            }
            resolve();
          })
          .catch((err) => {
            console.log("err", JSON.stringify(err));
          });
      });

      window.scrollTo(0, 0);
      window.setTimeout(() => {
        setShowAlert(false);
        setIsSubmitEnable(false);
      }, 5000);
    }
  };

  return (
    <React.Fragment>
      {dashboardError && showAlert && (
        <Row className="px-2">
          {error.map((item) => {
            return (
              <Alert
                key={item}
                color="danger"
                isOpen={dashboardError && showAlert}
              >
                <img src={ErrorValidation} alt="error" width={30} height={30} />
                {item}
              </Alert>
            );
          })}
        </Row>
      )}
      {dashboardError && showAlert && (
        <Row className="px-2">
          <Alert color="danger" isOpen={dashboardError && showAlert}>
            <img src={ErrorValidation} alt="error" width={30} height={30} />
            Changes could not be saved
          </Alert>
        </Row>
      )}
      {dashboardSuccess && showAlert && (
        <Row className="px-2">
          <Alert color="success" isOpen={dashboardSuccess && showAlert}>
            <img
              src={SuccessfulValidation}
              alt="success"
              width={30}
              height={30}
            />
            Changes saved successfully
          </Alert>
        </Row>
      )}
      {permissions.includes("PERMISSIONS.GLOBALSETTING.VIEW") ? (
        <Form className="py-1">
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Carbon Footprint &nbsp;
                <InfoTooltip text={"Carbon Footprint"} id="carbonFootprint" />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Description under the main title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="carbonFootPrintTitle"
                    id="carbonFootPrintTitle"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonTitleRequired ? "errorField" : ""
                    } `}
                    value={carbonFootPrintTitle}
                    onChange={handleCarbonFootPrintTitle}
                  />
                  {carbonTitleRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      className="errorImg"
                      height={20}
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    carbonTitleRequired ? "display-error" : ""
                  }`}
                >
                  Please enter the default description.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}></Col>
            <Col lg={8} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                <div className="setting-right-block">Right Block</div>
                Footnote for the resulting carbon footprint*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="carbonFootNote"
                    id="carbonFootNote"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonFootNoteRequired ? "errorField" : ""
                    }`}
                    value={carbonFootNote}
                    onChange={handleCarbonFootNote}
                  />
                  {carbonFootNoteRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    carbonFootNoteRequired ? "display-error" : ""
                  }`}
                >
                  Please enter the default footnote.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Forecast Cashflow and Breakeven Cycle &nbsp;
                <InfoTooltip
                  text={"Forecast Cashflow and Breakeven Cycle"}
                  id="forecastCashFlow"
                />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Description under the main title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="forecastCashFlow"
                    id="forecastCashFlow"
                    type="text"
                    className={`sort-form-input form-control ${
                      forecastCashFlowRequired ? "errorField" : ""
                    }`}
                    value={forecastCashFlow}
                    onChange={handleForecastCashFlow}
                  />
                  {forecastCashFlowRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    forecastCashFlowRequired ? "display-error" : ""
                  }`}
                >
                  Please enter the default description.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Projected Cost of Inaction &nbsp;
                <InfoTooltip
                  text={"Projected Cost of Inaction"}
                  id="costInaction"
                />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Description under the main title*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="costInaction"
                    id="costInaction"
                    type="text"
                    className={`sort-form-input form-control ${
                      costInactionRequired ? "errorField" : ""
                    }`}
                    value={costInaction}
                    onChange={handleCostInaction}
                  />
                  {costInactionRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    costInactionRequired ? "display-error" : ""
                  }`}
                >
                  Please enter the default description.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                EPC Summary  &nbsp;
                <InfoTooltip
                  text={"EPC Summary"}
                  id="epcSummary"
                />
              </Form.Label>
            </Col>
            <Col lg={8} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Description under the current EPC*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="currentEpc"
                    id="currentEpc"
                    type="text"
                    className={`sort-form-input form-control ${
                      currentEPCRequired ? "errorField" : ""
                    }`}
                    value={currentEPC}
                    onChange={handlecurrentEPC}
                  />
                  {currentEPCRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    currentEPCRequired ? "display-error" : ""
                  }`}
                >
                  Please enter the default description.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}></Col>
            <Col lg={8} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Description under the potential EPC*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="potentialEpc"
                    id="potentialEpc"
                    type="text"
                    className={`sort-form-input form-control ${
                      potencialEPCRequired ? "errorField" : ""
                    }`}
                    value={potentialEPC}
                    onChange={handlePotentialEPC}
                  />
                  {potencialEPCRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    potencialEPCRequired ? "display-error" : ""
                  }`}
                >
                  Please enter the default description.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable">
                Dashboard Tooltips &nbsp;
                <InfoTooltip
                  text={"Dashboard Tooltips"}
                  id="tooltips"
                />
              </Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
              Carbon Mentor Calls*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <textarea
                    name="carbonMentorCalls"
                    id="carbonMentorCalls"
                    type="text"
                    className={`sort-form-input form-control ${
                      carbonMentorCallsRequired ? "errorField" : ""
                    }`}
                    value={carbonMentorCallsTooltip}
                    onChange={handlecarbonMentorCalls}
                  />
                  {carbonMentorCallsRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    carbonMentorCallsRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a tooltip for the section.
                </span>
              </div>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Baseline Reports*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <textarea
                    name="baselineReports"
                    id="baselineReports"
                    type="text"
                    className={`sort-form-input form-control ${
                      baseLineReportsRequired ? "errorField" : ""
                    }`}
                    value={baselineReportsTooltip}
                    onChange={handleBaseLineReports}
                  />
                  {baseLineReportsRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    baseLineReportsRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a tooltip for the subsection.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Building Audits*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <textarea
                    name="buildingAudits"
                    id="buildingAudits"
                    type="text"
                    className={`sort-form-input form-control ${
                      buildingAuditsRequired ? "errorField" : ""
                    }`}
                    value={buildingAuditsTooltip}
                    onChange={handleBuildingAudits}
                  />
                  {buildingAuditsRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    buildingAuditsRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a tooltip for the subsection.
                </span>
              </div>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Project Financing*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <textarea
                    name="projectFinance"
                    id="projectFinance"
                    type="text"
                    className={`sort-form-input form-control ${
                      projectFinancingRequired ? "errorField" : ""
                    }`}
                    value={projectFinancingTooltip}
                    onChange={handleProjectFinance}
                  />
                  {projectFinancingRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    projectFinancingRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a tooltip for the subsection.
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3 row mt-4">
            <Col sm={4} md={3} lg={3}>
              <Form.Label className="sort-form-section-lable"></Form.Label>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Project Delivery*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <textarea
                    name="projectDelivery"
                    id="projectDelivery"
                    type="text"
                    className={`sort-form-input form-control ${
                      projectDeliveryRequired ? "errorField" : ""
                    }`}
                    value={projectDeliveryTooltip}
                    onChange={handleProjectDelivery}
                  />
                  {projectDeliveryRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    projectDeliveryRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a tooltip for the subsection.
                </span>
              </div>
            </Col>
            <Col lg={4} className="sort-form-field">
              <Form.Label className={`sort-form-lable`}>
                Staff / Stakeholder Engagement*
              </Form.Label>
              <div className="sort-form-wrapper">
                <div className="form-group sort-form-group">
                  <textarea
                    name="stakeholderEngage"
                    id="stakeholderEngage"
                    type="text"
                    className={`sort-form-input form-control ${
                      stakeholderEngageRequired ? "errorField" : ""
                    }`}
                    value={stakeholderEngageTooltip}
                    onChange={handleStakeholderEngage}
                  />
                  {stakeholderEngageRequired && (
                    <img
                      src={ErrorValidation}
                      alt="error"
                      width={20}
                      height={20}
                      className="errorImg"
                      style={{ top: 30, right: -30 }}
                    />
                  )}
                </div>
                <span
                  className={`inline-form-error ${
                    stakeholderEngageRequired ? "display-error" : ""
                  }`}
                >
                  Please enter a tooltip for the subsection.
                </span>
              </div>
            </Col>
          </Form.Group>
          <br />
          <br />
          <hr />
          <div className="mt-4 text-center">
            <Button
              className={`sort-primary-btn btn-min-width`}
              type="button"
              style={{ margin: "0px 10px 30px 10px" }}
              onClick={submitForm}
              disabled={disablePermission}
            >
              Save
            </Button>
          </div>
          {disablePermission && (
            <Row className="permission-denied-page m-0">
              <Card className="no-permission-card">
                You don't have permission to perform this action. Please contact
                the Admin.
              </Card>
            </Row>
          )}
        </Form>
      ) : (
        <Row className="permission-denied-page m-0">
          <Card className="no-permission-card">
            You don't have permission to access this content. Please contact the
            Admin.
          </Card>
        </Row>
      )}
    </React.Fragment>
  );
};

ClientDashboardItems.propTypes = {
  getGlobalSettings: PropTypes.func,
  globalParameters: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    getGlobalSettings: state.Settings.getGlobalSettings,
    globalParameters: state.Settings.globalParm,
    error: state.Settings.settingsError,
  };
};

export default connect(mapStateToProps, {
  getGlobalSettings,
})(ClientDashboardItems);
