import {
  GET_BUILDING_TYPES,
  GET_BUILDING_TYPES_SUCCESS,
  GET_BUILDING_TYPES_ERROR,
  GET_PARENT_COMPANIES,
  GET_PARENT_COMPANIES_SUCCESS,
  GET_PARENT_COMPANIES_ERROR,
  GET_BENCH_MARK_VALUES,
  GET_BENCH_MARK_VALUES_SUCCESS,
  GET_BENCH_MARK_VALUES_ERROR,
  POST_CLIENT_BUSINESS_ACCOUNT_CREATE,
  POST_CLIENT_BUSINESS_ACCOUNT_CREATE_SUCCESS,
  POST_CLIENT_BUSINESS_ACCOUNT_CREATE_ERROR,
  VIEW_CLIENT_BUSINESS_ACCOUNT,
  VIEW_CLIENT_BUSINESS_ACCOUNT_SUCCESS,
  VIEW_CLIENT_BUSINESS_ACCOUNT_ERROR,
  GET_CLIENT_BUSINESS_ACCOUNT_DETAILS,
  GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_SUCCESS,
  GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_ERROR,
  UPDATE_CLIENT_BUSINESS_ACCOUNT,
  UPDATE_CLIENT_BUSINESS_ACCOUNT_SUCCESS,
  UPDATE_CLIENT_BUSINESS_ACCOUNT_ERROR,
  RESET_TO_DEFAULT,
  DELETE_ATTACHMENT_CARBON,
  DELETE_ATTACHMENT_CARBON_SUCCESS,
  DELETE_ATTACHMENT_CARBON_ERROR,
  UPLOAD_FILES_CARBON,
  UPLOAD_FILES_CARBON_SUCCESS,
  UPLOAD_FILES_CARBON_ERROR,
  RESET_CLIENT_BUSINESS,
} from "./actionTypes";

export const getBuildingTypes = () => {
  return {
    type: GET_BUILDING_TYPES,
    payload: {},
  };
};
export const getBuildingTypesSuccess = (buildings) => {
  return {
    type: GET_BUILDING_TYPES_SUCCESS,
    payload: { buildings },
  };
};
export const getBuildingTypesError = (buildingTypeError) => {
  return {
    type: GET_BUILDING_TYPES_ERROR,
    payload: { buildingTypeError },
  };
};

export const getParentCompanies = (parm) => {
  return {
    type: GET_PARENT_COMPANIES,
    payload: { parm },
  };
};
export const getParentCompaniesSuccess = (companies) => {
  return {
    type: GET_PARENT_COMPANIES_SUCCESS,
    payload: { companies },
  };
};
export const getParentCompaniesError = (companyError) => {
  return {
    type: GET_PARENT_COMPANIES_ERROR,
    payload: { companyError },
  };
};

export const getBenchMark = () => {
  return {
    type: GET_BENCH_MARK_VALUES,
    payload: {},
  };
};
export const getBenchMarkSuccess = (data) => {
  return {
    type: GET_BENCH_MARK_VALUES_SUCCESS,
    payload: { data },
  };
};
export const getBenchMarkError = (error) => {
  return {
    type: GET_BENCH_MARK_VALUES_ERROR,
    payload: { error },
  };
};
export const postClientBusinessAccount = (data) => {
  return {
    type: POST_CLIENT_BUSINESS_ACCOUNT_CREATE,
    payload: { data },
  };
};
export const postClientBusinessAccountSuccess = (data) => {
  return {
    type: POST_CLIENT_BUSINESS_ACCOUNT_CREATE_SUCCESS,
    payload: { data },
  };
};
export const postClientBusinessAccountError = (error) => {
  return {
    type: POST_CLIENT_BUSINESS_ACCOUNT_CREATE_ERROR,
    payload: { error },
  };
};
export const viewClientBusinessAccounts = (parm) => {
  return {
    type: VIEW_CLIENT_BUSINESS_ACCOUNT,
    payload: { parm },
  };
};
export const viewClientBusinessAccountsSuccess = (data) => {
  return {
    type: VIEW_CLIENT_BUSINESS_ACCOUNT_SUCCESS,
    payload: { data },
  };
};
export const viewClientBusinessAccountsError = (error) => {
  return {
    type: VIEW_CLIENT_BUSINESS_ACCOUNT_ERROR,
    payload: { error },
  };
};
export const getClientBusinessAccountDetails = (id) => {
  return {
    type: GET_CLIENT_BUSINESS_ACCOUNT_DETAILS,
    payload: { id },
  };
};
export const getClientBusinessAccountDetailsSuccess = (data) => {
  return {
    type: GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_SUCCESS,
    payload: { data },
  };
};
export const getClientBusinessAccountDetailsError = (error) => {
  return {
    type: GET_CLIENT_BUSINESS_ACCOUNT_DETAILS_ERROR,
    payload: { error },
  };
};
export const updateClientBusinessAccount = (data) => {
  return {
    type: UPDATE_CLIENT_BUSINESS_ACCOUNT,
    payload: { data },
  };
};
export const updateClientBusinessAccountSuccess = (data) => {
  return {
    type: UPDATE_CLIENT_BUSINESS_ACCOUNT_SUCCESS,
    payload: { data },
  };
};
export const updateClientBusinessAccountError = (error) => {
  return {
    type: UPDATE_CLIENT_BUSINESS_ACCOUNT_ERROR,
    payload: { error },
  };
};
export const resetToDefaultState = () => {
  return {
    type: RESET_TO_DEFAULT,
    payload: {},
  };
};
export const deleteAttachmentCarbon = (id, attachmentId) => {
  return {
    type: DELETE_ATTACHMENT_CARBON,
    payload: { id, attachmentId },
  };
};
export const deleteAttachmentCarbonSuccess = (data) => {
  return {
    type: DELETE_ATTACHMENT_CARBON_SUCCESS,
    payload: { data },
  };
};
export const deleteAttachmentCarbonFaild = (error) => {
  return {
    type: DELETE_ATTACHMENT_CARBON_ERROR,
    payload: { error },
  };
};
export const uploadFilesCarbon = (data, id) => {
  return {
    type: UPLOAD_FILES_CARBON,
    payload: { data, id },
  };
};
export const uploadFilesCarbonSuccess = (data) => {
  return {
    type: UPLOAD_FILES_CARBON_SUCCESS,
    payload: { data },
  };
};
export const uploadFilesCarbonError = (error) => {
  return {
    type: UPLOAD_FILES_CARBON_ERROR,
    payload: { error },
  };
};
export const resetClientBusiness = (data) => {
  return {
    type: RESET_CLIENT_BUSINESS,
    payload: { data },
  };
};
